import React, { useState } from 'react';
import HowLong from '../how_long';

export default function ExperiencingBackPain({ answers, onSubmit, onBack, gtm }) {
  return (
    <HowLong
      answers={answers}
      keyForYears={'yearsExperiencingBackPain'}
      keyForMonths={'monthsExperiencingBackPain'}
      onSubmit={onSubmit}
      onBack={onBack}
      gtm={`${gtm} Experiencing Back Pain`}
      title={`How long have you been experiencing back pain?`}
      para={`This information will help the specialist understand how they can support you.`}
    />
  );
}
