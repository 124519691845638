import React from 'react';
import { useHistory } from 'react-router-dom';
// import WeightLossSurgeonMatchQuestionnaire from '../../questionnaire/weight_loss_surgeon_match'
import SurgeonMatchQuestionnaire from '../../questionnaire/surgeon_match';
import './index.css';

export default function GetStartedSurgeonMatch({
  home,
  updateAnswersAction,
  setContactIdAction,
  setSurgeonMatchQuestionnaireProgress,
  setSurgeon,
}) {
  let history = useHistory();

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <>
      <div style={{ minHeight: '200px', marginTop: '0px' }}>
        <div id='questionnaire' className='marg-bottom-20'>
          <div className='row justify-content-center h-100'>
            <div className='col my-auto'>
              <SurgeonMatchQuestionnaire
                globalAnswers={home.answers}
                setGlobalAnswers={updateAnswersAction}
                progress={home.surgeonMatchQuestionnaireProgress}
                setProgress={setSurgeonMatchQuestionnaireProgress}
                contactId={home.contactId}
                setContactIdAction={setContactIdAction}
                surgeon={home.surgeon}
                setSurgeon={setSurgeon}
                includeBackButton
                gtm={`Get Started`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
