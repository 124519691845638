import React from 'react';
import styles from './index.module.css';

export default function StatusMessage({ status = 'error', message = 'There has been an error' }) {
  const statuses = {
    error: {
      styles: {
        color: '#EC4E3E',
        backgroundColor: '#FEEEEC',
      },
    },
    infoBlue: {
      styles: {
        color: '#419DA5',
        backgroundColor: '#DDF7F9',
      },
    },
    success: {
      styles: {
        color: '#24A845',
        backgroundColor: '#EBFAEF',
      },
    },
  };
  let selectedStatus = statuses[status];

  return (
    <div style={selectedStatus.styles} className={styles.status_wrap}>
      <p style={{ color: selectedStatus.styles.color }}>{message}</p>
    </div>
  );
}
