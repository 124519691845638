import { connect } from 'react-redux';
import {
  setContactIdAction,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setPatientPartners,
} from '../../../actions/home';
import GetStartedPatientPartnerMatch from '../../../components/get_started_v2/patient_partner_match';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
  setContactIdAction: (item) => dispatch(setContactIdAction(item)),
  setPatientPartnerQuestionnaireProgress: (item) => dispatch(setPatientPartnerQuestionnaireProgress(item)),
  setPatientPartners: (item) => dispatch(setPatientPartners(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStartedPatientPartnerMatch);
