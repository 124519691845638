import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CountdownTimer from '../countdown_timer';
import SuccessPanel from '../success_panel';
import './weight_loss_webinar_parent.css';
import FsLightbox from 'fslightbox-react';
import { useCookies } from 'react-cookie';
import { validatePhone, validateEmail, validateText, validateZipcode } from '../../helpers';
import { createWebinarContacts } from '../../api/webinar_contacts';
import ErrorMessage from '../ui_kit/utilities/error_message';

export default function WeightLossWebinarParent({
  home,
  updateAnswersAction,
  webinarTitleForDb,
  smallSubTitle = 'Free Weight Loss Webinar',
  title = 'Life through Bariatric surgery: Questions you’re scared to ask',
  photo = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/webinars/weight-loss/dr-russo-and-dina/freeweightlosswebinar.jpeg',
  trailer,
  webinarDate = 'Feb 11, 2021 11:00:00',
  webinarDateText = ['Monday, April 12th, 2021', '11:00 AM PT', '45 Minutes'],
  evergreenPage,
  googleCalendarLink,
  otherCalendarLink,
  content,
  wrapperClass,
}) {
  const ALL_VALUES = [
    'Please select an option',
    'Exploring weight-loss options',
    'Ready to get weight-loss surgery',
    'Learning about surgical revisions',
    'Looking for support during recovery',
  ];
  const defaultFullName = home.answers.name || '';

  const [fullName, setFullName] = useState(defaultFullName);
  const [email, setEmail] = useState(home.answers.email || '');
  const [phone, setPhone] = useState(home.answers.phone || '');
  const [zipcode, setZipcode] = useState(home.answers.zipcode || '');
  const [extraInfo, setExtraInfo] = useState('');
  const [currentJourney, setCurrentJourney] = useState(ALL_VALUES[0]);
  const [toggler, setToggler] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(cookies);
  const [evergreen, setEvergreen] = useState(false);

  let disabled = 'disabled';
  let emailError = '';
  let phoneError = '';
  let zipcodeError = '';
  var checkedEmail = validateEmail(email);
  var checkedFullName = validateText(fullName);
  var checkedPhone = validatePhone(phone);
  var checkedZip = validateZipcode(zipcode);

  if (
    checkedFullName === true &&
    checkedEmail === true &&
    checkedPhone === true &&
    checkedZip === true &&
    currentJourney !== 'Please select an option'
  ) {
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  if (email !== '' && email !== undefined && checkedEmail === false) {
    emailError = 'Please enter a valid email';
  } else if (email !== '' && checkedEmail === true) {
    emailError = '';
  }

  if (phone !== '' && phone !== undefined) {
    if (checkedPhone === false) {
      phoneError = 'Please enter a valid phone number';
    } else {
      phoneError = '';
    }
  }

  if (zipcode !== '' && zipcode !== undefined) {
    if (checkedZip === false) {
      zipcodeError = 'Please enter only numbers for zipcode';
    } else {
      zipcodeError = '';
    }
  }

  const onSubmit = () => {
    createWebinarContacts(email, webinarTitleForDb, fullName, phone, currentJourney, zipcode, extraInfo, (data) => {
      setShowSuccess(true);
      scrollToSignUp();
      setCookie('weightlosswebinar', { shownPopup: true }, { path: '/' });
    });
    updateAnswersAction({
      ...home.answers,
      name: fullName,
      email,
      phone,
      zipcode,
    });
  };

  const scrollToSignUp = () => {
    document.querySelector('#sign-up').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const scrollToWebinar = () => {
    document.querySelector('#webinar').scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    var webinarTime = new Date(webinarDate).getTime();
    var currentTime = new Date().getTime();
    if (webinarTime - currentTime <= 0) {
      setEvergreen(true);
    }
  }, []);

  return (
    <div className={`webinar-parent ${wrapperClass}`}>
      {trailer && (
        <div className='container-fluid webinar-hero'>
          <>
            <div className='video-cover'></div>
            <div className='video-bg'>
              <video autoPlay='autoplay' muted loop playsInline>
                <source src={trailer} type='video/mp4' />
              </video>
            </div>
          </>
          <div className='container'>
            <div className='row h-100'>
              <div className='col-sm-6'>
                <p className='little-strong marg-bottom-20'>{smallSubTitle}</p>
                <h1 className='main marg-bottom-20'>{title}</h1>
                <h2 className='submain'></h2>
              </div>
              <div className='col-sm-6 my-auto'>
                <div
                  data-gtm-click-label={`webinar-${webinarTitleForDb}-watch-trailer-Google-calendar`}
                  onClick={() => setToggler(!toggler)}
                  className='click-container'
                >
                  <div>
                    <h1 className='main wtfilm marg-bottom-20 text-center'>Watch Trailer</h1>
                    <svg
                      className='play-button'
                      xmlns='http://www.w3.org/2000/svg'
                      width='68'
                      height='78'
                      viewBox='0 0 68 78'
                    >
                      <g id='Polygon_5' data-name='Polygon 5' transform='translate(68) rotate(90)' fill='#fff'>
                        <path
                          d='M 77.13683319091797 67.5 L 0.8631658554077148 67.5 L 39 1.005007147789001 L 77.13683319091797 67.5 Z'
                          stroke='none'
                        />
                        <path
                          d='M 39 2.009994506835938 L 1.726325988769531 67 L 76.273681640625 67 L 39 2.009994506835938 M 39 0 L 78 68 L 0 68 L 39 0 Z'
                          stroke='none'
                          fill='#707070'
                        />
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!trailer && (
        <div style={{ backgroundImage: `url('${photo}')` }} className='container-fluid webinar-hero photo-bg'>
          <div className='photo-cover'></div>
          <div className='container'>
            <div className='row h-100'>
              <div className='col-sm-6'>
                <p className='little-strong marg-bottom-20'>{smallSubTitle}</p>
                <h1 className='main marg-bottom-20'>{title}</h1>
                <h2 className='submain'></h2>
              </div>
              <div className='col-sm-6 my-auto'></div>
            </div>
          </div>
        </div>
      )}
      <div className='container'>
        <div className='row'>
          <div id='sign-up' className='col-md-5 move-me-up last-on-mobile'>
            <div className='sign-up-otr'>
              {!evergreen && (
                <>
                  <h4 className='marg-bottom-10'>{webinarDateText[0]}</h4>
                  <h5>
                    {webinarDateText[1]} | {webinarDateText[2]}
                  </h5>
                  <div className='counter-wrap'>
                    <CountdownTimer date={webinarDate} />
                  </div>
                </>
              )}
              {!showSuccess && (
                <>
                  {!evergreen && (
                    <>
                      <h5 className='marg-top-20 marg-bottom-10'>Register now for the free webinar:</h5>
                      <p className='shrink marg-bottom-20'>
                        *If you can’t make the live event, register and we’ll send you the recording!
                      </p>
                    </>
                  )}
                  {evergreen && <h5>Register now to view the webinar replay:</h5>}
                  <div className='material-input'>
                    <input
                      type='text'
                      name='name'
                      value={fullName}
                      onChange={(event) => setFullName(event.target.value)}
                      required
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label>Full Name</label>
                  </div>
                  <div className='material-input'>
                    <input
                      className='icon-email'
                      type='text'
                      name='email'
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      required
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label>Email</label>
                  </div>
                  <div className='material-input'>
                    <input
                      className='icon-phone'
                      type='phone'
                      name='phone'
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      required
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label>Phone Number</label>
                  </div>
                  <div className='material-input'>
                    <input
                      type='zipcode'
                      name='zipcode'
                      value={zipcode}
                      onChange={(event) => setZipcode(event.target.value)}
                      required
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label>Zip Code</label>
                  </div>
                  <div className='material-input'>
                    <p className='marg-bottom-10'>Where are you in your weight-loss journey:</p>
                    <select
                      className={`${currentJourney !== 'Please select an option' ? 'nobg' : ''}`}
                      onChange={(event) => setCurrentJourney(event.target.value)}
                    >
                      {ALL_VALUES.map((value) => (
                        <option value={value}>{value}</option>
                      ))}
                    </select>
                  </div>
                  {!evergreen && (
                    <div className='material-input'>
                      <p className='marg-bottom-10'>What questions would you like answered:</p>
                      <textarea
                        placeholder='Tell us here...'
                        value={extraInfo}
                        onChange={(e) => setExtraInfo(e.target.value)}
                      ></textarea>
                    </div>
                  )}
                  {!evergreen && (
                    <button
                      data-gtm-click-label={`webinar-${webinarTitleForDb}-register-now-for-the-webinar`}
                      style={{ padding: '20px 40px' }}
                      disabled={disabled}
                      className='marg-top-20 send-it'
                      onClick={onSubmit}
                    >
                      Register now
                    </button>
                  )}
                  {evergreen && (
                    <button
                      data-gtm-click-label={`webinar-${webinarTitleForDb}-register-now-for-the-webinar`}
                      disabled={disabled}
                      className='marg-top-20 send-it'
                      onClick={onSubmit}
                    >
                      Watch now
                    </button>
                  )}
                  {emailError && <ErrorMessage error={emailError} />}
                  {phoneError && <ErrorMessage error={phoneError} />}
                  {zipcodeError && <ErrorMessage error={zipcodeError} />}
                </>
              )}
              {showSuccess && !evergreen && (
                <div className='success-panel-wrapper marg-top-30'>
                  <SuccessPanel
                    title='Thank you!'
                    content='Your spot is confirmed for the webinar! Click one of the links below to add this event to your calendar!'
                  />
                  {googleCalendarLink && (
                    <a target='_blank' href={googleCalendarLink}>
                      <button data-gtm-click-label={`webinar-${webinarTitleForDb}-add-to-Google-calendar`}>
                        Add to Google calendar
                      </button>
                    </a>
                  )}
                  {otherCalendarLink && (
                    <a target='_blank' href={otherCalendarLink} download>
                      <button data-gtm-click-label={`webinar-${webinarTitleForDb}-add-to-other-calendar`}>
                        Add to other calendar
                      </button>
                    </a>
                  )}
                </div>
              )}
              {showSuccess && evergreen && (
                <div className='success-panel-wrapper marg-top-30'>
                  <SuccessPanel title='Thank you for registering for the webinar replay.' content='' />
                  <Link to={evergreenPage}>
                    <button
                      data-gtm-click-label={`webinar-${webinarTitleForDb}-watch-the-webinar`}
                      className='marg-center'
                    >
                      Watch the webinar
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className='col-md-7 first-on-mobile'>
            <div className='content-body'>
              <div className='yes-mobile marg-top-20'>
                <h6>{webinarDateText[0]}</h6>
                <h5>
                  {webinarDateText[1]} | {webinarDateText[2]}
                </h5>
              </div>
              <button
                data-gtm-click-label={`webinar-${webinarTitleForDb}-scroll-to-register-for-the-webinar`}
                onClick={scrollToSignUp}
                className='marg-top-20 send-it-mobile yes-mobile'
              >
                Register now
              </button>
              {content}
            </div>
          </div>
        </div>
      </div>
      {!evergreen && <FsLightbox className='high-z-index' toggler={toggler} sources={[`${trailer}`]} type='video' />}
      {evergreen && <FsLightbox className='high-z-index' toggler={toggler} sources={[`${trailer}`]} type='video' />}
    </div>
  );
}
