import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { validatePhone, validateEmail, validateText } from '../../helpers';
import ErrorMessage from '../ui_kit/utilities/error_message';
import SuccessMessage from '../ui_kit/utilities/success_message';
import './report_an_event.css';
import TxtLeftImgRightWbg from '../hero_sections/txt_left_img_right_wbg';
import HeaderLeftTextRight from '../hero_sections/header_left_text_right';
import RequestButton from '../ui_kit/utilities/request_button';
import SuccessPanel from '../success_panel';

import parsePhoneNumber from 'libphonenumber-js';
import { createReportAnEvent } from '../../api/report_an_events';

export default function ({ australiaPhone = false }) {
  const [view, setView] = useState('form');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [menteeName, setMenteeName] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [phoneError, setPhoneError] = useState('');

  const recaptchaRef = React.createRef();

  const runRecaptcha = async (event) => {
    event.preventDefault();
    recaptchaRef.current.execute();
  };

  let disabled = 'disabled';
  var checkedEmail = validateEmail(email);

  let actualPhone = phone;
  if (actualPhone && australiaPhone && !actualPhone.startsWith('+61')) {
    actualPhone = `+61${actualPhone}`;
  }
  var checkedPhone =
    validatePhone(actualPhone) ||
    (actualPhone !== '' && parsePhoneNumber(actualPhone, 'AUS') && parsePhoneNumber(actualPhone, 'AUS').isValid());

  var checkedName = validateText(name);
  var checkedMenteeName = validateText(menteeName);

  if (
    checkedName === true &&
    checkedEmail === true &&
    checkedMenteeName === true &&
    hasBeenSubmitted == false &&
    message !== ''
  ) {
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  const validatePhoneOnBlur = () => {
    actualPhone !== null &&
    actualPhone !== '' &&
    (!checkedPhone ||
      (parsePhoneNumber(actualPhone, 'US').isValid() || parsePhoneNumber(actualPhone, 'AUS').isValid()) === false)
      ? setPhoneError('Enter a valid phone number')
      : setPhoneError('');
  };

  const validate = (event) => {
    event.preventDefault();
    if (checkedEmail === false && email !== '') {
      if (email.indexOf('@') == '-1') {
        setEmail('');
      }
      setError('Please enter a valid email');
    } else if (checkedName == false && name !== '') {
      setError('Please enter a name without special characters');
    } else {
      setError('');
    }
  };

  const validateMessage = (event) => {
    event.preventDefault();
    if (message == '') {
      setError('Please enter a message');
    } else {
      setError('');
    }
  };

  const onClick = (event) => {
    if (hasBeenSubmitted == false) {
      if (checkedEmail === false) {
        setError('Please enter a valid email');
        setHasBeenSubmitted(false);
      } else if (checkedName == false) {
        setError('Please enter a name without special characters');
        setHasBeenSubmitted(false);
      } else if (checkedMenteeName == false) {
        setError('Please enter a mentee name without special characters');
        setHasBeenSubmitted(false);
      } else if (message == '') {
        setError('Please enter a message');
        setHasBeenSubmitted(false);
      } else {
        setError('');
        setHasBeenSubmitted(true);
        createReportAnEvent(
          {
            name,
            email,
            phone,
            mentee_name: menteeName,
            message,
          },
          () => {
            setView('success');
          }
        );
      }
    }
  };

  return (
    <div className='contact-us'>
      <HeaderLeftTextRight
        smallheadline={`Contact us`}
        headline={'Report an event'}
        para={``}
        actionContent={<></>}
        margBottom={`50px`}
      />
      <div className='container sign-in'>
        <div className='row h-100'>
          <div className='col-md-2 no-mobile'></div>
          <div className='col-md-8' style={{ top: '-75px' }}>
            {view === 'form' && (
              <div className='registrations-container'>
                <h5 className='text-center marg-top-20 marg-bottom-10'>Reportable Events Form</h5>
                <p className='text-center login-title marg-bottom-20'>
                  If a patient/caregiver indicates that they are experiencing a reportable event, please fill out the
                  form below immediately.
                </p>
                <div className={`material-input`} style={{ maxWidth: 'inherit' }}>
                  <input
                    className='autofocus'
                    type='text'
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    required
                  />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                  <label>Full Name</label>
                </div>
                <div className='material-input' style={{ maxWidth: 'inherit' }}>
                  <input
                    type='email'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onBlur={validate}
                    required
                  />
                  <span className='bar'></span>
                  <label>Email</label>
                </div>
                <>
                  <div className={`material-input`} style={{ maxWidth: 'inherit' }}>
                    <input
                      type='tel'
                      name='phone'
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      onBlur={validatePhoneOnBlur}
                      required
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label>Phone Number</label>
                  </div>
                  <ErrorMessage error={phoneError} />
                </>
                <div className={`material-input`} style={{ maxWidth: 'inherit' }}>
                  <input
                    type='text'
                    value={menteeName}
                    onChange={(event) => setMenteeName(event.target.value)}
                    required
                  />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                  <label>Mentee's User Name</label>
                </div>
                <div className={`material-input`} style={{ maxWidth: 'inherit' }}>
                  <textarea
                    style={{ maxWidth: 'inherit' }}
                    className={`material-input`}
                    placeholder={`Description of the event (please copy & paste the specific message)`}
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                    required
                  />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                </div>

                {error && <ErrorMessage error={error} />}
                <RequestButton
                  request={onClick}
                  recaptcha={true}
                  disabled={disabled}
                  classnames={'marg-top-10 submit'}
                  style={{ width: '100%' }}
                  cta={'Submit'}
                />
              </div>
            )}
            {view === 'success' && (
              <div className='registrations-container' style={{ padding: '70px' }}>
                <SuccessPanel
                  title='Thank you!'
                  content={
                    <>
                      <p style={{ marginBottom: '30px' }}>
                        <b style={{ color: '#59b6be' }}>WHAT'S NEXT?</b>
                      </p>
                      <p style={{ marginBottom: '30px' }}>
                        Thank you for completing the form as soon as possible. Please refrain from engaging with the
                        mentee until the PatientPartner team has notified you of the proper next steps. We will contact
                        you to obtain more information about the event if needed.
                      </p>
                      <p>
                        If you would like to contact us directly, please email us at{' '}
                        <a mailto={`report@patientpartner.com`}>report@patientpartner.com</a> or call us at 619-268-1112
                      </p>
                    </>
                  }
                  centerTitle
                />
              </div>
            )}
          </div>
        </div>
        <div className='col-md-2 no-mobile'></div>
      </div>
    </div>
  );
}
