import React, { useState, useEffect, useCallback } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import styles from './index.module.css';
import './index.css';

export default function ImageGridDisplay({ photos, setPhotos, type = 'grid', allowRemove = false }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  let removeItem = (index) => {
    setPhotos(photos.filter((img, idx) => index !== idx));
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  let photosForGallery = (imgArr) => {
    let imgs = [];
    imgArr.forEach((img) => {
      imgs.push({
        source: img,
      });
    });

    return imgs;
  };

  const photoWidth = (num) => {
    if (num > 4) {
      return 5;
    }
    return 4;
  };

  const renderPhoto = (i, style, width = false) => (
    <div
      onClick={() => openLightbox(i)}
      className={`${styles.img_uploaded} ${style}`}
      style={{
        backgroundImage: `url('${photosForGallery(photos)[i].source}')`,
        width: width ? 100 / width + '%' : null,
      }}
    >
      {allowRemove && (
        <img
          onClick={(e) => {
            removeItem(i);
            e.stopPropagation();
          }}
          className={`${styles.remove_img}`}
          src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg'
        />
      )}
    </div>
  );

  return (
    <div className={styles.gallery_cont} style={{ width: '100%', position: 'relative' }}>
      {photos.length > 0 && type === 'grid' && (
        <div>
          {photos.length === 1 && renderPhoto(0, styles.rectangle)}
          {photos.length === 2 && (
            <div style={{ display: 'flex' }}>
              {renderPhoto(0, styles.square)}
              {renderPhoto(1, styles.square)}
            </div>
          )}
          {photos.length === 3 && (
            <>
              <div style={{ display: 'flex' }}>{renderPhoto(0, styles.rectangle)}</div>
              <div style={{ display: 'flex' }}>
                {renderPhoto(1, styles.square)}
                {renderPhoto(2, styles.square)}
              </div>
            </>
          )}
          {photos.length === 4 && (
            <>
              <div style={{ display: 'flex' }}>{renderPhoto(0, styles.rectangle)}</div>
              <div style={{ display: 'flex' }}>
                {renderPhoto(1, styles.square_3)}
                {renderPhoto(2, styles.square_3)}
                {renderPhoto(3, styles.square_3)}
              </div>
            </>
          )}
          {photos.length >= 5 && (
            <>
              <div style={{ display: 'flex' }}>
                {renderPhoto(0, styles.square)}
                {renderPhoto(1, styles.square)}
              </div>
              <div style={{ display: 'flex' }}>
                {renderPhoto(2, styles.square_3)}
                {renderPhoto(3, styles.square_3)}
                {renderPhoto(4, styles.square_3)}
              </div>
            </>
          )}
          {photos.length > 5 && (
            <div className={styles.gallery_count_wrap}>
              <p className={styles.gallery_count}>+{photos.length - 5}</p>
            </div>
          )}
        </div>
      )}
      {photos.length > 0 && type === 'inline' && (
        <div>
          <div style={{ display: 'flex' }}>
            {photos.map((photo, i) => i < 5 && renderPhoto(i, styles.square_inline, photoWidth(photos.length)))}
          </div>
          {photos.length > 5 && (
            <div className={`${styles.gallery_count_wrap} ${styles.gallery_count_wrap_inline}`}>
              <p className={`${styles.gallery_count} ${styles.gallery_count_inline}`}>+{photos.length - 5}</p>
            </div>
          )}
        </div>
      )}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel currentIndex={currentImage} views={photosForGallery(photos)} />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}
