import React, { useState } from 'react';
import RequestButton from '../ui_kit/utilities/request_button';
import InputWithOptions from '../ui_kit/utilities/input_with_options';

export default function WhatConditions({
  defaultConditions = '',
  onSubmit,
  onBack,
  choices,
  label = 'Select a type of specialist',
  head = 'What kind of specialist are you looking for today?',
  head1,
}) {
  const [condition, setCondition] = useState(defaultConditions);

  const onClick = () => {
    onSubmit(condition);
  };
  const onBackClick = () => {
    onBack();
  };

  const disabled = condition !== '' ? '' : 'disabled';
  return (
    <div className='row'>
      <div className='col-md-2'></div>
      <div className='col-md-8'>
        <h5 className='mobile-marg-20 marg-bottom-30'>{head}</h5>
        <h5 className='mobile-marg-20 marg-bottom-40'>{head1}</h5>
        <InputWithOptions
          value={condition}
          setValue={setCondition}
          options={choices}
          name={''}
          label={label}
          showOptionsFromStart
          fullWidth
        />
        <div className='row button-holder'>
          <button className='back col-6' type='button' onClick={onBackClick}>
            Back
          </button>
          <RequestButton
            request={onClick}
            disabled={disabled}
            classnames={'gtm-preexisting-conditions submit col-6'}
            cta={`Next`}
          />
        </div>
      </div>
      <div className='col-md-2'></div>
    </div>
  );
}
