import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { autofocusAll } from '../../helpers';
import './index.css';
import Context from '../../utils/context';
import { validateEmail } from '../../helpers';
import RequestButton from '../ui_kit/utilities/request_button';

export default function UsersPasswordRecover({ defaultEmail }) {
  const [email, setEmail] = useState(defaultEmail || '');
  const context = useContext(Context);
  const [message, setMessage] = useState('');

  let disabled = 'disabled';
  var checkedEmail = validateEmail(email);

  if (checkedEmail === true) {
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  let history = useHistory();

  useEffect(() => {
    autofocusAll();
  }, []);

  const onClick = (afterAction) => {
    context.auth.userRecoverPassword(
      email,
      () => {
        setMessage('If an account with that email exists, we will send over instructions for resetting your password');
        afterAction();
      },
      () => {
        setMessage('If an account with that email exists, we will send over instructions for resetting your password');
        afterAction();
      }
    );
    setMessage('');
  };

  const validate = (event) => {
    event.preventDefault();

    if (checkedEmail === false && email !== '') {
      setMessage('Please enter a valid email');
    } else {
      setMessage('');
    }
  };

  return (
    <>
      <div className='container sign-in'>
        <div className='row h-100'>
          <div className='col-md-3 no-mobile'></div>
          <div className='col-md-6 mobile-margins'>
            <div className='registrations-container'>
              {message === '' ? (
                <>
                  <h5 className='text-center marg-top-20 marg-bottom-10'>Forgot your password?</h5>
                  <p className='text-center login-title marg-bottom-20'>
                    No problem! We’ll email you a link to create a new password.
                  </p>
                  <div className='material-input'>
                    <input
                      type='email'
                      name='email'
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      onBlur={validate}
                      required
                    />
                    <span className='bar'></span>
                    <label>Email address</label>
                  </div>
                  <RequestButton
                    request={onClick}
                    disabled={disabled}
                    classnames={'marg-top-20 marg-center'}
                    style={{ width: '100%' }}
                    cta={'Send password reset link'}
                  />
                  <Link
                    style={{ display: 'block', color: '#173959', margin: '20px auto 10px auto', width: 'max-content' }}
                    className='text-center'
                    to='/member/login'
                  >
                    Back to login
                  </Link>
                </>
              ) : (
                <>
                  {message !== '' && <p>{message}</p>}
                  <Link
                    style={{ display: 'block', color: '#173959', margin: '20px auto 10px auto', width: 'max-content' }}
                    className='text-center'
                    to='/member/login'
                  >
                    <button>Back to login</button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='col-md-3 no-mobile'></div>
      </div>
    </>
  );
}
