import React, { useState } from 'react';
import ChooseManyOptionsWithNone from './../choose_many_options_with_none';

export default function UterineFibroidsSymptoms({
  answers,
  onSubmit,
  onBack,
  ctaPreConditions = 'Next',
  gtm,
  options = [
    'Painful or heavy periods',
    'Cramping or Pelvic Discomfort',
    'Bloating',
    'Bladder Problems',
    'Low Back Pain',
    'None Of These',
  ],
  title = 'What symptoms have you been experiencing?',
  para = 'Select any that apply.',
}) {
  return (
    <ChooseManyOptionsWithNone
      answers={answers}
      keyForAnswer={`symptoms`}
      onSubmit={onSubmit}
      onBack={onBack}
      gtm={`${gtm} Symptoms`}
      options={options}
      title={title}
      para={para}
    />
  );
}
