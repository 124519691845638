import React, { useContext, useState, useEffect } from 'react';
import styles from './index.module.css';
import parentStyles from '../../index.module.css';
import Context from '../../../../utils/context';
import GhostButton from '../../../ui_kit/utilities/ghost_button';
import RequestButton from '../../../ui_kit/utilities/request_button';
import { successToast } from '../../../ui_kit/utilities/success_toast';
import InputWithOptions from '../../../ui_kit/utilities/input_with_options';
import ErrorMessage from '../../../ui_kit/utilities/error_message';
import Divider from '../../../ui_kit/utilities/divider';
import BedsideManner from '../../../bedside_manner';
import {
  addProfileInsuranceCarrier,
  addProfileMedicalProvider,
  deleteProfileInsuranceCarrier,
  deleteProfileMedicalProvider,
  getProfileInsuranceCarriers,
  getProfileMedicalProviders,
  updateProfile,
  updateProfileInsuranceCarrier,
  updateProfileMedicalProvider,
} from '../../../../api/profiles';
import { createActivity } from '../../../../api/activities';
import ProfileInsRow from './profile_ins_row';

export default function ProfileInsurance({ user }) {
  const context = useContext(Context);
  const currentUser = context.auth.getCurrentUser();
  const [editing, setEditing] = useState(false);
  const [profileInsuranceCarriers, setProfileInsuranceCarriers] = useState(
    user.current_profile.other_data.insurance_carriers || []
  );
  const [profileMedicalProviders, setProfileMedicalProviders] = useState([]);
  const [insuranceCarrier, setInsuranceCarrier] = useState(user.current_profile.other_data.insurance_carrier || '');
  const [insurancePolicy, setInsurancePolicy] = useState(user.current_profile.other_data.insurance_policy || '');
  const [insurancePrivate, setInsurancePrivate] = useState(user.current_profile.other_data.insurance_private || false);
  const [bedsideManner, setBedsideManner] = useState(user.current_profile.preferred_bedside_manner);
  const [providers, setProviders] = useState([]);
  const [profile, setProfile] = useState(user.current_profile);
  let insurances = [...gon.all_insurances];
  let allProviders = gon.all_providers;

  let profileIsCurrentUser = false;
  if (
    context.auth.isLoggedIn() &&
    currentUser.current_profile &&
    profile &&
    currentUser.current_profile.id === profile.id
  ) {
    profileIsCurrentUser = true;
  }

  let error = '';

  useEffect(() => {
    if (!user.current_profile) {
      return;
    }
    getProfileMedicalProviders(user.profile_id, (data) => {
      setProfileMedicalProviders(data);
    });
  }, [user.current_profile, user.profile_id]);

  if (!user.current_profile) {
    return <></>;
  }

  const update = (afterAction) => {
    updateProfile(
      user.profile_id,
      {
        insurance_policy: insurancePolicy,
        insurance_carriers: insuranceCarrier ? [insuranceCarrier] : [],
        preferred_bedside_manner: bedsideManner,
      },
      (data) => {
        if (providers.length > 0) {
          addProfileMedicalProvider({ id: user.profile_id, surgeons: providers, private: true }, (data) => {
            setProfileMedicalProviders(data);
            setProviders([]);
            successToast('Changes saved.');
            setEditing(false);
            afterAction();
          });
        } else {
          successToast('Changes saved.');
          setEditing(false);
          afterAction();
        }
        createActivity('Activity::EditProfile', { fields: 'edit_insurance' });
      }
    );
  };

  let disabled = true;
  if (profileInsuranceCarriers.length > 0) {
    if (
      insuranceCarrier !== profileInsuranceCarriers[0].carrier ||
      insurancePolicy !== profileInsuranceCarriers[0].policy
    ) {
      disabled = false;
    }
  } else {
    if (insuranceCarrier !== '' && insurancePolicy !== '') {
      disabled = false;
    }
  }
  if (bedsideManner !== user.current_profile.preferred_bedside_manner) {
    disabled = false;
  }
  if (providers.length > 0) {
    disabled = false;
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <h2 className={styles.profile_section_headline}>Insurance &amp; doctors</h2>
        {currentUser && currentUser.current_profile && profile && (
          <>
            {profileIsCurrentUser && !editing && (profile.bio !== '' || profile.bio === null) && (
              <GhostButton
                data-pendo-tag='profile insurance and doctors - edit button'
                onClick={() => setEditing(true)}
                style={{ marginLeft: 'auto' }}
              >
                <img
                  style={{ marginRight: '8px', height: '16px', marginTop: '-4px' }}
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/edit.svg'
                />
                <span>Edit</span>
              </GhostButton>
            )}
            {profileIsCurrentUser && editing && (
              <>
                <GhostButton
                  style={{ marginLeft: 'auto', padding: '5px 23px', marginBottom: '10px' }}
                  onClick={() => setEditing(false)}
                >
                  <p>Cancel</p>
                </GhostButton>
                <RequestButton
                  style={{ padding: '5px 23px', marginBottom: '10px' }}
                  cta={'Save'}
                  request={(afterAction) => update(afterAction)}
                  disabled={disabled}
                />
              </>
            )}
          </>
        )}
      </div>
      <div className={`content_container profile_ins_finder`}>
        {editing ? (
          <>
            <p className={`${styles.label} marg-top-0`}>Enter your insurance carrier</p>
            <InputWithOptions
              value={insuranceCarrier}
              setValue={setInsuranceCarrier}
              options={insurances}
              name={'insuranceCarrier'}
              label={'Insurance Provider'}
              noMatchTxt={"I don't see my insurance provider"}
              wrapperClassnames={styles.input_w_options}
              inputClassnames={styles.input_w_options_input}
              light={false}
              placeholder='Aetna, Blue Cross, etc...'
              defaultValue={insuranceCarrier}
            />
            <ErrorMessage error={error} />
            <p className={`${styles.label} marg-top-15`}>Member ID</p>
            <div className={`${styles.input_w_options} material-input marg-top-0`}>
              <input
                defaultValue={insurancePolicy}
                placeholder='Enter member ID'
                onChange={(e) => setInsurancePolicy(e.target.value)}
              ></input>
            </div>
          </>
        ) : (
          <div className={styles.bio}>
            {insuranceCarrier ? (
              <ProfileInsRow
                user={user}
                insuranceCarrier={insuranceCarrier}
                setInsuranceCarrier={setInsuranceCarrier}
                insurancePolicy={insurancePolicy}
                setInsurancePolicy={setInsurancePolicy}
                insurancePrivate={insurancePrivate}
                setInsurancePrivate={setInsurancePrivate}
                profileIsCurrentUser={profileIsCurrentUser}
                editing={editing}
              />
            ) : (
              <>
                {profileIsCurrentUser ? (
                  <p
                    data-pendo-tag='profile page - health profile - add insurance carrier'
                    onClick={() => setEditing(true)}
                    className={styles.add_bio}
                  >
                    + Add an insurance carrier
                  </p>
                ) : (
                  <p className={`${parentStyles.bio}`}>No insurance doctors shared</p>
                )}
              </>
            )}
          </div>
        )}
        <Divider />
        {editing ? (
          <>
            <p className={`${styles.label} marg-top-0 `}>Slide to select your preferred bedside manner</p>
            <BedsideManner
              bedsideManner={bedsideManner}
              setBedsideManner={setBedsideManner}
              wrapperClassnames={styles.de_pad_cont}
              pendoTag='health profile - bedside manner'
            />
          </>
        ) : (
          <div className={`${styles.bio} marg-top-15`}>
            {bedsideManner == null ? (
              <>
                {profileIsCurrentUser ? (
                  <p onClick={() => setEditing(true)} className={styles.add_bio}>
                    + Add your preferred surgeon bedside manner
                  </p>
                ) : (
                  <p className={`${parentStyles.bio} marg-top-15`}>No preferred bedside manner shared</p>
                )}
                <Divider />
              </>
            ) : (
              <>
                {profileIsCurrentUser || user.current_profile.show_preferred_bedside_manner ? (
                  <BedsideManner bedsideManner={bedsideManner} slide={false} wrapperClassnames={styles.de_pad_cont} />
                ) : (
                  <p className={`${parentStyles.bio} marg-top-15`}>No bedside manner shared</p>
                )}
                <Divider />
              </>
            )}
          </div>
        )}
        {editing ? (
          <>
            <p className={`${styles.label} marg-top-0`}>Add a doctor</p>
            <InputWithOptions
              value={''}
              setValue={(v) => setProviders([...providers, { surgeon_name: v }])}
              options={
                providers.length === 0 && profileMedicalProviders.length === 0
                  ? allProviders
                  : allProviders.filter(
                      (p) =>
                        providers.map((p) => p.surgeon_name).indexOf(p) === -1 &&
                        profileMedicalProviders.map((p) => p.surgeon_name).indexOf(p) === -1
                    )
              }
              name={'MedicalProvider'}
              label={''}
              wrapperClassnames={styles.input_w_options}
              inputClassnames={styles.input_w_options_input}
              light={false}
              placeholder='Dr. John Doe'
              searchAndPush={true}
            />
            <ErrorMessage error={error} />
            {providers.length > 0 && (
              <>
                <p style={{ marginBottom: '-22px' }} className={`${styles.label} marg-top-15`}>
                  New
                </p>
                 
                <div className={styles.provider_cont}>
                  {providers.map((provider, i) => (
                    <p key={i} className={`${styles.provider_tab} ${styles.editing}`}>
                      {provider.surgeon_name}
                    </p>
                  ))}
                </div>
              </>
            )}
            {profileMedicalProviders.length > 0 && (
              <>
                <p style={{ marginBottom: '-2px' }} className={`${styles.label} marg-top-15`}>
                  Currently On Profile
                </p>
                <div className={styles.provider_cont}>
                  {profileMedicalProviders.map((provider, i) => (
                    <p key={i} className={`${styles.provider_tab} ${styles.editing}`}>
                      {provider.surgeon_name}
                      <img
                        style={{ marginLeft: '10px' }}
                        onClick={() =>
                          updateProfileMedicalProvider(
                            user.profile_id,
                            provider.id,
                            { private: !provider.private },
                            (data) => setProfileMedicalProviders(data)
                          )
                        }
                        src={
                          provider.private
                            ? 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/eye-off.svg'
                            : 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/eye.svg'
                        }
                      />
                      <img
                        onClick={() =>
                          deleteProfileMedicalProvider(user.profile_id, provider.id, (data) => {
                            setProfileMedicalProviders(data);
                            successToast('Doctor successfully removed.');
                          })
                        }
                        style={{ marginLeft: '10px' }}
                        src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/trash.svg'
                      />
                    </p>
                  ))}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className={styles.bio}>
              {profileMedicalProviders !== null && profileMedicalProviders.length !== 0 ? (
                <>
                  <p
                    className={`${styles.label} marg-top-0`}
                  >{`Your doctors${profileMedicalProviders.length > 1 ? 's' : ''}`}</p>
                  <div className={styles.provider_cont}>
                    {profileMedicalProviders.map((provider, i) => (
                      <p key={i} className={`${styles.provider_tab}`}>
                        {provider.surgeon_name}
                        <img
                          style={{ marginLeft: '10px' }}
                          onClick={() =>
                            updateProfileMedicalProvider(
                              user.profile_id,
                              provider.id,
                              { private: !provider.private },
                              (data) => setProfileMedicalProviders(data)
                            )
                          }
                          src={
                            provider.private
                              ? 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/eye-off.svg'
                              : 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/eye.svg'
                          }
                        />
                        <img
                          onClick={() =>
                            deleteProfileMedicalProvider(user.profile_id, provider.id, (data) => {
                              setProfileMedicalProviders(data);
                              successToast('Doctor successfully removed.');
                            })
                          }
                          style={{ marginLeft: '10px' }}
                          src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/trash.svg'
                        />
                      </p>
                    ))}
                  </div>
                </>
              ) : profileIsCurrentUser ? (
                <p
                  data-pendo-tag='profile page - health profile - add a doctor'
                  onClick={() => setEditing(true)}
                  className={`${styles.add_bio} marg-top-15`}
                >
                  + Add a doctor
                </p>
              ) : (
                <>
                  {profileMedicalProviders.length > 0 ? (
                    <>
                      {profileMedicalProviders.map((provider, i) => (
                        <p key={i} className={`${styles.provider_tab}`}>
                          {provider.surgeon_name}
                        </p>
                      ))}
                    </>
                  ) : (
                    <p className={`${parentStyles.bio} marg-top-15`}>No medical doctors shared</p>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
