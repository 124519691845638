import React, { useState } from 'react';
import styles from './index.module.css';
import { useTransition, useChain, animated, useSpringRef } from '@react-spring/web';
import ScrollTrigger from '../ui_kit/utilities/scroll_trigger';

export default function FeaturedInMedia({
  images = [
    'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/forbes.jpg',
    'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/abc.jpg',
    'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/yahoo_a.jpg',
    'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/nbc_a.jpg',
  ],
  colMdClass = 'col-md-2',
  title = 'Featured in',
  imagesWithLinks = null,
}) {
  // animation
  const [animate, setAnimate] = useState(false);
  const dropIconAnimation = useSpringRef();
  const dropdown = useTransition(animate ? imagesWithLinks || images : [], {
    ref: dropIconAnimation,
    config: { mass: 1, tension: 500, friction: 30 },
    from: { opacity: 0, transform: 'translateY(-10px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    trail: 100,
  });

  useChain([dropIconAnimation], [0.1]);
  // animation

  return (
    <div className={`container-fluid ${styles.featured_in}`}>
      <div className='container'>
        <div className='row h-100'>
          <div className='col-sm-1'></div>
          <div className='col-sm-2 my-auto'>
            <p className={`${styles.fi_text}`}>{title}:</p>
          </div>
          {imagesWithLinks &&
            dropdown((style, image) => (
              <div className={`col-6 ${colMdClass} my-auto`}>
                <a href={image.link}>
                  <animated.div style={{ ...style }} className='dropdown'>
                    <img alt={`featured in media icon`} className={styles.img} src={image.image} />
                  </animated.div>
                </a>
              </div>
            ))}
          {!imagesWithLinks &&
            dropdown((style, image) => (
              <div className={`col-6 ${colMdClass} my-auto`}>
                <animated.div style={{ ...style }} className='dropdown'>
                  <img alt={`featured in media icon`} className={styles.img} src={image} />
                </animated.div>
              </div>
            ))}
          <div className='col-sm-1'></div>
        </div>
      </div>
      <ScrollTrigger callback={() => setAnimate(true)} />
    </div>
  );
}
