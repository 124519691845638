import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from '../utils/auth';

const RestrictedRoute = ({ component: Component, restrictedTypes = [], redirectPath = '/member/login', ...rest }) => {
  const auth = new Auth(gon.current_user || {});
  const currentUser = auth.getCurrentUser();
  const isRestricted = restrictedTypes.some((type) => currentUser[type]);

  return (
    <Route {...rest} render={(props) => (!isRestricted ? <Component {...props} /> : <Redirect to={redirectPath} />)} />
  );
};

export default RestrictedRoute;
