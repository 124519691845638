import React, { Suspense, useContext } from 'react';
import TextLeftImgRightWbg from '../../hero_sections/txt_left_img_right_wbg';
import FeaturedInMedia from '../../featured_in_media';
import AboutHeadlineThreeIcons from '../../about_headline_three_icons';
import ImgLeftTextRight from '../../hero_sections/img_left_text_right';
import EmailSignupFooter from '../../email_signup_footer';
import HowItWorks from '../../hero_sections/how_it_works';
import { autofocusAll } from '../../../helpers';
import Context from '../../../utils/context';
import './index.css';
import ImgRTxtLSimple from '../../hero_sections/img_r_txt_l_simple';
const SurgeonMatchQuestionnaire = React.lazy(() => import('../../questionnaire/surgeon_match'));

export default function SpineSurgerySurgeonMatch({
  home,
  updateAnswersAction,
  setContactIdAction,
  setSurgeonMatchQuestionnaireProgress,
  setSurgeon,
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  const scrollToQuestionnaire = () => {
    document.querySelector('#questionnaire').scrollIntoView({
      behavior: 'smooth',
    });
    const timer = setTimeout(() => {
      autofocusAll();
    }, 1000);
    return () => clearTimeout(timer);
  };

  return (
    <>
      <TextLeftImgRightWbg
        headline='Connect to the best specialist'
        para='Chat directly with a specialist in your area and start learning about your pain management options.'
        bg='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/spine-surgery/headerimg.jpg'
        actionContent={
          <div className='btn-wrap'>
            <button
              onClick={scrollToQuestionnaire}
              className='maincta'
              data-gtm-click-label='Spine Surgery Surgeon Match Get Started'
            >
              Get started
            </button>
          </div>
        }
        imgOffset={false}
        imgShadow={false}
        topOfIcon={'75%'}
        heightOfImage={isMobile ? '300px' : '500px'}
        headlineFontSize={'58px'}
        iconContent={
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 132.05 163.45'>
            <g id='Layer_2' data-name='Layer 2'>
              <g id='Layer_1-2' data-name='Layer 1'>
                <path
                  fill='#74ccd3'
                  className='cls-1'
                  d='M131.9,78.63V38.83c0-5.75-.43-11.32-2.66-16.73A36,36,0,0,0,108.2,2C103.11.19,97.89,0,92.57,0H37.07C19.79,0,3.91,11.21.67,28.77-.11,33,0,37.29,0,41.59V61.06C0,72.67,0,84.29,0,95.9c.08,19.16,15.19,35.35,34.54,35.94,2.44.08,4.87,0,7.31,0l.25,0a3,3,0,0,0,2.7-1.5l.12-.13h0L68,99.44c3.92-.87,7.16-4,9.32-7.57C83.79,81.23,89.81,70.31,96,59.54c2.88-5,6.84-12.13,3.58-17.86-2.52-4.47-8.3-4.24-13.27-4a6.26,6.26,0,1,0,.09,6.69h1.1c1.58,0,5.4-.81,6.45.57s-.46,5.17-1.06,6.55a49.19,49.19,0,0,1-2.78,5.15L73.91,84.77c-1.7,2.93-3.61,7.87-7.4,8.37-3.94.52-6.06-4.15-7.69-7l-16.18-28c-1.87-3.24-6.08-8.58-4.58-12.46.87-2.26,4.84-1.28,6.77-1.28h.23a6.25,6.25,0,0,0,11.59-3.26h0a6.25,6.25,0,0,0-11.59-3.26H43.71c-3.09,0-6.29-.29-8.93,1.61-5.19,3.71-3.41,11.19-1.1,16,2.43,5.11,5.63,9.94,8.46,14.83L52.58,88.43c2.15,3.72,4.36,7.81,8.18,9.9l-20.25,27c-8,0-15.43-.65-22.22-5.68A29.34,29.34,0,0,1,6.57,96c-.1-12.3,0-24.6,0-36.9V39.68c0-5.9.25-11.59,3-17A29.4,29.4,0,0,1,35.49,6.54c3.51,0,7,0,10.52,0H89.68c4.63,0,9.34-.27,13.86.89a29.34,29.34,0,0,1,21.14,21.79c1,4.36.68,9,.68,13.44V64c0,11.24.45,22.57,0,33.81a29.25,29.25,0,0,1-17.26,25.11c-7.5,3.34-14.6,1.66-22.39,2.44-2.32.24-3.06,1.54-4.14,3.29l-4.73,7.68L65.62,154.48l-13-16.71c-2.58-3.31-7.16,1.34-4.62,4.62l15.68,20.08c1.35,1.73,4.14.95,5.13-.65L87.22,131.9c5.73.07,11.63.36,17.21-.92a35.81,35.81,0,0,0,23.17-17.71C133.31,102.72,131.9,90.2,131.9,78.63Z'
                />
              </g>
            </g>
          </svg>
        }
      />
      <FeaturedInMedia />
      <AboutHeadlineThreeIcons
        headline='There is no one-size-fits-all approach to pain management. Find a solution that’s right for you by connecting with a top-tier specialist.'
        smHeadline='3 facts about surgery:'
        infoTiles={[
          {
            icon: (
              <svg xmlns='http://www.w3.org/2000/svg' width='76.838' height='48.897' viewBox='0 0 76.838 48.897'>
                <path
                  id='Icon_material-people-outline'
                  data-name='Icon material-people-outline'
                  d='M55.636,35.441a41.865,41.865,0,0,0-15.717,3.493A41.2,41.2,0,0,0,24.2,35.441c-7.579,0-22.7,3.772-22.7,11.351v9.6H78.338v-9.6C78.338,39.213,63.215,35.441,55.636,35.441ZM41.665,51.158H6.739V46.792c0-1.886,8.941-6.112,17.463-6.112s17.463,4.226,17.463,6.112Zm31.434,0H46.9V46.792a6.355,6.355,0,0,0-1.816-4.261A33.7,33.7,0,0,1,55.636,40.68c8.522,0,17.463,4.226,17.463,6.112ZM24.2,31.948A12.224,12.224,0,1,0,11.978,19.724,12.238,12.238,0,0,0,24.2,31.948Zm0-19.209a6.985,6.985,0,1,1-6.985,6.985A7.006,7.006,0,0,1,24.2,12.739ZM55.636,31.948A12.224,12.224,0,1,0,43.411,19.724,12.238,12.238,0,0,0,55.636,31.948Zm0-19.209a6.985,6.985,0,1,1-6.985,6.985A7.006,7.006,0,0,1,55.636,12.739Z'
                  transform='translate(-1.5 -7.5)'
                  fill='#59B6BE'
                />
              </svg>
            ),
            title: 'Learn about non-surgical options.',
          },
          {
            icon: (
              <svg
                className='beni-icon'
                xmlns='http://www.w3.org/2000/svg'
                width='51.865'
                height='55.936'
                viewBox='0 0 51.865 55.936'
              >
                <path
                  id='Icon_ionic-ios-thumbs-up'
                  data-name='Icon ionic-ios-thumbs-up'
                  d='M50.775,46.316a5.116,5.116,0,0,0,2.1-3.957,5.532,5.532,0,0,0-1.374-3.667c-.076-.151-.139-.29-.2-.428l-.025-.139a1.645,1.645,0,0,1,.252-1.26,5.524,5.524,0,0,0,.731-3.239A3.829,3.829,0,0,0,51.04,30.7a2.4,2.4,0,0,1-.517-2.306,4.86,4.86,0,0,0-1.033-4.763c-1.323-1.487-2.558-1.663-5.847-1.474-2.268.139-6.994.87-10.107,1.361-1.273.2-2.382.378-2.722.4-1.6.164-1.915,0-2.067-.617a4.086,4.086,0,0,1,.617-1.638,27.024,27.024,0,0,0,1.827-4.511c1.273-4.184,1.134-8.72-.34-11.367a4.118,4.118,0,0,0-3.314-2.407c-1.449,0-2.936.3-3.516,1-.428.5-.29,1.462-.781,3.377a36.743,36.743,0,0,1-1.512,5.293c-.945,2.092-5,5.683-7.183,7.611-.517.454-.958.844-1.273,1.147a25.975,25.975,0,0,0-3.213,4.121,21.935,21.935,0,0,1-1.726,2.331,4.552,4.552,0,0,1-2.81,1.374A1.011,1.011,0,0,0,4.514,30.64v20.1A1.043,1.043,0,0,0,5.56,51.785a19.859,19.859,0,0,1,8.368,1.487A21.778,21.778,0,0,0,19.775,54.8a121.045,121.045,0,0,0,14.19,1.021,62.8,62.8,0,0,0,7.133-.491c3.907-.454,8.09-1.361,9.476-4.663a3.559,3.559,0,0,0-.063-2.987.991.991,0,0,1-.1-.328A1.068,1.068,0,0,1,50.775,46.316Z'
                  transform='translate(-2.764 -1.632)'
                  fill='none'
                  stroke='#59B6BE'
                  strokeWidth='3.5'
                />
              </svg>
            ),
            title: 'Understand your pain relief options.',
          },
          {
            icon: (
              <svg
                className='beni-icon'
                xmlns='http://www.w3.org/2000/svg'
                width='56.436'
                height='56.436'
                viewBox='0 0 56.436 56.436'
              >
                <path
                  id='Icon_map-health'
                  data-name='Icon map-health'
                  d='M53.156,18.2a1.1,1.1,0,0,0-1.092-1.092h-14.2a1.1,1.1,0,0,1-1.092-1.092V1.812A1.1,1.1,0,0,0,35.677.72H18.2a1.1,1.1,0,0,0-1.092,1.092v14.2a1.1,1.1,0,0,1-1.092,1.092H1.812A1.1,1.1,0,0,0,.72,18.2V35.677A1.1,1.1,0,0,0,1.812,36.77h14.2a1.1,1.1,0,0,1,1.092,1.092v14.2A1.1,1.1,0,0,0,18.2,53.156H35.677a1.1,1.1,0,0,0,1.092-1.092v-14.2a1.1,1.1,0,0,1,1.092-1.092h14.2a1.1,1.1,0,0,0,1.092-1.092Z'
                  transform='translate(1.28 1.28)'
                  fill='none'
                  stroke='#59B6BE'
                  strokeWidth='4'
                />
              </svg>
            ),
            title: 'See if surgery could be an option.',
          },
        ]}
      />
      <ImgLeftTextRight
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/spine-surgery/first-hero.jpg'
        headline='You should see a pain specialist when:'
        list={[
          <span>
            Your back <b>pain is getting worse</b> and not better.
          </span>,
          <span>
            Walking, standing, or doing <b>every day activities is hard.</b>
          </span>,
          <span>
            You <b>develop neck issues</b> or the pain worsens with activity.
          </span>,
          <span>
            You feel <b>numbness, weakness, or tingling</b> in your limbs.
          </span>,
          <span>
            You have sudden <b> bowel and bladder problems.</b>
          </span>,
        ]}
        bg='none'
        blueBg={false}
        cta={
          <button data-gtm-click-label='Spine Surgeon Match Find a Surgeon' onClick={scrollToQuestionnaire}>
            Connect with a specialist
          </button>
        }
      />
      <ImgRTxtLSimple
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/spine-surgery/secondhero.jpg'
        mediumheadline='We find the best care for you:'
        list={[
          <span>Find a specialist who accepts your insurance.</span>,
          <span>Request a referral and instantly connect to ask questions.</span>,
          <span>Speak with recovered patients for advice, personal insight, and doctor reviews.</span>,
        ]}
        bg='none'
        blueBg={false}
        cta={
          <button data-gtm-click-label='Spine Surgeon Match Find a Surgeon' onClick={scrollToQuestionnaire}>
            Find a specialist
          </button>
        }
      />
      <HowItWorks
        headline={
          <h2>
            Getting <span className='how-it-works headline highlight'>connected to the right specialist</span> is free
            and easy:
          </h2>
        }
        smallHeadlineStyle={{
          fontFamily: 'HK Grotesk',
          lineHeight: '23px',
          fontSize: '18px',
          color: '#59B6BE',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          letterSpacing: '0.15em',
        }}
        steps={[
          {
            image: <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/about.svg' />,
            headline: 'Sign up for a free account',
            para: 'Join the PatientPartner platform that’s easy and free to use.',
          },
          {
            image: <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/finasurgeon.svg' />,
            headline: 'Send an inquiry',
            para: 'Instantly send a message to a specialist that’s covered by your insurance',
          },
          {
            image: <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/request.svg' />,
            headline: 'Learn about your options',
            para: 'See what steps you can take to finally find your pain relief.',
          },
        ]}
        paraColor='#232323'
      />
      <div
        id='questionnaire'
        className='container-fluid pad-cont'
        style={{
          backgroundColor: 'rgb(245, 247, 249)',
          alignContent: 'center',
          padding: '0px',
          borderRadius: '8px',
          width: '96%',
        }}
      >
        <div className='row justify-content-center h-100'>
          <div className='col my-auto'>
            <Suspense fallback={<p>loading</p>}>
              <SurgeonMatchQuestionnaire
                globalAnswers={{ ...home.answers, selectedProcedures: ['Spine Surgery'] }}
                setGlobalAnswers={updateAnswersAction}
                progress={home.surgeonMatchQuestionnaireProgress}
                setProgress={setSurgeonMatchQuestionnaireProgress}
                contactId={home.contactId}
                surgeon={home.surgeon}
                setSurgeon={setSurgeon}
                setContactIdAction={setContactIdAction}
                predefinedProcedures={['Spine Surgery']}
                predefinedSurgeries={["I'm not sure"]}
                gtm='Spine Surgery Surgeon Match'
                questionnaireHead='Connect to the best specialist'
                removeUnnecessaryQuestions
                newFlow
                skipAppointmentRequest
                dateBirthBeforeInsurance
                skipPassword
              />
            </Suspense>
          </div>
        </div>
      </div>
      <EmailSignupFooter
        headline='Want to learn more about back pain and treatment options?'
        para='Receive a free guide to learn more about back pain treatment and surgery options.'
        successMessage='Check your email inbox for information about spine surgery!'
        type='DownloadGuideContacts::SpineSurgerySurgeonMatch'
        gtm='Spine Surgeon Match Email Footer'
      />
    </>
  );
}
