import React, { useEffect, useState } from 'react';
import ChooseManyOptionsWithNone from '../choose_many_options_with_none';

export default function PreviousSurgeries({ answers, onSubmit, onBack, gtm }) {
  return (
    <ChooseManyOptionsWithNone
      answers={answers}
      keyForAnswer={`previousSurgeries`}
      onSubmit={onSubmit}
      onBack={onBack}
      gtm={`${gtm} Previous Surgeries`}
      options={['Knee replacement', 'Rotator cuff repair', 'ACL reconstruction', 'Hip replacement', 'None Of These']}
      title={`Have you had any of these procedures before?`}
      para={`We’ll match you with someone who’s also had similar procedures. Select all that apply.`}
    />
  );
}
