import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PatientPartnerQuestionnaire from '../../questionnaire/patient_partner';
import { autofocusAll } from '../../../helpers';
import './index.css';

export default function GetStartedPatientPartnerMatch({
  home,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setContactIdAction,
  setPatientPartners,
}) {
  useEffect(() => {
    autofocusAll();
  }, []);
  let history = useHistory();

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <>
      <div style={{ minHeight: '200px', marginTop: '0px' }}>
        <div id='questionnaire' className='marg-bottom-20'>
          <div className='row justify-content-center h-100'>
            <div className='col my-auto'>
              <PatientPartnerQuestionnaire
                globalAnswers={home.answers}
                setGlobalAnswers={updateAnswersAction}
                progress={home.patientPartnerQuestionnaireProgress}
                setProgress={setPatientPartnerQuestionnaireProgress}
                contactId={home.contactId}
                setContactIdAction={setContactIdAction}
                patientPartners={home.patientPartners}
                setPatientPartners={setPatientPartners}
                includeBackButton
                directConnection={true}
                gtm={`Get Started`}
                origin={`Get Started`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
