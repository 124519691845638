import React from 'react';
import { toSentTime, toSentDay } from '../../../helpers/index';

export default function ConversationsListItem({ selectedConversation, conversation }) {
  const hasUnreadMessages = conversation.unread_messages > 0;

  return (
    <>
      <div className={`message-in-list ${conversation.uuid === selectedConversation ? 'selected' : ''}`}>
        <div className='container'>
          <div className='row'>
            <div className='col-2 my-auto de-pad'>
              <div
                className={
                  conversation.other_user_conversations[0].profile_type === 'SuperUser'
                    ? 'chat-room-super-user-avatar'
                    : 'chat-room-pp-avatar'
                }
                style={
                  conversation.other_user_conversations[0].profile.type === 'SurgeonAdministrativeAssistant'
                    ? {
                        backgroundImage: `url(${conversation.other_user_conversations[0].profile.photo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                      }
                    : { backgroundImage: `url(${conversation.other_user_conversations[0].profile.thumbnail})` }
                }
              />
            </div>
            <div className='col-10 my-auto'>
              <div className='row' style={{ maxWidth: '107%' }}>
                <div className='col my-auto marg-bottom-0'>
                  <p className='bold chat-room-pp-info'>
                    {conversation.other_user_conversations[0].profile.display_name}
                  </p>
                </div>
                <div className='message-time-date'>
                  {conversation.messages[0] && (
                    <>
                      <div className='message-time-date-side-fade'></div>
                      <p className='chat-room-pp-created-at'>
                        {toSentDay(conversation.messages[conversation.messages.length - 1].created_at)}{' '}
                        {toSentTime(conversation.messages[conversation.messages.length - 1].created_at)}
                      </p>
                    </>
                  )}
                </div>
              </div>
              {conversation.messages.length > 0 && (
                <div className='chat-room-message-wrap'>
                  <p
                    style={{ width: hasUnreadMessages ? 'calc(100% - 10px)' : '100%' }}
                    className='chat-room-last-message'
                  >
                    {conversation.messages[conversation.messages.length - 1].body}
                  </p>
                  {hasUnreadMessages && <div className='new-messages-dot'></div>}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
