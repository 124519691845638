import React, { useState } from 'react';
import HowLong from './how_long';

export default function ThinkingAboutSurgery({
  answers,
  onSubmit,
  onBack,
  gtm,
  para = 'This will help your PatientPartner know how they can help you.',
}) {
  return (
    <HowLong
      answers={answers}
      keyForYears={'yearsThinkingAboutSurgery'}
      keyForMonths={'monthsThinkingAboutSurgery'}
      onSubmit={onSubmit}
      onBack={onBack}
      gtm={`${gtm} Thinking About Surgery`}
      title={`How long have you been thinking about surgery?`}
      para={para}
    />
  );
}
