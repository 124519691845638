import { connect } from 'react-redux';
// import {
//   updateAnswersAction,
//   setQuestionnaireStateAction,
// } from '../../actions/knee_replacement'
import { updateAnswersAction } from '../../actions/home';
import SurgeonMatchForm from '../../components/surgeon_match_form';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurgeonMatchForm);
