import React, { useContext } from 'react';
import Context from '../../../utils/context';
import styles from './index.module.css';
import './txt_left_img_right_wbg.css';

export default function TextLeftImgRightWbg({
  headline = 'This is a big headline',
  specialHeadline = false,
  para = 'Have questions, comments, or suggestions? We’d love to know. Fill out the form below and we’ll get back to you within 24 hours.',
  actionContent = '',
  bgColor = '#DDF7F9',
  thoseDots = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/thoseDots.svg',
  iconContent = (
    <svg viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.25 6.97519e-09H18.75C19.5801 -5.43467e-05 20.3788 0.317554 20.9822 0.887671C21.5856 1.45779 21.948 2.23719 21.995 3.066L22 3.25V14.75C22.0001 15.5801 21.6824 16.3788 21.1123 16.9822C20.5422 17.5856 19.7628 17.948 18.934 17.995L18.75 18H3.25C2.41986 18.0001 1.62117 17.6824 1.01777 17.1123C0.414367 16.5422 0.0519987 15.7628 0.00500012 14.934L6.97518e-09 14.75V3.25C-5.43467e-05 2.41986 0.317554 1.62117 0.887672 1.01777C1.45779 0.414367 2.23719 0.0519987 3.066 0.00500012L3.25 6.97519e-09H18.75H3.25ZM20.5 5.905L11.346 10.665C11.2573 10.7111 11.1603 10.739 11.0607 10.747C10.9611 10.7551 10.8609 10.7432 10.766 10.712L10.654 10.665L1.5 5.905V14.75C1.50002 15.1892 1.66517 15.6123 1.96268 15.9354C2.26019 16.2585 2.6683 16.4579 3.106 16.494L3.25 16.5H18.75C19.1893 16.5 19.6126 16.3347 19.9357 16.037C20.2588 15.7392 20.4581 15.3309 20.494 14.893L20.5 14.75V5.905V5.905ZM18.75 1.5H3.25C2.81081 1.50002 2.38768 1.66517 2.06461 1.96268C1.74154 2.26019 1.54214 2.6683 1.506 3.106L1.5 3.25V4.214L11 9.154L20.5 4.214V3.25C20.5 2.81065 20.3347 2.38739 20.037 2.06429C19.7392 1.74119 19.3309 1.5419 18.893 1.506L18.75 1.5Z'
        fill='#59B6BE'
      />
    </svg>
  ),
  bg = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/photo-1587554801471-37976a256db0.jpeg',
  topOfIcon = '93%', // you may have to tweak this ti get the icon to positon correctly
  leftOfIcon = '5%', // you may have to tweak this ti get the icon to positon correctly
  heightOfImage = '500px',
  showRightImgOnMobile = true,
  imgOffset = true,
  wrapperPadding,
  imgShadow,
  dotStyles = {},
  mobileBackgroundSize = 'cover',
  backgroundSize = 'contain',
  shortenHeaderImgCol = false,
  widthOfIcon = null,
  headerImg = null,
  headerImgWidth = 400,
  afterActionContent = null,
  fill = null,
  background = null,
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  return (
    <div
      style={{
        fill: fill,
        background: background,
        backgroundColor: bgColor,
        paddingTop: `${isMobile ? '' : wrapperPadding}`,
        paddingBottom: wrapperPadding,
      }}
      className={`container-fluid txtimgbg_hero ${imgOffset ? '' : 'marg-bottom-0'} ${styles.txtimgbg_hero}`}
    >
      <div className='container h-100'>
        <div className='semibg'></div>
        <div className='row justify-content-center h-100'>
          <div className={shortenHeaderImgCol ? 'col-md-5 col-lg-5 my-auto' : 'col-md-5 col-lg-6 my-auto'}>
            {headerImg && <img width={headerImgWidth} src={headerImg} />}
            {specialHeadline ? <h1>{specialHeadline}</h1> : <h1>{headline}</h1>}
            <p className='marg-bottom-30'>{para}</p>
            {actionContent}
            {afterActionContent}
          </div>
          {!isMobile && (
            <div
              className={
                shortenHeaderImgCol
                  ? 'col-md-7 col-lg-7 my-auto rightside marg-bottom-30'
                  : 'col-md-7 col-lg-6 my-auto rightside'
              }
            >
              <div style={{ top: topOfIcon, left: leftOfIcon, width: widthOfIcon }} className='icon-content'>
                {iconContent}
              </div>
              <div
                className={`rs-image ${imgOffset ? '' : 'marg-bottom-40 marg-top-40'}`}
                style={{
                  backgroundImage: `url('${bg}')`,
                  height: heightOfImage,
                  boxShadow: imgShadow ? '0 15px 10px -8px rgb(0 0 0 / 25%)' : 'none',
                  backgroundSize: backgroundSize,
                }}
              ></div>
              <img style={dotStyles} className='those-dots' src={thoseDots} />
            </div>
          )}
          {isMobile && showRightImgOnMobile && (
            <div
              className={
                shortenHeaderImgCol
                  ? `marg-bottom-30 col-md-6 my-auto rightside ${styles.right_side}`
                  : `col-md-6 my-auto rightside ${styles.right_side}`
              }
            >
              <div style={{ top: topOfIcon, left: leftOfIcon, width: widthOfIcon }} className='icon-content'>
                {iconContent}
              </div>
              <div
                style={{
                  marginTop: shortenHeaderImgCol && '-50px',
                  backgroundImage: `url('${bg}')`,
                  height: heightOfImage,
                  boxShadow: imgShadow ? '0 15px 10px -8px rgb(0 0 0 / 25%)' : 'none',
                  backgroundSize: mobileBackgroundSize,
                }}
                className={`rs-image ${imgOffset ? '' : 'marg-bottom-40 marg-top-40'}`}
              ></div>
              <img style={dotStyles} className='those-dots' src={thoseDots} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
