import React from 'react';
import WeightLossWebinarParent from '../index';
import VideoType1 from '../../video_sections/video_type_1';
export default function September212021({ home, updateAnswersAction }) {
  return (
    <div>
      <WeightLossWebinarParent
        home={home}
        updateAnswersAction={updateAnswersAction}
        title={'Life After Surgery'}
        photo={
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/webinars/september-21-2021/584A9945.jpg'
        }
        webinarDate={'September 21, 2021 11:00:00'}
        webinarDateText={['Tuesday, September 21st, 2021', '11:00 AM PT', '45 Minutes']}
        webinarTitleForDb={'September212021'}
        evergreenPage={'/webinar/september-21-2021/view'}
        googleCalendarLink={'http://shorturl.at/cwKQ2'}
        otherCalendarLink={
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/webinars/september-21-2021/PatientPartner+Webinar_+Life+After+Surgery.ics'
        }
        wrapperClass={'center-bg-photo'}
        content={
          <>
            <h5 className='marg-top-30'>Are you interested in weight loss surgery but aren’t sure what to expect?</h5>
            <p className='marg-top-30'>
              One of the biggest hesitations around weight loss surgery is the unknown of what life looks like after
              surgery. The questions seem to never end…
              <ul className='marg-top-10'>
                <li>How long is recovery?</li>
                <li>Will I have a scar?</li>
                <li>Can I eat the same things after surgery?</li>
                <li>How long do I have to wait to exercise?</li>
                <li>How much time will I need to take off from work? </li>
              </ul>
            </p>
            <p className='marg-top-20'>
              All of these questions and more will be addressed in the Life After Surgery Webinar hosted by
              PatientPartner and Dr. Hanna. Join in on the conversation to demystify what life looks like after weight
              loss surgery.
            </p>
            <p className='marg-top-20'>
              Dr. Hanna, Medical Director of Las Vegas Bariatrics, joins us in discussing the most common questions
              around what life is like after bariatric surgery. Dr. Hanna will guide us through the important details in
              anticipation of physical changes, potential limitations within life, and more. We will also be joined by
              Dr. Hanna’s recovered patient, Rana Khalil, who will share her story on how bariatric surgery has
              transformed her life. 
            </p>
            <h2 className='body-subheadline marg-top-20'>
              The webinar will include Dr. Hanna’s insights throughout his extensive experience with bariatric surgery
              in helping patients transform their lives. 
            </h2>
            <p className='marg-top-20'>
              Weight loss surgery is safe and effective. Join our webinar to find out if this could be a life-changing
              option for you.
            </p>
            <h2 className='body-subheadline marg-top-40'>About Dr. Hanna:</h2>
            <div className='surgeon'>
              <div className='row marg-top-20 photo-wrap rs-bio-vid'>
                <VideoType1
                  bg={''}
                  videoLink={
                    'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/webinars/september-21-2021/Dr.+Hanna+PatientPartner+Video+--.mp4'
                  }
                  headline={`About Dr. Hanna`}
                  gtm={'Webinar September 21 2021 Video'}
                />
              </div>
            </div>
            <p className='marg-top-20 marg-bottom-20'>
              Bernie Hanna, MD, FACS FASMBS is an internationally recognized bariatric surgeon and the founder and
              Medical Director of Las Vegas Bariatrics. He is known for his expertise in performing the Vertical Sleeve
              Gastrectomy (VSG), Roux-en-Y (RNY) Gastric Bypass, Adjustable Gastric Band (Lap-Band), and weight loss
              surgery revisions. Dr. Hanna serves as the Medical Director for North Vista Hospital, Sunrise Hospital and
              Medical Center, and Dignity San Martin Hospital. Dr. Hanna is also an award-winning surgeon, having
              received multiple Top Surgeon honors and achieved the “Top Doctor” award in 2020. Specializing in advanced
              laparoscopic surgery, Dr. Hanna stays at the forefront of surgery and bariatrics by being involved in
              surgical research. 
            </p>
            <button className='marg-top-20 send-it-final no-mobile'>Register now</button>
          </>
        }
      />
    </div>
  );
}
