import React, { useContext } from 'react';
import VideoSec1NoBgL from '../../../../../video_sections/video_sec_1_no_bg_L';
import Context from '../../../../../../utils/context';
import VideoType2 from '../../../../../video_sections/video_type_2';

export default function FeaturedVideo({ video, title = null }) {
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile();

  return (
    <div className='content_container'>
      <p
        style={{
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '0.08em',
          textTransform: 'uppercase',
          color: '#828282',
        }}
      >
        FEATURED MENTOR STORY
      </p>
      {title !== null && (
        <p style={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px', color: '#333333', marginTop: '8px' }}>
          {title}
        </p>
      )}
      <div style={{ marginTop: '10px', borderRadius: '5px', overflow: 'hidden' }}>
        <VideoType2 videoLink={video} autoplay iconSize='50px' height='200px' />
      </div>
    </div>
  );
}
