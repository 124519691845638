import React, { Component, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getSurgeon } from '../../api/surgeons';
import CustomCtaFooter from '../custom_cta_footer';
import DocPatientPartners from '../doc-patient-partners';
import Modal from '../modal';
import Oncehub from '../oncehub';
import HowItWorks from '../how_it_works';
import FloatingBox from '../floating_box';
import ScrollTo from '../scroll_to';
import './surgeon_partners.css';
import VideoType1 from '../video_sections/video_type_1';

export default function SurgeonPartners() {
  const { id } = useParams();
  const [view, setView] = useState('loading');
  const [data, setData] = useState(null);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [selectedPatientPartner, setSelectedPatientPartner] = useState(null);

  useEffect(() => {
    getSurgeon(id, (surgeon) => {
      console.log('surgeon');
      console.log(surgeon);
      setData(surgeon);
      setView('show');
    });
  }, []);

  if (view === 'loading') {
    return <div>Loading</div>;
  }

  const viewScheduleModal = (partner) => () => {
    setSelectedPatientPartner(partner);
    setShowScheduleModal(true);
  };

  const onScheduled = (data) => {
    console.log('scheduled');
  };

  return (
    <div className='embedded'>
      <div className='container-fluid'>
        <div className='container'>
          <div className='row justify-content-center h-100'>
            <div className='col-lg-6 my-auto'>
              <h1 className='marg-bottom-30 marg-top-100 headline'>{data.name}'s PatientPartner Program</h1>
              <p className='marg-bottom-30'>
                {data.name} offers the PatientPartner program to all patients so you can learn from his recovered
                patients and their experiences for free
              </p>
              <button
                onClick={() => ScrollTo('#pp-gallery', 50)}
                className='maincta'
                data-gtm-click-label={`${data.name} iFrame Surgeon Patient Partners See Patient Partners`}
              >
                See the PatientPartners
              </button>
            </div>
            <div className='col-lg-6 vt1-upper rightside my-auto'>
              <VideoType1
                headline={data.extra.iframe_content[0].headline}
                bg={data.extra.iframe_content[0].bg}
                videoLink={data.extra.iframe_content[0].video}
                gtm={`iFrame Top`}
              />
            </div>
          </div>
        </div>
      </div>
      <FloatingBox />
      <HowItWorks data={data} />
      {data.patient_partners.length > 0 && (
        <div id='pp-gallery' className='marg-bottom-100'>
          <DocPatientPartners
            headline={`Meet ${data.name}’s PatientPartners™`}
            para={`${data.name}’s PatientPartners™ choose to share their story with you because they wish they had someone to talk to when they were going through a similar journey. They are here to answer your questions based on their experience so you don’t have to wonder.`}
            patientPartners={data.patient_partners}
            actionContentFunc={(partner) => (
              <button
                data-gtm-click-label={`${data.name} Surgeon Partners iFrame Schedule A Call`}
                className='direct-schedule-cta'
                onClick={viewScheduleModal(partner)}
              >
                Schedule a call
              </button>
            )}
          />
        </div>
      )}
      {showScheduleModal && (
        <Modal
          id='oncehub-modal'
          content={
            <Oncehub
              divid={selectedPatientPartner.oncehub_divid}
              page={selectedPatientPartner.oncehub_page}
              onScheduled={onScheduled}
              params={{
                'Requested PatientPartner': selectedPatientPartner.name,
              }}
            />
          }
          onClose={() => {
            setShowScheduleModal(false);
          }}
        />
      )}
      <VideoType1
        headline={data.extra.iframe_content[1].headline}
        cta={'Watch'}
        gtm={`iFrame Patient Partner`}
        videoLink={data.extra.iframe_content[1].video}
      />
      <CustomCtaFooter
        headline='PatientPartner&trade; is here for you every step of the way.'
        para='PatientPartner&trade; has partnered with your surgeon to ensure patients are receiving the best patient experience.'
        buttontxt='Learn more'
        link='/'
        targetBlank={true}
        gtm={`iFrame`}
      />
    </div>
  );
}
