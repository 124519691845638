export const createActivity = (type, extra, successCallback) => {
  fetch(`/api/v1/activities`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...window.utmInfo,
      activity: {
        type: type,
        extra: extra,
      },
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (successCallback) {
        successCallback(data);
      }
    });
};
