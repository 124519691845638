import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleSlidingMenu from '../../../simple_sliding_menu';

export default function PatientOtherUserPanel({ isMobile, otherUser, setShowSurgeonModal }) {
  const [active, setActive] = useState();
  const [view, setView] = useState('AboutMe');

  const showRequestAppointmentModal = () => {
    setShowSurgeonModal(true);
  };

  const renderAge = (otherUser) => {
    if (otherUser.user && otherUser.user.age) {
      return `${otherUser.user.age} yrs. old`;
    }

    return '';
  };

  return (
    <>
      <div
        className={`${otherUser.profile.type === 'SuperUser' ? 'chat-room-super-user-avatar-lg' : 'chat-room-pp-avatar-lg'} marg-bottom-20 ${isMobile ? 'marg-top-10' : 'marg-top-40'}`}
        style={{ backgroundImage: `url(${otherUser.profile.photo})` }}
      />
      <p className='bold text-center ou-name'>{otherUser.profile.full_name}</p>
      <p className='text-center ou-short-info marg-bottom-0'>
        {renderAge(otherUser)}
        {otherUser.user && otherUser.user.age && otherUser.profile.display_location !== null ? ', ' : ''}
        {otherUser.profile.display_location !== null ? otherUser.profile.display_location : ''}
      </p>
      <SimpleSlidingMenu
        menuItems={[
          {
            idToScrollTo: 'AboutMe',
            linkText: 'About me',
          },
          {
            idToScrollTo: 'PrivacyAndSupport',
            linkText: 'Privacy & Support',
          },
        ]}
        setView={(view) => setView(view)}
      />
      {view === 'AboutMe' && (
        <div className={`other-p-bio-sec ${isMobile ? 'marg-bottom-40' : ''}`}>
          {(otherUser.profile.bio !== null || otherUser.profile.type === 'SuperUser') && (
            <>
              <p className='other-p-bio-title bold marg-bottom-5'>About me</p>
              {otherUser.profile.type === 'SuperUser' && (
                <>
                  <p className={`other-p-bio marg-bottom-5`}>
                    Our mission is to enpower and educate patients by providing personalized one-on-one support through
                    relatable experiences. <br />
                    We want to make sure every patient has the support and care they need throughout their journey.
                  </p>
                  <p className='other-p-bio-title bold marg-bottom-5'>How we can help:</p>
                  <ul>
                    <li>Connect you to patient mentors and provide emotional support</li>
                    <li>Answer any preliminary insurance questions</li>
                    <li>Connect you to a reputable surgeon in your area</li>
                  </ul>
                </>
              )}
              {otherUser.profile.type !== 'SuperUser' && (
                <>
                  <p className={`other-p-bio marg-bottom-5 ${active ? '' : 'active'}`}>{otherUser.profile.bio}</p>
                  <p
                    onClick={() => setActive(!active)}
                    className='text-link'
                  >{`${active ? 'See less' : 'See more'}`}</p>
                </>
              )}
            </>
          )}
          <div className='marg-top-10'>
            {otherUser.profile.messenger_treatment && otherUser.profile.type === 'PatientPartner' && (
              <div className='row'>
                <div className='marg-bottom-10 col-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='21'
                    height='100%'
                    fill='#59B6BE'
                    fillOpacity='0.6'
                    className='bi bi-prescription'
                    viewBox='0 0 16 16'
                  >
                    <path d='M5.5 6a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 1 0V9h.293l2 2-1.147 1.146a.5.5 0 0 0 .708.708L9 11.707l1.146 1.14</svg>7a.5.5 0 0 0 .708-.708L9.707 11l1.147-1.146a.5.5 0 0 0-.708-.708L9 10.293 7.695 8.987A1.5 1.5 0 0 0 7.5 6zM6 7h1.5a.5.5 0 0 1 0 1H6z' />
                    <path d='M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v10.5a1.5 1.5 0 0 1-1.5 1.5h-7A1.5 1.5 0 0 1 3 14.5V4a1 1 0 0 1-1-1zm2 3v10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V4zM3 3h10V1H3z' />
                  </svg>
                </div>
                <div className='marg-bottom-10 col-10'>
                  <p>
                    Treatment: <b>{otherUser.profile.messenger_treatment}</b>
                  </p>
                </div>
              </div>
            )}
            {otherUser.profile.type === 'Patient' && otherUser.profile.procedures && (
              <div className='row'>
                <div className='marg-bottom-10 col-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='100%'
                    fill='#59B6BE'
                    fillOpacity='0.6'
                    className='bi bi-clipboard2-pulse'
                    viewBox='0 0 16 16'
                  >
                    <path d='M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z' />
                    <path d='M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z' />
                    <path d='M9.979 5.356a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.926-.08L4.69 10H4.5a.5.5 0 0 0 0 1H5a.5.5 0 0 0 .447-.276l.936-1.873 1.138 3.793a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h.5a.5.5 0 0 0 0-1h-.128z' />
                  </svg>
                </div>
                <div className='marg-bottom-10 col-10'>
                  <p>
                    Conditions: <b>{otherUser.profile.procedures.join(', ')}</b>
                  </p>
                </div>
              </div>
            )}
            {otherUser.profile.type === 'PatientPartner' && otherUser.profile.messenger_condition && (
              <div className='row'>
                <div className='marg-bottom-10 col-1'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='20'
                    height='100%'
                    fill='#59B6BE'
                    fillOpacity='0.6'
                    className='bi bi-clipboard2-pulse'
                    viewBox='0 0 16 16'
                  >
                    <path d='M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z' />
                    <path d='M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z' />
                    <path d='M9.979 5.356a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.926-.08L4.69 10H4.5a.5.5 0 0 0 0 1H5a.5.5 0 0 0 .447-.276l.936-1.873 1.138 3.793a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h.5a.5.5 0 0 0 0-1h-.128z' />
                  </svg>
                </div>
                <div className='marg-bottom-10 col-10'>
                  <p>
                    Condition: <b>{otherUser.profile.messenger_condition}</b>
                  </p>
                </div>
              </div>
            )}
            {!isMobile && otherUser.profile.type === 'PatientPartner' && (
              <div className='row'>
                <div className='marg-bottom-10 col-1'>
                  <a
                    href={`/member/profile/${otherUser.profile.slug}/about-me`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <button style={{ padding: '10px 15px' }}>See full profile</button>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {view === 'PrivacyAndSupport' && (
        <div className={`other-p-bio-sec container ${isMobile ? 'marg-bottom-40' : ''}`}>
          {otherUser.profile && (
            <Link to='/contact-us' target='_blank' rel='noopener noreferrer'>
              <div className='row support-click-option'>
                <div className='col-1 my-auto'>
                  <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path d='M9.95383 6.60504V10.5493' stroke='#173959' strokeLinecap='round' />
                    <path d='M9.95383 13.3102V13.7047' stroke='#173959' strokeLinecap='round' />
                    <path
                      d='M1.59487 14.5062L8.5419 1.91295C9.15206 0.806197 10.7583 0.806197 11.3676 1.91295L18.3138 14.5062C18.8966 15.5664 18.1212 16.8601 16.8992 16.8601H3.00773C1.78656 16.8601 1.01039 15.5664 1.59573 14.5062H1.59487Z'
                      stroke='#173959'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
                <div className='col-10 my-auto'>
                  <p className='support-option-txt'>
                    Report {otherUser.profile.type === 'Patient' ? 'patient' : 'mentor'}
                  </p>
                  <p className='support-option-smtxt'>Let us know if something doesn’t seem right.</p>
                </div>
              </div>
            </Link>
          )}
        </div>
      )}
    </>
  );
}
