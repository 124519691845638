import React from 'react';
import Divider from '../../../../ui_kit/utilities/divider';
import VideoType2 from '../../../../video_sections/video_type_2';
import parent_styles from '../../../index.module.css';
import styles from './index.module.css';

export default function SurgeonBiography({ surgeon }) {
  return (
    <div id='surgeon-individual-bio-sec' style={{ marginTop: '20px' }}>
      <p className={parent_styles.profile_section_headline}>About me</p>
      <div style={{ display: 'flex' }} className='content_container'>
        <div className='row'>
          <div className='col'>
            <p className={styles.bio_p}>{surgeon.bio}</p>
            <div style={{ marginTop: '10px' }}>
              {surgeon.video_url && (
                <VideoType2 videoLink={surgeon.video_url} height='300px' iconSize='70px' autoplay />
              )}
            </div>
          </div>
        </div>
      </div>
      {surgeon && surgeon.supported_languages && surgeon.supported_languages !== null && (
        <>
          <p className={parent_styles.profile_section_headline}>Personal information</p>
          <div style={{ display: 'flex' }} className='content_container'>
            <div className='row'>
              <div className='col'>
                <p className={styles.detail_txt}>supported languages</p>
              </div>
            </div>
          </div>
        </>
      )}
      {surgeon &&
        surgeon.extra &&
        surgeon.extra !== null &&
        ((surgeon.extra.residency && surgeon.extra.residency !== null) ||
          (surgeon.extra.fellowship && surgeon.extra.fellowship !== null) ||
          (surgeon.extra.medical_school && surgeon.extra.medical_school !== null)) && (
          <>
            <p className={parent_styles.profile_section_headline}>Education</p>
            <div className='content_container container'>
              {surgeon.extra.fellowship && surgeon.extra.fellowship !== null && (
                <>
                  <div className='row'>
                    <div className='col'>
                      <p className={styles.school_name}>{surgeon.extra.fellowship}</p>
                      <p className={styles.school_title}>FELLOWSHIP</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <Divider />
                    </div>
                  </div>
                </>
              )}
              {surgeon.extra.residency && surgeon.extra.residency !== null && (
                <>
                  <div style={{ marginTop: '20px' }} className='row'>
                    <div className='col'>
                      <p className={styles.school_name}>{surgeon.extra.residency}</p>
                      <p className={styles.school_title}>RESIDENCY HOSPITAL</p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <Divider />
                    </div>
                  </div>
                </>
              )}
              {surgeon.extra.medical_school && surgeon.extra.medical_school !== null && (
                <div style={{ marginTop: '20px' }} className='row'>
                  <div className='col'>
                    <p className={styles.school_name}>{surgeon.extra.medical_school}</p>
                    <p className={styles.school_title}>MEDICAL SCHOOL</p>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
    </div>
  );
}
