export const getPatientAssistantInfo = (id, successCallback, onError) => {
  fetch(`/api/v1/patient_assistant_infos/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response.json());
    })
    .then((data) => successCallback(data))
    .catch((data) => onError(data));
};

export const updatePatientAssistantInfo = (id, status, successCallback) => {
  fetch(`/api/v1/patient_assistant_infos/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      status: status,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const sendNote = (id, type, params, successCallback) => {
  fetch(`/api/v1/patient_assistant_info_logs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: id,
      type: type,
      note: params.note,
      old_status: params.old_status,
      new_status: params.new_status,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getProviderPatients = (successCallback) => {
  fetch(`/api/v1/patient_assistant_infos`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const createPatientAssistantInfo = (params, successCallback) => {
  fetch(`/api/v1/patient_assistant_infos`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
