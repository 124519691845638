import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import RequestButton from '../../ui_kit/utilities/request_button';

export default function StripeCardInput({
  ctaText = 'Pay now',
  showAmountInline = true,
  amount = '',
  beforeSend = () => {},
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) return;
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    if (!clientSecret) return;
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (callback) => {
    beforeSend();
    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location}/success`, //  <-- required because some payment providers require you to go to a page in between checkout and success to verify payment
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
      callback();
    } else {
      setMessage('An unexpected error occurred.');
      callback();
    }

    setIsLoading(false);
  };

  return (
    <form id='payment-form' style={{ marginTop: '20px' }}>
      <PaymentElement id='payment-element' />
      {showAmountInline && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            padding: '16px',
            fontSize: '16px',
            marginTop: '20px',
            borderRadius: '12px',
          }}
        >
          <p>Total Price</p>
          <p style={{ marginLeft: 'auto' }}>${amount}</p>
        </div>
      )}
      <RequestButton
        request={(callback) => handleSubmit(callback)}
        style={{ width: '100%', margin: '20px 0px' }}
        disabled={isLoading || !stripe || !elements}
        cta={ctaText}
        id='submit'
      />
      {message && <div id='payment-message'>{message}</div>}
    </form>
  );
}
