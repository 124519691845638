import React, { Component } from 'react';
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function InsuranceSelection({ value, onSubmit, onBack }) {
  return (
    <OptionsAndOther
      answers={{ insuranceSelection: value }}
      keyForAnswer={'insuranceSelection'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={['Commercial Insurance', 'Medicare', 'Medicaid', 'Uninsured']}
      title={`What type of insurance do you have?`}
      para={``}
      showOther={false}
      ctaTxt='Next'
      selectOne
    />
  );
}
