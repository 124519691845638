import React from 'react';
import OptionsAndOther from '../../../../questionnaire/choose_an_option';
import VonjoIsi from '../../../../vonjo';

export default function MfPrescribedMedication({ value, onSubmit, onBack }) {
  return (
    <div>
      <OptionsAndOther
        answers={{ mfPrescribedMedication: value }}
        keyForAnswer={'mfPrescribedMedication'}
        onSubmit={onSubmit}
        onBack={onBack}
        choices={['Yes', 'No']}
        title={`Are you or the patient currently prescribed or currently taking VONJO® (pacritinib)?`}
        para={``}
        showOther={false}
        ctaTxt='Next'
        selectOne
      />

      <div style={{ height: '300px', width: '100%', overflowY: 'scroll', marginTop: '30px' }}>
        <VonjoIsi />
      </div>
    </div>
  );
}
