export const createPostUserLike = (postId, successFunction) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  fetch('/api/v1/post_user_likes', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({
      post_id: postId,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (successFunction) {
        successFunction(data);
      }
    });
};

export const deletePostUserLike = (postId, successCallback) => {
  fetch(`/api/v1/post_user_likes/${postId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (successCallback) {
        successCallback(data);
      }
    });
};
