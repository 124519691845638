export const shouldHideHeader = (path, isMobile) => {
  const match = path.match(
    /^\/member\/conversations\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
  );
  return match && isMobile;
};

export const showLoginButton = (isLoggedIn, isMobile) => {
  return !isLoggedIn && isMobile;
};
