import React, { useState, useRef } from 'react';
import styles from './index.module.css';
import { useClickAway } from 'react-use';

export default function SelectSearch({
  selected,
  setSelected,
  choices = ["I don't see my provider", ...gon.all_insurances],
  light = true,
  style = {},
  noMatchTxt = "I don't see what im looking for",
  placeholder = 'Search...',
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [valuePlaceholder, setValuePlaceholder] = useState('');
  const ref = useRef(null);

  let options = [];
  if (showOptions) {
    const re = new RegExp(valuePlaceholder, 'i');
    if (valuePlaceholder !== '') {
      options = choices.filter((option) => {
        if (re.test(option)) {
          return option;
        }
      });
    }
    if (options.length == 0) {
      options = choices;
    }
  }

  const typeChoice = (value) => {
    setValuePlaceholder(value);
    setShowOptions(true);
  };

  useClickAway(ref, () => {
    setShowOptions(false);
  });

  return (
    <div style={{ position: 'relative', ...style }}>
      <div className={`material-input marg-top-0 ${light ? 'light' : ''}`}>
        <input
          className={styles.input}
          style={{ backgroundColor: '#f7f7f7' }}
          value={valuePlaceholder === selected ? selected : valuePlaceholder}
          autoComplete='off'
          name='choice'
          onChange={(event) => typeChoice(event.target.value)}
          onClick={() => setShowOptions(true)}
          placeholder={placeholder}
        />
        <span className='highlight'></span>
        <span className='bar'></span>
      </div>
      <div ref={ref} className={`${styles.search_choices_container}`}>
        <div className={`${styles.search_choices_innr}`}>
          {options.length > 0 && (
            <>
              {options.map((value, index) => (
                <button
                  key={index}
                  value={value}
                  onClick={(event) => {
                    setShowOptions(false);
                    setSelected(event.target.value);
                    setValuePlaceholder(event.target.value);
                  }}
                  className={`${styles.search_option}`}
                >
                  {value}
                </button>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
