import React, { useState } from 'react';

export default function YesNoSubmit({ onBack, onSubmit, title, para, keyForAnswer, yesState, noState, answers, gtm }) {
  const choices = [
    { value: 'Yes', state: yesState, answer: true },
    { value: 'No', state: noState, answer: false },
  ];
  const [selected, setSelected] = useState(
    choices.filter((e) => e.answer === answers[keyForAnswer])[0] || { value: null, state: null, answer: null }
  );

  const submit = (e) => {
    e.preventDefault();
    onSubmit({ [keyForAnswer]: selected.answer }, selected.state);
  };

  return (
    <div>
      <form id='plans'>
        <h5 className='question'>{title}</h5>
        <p className='question'>{para}</p>
        {choices.map((choice) => (
          <div key={choice.value} className='tile two-column'>
            <label
              key={choice.value}
              className={`preexisting ${selected.answer == choice.answer ? 'activeCheckbox' : ''}`}
            >
              <input onClick={() => setSelected(choice)} type='checkbox' name={choice.value} value='value' />
              {choice.value}
            </label>
          </div>
        ))}
        <div className='container'>
          <div className='row button-holder'>
            <button className='back col-lg-6' type='button' onClick={onBack} data-gtm-click-label={`${gtm} - Back`}>
              Back
            </button>
            <button
              disabled={`${selected.value === null ? 'disabled' : ''}`}
              className={`gtm-preexisting-conditions submit col-lg-6`}
              type='submit'
              onClick={(e) => submit(e)}
              data-gtm-click-label={`${gtm} - Next`}
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
