import React from 'react';
import GoogleMapReact from 'google-map-react';
import Divider from '../../../../../ui_kit/utilities/divider';
import { formatPhoneNumber } from '../../../../../../helpers';
import parent_styles from '../../../../index.module.css';
import styles from '../index.module.css';

export default function SurgeonLocation({ surgeon }) {
  var settings = {
    center: {
      lat: surgeon.latitude,
      lng: surgeon.longitude,
    },
    zoom: 10,
  };

  if (surgeon && surgeon.office_locations && surgeon.office_locations.length > 0) {
    const totalLatitude = surgeon.office_locations.reduce((acc, l) => acc + l.latitude, 0);
    settings.center.lat = totalLatitude / surgeon.office_locations.length;
    const totaLongitude = surgeon.office_locations.reduce((acc, l) => acc + l.longitude, 0);
    settings.center.lng = totaLongitude / surgeon.office_locations.length;
  }

  const colors = ['#59B6BE', '#314D69', '#188F8B', '#FFE3BC'];

  const Marker = ({ color }) => (
    <svg
      style={{ transform: 'translateY(-10px) translateX(-10px)' }}
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='55'
      viewBox='0 0 24.376 29.126'
    >
      <g id='Icon_feather-map-pin' data-name='Icon feather-map-pin' transform='translate(1.5 1.5)'>
        <path
          id='Path_10'
          data-name='Path 10'
          d='M25.876,12.188c0,8.313-10.688,15.438-10.688,15.438S4.5,20.5,4.5,12.188a10.688,10.688,0,1,1,21.376,0Z'
          transform='translate(-4.5 -1.5)'
          fill='#fff'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        ></path>
        <path
          id='Path_11'
          data-name='Path 11'
          d='M20.625,14.063A3.563,3.563,0,1,1,17.063,10.5a3.563,3.563,0,0,1,3.563,3.563Z'
          transform='translate(-6.375 -3.375)'
          fill='#fff'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3'
        ></path>
      </g>
    </svg>
  );

  return (
    <>
      <p className={parent_styles.profile_section_headline}>Location{surgeon.office_locations.length > 1 ? 's' : ''}</p>
      <div className='content_container'>
        {surgeon &&
          surgeon.office_locations &&
          surgeon.office_locations.map((l, i) => (
            <div className='container' style={{ margin: i === 0 ? '0px 0px 20px 0px' : '20px 0px' }}>
              {i !== 0 && <Divider />}
              <div className='row' style={{ display: 'flex', marginTop: i === 0 ? '' : '20px' }}>
                <div className='col-12 col-md-6' style={{ display: 'flex' }}>
                  <img
                    alt='location'
                    src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/waypoint.svg'
                    style={{ marginRight: '10px' }}
                  />
                  <p className={styles.address_p}>{l.address}</p>
                </div>
                {l.phone_number && l.phone_number !== null && (
                  <div
                    className={`col-12 col-md-6 ${styles.location_phone}`}
                    style={{ display: 'flex', flexGrow: '1', alignContent: 'center' }}
                  >
                    <img
                      alt='location'
                      src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/surgeons/phone.svg'
                      style={{ marginRight: '10px', width: '16px' }}
                    />
                    <a
                      href={`tel:${formatPhoneNumber(l.phone_number)}`}
                      style={{ color: '#419DA5', fontWeight: '400', alignSelf: 'center' }}
                    >
                      {formatPhoneNumber(l.phone_number)}
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
        <div style={{ height: '400px', borderRadius: '4px', overflow: 'hidden' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: gon.google_maps_key, language: 'en' }}
            defaultCenter={settings.center}
            defaultZoom={settings.zoom}
          >
            {surgeon &&
              surgeon.office_locations &&
              surgeon.office_locations.map((o, i) => {
                return <Marker color={colors[i]} lat={o.latitude} lng={o.longitude} text='My Marker' />;
              })}
          </GoogleMapReact>
        </div>
      </div>
    </>
  );
}
