import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import DesktopNavItem from '../header/desktop_nav_item';
import MobileNavItem from '../header/mobile_nav_item';
import PortalNav from '../header/portal_nav';
import Notifications from '../header/notifications';
import { useTransition, useChain, animated, useSpringRef } from '@react-spring/web';
import Context from '../../utils/context';
import SimpleAvatar from '../ui_kit/utilities/simple_avatar';

export default function MemberPlatformHeader({ hideLinks = false }) {
  const context = useContext(Context);
  const actioncable = context.actioncable;
  const [navOpen, setNavOpen] = useState(false);
  const viewport = context.viewport;

  const closeNav = () => {
    setNavOpen(false);
    /*When Side bar Close Body Scroll Continue*/
    /*var element = document.getElementById("myDIV");*/
    var headerr = document.getElementById('hdr-content');
    headerr.classList.remove('no_scroll_css');
    var body = document.getElementById('hdr-content').parentElement;
    body.classList.toggle('no_scroll_css');
  };

  const isMobile = viewport.getIsMobile();
  const isSmallMobile = viewport.getIsSmallMobile();

  let location = useLocation();

  const [headerSticky, setHeaderSticky] = useState(false);

  // useEffect(() => {
  //   if (location.pathname.includes("surgeon/dr-")) {
  //     setHeaderSticky(true);
  //   } else {
  //     setHeaderSticky(false);
  //   }
  // }, [location.pathname]);

  // const menuItems = [
  //   {

  //     dropdown: [
  //       {
  //         title: "Meet our mentors",
  //         smallTitle: "Connect with a recovered patient",
  //         link: "/partners",
  //         props: {
  //           to: "/partners",
  //         },
  //         icon: (
  //           <svg
  //             width="24"
  //             height="15"
  //             viewBox="0 0 24 15"
  //             fill="none"
  //             xmlns="http://www.w3.org/2000/svg"
  //           >
  //             <path
  //               d="M19.47 2.39625L17.685 0.61125C17.2899 0.219683 16.7562 -4.34474e-06 16.2 0H7.695C7.14 0 6.60375 0.22125 6.21 0.61125L4.425 2.39625H0V11.985H2.4C3.06 11.985 3.5925 11.4525 3.59625 10.7963H3.9375L7.11 13.6613C8.26875 14.6025 9.8775 14.625 11.07 13.8038C11.5388 14.2088 12.045 14.4 12.6113 14.4C13.2938 14.4 13.935 14.1225 14.4413 13.5C15.27 13.8263 16.2488 13.5975 16.8413 12.87L17.8238 11.6588C18.0338 11.4 18.165 11.1038 18.2325 10.7963H20.4037C20.4075 11.4525 20.9438 11.985 21.6 11.985H24V2.39625H19.47ZM1.8 10.785C1.47 10.785 1.2 10.515 1.2 10.185C1.2 9.855 1.47 9.585 1.8 9.585C2.13 9.585 2.4 9.855 2.4 10.185C2.4 10.5188 2.13 10.785 1.8 10.785ZM16.425 10.5263L15.4463 11.7338C15.3413 11.8613 15.1538 11.8838 15.0225 11.7788L14.1263 11.0513L13.0013 12.42C12.7763 12.6938 12.4388 12.6 12.3263 12.51L10.9463 11.3288L10.3612 12.0488C9.84 12.69 8.89125 12.7875 8.2875 12.2963L4.63875 8.99625H3.6V4.1925H5.17125L7.485 1.8825C7.56 1.8525 7.62375 1.82625 7.69875 1.79625H9.825L8.37375 3.1275C7.27125 4.13625 7.2075 5.83875 8.20875 6.92625C8.76375 7.53375 10.5038 8.47125 12.015 7.09125L12.3225 6.81L16.38 10.1025C16.5075 10.2075 16.5263 10.3988 16.425 10.5263V10.5263ZM20.4 8.99625H17.805C17.7188 8.89125 17.6213 8.79375 17.5163 8.7075L13.665 5.58L14.1338 5.1525C14.3775 4.9275 14.3963 4.54875 14.1713 4.305L13.7625 3.86625C13.5375 3.6225 13.1588 3.6075 12.915 3.82875L10.845 5.72625C10.4888 6.0525 9.88125 6.07875 9.5475 5.72625C9.19875 5.355 9.22875 4.785 9.5925 4.455L12.0525 2.20125C12.33 1.94625 12.69 1.8075 13.065 1.8075L16.2038 1.8C16.2825 1.8 16.3575 1.83 16.41 1.88625L18.7238 4.19625H20.4V8.99625V8.99625ZM22.2 10.785C21.87 10.785 21.6 10.515 21.6 10.185C21.6 9.855 21.87 9.585 22.2 9.585C22.53 9.585 22.8 9.855 22.8 10.185C22.8 10.5188 22.53 10.785 22.2 10.785Z"
  //               fill="#173959"
  //             />
  //           </svg>
  //         ),
  //       },
  //       {
  //         title: "Blog",
  //         smallTitle: "Helpful resources",
  //         props: {
  //           to: {
  //             pathname: "https://www.patientpartner.blog/",
  //           },
  //           target: "_blank",
  //         },
  //         icon: (
  //           <svg
  //             width="16"
  //             height="17"
  //             viewBox="0 0 16 17"
  //             fill="none"
  //             xmlns="http://www.w3.org/2000/svg"
  //           >
  //             <path
  //               fillRule="evenodd"
  //               clipRule="evenodd"
  //               d="M2 4V2H14V4H2ZM2 6V15H14V6H2ZM0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1V16C16 16.5523 15.5523 17 15 17H1C0.447715 17 0 16.5523 0 16V1ZM3 10H13V8H3V10ZM13 13H3V11H13V13Z"
  //               fill="#173959"
  //             />
  //           </svg>
  //         ),
  //       },
  //     ],
  //   },
  //   {
  //     mainLink: {
  //       title: "Find a doctor",
  //       link: "/all-surgeons",
  //     },
  //   },
  // ];

  // const mobileMenuItems = context.auth.isLoggedIn()
  //   ? [
  //       {
  //         mainLink: {
  //           onclick: "logout",
  //           title: "Log out",
  //         },
  //       },
  //     ]
  //   : [
  //       {
  //         mainLink: {
  //           title: "Join for free",
  //           link: "/get-started/patient-partner-match",
  //         },
  //       },
  //       {
  //         mainLink: {
  //           title: "Log in ",
  //           link: "/member/login",
  //         },
  //       },
  //       {
  //         mainLink: {
  //           title: "Become a mentor",
  //           link: "/for-mentors",
  //         },
  //       },
  //     ];

  const navItemsAnimation = useSpringRef();
  const mobileNavItems = useTransition(navOpen ? [...menuItems, ...mobileMenuItems] : [], {
    ref: navItemsAnimation,
    trail: navOpen ? 100 / menuItems.length : 0,
    config: { mass: 0.5, tension: 200, friction: 20 },
    from: { opacity: 0, transform: 'translateX(50px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
  });

  useChain(navOpen ? [navItemsAnimation] : [navItemsAnimation], [0.2]);

  const navItems = [
    {
      onclick: 'logout',
      title: 'Log out',
    },
  ];

  const [unreadNotificationCount, setUnreadNotificationCount] = useState(
    context.auth.isLoggedIn() && context.auth.getCurrentUser().current_profile.unread_notification_count
  );

  const onReceived = (data) => {
    if (data.type === 'unread_notification_count') {
      context.auth.setUnreadNotificationCount(data.value);
      setUnreadNotificationCount(data.value);
    }
  };

  useEffect(() => {
    let subscriptionId = null;
    if (context.auth.isLoggedIn()) {
      subscriptionId = actioncable.subscribe(
        'ProfileChannel',
        { id: context.auth.getCurrentUser().profile_id },
        onReceived
      );
    }

    return () => {
      if (subscriptionId) {
        actioncable.unsubscribe(subscriptionId);
      }
    };
  }, []);

  let navigationItems = [];
  if (context.auth.isLoggedIn()) {
    let procedureFilter = '';
    if (context.auth.getCurrentUser().current_profile.surgeries_or_treatments.length > 0) {
      procedureFilter = objToQueryString({
        procedures: context.auth.getCurrentUser().current_profile.surgeries_or_treatments[0],
      });
    }

    if (context.auth.getCurrentUser().current_profile.procedures.length > 0) {
      navigationItems = [
        {
          props: {
            to: `/member/profile/${context.auth.getCurrentUser().slug}`,
          },
          title: 'Profile',
          icon: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/profile.svg',
          pendoTag: 'nav-dropdown-profile',
        },
        {
          props: {
            to: `/partners?${procedureFilter}`,
          },
          title: 'Find a mentor',
          icon: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/community.svg',
          pendoTag: 'nav-dropdown-community',
        },
        {
          props: {
            to:
              context.auth.getCurrentUser().current_profile_type === 'Patient' ? '/member/discussions' : '/member/home',
          },
          title: 'Discussions',
          icon: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/discussions.svg',
          pendoTag: 'nav-dropdown-discussions',
        },
        // {
        //     props: {
        //       to: `/all-surgeons`,
        //     },
        //     title: 'Doctor discovery',
        //     icon: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/doctor-discovery.svg',
        //     pendoTag: 'nav dropdown - Doctor discovery'
        // },
        {
          props: {
            to: '/member/settings',
          },
          title: 'Settings',
          icon: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/settings.svg',
          pendoTag: 'nav dropdown - settings',
        },
        {
          onclick: 'logout',
          props: {},
          title: 'Log out',
          icon: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/log+out.svg',
          color: 'red',
          pendoTag: 'nav dropdown - logout',
        },
      ];
    } else {
      navigationItems = [
        {
          props: {
            to: '/member/settings',
          },
          title: 'Settings',
          icon: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/settings.svg',
          pendoTag: 'nav dropdown - settings',
        },
        {
          onclick: 'logout',
          props: {},
          title: 'Log out',
          icon: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/log+out.svg',
          color: 'red',
        },
      ];
    }

    // TODO: Delete this once Jackie is done testing. Should be 1 week from 10/31/2024
    if (context.auth.getCurrentUser().origin === 'Morehouse Healthcare Landing Page') {
      navigationItems.unshift({
        props: {
          to: `/partners`,
        },
        title: 'Find a mentor',
        icon: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/community.svg',
        pendoTag: 'nav-dropdown-community',
      });
    }
  }

  if (
    context.auth.isLoggedIn() &&
    !context.auth.getCurrentUser().is_super_user &&
    context.auth.getCurrentUser().true_user &&
    context.auth.getCurrentUser().true_user.is_super_user
  ) {
    navigationItems.push({
      onclick: 'stopImpersonating',
      title: 'Stop Impersonating',
      color: 'red',
    });
  }

  let homeLogoRedirect = '/';
  if (context.auth.isLoggedIn()) {
    homeLogoRedirect = '/member/home';
  }

  return (
    <header
      className={`${headerSticky === true ? 'nosticky' : ''} platform-header member_platform_header`}
      id='hdr-content'
    >
      <div className='container-fluid'>
        <div className='header-outr row'>
          <div className={`${isMobile ? 'col-4' : 'col-2'}`}>
            <div className='hdr-logo'>
              {window.location.pathname.includes('/member/join') ? (
                <img
                  alt='logo'
                  id='logo'
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/logos/svg/pp-logo.svg'
                  data-gtm-click-label='Nav Logo'
                />
              ) : (
                <Link data-pendo-tag='navigation - logo' to={homeLogoRedirect}>
                  <img
                    alt='logo'
                    id='logo'
                    src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/logos/svg/pp-logo.svg'
                    data-gtm-click-label='Nav Logo'
                  />
                </Link>
              )}
            </div>
          </div>
          <div className={`text-center ${isMobile ? '' : 'col-7'}`}>
            <div className='header-innr'>
              {!hideLinks && (
                <>
                  {/*
                    <div className="col">
                      <Link to={context.auth.isLoggedIn() && context.auth.getCurrentUser().current_profile_type === 'PatientPartner' ? "/member/discussions" : "/member/home"}>
                        <img
                          alt="home-newsfeed"
                          id="home-newsfeed"
                          src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/home-nav.svg"
                          data-pendo-tag="navigation-home"
                        />
                      </Link>
                    </div>
                    */}

                  <div className='col'>
                    <Link to='/member/conversations'>
                      <img
                        alt='messages'
                        id='messages'
                        src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+16/messages.svg'
                        data-pendo-tag='navigation-conversations'
                      />
                    </Link>
                  </div>
                  <div style={unreadNotificationCount > 0 ? { marginRight: '10px' } : {}} className='col'>
                    {isMobile ? (
                      <Link
                        data-pendo-tag='navigation-notifications'
                        to={
                          isMobile
                            ? `/member/profile/${context.auth.getCurrentUser().slug}/activity?scrollto=true`
                            : `/member/profile/${context.auth.getCurrentUser().slug}/activity`
                        }
                      >
                        <Notifications embedded unreadNotificationCount={unreadNotificationCount} />
                      </Link>
                    ) : (
                      <Notifications
                        data-pendo-tag='navigation-notifications'
                        unreadNotificationCount={unreadNotificationCount}
                      />
                    )}
                  </div>
                </>
              )}

              <div className='col de-pad'>
                <PortalNav profileCircleElement={<SimpleAvatar />} navigationItems={navigationItems} />
              </div>
            </div>
            <div className='navbar-header'>
              <nav
                className='navbar navbar-expand-md navbar-light sidenav'
                id='mySidenav'
                style={{ right: navOpen ? '0px' : '-100%' }}
              >
                {isMobile === true && (
                  <Link onClick={() => setNavOpen(false)} to='/'>
                    {/* <SimpleAvatar className={`mobile-nav-logo ${navOpen ? "active" : ""}`}/> */}
                    {/* <img
                        alt="logo"
                        className={`mobile-nav-logo ${navOpen ? "active" : ""}`}
                        src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/logos/svg/pp-logo.svg"
                        data-gtm-click-label="Nav Logo"
                      /> */}
                  </Link>
                )}
              </nav>
            </div>

            {/* <ul className="navbar-nav">
                {isMobile === false && <>
                  {menuItems.map((menuItem) =>
                    <DesktopNavItem
                      navItem={menuItem}
                    />
                  )}
                </>}
                {isMobile === true && <>
                  {mobileNavItems((style, menuItem) =>
                    <animated.div key={menuItem.mainLink.title} style={{ ...style }} className="mobile-nav-item-animated-wrap">
                      <MobileNavItem
                        navItem={menuItem}
                        setNavOpen={setNavOpen}
                      />
                    </animated.div>
                  )}
                </>}
              </ul> */}
          </div>
        </div>
        {/* <div
            className={`hdr-btn-wrap ${
              isMobile ? (isSmallMobile ? "col-6" : "col-7") : "col-1"
            }`}
          >
            <div style={{ width: "100%" }} className="hdr-btn-innr in-platform">
              {!isMobile && <PortalNav navigationItems={navItems} />}
            </div>
          </div>
          <div
            style={
              isSmallMobile
                ? {}
                : { width: "max-content", maxWidth: "max-content" }
            }
            className={`${isMobile ? "col-1" : ""}`}
            className="col-1"
          >
            <svg
              className={`tgl-innr ${navOpen ? "active" : ""}`}
              onClick={() => {
                setNavOpen(!navOpen);
              }}
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 0.482178C6.71371 0.482178 0 7.17191 0 15.4286C0 23.6853 6.71371 30.375 15 30.375C23.2863 30.375 30 23.6853 30 15.4286C30 7.17191 23.2863 0.482178 15 0.482178ZM22.7419 25.8911C20.5706 27.4882 17.8972 28.4465 15 28.4465C12.1028 28.4465 9.42944 27.4882 7.25806 25.8911V25.0715C7.25806 22.944 8.99395 21.2143 11.129 21.2143C11.8004 21.2143 12.7923 21.9014 15 21.9014C17.2137 21.9014 18.1935 21.2143 18.871 21.2143C21.006 21.2143 22.7419 22.944 22.7419 25.0715V25.8911ZM24.5927 24.2337C24.1815 21.4373 21.7923 19.2857 18.871 19.2857C17.631 19.2857 17.0323 19.9728 15 19.9728C12.9677 19.9728 12.375 19.2857 11.129 19.2857C8.20766 19.2857 5.81855 21.4373 5.40726 24.2337C3.26008 21.9134 1.93548 18.8277 1.93548 15.4286C1.93548 8.2507 7.79637 2.41075 15 2.41075C22.2036 2.41075 28.0645 8.2507 28.0645 15.4286C28.0645 18.8277 26.7399 21.9134 24.5927 24.2337ZM15 7.23218C12.0605 7.23218 9.67742 9.60673 9.67742 12.5357C9.67742 15.4648 12.0605 17.8393 15 17.8393C17.9395 17.8393 20.3226 15.4648 20.3226 12.5357C20.3226 9.60673 17.9395 7.23218 15 7.23218ZM15 15.9107C13.131 15.9107 11.6129 14.398 11.6129 12.5357C11.6129 10.6735 13.131 9.16075 15 9.16075C16.869 9.16075 18.3871 10.6735 18.3871 12.5357C18.3871 14.398 16.869 15.9107 15 15.9107Z"
                fill="#314D69"
              ></path>
            </svg>
          </div> */}
      </div>
    </header>
  );
}
