export const createDirectBookingContacts = (name, email, phone, patientPartner, meetingTitle, successFunction) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  fetch('/direct_booking_contacts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({
      ...window.utmInfo,
      name: name,
      email: email,
      phone: phone,
      patient_partner: patientPartner,
      meeting_title: meetingTitle,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successFunction(data);
    });
};
