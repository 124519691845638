import { createConsumer } from '@rails/actioncable';
import uuid from 'react-uuid';

export default class ActionCable {
  constructor() {
    this.subscriptions = {};
  }

  subscribe(channel, params, onReceived, onConnected, onDisconnected) {
    const consumer = createConsumer(gon.action_cable_url);
    const id = uuid();
    this.subscriptions[id] = consumer;

    consumer.subscriptions.create(
      {
        ...params,
        channel: channel,
      },
      {
        connected: () => {
          console.log(`Connected to channel: ${channel}, params: ${JSON.stringify(params)}`);
          if (onConnected) {
            onConnected();
          }
        },
        disconnected: () => {
          console.log(`Disconnected from channel: ${channel}, params: ${JSON.stringify(params)}`);
          if (onDisconnected) {
            onDisconnected();
          }
        },
        received: (data) => {
          console.log(
            `Received from channel: ${channel}, params: ${JSON.stringify(params)}, data: ${JSON.stringify(data)}`
          );
          if (onReceived) {
            onReceived(data);
          }
        },
      }
    );

    return id;
  }

  unsubscribe(id) {
    if (this.subscriptions[id]) {
      this.subscriptions[id].disconnect();
      delete this.subscriptions[id];
    }
  }
}
