export const getProducts = (params = {}, successCallback = () => {}, errorCallback = () => {}) => {
  fetch(`/api/v1/products?${objToQueryString(params)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response.json());
    })
    .then((data) => successCallback(data))
    .catch((data) => errorCallback(data));
};
