import React from 'react';
import styles from './index.module.css';

export default function NoNotifications() {
  return (
    <>
      <img
        className='marg-top-20 marg-bottom-20'
        src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/icon+22/Bitmap.svg'
      />
      <h3 className={styles.title}>No notifications yet</h3>
      <p className={`marg-top-10 marg-bottom-20 ${styles.body}`}>
        You have no notifications, yet. When you engage with members in the community, you'll get notified of updates
        here!
      </p>
    </>
  );
}
