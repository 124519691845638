import React from 'react';
import styles from './index.module.css';

export default function GhostButton(props) {
  return (
    <div className={styles.button} {...props}>
      {props.children}
    </div>
  );
}
