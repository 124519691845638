import React, { useState, useEffect } from 'react';
import Divider from '../../../../../ui_kit/utilities/divider';
import parent_styles from '../../../../index.module.css';

export default function ProceduresPerformed({ surgeon }) {
  return (
    <>
      <p className={parent_styles.profile_section_headline}>Procedures performed</p>
      <div className='content_container'>
        {surgeon.extra.number_of_procedures_performed && (
          <p style={{ fontWeight: 600, fontSize: '18px', color: '#419DA5' }}>
            {surgeon.extra.number_of_procedures_performed}+ procedures performed
          </p>
        )}
        {surgeon.procedure_types && surgeon.procedure_types !== [] && surgeon.procedure_types !== null && (
          <>
            {surgeon.procedure_types.map((p, i) => (
              <div key={i}>
                {(surgeon.extra.number_of_procedures_performed ||
                  (i !== 0 && !surgeon.extra.number_of_procedures_performed)) && <Divider />}
                <p
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    marginTop:
                      surgeon.extra.number_of_procedures_performed ||
                      (i !== 0 && !surgeon.extra.number_of_procedures_performed)
                        ? '12px'
                        : '',
                    color: '#333333',
                  }}
                >
                  {p}
                </p>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}
