import React from 'react';
import './process.css';
import CustomCtaFooter from '../custom_cta_footer';
import ProcessHeader from '../process_header';

export default function Process({}) {
  return (
    <div className='process'>
      <ProcessHeader
        para1={'How it Works'}
        headline={"Support you can count on from someone who's been there"}
        para2={'Connect with a mentor who understands your journey and get personal answers to your questions.'}
      />
      <div className='container steps'>
        <div className='row h-100 marg-top-100 marg-bottom-100'>
          <div className='col-md-5 my-auto '>
            <img
              className='step-image one no-mobile'
              alt='stepone'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/stepone.png'
            />
          </div>
          <div className='col-md-2 my-auto'>
            <div className='step-number one'>01</div>
          </div>
          <div className='col-md-5 my-auto step-text one'>
            <img
              className='step-image yes-mobile'
              alt='stepone'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/stepone.png'
            />
            <h2 className='marg-bottom-20'>Tell us about yourself</h2>
            <p>
              Complete a questionnaire to be matched with your dedicated mentor who has experienced a similar journey.
            </p>
          </div>
        </div>
        <div className='row h-100 marg-top-100 marg-bottom-100'>
          <div className='col-md-5 my-auto step-text two third-mob'>
            <img
              className='step-image yes-mobile'
              alt='stepone'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/process2.jpg'
            />
            <h2 className='marg-bottom-20'>Meet your mentor</h2>
            <p>
              We'll connect you with someone who has been through a similar journey and understands what you're
              experiencing. They can share their personal insights and answer your questions from firsthand experience.
            </p>
          </div>
          <div className='col-md-2 my-auto second-mob'>
            <div className='step-number two'>02</div>
          </div>
          <div className='col-md-5 my-auto first-mob'>
            <img
              className='step-image two no-mobile'
              alt='stepone'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/process2.jpg'
            />
          </div>
        </div>
        <div className='row h-100 marg-top-100 marg-bottom-100'>
          <div className='col-md-5 my-auto'>
            <img
              className='step-image three no-mobile'
              alt='stepone'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/steptwo.png'
            />
          </div>
          <div className='col-md-2 my-auto'>
            <div className='step-number three'>03</div>
          </div>
          <div className='col-md-5 my-auto step-text three'>
            <img
              className='step-image yes-mobile'
              alt='stepone'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/steptwo.png'
            />

            <h2 className='marg-bottom-20'>Begin your connection</h2>
            <p>
              Connect with your mentor through our secure messaging platform. Ask questions, get practical tips, and
              feel more confident about your journey ahead. Your mentor is there to guide you through our easy-to-use
              chat feature!
            </p>
          </div>
        </div>
      </div>
      <CustomCtaFooter
        headline='Ready to take the first step?'
        para='It only takes a few minutes to begin your journey with your PatientPartner mentor.'
        buttontxt='Begin questionnaire'
        link='/member/join/support'
        mergeWithFooter='false'
        gtm='Process'
      />
    </div>
  );
}
