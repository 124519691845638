import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Context from '../../../utils/context';
import TextLeftImgRightWbg from '../../hero_sections/txt_left_img_right_wbg';
import ImgLeftTextRight from '../../hero_sections/img_left_text_right';
import TwoTestimonials from '../../two_testimonials';
import HowItWorks from '../../hero_sections/how_it_works';
import { autofocusAll } from '../../../helpers';
import ImgRightTextLeftSimple from '../../hero_sections/img_right_text_left_simple';
import styles from './index.module.css';
import FAQs from '../../ui_kit/utilities/faqs';
import SignUp from '../../sign_up';
import LoadingScreen from '../../loading_screen';
import { createSurgeonReferredMentorMatch } from '../../../api/surgeon_referred_mentor_matches';
import { getSurgeon } from '../../../api/surgeons';

export default function MentorMatchNybg({
  home,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setContactIdAction,
  setPatientPartners,
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();
  const [view, setView] = useState('loading');
  const [surgeon, setSurgeon] = useState();
  const utm_source = useRef(new URLSearchParams(window.location.search).get('utm_source'));
  const history = useHistory();

  const scrollToQuestionnaire = () => {
    document.querySelector('#questionnaire').scrollIntoView({
      behavior: 'smooth',
    });
    const timer = setTimeout(() => {
      autofocusAll();
    }, 1000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    getSurgeon('new-york-bariatric-group', (data) => {
      setSurgeon(data);
      setView('loaded');
    });
  }, []);

  React.useEffect(() => {
    if (new URLSearchParams(window.location.search).get('contact_id')) {
      const timer = setTimeout(() => {
        document.querySelector('#questionnaire').scrollIntoView({
          behavior: 'smooth',
        });
      }, 100);
      return () => clearTimeout(timer);
    }
  }, []);

  const createAndRedirect = () => {
    createSurgeonReferredMentorMatch(
      {
        surgeon_id: surgeon.id,
        surgery: null,
        ...(utm_source.current === 'SurgeonPatientLead' &&
          uuid.current !== null && { surgeon_patient_lead: uuid.current }),
      },
      (match) => {
        history.push(`/member/surgeon-mentor-matches/${match.uuid}/pre-survey`);
      }
    );
  };

  return (
    <div>
      <TextLeftImgRightWbg
        headerImg={`https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/NYBGherologo.png`}
        headline={<h1 className={styles.headline}>Connect with your mentor</h1>}
        para={
          'Connect with New York Bariatric Group’s recovered patients similar to you who understand what you’re going through for free!'
        }
        bg={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/mentormatchnybghero2.jpg'}
        actionContent={
          <div className='btn-wrap'>
            <button
              onClick={scrollToQuestionnaire}
              className='maincta'
              data-gtm-click-label='Mentor match NYBG hero section CTA Get Started'
            >
              Get started
            </button>
          </div>
        }
        imgOffset={false}
        topOfIcon={isMobile ? '65%' : '75%'}
        leftOfIcon={isMobile ? '10%' : '35%'}
        heightOfImage={isMobile && '300px'}
        iconContent={
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 132.05 163.45'>
            <g id='Layer_2' data-name='Layer 2'>
              <g id='Layer_1-2' data-name='Layer 1'>
                <path
                  fill='#74ccd3'
                  className='cls-1'
                  d='M131.9,78.63V38.83c0-5.75-.43-11.32-2.66-16.73A36,36,0,0,0,108.2,2C103.11.19,97.89,0,92.57,0H37.07C19.79,0,3.91,11.21.67,28.77-.11,33,0,37.29,0,41.59V61.06C0,72.67,0,84.29,0,95.9c.08,19.16,15.19,35.35,34.54,35.94,2.44.08,4.87,0,7.31,0l.25,0a3,3,0,0,0,2.7-1.5l.12-.13h0L68,99.44c3.92-.87,7.16-4,9.32-7.57C83.79,81.23,89.81,70.31,96,59.54c2.88-5,6.84-12.13,3.58-17.86-2.52-4.47-8.3-4.24-13.27-4a6.26,6.26,0,1,0,.09,6.69h1.1c1.58,0,5.4-.81,6.45.57s-.46,5.17-1.06,6.55a49.19,49.19,0,0,1-2.78,5.15L73.91,84.77c-1.7,2.93-3.61,7.87-7.4,8.37-3.94.52-6.06-4.15-7.69-7l-16.18-28c-1.87-3.24-6.08-8.58-4.58-12.46.87-2.26,4.84-1.28,6.77-1.28h.23a6.25,6.25,0,0,0,11.59-3.26h0a6.25,6.25,0,0,0-11.59-3.26H43.71c-3.09,0-6.29-.29-8.93,1.61-5.19,3.71-3.41,11.19-1.1,16,2.43,5.11,5.63,9.94,8.46,14.83L52.58,88.43c2.15,3.72,4.36,7.81,8.18,9.9l-20.25,27c-8,0-15.43-.65-22.22-5.68A29.34,29.34,0,0,1,6.57,96c-.1-12.3,0-24.6,0-36.9V39.68c0-5.9.25-11.59,3-17A29.4,29.4,0,0,1,35.49,6.54c3.51,0,7,0,10.52,0H89.68c4.63,0,9.34-.27,13.86.89a29.34,29.34,0,0,1,21.14,21.79c1,4.36.68,9,.68,13.44V64c0,11.24.45,22.57,0,33.81a29.25,29.25,0,0,1-17.26,25.11c-7.5,3.34-14.6,1.66-22.39,2.44-2.32.24-3.06,1.54-4.14,3.29l-4.73,7.68L65.62,154.48l-13-16.71c-2.58-3.31-7.16,1.34-4.62,4.62l15.68,20.08c1.35,1.73,4.14.95,5.13-.65L87.22,131.9c5.73.07,11.63.36,17.21-.92a35.81,35.81,0,0,0,23.17-17.71C133.31,102.72,131.9,90.2,131.9,78.63Z'
                />
              </g>
            </g>
          </svg>
        }
        imgShadow={false}
        thoseDots={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/spine/hero---dots.svg'}
        dotStyles={
          isMobile
            ? { maxWidth: '76px', top: '45px', right: '25px', zIndex: '1' }
            : { maxWidth: '136px', top: '120px', right: '-90px', zIndex: '1' }
        }
        mobileBackgroundSize='contain'
        bgColor='#F1FEFF'
      />
      <div
        style={{ backgroundColor: '#DDF7F9', paddingTop: '35px', paddingBottom: '35px' }}
        className='container-fluid'
      >
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <h3
                className={styles.so_headline}
                style={{
                  fontFamily: 'Utopia Std',
                  fontWeight: 400,
                  fontSize: '30px',
                  lineHeight: '48px',
                  textAlign: 'center',
                  letterSpacing: '0.01em',
                  color: '#314D69',
                }}
              >
                PatientPartner is a complimentary, private, and easy-to-use tool that allows you to ask questions and
                get more insight about the procedure from a New York Bariatric Group mentor, a past patient that went
                through a similar experience.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <ImgLeftTextRight
        img={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/nybgfold2.jpg'}
        headline={'Feel empowered and confident in your decisions!'}
        subheadline={'Things you can ask your mentor privately:'}
        list={[
          <span>What was your recovery like?</span>,
          <span>How quickly did you return to work and exercise?</span>,
          <span>Can you help me stay accountable for the next few weeks?</span>,
          <span>How was the pain level?</span>,
          <span>Tips and tricks for pre-opp?</span>,
        ]}
        littlePara=''
        cta={
          <button
            data-gtm-click-label='Mentor Match NYBG Connecting you to real patients Get connected'
            className='gtm-wl-get-connected'
            onClick={scrollToQuestionnaire}
          >
            Match with my mentor
          </button>
        }
        blueBg={false}
      />
      <HowItWorks
        headline={<h2>Make a friend throughout your process</h2>}
        smallHeadlineStyle={{
          fontFamily: 'HK Grotesk',
          lineHeight: '23px',
          fontSize: '18px',
          color: '#59B6BE',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          letterSpacing: '0.15em',
        }}
        steps={[
          {
            image: <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/about.svg' />,
            headline: 'Create your free account',
            para: 'On behalf of the New York Bariatric Group, you get complimentary access to PatientPartner.',
          },
          {
            image: <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/finasurgeon.svg' />,
            headline: 'Message your mentor',
            para: 'Review the mentor you’ve been matched with and send them a message to introduce yourself and ask questions.',
          },
          {
            image: <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/request.svg' />,
            headline: 'Get support and advice',
            para: 'Ask questions about personal experiences and advice your doctor won’t be able to provide you.',
          },
        ]}
        paraColor={'#232323'}
      />
      <div style={{ marginTop: '50px' }}>
        <TwoTestimonials
          testimonials={[
            {
              text: "I was scared to go through with my surgery for years. By talking to someone who's been through it already, I was able to get over my fears of the unknown. PatientPartner made the process easy and took away any hesitation I had by chatting with someone like me.",
              image:
                'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/mentormatchnybgtestimonial1+(1).jpg',
              name: 'Rose',
              city: 'Long Beach, CA',
            },
            {
              text: 'The doctors can’t answer all of your questions because they haven’t been through it. My mentor was exactly what I needed to make educated decisions and move forward through the process with confidence. This is an indispensable resource you should use!',
              image:
                'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/mentormatchnybgtestimonial2.jpg',
              name: 'Rana',
              city: 'Las Vegas, NV',
            },
          ]}
        />
      </div>
      <div id='questionnaire' className={`${styles.questionnaire_ottr} container`}>
        <div className='row justify-content-center h-100'>
          <div className='col h-100'>
            <div className={`container h-100`}>
              <div className={`row h-100 ${styles.cont_row}`}>
                <div className='col-sm-2'></div>
                <div className='col-sm-8'>
                  {view === 'loading' && <LoadingScreen />}
                  {view === 'loaded' && (
                    <SignUp
                      onSuccess={createAndRedirect}
                      params={{ keap_force_sync: true, origin: 'Surgeon Referred Mentor Match' }}
                      headline={`Get matched to your patient mentor!`}
                      para={`Create your account and get your mentor match.`}
                      embedded={true}
                      alignLeft={true}
                      light={false}
                      captureFirstLastName
                    />
                  )}
                </div>
                <div className='col-sm-2'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
