import React, { Component, useEffect, useState } from 'react';
import './index.css';
import PatientPartnerColumn from '../patient_partner_column';
import uuid from 'react-uuid';
import Slider from 'react-slick';

export default function PatientPartnerCarousel({
  patientPartners,
  actionContentFunc,
  setSlidesToShow = 3,
  showWeightLoss = false,
  showAge = false,
  showSurgerySpecialty = false,
  showTitle = false,
  sliderClassName = 'pp-carousel',
}) {
  const id = `carousel_${uuid()}`;
  const prevArrowId = `prevArrowId_${uuid()}`;
  const nextArrowId = `nextArrowId_${uuid()}`;

  const settings = {
    className: 'pp-carousel',
    lazyLoad: true,
    infinite: false,
    arrows: false,
    slidesToShow: setSlidesToShow,
    slidesToScroll: 1,
    dots: true,
    beforeChange: (oldIndex, newIndex) => {
      if (newIndex === patientPartners.length - 2) {
        setNextFadeOut(true);
      } else {
        setNextFadeOut(false);
      }
      if (newIndex === 0) {
        setPrevFadeOut(true);
      } else {
        setPrevFadeOut(false);
      }
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 561,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [nextFadeOut, setNextFadeOut] = useState(false);
  const [prevFadeOut, setPrevFadeOut] = useState(false);

  let currentSlider;
  const prevClick = () => {
    currentSlider.slickPrev();
  };

  const nextClick = () => {
    currentSlider.slickNext();
  };

  useEffect(() => {
    if (
      document.querySelector('.' + sliderClassName + ' .slick-track > .slick-slide:first-child').classList[1] ==
      'slick-active'
    ) {
      setPrevFadeOut(true);
    }
  }, []);

  return (
    <div className='pos-rel'>
      <div
        data-gtm-click-label='Questionnaire Left Arrow'
        id={prevArrowId}
        onClick={prevClick}
        className={`prev-arrow ${prevFadeOut ? 'no-opacity' : ''}`}
      >
        <svg xmlns='http://www.w3.org/2000/svg' width='11.253' height='19.682' viewBox='0 0 11.253 19.682'>
          <path
            id='Icon_ionic-ios-arrow-back'
            data-name='Icon ionic-ios-arrow-back'
            d='M14.643,16.032,22.09,8.591A1.407,1.407,0,1,0,20.1,6.6L11.66,15.036a1.4,1.4,0,0,0-.041,1.939l8.473,8.49a1.407,1.407,0,0,0,1.992-1.986Z'
            transform='translate(-11.251 -6.194)'
            fill='#74CCD3'
            data-gtm-click-label='Patient Partner Carousel Left Arrow'
          />
        </svg>
      </div>
      <div
        data-gtm-click-label='Questionnaire Right Arrow'
        id={nextArrowId}
        onClick={nextClick}
        className={`next-arrow ${nextFadeOut ? 'no-opacity' : ''}`}
      >
        <svg xmlns='http://www.w3.org/2000/svg' width='11.294' height='19.754' viewBox='0 0 11.294 19.754'>
          <path
            id='Icon_ionic-ios-arrow-back'
            data-name='Icon ionic-ios-arrow-back'
            d='M14.655,16.068,22.13,8.6a1.412,1.412,0,0,0-2-1.994l-8.468,8.462a1.409,1.409,0,0,0-.041,1.947l8.5,8.521a1.412,1.412,0,0,0,2-1.994Z'
            transform='translate(-11.251 -6.194)'
            fill='#74CCD3'
            data-gtm-click-label='Patient Partner Carousel Right Arrow'
          />
        </svg>
      </div>
      <Slider ref={(slider) => (currentSlider = slider)} {...settings}>
        {patientPartners.map((partner, index) => (
          <div key={index}>
            <PatientPartnerColumn
              partner={partner}
              actionContent={actionContentFunc(partner)}
              index={index + 1}
              showWeightLoss={showWeightLoss}
              showAge={showAge}
              showSurgerySpecialty={showSurgerySpecialty}
              showTitle={showTitle}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}
