import React from 'react';
import ChooseManyOptions from '../choose_many_options';

export default function WhereBackPain({ answers, onSubmit, onBack, gtm }) {
  return (
    <ChooseManyOptions
      answers={answers}
      keyForAnswer={'whereBackPain'}
      onSubmit={onSubmit}
      onBack={onBack}
      gtm={`${gtm} Where are you experiencing your back pain?`}
      options={['Neck (Cervical)', 'Upper back (Thoracic)', 'Lower back (Lumbar)']}
      title={`Where are you experiencing your back pain? Select any that apply.`}
      para={`This information will help the specialist understand how they can support you.`}
      otherItemLabel={`areas`}
      submitCta={`Next`}
    />
  );
}
