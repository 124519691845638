import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styles from './index.module.css';

export default function PhoneNumberInput({
  phone,
  setPhone,
  style = {},
  phoneOverrideStyle = {},
  light,
  onBlur,
  required,
}) {
  return (
    <div className={`${styles.phoneInputWrapper} material-input`} style={phoneOverrideStyle}>
      <PhoneInput
        value={phone}
        defaultCountry='US'
        onChange={setPhone}
        placeholder='Phone Number'
        onBlur={onBlur}
        className={`${light ? styles.light : ''}`}
        required={required}
        style={style}
      />
    </div>
  );
}
