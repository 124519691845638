import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import SimpleAvatar from '../ui_kit/utilities/simple_avatar';
import Comment from './comments';
import { getPost } from './../../api/posts';
import PostComment from './post_comment';
import UrlPreview from './url_preview';
import ButtonRow from './button_row';
import ImageGridDisplay from '../ui_kit/utilities/image_grid_display';
import LoadingScreen from '../loading_screen';
import { MetaTags } from 'react-meta-tags';
import Divider from './../ui_kit/utilities/divider';
import Video from './custom_post_content/video';
import Image from './custom_post_content/image';
import DOMPurify from '../../utils/purify.min.js';

const CUSTOM_POST_COMPONENTS = {
  video: Video,
  image: Image,
};

export default function Post({ id, post, removePost, defaultShowComments, commentToHightlight }) {
  const linkifyHtml = require('linkifyjs/html');
  const [innrPost, setInnrPost] = useState(post);
  const [showComments, setShowComments] = useState(defaultShowComments || false);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [lines, setLines] = useState(0);

  const refreshPost = (newShowComments) => {
    setIsLoading(true);
    getPost(innrPost.uuid, newShowComments || showComments, (data) => {
      setInnrPost(data);
      setIsLoading(false);
    });
  };

  if (post === undefined) {
    useEffect(() => {
      setIsLoading(true);
      getPost(id, showComments, (data) => {
        setInnrPost(data);
        setIsLoading(false);
      });
    }, [id]);
  }

  useEffect(() => {
    if (innrPost && !window.location.pathname.includes(`/post/${innrPost.uuid}`)) {
      setLines(document.getElementById(`post-body-${innrPost.uuid}`).offsetHeight / 20);
    }
  }, []);

  if (innrPost === undefined || innrPost === null) {
    return <></>;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  const customPostData = innrPost.custom_post_data;
  let CustomPostComponent = null;
  if (customPostData) {
    CustomPostComponent = CUSTOM_POST_COMPONENTS[customPostData.type];
  }

  return (
    <>
      {window.location.href === innrPost.share_url && (
        <MetaTags>
          <title>{innrPost.body}</title>
          <meta property='og:title' content={innrPost.body} />
          {innrPost.post_images[0] && <meta property='og:image' content={innrPost.post_images[0].image} />}
          <meta property='og:url' content={innrPost.share_url} />
        </MetaTags>
      )}
      <div key={`post-${id}`} className={`content_container container`}>
        <SimpleAvatar
          showInfo
          profile={innrPost.profile}
          showTime={!innrPost.custom_post_data || false}
          post={innrPost}
          className={styles.avatar}
          showTypeIcon
          showProfileTooltip={!innrPost.custom_post_data || false}
          userSlug={innrPost.custom_post_data ? null : innrPost.user_slug}
        />
        <p
          id={`${innrPost ? `post-body-${innrPost.uuid}` : null}`}
          style={{ WebkitLineClamp: lines > 7 ? '7' : '' }}
          className={`${styles.description} ${active ? '' : styles.inactive} `}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(linkifyHtml(innrPost.body.replaceAll('\n', '<br/>'), { target: 'blank' })),
          }}
        ></p>
        {lines > 7 && (
          <p
            onClick={() => setActive(!active)}
            className={`text-link marg-bottom-10 `}
          >{`${active ? 'See less' : 'See more'}`}</p>
        )}
        {innrPost.post_images.length < 1 && innrPost.url_preview_metadata && (
          <UrlPreview metadata={innrPost.url_preview_metadata} />
        )}
        {innrPost.post_images && <ImageGridDisplay photos={innrPost.post_images.map((i) => i.image)} />}
        {CustomPostComponent && <CustomPostComponent post={innrPost} {...customPostData} />}
        <ButtonRow
          refreshPost={refreshPost}
          post={innrPost}
          showComments={() => {
            setShowComments(!showComments);
            if (!showComments) {
              getPost(innrPost.uuid, true, (data) => setInnrPost(data));
            }
          }}
          removePost={removePost}
        />
        <Divider />
        {innrPost.can_reply && (
          <Comment
            post={innrPost}
            refresh={() => {
              setShowComments(true);
              refreshPost(true);
            }}
            setShowComments={setShowComments}
          />
        )}
        {showComments &&
          innrPost.comment_tree &&
          innrPost.comment_tree.map((comment, index) => (
            <div key={`post-comment-${index}`} className={styles.comment_otr}>
              <PostComment
                key={comment.uuid}
                comment={comment}
                refreshParentPost={refreshPost}
                index={index}
                commentToHightlight={commentToHightlight}
                length={innrPost.comment_tree.length}
                showPath={false}
              />
            </div>
          ))}
      </div>
    </>
  );
}
