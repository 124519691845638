import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';
import { getPatientAssistantInfo, updatePatientAssistantInfo } from '../../../api/patient_assistant_infos';
import Context from '../../../utils/context';
import Conversation from '../../users/conversation_room/conversation';
import SurgeonAdminPatientPanel from '../../users/conversation_room/profile_panel/surgeon_admin_patient_panel';
import { findConversation } from '../../../api/conversations';
import { sendNote } from '../../../api/patient_assistant_infos';
import ActionCable from 'actioncable';
import { ActionCableProvider, ActionCableConsumer } from 'react-actioncable-provider';
import styles from './index.module.css';
import LoadingScreen from '../../loading_screen';

export default function ProviderPatientShow({}) {
  const [view, setView] = useState('loading');
  const [data, setData] = useState({});
  const [conversation, setConversation] = useState(null);
  const [viewSetter, setViewSetter] = useState(null);
  const { patientAssistantInfoId } = useParams();
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();
  const cable = ActionCable.createConsumer(gon.action_cable_url);
  const viewport = context.viewport;
  let history = useHistory();
  let path = useRouteMatch();

  useEffect(() => {
    getPatientAssistantInfos();
  }, []);

  const getPatientAssistantInfos = () => {
    getPatientAssistantInfo(
      patientAssistantInfoId,
      (data) => {
        setData(data);
        if (data.conversation && data.conversation.uuid) {
          findConversation(data.conversation.uuid, (conversation) => {
            setConversation(conversation);
            setView('loaded');
            if (document.querySelector('.log-container') !== null) {
              document.querySelector('.log-container').scrollTop =
                document.querySelector('.log-container').scrollHeight;
            }
          });
        }
      },
      () => {
        history.push('/provider/patients');
      }
    );
  };

  const updatePatientAssistantInfos = (params) => {
    updatePatientAssistantInfo(patientAssistantInfoId, params.new_status, (data) => {
      setData(data);
      if (data.conversation && data.conversation.uuid) {
        findConversation(data.conversation.uuid, (conversation) => {
          setConversation(conversation);
          setView('loaded');
        });
      }
    });
    sendNote(patientAssistantInfoId, 'PatientAssistantInfoLogs::NewStatus', params, (data) => console.log(data));
  };

  const messagedPatient = () => {
    sendNote(patientAssistantInfoId, 'PatientAssistantInfoLogs::MessagedPatient', {}, (data) => console.log(data));
  };

  const fetchConversation = (uuid = null) => {
    let _uuid = uuid;
    if (data && data.conversation && (data.conversation.uuid !== null || data.conversation.uuid !== undefined)) {
      _uuid = data.conversation.uuid;
    }
    findConversation(
      _uuid,
      (conversation) => {
        setConversation(conversation);
      },
      () => {
        history.push('/provider/patients');
        setConversation(null);
      }
    );
  };

  const isMobile = viewport.getIsMobile();
  const platformHeight = viewport.getPlatformHeight();

  const updateView = (view) => {
    if (isMobile === false) {
      setViewSetter(true);
    } else if (isMobile === true && view === undefined) {
      setViewSetter('conversation');
    }
  };

  useEffect(() => {
    viewport.update();
  }, []);

  useEffect(() => {
    updateView();
  }, [isMobile]);

  const updateRoom = (data) => {
    switch (data.type) {
      case 'message':
        fetchConversation();
        break;
      case 'log':
        getPatientAssistantInfos();
        break;
      default:
        return;
    }
  };

  if (view === 'loading')
    return (
      <div style={{ margin: '0px auto', display: 'block' }}>
        <LoadingScreen />
      </div>
    );

  if (view === 'loaded' && isMobile) {
    return (
      <>
        <ActionCableProvider cable={cable}>
          {conversation !== null && (
            <ActionCableConsumer
              channel={{ channel: 'ConversationsChannel', ids: [conversation.uuid] }}
              onReceived={(data) => {
                console.log(data);
                updateRoom(data);
              }}
            />
          )}
          <Switch>
            <Route exact path={path.path}>
              <div className='col-md-6 chat-room' style={{ flex: '1' }}>
                {conversation && (
                  <Conversation
                    conversation={conversation}
                    user={user}
                    fetchConversation={fetchConversation}
                    platformHeight={platformHeight}
                    otherUser={conversation.other_user_conversations[0]}
                    sendFunction={messagedPatient}
                    backPath={'/provider/patients'}
                    profilePath={`${window.location.pathname}/patient`}
                  />
                )}
              </div>
            </Route>
            <Route exact path={`${path.path}/patient`}>
              {conversation && (
                <SurgeonAdminPatientPanel
                  patientAssistantInfo={data}
                  updatePatientAssistantInfos={updatePatientAssistantInfos}
                  sendNote={sendNote}
                  update={getPatientAssistantInfos}
                  onBackClick={() => history.push(`/provider/patients/${data.uuid}`)}
                />
              )}
            </Route>
          </Switch>
        </ActionCableProvider>
      </>
    );
  }

  let backLink = '/provider/patients';
  if (new URLSearchParams(window.location.search).get('from') === 'mentor-connect') {
    backLink = '/provider/mentor-connect';
  }

  return (
    <>
      {view === 'loaded' && (
        <ActionCableProvider cable={cable}>
          {conversation !== null && (
            <ActionCableConsumer
              channel={{ channel: 'ConversationsChannel', ids: [conversation.uuid] }}
              onReceived={(data) => {
                console.log(data);
                updateRoom(data);
              }}
            />
          )}
          <div className={`container-fluid ${styles.conversation_room_bg}`}>
            <p className={`text-link ${styles.back}`}>
              <Link to={backLink} data-gtm-click-label='Practice Convo Page Back To All Patients'>
                Back to all patients
              </Link>
            </p>
            <div className='container conversation-room-innr'>
              <div className='row'>
                {(viewSetter === true || viewSetter === 'conversation') && (
                  <div className='col-md-6 chat-room'>
                    {conversation && (
                      <Conversation
                        conversation={conversation}
                        user={user}
                        onProfileClick={() => setViewSetter('right-panel')}
                        fetchConversation={fetchConversation}
                        platformHeight={platformHeight}
                        otherUser={conversation.other_user_conversations[0]}
                        sendFunction={messagedPatient}
                        onBackClick={(data) => {
                          history.push(`/provider/patients`);
                        }}
                      />
                    )}
                  </div>
                )}
                {(viewSetter === true || viewSetter === 'right-panel') && (
                  <div style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '92vh' }} className='col-md-6'>
                    {conversation && (
                      <SurgeonAdminPatientPanel
                        patientAssistantInfo={data}
                        updatePatientAssistantInfos={updatePatientAssistantInfos}
                        sendNote={sendNote}
                        update={getPatientAssistantInfos}
                        onBackClick={() => setViewSetter('conversation')}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </ActionCableProvider>
      )}
    </>
  );
}
