import React, { useContext, useState } from 'react';
import Context from '../../../../utils/context';
import ProfileTooltipWrapper from '../../../users/profile_tooltip_wrapper';
import MentorTag from '../../../profile/profile_panel/profile_panel_sections/mentor_tag';
import LocationDisplay from '../../../profile/profile_panel/profile_panel_sections/location_display';
import { Link } from 'react-router-dom';
import styles from './index.module.css';

export default function SimpleAvatar({
  profile = null,
  showInfo = false,
  showTime = false,
  post = null,
  showTypeIcon = false,
  colDisplay = false,
  showProfileTooltip = false,
  userSlug = null,
  size = '40px',
  showProcedure = null,
  showLocationIcon = false,
  changeNameColor,
  editing = false,
}) {
  const context = useContext(Context);

  if (profile == null && context.auth.isLoggedIn()) {
    profile = context.auth.getCurrentUser().current_profile;
  }

  const renderAvatar = () => (
    <div className='avatar-holder'>
      <div
        className='chat-room-pp-avatar'
        style={{
          backgroundImage: `url(${profile.photo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: size,
          width: size,
        }}
      />
    </div>
  );

  const renderName = () => <p className={`${changeNameColor ? styles.name1 : styles.name}`}>{profile.display_name}</p>;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} data-active-id={profile.id}>
      {showProfileTooltip && (
        <ProfileTooltipWrapper profile={profile} userSlug={userSlug}>
          {userSlug ? <Link to={`/member/profile/${userSlug}/about-me`}>{renderAvatar()}</Link> : renderAvatar()}
        </ProfileTooltipWrapper>
      )}
      {!showProfileTooltip &&
        (userSlug && !editing ? (
          <Link to={`/member/profile/${userSlug}/about-me`}>{renderAvatar()}</Link>
        ) : (
          renderAvatar()
        ))}
      {showInfo && (
        <div className='col' style={{ position: 'relative' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {userSlug ? (
              <Link
                data-pendo-link={`Member name on post - ${profile.display_name}`}
                to={`/member/profile/${userSlug}/about-me`}
              >
                {renderName()}
              </Link>
            ) : (
              renderName()
            )}

            {showTypeIcon && profile && profile.type && profile.type === 'PatientPartner' && <MentorTag />}
            {showTime && <p className={styles.date}>{post.how_long_posted}</p>}
          </div>
          {profile.display_location && (
            <LocationDisplay showIcon={showLocationIcon} location={profile.display_location} />
          )}
          {showProcedure && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p className={styles.procedure_type}>
                {profile.procedures.length > 1
                  ? [...new Set(profile.procedures)].map((p) => p).join(', ')
                  : profile.procedures.map((p) => p)}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
