import React from 'react';
import { completePaymentIntent } from '../../../../api/payments';

export default function StripePaymentSuccessCatch({ callback = () => {} }) {
  React.useEffect(() => {
    let paymentIntent = new URLSearchParams(window.location.search).get('payment_intent');
    let redirectStatus = new URLSearchParams(window.location.search).get('redirect_status');
    if (paymentIntent != null && redirectStatus === 'succeeded') {
      completePaymentIntent(paymentIntent);
      callback();
    }
  }, [window.location.pathname]);

  return <></>;
}
