import React, { useState } from 'react';
import DatePickerWrapper from '../../../date_picker_wrapper';

export default function WhenIsConsultation({ onBack, onSubmit, title, para, keyForAnswer, yesState, answers, gtm }) {
  const [apptDate, setApptDate] = useState(answers[keyForAnswer] || '');

  const submit = (e) => {
    e.preventDefault();
    onSubmit({ [keyForAnswer]: apptDate }, yesState);
  };

  const getNextWork = (d) => {
    d.setDate(d.getDate() + 1); // tomorrow
    if (d.getDay() == 0) d.setDate(d.getDate() + 1);
    else if (d.getDay() == 6) d.setDate(d.getDate() + 2);
    return d;
  };
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const minDate = getNextWork(getNextWork(new Date()));

  return (
    <div>
      <h5>{title}</h5>
      <p>{para}</p>
      <DatePickerWrapper
        selected={apptDate}
        minDate={minDate}
        filterDate={isWeekday}
        onChange={(date) => setApptDate(date)}
        wrapperClassName={'react-datepicker-wrapper material-input light'}
        placeholderText={'Select date'}
      />
      <div className='container'>
        <div className='row button-holder'>
          <button className='back col-lg-6' type='button' onClick={onBack} data-gtm-click-label={`${gtm} - Back`}>
            Back
          </button>
          <button
            disabled={`${apptDate === '' ? 'disabled' : ''}`}
            className={`gtm-preexisting-conditions submit col-lg-6`}
            type='submit'
            onClick={(e) => submit(e)}
            data-gtm-click-label={`${gtm} - Next`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
