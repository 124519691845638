import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoadingScreen from '../../../loading_screen';
import SuccessPanel from '../../../success_panel';
import { getSurgeonReferredMentorMatchFromProfile } from '../../../../api/profiles';
import { requestAppointment } from '../../../../api/surgeon_referred_mentor_matches';
import { getSurgeon } from '../../../../api/surgeons';
import SurgeonsColumn from '../../../surgeons_column';

export default function WomensHealthMyDoctorPanel({ user }) {
  const [view, setView] = useState('loading');
  const [surgeonReferredMentorMatch, setSurgeonReferredMentorMatch] = useState();
  const [surgeon, setSurgeon] = useState();
  const [isMobile, setDesktop] = useState(window.innerWidth < 768);

  useEffect(() => {
    getSurgeonReferredMentorMatchFromProfile({ do_not_create: true, surgeon_id: gon.hologic_surgeon_id }, (data) => {
      setSurgeonReferredMentorMatch(data);
      if ((data || {}).matched_surgeon_id) {
        getSurgeon(data.matched_surgeon_id, (s) => {
          setSurgeon(s);
          setView('surgeon');
        });
      } else {
        setView('surgeon-match');
      }
    });
  }, []);

  const requestAppointmentClick = () => {
    if (surgeonReferredMentorMatch) {
      requestAppointment(surgeonReferredMentorMatch.uuid, () => {
        setView('requested-appointment');
      });
    }
  };

  if (view === 'loading') {
    return <LoadingScreen />;
  }

  if (view === 'surgeon-match') {
    return (
      <div className='container'>
        <div className='row justify-content-center marg-top-10'>
          <h5 className='col-11'>Find a Specialist</h5>
        </div>
        <div className='row justify-content-center marg-bottom-20'>
          <p className='col-11'>
            Find a doctor in your area who accepts your insurance and can provide expert advice on your symptoms and
            treatment options.
          </p>
        </div>
        <div className='row justify-content-center marg-bottom-20'>
          <Link to='/procedures/uterine-fibroids/surgeon-match'>
            <button className=''>Find a physician for me</button>
          </Link>
        </div>
      </div>
    );
  }

  if (view === 'surgeon') {
    return (
      <div className='container'>
        <div className='row justify-content-center marg-bottom-20 marg-top-15'>
          <p className='col-11'>
            {user.name}, based on your insurance, location, and health conditions, {surgeon.name} is your recommended
            specialist.
          </p>
        </div>
        <div className='marg-bottom-20 marg-top-15'>
          <SurgeonsColumn
            surgeon={surgeon}
            showBedsideManner={false}
            showFullLocation
            showPhoneNumber
            clickable={false}
            showSeeMore={false}
            cta={
              <div
                onClick={requestAppointmentClick}
                style={isMobile ? { display: 'flex', flexDirection: 'column-reverse' } : {}}
                className='marg-top-20 link-container'
              >
                <button className={`liner`} style={isMobile ? { width: '100%' } : { marginRight: '5px' }}>
                  Request an appointment
                </button>
              </div>
            }
          />
        </div>
      </div>
    );
  }

  if (view === 'requested-appointment') {
    return (
      <div className='container' style={{ paddingTop: '20px' }}>
        <div className='row'>
          <div className='col'>
            <SuccessPanel
              title='Thank you!'
              content={
                <>
                  <p style={{ marginBottom: '10px' }}>
                    Your appointment request has been submitted. You will receive a call from one of our Patient Support
                    Specialists to help you schedule your appointment shortly.
                  </p>
                </>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}
