import React, { useState, useEffect } from 'react';
import DatePickerWrapper from '../../date_picker_wrapper';
import Todpicker from '../../todpicker';
import ErrorMessage from '../../ui_kit/utilities/error_message';
import { validatePhone } from '../../../helpers';

export default function DateTime({
  onSubmit,
  answers,
  title,
  subtitle = 'Select a day and time, then verify your contact information.',
  datePlaceholder = 'Preferred appt. date',
  timePlaceholder = 'Preferred appt. time',
  gtm,
  capturePhone = false,
}) {
  const [disabled, setDisabled] = useState('disabled');
  const [error, setError] = useState('');

  const getNextWork = (d) => {
    d.setDate(d.getDate() + 1); // tomorrow
    if (d.getDay() == 0) d.setDate(d.getDate() + 1);
    else if (d.getDay() == 6) d.setDate(d.getDate() + 2);
    return d;
  };
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const minDate = getNextWork(getNextWork(new Date()));
  const [apptDate, setApptDate] = useState(answers.apptDate || '');
  const [apptTimeOfDay, setApptTimeOfDay] = useState(answers.apptTimeOfDay || timePlaceholder);
  const [phone, setPhone] = useState(answers.phone || '');
  var checkedPhone = validatePhone(phone);

  const validatePhoneOnBlur = () => {
    if (phone !== '' && !checkedPhone) {
      setError('Enter a valid phone number');
    } else {
      setError('');
    }
  };

  const validate = () => {
    if (
      apptDate !== '' &&
      apptDate !== null &&
      apptTimeOfDay !== timePlaceholder &&
      (checkedPhone === true || !capturePhone)
    ) {
      setDisabled('');
      setError('');
    } else {
      setDisabled('disabled');
    }
  };

  useEffect(() => {
    validate();
  }, [apptDate, apptTimeOfDay, phone]);

  const options = [timePlaceholder, 'Morning (9:00-12:00pm)', 'Afternoon (1:00-5:00pm)'];

  const onClick = () => {
    if (capturePhone) {
      onSubmit({ ...answers, apptDate, apptTimeOfDay, phone });
    } else {
      onSubmit({ ...answers, apptDate, apptTimeOfDay });
    }
  };

  return (
    <div>
      <div className='date-time-smallform'>
        <h5 className='text-left'>{title}</h5>
        <hr />
        <p className='marg-top-5 text-left'>{subtitle}</p>
        <DatePickerWrapper
          selected={apptDate}
          onChange={(date) => setApptDate(date)}
          minDate={minDate}
          filterDate={isWeekday}
          wrapperClassName={'text-left material-input'}
          placeholderText={datePlaceholder}
          onBlur={validate}
        />
        <Todpicker
          onBlur={validate}
          value={apptTimeOfDay}
          onChange={(event) => {
            setApptTimeOfDay(event.target.value);
            validate();
          }}
          options={options}
        />
        {capturePhone && (
          <div style={{ maxWidth: '100%', marginBottom: '20px' }} className={`material-input`}>
            <input
              type='tel'
              name='phone'
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              onBlur={validatePhoneOnBlur}
              required
            />
            <span className='highlight'></span>
            <span className='bar'></span>
            <label>Phone Number</label>
          </div>
        )}
        {error && <ErrorMessage error={error} />}
        <button
          className='full-width'
          data-gtm-click-label={`${gtm} Date Time Submit`}
          disabled={disabled}
          onClick={onClick}
        >
          Next
        </button>
      </div>
    </div>
  );
}
