import React, { useContext } from 'react';
import styles from './index.module.css';
import MemberPlatformHeader from '../header/member_platform_header';
import Context from '../../utils/context';
import { shouldHideHeader } from '../../helpers/header_helper';

export default function MemberPlatformNavWrap({ children }) {
  let isMobile = useContext(Context).viewport.getIsMobile();
  let showHeader = !shouldHideHeader(window.location.pathname, isMobile);

  return (
    <div style={{ height: isMobile ? window.innerHeight : null }} className={styles.member_platform_header_wrapper}>
      {showHeader && <MemberPlatformHeader />}
      <div className={styles.member_platform}>{children}</div>
    </div>
  );
}
