export const getSurgeon = (id, successCallback) => {
  fetch(`/surgeons/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getSurgeons = (successCallback) => {
  fetch(`/surgeons/`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getSurgeonMatches = (contactId, successCallback) => {
  fetch(`/weight_loss_surgeon_match_contacts/${contactId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data.contact);
    });
};

export const getConsultationTimes = (params, successCallback) => {
  fetch(`/api/v1/surgeon_virtual_consultation_times?${objToQueryString(params)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
export const setConsultationTimes = (params, successCallback) => {
  fetch(`/api/v1/surgeon_virtual_consultation_times/${params.surgeon_virtual_consultation_time_id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
