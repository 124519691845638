import React, { useState } from 'react';
import styles from '../../users/profile_tooltip_wrapper/index.module.css';

export default function OptionsAndOther({
  onBack,
  onSubmit,
  title,
  para,
  keyForAnswer,
  yesState,
  answers,
  choices = ['foo', 'bar', 'baz'],
  gtm,
  showOther = true,
  selectOne = false,
  otherTxt = 'Type other...',
  ctaTxt = 'Next',
  showBack = true,
  toolTip = false,
  optional = false,
}) {
  let defaultOther = '';
  let defaultSelected = answers[keyForAnswer] || [];
  if (selectOne && answers[keyForAnswer] && !choices.includes(answers[keyForAnswer])) {
    defaultOther = answers[keyForAnswer];
    defaultSelected = [];
  }
  if (
    !selectOne &&
    Array.isArray(answers[keyForAnswer]) &&
    answers[keyForAnswer].filter((a) => !choices.includes(a)).length !== 0
  ) {
    defaultOther = answers[keyForAnswer].filter((a) => !choices.includes(a))[0];
  }
  const [selected, setSelected] = useState(defaultSelected);
  const [other, setOther] = useState(defaultOther);

  React.useEffect(() => {
    setSelected(answers[keyForAnswer] || []);
  }, [answers, keyForAnswer]);

  const submit = (e) => {
    e.preventDefault();
    let newSelected = { [keyForAnswer]: selected };
    if (selectOne && Array.isArray(selected)) {
      newSelected = { [keyForAnswer]: '' };
    }
    if (other !== '') {
      newSelected.other = other;
    }
    onSubmit(newSelected, yesState);
  };

  const onClick = (choice) => () => {
    if (selectOne) {
      if (!Array.isArray(selected) && selected === choice) {
        setSelected([]);
      } else {
        setSelected(choice);
        setOther('');
      }
      return;
    }
    if (selected.includes(choice)) {
      setSelected(selected.filter((c) => c !== choice));
    } else {
      setSelected(selected.concat([choice]));
    }
  };
  const [showTooltip, setShowTooltip] = useState(false);

  const onMouseEnter = () => {
    setShowTooltip(true);
  };

  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  const onOtherChange = (event) => {
    setOther(event.target.value);
    if (selectOne) {
      setSelected([]);
    }
  };

  return (
    <form id='plans'>
      <h5 className='question'>{title}</h5>
      <p onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className='question'>
        {para}
      </p>
      {toolTip && showTooltip && (
        <div style={{ position: 'relative' }}>
          <p
            style={{ color: 'white', backgroundColor: '#1C1E21', wordBreak: 'break-word' }}
            className={`${styles.info_wrap} w-100`}
          >
            1 What are fibroids? UCLA. (n.d.). Retrieved March 8, 2022, from
            https://www.uclahealth.org/fibroids/what-are-fibroids#:~:text=It%20is%20estimated%20that%2070,almost%20always%20benign%2C%20or%20noncancerous.
          </p>
        </div>
      )}
      {choices.map((choice) => (
        <div key={choice} className='tile two-column'>
          <label key={choice} className={`preexisting ${selected.includes(choice) ? 'activeCheckbox' : ''}`}>
            <input onClick={onClick(choice)} type='checkbox' name={choice} value='value' />
            {choice}
          </label>
        </div>
      ))}
      {showOther && (
        <div
          className='marg-top-20 marg-bottom-20 preexisting-conditions material-input light marg-center'
          style={{ display: 'inline-block', maxWidth: '100%' }}
        >
          <input placeholder={otherTxt} type='text' name='other' value={other} onChange={onOtherChange} />
          <span className='bar'></span>
        </div>
      )}
      <div className='container-fluid de-pad'>
        <div className='row button-holder de-pad'>
          {showBack && (
            <button className='back col-lg-6 col' type='button' onClick={onBack} data-gtm-click-label={`${gtm} - Back`}>
              Back
            </button>
          )}
          <button
            disabled={`${selected.length === 0 && !optional && other.length === 0 ? 'disabled' : ''}`}
            className={`gtm-preexisting-conditions submit col-lg-6 col`}
            type='submit'
            onClick={(e) => submit(e)}
            data-gtm-click-label={`${gtm} - Next`}
          >
            {ctaTxt}
          </button>
        </div>
      </div>
    </form>
  );
}
