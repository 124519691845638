import { connect } from 'react-redux';
import {
  setContactIdAction,
  updateAnswersAction,
  setSurgeonMatchQuestionnaireProgress,
  setSurgeon,
} from '../../../actions/home';
import GetStartedV3SurgeonMatch from '../../../components/get_started_v3/surgeon_match';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
  setContactIdAction: (item) => dispatch(setContactIdAction(item)),
  setSurgeonMatchQuestionnaireProgress: (item) => dispatch(setSurgeonMatchQuestionnaireProgress(item)),
  setSurgeon: (item) => dispatch(setSurgeon(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStartedV3SurgeonMatch);
