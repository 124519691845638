import { compact } from '../helpers';

export const getPosts = (params, successCallback) => {
  let profileUri = ``;
  if (params.profile_id) {
    profileUri = `profiles/${params.profile_id}/`;
  }

  fetch(`/api/v1/${profileUri}posts?${objToQueryString(compact(params))}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getPost = (id, includeComments, successCallback) => {
  fetch(`/api/v1/posts/${id}?include_comments=${includeComments.toString()}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const deletePost = (postUuid, successCallback) => {
  fetch(`/api/v1/posts/${postUuid}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const createPost = (params, successCallback) => {
  fetch(`/api/v1/posts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getPostUserLikes = (postId, successCallback) => {
  fetch(`/api/v1/posts/${postId}/post_user_likes`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const updatePost = (id, params, successCallback) => {
  fetch(`/api/v1/posts/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
