import React, { useState, useEffect, useContext, useRef } from 'react';
import MentorMatchReactiv8Common from './common';

export default function MentorMatchReactiv8({
  home,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setContactIdAction,
  setPatientPartners,
}) {
  return (
    <MentorMatchReactiv8Common
      home={home}
      updateAnswersAction={updateAnswersAction}
      setPatientPartnerQuestionnaireProgress={setPatientPartnerQuestionnaireProgress}
      setContactIdAction={setContactIdAction}
      setPatientPartners={setPatientPartners}
      origin={`Surgeon Referred Mentor Match Reactiv8 US`}
    />
  );
}
