import {
  signUp,
  logOut,
  signIn,
  recoverPassword,
  resetPassword,
  userUpdate,
  userImpersonate,
  userStopImpersonate,
} from '../api/users';
import { updateProfile, getRecommendedSurgeon, addMatchedPatientPartner } from '../api/profiles';

export default class Auth {
  constructor(user) {
    this.currentUser = user;
    this.initializePendo();
  }

  getCurrentUser = () => {
    return this.currentUser;
  };

  initializePendo = () => {
    if (!this.currentUser) {
      return;
    }

    if (typeof pendo === 'undefined') {
      return;
    }

    pendo.initialize({
      visitor: {
        id: this.currentUser.id,
        email: this.currentUser.email,
        full_name: this.currentUser.name,
        role: this.currentUser.current_profile_type,

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  };

  userSignUp = (name, email, phone, password, params, onSuccess, onError) => {
    signUp(
      name,
      email,
      phone,
      password,
      params,
      (result) => {
        this.currentUser = result;
        this.initializePendo();
        if (onSuccess) {
          onSuccess(result);
        }
      },
      (errors) => {
        if (onError) {
          onError(errors);
        }
      }
    );
  };

  isLoggedIn = () => {
    var currentUserPresent = Object.keys(this.currentUser).length !== 0;

    if (!currentUserPresent) {
      return false;
    }

    if (this.getCurrentUser().current_profile === null) {
      return false;
    }

    return true;
  };

  userLogOut = () => {
    logOut(() => {
      this.currentUser = {};
      window.location = '/member/login';
    });
  };

  userSignIn = (email, password, rememberMe, onSuccess, onError) => {
    signIn(
      email,
      password,
      rememberMe,
      (user) => {
        this.currentUser = user;
        this.initializePendo();
        onSuccess(user);
      },
      onError
    );
  };

  userRecoverPassword = (email, onSuccess, onError) => {
    recoverPassword(email, onSuccess, onError);
  };

  userResetPassword = (resetPasswordToken, password, onSuccess, onError) => {
    resetPassword(
      resetPasswordToken,
      password,
      (user) => {
        this.currentUser = user;
        onSuccess();
      },
      onError
    );
  };

  userUpdate = (updates, onSuccess, onError) => {
    userUpdate(
      updates,
      (user) => {
        this.currentUser = user;
        if (onSuccess) {
          onSuccess(user);
        }
      },
      onError
    );
  };

  impersonate = (userId, onSuccess, onError) => {
    if (!this.currentUser.is_super_user) {
      return;
    }

    userImpersonate(
      userId,
      (user) => {
        this.currentUser = user;
        onSuccess(user);
      },
      onError
    );
  };

  stopImpersonating = (onSuccess, onError) => {
    if (!this.currentUser.true_user) {
      return;
    }

    userStopImpersonate((user) => {
      this.currentUser = user;
      onSuccess(user);
    }, onError);
  };

  setUnreadNotificationCount = (newUnreadNotificationCount) => {
    if (!this.isLoggedIn()) {
      return;
    }

    if (this.getCurrentUser().current_profile === null) {
      return;
    }

    this.getCurrentUser().current_profile.unread_notification_count = newUnreadNotificationCount;
  };

  updateProfile = (params, successCallback) => {
    if (!this.isLoggedIn()) {
      return;
    }

    updateProfile(this.getCurrentUser().profile_id, params, (newProfile) => {
      this.getCurrentUser().current_profile = newProfile;
      if (successCallback) {
        successCallback(newProfile);
      }
    });
  };

  getRecommendedSurgeon = (callback) => {
    if (!this.isLoggedIn()) {
      return;
    }

    if (this.getCurrentUser().current_profile === null) {
      return;
    }

    getRecommendedSurgeon(callback);
  };

  addMatchedPatientPartner = (callback) => {
    if (!this.isLoggedIn()) {
      return;
    }

    if (this.getCurrentUser().current_profile === null) {
      return;
    }

    addMatchedPatientPartner(callback);
  };
}
