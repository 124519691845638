import React, { useState, useEffect, useContext, useRef } from 'react';
import MentorMatchReactiv8Common from '../reactiv8/common';

export default function MentorMatchReactiv8Aus({
  home,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setContactIdAction,
  setPatientPartners,
}) {
  return (
    <MentorMatchReactiv8Common
      home={home}
      updateAnswersAction={updateAnswersAction}
      setPatientPartnerQuestionnaireProgress={setPatientPartnerQuestionnaireProgress}
      setContactIdAction={setContactIdAction}
      setPatientPartners={setPatientPartners}
      surgeonSlug={'reactiv8-australia'}
      australiaPhone
      countryRestriction={`aus`}
      locationPlaceholder={`Enter location`}
      origin={`Surgeon Referred Mentor Match Reactiv8 AUS`}
    />
  );
}
