import React from 'react';
import './ui_kit.css';
import ErrorMessage from './utilities/error_message';
import IconButtonSvg from './utilities/icon_button_svg';

export default function UiKit({}) {
  return (
    <div className='ui-kit'>
      <h2>Fonts</h2>
      <div id='fonts' className='container marg-top-30'>
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <h5>Heading 5</h5>
        <h6>Heading 6</h6>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit dui dolor facilisis eu fringilla egestas.
          Elementum a at urna, sed. Urna, purus vel sed blandit aliquet porttitor ipsum ut. Integer interdum volutpat,
          ullamcorper consequat lacus lectus cursus eu facilisis. Dis nunc, odio quis vulputate interdum donec. Sed nec
          natoque at sed integer viverra.
        </p>
        <ul>
          <li>List item</li>
        </ul>
      </div>
      <h2 className='ui-title'>Font utilities</h2>
      <div id='fonts-utilities' className='container'>
        <ErrorMessage />
      </div>
      <h2 className='ui-title'>Buttons</h2>
      <div id='buttons' className='container'>
        <button>Register now</button>
        <button className='liner'>Register now</button>
        <p className='text-link'>Register now</p>
        <IconButtonSvg
          icon={
            <svg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M17.1 1.90909H16.15V0H14.25V1.90909H4.75V0H2.85V1.90909H1.9C0.855 1.90909 0 2.76818 0 3.81818V19.0909C0 20.1409 0.855 21 1.9 21H17.1C18.145 21 19 20.1409 19 19.0909V3.81818C19 2.76818 18.145 1.90909 17.1 1.90909ZM17.1 19.0909H1.9V6.68182H17.1V19.0909Z'
                fill='white'
              />
            </svg>
          }
          text='Book a meeting'
        />
      </div>
      <h2 className='ui-title'>Inputs</h2>
      <div id='inputs' className='container'>
        <div className='material-input'>
          <input aria-label='name' type='text' id='name' name='name' required />
          <span className='bar'></span>
          <label>Email</label>
        </div>
        <textarea className='material-input' placeholder='Tell us here...'></textarea>
        <div className='darkwrapper'>
          {/* dont copy this wrapper START */}
          {/* input for dark backgrounds */}
          <div className='material-input light'>
            <input aria-label='name' type='text' id='name' name='name' required />
            <span className='bar'></span>
            <label>Email</label>
          </div>
          <textarea className='material-input light' placeholder='Tell us here...'></textarea>
        </div>
        {/* dont copy this wrapper END */}
      </div>
    </div>
  );
}
