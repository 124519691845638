export const pageInspect = (pageUrl, successFunction) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  fetch(`/api/v1/metainspector/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({
      page_url: pageUrl,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (successFunction) {
        successFunction(data);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
