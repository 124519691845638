export const updateAnswersAction = (item) => {
  return {
    type: 'UPDATE_ANSWERS',
    item,
  };
};

export const setQuestionnaireStateAction = (item) => {
  return {
    type: 'SET_QUESTIONNAIRE_STATE',
    item,
  };
};

export const setContactIdAction = (item) => {
  return {
    type: 'SET_CONTACT_ID',
    item,
  };
};

export const setPatientPartnersAction = (item) => {
  return {
    type: 'SET_PATIENT_PARTNERS',
    item,
  };
};
