import React, { useEffect, useState } from 'react';
import './how_it_works.css';

export default function HowItWorks({
  data,
  showTitle = true,
  leftHead = (
    <h2 className='text-left'>
      Getting answers is <u>easy and free</u>
    </h2>
  ),
  rightHead,
  bublets = [
    {
      icon: (
        <svg width='359' height='344' viewBox='0 0 359 344' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M314.125 22.4375C320.076 22.4375 325.783 24.8014 329.991 29.0093C334.199 33.2171 336.562 38.9242 336.562 44.875V224.375C336.562 230.326 334.199 236.033 329.991 240.241C325.783 244.449 320.076 246.812 314.125 246.812H99.0391C87.1385 246.815 75.7262 251.545 67.3125 259.961L22.4375 304.836V44.875C22.4375 38.9242 24.8014 33.2171 29.0093 29.0093C33.2171 24.8014 38.9242 22.4375 44.875 22.4375H314.125ZM44.875 0C32.9734 0 21.5593 4.72789 13.1436 13.1436C4.72789 21.5593 0 32.9734 0 44.875L0 331.918C0.000471521 334.138 0.659496 336.308 1.89366 338.153C3.12783 339.998 4.88166 341.435 6.93317 342.283C8.98469 343.131 11.2417 343.352 13.4184 342.917C15.5952 342.481 17.5939 341.41 19.1616 339.838L83.1758 275.824C87.3827 271.616 93.0888 269.251 99.0391 269.25H314.125C326.027 269.25 337.441 264.522 345.856 256.106C354.272 247.691 359 236.277 359 224.375V44.875C359 32.9734 354.272 21.5593 345.856 13.1436C337.441 4.72789 326.027 0 314.125 0L44.875 0Z'
            fill='black'
          />
          <path
            d='M67.3125 78.5312C67.3125 75.5559 68.4945 72.7023 70.5984 70.5984C72.7023 68.4945 75.5559 67.3125 78.5312 67.3125H280.469C283.444 67.3125 286.298 68.4945 288.402 70.5984C290.506 72.7023 291.688 75.5559 291.688 78.5312C291.688 81.5066 290.506 84.3602 288.402 86.4641C286.298 88.568 283.444 89.75 280.469 89.75H78.5312C75.5559 89.75 72.7023 88.568 70.5984 86.4641C68.4945 84.3602 67.3125 81.5066 67.3125 78.5312ZM67.3125 134.625C67.3125 131.65 68.4945 128.796 70.5984 126.692C72.7023 124.588 75.5559 123.406 78.5312 123.406H280.469C283.444 123.406 286.298 124.588 288.402 126.692C290.506 128.796 291.688 131.65 291.688 134.625C291.688 137.6 290.506 140.454 288.402 142.558C286.298 144.662 283.444 145.844 280.469 145.844H78.5312C75.5559 145.844 72.7023 144.662 70.5984 142.558C68.4945 140.454 67.3125 137.6 67.3125 134.625ZM67.3125 190.719C67.3125 187.743 68.4945 184.89 70.5984 182.786C72.7023 180.682 75.5559 179.5 78.5312 179.5H190.719C193.694 179.5 196.548 180.682 198.652 182.786C200.756 184.89 201.938 187.743 201.938 190.719C201.938 193.694 200.756 196.548 198.652 198.652C196.548 200.756 193.694 201.938 190.719 201.938H78.5312C75.5559 201.938 72.7023 200.756 70.5984 198.652C68.4945 196.548 67.3125 193.694 67.3125 190.719Z'
            fill='black'
          />
        </svg>
      ),
      head: 'Tell us about yourself',
      para: 'Take a short questionnaire about your health history and needs.',
    },
    {
      icon: (
        <svg viewBox='0 0 45 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M14.8182 32.8889V32.3889H14.3182H4.09091C2.10054 32.3889 0.5 30.7667 0.5 28.7778V4.11111C0.5 2.10326 2.09892 0.5 4.09091 0.5H40.9091C42.8821 0.5 44.4998 2.10467 44.5 4.1107C44.5 4.11083 44.5 4.11097 44.5 4.11111L44.4795 28.7774V28.7778C44.4795 30.7683 42.8774 32.3889 40.9091 32.3889H30.6818H30.1818V32.8889V36.5H14.8182V32.8889ZM40.9091 29.2778H41.4091V28.7778V4.11111V3.61111H40.9091H4.09091H3.59091V4.11111V28.7778V29.2778H4.09091H40.9091Z'
            fill='#203856'
            stroke='white'
          />
          <path
            d='M30.625 23.725H30.725V23.625V22.25C30.725 22.1119 30.6428 21.9722 30.5238 21.8405C30.402 21.7056 30.2273 21.5634 30.0112 21.4192C29.5784 21.1303 28.9662 20.824 28.2407 20.5442C26.7896 19.9845 24.8711 19.525 23 19.525C21.1289 19.525 19.2104 19.9845 17.7593 20.5442C17.0338 20.824 16.4216 21.1303 15.9888 21.4192C15.7727 21.5634 15.598 21.7056 15.4762 21.8405C15.3572 21.9722 15.275 22.1119 15.275 22.25V23.625V23.725H15.375H30.625ZM25.725 11C25.725 9.49477 24.5052 8.275 23 8.275C21.4948 8.275 20.275 9.49477 20.275 11C20.275 12.5052 21.4948 13.725 23 13.725C24.5052 13.725 25.725 12.5052 25.725 11ZM18.1 11C18.1 8.29273 20.2927 6.1 23 6.1C25.7073 6.1 27.9 8.29273 27.9 11C27.9 13.7073 25.7073 15.9 23 15.9C20.2927 15.9 18.1 13.7073 18.1 11ZM13.1 22.25C13.1 21.4555 13.4972 20.7521 14.161 20.1395C14.8254 19.5262 15.7503 19.0107 16.7856 18.596C18.8564 17.7664 21.3438 17.35 23 17.35C24.6562 17.35 27.1436 17.7664 29.2144 18.596C30.2497 19.0107 31.1746 19.5262 31.839 20.1395C32.5028 20.7521 32.9 21.4555 32.9 22.25V25.9H13.1V22.25Z'
            fill='#203856'
            stroke='white'
            strokeWidth='0.2'
          />
        </svg>
      ),
      head: 'Talk to a mentor',
      para: 'Receive recommendations for a relatable PatientPartner Mentor who can tell you about their procedure.',
    },
    {
      icon: (
        <svg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M11.0473 7.79351L11.0451 7.78656L11.0429 7.77965C10.5827 6.38687 10.3333 4.89273 10.3333 3.33333C10.3333 2.04772 9.28562 1 8 1H3.33333C2.04772 1 1 2.04772 1 3.33333C1 16.4056 11.5944 27 24.6667 27C25.9523 27 27 25.9523 27 24.6667V20.0133C27 18.7277 25.9523 17.68 24.6667 17.68C23.1218 17.68 21.6159 17.431 20.2248 16.9719C19.3973 16.6909 18.4671 16.9053 17.8396 17.5329L15.4313 19.9412C12.3428 18.2093 9.77856 15.6534 8.05753 12.57L10.4671 10.1604C11.1154 9.51214 11.3007 8.59979 11.0473 7.79351Z'
            stroke='#314D69'
            strokeWidth='2'
          />
        </svg>
      ),
      head: 'Find a doctor',
      para: 'Use our scheduling tool to connect with your PatientPartner Mentor for a one-on-one call.',
    },
  ],
}) {
  // Listens to know if the device is a mobile device START
  const [isMobile, setDesktop] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  // Listens to know if the device is a mobile device END

  return (
    <div className='container how-it-works marg-top-100 marg-bottom-50'>
      <div className='row h-100'>
        {showTitle && (
          <div className='col-lg-5 my-auto'>
            <div className='left-head'>{leftHead}</div>
          </div>
        )}
        <div className={`my-auto ${showTitle ? 'col-lg-7' : 'col'}`}>
          {rightHead && (
            <div className='row'>
              <div className='col-sm-2 my-auto'></div>
              <div className='col-sm-10 my-auto'>
                <h2 style={{ paddingLeft: `${isMobile ? '0px' : '10px'}` }} className='marg-bottom-30'>
                  {rightHead}
                </h2>
              </div>
            </div>
          )}
          <div className='container bublets'>
            {bublets.map((bublet, index) => (
              <div key={`how-it-works-${index}`} className='row h-100 marg-bottom-40'>
                <div className='col-sm-2 my-auto'>
                  <div className='hiw-bubble'>{bublet.icon}</div>
                </div>
                <div className='col-sm-10 my-auto'>
                  <h3 className='marg-bottom-10'>
                    {index + 1}. {bublet.head}
                  </h3>
                  <p>{bublet.para}</p>
                  <div className='marg-top-10'>{bublet.actionContent}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
