import React, { useState } from 'react';
import ErrorMessage from '../../../ui_kit/utilities/error_message';
import { validateEmail } from '../../../../helpers';

export default function EmailInput({ value, setValue, gtm, style = {}, light, name = 'email' }) {
  var [currentValue, setCurrentValue] = useState(value);

  var [error, setError] = useState('');
  const validateEmailOnBlur = () => {
    var checkedEmail = validateEmail(currentValue);
    if (currentValue !== '' && !checkedEmail) {
      setError('Enter a valid email address');
    } else {
      setError('');
    }
  };

  return (
    <>
      <div style={style} className={`${light ? 'light' : ''} material-input`}>
        <input
          type='email'
          name={name}
          value={currentValue}
          onBlur={validateEmailOnBlur}
          onChange={(event) => {
            setCurrentValue(event.target.value);
            var isValid = validateEmail(event.target.value);
            if (isValid) {
              setValue(event.target.value);
            } else {
              setValue('');
            }
          }}
          required
        />
        <span className='bar'></span>
        <label>Email</label>
      </div>
      {error && <ErrorMessage error={error} />}
    </>
  );
}
