import React, { Component, useState, useEffect } from 'react';
import './video_sec_1.css';
import './video_sec_1_no_bg_L.css';
import FsLightbox from 'fslightbox-react';

export default function VideoSec1NoBgL({
  headline = '',
  subheadline = '',
  bg = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/videobg.png',
  videoLink = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/video/R5+-+Patient+Partner+Program+Value+-+D3+(1).mp4',
  gtm = 'Watch Video Video Type 2',
  margin = '200px auto',
}) {
  const [toggler, setToggler] = useState(false);

  return (
    <div className='container innr-testimonials video-sec-1 no-bg-l'>
      <div className='row'>
        <div className='col'>
          {subheadline !== '' && <h6 className='marg-top-50 sub'>{subheadline}</h6>}
          {headline !== '' && <h2 className='marg-bottom-30'>{headline}</h2>}
        </div>
        <div
          style={{ backgroundImage: 'url(' + bg + ')' }}
          className='video-sec container-fluid video-type-1 video-sec-wrap'
        >
          <div className='video-cover'></div>
          <div className='video-bg'>
            <video autoPlay='autoplay' muted loop playsInline>
              <source src={videoLink} type='video/mp4' />
            </video>
          </div>
          <div className='container content'>
            <div className='row'>
              <div className='col'>
                <svg
                  style={{ margin: margin }}
                  data-gtm-click-label={gtm}
                  onClick={() => setToggler(!toggler)}
                  className='watchbutton'
                  xmlns='http://www.w3.org/2000/svg'
                  width='101.311'
                  height='101.311'
                  viewBox='0 0 101.311 101.311'
                >
                  <g id='Icon_feather-play-circle' data-name='Icon feather-play-circle' transform='translate(-1 -1)'>
                    <path
                      data-gtm-click-label={gtm}
                      id='Path_113'
                      data-name='Path 113'
                      d='M100.311,51.656A48.656,48.656,0,1,1,51.656,3a48.656,48.656,0,0,1,48.656,48.656Z'
                      transform='translate(0 0)'
                      fill='none'
                      stroke='#fff'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='4'
                    />
                    <path
                      data-gtm-click-label={gtm}
                      id='Path_114'
                      data-name='Path 114'
                      d='M15,12,44.193,31.462,15,50.925Z'
                      transform='translate(26.925 20.193)'
                      fill='none'
                      stroke='#fff'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='4'
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <FsLightbox toggler={toggler} sources={[`${videoLink}`]} type='video' />
        </div>
      </div>
    </div>
  );
}
