import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../../../utils/context';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import HowAreYouFeeling from '../../../../questionnaire/cosmetic/how_are_you_feeling';
import ChooseAnOption from '../../../../questionnaire/choose_an_option';
import YesNoLocation from '../../../../questionnaire/yes_no_location';
import YesNoExtra from '../../../../questionnaire/yes_no_extra';
import styles from '../../index.module.css';
import { getSurgeonReferredMentorMatch } from '../../../../../api/surgeon_referred_mentor_matches';
import { updateProfile } from '../../../../../api/profiles';

export default function Xcopri({
  path,
  id,
  surgeonReferredMentorMatch,
  update,
  setQuestionNumber,
  questionNumber,
  answeredFinalQuestion,
  gtm = '',
}) {
  let history = useHistory();
  const context = useContext(Context);
  const [currentSurgeonReferredMentorMatch, setCurrentSurgeonReferredMentorMatch] =
    useState(surgeonReferredMentorMatch);
  const [conversationRating, setConversationRating] = useState('');
  const [comfortLevel, setComfortLevel] = useState('');
  const [confidenceLevel, setConfidenceLevel] = useState('');
  const [feelingNow, setFeelingNow] = useState('');
  const [findDoctor, setFindDoctor] = useState();
  const [profileLocation, setProfileLocation] = useState({});
  const [isReadyForNextStep, setIsReadyForNextStep] = useState('');
  const [anythingElse, setAnythingElse] = useState();
  const [xcopriHesitation, setXcopriHesitation] = useState();

  useEffect(() => {
    getSurgeonReferredMentorMatch(id, (data) => {
      setCurrentSurgeonReferredMentorMatch(data);
      setConversationRating(data.conversation_rating);
      setComfortLevel(data.comfort_level);
    });
  }, []);

  const updateP = (params) => {
    updateProfile(currentSurgeonReferredMentorMatch.profile_id, params);
  };
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <HowAreYouFeeling
          title={`Please rate your conversation with your mentor ${currentSurgeonReferredMentorMatch.patient_partner_name}.`}
          para='Your input helps us ensure you and other patients are getting the support you need. Your mentor will not see your rating.'
          answers={{ conversationRating: conversationRating }}
          numberRating={true}
          onSubmit={(answers) => {
            setConversationRating(answers.conversationRating);
            update({ conversation_rating: answers.conversationRating });
            history.push(`${path}/comfort-level`);
          }}
          star={true}
          icons={[
            { text: 'Very disappointing' },
            { text: 'Disappointing' },
            { text: 'Somewhat helpful' },
            { text: 'Helpful' },
            { text: 'Very helpful' },
          ]}
          keyForAnswer={'conversationRating'}
          textLabels={['Very disappointing', 'Disappointing', 'Somewhat helpful', 'Helpful', 'Very helpful']}
        />
      </Route>
      <Route exact path={`${path}/comfort-level`}>
        <ChooseAnOption
          onSubmit={(answers) => {
            setComfortLevel(answers.comfortLevel);
            update({ comfort_level: answers.comfortLevel });
            history.push(`${path}/next-step`);
          }}
          onBack={() => {
            history.push(`${path}`);
          }}
          answers={{ comfortLevel: comfortLevel }}
          title={`My mentor made me (or the patient) feel more comfortable continuing or starting XCOPRI (cenobamate tablets) CV.`}
          para={'Please rate your level of agreement with the statement above.'}
          keyForAnswer={'comfortLevel'}
          choices={['Strongly agree', 'Agree', 'Neither agree or disagree', 'Disagree', 'Strongly disagree']}
          showOther={false}
          selectOne
        />
      </Route>
      <Route exact path={`${path}/next-step`}>
        <div className={`row activity-question`}>
          <div className='col-sm-2'></div>
          <div className='col-sm-8'>
            {(isReadyForNextStep === '' || isReadyForNextStep === 'Yes') && (
              <YesNoExtra
                onSubmit={(answers) => {
                  setIsReadyForNextStep(answers.isReadyForNextStep);
                  update({ ready_for_next_step: answers.isReadyForNextStep });
                  if (answers.isReadyForNextStep === 'Yes') {
                    history.push(`${path}/anything-else`);
                  }
                }}
                onBack={() => {
                  history.push(`${path}/comfort-level`);
                }}
                answers={{ isReadyForNextStep: isReadyForNextStep }}
                title={`Do you (or the patient) plan on starting or continuing treatment with XCOPRI (cenobamate tablets) CV?`}
                para={`Please rate your level of agreement with the statement above`}
                keyForAnswer={'isReadyForNextStep'}
                ctaText='Next'
                choices={['Yes', 'No', 'Maybe']}
                extraIndex={null}
              />
            )}
            {(isReadyForNextStep === 'No' || isReadyForNextStep === 'Maybe') && (
              <ChooseAnOption
                onSubmit={(answers) => {
                  let results = null;
                  if (answers.other) {
                    results = answers.other;
                  }
                  setXcopriHesitation(results);
                  update({ xcopri_hesitation: results });
                  history.push(`${path}/anything-else`);
                }}
                onBack={() => {
                  setIsReadyForNextStep('');
                }}
                answers={{ xcopriHesitation: xcopriHesitation }}
                title={`What are your hesitations with starting or continuing XCOPRI (cenobamate tablets) CV?`}
                para={'Please write out your answer in the text field below.'}
                keyForAnswer={'xcopriHesitation'}
                choices={[]}
                otherTxt={`Write your answers...`}
                showOther
                ctaTxt={`Next`}
              />
            )}
          </div>
          <div className='col-sm-2'></div>
        </div>
      </Route>
      <Route exact path={`${path}/anything-else`}>
        <ChooseAnOption
          onSubmit={(answers) => {
            let results = answers.anythingElse || [];
            if (answers.other) {
              results.push(answers.other);
            }
            setAnythingElse(results);
            update({ anything_else: results, post_survey_complete: true });
            history.push(`${path}/thank-you`);
          }}
          onBack={() => {
            history.push(`${path}/next-step`);
          }}
          answers={{ anythingElse: anythingElse }}
          title={`Is there anything else we can assist you with? (Please select all that apply)`}
          para={'We will ensure to contact you and provide assistance as needed'}
          keyForAnswer={'anythingElse'}
          choices={[
            'I have more personal questions',
            'I need help with the pharmacy/prescription',
            'I need financial assistance',
            'N/A',
          ]}
          showOther
          ctaTxt={`Submit`}
        />
      </Route>
      <Route exact path={`${path}/thank-you`}>
        <p className={styles.thank_you}>Thank you!</p>
        <p className={styles.thank_you_p}>
          Your feedback is invaluable to us. With it, we can continue to improve the platform and experience for
          patients everywhere.
        </p>
      </Route>
    </Switch>
  );
}
