import React, { useState, useEffect } from 'react';
import Skeleton from '../../../ui_kit/utilities/skeleton';
import styles from './index.module.css';
import ContentLoader from 'react-content-loader';

export default function ConversationRoomLoadingSkeleton({ type = 'desktop' }) {
  if (type === 'mobile') {
    return (
      <>
        <div className={styles.profile_row_wrapper}>
          <Skeleton shape='avatar-w-info' />
        </div>
        <div className={styles.messenger_loading}>
          <ContentLoader
            speed={2}
            width={390}
            height={479}
            viewBox='0 0 390 479'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='321' y='7' rx='0' ry='0' width='53' height='10' />
            <rect x='16' y='86' rx='0' ry='0' width='51' height='10' />
            <rect x='325' y='160' rx='0' ry='0' width='51' height='10' />
            <rect x='321' y='224' rx='0' ry='0' width='51' height='10' />
            <rect x='165' y='327' rx='0' ry='0' width='22' height='10' />
            <rect x='314' y='348' rx='0' ry='0' width='59' height='10' />
            <rect x='238' y='23' rx='0' ry='0' width='137' height='39' />
            <rect x='14' y='104' rx='0' ry='0' width='107' height='37' />
            <rect x='292' y='178' rx='0' ry='0' width='86' height='39' />
            <rect x='224' y='242' rx='0' ry='0' width='152' height='81' />
            <rect x='227' y='362' rx='0' ry='0' width='148' height='41' />
            <rect x='11' y='416' rx='0' ry='0' width='315' height='60' />
            <rect x='3' y='408' rx='0' ry='0' width='354' height='1' />
          </ContentLoader>
        </div>
      </>
    );
  }

  return (
    <div className='container h-100'>
      <div className='row h-100'>
        <div className='col-8'>
          <div className={styles.profile_row_wrapper}>
            <Skeleton shape='avatar-w-info' />
            <ContentLoader
              speed={2}
              width={568}
              height={10}
              viewBox='0 0 568 10'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
            >
              <rect x='0' y='5' rx='0' ry='0' width='545' height='1' />
            </ContentLoader>
          </div>
          <div style={{ position: 'absolute', bottom: '0px' }} className={styles.conversation_wrapper}>
            <ContentLoader
              speed={2}
              width={568}
              height={676}
              viewBox='0 0 568 676'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
            >
              <rect x='475' y='126' rx='5' ry='5' width='75' height='38' />
              <rect x='14' y='195' rx='5' ry='5' width='200' height='164' />
              <rect x='15' y='176' rx='5' ry='5' width='58' height='10' />
              <rect x='495' y='106' rx='5' ry='5' width='54' height='11' />
              <rect x='494' y='359' rx='5' ry='5' width='53' height='10' />
              <rect x='378' y='382' rx='5' ry='5' width='172' height='60' />
              <rect x='23' y='460' rx='5' ry='5' width='55' height='8' />
              <rect x='20' y='476' rx='5' ry='5' width='198' height='123' />
              <rect x='2' y='607' rx='0' ry='0' width='555' height='1' />
              <rect x='15' y='617' rx='0' ry='0' width='479' height='73' />
            </ContentLoader>
          </div>
        </div>
        <div className='col-4'>
          <div style={{ marginBottom: '-100px' }}>
            <ContentLoader
              speed={2}
              width={267}
              height={676}
              viewBox='0 0 267 676'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
            >
              <circle cx='143' cy='84' r='48' />
              <rect x='85' y='158' rx='5' ry='5' width='119' height='13' />
              <rect x='59' y='177' rx='5' ry='5' width='168' height='13' />
              <rect x='29' y='217' rx='5' ry='5' width='57' height='12' />
              <rect x='106' y='218' rx='5' ry='5' width='105' height='10' />
              <rect x='30' y='240' rx='0' ry='0' width='56' height='4' />
              <rect x='31' y='244' rx='0' ry='0' width='224' height='1' />
              <rect x='30' y='261' rx='5' ry='5' width='62' height='12' />
              <rect x='30' y='287' rx='5' ry='5' width='167' height='14' />
              <rect x='29' y='312' rx='5' ry='5' width='221' height='14' />
              <rect x='30' y='336' rx='5' ry='5' width='213' height='14' />
              <rect x='31' y='357' rx='5' ry='5' width='205' height='14' />
              <rect x='32' y='379' rx='5' ry='5' width='210' height='14' />
              <rect x='30' y='406' rx='5' ry='5' width='72' height='14' />
              <rect x='59' y='434' rx='5' ry='5' width='168' height='16' />
              <rect x='60' y='458' rx='5' ry='5' width='84' height='16' />
              <rect x='57' y='492' rx='5' ry='5' width='186' height='14' />
              <rect x='57' y='515' rx='5' ry='5' width='128' height='14' />
              <circle cx='39' cy='454' r='14' />
              <circle cx='38' cy='508' r='14' />
              <rect x='1' y='12' rx='0' ry='0' width='1' height='665' />
            </ContentLoader>
          </div>
        </div>
      </div>
    </div>
  );
}
