export const getBetaDoctorConnectContact = (id, successCallback) => {
  fetch(`/api/v1/beta_doctor_connect_contacts/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const createBetaDoctorConnectContact = (origin, successCallback) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  fetch('/api/v1/beta_doctor_connect_contacts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({
      origin: origin,
      ...window.utmInfo,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data.contact);
    });
};

export const updateBetaDoctorConnectContact = (id, params, successCallback) => {
  fetch(`/api/v1/beta_doctor_connect_contacts/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: id,
      beta_doctor_connect_contact: params,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (successCallback) {
        successCallback(data);
      }
    });
};
