import React, { Component, useState, useEffect } from 'react';
import './weight_loss_webinar_view.css';

export default function WebinarView({ video }) {
  return (
    <div className='webinar-view'>
      {!video && (
        <div className='container marg-top-100 marg-bottom-100'>
          <div className='row'>
            <div className='col marg-bottom-100'>
              <h2 className='marg-bottom-100'>Replay coming soon</h2>
            </div>
          </div>
        </div>
      )}
      {video && (
        <iframe
          src={video}
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen='allowfullscreen'
          mozallowfullscreen='mozallowfullscreen'
          msallowfullscreen='msallowfullscreen'
          oallowfullscreen='oallowfullscreen'
          webkitallowfullscreen='webkitallowfullscreen'
        ></iframe>
      )}
    </div>
  );
}
