import React, { useState, useEffect, useRef } from 'react';
import { useClickAway } from 'react-use';
import styles from './index.module.css';

export default function ClickToSee({
  contentToClick,
  children,
  open,
  setOpen,
  onClick = () => {},
  headerTitle = null,
  headerRightContent = null,
  contentToSeeClassNames,
}) {
  const ref = useRef(null);
  useClickAway(ref, () => {
    setOpen(false);
  });

  const clickToOpen = () => {
    setOpen(!open);
    onClick();
  };

  const renderHeader = () => {
    if (headerTitle === null && headerRightContent === null) {
      return <></>;
    }

    return (
      <>
        <div className={styles.header_container}>
          {headerTitle && <div className={styles.header_title}>{headerTitle}</div>}
          <div className={styles.header_right_content_container}>{headerRightContent}</div>
        </div>
        <div className={styles.divider}></div>
      </>
    );
  };

  return (
    <div ref={ref}>
      <div className={styles.content_to_click_container} onClick={clickToOpen}>
        {contentToClick}
      </div>
      {open && (
        <div className={`${styles.content_to_see_container} ${contentToSeeClassNames}`}>
          {renderHeader()}
          {children}
        </div>
      )}
    </div>
  );
}
