import React, { useState } from 'react';
import InputWithOptions from '../ui_kit/utilities/input_with_options';
import RequestButton from '../ui_kit/utilities/request_button';

export default function WhatSpecialist({
  defaultSpecialist,
  onSubmit,
  onBack,
  choices,
  noMatchTxt = "I don't know",
  label = 'Select a type of specialist',
  head = 'What kind of specialist are you looking for today?',
  head1 = '',
}) {
  const [specialist, setSpecialist] = useState(defaultSpecialist || '');

  const onClick = () => {
    onSubmit(specialist);
  };
  const onBackClick = () => {
    onBack();
  };

  const disabled = specialist !== '' ? '' : 'disabled';

  return (
    <div className='row'>
      <div className='col-md-2'></div>
      <div className='col-md-8'>
        <h5 className='mobile-marg-20 marg-bottom-30'>{head}</h5>
        <h5 className='mobile-marg-20 marg-bottom-40'>{head1}</h5>
        <InputWithOptions
          value={specialist}
          setValue={setSpecialist}
          options={choices}
          name={''}
          label={label}
          noMatchTxt={noMatchTxt}
          showOptionsFromStart
          fullWidth
        />
        <div className='row button-holder'>
          <button className='back col-6' type='button' onClick={onBackClick}>
            Back
          </button>
          <RequestButton
            request={onClick}
            disabled={disabled}
            classnames={'gtm-preexisting-conditions submit col-6'}
            cta={`Next`}
          />
        </div>
      </div>
      <div className='col-md-2'></div>
    </div>
  );
}
