import React from 'react';
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function PatientOrCaregiver({
  value,
  onSubmit,
  onBack,
  title = `Are you a patient or a caregiver/family member of someone with Myelofibrosis (MF)?`,
  choices = ['Patient', 'Caregiver/Family member'],
}) {
  return (
    <OptionsAndOther
      answers={{ mfPatientOrCaregiver: value }}
      keyForAnswer={'mfPatientOrCaregiver'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={choices}
      title={title}
      para={``}
      showOther={false}
      ctaTxt='Next'
      selectOne
    />
  );
}
