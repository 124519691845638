import React from 'react';
import styles from './index.module.css';

export default function VonjoIsi({}) {
  return (
    <div id='cti-isi' className={styles.container_fluid}>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <p className={styles.isi_body}>
              <strong>What is VONJO® (pacritinib)?</strong>
            </p>
            <p className={styles.isi_body}>
              VONJO is a prescription medicine used to treat adults with certain types of myelofibrosis (MF) who have a
              platelet count below 50,000 per microliter. This indication is approved under accelerated approval based
              on spleen volume reduction. Continued approval for this indication may depend on proof and description of
              clinical benefit in a confirmatory trial(s). It is not known if VONJO is safe and works in children.
            </p>
            <p className={styles.isi_body}>
              <strong>Important Safety Information</strong>
            </p>
            <p className={styles.isi_body}>
              <strong>What are the serious side effects of VONJO?</strong>
            </p>
            <p className={styles.isi_body}>
              <strong>Bleeding.</strong> VONJO may cause severe bleeding, which can be serious and, in some cases, may
              lead to death. Do not take VONJO if you are bleeding. If you start bleeding, stop VONJO and call your
              healthcare provider. Your healthcare provider will do a blood test to check your blood cell counts before
              you start VONJO and regularly during your treatment with VONJO.
              <strong>
                {' '}
                Tell your healthcare provider right away if you develop any of these symptoms: unusual bleeding,
                bruising, and fever.{' '}
              </strong>
              You will need to stop taking VONJO 7 days before any planned surgery or invasive procedures (such as a
              heart catheterization, stent placement in a coronary artery in your heart, or a procedure for varicose
              veins). Your healthcare provider should tell you when you can start taking VONJO again.
            </p>
            <p className={styles.isi_body}>
              <strong>Diarrhea.</strong> Diarrhea is common with VONJO, but can be severe, and cause loss of too much
              body fluid (dehydration). Tell your healthcare provider if you have diarrhea and follow instructions for
              what to do to help treat diarrhea. If you notice any change in how often you have bowel movements, if they
              become softer or you have diarrhea, your health care provider may tell you to start taking an
              antidiarrheal medicine as soon as you notice changes.
            </p>
            <p className={styles.isi_body}>
              <strong>Worsening low platelet counts.</strong> Your healthcare provider will do blood tests to check your
              blood counts before you start taking VONJO and during treatment with VONJO.
            </p>
            <p className={styles.isi_body}>
              <strong>
                Changes in the electrical activity of your heart called QTc prolongation. QTc prolongation can cause
                irregular heartbeats that can be life-threatening.
              </strong>{' '}
              Your healthcare provider will check the electrical activity of your heart before you start and during
              treatment with VONJO, as needed. If you have a history of low blood potassium, it is important that you
              get your blood tests done as ordered by your healthcare provider to monitor electrolytes in your blood.{' '}
              <strong>
                Tell your healthcare provider right away if you feel dizzy, lightheaded, faint or feel palpitations.
              </strong>
            </p>
            <p className={styles.isi_body}>
              <strong>
                Increased risk of major cardiovascular events such as heart attack, stroke, or death in people who have
                cardiovascular risk factors and who are current or past smokers
              </strong>{' '}
              have happened in some people taking another Janus associated kinase (JAK) inhibitor to treat rheumatoid
              arthritis.{' '}
              <strong>
                Get emergency help right away if you have any symptoms of a heart attack or stroke while taking VONJO
              </strong>
              , including: discomfort in the center of your chest that lasts for more than a few minutes, or that goes
              away and comes back; severe tightness, pain, pressure, or heaviness in your chest, throat, neck, or jaw;
              pain or discomfort in your arms, back, neck, jaw, or stomach; shortness of breath with or without chest
              discomfort; breaking out in a cold sweat; nausea or vomiting; feeling lightheaded; weakness in one part or
              on one side of your body; or slurred speech.
            </p>
            <p className={styles.isi_body}>
              <strong>Increased risk of blood clots.</strong> Blood clots in the veins of your legs (deep vein
              thrombosis) or lungs (pulmonary embolism) have happened in some people taking another JAK inhibitor and
              may be life-threatening.
              <strong>
                {' '}
                Tell your healthcare provider if you have had blood clots in the veins of your legs or lungs in the
                past.{' '}
              </strong>
              Tell your healthcare provider right away if you have any signs and symptoms of blood clots during
              treatment with VONJO, including: swelling, pain, or tenderness in one or both legs; sudden, unexplained
              chest pain; or shortness of breath/difficulty breathing.
            </p>
            <p className={styles.isi_body}>
              <strong>Possible increased risk of new (secondary) cancers. </strong>
              People who take another JAK inhibitor for rheumatoid arthritis have an increased risk of new (secondary)
              cancers, including lymphoma and other cancers. The risk of new cancers is greater if you smoke or have
              smoked in the past.
            </p>
            <p className={styles.isi_body}>
              <strong>Risk of infection.</strong> People who have certain blood cancers and take another JAK inhibitor
              have an increased risk of serious infections. People who take VONJO may develop serious infections,
              including bacterial, mycobacterial, fungal, and viral infections. If you have a serious infection, your
              healthcare provider may not start you on VONJO until your infection is treated.{' '}
              <strong>
                Tell your healthcare provider right away if you develop any of the following symptoms of infection:
              </strong>{' '}
              chills, aches, fever, nausea, vomiting, weakness, painful skin rash, or blisters.
            </p>
            <p className={styles.isi_body}>
              <strong>What should I tell my healthcare provider before starting VONJO?</strong>
            </p>
            <ul className={styles.isi_body}>
              <li>Current or past smoking history</li>
              <li>
                Previous medical conditions such as any other cancers, blood clot, heart attack, other heart problems,
                stroke, infection, diarrhea, commonly loose stools, nausea, vomiting, liver problems, or kidney
                problems.
              </li>
              <li>
                Have active bleeding, have had severe bleeding or plan to have surgery. You should stop taking VONJO 7
                days before any planned surgery or invasive procedures because you may be at higher risk for bleeding.
              </li>
              <li>
                Are pregnant, plan to become pregnant, are breastfeeding, or plan to breastfeed. It is not known if
                VONJO will harm your unborn baby or if it passes into breast milk. You should not breastfeed during
                treatment and for 2 weeks after your last dose of VONJO.
              </li>
              <li>
                Plan to father a child. VONJO may affect fertility in males. You may have problems fathering a child.
              </li>
            </ul>
            <p className={styles.isi_body}>
              <strong>
                Tell your healthcare provider about all the medicines you take, including prescription and
                over-the-counter medicines, vitamins, and herbal supplements and remedies.
              </strong>
            </p>
            <p className={styles.isi_body}>
              <strong>What are the most common side effects with VONJO?</strong>
            </p>
            <p className={styles.isi_body}>
              Nausea, vomiting, low red blood cell count (anemia), and swelling of your ankles, legs, and feet. These
              are not all the possible side effects of VONJO.
            </p>
            <p className={styles.isi_body}>
              If you suspect that you have experienced a side effect, call your healthcare provider. You may also report
              side effects to the US Food and Drug Administration (FDA) at 1-800-FDA-1088.
            </p>
            <p className={styles.isi_body}>
              Please see the full <a href='https://www.ctibiopharma.com/VONJO_USPI.pdf'>Prescribing Information</a> for
              VONJO.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
