import React from 'react';
import { toast } from 'react-toastify';

export const successToast = (copy) => {
  toast.success(
    <div className='container'>
      <div className='row'>
        <div className='col-1 de-pad'>
          <svg width='27' height='25' viewBox='0 0 27 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='27' height='25' rx='4' fill='white' fillOpacity='0.3' />
            <path
              d='M20.202 6.44635C20.5131 6.15689 20.927 5.99687 21.3565 6.00005C21.7859 6.00322 22.1973 6.16935 22.5038 6.46337C22.8104 6.7574 22.9881 7.15632 22.9994 7.57596C23.0108 7.9956 22.8549 8.40315 22.5647 8.71259L13.7544 19.4826C13.6029 19.6421 13.4201 19.7701 13.2168 19.859C13.0136 19.9478 12.7941 19.9957 12.5714 19.9997C12.3488 20.0038 12.1277 19.9639 11.9212 19.8824C11.7147 19.801 11.5271 19.6797 11.3697 19.5258L5.52709 13.8149C5.36438 13.6667 5.23388 13.488 5.14337 13.2894C5.05285 13.0908 5.00418 12.8765 5.00026 12.6591C4.99633 12.4418 5.03724 12.2259 5.12053 12.0243C5.20382 11.8228 5.32779 11.6397 5.48505 11.486C5.64231 11.3322 5.82963 11.2111 6.03584 11.1297C6.24205 11.0482 6.46292 11.0083 6.68528 11.0121C6.90764 11.0159 7.12693 11.0635 7.33008 11.152C7.53322 11.2404 7.71605 11.368 7.86766 11.527L12.4914 16.0444L20.1601 6.49383C20.1739 6.47721 20.1886 6.46136 20.2042 6.44635H20.202Z'
              fill='white'
            />
          </svg>
        </div>
        <div className='col-10'>
          <p className='bold'>Success</p>
          <p>{copy}</p>
        </div>
      </div>
    </div>,
    {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};
