import React, { useEffect, useState, useMemo } from 'react';
import CheckoutForm from '../checkout_form';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import styles from './index.module.css';
import StripePaymentSuccessCatch from '../../ui_kit/utilities/stripe_payment_success_catch';
import { getProducts } from '../../../api/products';
import LoadingScreen from '../../loading_screen';

export default function PurchasePage({
  img = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss-surgeon-match/weight_loss_surgeon_match_header.jpg',
  headline = 'Join PatientPartner',
  subheadline = 'Transform your patient experiences',
  checkoutTxt = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
}) {
  const [products, setProducts] = useState([]);
  const [checkoutItems, setCheckoutItems] = useState([]);
  let { path } = useRouteMatch();
  let history = useHistory();

  useEffect(() => {
    getProducts({ type: 'membership' }, (data) => setProducts(data.products));
  }, []);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <div className='container '>
          <div className='row'>
            <div className='col'>
              <p>Pick a plan</p>
              {products.length === 0 ? (
                <LoadingScreen />
              ) : (
                products.map((product) => (
                  <button
                    onClick={() => {
                      setCheckoutItems([...checkoutItems, product]);
                      history.push(`${path}/payment`);
                    }}
                  >
                    {product.name}
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
      </Route>
      <Route exact path={`${path}/payment`}>
        <div style={{ maxWidth: '1000px', margin: '0px auto' }}>
          <div className={`container ${styles.checkout_container}`}>
            <div className='row' style={{ alignItems: 'flex-end' }}>
              <div className='col-md-7 col-12'>
                <div className={styles.headline_sec}>
                  <h3>{headline}</h3>
                  <h6>{subheadline}</h6>
                </div>
              </div>
              <div className='col-md-5 d-none d-md-block'>
                <div className={`${styles.desk_img} d-none d-md-block`} style={{ background: `url('${img}')` }}></div>
              </div>
            </div>
          </div>
          {useMemo(
            () => (
              <>
                {checkoutItems.length > 0 && <CheckoutForm checkoutItems={checkoutItems} checkoutTxt={checkoutTxt} />}
              </>
            ),
            [checkoutItems, checkoutTxt]
          )}
        </div>
      </Route>
      <Route exact path={`${path}/payment/success`}>
        <StripePaymentSuccessCatch />
        <div className={`container ${styles.checkout_container}`}>
          <p>Order Confirmed</p>
          {gon.current_user.subscription_status && (
            <>
              <p>Membership plan: {gon.current_user.subscription_status.memberhship_plan}</p>
              <p>Is active: {gon.current_user.subscription_status.is_active.toString()}</p>
            </>
          )}
        </div>
      </Route>
    </Switch>
  );
}
