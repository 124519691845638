import React, { useState, useRef } from 'react';
import styles from './index.module.css';
import { useClickAway } from 'react-use';
import parentstyles from '../../index.module.css';
import { deleteCondition, updateCondition } from '../../../../api/profiles';
import { successToast } from '../../../ui_kit/utilities/success_toast';
import { createActivity } from '../../../../api/activities';

export default function ConditionRow({ user, condition, i, setConditions, conditions, profileIsCurrentUser }) {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => {
    setShowOptions(false);
  });

  const removeCondition = () => {
    deleteCondition(user.profile_id, condition.id, (data) => {
      setConditions(conditions.filter((condition) => condition.id !== data.success.id));
      setShowOptions(false);
      createActivity('Activity::EditProfile', { fields: 'remove_condition' });
      successToast('Condition successfully removed.');
    });
  };

  const editVisibility = () => {
    updateCondition(user.profile_id, condition.id, { private: !condition.private }, (data) => {
      setConditions(data);
      // successToast('Condition visibility successfully edited.')
    });
  };

  return (
    <div
      data-pendo-tag='profile page - health profile - condition row'
      className={i !== 0 ? 'marg-top-15' : null}
      style={{ display: 'flex' }}
    >
      <p className={`${parentstyles.bio}`}>{condition.condition}</p>
      {profileIsCurrentUser && (
        <div
          data-pendo-tag='profile page - health profile - edit condition row'
          style={{ marginLeft: 'auto', cursor: 'pointer', position: 'relative', display: 'flex', alignItems: 'center' }}
        >
          <img
            onClick={() => editVisibility()}
            src={
              condition.private
                ? 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/eye-off.svg'
                : 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/eye.svg'
            }
          />
          <div style={{ position: 'relative' }}>
            <img
              style={{ marginLeft: '10px' }}
              className={`${styles.options_icon} `}
              onClick={() => setShowOptions(!showOptions)}
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+3/Vector.svg'
            />
            {showOptions && (
              <div ref={ref} className={`${styles.share_list}`}>
                <div className='container'>
                  <div className={`${styles.icon_row} row`}>
                    <div onClick={() => removeCondition(condition.id)} className={`${styles.icon_col} col`}>
                      <img
                        className={`${styles.image}`}
                        src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/trash.svg'
                      />
                      Delete
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
