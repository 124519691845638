import React, { useState, useEffect, useRef } from 'react';
import { useClickAway } from 'react-use';
import styles from './dropdown_click.module.css';

export default function DropdownInput({
  values,
  options,
  setValues,
  gtm,
  type = 'multi', // different types: 'single', 'multi', 'multi-w-category'
  wrapperClassname = '',
  elemClassname = '',
  disabled = '',
  multipleSetOpen = () => {},
  uuid,
  openStatus,
  placeholder = 'Select',
  light = true,
  marginTop = '5px',
  defaultOpen = false,
  focus = false,
  valueOverride = false,
  pendoTag = '',
}) {
  const ref = useRef(null);
  useClickAway(ref, () => {
    setOpen(false);
  });

  // The different data structures:
  // single (color is optional):
  // [
  //   {
  //     color: "#ED1C24",
  //     value: 'Not Contacted'
  //   },
  //   {
  //     color: "#314D69",
  //     value: 'Contacted'
  //   }
  // ]

  // multi (user can select multiple options):
  // [
  //   {
  //     value: '18-24',
  //   },
  //   {
  //     value: '25-34',
  //   },
  //   {
  //     value: '35-44',
  //   },
  //   {
  //     value: '45-54',
  //   },
  //   {
  //     value: '55-64',
  //   },
  //   {
  //     value: '65-74',
  //   },
  //   {
  //     value: '75+',
  //   },
  // ]

  // multi-w-category (user can select multiple options && it renders a category title):
  // [
  //   {
  //     category: 'Bariatric Surgery',
  //     surgeries: [
  //       "Sleeve Gastrectomy",
  //       "Gastric Bypass",
  //       "Lap Band"
  //     ]
  //   },
  //   {
  //     category: 'Orthopedic Surgery',
  //     surgeries: [
  //       "Knee Replacement",
  //       "Hip Replacement",
  //     ]
  //   }
  // ];

  const [open, setOpen] = useState(defaultOpen);

  const bgColor = () => {
    return options.filter((option) => option.color).length > 0
      ? options.filter((option) => option.value === values)[0].color
      : '';
  };

  const onClick = (option) => () => {
    if (option.single && option.single === true) {
      if (values.length === 1 && values[0].value === option.value) {
        setValues([]);
      } else if (values.length > 0 && values.filter((v) => v.value === option.value).length === 0) {
        setValues([option]);
        return;
      }
    } else if (values.filter((v) => v.single === true).length === 1) {
      setValues([option]);
      return;
    }

    if (values.map((ageObject) => ageObject.value && ageObject.value.includes(option.value)).includes(true)) {
      setValues(values.filter((a) => a.value != option.value));
    } else {
      var arr = [...values];
      arr.push(option);
      setValues(arr);
    }
  };

  useEffect(() => {
    if (openStatus !== uuid) {
      setOpen(false);
    }
  }, [openStatus]);

  const formatBgColor = (str) => {
    return `${str.slice(0, 3)}a${str.slice(3, str.length - 1)},.25)`;
  };

  return (
    <div
      data-pendo-tag={pendoTag}
      ref={ref}
      className={`${wrapperClassname} dropdown multiselect ${light ? 'light' : ''}`}
    >
      <button
        disabled={disabled}
        onClick={() => {
          setOpen(!open);
          multipleSetOpen(uuid);
        }}
        data-gtm-click-label={gtm}
        style={{
          backgroundColor: bgColor().includes('rgb') ? formatBgColor(bgColor()) : bgColor(),
          marginTop: marginTop,
        }}
        className={`material-input ${elemClassname} ${styles.dropdown_toggle} ${focus ? styles.focus : ''}`}
      >
        {type === 'single' && (
          <p className={`${styles.dropdown_placeholder}`}>
            {values !== '' ? (
              <span style={{ color: bgColor() === '' ? '#333333' : bgColor() }}>{values}</span>
            ) : (
              placeholder
            )}
          </p>
        )}
        {type === 'multi' && (
          <p className={`${styles.dropdown_placeholder}`}>
            {valueOverride ? (
              <span>{valueOverride}</span>
            ) : (
              <>
                {values.length > 0 ? (
                  <>
                    {values.map((pro, index) => (
                      <span key={index}>
                        {index > 0 && <span>, </span>}
                        <span>{pro.value}</span>
                      </span>
                    ))}
                  </>
                ) : (
                  placeholder
                )}
              </>
            )}
          </p>
        )}
        {type === 'multi-w-category' && (
          <p className={`${styles.dropdown_placeholder}`}>{values.length > 0 ? <>{values.join(', ')}</> : 'Select'}</p>
        )}
        <svg
          style={open ? { transform: 'rotate(180deg)' } : {}}
          className={styles.arrow}
          width='13'
          height='8'
          viewBox='0 0 13 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            style={{ fill: bgColor() === '' ? '#999' : '#828282' }}
            d='M6.4898 7.9973C6.27322 7.99782 6.06333 7.90487 5.89656 7.73458L0.334863 2.02324C0.145564 1.82936 0.0265212 1.55074 0.00392239 1.2487C-0.0186764 0.946653 0.05702 0.645916 0.214359 0.412646C0.371698 0.179376 0.597792 0.0326817 0.842901 0.00483352C1.08801 -0.0230146 1.33206 0.0702645 1.52136 0.264151L6.4898 5.38151L11.4583 0.446915C11.5531 0.352031 11.6622 0.281174 11.7793 0.238417C11.8964 0.19566 12.0192 0.181846 12.1407 0.197768C12.2621 0.213691 12.3798 0.259035 12.487 0.331196C12.5942 0.403358 12.6888 0.500913 12.7653 0.618254C12.8501 0.735704 12.9144 0.873491 12.9541 1.02298C12.9938 1.17248 13.008 1.33045 12.9958 1.48702C12.9836 1.64358 12.9453 1.79535 12.8833 1.93283C12.8213 2.07031 12.7369 2.19053 12.6355 2.28596L7.07378 7.80311C6.90222 7.94648 6.69661 8.01486 6.4898 7.9973V7.9973Z'
          />
        </svg>
      </button>
      {open && (
        <>
          {type === 'single' && (
            <ul className={styles.dropdown_menu}>
              {options.map((choice) => (
                <li key={choice.value}>
                  <a className={styles.position_parent}>
                    <input
                      style={{ visibility: 'hidden' }}
                      onClick={() => {
                        setValues(choice.value);
                        setOpen(false);
                        multipleSetOpen(null);
                      }}
                      type='checkbox'
                      className='checkedbox'
                      id={choice.value}
                      checked={`${values === choice.value ? 'checked' : ''}`}
                    />
                    <label htmlFor={choice.value}>
                      <p label={gtm} className={`${values === choice.value ? 'active' : ''}`}>
                        {choice.value}
                      </p>
                    </label>
                  </a>
                </li>
              ))}
            </ul>
          )}
          {type === 'multi' && (
            <ul className={`${styles.multi_menu}`}>
              {options.map((choice) => (
                <li key={choice.value}>
                  <a className='position-parent' value={choice.value}>
                    <input
                      style={{ visibility: 'hidden' }}
                      onClick={onClick(choice)}
                      type='checkbox'
                      className='checkedbox'
                      id={choice.value}
                      checked={`${values.findIndex((b) => b.value === choice.value) !== -1 ? 'checked' : ''}`}
                    />
                    <label htmlFor={choice.value}>
                      <p label={gtm} className={`${values.includes(choice) ? 'active' : ''}`}>
                        {choice.value}
                      </p>
                    </label>
                  </a>
                </li>
              ))}
            </ul>
          )}
          {type === 'multi-w-category' && (
            <>
              <ul className={`${styles.multi_menu}`}>
                {options.map((value) => (
                  <div key={value.category}>
                    <p className='bold'>{value.category}</p>
                    {value.surgeries.map((value) => (
                      <li key={value}>
                        <a className='position-parent' value={value}>
                          <input
                            style={{ visibility: 'hidden' }}
                            onClick={() => setValues(value)}
                            type='checkbox'
                            className='checkedbox'
                            id={value}
                            checked={`${values.includes(value) ? 'checked' : ''}`}
                          />
                          <label htmlFor={value}>
                            <p
                              data-gtm-click-label='Patient partner gallery filters - Procedure'
                              className={`${values.includes(value) ? 'active' : ''}`}
                            >
                              {value}
                            </p>
                          </label>
                        </a>
                      </li>
                    ))}
                  </div>
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </div>
  );
}
