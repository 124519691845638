import React, { useEffect, useState } from 'react';
import ChooseManyOptionsWithNone from '../choose_many_options_with_none';

export default function PreviousTreatments({ answers, onSubmit, onBack, gtm }) {
  return (
    <ChooseManyOptionsWithNone
      answers={answers}
      keyForAnswer={`previousSpineTreatments`}
      onSubmit={onSubmit}
      onBack={onBack}
      gtm={`${gtm} Previous Spine Treatments`}
      options={[
        'MRI or X-Ray',
        'Chiropractor sessions',
        'Physical therapy',
        'Prescription pain medication',
        'None Of These',
      ]}
      title={`Have you had any of these treatments before? Select any that apply.`}
      para={`This information will help the specialist understand how they can support you.`}
    />
  );
}
