import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Context from '../../../utils/context';
import { setLastRead } from '../../../api/user_conversations';
import { sendMessage } from '../../../api/messages';
import { createActivity } from '../../../api/activities';
import TextareaAutosize from 'react-textarea-autosize';
import AvatarWActive from '../../ui_kit/utilities/avatar-w-active';
import ActionIcons from '../../action_icons';
import SimpleSlidingMenu from '../../ui_kit/utilities/simple_sliding_menu';
import SurgeonsColumn from '../../surgeons_column';
import ErrorMessage from '../../ui_kit/utilities/error_message';
import PersonalInformation from '../../profile/about_me_sections/personal_information';
import OtherDetails from '../../profile/about_me_sections/other_details';
import About from '../../profile/about_me_sections/about';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';
import { pageInspect } from '../../../api/metainspector';
import UrlPreview from './../../posts/url_preview';
import { isObjEmpty } from '../../../helpers';
import LoadingScreen from './../../loading_screen';
import DOMPurify from '../../../utils/purify.min.js';
import S3UploadFile from '../../ui_kit/utilities/s3_upload_file';
import ImageGridDisplay from '../../ui_kit/utilities/image_grid_display';
import WomensHealthMyDoctorPanel from './womens_health_my_doctor_panel';

export default function Conversation({
  conversation,
  user,
  backPath,
  profilePath,
  fetchConversation,
  otherUser,
  numConversationsWithUnreadMessages,
  sendFunction = () => {},
}) {
  useEffect(() => {
    if (isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [currentMessage, setCurrentMessage] = useState(searchParams.get('firstMessage') || '');
  const lastReadTime = conversation.current_user_conversation.last_read_time;
  const [heldKey, setHeldKey] = useState(null);
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();
  const [urlPreview, setUrlPreview] = useState();
  const [isUrlPreviewLoading, setIsUrlPreviewLoading] = useState(false);
  const [urlMetadata, setUrlMetadata] = useState(null);
  const linkifyHtml = require('linkifyjs/html');
  let defaultFiles = [];
  if (currentMessage.message_images) {
    defaultFiles = currentMessage.message_images.map((i) => i.image);
  }
  const [files, setFiles] = useState(defaultFiles);
  const send = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }

    if (currentMessage.replace(/\n/g, '') === '' && files.length === 0) {
      return;
    }
    setCurrentMessage('');
    onSendClick(currentMessage, urlMetadata);
    setFiles([]);
  };
  let suggestedMessages = [];
  if (user.current_profile_type === 'Patient' && conversation && conversation.messages.length === 0) {
    suggestedMessages = [
      'How did you find a surgeon?',
      'What was recovery like?',
      'How do I prepare for my upcoming surgery?',
      'How did surgery change your life?',
      'How can my loved ones support me during surgery?',
      'Where can i find support if I do not have it during surgery?',
    ];
  }

  const onRead = () => {
    setLastRead(conversation.id, () => fetchConversation());
  };

  const onSendClick = (currentMessage, urlMetadata) => {
    createActivity('Activity::InitiateConnection', { conversation_id: conversation.uuid });
    sendMessage(conversation.uuid, currentMessage, urlMetadata, files, () => {
      setUrlPreview(null);
      setUrlMetadata(null);
      fetchConversation(false, true);
    });
    sendFunction();
  };

  const clickNewMessages = () => {
    document.querySelector('.message-container-otr').scrollTop = 0;
  };

  const displayWithLineBreaks = (message) =>
    message.split('\n').map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });

  let menuItems = [
    {
      idToScrollTo: 'AboutMe',
      linkText: 'About Mentor',
      pendoTag: 'conversations-about-me',
    },
    {
      idToScrollTo: 'Chat',
      linkText: 'Chat',
      pendoTag: 'conversations-chat-window',
    },
    {
      idToScrollTo: 'MyDoctor',
      linkText: 'My Doctor',
      pendoTag: 'conversations-my-doctor',
    },
  ];

  let defaultSelectedMenuItem = menuItems[1];
  menuItems.forEach((item) => {
    if (selectedMenuItem === item.linkText) {
      defaultSelectedMenuItem = item;
    }
  });
  const [selectedMenuItem, setSelectedMenuItem] = useState(defaultSelectedMenuItem);
  var linkify = require('linkifyjs');
  const showUrlPreview = (newBody) => {
    const link = linkify.find(newBody).find((l) => l.type === 'url');
    if (link && link.href !== urlPreview) {
      setUrlPreview(link.href);
      pageInspect(link.href, (data) => {
        if (!isObjEmpty(data) && data.title && data.description && data.image) {
          setUrlMetadata(data);
        }
        setIsUrlPreviewLoading(false);
      });
      setIsUrlPreviewLoading(true);
    }
  };

  const removeUrlPreview = () => {
    setUrlMetadata(null);
    setUrlPreview(null);
  };

  const debouncedShowUrlPreview = useDebouncedCallback((value) => {
    showUrlPreview(value);
  }, 1000);

  const onBodyChange = (event) => {
    setCurrentMessage(event.target.value);
    debouncedShowUrlPreview(event.target.value);
  };

  const current_user_is_womens_health = (user.current_profile.procedures || []).includes("Women's health");

  const renderMyDoctorPanel = () => {
    if (
      (otherUser.profile.type === 'Patient' && otherUser.profile.profile_medical_providers.length === 0) ||
      (otherUser.profile.type === 'PatientPartner' && otherUser.profile.other_data.previous_surgeon === undefined)
    ) {
      return (
        <>
          <div className='row justify-content-center marg-top-20'>
            <div className='col-11'>
              <ErrorMessage error={`${otherUser.profile.display_name} doesn't have their doctor listed.`} />
            </div>
          </div>

          {otherUser.profile.type === 'Patient' && user.current_profile.type === 'Patient' && (
            <div className='container'>
              <div className='row justify-content-center marg-top-10'>
                <h5 className='col-11'>Looking for a doctor who will listen to you?</h5>
              </div>
              <div className='row justify-content-center marg-bottom-20'>
                <p className='col-11'>
                  Take a short questionnaire, and we’ll help you find the best doctor in your area!
                </p>
              </div>
              <div className='row justify-content-center'>
                <Link to='/member/join/surgeon-match'>
                  <button className=''>Find a doctor for me</button>
                </Link>
              </div>
            </div>
          )}
          {otherUser.profile.type === 'Patient' && user.current_profile.type === 'PatientPartner' && (
            <div className='container'>
              <div className='row justify-content-center marg-top-10'>
                <h5 className='col-11'>Would you recommend your doctor?</h5>
              </div>
              <div className='row justify-content-center marg-bottom-20'>
                <p className='col-11'>
                  Finding a trusted doctor is hard. Recommend your doctor to {otherUser.profile.display_name}!
                </p>
              </div>
              <div className='row justify-content-center'>
                <button
                  onClick={() => {
                    setSelectedMenuItem(menuItems[1]);
                    user.current_profile.other_data.previous_surgeon !== undefined &&
                    user.current_profile.other_data.previous_surgeon.inactive !== true
                      ? setCurrentMessage(
                          `If you are looking for a trusted doctor, my doctor was ${user.current_profile.other_data.previous_surgeon.name}. You can find more information on their profile page ${user.current_profile.other_data.previous_surgeon.surgeon_share_url}`
                        )
                      : setCurrentMessage('If you are looking for a trusted doctor, my doctor was');
                    setConversationWindowHeight();
                  }}
                >
                  Recommend my doctor
                </button>
              </div>
            </div>
          )}
          {otherUser.profile.type === 'PatientPartner' && user.current_profile.type === 'Patient' && (
            <div className='container'>
              <div className='row justify-content-center marg-top-10'>
                <h5 className='col-11'>Looking for a doctor who will listen to you?</h5>
              </div>
              <div className='row justify-content-center marg-bottom-20'>
                <p className='col-11'>
                  Ask your mentor who their doctor was or take a short questionnaire, and we’ll help you find the best
                  doctor in your area!
                </p>
              </div>
              <div className='row justify-content-center'>
                <button
                  onClick={() => {
                    setSelectedMenuItem(menuItems[1]);
                    setCurrentMessage("I was wondering if you'd be willing to share who your doctor was?");
                    setConversationWindowHeight();
                  }}
                  className=''
                >
                  Ask your mentor
                </button>
              </div>
              <div className='row justify-content-center'>
                <p className=''>-or-</p>
              </div>
              <div className='row justify-content-center'>
                {otherUser.profile.from_real_self &&
                (otherUser.profile.surgeries_or_treatments.includes('Tummy Tuck') ||
                  otherUser.profile.surgeries_or_treatments.includes('Liposuction')) ? (
                  <>
                    {otherUser.profile.surgeries_or_treatments.includes('Tummy Tuck') && (
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.realself.com/drsearch?keyword=Tummy-tuck&utm_source=PatientPartner&utm_medium=all&utm_campaign=realself_ambassador_campaign'
                      >
                        <button className='liner'>Find a doctor for me</button>
                      </a>
                    )}
                    {otherUser.profile.surgeries_or_treatments.includes('Liposuction') && (
                      <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.realself.com/drsearch?keyword=Liposuction&utm_source=PatientPartner&utm_medium=all&utm_campaign=realself_ambassador_campaign'
                      >
                        <button className='liner'>Find a doctor for me</button>
                      </a>
                    )}
                  </>
                ) : (
                  <Link to='/member/join/surgeon-match'>
                    <button className='liner'>Find a doctor for me</button>
                  </Link>
                )}
              </div>
            </div>
          )}
        </>
      );
    }

    if (
      otherUser.profile.type !== 'SuperUser' &&
      ((otherUser.profile.profile_medical_providers.length > 0 &&
        otherUser.profile.profile_medical_providers.inactive !== true) ||
        (otherUser.profile.other_data.previous_surgeon !== undefined &&
          otherUser.profile.other_data.previous_surgeon.inactive !== true))
    ) {
      return (
        <div className='marg-bottom-20 marg-top-15'>
          <SurgeonsColumn
            surgeon={
              otherUser.profile.type === 'Patient'
                ? otherUser.profile.profile_medical_providers[0]
                : otherUser.profile.other_data.previous_surgeon
            }
          />
        </div>
      );
    }
  };

  return (
    <div className='chat-room' style={isMobile ? { height: '100dvh' } : { height: '100%' }}>
      <div
        className='container chat-room-header'
        style={isMobile ? { borderBottom: '1px solid rgba(196, 196, 196, 0.44)', paddingBottom: 'none' } : null}
      >
        <div className='row'>
          {otherUser && (
            <>
              {isMobile && backPath && (
                <div style={{ cursor: 'pointer' }} className='col-1 my-auto'>
                  <Link to={backPath} style={{ marginTop: '6px', display: 'block' }}>
                    <svg width='13' height='23' viewBox='0 0 13 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M12.183 2.56066C12.7688 1.97487 12.7688 1.02513 12.183 0.43934C11.5972 -0.146447 10.6475 -0.146447 10.0617 0.43934L0.43934 10.0617C-0.146447 10.6475 -0.146447 11.5972 0.43934 12.183L10.0617 21.8054C10.6475 22.3912 11.5972 22.3912 12.183 21.8054C12.7688 21.2196 12.7688 20.2699 12.183 19.6841L3.62132 11.1224L12.183 2.56066Z'
                        fill='#59B6BE'
                      />
                    </svg>
                    {numConversationsWithUnreadMessages > 0 && numConversationsWithUnreadMessages < 99 && (
                      <div className='notif-number-bub convo-arrow-notif'>{numConversationsWithUnreadMessages}</div>
                    )}
                    {numConversationsWithUnreadMessages > 99 && <div className='notif-number-bub'>99+</div>}
                  </Link>
                </div>
              )}
              {conversation && (
                <div className='col my-auto chat-room-pp-info'>
                  <Link to={isMobile && profilePath}>
                    <AvatarWActive profile={otherUser.profile} showInfo={true} />
                  </Link>
                  <ActionIcons conversation={conversation} style={{ alignSelf: 'center', marginLeft: 'auto' }} />
                </div>
              )}
            </>
          )}
        </div>
        {isMobile &&
          otherUser &&
          (otherUser.profile.type === 'Patient' || otherUser.profile.type === 'PatientPartner') &&
          conversation &&
          user.current_profile_type !== 'SurgeonAdministrativeAssistant' && (
            <div className='row justify-content-center marg-top-20'>
              <SimpleSlidingMenu
                menuItems={menuItems}
                setMarker={selectedMenuItem}
                setMarkerItem={setSelectedMenuItem}
                wrapperStyles={{ width: 'max-content', marginBottom: '0px' }}
                menuPadding={true}
              />
            </div>
          )}
      </div>
      {selectedMenuItem.linkText === 'About Mentor' && isMobile && (
        <div
          style={{ paddingRight: '20px', paddingLeft: '20px', width: '100%' }}
          className={`container other-p-bio-sec marg-top-20`}
        >
          {otherUser.profile.bio !== null && otherUser.profile.type === 'SuperUser' && (
            <>
              <div className='row justify-content-center'>
                <h5 style={{ marginTop: '20px', marginBottom: '20px' }} className='col'>
                  About Mentor
                </h5>
              </div>
              <p className={`other-p-bio marg-bottom-5`}>
                Our mission is to empower and educate patients by providing personalized one-on-one support through
                relatable experiences. <br />
                We want to make sure every patient has the support and care they need throughout their journey.
              </p>
              <p className='other-p-bio-title bold marg-bottom-5'>How we can help:</p>
              <ul>
                <li>Connect you to patient mentors and provide emotional support</li>
                <li>Answer any preliminary insurance questions</li>
                <li>Connect you to a reputable surgeon in your area</li>
              </ul>
            </>
          )}
          {otherUser.profile.type !== 'SuperUser' && (
            <div className='container'>
              <div className='marg-top-20'>
                <About user={otherUser.user} />
              </div>
              <div className='marg-top-20'>
                <PersonalInformation user={otherUser.user} />
              </div>
              <div className='marg-top-20'>
                <OtherDetails user={{ current_profile: otherUser.profile }} />
              </div>
            </div>
          )}
        </div>
      )}
      {(selectedMenuItem.linkText === 'Chat' || !isMobile) && (
        <>
          {conversation && conversation.messages.length === 0 ? (
            <div
              style={{ paddingTop: '40px', width: '100%', textAlign: 'center', flexFlow: 'column', flex: '1 1 auto' }}
            >
              <p>Send your first message to</p>
              <p className='bold'>{otherUser.profile.display_name}</p>
              <div
                className={`${otherUser.profile_type === 'SuperUser' ? 'chat-room-super-user-avatar-lg' : 'chat-room-pp-avatar-lg'} marg-bottom-20 marg-top-10`}
                style={{ backgroundImage: `url(${otherUser.profile.photo})` }}
              />
            </div>
          ) : (
            <div className='message-container-otr' style={isMobile ? {} : { minHeight: '30vh', maxHeight: '50vh' }}>
              <div className='message-container'>
                <div className='container'>
                  <div className='row'>
                    <div className='col'>
                      {conversation &&
                        lastReadTime !== null &&
                        conversation.messages.map((message, index) => {
                          const currentMessageCurrentUser = message.current_user_sent;
                          const previousMessageCurrentUser =
                            index - 1 !== -1 && conversation.messages[index - 1].current_user_sent;
                          return (
                            <>
                              {((index - 1 === -1) === true ||
                                (new Date(message.created_at).getDate() !==
                                  new Date(conversation.messages[index - 1].created_at).getDate() &&
                                  index - 1 !== -1)) && (
                                <p className='text-center message-date'>{`${new Date(message.created_at).toLocaleString('default', { month: 'short' })} ${new Date(message.created_at).getDate()}`}</p>
                              )}
                              {index === 0 &&
                                !message.current_user_sent &&
                                new Date(message.created_at).getTime() > new Date(lastReadTime).getTime() && (
                                  <div
                                    onClick={() => {
                                      clickNewMessages();
                                      onRead();
                                    }}
                                    className='new-message-txt-wrap'
                                  >
                                    <div className='new-message-txt'>
                                      <h6>New Messages</h6>
                                      <svg
                                        width='15'
                                        height='8'
                                        viewBox='0 0 15 8'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M1.00177 1.84135e-05C1.23542 -0.000438079 1.46185 0.0809368 1.64177 0.230018L7.00177 4.71002L12.3718 0.39002C12.4741 0.306954 12.5918 0.244922 12.7181 0.20749C12.8444 0.170059 12.9769 0.157965 13.1079 0.171904C13.239 0.185843 13.366 0.22554 13.4816 0.288714C13.5972 0.351888 13.6992 0.437293 13.7818 0.540019C13.8648 0.642307 13.9269 0.760003 13.9643 0.886343C14.0017 1.01268 14.0138 1.14517 13.9999 1.2762C13.9859 1.40723 13.9462 1.53421 13.8831 1.64985C13.8199 1.76549 13.7345 1.8675 13.6318 1.95002L7.63177 6.78002C7.45283 6.9271 7.22839 7.00751 6.99677 7.00751C6.76514 7.00751 6.5407 6.9271 6.36177 6.78002L0.361767 1.78002C0.260509 1.69607 0.176806 1.59297 0.115454 1.47662C0.0541022 1.36027 0.016305 1.23297 0.00422911 1.10199C-0.00784675 0.971013 0.00603624 0.838941 0.0450807 0.713337C0.0841252 0.587734 0.147564 0.471067 0.231766 0.370018C0.32482 0.255305 0.442132 0.162631 0.575268 0.098656C0.708405 0.0346812 0.85406 0.000995185 1.00177 1.84135e-05Z'
                                          fill='white'
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                )}
                              {index - 1 !== -1 &&
                                !message.current_user_sent &&
                                new Date(message.created_at).getTime() > new Date(lastReadTime).getTime() &&
                                new Date(conversation.messages[index - 1].created_at).getTime() <
                                  new Date(lastReadTime).getTime() && (
                                  <div
                                    onClick={() => {
                                      clickNewMessages();
                                      onRead();
                                    }}
                                    className='new-message-txt-wrap'
                                  >
                                    <div className='new-message-txt'>
                                      <h6>New Messages</h6>
                                      <svg
                                        width='15'
                                        height='8'
                                        viewBox='0 0 15 8'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M1.00177 1.84135e-05C1.23542 -0.000438079 1.46185 0.0809368 1.64177 0.230018L7.00177 4.71002L12.3718 0.39002C12.4741 0.306954 12.5918 0.244922 12.7181 0.20749C12.8444 0.170059 12.9769 0.157965 13.1079 0.171904C13.239 0.185843 13.366 0.22554 13.4816 0.288714C13.5972 0.351888 13.6992 0.437293 13.7818 0.540019C13.8648 0.642307 13.9269 0.760003 13.9643 0.886343C14.0017 1.01268 14.0138 1.14517 13.9999 1.2762C13.9859 1.40723 13.9462 1.53421 13.8831 1.64985C13.8199 1.76549 13.7345 1.8675 13.6318 1.95002L7.63177 6.78002C7.45283 6.9271 7.22839 7.00751 6.99677 7.00751C6.76514 7.00751 6.5407 6.9271 6.36177 6.78002L0.361767 1.78002C0.260509 1.69607 0.176806 1.59297 0.115454 1.47662C0.0541022 1.36027 0.016305 1.23297 0.00422911 1.10199C-0.00784675 0.971013 0.00603624 0.838941 0.0450807 0.713337C0.0841252 0.587734 0.147564 0.471067 0.231766 0.370018C0.32482 0.255305 0.442132 0.162631 0.575268 0.098656C0.708405 0.0346812 0.85406 0.000995185 1.00177 1.84135e-05Z'
                                          fill='white'
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                )}
                              {index - 1 === -1 && (
                                // show timestamp for the very first message
                                <p
                                  style={{ textAlign: message.current_user_sent ? 'right' : 'left' }}
                                  className='message-date marg-top-10'
                                >{`${new Date(message.created_at).toLocaleTimeString()}`}</p>
                              )}
                              {index - 1 !== -1 &&
                                (message.current_user_sent !== conversation.messages[index - 1].current_user_sent) ===
                                  true &&
                                new Date(message.created_at).getMinutes() ===
                                  new Date(conversation.messages[index - 1].created_at).getMinutes() && (
                                  // show timestamp if the message is from a different user than the one above
                                  <p
                                    style={{ textAlign: message.current_user_sent ? 'right' : 'left' }}
                                    className='message-date marg-top-10'
                                  >{`${new Date(message.created_at).toLocaleTimeString()}`}</p>
                                )}
                              {index - 1 !== -1 &&
                                new Date(message.created_at).getMinutes() !==
                                  new Date(conversation.messages[index - 1].created_at).getMinutes() && (
                                  // show timestamp if the message above is a different minute
                                  <p
                                    style={{ textAlign: message.current_user_sent ? 'right' : 'left' }}
                                    className='message-date marg-top-10'
                                  >{`${new Date(message.created_at).toLocaleTimeString()}`}</p>
                                )}
                              {currentMessageCurrentUser && (
                                <div
                                  style={
                                    message.message_images.length > 0 ? { width: 'auto' } : { width: ' max-content' }
                                  }
                                  className={`message-bubble current-user ${index - 1 !== -1 ? (!previousMessageCurrentUser ? 'marg-top-20' : '') : ''}`}
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        linkifyHtml(message.body.replaceAll('\n', '<br/>'), { target: 'blank' })
                                      ),
                                    }}
                                    className='no-select'
                                  ></p>
                                  {message.url_preview_metadata && (
                                    <UrlPreview metadata={message.url_preview_metadata} />
                                  )}
                                  {message.message_images.length > 0 && (
                                    <div className='marg-top-10 marg-bottom-10'>
                                      <ImageGridDisplay photos={message.message_images.map((i) => i.image)} />
                                    </div>
                                  )}
                                </div>
                              )}
                              {!currentMessageCurrentUser && (
                                <div
                                  style={
                                    message.message_images.length > 0 ? { width: 'auto' } : { width: ' max-content' }
                                  }
                                  className={`message-bubble other-user ${index - 1 !== -1 ? (previousMessageCurrentUser ? 'marg-top-20' : '') : ''}`}
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        linkifyHtml(message.body.replaceAll('\n', '<br/>'), { target: 'blank' })
                                      ),
                                    }}
                                    className='no-select'
                                  ></p>
                                  {message.url_preview_metadata && (
                                    <UrlPreview metadata={message.url_preview_metadata} />
                                  )}
                                  {message.message_images.length > 0 && (
                                    <div className='marg-top-10 marg-bottom-10'>
                                      <ImageGridDisplay photos={message.message_images.map((i) => i.image)} />
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {conversation && (
            <div>
              <div className='container message-otr'>
                <div className='row' style={{ height: files.length === 0 ? '0px' : '' }}>
                  <div className='col-12 marg-top-10'>
                    <ImageGridDisplay photos={files} setPhotos={setFiles} type={'inline'} allowRemove />
                  </div>
                </div>
                <div className='row type-message-row'>
                  <div className='col-1 marg-top-10'>
                    <S3UploadFile
                      onChange={(newFiles) => {
                        setFiles([...files, ...newFiles]);
                      }}
                      type='icon'
                      name='filename'
                      multiple={true}
                      cta=''
                    />
                  </div>
                  <div className='col-10'>
                    <TextareaAutosize
                      className='material-input message-box'
                      value={currentMessage}
                      onChange={(e) => onBodyChange(e)}
                      onKeyDown={(e) => {
                        e.key === 'Enter' && heldKey !== 'Shift'
                          ? send(e)
                          : e.key === 'Shift'
                            ? setHeldKey(e.key)
                            : null;
                      }}
                      onKeyUp={(e) => {
                        e.key === heldKey ? setHeldKey(null) : null;
                      }}
                      onFocus={() => onRead()}
                      placeholder={
                        suggestedMessages.length !== 0
                          ? 'Introduce yourself...'
                          : `Message ${otherUser.profile.display_name}`
                      }
                      maxRows={15}
                      style={isMobile ? {} : { minHeight: '64px' }}
                    />
                    <div style={{ marginTop: '10px' }}>
                      {isUrlPreviewLoading && <LoadingScreen size={'20'} />}
                      {!isUrlPreviewLoading && urlMetadata && (
                        <UrlPreview metadata={urlMetadata} onDelete={removeUrlPreview} />
                      )}
                    </div>
                  </div>
                  <div className='col-1 send-message-col'>
                    <button
                      className={`send-message-button ${currentMessage === '' && files.length === 0 ? 'disabled' : ''}`}
                      onClick={send}
                    >
                      <svg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M15.5162 8.09473L1.20138 0.095958C1.05725 0.0149865 0.896708 -0.0156778 0.738463 0.00753903C0.580218 0.0307558 0.43079 0.106898 0.307592 0.22709C0.184395 0.347282 0.0925034 0.506575 0.0426271 0.686402C-0.00724916 0.86623 -0.0130558 1.05919 0.025884 1.24278L1.65861 8.99959L0.025884 16.7564C-0.0134202 16.9401 -0.00786745 17.1332 0.0418927 17.3133C0.0916529 17.4933 0.183563 17.6528 0.306872 17.7731C0.430181 17.8934 0.57979 17.9696 0.738199 17.9926C0.896607 18.0156 1.05727 17.9846 1.20138 17.9032L15.5162 9.90446C15.6608 9.82371 15.7831 9.69574 15.8688 9.53551C15.9545 9.37528 16 9.1894 16 8.99959C16 8.80979 15.9545 8.62391 15.8688 8.46368C15.7831 8.30344 15.6608 8.17548 15.5162 8.09473V8.09473ZM2.08974 15.1956L2.79622 11.8392L7.57904 8.99959L2.79622 6.16003L2.08974 2.80354L13.1795 8.99959L2.08974 15.1956Z'
                          fill='#74CCD3'
                        />
                      </svg>
                    </button>
                  </div>
                  <div className='col-2'></div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {selectedMenuItem.linkText === 'My Doctor' && isMobile && (
        <div style={{ backgroundColor: 'white' }} className={`container other-p-bio-sec`}>
          {current_user_is_womens_health ? <WomensHealthMyDoctorPanel user={user} /> : renderMyDoctorPanel()}
        </div>
      )}
    </div>
  );
}
