import React, { useState, useEffect, useContext } from 'react';
import Context from '../../../../utils/context';
import { Switch, useParams, useRouteMatch, Route, useHistory, Link } from 'react-router-dom';
import {
  getSurgeonReferredMentorMatch,
  updateSurgeonReferredMentorMatch,
} from '../../../../api/surgeon_referred_mentor_matches';
import HowAreYouFeeling from '../../../questionnaire/cosmetic/how_are_you_feeling';
import LoadingScreen from '../../../loading_screen';
import ChooseAnOption from '../../../questionnaire/choose_an_option';
import styles from '../index.module.css';
import YesNoExtra from '../../../questionnaire/yes_no_extra';
import UterineFibroidsPostSurvey from './uterine_fibroids';
import Xcopri from './xcopri';

export default function PostConversationQuestionnaire() {
  const context = useContext(Context);
  const { id } = useParams();
  const [surgeonReferredMentorMatch, setSurgeonReferredMentorMatch] = useState({});
  const [currentSurgery, setCurrentSurgery] = useState(
    surgeonReferredMentorMatch ? surgeonReferredMentorMatch.surgery : ''
  );
  const [conversationRating, setConversationRating] = useState('');
  const [comfortLevel, setComfortLevel] = useState('');
  const [confidenceLevel, setConfidenceLevel] = useState('');
  const [feelingNow, setFeelingNow] = useState('');
  const [isReadyForNextStep, setIsReadyForNextStep] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  let { path } = useRouteMatch();
  path = path.replace(`:id`, id);
  let history = useHistory();

  let surgeonIsNybg = false;
  if (surgeonReferredMentorMatch.surgeon) {
    surgeonIsNybg = surgeonReferredMentorMatch.surgeon.name === 'New York Bariatric Group';
  }

  let questionNumber = 1;
  let steps = ['comfort-level', 'confidence-level', 'feeling-now', 'next-step'];
  if (surgeonIsNybg) {
    steps = ['comfort-level', 'confidence-level', 'next-step'];
  } else if (currentSurgery && currentSurgery.includes('Epilepsy')) {
    steps = ['comfort-level', 'next-step', 'anything-else'];
  }
  let numberOfQuestions = steps.length + 1;
  steps.forEach((step, i) => {
    if (window.location.pathname.includes(step)) {
      questionNumber = i + 2;
    }
  });
  if (window.location.pathname.includes('/thank-you')) {
    questionNumber = numberOfQuestions + 1;
  }

  useEffect(() => {
    getSurgeonReferredMentorMatch(id, (data) => {
      setSurgeonReferredMentorMatch(data);
      setCurrentSurgery(data.surgery);
      setConversationRating(data.conversation_rating);
      setComfortLevel(data.comfort_level);
      setConfidenceLevel(data.confidence_level);
      setFeelingNow(data.feeling_now);
      setIsReadyForNextStep(data.ready_for_next_step);
      // not sure what has_follow_up_questions does
      // if (data.has_follow_up_questions != null) {
      //   history.push(`${path}/thank-you`);
      //   setQuestionNumber(numberOfQuestions + 1)
      // };
      setIsLoading(false);
    });
  }, [id, history, path]);

  const update = (params) => {
    updateSurgeonReferredMentorMatch(id, params, (data) => setSurgeonReferredMentorMatch(data));
  };

  // use this instead of 'update' on the final question
  const answeredFinalQuestion = (params) => {
    update({ ...params, post_survey_complete: true });
  };

  const renderQuestionnaire = () => {
    return (
      <Switch>
        <Route exact path={`${path}`}>
          <HowAreYouFeeling
            title={`Rate the conversation with your mentor ${surgeonReferredMentorMatch.patient_partner_name}.`}
            para="Your mentor won't see the rating. We want to make sure you're receiving the support you're looking for."
            answers={{ conversationRating: conversationRating }}
            numberRating={true}
            onSubmit={(answers) => {
              setConversationRating(answers.conversationRating);
              update({ conversation_rating: answers.conversationRating });
              history.push(`${path}/comfort-level`);
            }}
            star={true}
            icons={[
              { text: 'Very disappointing' },
              { text: 'Disappointing' },
              { text: 'Somewhat helpful' },
              { text: 'Helpful' },
              { text: 'Very helpful' },
            ]}
            textLabels={['Very disappointing', 'Disappointing', 'Somewhat helpful', 'Helpful', 'Very helpful']}
            keyForAnswer={'conversationRating'}
          />
        </Route>
        <Route exact path={`${path}/comfort-level`}>
          <ChooseAnOption
            onSubmit={(answers) => {
              setComfortLevel(answers.comfortLevel);
              update({ comfort_level: answers.comfortLevel });
              history.push(`${path}/confidence-level`);
            }}
            onBack={() => {
              history.push(`${path}`);
            }}
            answers={{ comfortLevel: comfortLevel }}
            title={`My mentor made me feel more comfortable about seeking treatment for my condition.`}
            para={"We want to make sure your receiving the support you're looking for."}
            keyForAnswer={'comfortLevel'}
            choices={['Strongly agree', 'Agree', 'Neither agree or disagree', 'Disagree', 'Strongly disagree']}
            showOther={false}
            selectOne
          />
        </Route>
        <Route exact path={`${path}/confidence-level`}>
          <ChooseAnOption
            onSubmit={(answers) => {
              setConfidenceLevel(answers.confidenceLevel);
              update({ confidence_level: answers.confidenceLevel });
              surgeonIsNybg ? history.push(`${path}/next-step`) : history.push(`${path}/feeling-now`);
            }}
            onBack={() => {
              history.push(`${path}/comfort-level`);
            }}
            answers={{ confidenceLevel: confidenceLevel }}
            title={`My mentor made me feel more confident working with ${surgeonIsNybg ? 'the New York Bariatric Group' : surgeonReferredMentorMatch.surgeon ? surgeonReferredMentorMatch.surgeon.name : 'the surgeon'}.`}
            para={"We want to make sure your receiving the support you're looking for."}
            keyForAnswer={'confidenceLevel'}
            choices={['Strongly agree', 'Agree', 'Neither agree or disagree', 'Disagree', 'Strongly disagree']}
            showOther={false}
            keyMultiplier={3}
            selectOne
          />
        </Route>
        <Route exact path={`${path}/feeling-now`}>
          <HowAreYouFeeling
            title='How are you feeling about getting treatment now?'
            para='We want to make sure mentors are providing emotional support an guidance. '
            answers={{ feeling_now: feelingNow }}
            numberRating={true}
            onSubmit={(answers) => {
              setFeelingNow(answers.feeling_now);
              update({ feeling_now: answers.feeling_now });
              history.push(`${path}/next-step`);
            }}
            onBack={() => {
              history.push(`${path}/confidence-level`);
            }}
            icons={[
              { text: 'Very nervous' },
              { text: 'Nervous' },
              { text: 'Unsure' },
              { text: 'Excited' },
              { text: 'Very excited' },
            ]}
            textLabels={['Very Nervous', 'Nervous', 'Unsure', 'Excited', 'Very Excited']}
            keyForAnswer={'feeling_now'}
          />
        </Route>
        <Route exact path={`${path}/next-step`}>
          <div className={`row activity-question`}>
            <div className='col-sm-2'></div>
            <div className='col-sm-8'>
              <YesNoExtra
                onSubmit={(answers) => {
                  setIsReadyForNextStep(answers.other ? answers.other : answers.isReadyForNextStep);
                  answeredFinalQuestion({
                    ready_for_next_step: answers.other ? answers.other : answers.isReadyForNextStep,
                  });
                  history.push(`${path}/thank-you`);
                }}
                onBack={() => {
                  surgeonIsNybg ? history.push(`${path}/confidence-level`) : history.push(`${path}/feeling-now`);
                }}
                answers={{ isReadyForNextStep: isReadyForNextStep }}
                title={`Are you ready to keep moving forward with your treatment or procedure?`}
                para={`Let us know if you're feeling prepared to take the next step on your journey.`}
                keyForAnswer={'isReadyForNextStep'}
                ctaText='Submit'
                extraIndex={1}
              />
            </div>
            <div className='col-sm-2'></div>
          </div>
        </Route>
        <Route exact path={`${path}/thank-you`}>
          <p className={styles.thank_you}>Thank you!</p>
          <p className={styles.thank_you_p}>
            We've received your feedback and will use the information you shared to improve the program.
          </p>
          <Link
            to={
              context.auth.isLoggedIn()
                ? '/member/discussions'
                : `/member/login?return_to=${encodeURIComponent('/member/discussions')}`
            }
          >
            <button className={`${styles.thank_you_button}`}>Explore the community</button>
          </Link>
        </Route>
      </Switch>
    );
  };

  const renderAllQuestionnaires = () => {
    if (currentSurgery && currentSurgery.includes('Fibroids')) {
      return (
        <Switch>
          <Route path={`${path}`}>
            <UterineFibroidsPostSurvey
              path={`${path}`}
              id={id}
              rootPath={path}
              surgeonReferredMentorMatch={surgeonReferredMentorMatch}
              update={update}
              answeredFinalQuestion={answeredFinalQuestion}
            />
          </Route>
          <Route exact path={`${path}/thank-you`}>
            <p className={styles.thank_you}>Thank you!</p>
            <p className={styles.thank_you_p}>
              We’ve received your feedback and will use the information you shared to improve the program.
            </p>
            <Link
              to={
                context.auth.isLoggedIn()
                  ? '/member/discussions'
                  : `/member/login?return_to=${encodeURIComponent('/member/discussions')}`
              }
            >
              <button className={`${styles.thank_you_button}`}>Explore the community</button>
            </Link>
          </Route>
        </Switch>
      );
    }

    if (currentSurgery && currentSurgery.includes('Epilepsy')) {
      return (
        <Switch>
          <Route path={`${path}`}>
            <Xcopri
              path={`${path}`}
              id={id}
              rootPath={path}
              surgeonReferredMentorMatch={surgeonReferredMentorMatch}
              update={update}
              answeredFinalQuestion={answeredFinalQuestion}
            />
          </Route>
          <Route exact path={`${path}/thank-you`}>
            <p className={styles.thank_you}>Thank you!</p>
            <p className={styles.thank_you_p}>
              We’ve received your feedback and will use the information you shared to improve the program.
            </p>
            <Link
              to={
                context.auth.isLoggedIn()
                  ? '/member/discussions'
                  : `/member/login?return_to=${encodeURIComponent('/member/discussions')}`
              }
            >
              <button className={`${styles.thank_you_button}`}>Explore the community</button>
            </Link>
          </Route>
        </Switch>
      );
    }

    return renderQuestionnaire();
  };

  return (
    <div className={styles.questionnaire_ottr} style={{ backgroundColor: '#f5f7f9', maxWidth: '100%' }}>
      <div id='questionnaire' className='container h-100'>
        <div className='row justify-content-center h-100'>
          <div className='col h-100'>
            <div className={`row ${styles.introQuestion} h-100`}>
              <div className='col-sm-2'></div>
              <div className='col-sm-8'>
                {questionNumber <= numberOfQuestions && (
                  <h5 style={{ width: '100%', marginBottom: '30px', textAlign: 'center', fontSize: '16px' }}>
                    {questionNumber} of {numberOfQuestions}
                  </h5>
                )}
                {isLoading ? <LoadingScreen /> : renderAllQuestionnaires()}
              </div>
              <div className='col-sm-2'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
