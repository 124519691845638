import React, { Component, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import BedsideManner from '../bedside_manner';
import IconButtonSVG from '../ui_kit/utilities/icon_button_svg';
import ScrollTo from '../scroll_to';
import ReactGA from 'react-ga';
import queryString from 'query-string';
import './index.css';
import SurgeonBookingModal from '../surgeon_booking_modal';

export default function SurgeonsColumn({
  home,
  surgeon = null,
  index,
  showShowMore = true,
  showRequestAnAppointment = true,
  gtm = 'Surgeon gallery',
  seeMoreLink = `/surgeon/${surgeon.slug}`,
  onQuestionnaire,
  showSeeMore = true,
  clickable = true,
  showBedsideManner = true,
  showFullLocation = false,
  showPhoneNumber = false,
  cta = null,
}) {
  const [showModal, setShowModal] = useState(false);
  // Listens to know if the device is a mobile device START
  const [isMobile, setDesktop] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  // Listens to know if the device is a mobile device END

  const [showLongBio, setShowLongBio] = useState(false);
  const showLessClick = () => {
    setShowLongBio(false);
  };
  const showMoreClick = () => {
    setShowLongBio(true);
  };

  const renderBio = (bio) => {
    const shortBio = bio.substring(0, 100) + '...';
    if (shortBio === bio) {
      return bio;
    }
    if (showLongBio) {
      return (
        <>
          <span>{bio}</span>
          {showShowMore == true && (
            <div>
              <p className='text-link no-arrow marg-top-20' onClick={showLessClick}>
                Show less
              </p>
            </div>
          )}
        </>
      );
    }

    return (
      <>
        <span className='short-bio'>{bio}</span>
        {showShowMore == true && (
          <div>
            <p className='text-link no-arrow marg-top-20' onClick={showMoreClick}>
              Show more
            </p>
          </div>
        )}
      </>
    );
  };

  // This is used to make the entire surgeon card clickable as well as the links inside of it. START
  const history = useHistory();
  const goToCardLink = (e) => {
    if (e.target.parentNode.classList.contains('link-container')) {
      if (e.target.getAttribute('aria-label')) {
        history.push(e.target.getAttribute('aria-label'));
      }
    } else {
      history.push(seeMoreLink);
    }
    ReactGA.event({
      category: 'Click',
      action: 'Click',
      label: `${gtm} Card Click`,
    });
  };
  // END

  if (index < 10) {
    index = '0' + index;
  }

  let yearsOfExperience = null;
  if (surgeon.extra.years_of_experience) {
    yearsOfExperience = parseInt(surgeon.extra.years_of_experience) + (new Date().getFullYear() - 2021);
  }

  const showEducationAndAffiliation =
    yearsOfExperience &&
    surgeon.extra.number_of_procedures_performed &&
    yearsOfExperience < 5 &&
    surgeon.extra.number_of_procedures_performed < 500;

  return (
    <div>
      <div
        data-pendo-tag={`doctor discovery - gallery profile card - ${surgeon.name}`}
        onClick={(e) => (clickable ? goToCardLink(e) : null)}
        className='surgeon surgeon-pre-wrapper'
        style={clickable || cta ? null : { pointerEvents: 'none' }}
      >
        <div className='profile-innr container'>
          <div className='row'>
            <div className='col-md-8 intro-info'>
              <div className='row'>
                <div className='col-lg-6'>
                  <h5 className='doc-name'>{surgeon.name}</h5>
                </div>
                {showBedsideManner && (
                  <div className='col-lg-6 bedside-otr'>
                    <BedsideManner slide={false} bedsideManner={surgeon.extra.bedside_manner} />
                  </div>
                )}
              </div>
              <div className={`stats-container ${onQuestionnaire ? 'marg-bottom-50' : 'marg-bottom-20'}`}>
                {!showFullLocation && surgeon.location && surgeon.location != null && (
                  <a onClick={() => ScrollTo('#location', 200)}>
                    <div className='location icon-left'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24.376'
                        height='29.126'
                        viewBox='0 0 24.376 29.126'
                      >
                        <g id='Icon_feather-map-pin' data-name='Icon feather-map-pin' transform='translate(1.5 1.5)'>
                          <path
                            id='Path_10'
                            data-name='Path 10'
                            d='M25.876,12.188c0,8.313-10.688,15.438-10.688,15.438S4.5,20.5,4.5,12.188a10.688,10.688,0,1,1,21.376,0Z'
                            transform='translate(-4.5 -1.5)'
                            fill='none'
                            stroke='#77ccd2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='3'
                          />
                          <path
                            id='Path_11'
                            data-name='Path 11'
                            d='M20.625,14.063A3.563,3.563,0,1,1,17.063,10.5a3.563,3.563,0,0,1,3.563,3.563Z'
                            transform='translate(-6.375 -3.375)'
                            fill='none'
                            stroke='#77ccd2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='3'
                          />
                        </g>
                      </svg>
                      <p className='my-auto'>{surgeon.location}</p>
                    </div>
                  </a>
                )}
                {showFullLocation && surgeon.office_locations && surgeon.office_locations[0] && (
                  <a onClick={() => ScrollTo('#location', 200)}>
                    <div className='location icon-left'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24.376'
                        height='29.126'
                        viewBox='0 0 24.376 29.126'
                      >
                        <g id='Icon_feather-map-pin' data-name='Icon feather-map-pin' transform='translate(1.5 1.5)'>
                          <path
                            id='Path_10'
                            data-name='Path 10'
                            d='M25.876,12.188c0,8.313-10.688,15.438-10.688,15.438S4.5,20.5,4.5,12.188a10.688,10.688,0,1,1,21.376,0Z'
                            transform='translate(-4.5 -1.5)'
                            fill='none'
                            stroke='#77ccd2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='3'
                          />
                          <path
                            id='Path_11'
                            data-name='Path 11'
                            d='M20.625,14.063A3.563,3.563,0,1,1,17.063,10.5a3.563,3.563,0,0,1,3.563,3.563Z'
                            transform='translate(-6.375 -3.375)'
                            fill='none'
                            stroke='#77ccd2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='3'
                          />
                        </g>
                      </svg>
                      <p className='my-auto'>{surgeon.office_locations[0].address}</p>
                    </div>
                  </a>
                )}
                {showPhoneNumber && surgeon.office_locations && surgeon.office_locations[0] && (
                  <div className='fellowship icon-left'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                      style={{ cursor: 'pointer' }}
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M9.87815 13.9919L10.0081 14.1218C12.2457 16.2882 14.1184 17.3056 15.6261 17.174L16.0605 16.7687C16.6785 16.2064 17.1444 15.85 17.4584 15.6994C18.112 15.3859 18.5707 15.3922 19.8094 16.0625C21.439 16.9442 22.5642 17.9691 23.1392 18.5441C23.6731 19.078 23.5072 19.9977 23.3197 20.4057L23.1955 20.6333C22.8619 21.2129 21.6521 23.1249 19.3334 23.4521C18.4523 23.5764 17.3399 23.4624 16.0357 23.0696C13.2419 22.1024 10.4598 20.3297 7.68932 17.7514L7.19661 17.2834L7.19197 17.288L6.95419 17.046L6.71199 16.808L6.71664 16.8034L6.24857 16.3107C3.67035 13.5402 1.89763 10.7581 0.930416 7.96428C0.537607 6.66006 0.423627 5.54772 0.547946 4.66655C0.89822 2.18383 3.06566 0.972407 3.46871 0.746715L3.50964 0.723616C3.85849 0.522447 4.88095 0.285854 5.45591 0.860816L5.72814 1.14078C6.32079 1.76756 7.18177 2.79378 7.93755 4.19059C8.6078 5.42934 8.61405 5.88801 8.30058 6.54161C8.11655 6.92534 7.625 7.53612 6.82595 8.37394C6.69439 9.88161 7.71179 11.7543 9.87815 13.9919Z'
                        fill='#59B6BE'
                      ></path>
                    </svg>
                    <p className='my-auto'>{surgeon.office_locations[0].phone_number}</p>
                  </div>
                )}
                {surgeon.specialties && surgeon.specialties !== null && surgeon.specialties[0] && (
                  <div className='specialties icon-left'>
                    <svg xmlns='http://www.w3.org/2000/svg' width='25.376' height='25.376' viewBox='0 0 25.376 25.376'>
                      <path
                        id='Icon_map-health'
                        data-name='Icon map-health'
                        d='M23.1,8.179a.468.468,0,0,0-.466-.466h-6.06a.468.468,0,0,1-.466-.466V1.186A.468.468,0,0,0,15.637.72H8.179a.468.468,0,0,0-.466.466v6.06a.468.468,0,0,1-.466.466H1.186a.468.468,0,0,0-.466.466v7.459a.468.468,0,0,0,.466.466h6.06a.468.468,0,0,1,.466.466v6.06a.468.468,0,0,0,.466.466h7.459a.468.468,0,0,0,.466-.466v-6.06a.468.468,0,0,1,.466-.466h6.06a.468.468,0,0,0,.466-.466Z'
                        transform='translate(0.78 0.78)'
                        fill='none'
                        stroke='#77ccd2'
                        strokeWidth='3'
                      />
                    </svg>
                    <p className='my-auto'>
                      {surgeon.specialties}
                      {surgeon.procedure_types && (
                        <>{`${surgeon.specialties.length > 0 ? ': ' : ''}${surgeon.procedure_types.join(' | ')}`}</>
                      )}
                    </p>
                  </div>
                )}
                {showEducationAndAffiliation && (
                  <>
                    {surgeon.extra.fellowship && (
                      <div className='fellowship icon-left'>
                        <svg width='25' height='23' viewBox='0 0 25 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M18.7496 15.1498C18.7496 16.2936 18.1327 17.3484 17.1358 17.9092L14.4606 19.4139C13.243 20.0989 11.7562 20.0989 10.5385 19.4139L7.86337 17.9092C6.86649 17.3484 6.24958 16.2936 6.24958 15.1498V13.8124C6.24958 13.4881 6.07379 13.1893 5.79034 13.0319V13.0319C5.19522 12.7012 4.46387 13.1316 4.46387 13.8124V16.5734C4.46387 17.4829 4.95442 18.3216 5.74711 18.7675L10.5385 21.4627C11.7562 22.1476 13.243 22.1476 14.4606 21.4627L19.2521 18.7675C20.0447 18.3216 20.5353 17.4829 20.5353 16.5734V13.8124C20.5353 13.1316 19.8039 12.7012 19.2088 13.0319V13.0319C18.9254 13.1893 18.7496 13.4881 18.7496 13.8124V15.1498Z'
                            fill='#59B6BE'
                          />
                          <path
                            d='M14.3413 0.954729C13.1867 0.356088 11.8133 0.356089 10.6587 0.95473L0.698172 6.11946C0.269244 6.34187 0 6.78476 0 7.26792V7.26792C0 7.73774 0.254719 8.17064 0.665421 8.3988L10.5575 13.8942C11.7655 14.5654 13.2345 14.5654 14.4425 13.8942L21.929 9.73524C22.5056 9.41489 23.2143 9.83187 23.2143 10.4915V13.0593C23.2143 13.5524 23.614 13.9522 24.1071 13.9522V13.9522C24.6003 13.9522 25 13.5524 25 13.0593V7.08939C25 6.71591 24.7919 6.37356 24.4603 6.20164L14.3413 0.954729ZM21.4286 7.97048L19.6429 8.9625L14.4427 11.8517C13.2345 12.5229 11.7655 12.5229 10.5573 11.8517L5.35714 8.9625L3.57143 7.97048V7.97048C3.04149 7.67606 3.05251 6.91019 3.59069 6.63113L10.6587 2.96622C11.8133 2.36758 13.1867 2.36758 14.3413 2.96622L21.4093 6.63113C21.9475 6.91019 21.9585 7.67606 21.4286 7.97048V7.97048Z'
                            fill='#59B6BE'
                          />
                        </svg>
                        <p className='my-auto'>{surgeon.extra.fellowship}</p>
                      </div>
                    )}
                    {surgeon.extra.hospital_affiliations && surgeon.extra.hospital_affiliations.length > 0 && (
                      <div className='hospital-affiliation icon-left'>
                        <svg width='25' height='25' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M13.6562 5.95C13.6562 5.83954 13.5667 5.75 13.4562 5.75H10.9813C10.8708 5.75 10.7812 5.66046 10.7812 5.55V3.075C10.7812 2.96454 10.6917 2.875 10.5812 2.875H9.54375C9.43329 2.875 9.34375 2.96454 9.34375 3.075V5.55C9.34375 5.66046 9.25421 5.75 9.14375 5.75H6.66875C6.55829 5.75 6.46875 5.83954 6.46875 5.95V6.9875C6.46875 7.09796 6.55829 7.1875 6.66875 7.1875H9.14375C9.25421 7.1875 9.34375 7.27704 9.34375 7.3875V9.8625C9.34375 9.97296 9.43329 10.0625 9.54375 10.0625H10.5812C10.6917 10.0625 10.7812 9.97296 10.7812 9.8625V7.3875C10.7812 7.27704 10.8708 7.1875 10.9813 7.1875H13.4562C13.5667 7.1875 13.6562 7.09796 13.6562 6.9875V5.95Z'
                            fill='#59B6BE'
                          />
                          <path
                            d='M18.6875 5.75H17.25V1.4375C17.2496 1.05637 17.098 0.690955 16.8285 0.421454C16.559 0.151953 16.1936 0.000380561 15.8125 0H4.3125C3.93137 0.000380561 3.56596 0.151953 3.29645 0.421454C3.02695 0.690955 2.87538 1.05637 2.875 1.4375V5.75H1.4375C1.05637 5.75038 0.690955 5.90195 0.421454 6.17145C0.151953 6.44096 0.000380561 6.80637 0 7.1875V19.725C0 19.9459 0.179086 20.125 0.4 20.125H19.725C19.9459 20.125 20.125 19.9459 20.125 19.725V7.1875C20.1246 6.80637 19.973 6.44096 19.7035 6.17145C19.434 5.90195 19.0686 5.75038 18.6875 5.75ZM8.625 18.6875V14.375H11.5V18.6875H8.625ZM12.9375 18.6875V13.6562C12.9375 13.4656 12.8618 13.2828 12.727 13.148C12.5922 13.0132 12.4094 12.9375 12.2188 12.9375H7.90625C7.71563 12.9375 7.53281 13.0132 7.39802 13.148C7.26323 13.2828 7.1875 13.4656 7.1875 13.6562V18.6875H2.0375C1.70613 18.6875 1.4375 18.4189 1.4375 18.0875V7.1875H3.7125C4.04387 7.1875 4.3125 6.91887 4.3125 6.5875V1.4375H15.8125V6.5875C15.8125 6.91887 16.0811 7.1875 16.4125 7.1875H18.6875V18.0875C18.6875 18.4189 18.4189 18.6875 18.0875 18.6875H12.9375Z'
                            fill='#59B6BE'
                          />
                        </svg>
                        <p className='my-auto'>{surgeon.extra.hospital_affiliations[0]}</p>
                      </div>
                    )}
                  </>
                )}
                {!showEducationAndAffiliation && (
                  <>
                    {surgeon.extra.years_of_experience && (
                      <div className='years-exp icon-left'>
                        <svg width='25' height='25' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M9.795 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V9'
                            stroke='#77CCD2'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20Z'
                            stroke='#77CCD2'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M13 1V5'
                            stroke='#77CCD2'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M5 1V5'
                            stroke='#77CCD2'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M1 9H17'
                            stroke='#77CCD2'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                          <path
                            d='M16 14.496V16L17 17'
                            stroke='#77CCD2'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                        <p className='my-auto'>
                          {parseInt(surgeon.extra.years_of_experience) + (new Date().getFullYear() - 2021)}{' '}
                          {/* Makes the year increment every year */} years of experience
                        </p>
                      </div>
                    )}
                    {surgeon.extra.number_of_procedures_performed && (
                      <div className='surgeries-performed icon-left'>
                        <svg width='25' height='25' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M18.9746 5.68359C18.0957 5.68359 17.3551 5.97656 16.7529 6.5625C16.1507 7.14844 15.8496 7.89714 15.8496 8.80859C15.8496 9.45964 16.0531 10.0537 16.46 10.5908C16.8669 11.1279 17.3633 11.4941 17.9492 11.6895V14.7949C17.9492 16.6504 17.5 18 16.7529 19C16.0059 20 14.5 21.0449 13 21.0449C11.112 21.0449 10.3184 20.8184 9 19.5C7.68164 18.1816 7.27539 16.1914 7.27539 16.1621V13.4277C8.77279 13.1673 10.0179 12.4593 11.0107 11.3037C12.0036 10.1481 12.5 8.80534 12.5 7.27539V2.09961C12.5 1.51367 12.2965 1.01725 11.8896 0.610352C11.4827 0.203451 10.9863 0 10.4004 0H9.375C8.69141 0 8.34961 0.341797 8.34961 1.02539C8.34961 1.74154 8.69141 2.09961 9.375 2.09961H10.4004V7.27539C10.4004 8.41471 9.99349 9.39941 9.17969 10.2295C8.36589 11.0596 7.38932 11.4746 6.25 11.4746C5.11068 11.4746 4.13411 11.0596 3.32031 10.2295C2.50651 9.39941 2.09961 8.41471 2.09961 7.27539V2.09961H3.125C3.80859 2.09961 4.15039 1.74154 4.15039 1.02539C4.15039 0.341797 3.80859 0 3.125 0H2.09961C1.51367 0 1.01725 0.203451 0.610352 0.610352C0.203451 1.01725 0 1.51367 0 2.09961V7.27539C0 8.80534 0.496419 10.1481 1.48926 11.3037C2.4821 12.4593 3.72721 13.1673 5.22461 13.4277V16.1621C5.22461 16.7773 5.55013 19.0137 7.27539 20.739C9.00065 22.4643 10.5586 23 13 23C14.7891 23 17.213 21.8581 18.5 20C19.787 18.1419 20 17.2363 20 14.7949V11.6895C20.6185 11.4941 21.123 11.1279 21.5137 10.5908C21.9043 10.0537 22.0996 9.45964 22.0996 8.80859C22.0996 7.89714 21.7985 7.14844 21.1963 6.5625C20.5941 5.97656 19.8535 5.68359 18.9746 5.68359ZM18.9746 9.83398C18.291 9.83398 17.9492 9.49219 17.9492 8.80859C17.9492 8.09245 18.291 7.73438 18.9746 7.73438C19.6582 7.73438 20 8.09245 20 8.80859C20 9.49219 19.6582 9.83398 18.9746 9.83398Z'
                            fill='#77CCD2'
                          />
                        </svg>
                        <p className='my-auto'>{surgeon.extra.number_of_procedures_performed} procedures performed</p>
                      </div>
                    )}
                  </>
                )}
              </div>
              {showSeeMore && (
                <div
                  style={isMobile ? { display: 'flex', flexDirection: 'column-reverse' } : {}}
                  className='marg-top-20 link-container'
                >
                  <button
                    className={`liner`}
                    data-gtm-click-label={`${gtm} see more`}
                    aria-label={seeMoreLink}
                    style={isMobile ? { width: '100%' } : { marginRight: '5px' }}
                  >
                    See more
                  </button>
                  {/* <button data-gtm-click-label={`${gtm} Check cost`} aria-label={`${seeMoreLink}?scrollTo=check-cost`} style={isMobile ? {width: '100%', marginBottom:'5px'} : {marginLeft: '5px'}}>Check cost</button> */}
                </div>
              )}
              {cta}
            </div>
            <div className='col-md-4 photo-side'>
              <div className='profile-photo' style={{ backgroundImage: `url('${surgeon.profile_photo}')` }}></div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <SurgeonBookingModal
          home={home}
          surgeon={surgeon}
          onClose={() => {
            setShowModal(false);
          }}
          origin={'Surgeon Gallery Card'}
        />
      )}
    </div>
  );
}
