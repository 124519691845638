import React, { useState, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { createDoctorConnectPatient } from '../../../api/doctor_connect';
import ImgRightTextLeft from '../../hero_sections/img_right_text_left';
import ImgRightTextLeftSimple from '../../hero_sections/img_right_text_left_simple';
import MinimalTestimonials from '../../minimal_testimonials';
import AgreeToTerms from '../../ui_kit/utilities/agree_to_terms';
import SuccessMessage from '../../ui_kit/utilities/success_message';
import ValueOffer from '../../value_offer';
import Context from '../../../utils/context';
import styles from '../acl/index.module.css';
import { createBetaDoctorConnectContact } from '../../../api/beta_doctor_connect_contacts';

export default function DoctorConnectAgnostic() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [doctor, setDoctor] = useState('');
  const [message, setMessage] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  let { path } = useRouteMatch();

  const submit = () => {
    createDoctorConnectPatient({ name, email, phone, doctor }, () => {
      setName('');
      setEmail('');
      setPhone('');
      setDoctor('');
      setAcceptedTerms(false);
      setMessage('Request received! Our team is researching and will give you a call to share their findings.');
    });
  };

  const isMobile = useContext(Context).viewport.getIsMobile();
  const isLoggedIn = useContext(Context).auth.isLoggedIn();
  const flowB = new URLSearchParams(window.location.search).get('type') === 'b' ? true : false;
  const origin = flowB ? 'Paid Beta Doctor Connect Questionnaire' : 'Unpaid Beta Doctor Connect Questionnaire';
  const history = useHistory();
  const createContactAndRedirect = () => {
    createBetaDoctorConnectContact(origin, (data) => {
      flowB
        ? isLoggedIn
          ? history.push(`${path}/${data.uuid}/specialist`)
          : history.push(`/member/join/b/doctor-connect?returnTo=${path}/${data.uuid}/specialist`)
        : history.push(`${path}/${data.uuid}/name`);
    });
  };

  return (
    <div>
      <ImgRightTextLeft
        img={'https://p-user-uploads2.s3.us-west-1.amazonaws.com/uploads-production/uploads/docava-a.jpg'}
        bg='#fff'
        headline='Find your perfect doctor'
        para="Tell us what you want in a doctor and we'll do the rest. No more filtering through hundreds of results. Just one doctor, perfectly matched to you."
        actionContent={
          <button
            onClick={createContactAndRedirect}
            style={{ padding: '12px 20px' }}
            data-gtm-click-label='DoctorConnect Sign up for free'
          >
            Get Started
          </button>
        }
        marginTopPhotoMobile='20px'
        paddImg={false}
        padLeft={isMobile ? '' : '45px'}
      />
      <ValueOffer />
      <ImgRightTextLeftSimple
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/guide.jpg'
        bg='#fff'
        headline='Your dedicated guide'
        headlineType='h2'
        middleContent={
          <>
            <p style={{ marginTop: '20px', marginBottom: '20px' }} className={styles.list_para}>
              Your personal guide will help you find the best doctor who meets your needs. Our one-on-one, human
              approach creates a personalized experience so you can feel confident and supported every step of the way.
            </p>
            <div className={styles.unlist}>
              <div className={styles.list_item}>
                <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/check.svg' />
                <p>
                  <b>Doctor overview:</b> Your guide will walk you through the specialist's credentials and explain why
                  we matched them to you.
                </p>
              </div>
              <div className={styles.list_item}>
                <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/check.svg' />
                <p>
                  <b>Booking priority appointments:</b> Your guide will do the leg work and help you get an appointment
                </p>
              </div>
              <div className={styles.list_item}>
                <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/check.svg' />
                <p>
                  <b>Insurance verification:</b> Your guide will ensure your doctor and procedure are in-network, and
                  help answer any insurance-related questions you have.
                </p>
              </div>
            </div>
          </>
        }
        actionContent={
          <button
            onClick={createContactAndRedirect}
            data-gtm-click-label='doctor connect Get Started For Free'
            style={{ padding: '12px 20px' }}
          >
            Get Started
          </button>
        }
        swap
      />
      <ImgRightTextLeftSimple
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/cardstack.jpg'
        bg='#fff'
        headline='How it works'
        headlineType='h2'
        middleContent={
          <div className={styles.unlist} style={{ marginTop: '10px' }}>
            <div className={styles.list_item}>
              <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/check.svg' />
              <p>
                <b>Tell us about your priorities:</b> Answer some questions about what's most important to you in a
                specialist.
              </p>
            </div>
            <div className={styles.list_item}>
              <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/check.svg' />
              <p>
                <b>We do deep research:</b> Based on your priorities, we research and find the perfect specialist that
                fits your needs.
              </p>
            </div>
            <div className={styles.list_item}>
              <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/check.svg' />
              <p>
                <b>We share our findings with you:</b> Within 1-2 business days, your personal guide will contact you to
                discuss which doctor best matches your needs and help you set up an appointment.
              </p>
            </div>
          </div>
        }
        actionContent={
          <button
            onClick={createContactAndRedirect}
            data-gtm-click-label='doctor connect Get Started For Free'
            style={{ padding: '12px 20px' }}
          >
            Get Started
          </button>
        }
      />
      <MinimalTestimonials
        testimonials={[
          {
            name: 'Martin',
            location: 'Phoenix, AZ',
            content:
              'I used to think reviews were enough until I found out a lot of negative reviews get removed. PatientPartner helped me find a doctor who was the best in the field for the procedure I needed!',
          },
          {
            name: 'Sarah',
            location: 'San Francisco, CA',
            content:
              'I didn’t know how to even begin searching for a doctor when I tore my ACL. The PatientPartner team made me feel comfortable going through surgery knowing I had the best care.',
          },
        ]}
        autoplay
      />
      <div className={styles.cta_form}>
        <div
          className={styles.l_img}
          style={{
            backgroundImage:
              'url(https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-connect/Group+170.jpg)',
          }}
        ></div>
        <div className={styles.form}>
          <h2 className='marg-bottom-10'>Already working with a doctor?</h2>
          <p>Let us know and we'll do a free check to see how your doctor stacks up to the PatientPartner standards.</p>
          <div className={`material-input ${styles.form_inputs}`}>
            <input
              placeholder='Full Name'
              type='text'
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
            <input
              placeholder='Email'
              type='text'
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <input
              placeholder='Phone Number'
              type='text'
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              required
            />
            <input
              placeholder='Doctor Name'
              type='text'
              value={doctor}
              onChange={(event) => setDoctor(event.target.value)}
              required
            />
          </div>
          <AgreeToTerms acceptedTerms={acceptedTerms} setAcceptedTerms={setAcceptedTerms} />
          <button
            disabled={name === '' || email === '' || phone === '' || doctor === '' || !acceptedTerms}
            style={{ marginTop: '20px', width: '100%' }}
            onClick={() => submit()}
          >
            Submit
          </button>
          <SuccessMessage success={message} />
        </div>
      </div>
    </div>
  );
}
