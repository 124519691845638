import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Context from '../../../../utils/context';
import TextLeftImgRightWbg from '../../../hero_sections/txt_left_img_right_wbg';
import ImgLeftTextRight from '../../../hero_sections/img_left_text_right';
import TwoTestimonials from '../../../two_testimonials';
import HowItWorks from '../../../hero_sections/how_it_works';
import VideoSec1 from '../../../video_sections/video_sec_1_no_bg_L';
import { autofocusAll } from '../../../../helpers';
import ImgRightTextLeftSimple from '../../../hero_sections/img_right_text_left_simple';
import styles from './index.module.css';
import FAQs from '../../../ui_kit/utilities/faqs';
import SignUp from '../../../sign_up';
import LoadingScreen from '../../../loading_screen';
import { createSurgeonReferredMentorMatch } from '../../../../api/surgeon_referred_mentor_matches';
import { getSurgeon } from '../../../../api/surgeons';

export default function MentorMatchReactiv8Common({
  home,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setContactIdAction,
  setPatientPartners,
  surgeonSlug = 'reactiv8',
  captureLocation = true,
  australiaPhone = false,
  countryRestriction = 'us',
  locationPlaceholder = 'Enter zip code',
  origin = 'Surgeon Referred Mentor Match',
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();
  const [view, setView] = useState('loading');
  const [surgeon, setSurgeon] = useState();
  const utm_source = useRef(new URLSearchParams(window.location.search).get('utm_source'));
  const history = useHistory();

  const scrollToQuestionnaire = () => {
    document.querySelector('#questionnaire').scrollIntoView({
      behavior: 'smooth',
    });
    const timer = setTimeout(() => {
      autofocusAll();
    }, 1000);
    return () => clearTimeout(timer);
  };

  useEffect(() => {
    getSurgeon(surgeonSlug, (data) => {
      setSurgeon(data);
      setView('loaded');
    });
  }, []);

  React.useEffect(() => {
    if (new URLSearchParams(window.location.search).get('contact_id')) {
      const timer = setTimeout(() => {
        document.querySelector('#questionnaire').scrollIntoView({
          behavior: 'smooth',
        });
      }, 100);
      return () => clearTimeout(timer);
    }
  }, []);

  const createAndRedirect = () => {
    createSurgeonReferredMentorMatch(
      {
        surgeon_id: surgeon.id,
        surgery: null,
        ...(utm_source.current === 'SurgeonPatientLead' &&
          uuid.current !== null && { surgeon_patient_lead: uuid.current }),
      },
      (match) => {
        history.push(`/member/surgeon-mentor-matches/${match.uuid}/pre-survey`);
      }
    );
  };

  return (
    <div>
      <TextLeftImgRightWbg
        headerImgWidth={250}
        headline={
          <p className={styles.headline}>
            Connect with your <span className={styles.headline_highlight}>ReActiv8</span> mentor
          </p>
        }
        para={
          'Chat with a ReActiv8 patient to get all your questions answered. Learn about the procedure, therapy, and life with ReActiv8 for free!'
        }
        bg={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/reactiv8/reactiv8-hero1.png'}
        actionContent={
          <div className='btn-wrap'>
            <button onClick={scrollToQuestionnaire} className={styles.button}>
              Find my mentor
            </button>
          </div>
        }
        afterActionContent={
          <p className={styles.after_action_content}>
            <em>*Get matched with a mentor in under a minute.</em>
          </p>
        }
        imgOffset={false}
        topOfIcon={isMobile ? '65%' : '75%'}
        leftOfIcon={isMobile ? '10%' : '35%'}
        heightOfImage={isMobile && '300px'}
        iconContent={''}
        imgShadow={false}
        thoseDots={''}
        dotStyles={
          isMobile
            ? { maxWidth: '76px', top: '45px', right: '25px', zIndex: '1' }
            : { maxWidth: '136px', top: '120px', right: '-90px', zIndex: '1' }
        }
        mobileBackgroundSize='contain'
        bgColor='#FFFFFF'
      />
      <div
        style={{ backgroundColor: '#F2F0E4', paddingTop: '55px', paddingBottom: '55px' }}
        className='container-fluid'
      >
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <h3 className={styles.so_headline}>
                PatientPartner is a complimentary, private, and easy-to-use tool that allows you to ask questions and
                get more insight about ReActiv8 therapy from your mentor.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <ImgLeftTextRight
        img={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/reactiv8/reactiv8-hero2.png'}
        headline={<p className={styles.small_headline}>Feel empowered and confident in your decisions!</p>}
        subheadline={<p className={styles.small_subheadline}>Things you can ask your mentor privately:</p>}
        list={[
          <span>How does the therapy feel?</span>,
          <span>How soon can you exercise?</span>,
          <span>Do you notice the device?</span>,
          <span>How is your pain level now?</span>,
        ]}
        littlePara=''
        cta={
          <div className='btn-wrap'>
            <button onClick={scrollToQuestionnaire} className={styles.button}>
              Find my mentor
            </button>
          </div>
        }
        blueBg={false}
        containerClassNames={isMobile ? 'marg-top-100 h-100' : 'marg-top-100 marg-bottom-100 h-100'}
      />
      <HowItWorks
        smallHeadlineStyle={{
          fontFamily: 'Utopia STD',
          color: '#48433D',
          fontWeight: '600',
          letterSpacing: '0.01em',
        }}
        steps={[
          {
            image: (
              <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/reactiv8/reactiv8-create-account.png' />
            ),
            headline: 'Create your free account',
            para: 'On behalf of ReActiv8, you get complimentary access to PatientPartner.',
          },
          {
            image: (
              <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/reactiv8/reactiv8-message-mentor.jpg' />
            ),
            headline: 'Message your mentor',
            para: 'Review the mentor you’ve been matched with and send them a message to introduce yourself and ask questions.',
          },
          {
            image: (
              <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/reactiv8/reactiv8-support-advice.png' />
            ),
            headline: 'Get support and advice',
            para: 'Ask questions about personal experiences and advice your doctor won’t be able to provide you.',
          },
        ]}
        stepHeadlineStyle={{
          fontFamily: 'Utopia Std',
          fontWeight: '700',
          fontSize: '28px',
          color: '#129A76',
        }}
      />
      <div style={{ marginTop: '50px' }}>
        <TwoTestimonials
          headline={<p className={styles.testimonials}>Hear what others are saying.</p>}
          smallHeadline={<p className={styles.testimonials_small}>Real patients, real stories.</p>}
          bg={'#129A76'}
          quoteColor={'#129A76'}
          nameClassName={styles.testimonial_name}
          cityClassName={styles.testimonial_city}
          doNotAnimate={true}
          testimonials={[
            {
              text: "Talking with my mentor made all the difference. Hearing from someone who had been through it and come out stronger gave me the confidence I needed to move forward with ReActiv8. Now, my back pain is nearly gone, and I'm getting my strength back. It's life-changing.",
              image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/reactiv8/reactiv8-scott.png',
              name: 'Scott',
              city: 'Indianapolis, IN',
            },
            {
              text: "I was so impressed by how quickly my mentor responded and how thoroughly she answered all of my questions. Her support made a real difference, and I couldn't recommend the mentor program enough! 😊👍",
              image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/reactiv8/reactiv8-linda.png',
              name: 'Linda',
              city: 'Pittsburgh, PA',
            },
          ]}
        />
      </div>
      <div className={'marg-bottom-50'}>
        <VideoSec1
          headline={
            <p className={isMobile ? styles.video_headline_mobile : styles.video_headline}>
              Real Stories of Pain Relief and Recovery
            </p>
          }
          subheadline={
            <p className={isMobile ? styles.video_subheadline_mobile : styles.video_subheadline}>
              Experience ReActiv8 Through Patients' Eyes
            </p>
          }
          videoLink={
            'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/reactiv8/reactiv8-embedded-video.mp4'
          }
        />
      </div>
      <div id='questionnaire' className={`${styles.questionnaire_ottr} container`}>
        <div className='row justify-content-center h-100'>
          {/* <div className='col h-100'> */}
          <div className={`container h-100`}>
            <div className={`row h-100 ${styles.cont_row}`}>
              <div className='col-sm-2'></div>
              <div className={isMobile ? '' : 'col-sm-8'}>
                {view === 'loading' && <LoadingScreen />}
                {view === 'loaded' && (
                  <SignUp
                    onSuccess={createAndRedirect}
                    params={{
                      keap_force_sync: true,
                      origin: origin,
                      procedure: 'ReActiv8',
                      surgery: 'Mechanical Chronic Low Back Pain',
                    }}
                    headline={
                      <p style={{ color: '#129A76', fontWeight: '700', fontSize: '25px' }}>
                        Get matched with your mentor!
                      </p>
                    }
                    para={`Create your account and get your mentor match.`}
                    embedded={true}
                    alignLeft={true}
                    light={false}
                    captureFirstLastName
                    captureLocation={captureLocation}
                    australiaPhone={australiaPhone}
                    locationPlaceholder={locationPlaceholder}
                    countryRestriction={countryRestriction}
                    useFacebookConversionApi
                  />
                )}
              </div>
              <div className='col-sm-2'></div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
