import React, { useState } from 'react';
import { validateEmail, validateText, validatePhone } from '../../helpers';
import ErrorMessage from '../ui_kit/utilities/error_message';
import DatePickerWrapper from '../date_picker_wrapper';
import Todpicker from '../todpicker';

export default function ConfirmationForm({
  surgeons,
  answers,
  onSubmit,
  onBack,
  head = `Confirm your information below for your appointment with ${surgeons.name}`,
  para,
  cta = 'Submit appointment request',
  gtm,
  datePlaceholder,
  timePlaceholder,
}) {
  const [name, setName] = useState(answers.name || '');
  const [phone, setPhone] = useState(answers.phone || '');
  const [email, setEmail] = useState(answers.email || '');
  const [error, setError] = useState('');

  // Datepicker form functions
  const getNextWork = (d) => {
    d.setDate(d.getDate() + 1); // tomorrow
    if (d.getDay() == 0) d.setDate(d.getDate() + 1);
    else if (d.getDay() == 6) d.setDate(d.getDate() + 2);
    return d;
  };
  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const minDate = getNextWork(getNextWork(new Date()));
  const [apptDate, setApptDate] = useState(answers.apptDate || '');
  const [apptTimeOfDay, setApptTimeOfDay] = useState(answers.apptTimeOfDay || timePlaceholder);

  var checkedEmail = validateEmail(email);
  var checkedName = validateText(name);
  var checkedPhone = validatePhone(phone);
  var checkedApptDate = apptDate !== '' && apptDate !== null;
  var checkedApptTimeOfDay = apptTimeOfDay !== timePlaceholder;

  let disabled = 'disabled';

  if (checkedName && checkedEmail && checkedPhone && checkedApptDate && checkedApptTimeOfDay) {
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  const onClick = (event) => {
    if (checkedEmail === false) {
      setError('Please enter a valid email');
    } else if (checkedName == false) {
      setError('Please enter a name without special characters');
    } else if (checkedPhone == false) {
      setError('Please enter a valid phone number');
    } else {
      const newAnswer = { ...answers, name, phone, email, apptDate, apptTimeOfDay };
      onSubmit(newAnswer);
      setError('');
    }
  };

  const validate = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (checkedEmail === false || email == '') {
      if (email.indexOf('@') == '-1') {
        setEmail('');
      }
      setError('Please enter a valid email');
    } else if (checkedPhone == false || phone == '') {
      setError('Please enter a valid phone number');
    } else if (checkedName == false || name == '') {
      setError('Please enter a valid name');
    } else if (apptDate == null || apptDate == '') {
      setError('Please enter an appointment date');
    } else if (apptTimeOfDay == '') {
      setError('Please enter a time of day');
    } else {
      setError('');
    }
  };
  if (phone !== '') {
    checkedPhone = validatePhone(phone);
  }

  const todPickerOptions = [timePlaceholder, 'Morning (9:00-12:00pm)', 'Afternoon (1:00-5:00pm)'];

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-sm-2'></div>
        <div className='col-sm-8'>
          <h5 className='marg-bottom-30 text-left'>{head}</h5>
          {para && <p className='marg-bottom-30'>{para}</p>}
          <div className='weight-loss-surgeon-match-confirmation-form '>
            <div className='material-input light marg-center marg-bottom-30'>
              <input
                type='text'
                name='name'
                defaultValue={answers.name}
                onBlur={validate}
                onChange={(event) => setName(event.target.value)}
                required
              />
              <span className='highlight'></span>
              <span className='bar'></span>
              <label>Name</label>
            </div>
            <div className='material-input light marg-center marg-bottom-30'>
              <input
                type='tel'
                defaultValue={answers.phone}
                onChange={(event) => setPhone(event.target.value)}
                required
                name='phone'
                onBlur={validate}
              />
              <span className='highlight'></span>
              <span className='bar'></span>
              <label>Phone Number</label>
            </div>
            <div className='material-input light marg-center marg-bottom-30'>
              <input
                type='email'
                name='email'
                onBlur={validate}
                required
                defaultValue={answers.email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <span className='bar'></span>
              <label>Email</label>
            </div>
            <DatePickerWrapper
              selected={apptDate}
              onChange={(date) => setApptDate(date)}
              minDate={minDate}
              filterDate={isWeekday}
              wrapperClassName={'react-datepicker-wrapper material-input light marg-bottom-30 text-left'}
              placeholderText={datePlaceholder}
              onBlur={validate}
            />
            <Todpicker
              onBlur={validate}
              value={apptTimeOfDay}
              onChange={(event) => {
                setApptTimeOfDay(event.target.value);
              }}
              light={true}
              options={todPickerOptions}
            />
            {error && disabled != '' && <ErrorMessage error={error} />}
            <div className='container'>
              <div className='row'>
                <button
                  data-gtm-click-label={`${gtm} Confirmation Form Back`}
                  className='liner noline col-12 col-md-6 last-on-mobile mobile-full-wid marg-top-10-mob'
                  type='button'
                  onClick={onBack}
                >
                  Back
                </button>
                <button
                  data-gtm-click-label={`${gtm} Confirmation Form Submit`}
                  className='col-12 col-md-6 first-on-mobile mobile-full-wid '
                  disabled={disabled}
                  onClick={onClick}
                >
                  {cta}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-2'></div>
      </div>
    </div>
  );
}
