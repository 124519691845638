import React, { useState, useContext, useEffect } from 'react';
import Context from '../../../utils/context';
import styles from '../style.module.css';
import { Link } from 'react-router-dom';
import LoadingScreen from '../../loading_screen';
import MentorCard from '../../ui_kit/utilities/mentor_card';

export default function MentorSection({}) {
  const context = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [matchedPatientPartner, setMatchedPatientPartner] = useState();
  const isMobile = context.viewport.getIsMobile();

  useEffect(() => {
    context.auth.addMatchedPatientPartner((data) => {
      setMatchedPatientPartner(data);
      setIsLoading(false);
    });
  }, []);

  return matchedPatientPartner ? (
    <>
      <div className='container'>
        <div className='row marg-top-30'>
          <img
            alt='mentor-icon'
            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/relatability.svg'
          />
          <p className={`marg-bottom-10 col ${styles.subheader}`}>Chat with your mentor</p>
        </div>
        <div className='row marg-top-10'>
          <p className={styles.light_text}>
            Your mentor is there to answer any questions and support you on your healthcare journey.
          </p>
        </div>
      </div>
      <div className={`container ${styles.blue_container} marg-bottom-30`}>
        <ul className='row'>
          <img
            alt='chat-icon'
            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+16/messages.svg'
          />
          <p className={`col ${styles.blue_text}`}>Send them a message to get the conversation going.</p>
        </ul>
      </div>
      {isLoading && <LoadingScreen />}
      {!isLoading && matchedPatientPartner && <MentorCard mentor={matchedPatientPartner} />}
    </>
  ) : (
    <>
      <div className='container'>
        <div className='row marg-top-30'>
          <img
            alt='mentor-icon'
            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/relatability.svg'
          />
          <p className={`marg-bottom-10 col ${styles.subheader}`}>Find your mentor</p>
        </div>
        <div className='row marg-top-10'>
          <p className={styles.light_text}>
            Our community of mentors are here to help. Just answer a few simple questions and we'll do the rest.{' '}
          </p>
        </div>
      </div>
      <div className='container content_container marg-top-30'>
        {isMobile ? (
          <>
            <div className='row justify-content-start'>
              <img
                alt='mentor-avatars'
                className='col-9'
                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/mentor_avatars.jpg'
              />
              <div className='justify-content-start marg-top-10 '>
                <p className={styles.dark_text}>
                  Want to talk to someone who truly understands what you're going through? That's what our mentors are
                  for. Your mentor is there to answer any questions and support you on your journey.{' '}
                </p>
              </div>
            </div>
            <Link to='/partners'>
              <button className='col marg-top-10'>Find a mentor</button>
            </Link>
          </>
        ) : (
          <>
            <div className='row'>
              <div className='col-5 marg-top-30'>
                <img
                  alt='mentor-avatars'
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/mentor_avatars.jpg'
                />
              </div>
              <div className='col-7 marg-top-10'>
                <p className={styles.dark_text}>
                  Want to talk to someone who truly understands what you're going through? That's what our mentors are
                  for. Your mentor is there to answer any questions and support you on your journey.{' '}
                </p>
                <Link style={{ marginLeft: '15px' }} to='/partners'>
                  <button className='marg-top-10'>Find a mentor</button>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
