import React, { Component } from 'react';
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function InsuranceSelectionAus({ value, onSubmit, onBack }) {
  return (
    <OptionsAndOther
      answers={{ insuranceSelection: value }}
      keyForAnswer={'insuranceSelection'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={['Private', 'DVA - White', 'DVA - Gold', 'Worker’s Compensation', 'Not covered']}
      title={`What type of insurance do you have?`}
      para={``}
      showOther={false}
      ctaTxt='Next'
      selectOne
    />
  );
}
