import React, { useState } from 'react';
import ErrorMessage from '../ui_kit/utilities/error_message';
import { validateText } from '../../helpers';
import RequestButton from '../ui_kit/utilities/request_button';
export default function CommentBox({
  head = 'Is there anything else you want us to keep in mind as we search for your perfect doctor?',
  placeholder = "Let us know about doctors you've already worked with, treatments you've already tried, and specifics about your condition that you think would be helpful for us to know.",
  onSubmit,
  onBack,
}) {
  const [comments, setComments] = useState('');

  const onBackClick = (event) => {
    event.preventDefault();
    onBack();
  };

  const onClick = () => {
    onSubmit(comments);
  };

  let error = '';
  if (comments) {
    if (!validateText(comments, 6000)) {
      error = 'Please enter this information without special characters';
    }
  }

  return (
    <div className='row'>
      <div className='col-sm-2'></div>
      <div className='col-sm-8'>
        <form className='anythingElse'>
          <h5 className='mobile-marg-20 marg-bottom-30'>{head}</h5>
          <div style={{ border: '0px' }} className='text-box-wrap'>
            <textarea
              className='autofocus'
              placeholder={placeholder}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            ></textarea>
          </div>
          {error && <ErrorMessage error={error} />}
        </form>
        <div className='row button-holder'>
          <button className='back col-6' type='button' onClick={onBackClick}>
            Back
          </button>
          <RequestButton request={onClick} classnames={'gtm-preexisting-conditions submit col-6'} cta={`Next`} />
        </div>
      </div>
      <div className='col-sm-2'></div>
    </div>
  );
}
