import React, { useContext } from 'react';
import ActionCable from 'actioncable';
import { ActionCableProvider, ActionCableConsumer } from 'react-actioncable-provider';
import Context from '../../utils/context';

export default function GlobalPresence() {
  const cable = ActionCable.createConsumer(gon.action_cable_url);
  const context = useContext(Context);
  const profileStatus = context.profileStatus;

  const onReceived = (data) => {
    if (data.profile_type && data.profile_id) {
      profileStatus.updateProfile({
        type: data.profile_type,
        id: data.profile_id,
        last_active_display: 'now',
      });
    }
  };

  return (
    <ActionCableProvider cable={cable}>
      <ActionCableConsumer channel={{ channel: 'GlobalPresence' }} onReceived={onReceived} />
    </ActionCableProvider>
  );
}
