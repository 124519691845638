import React, { useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Context from '../../utils/context';
import './process_header.css';

export default function ProcessHeader({
  para1 = 'How it Works',
  headline = 'This is a big headline',
  para2 = 'Have questions, comments, or suggestions? We’d love to know. Fill out the form below and we’ll get back to you within 24 hours.',
  bgColor = '#F1FEFF',
  wrapperPadding,
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  return (
    <div
      style={{
        backgroundColor: bgColor,
        paddingTop: `${isMobile ? '' : wrapperPadding}`,
        paddingBottom: wrapperPadding,
      }}
      className={`container-fluid marg-bottom-0`}
    >
      <div className='container'>
        <div className='semibg'></div>
        <div className='row justify-content-center h-100'>
          <p className='text-center top-header'>{para1}</p>
          <h1 className='headline'>{headline}</h1>
          <p className='marg-bottom-100'>{para2}</p>
        </div>
      </div>
    </div>
  );
}
