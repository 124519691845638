import { connect } from 'react-redux';
import { updateAnswersAction } from '../../actions/home';
import WeightLossWebinar from '../../components/weight_loss_webinar';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WeightLossWebinar);
