const initialState = {
  contact: gon.contact,
  surgeon: gon.surgeon,
};

const surgeonMatchJourneyFeedback = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_JOURNEY_FEEDBACK_ANSWERS':
      return {
        ...state,
        journeyFeedbackAnswers: action.item,
      };
    default:
      return state;
  }
};

export default surgeonMatchJourneyFeedback;
