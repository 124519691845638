import { connect } from 'react-redux';
import { updateAnswersAction } from '../../../actions/home';
import June292021 from '../../../components/webinars/june_29_2021';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(June292021);
