import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './index.css';

export default function AgreeToTerms({ acceptedTerms, setAcceptedTerms, parentClass }) {
  const [acceptedTermsInner, setAcceptedTermsInner] = useState(acceptedTerms);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(acceptedTerms);

  const onTermsClick = () => {
    setAcceptedTermsInner(!acceptedTermsInner);
    setAcceptedTerms(!acceptedTermsInner && acceptedPrivacy);
  };

  const onPrivacyClick = () => {
    setAcceptedPrivacy(!acceptedPrivacy);
    setAcceptedTerms(acceptedTermsInner && !acceptedPrivacy);
  };

  return (
    <>
      <p className={`text-center question-privacy marg-top-10 marg-bottom-10 ${parentClass}`}>
        <label className='custom-checkbox'>
          <input type='checkbox' id='terms' name='terms' checked={acceptedTermsInner} onChange={onTermsClick} />
          <span className='marg-top-20'>
            I agree to the{' '}
            <Link to='/terms' className='terms-link'>
              Terms of Service
            </Link>
            .
          </span>
        </label>
      </p>
      <p className={`text-center question-privacy marg-top-10 marg-bottom-10 ${parentClass}`}>
        <label className='custom-checkbox'>
          <input type='checkbox' id='privacy' name='privacy' checked={acceptedPrivacy} onChange={onPrivacyClick} />
          <span className='marg-top-20'>
            I agree to the{' '}
            <Link to='/privacy' className='terms-link'>
              Privacy Policy and Authorization
            </Link>
            .
          </span>
        </label>
      </p>
    </>
  );
}
