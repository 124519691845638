import React, { Component, useState, useEffect } from 'react';
import styles from './video_type_2.module.css';
import FsLightbox from 'fslightbox-react';

export default function VideoType2({
  bg = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/videobg.png',
  videoLink = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/video/R5+-+Patient+Partner+Program+Value+-+D3+(1).mp4',
  gtm = 'Watch Video Video Type 2',
  margButton = true,
  height = '140px',
  borderRadius = '5px',
  iconSize = '100px',
  bgCover = true,
  autoplay = false,
}) {
  const [toggler, setToggler] = useState(false);
  const [type, setType] = useState('video');

  useEffect(() => {
    if (videoLink.includes('youtube')) {
      setType('youtube');
    }
  }, [videoLink]);

  if (autoplay)
    return (
      <>
        <div
          data-gtm-click-label={gtm}
          style={{
            backgroundImage: 'url(' + bg + ')',
            backgroundSize: 'cover',
            height: height,
            borderRadius: borderRadius,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            overflow: 'hidden',
          }}
          className={`${styles.video_sec} container-fluid video-type-1 video-sec-wrap`}
        >
          <div style={{ borderRadius: '5px', overflow: 'hidden' }} className='video-cover'></div>
          <div style={{ borderRadius: '5px', overflow: 'hidden' }} className='video-bg'>
            <video autoPlay='autoplay' muted loop playsInline>
              <source src={videoLink} type='video/mp4' />
            </video>
          </div>
          <div style={{ width: 'max-content' }} className='container content'>
            <div className='row'>
              <div className='col'>
                <svg
                  style={{ margin: '0px auto', height: iconSize, width: iconSize }}
                  data-gtm-click-label={gtm}
                  onClick={() => setToggler(!toggler)}
                  className='watchbutton'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 101.311 101.311'
                >
                  <g id='Icon_feather-play-circle' data-name='Icon feather-play-circle' transform='translate(-1 -1)'>
                    <path
                      data-gtm-click-label={gtm}
                      id='Path_113'
                      data-name='Path 113'
                      d='M100.311,51.656A48.656,48.656,0,1,1,51.656,3a48.656,48.656,0,0,1,48.656,48.656Z'
                      transform='translate(0 0)'
                      fill='none'
                      stroke='#fff'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='4'
                    />
                    <path
                      data-gtm-click-label={gtm}
                      id='Path_114'
                      data-name='Path 114'
                      d='M15,12,44.193,31.462,15,50.925Z'
                      transform='translate(26.925 20.193)'
                      fill='none'
                      stroke='#fff'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='4'
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <FsLightbox toggler={toggler} sources={[`${videoLink}`]} type={type} />
      </>
    );

  return (
    <>
      <div
        data-gtm-click-label={gtm}
        style={{
          backgroundImage: `url('${bg}')`,
          backgroundSize: bgCover ? 'cover' : 'contain',
          height: height,
          borderRadius: borderRadius,
        }}
        className={`${styles.video_sec} container video-type-2`}
      >
        <div className='row h-100'>
          <div className='col h-100' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <svg
              style={{ height: iconSize, width: iconSize }}
              data-gtm-click-label={gtm}
              onClick={() => setToggler(!toggler)}
              className={`${styles.watchbutton} ${margButton ? styles.marg_me : styles.normal_marg}`}
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 101.311 101.311'
            >
              <g id='Icon_feather-play-circle' data-name='Icon feather-play-circle' transform='translate(-1 -1)'>
                <path
                  data-gtm-click-label={gtm}
                  id='Path_113'
                  data-name='Path 113'
                  d='M100.311,51.656A48.656,48.656,0,1,1,51.656,3a48.656,48.656,0,0,1,48.656,48.656Z'
                  transform='translate(0 0)'
                  fill='none'
                  stroke='#fff'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='4'
                />
                <path
                  data-gtm-click-label={gtm}
                  id='Path_114'
                  data-name='Path 114'
                  d='M15,12,44.193,31.462,15,50.925Z'
                  transform='translate(26.925 20.193)'
                  fill='none'
                  stroke='#fff'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='4'
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
      <FsLightbox toggler={toggler} sources={[`${videoLink}`]} type={type} />
    </>
  );
}
