import React, { useContext, useState, useEffect } from 'react';
import Context from '../../utils/context';
import ClickToCall from './icons/click_to_call';
import ClickToRequestCall from './icons/click_to_request_call';
import { ActionCableConsumer } from 'react-actioncable-provider';
import ReactTooltip from 'react-tooltip';
import { getBrowserCall } from '../../api/browser_calls';
import styles from './styles.module.css';

export default function ActionIcons({ conversation, style }) {
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();
  if (user.current_profile_type !== 'Patient' && user.current_profile_type !== 'PatientPartner') {
    return <span></span>;
  }

  const [callFeedbackModal, setCallFeedbackModal] = useState(false);
  const [browserCall, setBrowserCall] = useState(null);

  const updateRoom = (data) => {
    switch (data.type) {
      case 'call_feedback':
        setBrowserCall(data.browser_call);
        setCallFeedbackModal(true);
        break;
      default:
        return;
    }
  };

  const callComplete = () => {
    setCallFeedbackModal(false);
    setBrowserCall(null);
  };

  useEffect(() => {
    if (
      new URLSearchParams(window.location.search).get('showFeedback') &&
      new URLSearchParams(window.location.search).get('id') !== null
    ) {
      getBrowserCall(new URLSearchParams(window.location.search).get('id'), (data) => {
        setBrowserCall(data);
        setCallFeedbackModal(true);
      });
    }
  }, []);

  useEffect(() => {
    if (conversation.need_feedback_on_last_conversation) {
      setBrowserCall(conversation.need_feedback_on_last_conversation);
      setCallFeedbackModal(true);
    }
  }, []);

  return (
    <>
      <ActionCableConsumer
        channel={{ channel: 'ConversationsChannel', ids: [conversation.uuid] }}
        onReceived={(data) => {
          updateRoom(data);
        }}
      />
      <div style={style}>
        {user.current_profile.type === 'PatientPartner' && (
          <>
            <a href='/report-an-event' target='_blank'>
              <svg width='24' height='24' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M9.95383 6.60504V10.5493' stroke='#173959' strokeLinecap='round'></path>
                <path d='M9.95383 13.3102V13.7047' stroke='#173959' strokeLinecap='round'></path>
                <path
                  d='M1.59487 14.5062L8.5419 1.91295C9.15206 0.806197 10.7583 0.806197 11.3676 1.91295L18.3138 14.5062C18.8966 15.5664 18.1212 16.8601 16.8992 16.8601H3.00773C1.78656 16.8601 1.01039 15.5664 1.59573 14.5062H1.59487Z'
                  stroke='#173959'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                ></path>
              </svg>
            </a>
          </>
        )}
        {user.current_profile.type === 'Patient' && user.current_profile.procedures.includes('ReActiv8') && (
          <ClickToRequestCall
            conversation={conversation}
            feedbackModal={callFeedbackModal}
            browserCall={browserCall}
            callComplete={callComplete}
          />
        )}
        {user.current_profile.type === 'PatientPartner' && <ClickToCall conversation={conversation} />}
      </div>
    </>
  );
}
