import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ClickToSee from '../ui_kit/utilities/click_to_see';
import SimpleAvatar from '../ui_kit/utilities/simple_avatar';
import Context from '../../utils/context';
import { isObjEmpty } from '../../helpers';
import { getNotifications, markEverythingAsRead, updateNotification } from '../../api/notifications';
import styles from './index.module.css';
import NoNotifications from '../header/no_notifications';
import Divider from '../ui_kit/utilities/divider';
import ScrollTo from '../scroll_to';

export default function Notifications() {
  const context = useContext(Context);
  const actioncable = context.actioncable;

  const currentUser = context.auth.getCurrentUser();
  if (isObjEmpty(currentUser.current_profile)) {
    return <></>;
  }

  if (!currentUser.profile_id) {
    return <></>;
  }

  const [unreadNotificationCount, setUnreadNotificationCount] = useState(
    context.auth.isLoggedIn() && context.auth.getCurrentUser().current_profile.unread_notification_count
  );

  const onReceived = (data) => {
    if (data.type === 'unread_notification_count') {
      context.auth.setUnreadNotificationCount(data.value);
      setUnreadNotificationCount(data.value);
    }
  };

  useEffect(() => {
    loadNotifications();

    let subscriptionId = null;
    if (context.auth.isLoggedIn()) {
      subscriptionId = actioncable.subscribe(
        'ProfileChannel',
        { id: context.auth.getCurrentUser().profile_id },
        onReceived
      );
    }

    return () => {
      if (subscriptionId) {
        actioncable.unsubscribe(subscriptionId);
      }
    };
  }, []);

  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const loadNotifications = () => {
    setLoadingNotifications(true);
    getNotifications((data) => {
      setNotifications(data);
      setLoadingNotifications(false);
    });
  };

  const markEverythingAsReadClick = () => {
    markEverythingAsRead();
    const newNotifications = notifications.map((notification) => {
      return {
        ...notification,
        read: true,
      };
    });

    setNotifications(newNotifications);
  };

  const notificationOnClick = (notification) => () => {
    const newNotifications = notifications.map((n) => {
      if (n.id === notification.id) {
        return {
          ...n,
          read: true,
        };
      }
      return n;
    });
    updateNotification(notification.id, { read: true });
    setNotifications(newNotifications);
  };

  if (!context.auth.isLoggedIn()) {
    return <></>;
  }

  let scrollto = new URLSearchParams(window.location.search).get('scrollto');
  if (document.querySelector('#scrollto') && scrollto && scrollto.toString() === 'true') {
    ScrollTo('#scrollto');
  }

  const renderNotification = (notification, i) => {
    return (
      <div
        key={`notification-${notification.id}`}
        className={`${styles.notification_container} ${notification.read ? '' : styles.notification_unread} container`}
      >
        <div className={`row`}>
          <div className={`${styles.notification_avatar_container} col-2`}>
            <SimpleAvatar profile={notification.action_profile} />
          </div>
          <div onClick={notificationOnClick(notification)} className={`${styles.notification_text_container} col-10`}>
            <Link to={notification.action_link}>
              {notification.num_other_distinct_users >= 1 ? (
                <p>
                  <b>{notification.action_profile.display_name} </b>
                  and {notification.num_other_distinct_users}{' '}
                  {notification.num_other_distinct_users == 1 ? <>other </> : <> others </>}
                  {notification.action_phrase} <b>"{notification.gist}"</b>
                </p>
              ) : (
                <p>
                  <b>{notification.action_profile.display_name} </b>
                  {notification.action_phrase} <b>"{notification.gist}"</b>
                </p>
              )}
              <div className={styles.notification_timestamp}>{notification.last_notified_at}</div>
            </Link>
          </div>
        </div>
        {i !== notifications.length - 1 && <Divider />}
      </div>
    );
  };

  return (
    <div id='scrollto' style={{ position: 'relative' }}>
      <h2 className={styles.profile_section_headline}>
        Activity {unreadNotificationCount > 0 && <span className={styles.notif_bub}>{unreadNotificationCount}</span>}
      </h2>
      <div className='content_container'>
        {loadingNotifications && <div>Loading</div>}
        {!loadingNotifications && (
          <>
            {notifications.length === 0 ? (
              <div style={{ textAlign: 'center' }}>
                <NoNotifications headerTitle={'Notifications'} />
              </div>
            ) : (
              notifications.map(renderNotification)
            )}
          </>
        )}
      </div>
    </div>
  );
}
