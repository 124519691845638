import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import './index.css';

export default function GetStartedV2({
  weightLossSurgeonMatch,
  weightLoss,
  setPatientPartnerQuestionnaireStateAction,
  setSurgeonMatchQuestionnaireStateAction,
  updatePatientPartnerAnswersAction,
  updateSurgeonMatchAnswersAction,
  clearGlobalContactInfoAction,
}) {
  let history = useHistory();

  const onClick = (value) => () => {
    event.preventDefault();
    if (value === 'talk-to-patient-partner') {
      history.push('/get-started/patient-partner-match');
    } else if (value === 'find-a-surgeon') {
      history.push('/get-started/surgeon-match');
    }
  };
  return (
    <div
      className='get-started questionnaire-wrapper'
      style={{ minHeight: '200px', marginTop: '20px', paddingBottom: '100px' }}
    >
      <div id='questionnaire' className='marg-bottom-20'>
        <div className='row justify-content-center h-100'>
          <div className='col my-auto'>
            <div className='row introQuestion'>
              <div className='col-sm-2'></div>
              <div className='col-sm-8'>
                <div className='row'>
                  <div className='col-sm-8'>
                    <h2 style={{ fontWeight: '500' }} className='mobile-marg-20 marg-top-100 text-left'>
                      We're here to help you
                    </h2>
                    <p className='question-sub-headline marg-bottom-10'>
                      Wherever you are in your healthcare journey, we’re here guide you.
                    </p>
                  </div>
                </div>
                <h5 className='text-left question'>Where would you like to start?</h5>
                <div className='choices get-started-choices'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <button
                        value='talk-to-patient-partner'
                        onClick={onClick('talk-to-patient-partner')}
                        data-gtm-click-label='Get Started Find a Patient Partner'
                      >
                        <div className='row'>
                          <div className='col-4 my-auto'>
                            <div className='icon'>
                              <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/stepone1.jpg' />
                            </div>
                          </div>
                          <div className='col-8 my-auto'>
                            <p className='text-left' data-gtm-click-label='Get Started Find a Patient Partner'>
                              <b>Find a recovered patient</b> to talk to about their experience.
                            </p>
                          </div>
                        </div>
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        value='find-a-surgeon'
                        onClick={onClick('find-a-surgeon')}
                        data-gtm-click-label='Get Started Find a Surgeon'
                      >
                        <div className='row'>
                          <div className='col-4 my-auto'>
                            <div className='icon'>
                              <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/process1.jpg' />
                            </div>
                          </div>
                          <div className='col-8 my-auto'>
                            <p className='text-left' data-gtm-click-label='Get Started Find a Surgeon'>
                              <b>Find a surgeon</b> based on my insurance coverage.
                            </p>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <p className='marg-top-40 text-left contact-cta'>
                    Not sure where to start?{' '}
                    <Link to='/contact-us'>
                      <p data-gtm-click-label='Get Started Contact Us' className='text-link no-arrow'>
                        Contact us!
                      </p>
                    </Link>
                  </p>
                </div>
              </div>
              <div className='col-sm-2'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
