import React, { useState } from 'react';
import InputWithOptions from '../../ui_kit/utilities/input_with_options';
import RequestButton from '../../ui_kit/utilities/request_button';

export default function WhatSurgery({
  selected,
  onSubmit,
  onBack,
  choices,
  noMatchTxt = "I don't see what I'm looking for",
  label = 'Type surgery here...',
  head = 'What surgery are you considering?',
  para = 'We’ll match you with someone who has had the same surgery.',
  gtm,
}) {
  const [surgery, setSurgery] = useState((selected && selected[0]) || '');

  const onClick = () => {
    onSubmit([surgery]);
  };
  const onBackClick = () => {
    onBack();
  };

  const disabled = surgery !== '' ? '' : 'disabled';

  return (
    <div className='row'>
      <div className='col-md-2'></div>
      <div className='col-md-8'>
        <h5 className='mobile-marg-20 marg-bottom-10'>{head}</h5>
        <p className='question-sub-headline marg-bottom-30'>{para}</p>
        <InputWithOptions
          value={surgery}
          setValue={setSurgery}
          options={choices}
          name={''}
          label={label}
          noMatchTxt={noMatchTxt}
          showOptionsFromStart
          fullWidth
        />
        <div className='row button-holder'>
          <button
            className='back col-6'
            type='button'
            onClick={onBackClick}
            data-gtm-click-label={`${gtm} What Surgery Back`}
          >
            Back
          </button>
          <RequestButton
            request={onClick}
            disabled={disabled}
            classnames={'gtm-preexisting-conditions submit col-6'}
            cta={`Next`}
            gtm={`${gtm} What Surgery Submit`}
          />
        </div>
      </div>
      <div className='col-md-2'></div>
    </div>
  );
}
