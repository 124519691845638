import React, { useState } from 'react';

export default function YesNoExtra({
  onBack,
  onSubmit,
  title,
  para,
  keyForAnswer,
  yesState,
  answers,
  choices = ['Yes', 'No'],
  gtm,
  extraIndex = 0,
  otherPlaceholder = 'Share your follow up questions here... (Optional)',
  ctaText = 'Next',
}) {
  const [selected, setSelected] = useState(answers[keyForAnswer] || '');
  const [other, setOther] = useState(answers.other || '');

  const submit = (e) => {
    e.preventDefault();
    let newSelected = { [keyForAnswer]: selected };
    if (other !== '') {
      newSelected.other = other;
    }
    onSubmit(newSelected, yesState);
  };

  const onClick = (choice) => {
    if (selected === choice) {
      setSelected('');
      if (selected === choices[extraIndex]) {
        setOther('');
      }
    } else {
      setSelected(choice);
    }
  };

  return (
    <form id='plans'>
      <h5 className='question'>{title}</h5>
      <p className='question'>{para}</p>
      {selected !== '' && selected === choices[extraIndex]
        ? [choices[extraIndex]].map((choice) => (
            <>
              <div key={choice} className='tile two-column'>
                <label key={choice} className={`preexisting ${selected.includes(choice) ? 'activeCheckbox' : ''}`}>
                  <input onClick={() => onClick(choice)} type='checkbox' name={choice} value='value' />
                  {choice}
                </label>
              </div>
              {selected === choices[extraIndex] && choice === selected && (
                <div className='marg-top-0 marg-bottom-20 light marg-center w-100'>
                  <textarea
                    className={`material-input light w-100 marg-top-0`}
                    style={{ backgroundColor: '#fff !important', width: '100%' }}
                    placeholder={otherPlaceholder}
                    type='text'
                    name='other'
                    value={other}
                    onChange={(event) => setOther(event.target.value)}
                  />
                  <span className='bar'></span>
                </div>
              )}
            </>
          ))
        : choices.map((choice) => (
            <div key={choice} className='tile two-column'>
              <label key={choice} className={`preexisting ${selected.includes(choice) ? 'activeCheckbox' : ''}`}>
                <input onClick={() => onClick(choice)} type='checkbox' name={choice} value='value' />
                {choice}
              </label>
            </div>
          ))}
      <div className='container-fluid de-pad'>
        <div className='row button-holder de-pad'>
          <button
            className='back col-lg-6 col'
            type='button'
            onClick={() => onBack()}
            data-gtm-click-label={`${gtm} - Back`}
          >
            Back
          </button>
          <button
            disabled={`${selected.length === 0 ? 'disabled' : ''}`}
            className={`gtm-preexisting-conditions submit col-lg-6 col`}
            type='submit'
            onClick={(e) => submit(e)}
            data-gtm-click-label={`${gtm} - Next`}
          >
            {ctaText}
          </button>
        </div>
      </div>
    </form>
  );
}
