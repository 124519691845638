import React, { useContext } from 'react';
import Context from '../../../utils/context';
import './index.css';

export default function HowItWorks({
  mainHeadline = 'How it works',
  headline,
  para = '',
  steps,
  headlineStyle,
  smallHeadlineStyle,
  backgroundColor,
  stepHeadlineStyle,
}) {
  const isMobile = useContext(Context).viewport.getIsMobile();

  return (
    <div className='container-fluid' style={{ backgroundColor }}>
      <div className='container how-it-works'>
        <div className='row text-center'>
          <div className='col'>
            <h2 style={smallHeadlineStyle} className='small-header'>
              {mainHeadline}
            </h2>
          </div>
        </div>
        <div className='row text-center marg-bottom-20'>
          <div style={headlineStyle} className='col'>
            {headline}
          </div>
        </div>
        <div className='row justify-content-center marg-bottom-20'>
          <p className='col-6' style={{ textAlign: 'center' }}>
            {para}
          </p>
        </div>
        <div style={{ justifyContent: 'center' }} className={`row ${isMobile ? 'text-left' : 'text-center'}`}>
          {steps.map((step, index) => (
            <div key={`how-it-works-step-${index}`} className='how-it-works-step col-md-4'>
              <div className='how-it-works-step-image'>{step.image}</div>
              <div style={stepHeadlineStyle} className='step-headline'>
                <h3>{step.headline}</h3>
              </div>
              <div className='step-para'>
                <span>{step.para}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
