import { combineReducers } from 'redux';
import home from './home';
import weightLoss from './weight_loss';
import weightLossSurgeonMatch from './weight_loss_surgeon_match';
import partners from './partners';
import getStarted from './get_started';
import surgeonPartners from './surgeon_partners';
import insuranceCheckForm from './insurance_check_form';
import SurgeonMatchForm from './surgeon_match_form';
import getStartedV2 from './get_started_v2';
import surgeonMatchJourneyFeedback from './surgeon_match_journey_feedback';

export default combineReducers({
  home,
  weightLoss,
  weightLossSurgeonMatch,
  partners,
  getStarted,
  surgeonPartners,
  insuranceCheckForm,
  SurgeonMatchForm,
  getStartedV2,
  surgeonMatchJourneyFeedback,
});
