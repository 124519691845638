import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './for_mentors.module.css';
import AgreeToTerms from '../ui_kit/utilities/agree_to_terms';
import { createMentorApplicantContacts } from '../../api/mentor_applicant_contacts';
import VideoType2 from '../video_sections/video_type_2';
import FAQs from '../ui_kit/utilities/faqs';
import ScrollTo from '../scroll_to';
import { validatePhone, validateEmail, validateText, validateZipcode } from '../../helpers';
import ErrorMessage from '../ui_kit/utilities/error_message';
import SuccessPanel from '../success_panel';
import LoadingScreen from '../loading_screen';
import ImgRightTextLeft from '../hero_sections/img_right_text_left';
import TwoTestimonials from '../two_testimonials';
import HowItWorks from '../hero_sections/how_it_works';

export default function ForMentors({}) {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [procedures, setProcedures] = useState('');
  const [acceptedTerms, setTerms] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [zipError, setZipError] = useState('');

  var checkedName = validateText(fullName);
  var checkedEmail = validateEmail(email);
  var checkedPhone = validatePhone(phone);
  var checkedZipcode = validateZipcode(zipcode);

  const setAcceptedTerms = () => {
    setTerms(!acceptedTerms);
    validate();
  };

  const validate = () => {
    if (checkedName == false && fullName !== '') {
      setNameError('Please enter a name without special characters');
    } else {
      setNameError('');
    }
    if (checkedEmail === false && email !== '') {
      setEmailError('Please enter a valid email');
    } else {
      setEmailError('');
    }
    if (checkedPhone == false && phone !== '') {
      setPhoneError('Please enter a valid phone number');
    } else {
      setPhoneError('');
    }
    if (checkedZipcode == false && zipcode !== '') {
      setZipError('Please enter a valid zipcode');
    } else {
      setZipError('');
    }
  };

  const onSubmit = () => {
    setLoading(true);
    createMentorApplicantContacts(fullName, email, phone, zipcode, procedures, (response) => {
      setLoading(false);
      if (response === 200) {
        setSuccess(true);
      } else {
        setError('There was an error, please try again');
      }
    });
  };

  return (
    <div>
      <ImgRightTextLeft
        headline='Make a difference by sharing your experience'
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/for-mentors-header-hero.jpg'
        para='Your patient story provides valuable insight to someone else considering the procedure you experienced. Apply to become a mentor.'
        actionContent={
          <a href='https://form.typeform.com/to/elKIteiM?typeform-source=wc07scd3jfo.typeform.com'>
            <button data-gtm-click-label='Become a mentor'>Become a mentor</button>
          </a>
        }
      />
      <div className='container'>
        <div className='row'>
          <div className='col-lg-5'>
            <img
              alt='two-friends'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/for-mentors-center-hero.jpg'
            />
          </div>
          <div className='col-lg-7'>
            <div className='container'>
              <div className='col'>
                <div style={{ padding: '0px 30px 50px 30px' }}>
                  <div className='row'>
                    <div className='col'>
                      <h2 className={styles.headingicons}>
                        A{' '}
                        <span
                          style={{
                            color: '#59B6BE',
                            fontSize: 'inherit',
                            fontWeight: 'inherit',
                            fontFamily: 'inherit',
                            lineHeight: '100%',
                          }}
                        >
                          low-commitment
                        </span>{' '}
                        way to get involved.
                      </h2>
                    </div>
                  </div>
                  <div className='row h-100 marg-top-30'>
                    <div className='col-md-3 my-auto'>
                      <img
                        className={styles.icon}
                        src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/peopleclose.svg'
                      />
                    </div>
                    <div className='col-md-9'>
                      <h6 className={`${styles.heading6} ${styles.iconheads}`}>BE PART OF A COMMUNITY</h6>
                      <p className='marg-top-5'>
                        Join a community that empowers others to make informed healthcare decisions.{' '}
                      </p>
                    </div>
                  </div>
                  <div className='row h-100 marg-top-30'>
                    <div className='col-md-3 my-auto'>
                      <img
                        className={styles.icon}
                        src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/matchicon.svg'
                      />
                    </div>
                    <div className='col-md-9'>
                      <h6 className={`${styles.heading6} ${styles.iconheads}`}>OFFER SUPPORT</h6>
                      <p className='marg-top-5'>Offer support to someone going through a similar healthcare journey.</p>
                    </div>
                  </div>
                  <div className='row h-100 marg-top-30'>
                    <div className='col-md-3 my-auto'>
                      <img
                        className={styles.icon}
                        src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/moneyicon.svg'
                      />
                    </div>
                    <div className='col-md-9'>
                      <h6 className={`${styles.heading6} ${styles.iconheads}`}>RAISE MONEY</h6>
                      <p className='marg-top-5'>
                        For every connection you have, we donate to your favorite charity through our{' '}
                        <Link className={styles.link} to='/who-we-are'>
                          Circle of Giving
                        </Link>{' '}
                        as a thank you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TwoTestimonials
        headline='What mentors are saying'
        testimonials={[
          {
            text: 'I want to be there for someone else. If there’s just one question that I can answer for someone that helps them along the way - that’s it. That’s all I want to do.',
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/frances.jpg',
            name: 'Frances',
            city: 'San Bernadino, CA',
          },
          {
            text: 'A community like PatientPartner is so important because sometimes you just don’t know where to go. You need that community to lean on and trust. And I want to help others.',
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/charles_avatar.jpg',
            name: 'Charles',
            city: 'San Diego, CA',
          },
        ]}
      />
      <HowItWorks
        headline={
          <h2>
            Becoming a mentor is <span className='how-it-works headline highlight'>easy</span>!
          </h2>
        }
        para='We’re accepting volunteers for new PatientPartner Mentors with any conditions. It’s easy to get started.'
        headlineStyle={{ fontSize: '40px', fontWeight: '400', color: '#314D69', textAlign: 'center' }}
        smallHeadlineStyle={{
          fontFamily: 'HK Grotesk',
          lineHeight: '23px',
          fontSize: '18px',
          color: '#59B6BE',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          letterSpacing: '0.15em',
        }}
        steps={[
          {
            image: <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/about.svg' />,
            headline: 'Fill out a questionnaire',
            para: 'Provide basic information about yourself and why you want to be a PatientPartner Mentor.',
          },
          {
            image: <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/finasurgeon.svg' />,
            headline: 'Join and help patients',
            para: 'Log into your PatientPartner profile and start chatting with other patients on the platform.',
          },
          {
            image: <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/request.svg' />,
            headline: 'Donate to charities',
            para: 'Connect and help out another patient and we’ll donate $20 to a charity of your choice!',
          },
        ]}
      />
      <div className='row justify-content-center marg-top-40'>
        <a href='https://form.typeform.com/to/elKIteiM?typeform-source=wc07scd3jfo.typeform.com'>
          <button data-gtm-click-label='Become a mentor'>Become a mentor</button>
        </a>
      </div>
      <div
        style={{ paddingBottom: '100px', backgroundColor: '#EFEFEF', overflow: 'hidden' }}
        className='container-fluid marg-top-100'
      >
        <div className='row'>
          <div className='col-3 col-lg-2'></div>
          <div className='col-6 col-lg-8'>
            <h2 style={{ color: '#314D69' }} className={styles.vidheadline}>
              Hear about the impact mentors make:
            </h2>
            <div style={{ position: 'relative' }}>
              <svg
                className={styles.svg1}
                width='57'
                height='113'
                viewBox='0 0 57 113'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M56.5 113C41.5153 113 27.1443 107.047 16.5485 96.4515C5.95266 85.8557 1.98376e-06 71.4847 6.73756e-07 56.5C-6.36251e-07 41.5153 5.95266 27.1443 16.5485 16.5485C27.1443 5.95267 41.5153 3.11508e-06 56.5 6.73757e-07L56.5 56.5L56.5 113Z'
                  fill='#314D69'
                />
              </svg>
              <VideoType2
                bg='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/videomentorbg.jpg'
                videoLink='https://www.youtube.com/embed/t7GDNbUK-_I?si=ms4xiuZNT1aKmw8z'
                gtm='For Mentors Video Watch'
                height='390px'
              />
              <svg
                className={styles.svg2}
                width='259'
                height='208'
                viewBox='0 0 259 208'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M239.812 0.326238C248.879 16.0304 254.764 33.3667 257.131 51.3452C259.498 69.3237 258.3 87.5923 253.607 105.108C248.914 122.624 240.816 139.044 229.777 153.43C218.738 167.816 204.974 179.888 189.269 188.954C173.565 198.021 156.229 203.906 138.251 206.273C120.272 208.64 102.003 207.442 84.4876 202.749C66.9719 198.056 50.5521 189.958 36.1657 178.919C21.7793 167.88 9.70813 154.116 0.641313 138.412L120.227 69.3689L239.812 0.326238Z'
                  fill='#59B6BE'
                />
              </svg>
              <img
                className={styles.svg3}
                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/svgdots.svg'
              />
            </div>
          </div>
          <div className='col-3 col-lg-2'></div>
        </div>
      </div>
      <div style={{ paddingBottom: '50px' }} className='container'>
        <div className='row'>
          <div className='col'>
            <FAQs
              faqs={[
                {
                  headline: 'What is PatientPartner?',
                  para: 'PatientPartner is a patient-connection platform that matches new patients considering a medical procedure with a relatable patient who has previously experienced the same procedure (a PatientPartner Mentor). This personalized approach helps educate and empower new patients with the information and support they need to make more informed healthcare decisions.',
                },
                {
                  headline: 'How much time is required to be a PatientPartner Mentor?',
                  para: 'As much time as you are willing to dedicate! As a PatientPartner Mentor, you have the freedom to accept or pass any connection request. The commitment for each accepted connection is simply messaging back and forth on our platform or a phone call through our platform. On average, current mentors say they spend between 1-3 hours a week providing support to patients.',
                },
                {
                  headline: 'How do PatientPartner Mentors communicate with patients?',
                  para: 'All communication happens within your PatientPartner account where you can text message and have a phone call. Your personal contact details are never shared.',
                },
                {
                  headline: 'Are there guidelines for what I can and cannot say to a patient?',
                  para: 'Our goal is to create a safe space where patients can have authentic connections and ask personal questions. While we do not provide scripts or strict guidelines, we ask that mentors focus on their personal experience and do not provide medical or insurance advice.',
                },
                {
                  headline: 'Who can I talk to if I have more questions about becoming a PatientPartner Mentor?',
                  para: 'You can contact the PatientPartner team directly here and we will get back to you within 24 hours. We look forward to discussing the opportunity with you further!',
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
