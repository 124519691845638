import React from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import './index.css';
import styles from './index.module.css';

export default function GetStartedV3() {
  let history = useHistory();
  let { path } = useRouteMatch();

  const onClick = (value) => {
    if (history.location.state) {
      history.push(`${path}/${value}/${history.location.state}`);
    } else {
      history.push(`${path}/${value}`);
    }
  };
  return (
    <div className={styles.questionnaire_ottr} style={{ backgroundColor: '#f5f7f9', maxWidth: '100%' }}>
      <div id='questionnaire' className='container'>
        <div className='row justify-content-center h-100'>
          <div className='col h-100'>
            <div className={`row ${styles.introQuestion}`}>
              <div className='col-sm-2'></div>
              <div className='col-sm-10'>
                <div className='row'>
                  <div className='col-sm-8'>
                    <h2 style={{ fontWeight: '500' }} className='mobile-marg-20 text-left marg-bottom-20'>
                      Let’s get started!
                    </h2>
                    <p className='question-sub-headline marg-bottom-10'>
                      Take a second to let us know how we can help and who you are. All of your information is private
                      and secure.
                    </p>
                  </div>
                </div>
                <h5 className='text-left question'>Where would you like to start?</h5>
                <div>
                  <div className='row h-100'>
                    <div className={`${styles.mobile_marg} col-md-6 h-100`}>
                      <button
                        className={`${styles.choice_button}`}
                        value='talk-to-patient-partner'
                        onClick={() => onClick('support')}
                        data-gtm-click-label='Member Join Support'
                      >
                        <div className='row'>
                          <div className='col-4 my-auto'>
                            <div className={styles.icon}>
                              <img
                                alt='get started patient partner'
                                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/stepone1.jpg'
                              />
                            </div>
                          </div>
                          <div className='col-8 my-auto'>
                            <p className='text-left' data-gtm-click-label='Member Join Support'>
                              <b>Join a community</b> of like-minded patients.
                            </p>
                          </div>
                        </div>
                      </button>
                    </div>
                    <div className='col-md-6 h-100'>
                      <button
                        className={`${styles.choice_button}`}
                        value='find-a-surgeon'
                        onClick={() => onClick('surgeon-match')}
                        data-gtm-click-label='Member Join Find a Surgeon'
                      >
                        <div className='row'>
                          <div className='col-4 my-auto'>
                            <div className={styles.icon}>
                              <img
                                alt='get started surgeon match'
                                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/process1.jpg'
                              />
                            </div>
                          </div>
                          <div className='col-8 my-auto'>
                            <p className='text-left' data-gtm-click-label='Member Join Find a Surgeon'>
                              <b>Find a surgeon</b> based on my insurance coverage.
                            </p>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <p className='marg-top-40 text-left contact-cta'>
                    Want to share your experience and help others?{' '}
                    <Link to='/for-mentors'>
                      <p data-gtm-click-label='Mentor Join' className='text-link no-arrow'>
                        Become a mentor!
                      </p>
                    </Link>
                  </p>
                </div>
              </div>
              <div className='col-sm-2'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
