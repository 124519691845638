import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { getArticles } from '../../api/articles';
import { monthDateYear } from '../../helpers/index';
import EmailSignupPanel from '../email_signup_panel';
import LoadingScreen from '../loading_screen';
import DropdownInput from '../ui_kit/utilities/dropdown_input';
import RequestButton from '../ui_kit/utilities/request_button';
import DOMPurify from '../../utils/purify.min.js';
import styles from './index.module.css';

export default function Blogs() {
  const [articles, setArticles] = useState([]);
  const [blogFilter, setBlogFilter] = useState(
    new URLSearchParams(window.location.search).get('filter') || 'all-posts'
  );
  const [loading, setLoading] = useState(false);
  let { path } = useRouteMatch();
  let offset = React.useRef(0);
  let history = useHistory();

  useEffect(() => {
    offset.current = 0;
    fetchArticles();
  }, [blogFilter]);

  const fetchArticles = (callback = () => {}, append = false) => {
    setLoading(true);
    getArticles({ offset: offset.current, tag: blogFilter }, (data) => {
      if (append) {
        setArticles([...articles, ...data]);
      } else {
        setArticles([...data]);
      }
      offset.current = offset.current + 5;
      setLoading(false);
      callback();
    });
  };

  const setFilter = (filter) => {
    let searchParams = new URLSearchParams(window.location.search);
    if (filter !== 'Procedures') {
      searchParams.set('filter', filter.toLowerCase().replace(' ', '-'));
      history.push('?' + searchParams.toString());
      setBlogFilter(filter.toLowerCase().replace(' ', '-'));
    } else if (filter === 'Procedures') {
      searchParams.set('filter', 'all-posts');
      history.push('?' + searchParams.toString());
      setBlogFilter('all-posts');
    }
  };

  return (
    <>
      <div style={{ backgroundColor: '#F1FEFF' }} className={'container-fluid marg-bottom-20'}>
        <div className='container marg-bottom-0'>
          <div className='row justify-content-center text-center h-100'>
            <div className='col marg-top-100 marg-bottom-100'>
              <h5 className={`${styles.sm_header} `}>Blog</h5>
              <h2 className={`${styles.h2} `}>PatientPartner™ Blog</h2>
              <p>The PatientPartner blog guides you through everything you need to know about surgery.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container' style={{ minHeight: '100vh' }}>
        <div className='row justify-content-center' style={{ marginBottom: '30px' }}>
          <div className='col-12 col-md-6'>
            <div className='container'>
              <div className='row'>
                <p
                  onClick={() => setFilter('all-posts')}
                  key={'All Posts'}
                  className={`${styles.filter} col-12 col-md-3`}
                >
                  All Posts
                </p>
                <p
                  onClick={() => setFilter('general-health-and-wellness')}
                  key={'General health & wellness'}
                  className={`${styles.filter} col-12 col-md-6`}
                >
                  General health &amp; wellness
                </p>
                <div className='col-12 col-md-3'>
                  <DropdownInput
                    wrapperClassname={styles.procedures_dropdown_wrapper}
                    elemClassname={styles.procedures_dropdown}
                    options={[
                      {
                        value: 'Procedures',
                      },
                      {
                        value: 'Joint Replacement',
                      },
                      {
                        value: 'Weight Loss',
                      },
                      {
                        value: 'Spine',
                      },
                      {
                        value: 'Cosmetic',
                      },
                    ]}
                    values={blogFilter
                      .replace('-', ' ')
                      .toLowerCase()
                      .split(' ')
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(' ')}
                    setValues={setFilter}
                    type='single'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-8'>
            {loading && <LoadingScreen />}
            {articles.length === 0 && !loading && (
              <>
                <img
                  alt='sad face'
                  className='marg-top-20 marg-bottom-20'
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/icon+22/Bitmap.svg'
                />
                <h3 className={styles.title}>No articles yet for that tag yet</h3>
              </>
            )}
            {articles.length > 0 &&
              articles.map((article, i) => (
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={`/blogs/${article.slug}`}
                  className={`${styles.read_more}`}
                  style={{ color: 'black' }}
                >
                  <div key={i} className={`${styles.post} container`}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div
                          className={`${styles.article_img} `}
                          style={{ backgroundImage: `url(${article.image_url})` }}
                        ></div>
                      </div>
                      <div className='col-md-6'>
                        <div className={`${styles.content} `}>
                          <h5 className={`${styles.title} `}>{article.title}</h5>
                          <div className={`${styles.meta_info_wrap}`}>
                            <div className={`${styles.published_at}`}>
                              <img
                                alt='date posted'
                                className={`${styles.meta_icon}`}
                                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/calendar.svg'
                              />
                              <p>{monthDateYear(article.published_at)}</p>
                            </div>
                            <div className={`${styles.pipe}`}> | </div>
                            <div className={`${styles.author_names}`}>
                              <img
                                alt='author'
                                className={`${styles.meta_icon}`}
                                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/person.svg'
                              />
                              <p>{article.author_names}</p>
                            </div>
                          </div>
                          <div
                            className={`${styles.teaser}`}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.teaser) }}
                          ></div>
                          <button className={`${styles.button} liner`}>Read More</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            {articles.length > 0 && (
              <RequestButton
                style={{ marginTop: '50px', marginBottom: '50px' }}
                request={(callback) => fetchArticles(callback, true)}
                cta='Load more blogs'
                pendoTag='blogs - load more posts'
                classnames={styles.load_more}
              />
            )}
          </div>
          <div className='col-lg-4'>
            <EmailSignupPanel type='DownloadGuideContacts::BlogLead' />
          </div>
        </div>
      </div>
    </>
  );
}
