export const createDirectSurgeonBookingContacts = (
  surgeon,
  name,
  email,
  phone,
  zipcode,
  insuranceCarrier,
  requestedApptDate,
  requestedApptTimeOfDate,
  extraInfo,
  origin,
  surgery,
  successFunction
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  fetch('/direct_surgeon_booking_contacts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({
      ...window.utmInfo,
      surgeon: surgeon.slug,
      name: name,
      email: email,
      phone: phone,
      zipcode: zipcode,
      insurance_carrier: insuranceCarrier,
      requested_appt_date: requestedApptDate,
      requested_appt_time_of_date: requestedApptTimeOfDate,
      extra_info: extraInfo,
      origin: origin,
      surgery: surgery,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successFunction(data);
    });
};

export const getDirectSurgeonBookingContact = (id, successCallback) => {
  fetch(`/api/v1/direct_surgeon_booking_contacts/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
