import React, { useState, useEffect, useContext } from 'react';
import { createRatings } from '../../../api/ratings';
import './index.css';

export default function ConnectionFeedback({ browserCall }) {
  const [view, setView] = useState('feedback');
  const [starRating, setStarRating] = useState();
  const [feedbackComment, setFeedbackComment] = useState();
  const submitFeedback = () => {
    createRatings(browserCall.uuid, starRating, feedbackComment, () => {
      setView('success');
    });
  };

  if (view === 'feedback') {
    return (
      <>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col'>
              <p style={{ textAlign: 'center', fontSize: '20px' }} className='marg-bottom-10'>
                <b>Call ended</b>
              </p>
            </div>
          </div>
        </div>
        <div className='container marg-top-20'>
          <div className='row justify-content-center'>
            <div className='col'>
              <p className='text-center' style={{ fontSize: '12px', color: 'rgb(136 136 136)' }}>
                Provide feedback on your
                <br />
                PatientPartner mentor connection!
              </p>
              <div className='feedback-star-holder'>
                {[...Array(5)].map((ele, i) => (
                  <>
                    {starRating >= i + 1 ? (
                      <svg
                        onClick={() => setStarRating(i + 1)}
                        className={`feedback-star`}
                        viewBox='0 0 30 28'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M28.1518 9.72402L19.7215 8.49883L15.9529 0.858789C15.85 0.649609 15.6807 0.480274 15.4715 0.377344C14.9469 0.118359 14.3094 0.33418 14.0471 0.858789L10.2785 8.49883L1.84826 9.72402C1.61584 9.75723 1.40334 9.8668 1.24064 10.0328C1.04395 10.235 0.935567 10.507 0.939302 10.789C0.943038 11.071 1.05859 11.34 1.26056 11.5369L7.35998 17.4836L5.91896 25.8807C5.88517 26.076 5.90678 26.2769 5.98136 26.4606C6.05593 26.6442 6.18047 26.8033 6.34087 26.9198C6.50126 27.0363 6.69109 27.1056 6.88882 27.1197C7.08656 27.1338 7.28429 27.0922 7.45959 26.9996L15 23.0352L22.5404 26.9996C22.7463 27.1092 22.9854 27.1457 23.2145 27.1059C23.7922 27.0062 24.1807 26.4584 24.0811 25.8807L22.6401 17.4836L28.7395 11.5369C28.9055 11.3742 29.0151 11.1617 29.0483 10.9293C29.1379 10.3482 28.7328 9.81035 28.1518 9.72402Z'
                          fill='#59B6BE'
                        />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => setStarRating(i + 1)}
                        className={`feedback-star`}
                        viewBox='0 0 29 27'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M28.0627 9.50747L19.3691 8.27564L15.4828 0.594228C15.3766 0.383916 15.202 0.213663 14.9863 0.110176C14.4453 -0.150211 13.7879 0.0667782 13.5174 0.594228L9.63108 8.27564L0.937449 9.50747C0.697766 9.54085 0.478627 9.65102 0.310849 9.81793C0.108015 10.0212 -0.00375586 10.2946 9.63727e-05 10.5782C0.0039486 10.8618 0.123109 11.1322 0.331393 11.3302L6.62135 17.3091L5.13532 25.7516C5.10047 25.948 5.12276 26.15 5.19967 26.3346C5.27657 26.5193 5.405 26.6793 5.57041 26.7964C5.73581 26.9135 5.93157 26.9831 6.13548 26.9973C6.33939 27.0115 6.5433 26.9697 6.72408 26.8766L14.5001 22.8907L22.2761 26.8766C22.4884 26.9868 22.7349 27.0235 22.9711 26.9834C23.5669 26.8833 23.9675 26.3325 23.8648 25.7516L22.3788 17.3091L28.6687 11.3302C28.8399 11.1666 28.9529 10.9529 28.9872 10.7193C29.0796 10.1351 28.6619 9.59426 28.0627 9.50747ZM19.732 16.4678L20.9681 23.4882L14.5001 20.1767L8.03206 23.4916L9.26814 16.4711L4.0362 11.4971L11.2678 10.4722L14.5001 4.08608L17.7324 10.4722L24.9639 11.4971L19.732 16.4678Z'
                          fill='#59B6BE'
                        />
                      </svg>
                    )}
                  </>
                ))}
              </div>
              <textarea
                onChange={(e) => setFeedbackComment(e.target.value)}
                className='material-input'
                placeholder='How are you feeling after your call?'
              ></textarea>
              <button
                disabled={starRating === null ? 'disabled' : ''}
                className=' marg-center marg-top-20'
                onClick={submitFeedback}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className='container marg-top-20 marg-bottom-20'>
      <div className='row justify-content-center'>
        <div className='col text-center'>
          <img
            className='connection-feedback-logo'
            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/Logo+Icon_Teal.png'
          />
          <h5>Thank you!</h5>
          <p>Your feedback has been submitted.</p>
        </div>
      </div>
    </div>
  );
}
