import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import Context from '../../utils/context';

export default function SendPageView() {
  let location = useLocation();
  const context = useContext(Context);

  useEffect(() => {
    if (gon.ga_id) {
      // ReactGA.pageview(location.pathname);
      console.log(`ReactGA.pageview: ${location.pathname}`);
    } else {
      console.log(`pageview: ${location.pathname}`);
    }
    context.facebookConversionApi.pageViewEvent();
  }, [location]);

  return null;
}
