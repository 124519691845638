import React, { useState, useContext } from 'react';
import styles from '../img_right_text_left/index.module.css';
import Context from '../../../utils/context';
import { Link } from 'react-router-dom';
import ScrollTrigger from '../../ui_kit/utilities/scroll_trigger';

export default function HeaderLeftTextRight({
  headline = 'Your partner through surgery',
  smallheadline = 'Find support',
  para = "Answer some questions about yourself and we'll help you find a recovered patient to help you throughout your journey.",
  margBottom,
  bg = '#f1feff',
  headlineColor = '#314D69',
  actionContent = (
    <Link to='/member/join/support'>
      <button style={{ padding: '12px 20px' }} data-gtm-click-label='Home Sign up for free'>
        Find a PatientPartner for me
      </button>
    </Link>
  ),
}) {
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile();
  const [animateLeft, setAnimateLeft] = useState(false);
  const [animateRight, setAnimateRight] = useState(false);

  return (
    <div style={{ backgroundColor: bg, padding: '50px 0px' }} className={`container-fluid ${styles.hero_container}`}>
      <div className='container'>
        <div className='row'>
          <div style={{ display: 'flex' }} className={`col-lg-5`}>
            <ScrollTrigger callback={() => setAnimateRight(true)} />
            <div
              style={{ alignSelf: 'center', height: 'max-content' }}
              className={`${animateLeft ? styles.animate : styles.animate_base}`}
            >
              <h6 className={`marg-bottom-20 marg-top-20 ${styles.heading6}`}>{smallheadline}</h6>
              <h1 style={{ marginBottom: margBottom, color: headlineColor }} className={styles.heading1}>
                {headline}
              </h1>
            </div>
          </div>
          <div className={`col-lg-7 de-pad align-items-end`}>
            <ScrollTrigger callback={() => setAnimateLeft(true)} />
            <h2 className={`marg-bottom-20 marg-top-20 ${styles.para}`}>{para}</h2>
            {actionContent}
          </div>
        </div>
      </div>
    </div>
  );
}
