import { connect } from 'react-redux';
import GetStartedV2 from '../../components/get_started_v2';
import {
  setQuestionnaireStateAction as setPatientPartnerQuestionnaireStateAction,
  updateAnswersAction as updatePatientPartnerAnswersAction,
} from '../../actions/weight_loss';
import {
  setQuestionnaireStateAction as setSurgeonMatchQuestionnaireStateAction,
  updateAnswersAction as updateSurgeonMatchAnswersAction,
} from '../../actions/weight_loss_surgeon_match';
import { clearGlobalContactInfoAction } from '../../actions/home';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setPatientPartnerQuestionnaireStateAction: (item) => dispatch(setPatientPartnerQuestionnaireStateAction(item)),
  setSurgeonMatchQuestionnaireStateAction: (item) => dispatch(setSurgeonMatchQuestionnaireStateAction(item)),
  updatePatientPartnerAnswersAction: (item) => dispatch(updatePatientPartnerAnswersAction(item)),
  updateSurgeonMatchAnswersAction: (item) => dispatch(updateSurgeonMatchAnswersAction(item)),
  clearGlobalContactInfoAction: () => dispatch(clearGlobalContactInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStartedV2);
