import React, { Component } from 'react';
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function Diagnosis({ value, onSubmit, onBack }) {
  return (
    <OptionsAndOther
      answers={{ mfDiagnosis: value }}
      keyForAnswer={'mfDiagnosis'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={[
        'Primary Myelofibrosis (PMF)',
        'Secondary Myelofibrosis (post-polycythemia vera myelofibrosis)',
        'Secondary Myelofibrosis (post-essential thrombocythemia myelofibrosis)',
        'Not sure',
        'None of the above',
      ]}
      title={`Please choose the diagnosis that you (or the patient) have been given.`}
      para={``}
      showOther={false}
      ctaTxt='Next'
      selectOne
    />
  );
}
