import React, { useState, useRef } from 'react';
import styles from './index.module.css';
import { useClickAway } from 'react-use';
import parentstyles from '../../index.module.css';
import { deleteProfileInsuranceCarrier, updateProfileInsuranceCarrier, updateProfile } from '../../../../api/profiles';
import { successToast } from '../../../ui_kit/utilities/success_toast';

export default function ProfileInsRow({
  user,
  insuranceCarrier,
  setInsuranceCarrier,
  insurancePolicy,
  setInsurancePolicy,
  insurancePrivate,
  setInsurancePrivate,
  profileIsCurrentUser,
}) {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => {
    setShowOptions(false);
  });

  const removeInsurance = () => {
    updateProfile(user.profile_id, { insurance_carriers: null, insurance_policy: null }, (data) => {
      setInsuranceCarrier(null);
      setInsurancePolicy(null);
    });
  };

  const editVisibility = () => {
    updateProfile(user.profile_id, { insurance_private: !insurancePrivate }, (data) => {
      setInsurancePrivate(!insurancePrivate);
    });
  };

  const renderInsurance = () => {
    const data = [insuranceCarrier, insurancePolicy].filter((i) => i);
    return data.join(' | ');
  };

  return (
    <div style={{ display: 'flex' }}>
      <p className={`${parentstyles.bio}`}>{renderInsurance()}</p>
      {profileIsCurrentUser && (
        <div
          style={{ marginLeft: 'auto', cursor: 'pointer', position: 'relative', display: 'flex', alignItems: 'center' }}
        >
          <img
            onClick={editVisibility}
            src={
              insurancePrivate
                ? 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/eye-off.svg'
                : 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/eye.svg'
            }
          />
          <div style={{ position: 'relative' }}>
            <img
              style={{ marginLeft: '10px' }}
              className={`${styles.options_icon} `}
              onClick={() => setShowOptions(!showOptions)}
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+3/Vector.svg'
            />
            {showOptions && (
              <div ref={ref} className={`${styles.share_list}`}>
                <div className='container'>
                  <div className={`${styles.icon_row} row`}>
                    <div onClick={removeInsurance} className={`${styles.icon_col} col`}>
                      <img
                        className={`${styles.image}`}
                        src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/trash.svg'
                      />
                      Delete
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
