import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './terms.css';

export default function Terms() {
  return (
    <div className='container-fluid nopadding marg-top-for-transparent-header'>
      <div className='row'>
        <div className='col-sm-1'></div>
        <div className='col-sm-10'>
          <section className='terms-section'>
            <h1>
              <b>RightDevice Terms of Service</b>
            </h1>
            <p>
              Please read these Rightdevice Terms of Service ("Terms") carefully. These Terms are between you ("you" or
              "your") and Rightdevice, Inc. ("Rightdevice," "we" or "our"). The use of "you" or "your" in these Terms
              may refer to Rightdevice community members, health care provider accessing the Site (as defined below) as
              a consumer, or anyone accessing the Site (as defined below). These Terms govern your use of the website,
              www.rightdevice.com, and the services and information available on the website (collectively, "Site")
              whether you access the Site through your computer, mobile device, tablet or any Rightdevice app. You
              accept and agree to be bound by these Terms, the Rightdevice Privacy Policy, Code of Conduct and any other
              policies in place when you access or use the Site or by clicking the "Submit" button when you register for
              an account with Rightdevice. If you do not agree to abide by or be bound by these Terms or any of the
              other policies of Rightdevice, then do not register for an account, access or use the Site.
            </p>
            <p>
              <b>1. Modification</b>
            </p>
            <p>
              Rightdevice reserves the right to make changes to the Site, these Terms and other policies. If Rightdevice
              makes material changes to these Terms or other policies, then Rightdevice will either notify you by email
              or by posting a notice on the Site prior to the effective date of the changes. Rightdevice will also
              indicate at the bottom of this page the date these Terms were last updated. These Terms are effective upon
              acceptance by new users. For current users, these Terms are effective as of January 23, 2017 and supersede
              all previous versions of the Rightdevice Terms of Service. If any section or portion of these Terms shall
              be deemed invalid, void or for any reason unenforceable, then that section of the Terms shall be deemed
              severable and shall not affect the validity of the remaining condition. Your continued use of the Site
              after any such changes are posted constitutes your acceptance of the new Terms. If you do not agree to
              abide by these or any future terms, you may not use or access the Site.
            </p>
            <p>
              <b>2. No Medical Advice</b>
            </p>
            <p>
              The content (which includes any text, graphics, images or other material contained, accessed or entered on
              the Site by you or a health care provider ("Content") is for educational/informational purposes only and
              is not a substitute for medical advice, diagnosis or treatment provided by a qualified health care
              provider. Any communication between you and health care providers on the Site (including any responses by
              health care providers to questions posted on the Site or through any feature available on the Site) is for
              general informational purposes only and does not create nor is it intended to create a physician-patient
              relationship as defined by federal and state law. Your reliance on any information (including any links to
              third party websites containing any information about health care providers) or Content provided on the
              Site, whether or not it is provided by a health care provider, is solely at your own risk. You should
              always seek the advice of your health care provider for any questions you may have about your own medical
              condition.
            </p>
            <p>
              <b>3. How and When You May Use the Site</b>
            </p>
            <p>
              You may use the Site only to post reviews (including photos and videos) based on your own personal
              experience, to communicate with other community members about questions on personal care, beauty, and
              wellness and to post questions on the Site. You may only use the Site for personal, non-commercial uses,
              and you may not use the Site to advertise or promote a product, service, procedure, business, health care
              provider or another person.
            </p>
            <p>
              <b>4. Your Service Account; Email Consent</b>
            </p>
            <p>
              In order to use some of the features on the Site, you are required to register and provide information
              about yourself to us and create a service account ("Service Account"). It is your responsibility to
              maintain the confidentiality of your Service Account information, including your Service Account password.
              In addition, you are responsible for all activities that occur in connection with your Service Account.
              You must tell us right away about anyone using your Service Account without your consent, or any security
              breach that relates to the Site or your Service Account. You agree to provide Rightdevice with accurate,
              current and complete information about yourself as may be prompted in any registration forms on the Site
              and promptly update any such information to keep it accurate, current and complete. Rightdevice reserves
              the right to refuse to provide services to anyone, including terminating your Service Account and/or
              preventing you from accessing the Site or your Service Account, with or without cause, at any time and in
              its sole discretion. By using the Site, you consent to receive emails from Rightdevice, which may include
              commercial emails provided such emails are in accordance with the preferences you select in the email and
              notifications page of the My Profile section of the Site. You may change such preferences by changing your
              account settings on the email and notifications page of the My Profile section of the Site or via the
              unsubscribe link in all commercial emails. Please note that as long as you maintain a Service Account, you
              may not "opt out" of receiving service or account-related emails from Rightdevice and all agreements,
              notices, disclosures, and other communications that we provide to you electronically satisfy any legal
              requirement that such communications be in writing.
            </p>
            <p>
              <b>5. Materials You Post or Provide; Communications Monitoring</b>
            </p>
            <p>
              You may be able to post or submit reviews, comments, questions, direct messages, photographs, videos
              (including videos created through a third-party tool) and other materials on or through the Site
              (collectively, "Submission" or "Submissions"). Your Submissions, (including to the extent applicable
              personally identifiable information), are made voluntarily and are subject to the Rightdevice Code of
              Conduct and the Rightdevice Privacy Policy. Except as otherwise provided under applicable law, including
              but not limited to the California Confidentiality of Medical Information Act (“CMIA”) and the federal
              Health Insurance Portability and Accountability Act of 1996 (as amended)(“HIPAA”), You understand
              Rightdevice is not required to treat any of your Submissions as confidential. In addition, you understand
              and agree that your Submissions (including your username associated with your Submissions) are not
              confidential and are publicly available for anyone to view on the Site. Rightdevice does not claim
              ownership of the Submission(s) you post or submit on the Site. With the exception of any information you
              directly submit through a health care provider’s profile or inquiry form, by posting or otherwise
              providing your Submission on the Site, you are hereby expressly granting Rightdevice a nonexclusive,
              irrevocable, worldwide, perpetual, royalty free license (including sublicense) to use, copy, distribute,
              publicly display, publicly perform, modify (including create derivative works) or transmit (including
              digital transmission of a sound recording) your Submission, in connection with the Site and the
              Rightdevice business, including without limitation promoting and redistributing part or all of the Site or
              the Rightdevice business, in any media formats and through any media channels now known or hereafter
              developed. You also grant Rightdevice permission to publish and use your name, likeness and voice in
              connection with your Submission. In addition, by uploading a Submission to the Site, you hereby waive any
              rights of privacy or publicity, with the exception of those rights of privacy that may be provided to You
              under applicable laws, state and federal, laws such as CMIA or HIPAA.. You also grant Rightdevice the
              right to grant any of the above rights to other persons or entities, including for the purpose of
              promoting the Site or the Rightdevice business, without any compensation or obligation to you. You also
              grant Rightdevice the right and authority to send takedown notices (on your behalf) related to your
              Submission to any individual or entity under the Digital Millennium Copyright Act. Rightdevice will not
              pay you for your Submission. You also understand and agree that Rightdevice may, in its sole discretion,
              refuse, or remove part or all of your Submission from the Site at any time if Rightdevice considers the
              Submission to be illegal, offensive, harassing, infringing, inappropriate or otherwise violates these
              Terms or the Rightdevice Code of Conduct or other policies. For each Submission, you represent and warrant
              that you have all rights necessary for you to post or submit the Submission and to grant the licenses and
              permissions in this section and that you are solely responsible for the content of your Submission.
              Further, you represent and warrant that your Submission does not violate any law. Rightdevice reserves the
              right to review and monitor Submissions but is under no obligation to do so. In addition, Rightdevice is
              not responsible for screening, monitoring or editing Submissions. However, we may monitor your Submissions
              and may disclose information about you, including the contents of such Submission, if we deem it necessary
              to
            </p>
            <ul>
              <li>(1) Conform to legal requirements or respond to legal process.</li>
              <li>(2) Ensure your compliance with these Terms or other Rightdevice guidelines or policies.</li>
              <li>
                (3) Protect the rights, property, or interests of Rightdevice, its employees, its users, or the public.
                You may also request information directly from a specific health care provider by submitting an inquiry
                through their profile or inquiry form. You understand these requests, including personally identifiable
                information, are made voluntarily and are subject to the Rightdevice Privacy Policy. There is no
                guarantee that a health care provider will respond to any request submitted through their profile or
                inquiry form. In addition, by submitting a request to a health care provider through the Site, you
                understand that Rightdevice may contact you by phone, email or text message about your request. All
                standard message and data charges will apply to any text messages.
              </li>
            </ul>
            <p>
              <b>6. Copyright Policy</b>
            </p>
            <p>
              If you believe that your HCP Content or any other content has been used in a way that constitutes
              copyright infringement, or your intellectual property rights have been otherwise violated, please notify
              Rightdevice, Inc. at copyrightagent@rightdevice.com or Copyright Agent, Rightdevice, Inc., 5492 Pire
              Avenue, San Diego, CA 92122. Provide all of the following in writing: (1) identify the copyrighted work
              you claim has been infringed or if multiple copyrighted works, then a representative list of such works on
              the Site; (2) identify the material on the Site that you claim is infringing and with enough detail so
              that we may locate the material; (3) provide a statement that you have a good faith belief that the use of
              the material on the Site is not authorized by the copyright owner, its agent, or the law; (4) provide a
              statement declaring that the notification is accurate, and, under penalty of perjury, that you are the
              owner of the copyright interest involved or that you are authorized to act on behalf of the exclusive
              owner; (5) provide information reasonably sufficient to permit Rightdevice to contact you, such as an
              address, telephone number, and email address; and (6) your physical or electronic signature. Upon receipt
              of notice as described above, Rightdevice will take whatever action, in its sole discretion, it deems
              appropriate, including removal of the alleged infringing material from the Site.
            </p>
            <p>
              <b>7. Photograph/Video Policy.</b>
            </p>
            <p>
              Health care providers must have consent from individuals and follow all HIPAA and CMIA requirements (as
              well as these HCP Terms, Health care Code of Conduct and Health Care Provider Photo & Video Guidelines)
              before uploading, submitting or posting any photographs or videos (including the use of any third-party
              video tool whether or not provided by Rightdevice) of individuals on the Site. When you upload or submit
              any photo or video of an individual, you represent and warrant that you have the appropriate consent and
              license to do so and the uploading or submission of the videos or photographs to the Site will not violate
              these HCP Terms, the Health care Code of Conduct, the Health Care Provider Photo & Video Guidelines, or
              any other applicable laws, including any applicable privacy laws (such as HIPAA) or CMIA). You also agree
              to indemnify Rightdevice and the Rightdevice Parties against any third-party claims related to the
              uploading or submitting of any videos or photographs by you or anyone affiliated with you (including an
              agent, employee of your practice or other third party acting on your behalf).
            </p>
            <p>
              <b>8. Rightdevice Advertising</b>
            </p>
            <p>
              If you purchase advertising from Rightdevice or offer any Special Offers on the Site, then the applicable
              agreement or the Rightdevice Special Offers Program Terms of Use will apply to you in addition to these
              HCP Terms.
            </p>
            <p>
              <b>9. Links to Other Websites</b>
            </p>
            <p>
              The Site and the Rightdevice App may contain links to other websites maintained by third parties ("Third
              Party Website") or you may be able to log into the Site or the Rightdevice App through a Third Party
              Website. Third Party Websites are not under the control of Rightdevice and Rightdevice does not endorse or
              assume any responsibility for the content, information or functionality of any Third Party Website. As a
              result, your access or use of any Third Party Website is at your own risk and these HCP Terms, the
              Rightdevice Privacy Policy, Health care Code of Conduct and any other Rightdevice policies do not apply or
              govern any Third Party Website or your use of any Third Party Website. You expressly relieve Rightdevice
              of any and all liability related to the functionality, content or information contained on any Third Party
              Website. In addition, your dealings with or participation in promotions of third parties found on the
              Site, including payment and delivery of goods or services any other terms (such as warranties) are solely
              between you and the third parties. You agree that Rightdevice shall not be responsible for any loss or
              damage of any kind relating to your dealings with such third parties.
            </p>
            <p>
              <b>10. Rightdevice Intellectual Property Rights</b>
            </p>
            <p>
              Rightdevice grants you a limited, personal, revocable, nonassignable and nonexclusive license to use the
              Site. Rightdevice owns the text, photographs, videos, visual interfaces, interactive features, graphics,
              design, compilation, computer code, products, software and all other elements and components of the Site
              ("Rightdevice Content"), excluding your HCP Content and community members’ submissions. Rightdevice owns
              the copyrights, trademarks, service marks, trade names and other intellectual property and proprietary
              rights throughout the world associated with the Rightdevice Content and the Site, which are protected by
              copyright, trade dress, patent, trademark laws and all other applicable intellectual and proprietary
              rights and laws. You may not modify, reproduce, republish, frame, download, transmit, distribute, rent,
              lease, loan, sell, assign, license, sublicense, reverse engineer, publicly display or create derivative
              works based on any of the Rightdevice Content in whole or in part, or the Site except as expressly
              authorized in writing by Rightdevice. In addition, Rightdevice does not grant any express or implied
              rights in and to the Site and the Rightdevice Content, and all rights in and to the Site and the
              Rightdevice Content, are retained by Rightdevice.
            </p>
            <p>
              <b>11. RIGHTDEVICE DISCLAIMS ALL WARRANTIES</b>
            </p>
            <p>
              Rightdevice provides the site, Rightdevice content and all information, materials and other content
              available on the site on an "as is" and " as available" basis "with all faults." Rightdevice disclaims all
              warranties of any kind (whether express or implied) as well as any guarantees or conditions related to the
              site. Without limiting the foregoing, to the fullest extent permitted by law, Rightdevice disclaims all
              express and implied warranties, including those of merchantability, fitness for a particular purpose,
              workmanlike effort and non-infringement. Rightdevice does not represent or warrant that the site or any
              electronic communication from Rightdevice will be uninterrupted, error-free or free of viruses or other
              harmful components or defects. In addition, Rightdevice expressly disclaims any and all responsibility and
              liability with respect to separate agreements you may make with health care providers or other third
              parties and you will look solely to such persons and/or entities with respect to any and all claims
              arising out of such agreements. Further, Rightdevice does not represent or warrant that the site or the
              content available on the site will assist you in finding a suitable health care provider or for any other
              purpose. You understand and agree that your use of the site is entirely at your own risk. While
              Rightdevice takes reasonable physical, technical and administrative measures to secure the Rightdevice
              site, Rightdevice does not guarantee that the Rightdevice site cannot be compromised.
            </p>
            <p>
              <b>12. LIMITATION OF LIABILITY; YOUR EXCLUSIVE REMEDY</b>
            </p>
            <p>
              To the extent permitted by law, in no event will Rightdevice or the Rightdevice parties be liable to you
              or to any third party for any indirect, consequential, special, incidental, exemplary, or punitive damages
              (including, but not limited, to loss profits and damages that result from inconvenience, delay or loss of
              use) arising out of or relating to the use of the site even if Rightdevice or any of the Rightdevice
              parties have been advised of the possibility of such damages. In addition, in no event shall Rightdevice
              be liable to you or to anyone for any decision made or action taken by a party (including, without
              limitation, by any user or community member) in reliance on any content or other information about health
              care providers or procedures posted on the site. Rightdevice shall not be liable for any damages, delays,
              or non-performance caused by factors or activities beyond its reasonable control, including delays and
              nonperformance caused by viruses, denial of service attacks, other acts or omission of third parties,
              internet service provider failures, strikes, lockouts, work slowdowns or stoppages, accidents, fires,
              terrorism, acts of god, government restrictions, mechanical, electronic or communications failures, war or
              insurrection. Notwithstanding anything in these terms, and to the extent permitted by applicable law, the
              aggregate liability of the Rightdevice parties to you in connection with the site (including any third
              party content on the site) and these terms will be limited to the amount, if any, paid by you to
              Rightdevice for use of the site in the six (6) months prior to the act that gave rise to the liability,
              even if Rightdevice has been advised of the possibility of any of the foregoing types of losses or
              damages. Some jurisdictions do not allow the exclusion or limitation of incidental or consequential
              damages, so the above limitations or exclusions may not apply to you.
            </p>
            <p>
              <b>13. Changes to the Site; Updates; Termination</b>
            </p>
            <p>
              We may change the Site or delete features available on the Site at any time and for any reason. We are not
              obligated to provide maintenance, technical support or updates to you for the Site, but we may provide
              these in our discretion. We may terminate or suspend your HCP Account and your use of the Site or any
              services we may provide you that are associated with your use of the Site at any time in our discretion.
              Our termination or suspension may be without cause and/or without notice. You may terminate your use of
              the Site at any time, with or without cause, upon notice to us as specified in the customer support or
              "contact us" area on the Site. Upon termination, you may no longer use or access the Site. Once your use
              of the Site is terminated, any data you have stored on the Site may not be retrieved later. In addition,
              even if you terminate your use of the Site, Rightdevice may continue to show some or all of your HCP
              Content.
            </p>
            <p>
              <b>14. Jurisdiction</b>
            </p>
            <p>
              Rightdevice controls and operates the Site from its offices in San Diego, California in the United States
              of America and Rightdevice makes no representation that the HCP Content, content submitted by community
              members or any other content available on the Site or the Site are appropriate, available or may be
              downloaded for use outside the United States. You are responsible for complying with all of your local
              laws, if and to the extent your local laws are applicable. These HCP Terms and the Site are governed by
              the federal laws of the United States and the laws of the State of California, excluding any conflicts of
              law provisions. When you register for an account and click "I accept", submit content to the Site, access,
              browse or otherwise use the Site, this means you hereby consent to the exclusive jurisdiction of the state
              and federal courts in San Diego County, California and you waive all defenses of lack of personal
              jurisdiction and forum non conveniens with respect to venue and jurisdiction in the state and federal
              courts of San Diego County, California.
            </p>
            <p>
              <b>15. Indemnification</b>
            </p>
            <p>
              To the extent permitted by applicable law, You agree to indemnify and hold harmless Rightdevice and the
              Rightdevice Parties from and against any and all losses, liability, claims, demand, damages, costs and
              expenses, including reasonable attorney's fees, arising out of or in connection with: (i) any HCP Content
              you post or submit to or through the Site; (ii) your use of the Site; (iii) your conduct in connection
              with the Site or with other health care providers and community members of the Site; (iv) any breach of
              these HCP Terms, other Rightdevice policies and guidelines; or (v) any violation of any law or the rights
              of any third party (including, but not limited to any violation of HIPAA or the rules, laws, guidelines or
              policies of any state medical board).
            </p>
            <p>
              <b>16. Claim Must Be Filed Within One Year; Arbitration</b>
            </p>
            <p>
              Any dispute, claim or controversy arising out of or relating to these HCP Terms, Rightdevice, the Site or
              the breach, termination, enforcement, interpretation or validity thereof, including the determination of
              the scope or applicability of this agreement to arbitrate, shall be determined by final and binding
              arbitration as the sole and exclusive remedy for such controversy or dispute. Any claim shall be made by
              filing a demand for arbitration within one (1) year following the event first giving rise to the claim.
              The arbitration shall be held in San Diego County, California before one arbitrator. The arbitration shall
              be governed by the Federal Arbitration Act and the arbitration shall take place according to the rules of
              the American Arbitration Association. Judgment on the Award may be entered in any court having
              jurisdiction. Each of the parties shall bear all of its own costs of arbitration except the fee for the
              arbitrator shall be equally split between the parties. The arbitrator shall not have the authority to
              modify these HCP Terms or award punitive or exemplary damages to either party. This clause shall not
              preclude parties from seeking provisional remedies in aid of arbitration from a court of appropriate
              jurisdiction. Seeking any such remedies shall not constitute a waiver of either party’s right to compel
              arbitration.
            </p>
            <p>
              <b>17. Your Notices to Us</b>
            </p>
            <p>
              You may notify us by e-mail or postal mail. Your notices to us must be addressed as stated in the
              contactus@rightdevice.com or "feedback" area for the Site or at the following address: Rightdevice, Inc.
            </p>
            <p>
              <b>18. Our Notices to You; Consent Regarding Electronic Communication</b>
            </p>
            <p>
              This contract is in electronic form. There may be other information regarding the Site that the law
              requires us to send you. You agree that we may send you this information in electronic form. You have the
              right to withdraw this consent, but if you do, we may cancel your ability to use the Site. We may provide
              required information to you via e-mail at the e-mail address you specified when you registered for a HCP
              Account (or subsequently provided when updating your account information), (2) by access to a Rightdevice
              website that will be designated in an e-mail notice sent to you at the time the information is available,
              or (3) by access to a Rightdevice website that will be generally designated in advance for this purpose.
              Notices provided to you via e-mail will be deemed given and received on the transmission date of the
              e-mail. As long as you access and use the Site, you will have the necessary software and hardware to
              receive these notices. If you do not consent to receive any notices electronically, you must stop using
              the Site. If you want these HCP Terms provided to you in nonelectronic form, then please send a request to
              Rightdevice at contactus@rightdevice.com. In that request, please provide your full name and address where
              Rightdevice can send these HCP Terms in nonelectronic form.
            </p>
            <p>
              <b>19. Miscellaneous</b>
            </p>
            <p>
              These HCP Terms constitute the entire agreement between you and Rightdevice regarding your use of the
              Site, and supersedes any prior agreements between you and Rightdevice relating to your use of the Site.
              The failure of Rightdevice to exercise or enforce any right or provision of these HCP Terms shall not
              constitute a waiver of such right or provision in that or any other instance. If any provision of these
              HCP Terms is held invalid, the remainder of these HCP Terms shall continue in full force and effect. If
              any provision of these HCP Terms shall be deemed unlawful, void or for any reason unenforceable, then that
              provision shall be deemed severable from these HCP Terms and shall not affect the validity and
              enforceability of any remaining provisions. If you are a California resident, in accordance with Cal. Civ.
              Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer
              Services of the California Department of Consumer Affairs by contacting them in writing at 400 R Street,
              Sacramento, CA 95814 or by telephone at (800) 952-5210. Sections 4, 7, 12-19 survive the termination of
              these HCP Terms. Last updated: January 01, 2023
            </p>
          </section>
        </div>
        <div className='col-sm-1'></div>
      </div>
    </div>
  );
}
