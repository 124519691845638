import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useParams, useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';
import SignUp from '../../sign_up';
import { getSurgeon } from '../../../api/surgeons';
import { createSurgeonReferredMentorMatch } from '../../../api/surgeon_referred_mentor_matches';
import GetStartedSurgeonReferred from './get_started';
import Context from '../../../utils/context';
import styles from './index.module.css';
import LoadingScreen from '../../loading_screen';

export default function SurgeonReferredMentorMatch() {
  let { id } = useParams();
  if (!id) {
    if (window.location.pathname.includes('reactiv8')) {
      id = 'reactiv8';
    } else {
      id = 'new-york-bariatric-group';
    }
  }
  const [view, setView] = useState('loading');
  const [surgeon, setSurgeon] = useState();
  const utm_source = useRef(new URLSearchParams(window.location.search).get('utm_source'));
  const uuid = useRef(new URLSearchParams(window.location.search).get('uuid'));
  const history = useHistory();
  const context = useContext(Context);
  let { path } = useRouteMatch();
  path = path.replace(`:id`, id);

  useEffect(() => {
    if (id === 'reactiv8') {
      history.push('/mentor-match/reactiv8');
      return;
    }
    if (id === 'new-york-bariatric-group') {
      history.push('/mentor-match/nybg');
      return;
    }
    getSurgeon(id, (data) => {
      setSurgeon(data);
      setView('get-started');
    });
  }, [id]);

  const createAndRedirect = () => {
    createSurgeonReferredMentorMatch(
      {
        surgeon_id: surgeon.id,
        surgery: null,
        ...(utm_source.current === 'SurgeonPatientLead' &&
          uuid.current !== null && { surgeon_patient_lead: uuid.current }),
      },
      (match) => {
        history.push(`/member/surgeon-mentor-matches/${match.uuid}/pre-survey`);
      }
    );
  };

  return (
    <div id='questionnaire' className={`${styles.questionnaire_ottr} container`}>
      <div className='row justify-content-center h-100'>
        <div className='col h-100'>
          <div className={`container h-100`}>
            <div className={`row h-100 ${styles.cont_row}`}>
              <div className='col-sm-2'></div>
              <div className='col-sm-8'>
                <Switch>
                  <Route exact path={`${path}`}>
                    {view === 'loading' && <LoadingScreen />}
                    {view === 'get-started' && (
                      <div style={{ padding: '0px 20px' }}>
                        <GetStartedSurgeonReferred
                          surgeon={surgeon}
                          onSubmit={() => {
                            context.auth.isLoggedIn() ? createAndRedirect() : history.push(`${path}/get-started`);
                          }}
                        />
                      </div>
                    )}
                  </Route>
                  <Route path={`${path}/get-started`}>
                    <SignUp
                      onSuccess={createAndRedirect}
                      params={{ keap_force_sync: true, origin: 'Surgeon Referred Mentor Match' }}
                      headline={`Get matched to your patient mentor!`}
                      para={`Create your account and get your mentor match.`}
                      embedded={true}
                      alignLeft={true}
                      light={false}
                      captureFirstLastName
                    />
                  </Route>
                </Switch>
              </div>
              <div className='col-sm-2'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
