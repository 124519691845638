import React, { useState, useEffect, useContext } from 'react';
import Context from '../../../../utils/context';
import GhostButton from '../../../ui_kit/utilities/ghost_button';
import styles from './index.module.css';
import parentstyles from '../../index.module.css';
import AddCondition from './add_condition';
import Divider from '../../../ui_kit/utilities/divider';
import { getConditions } from '../../../../api/profiles';
import ConditionRow from './condition_row';

export default function ProfileConditions({ user, tab = 'current' }) {
  const context = useContext(Context);
  const currentUser = context.auth.getCurrentUser();
  const [addCondition, setAddCondition] = useState(false);
  const [profile, setProfile] = useState(user.current_profile);
  const [conditions, setConditions] = useState([]);
  const [recovered, setRecovered] = useState(false);

  let profileIsCurrentUser = false;
  if (
    context.auth.isLoggedIn() &&
    currentUser.current_profile &&
    profile &&
    currentUser.current_profile.id === profile.id
  ) {
    profileIsCurrentUser = true;
  }

  useEffect(() => {
    getConditions(user.profile_id, (data) => {
      setConditions(data);
    });
  }, []);

  useEffect(() => {
    if (tab === 'current') {
      setRecovered(false);
    } else {
      setRecovered(true);
    }
  }, [tab]);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <h2 className={styles.profile_section_headline}>Conditions</h2>
        {profileIsCurrentUser && (
          <GhostButton
            data-pendo-tag='profile page - health profile - add condition'
            onClick={() => setAddCondition(!addCondition)}
            style={{ marginLeft: 'auto' }}
          >
            <img
              alt='add Condition'
              style={{
                marginRight: '14px',
                height: '12px',
                transform: addCondition ? 'rotate(45deg)' : 'rotate(0deg)',
              }}
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/plus_.svg'
            />
            <div style={{ position: 'relative' }}>
              <span
                className={`${addCondition ? styles.show : null} ${styles.no_show} ${styles.cancel}`}
                style={{ fontWeight: '600', color: '#419DA5', position: 'absolute', right: '0px' }}
              >
                Cancel
              </span>
              <span
                className={`${addCondition ? null : styles.show} ${styles.no_show} ${styles.add}`}
                style={{ fontWeight: '600', color: '#419DA5' }}
              >
                Add condition
              </span>
            </div>
          </GhostButton>
        )}
      </div>
      {addCondition && (
        <div className={`marg-bottom-10`}>
          <AddCondition
            conditions={conditions}
            setConditions={setConditions}
            setAddCondition={setAddCondition}
            recovered={recovered}
          />
        </div>
      )}
      <div className='content_container'>
        {conditions.filter((condition) => condition.recovered == recovered).length > 0 ? (
          <>
            {conditions
              .filter((condition) => condition.recovered == recovered)
              .map((condition, i) => (
                <div key={i}>
                  <ConditionRow
                    user={user}
                    condition={condition}
                    i={conditions
                      .filter((condition) => condition.recovered == recovered)
                      .findIndex((p) => p.id == condition.id)}
                    setConditions={setConditions}
                    conditions={conditions}
                    profileIsCurrentUser={profileIsCurrentUser}
                  />
                  {conditions
                    .filter((condition) => condition.recovered == recovered)
                    .findIndex((p) => p.id == condition.id) !==
                    conditions.filter((condition) => condition.recovered == recovered).length - 1 && <Divider />}
                </div>
              ))}
          </>
        ) : (
          <p className={parentstyles.bio}>No conditions added yet</p>
        )}
      </div>
    </div>
  );
}
