import React, { useEffect } from 'react';
import './simple_sliding_menu.css';

export default function SimpleSlidingMenu({
  menuItems = [
    {
      idToScrollTo: 'insurance',
      linkText: 'Insurance',
    },
    {
      idToScrollTo: 'media',
      linkText: 'Media',
    },
  ],
  setView,
  defaultState,
}) {
  // useEffect(() => {
  //   const top = document.querySelector('.top-of-elem').offsetTop;
  //   window.onscroll = function () {
  //     /* for making the menu sticky */
  //     afixMenu(top)
  //     /* for moving the sliding indiator around */
  //     checkLocation()
  //   }
  //   document.querySelector('.slider-menu').onscroll = function () { showScrollShadows() }
  //   if (document.querySelector('body').getBoundingClientRect().top * -1 <= top) {
  //     document.querySelector('.slider-menu-container').classList.remove("sticky");

  //   }
  //   return () => {
  //     window.onscroll = window.defaultOnScroll;
  //   }
  // }, []);

  useEffect(() => {
    document.querySelector('#marker').style.left =
      document.querySelector(`.slider-menu.simple a${defaultState ? '.' + defaultState : ':first-of-type'}`)
        .offsetLeft + 'px';
    document.querySelector('#marker').style.width =
      document.querySelector(`.slider-menu.simple a${defaultState ? '.' + defaultState : ':first-of-type'}`)
        .offsetWidth + 'px';
    document
      .querySelector(`.slider-menu.simple a${defaultState ? '.' + defaultState : ':first-of-type'}`)
      .classList.add('active');
  }, []);

  const indicator = (c) => {
    document.querySelector('#marker').style.left = document.querySelector('.' + c).offsetLeft + 'px';
    document.querySelector('#marker').style.width = document.querySelector('.' + c).offsetWidth + 'px';
    document.querySelectorAll('.slider-menu>a').forEach(function (e) {
      e.classList.remove('active');
    });
    document.querySelector('.' + c).classList.add('active');
  };

  return (
    <div className='container de-pad'>
      <div className='row'>
        <div className='col'>
          <div className='slider-menu-container'>
            <div className='slider-menu simple'>
              <div id='marker'></div>
              {menuItems.map((item) => (
                <a
                  data-pendo-tag={item.pendoTag}
                  key={item.idToScrollTo}
                  onClick={(e) => {
                    indicator(item.idToScrollTo);
                    setView(item.idToScrollTo);
                  }}
                  className={item.idToScrollTo}
                >
                  {item.linkText}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
