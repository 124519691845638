import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './privacy.css';

export default function Privacy() {
  return (
    <div className='container-fluid nopadding marg-top-for-transparent-header'>
      <div className='row'>
        <div className='col-sm-1'></div>
        <div className='col-sm-10'>
          <section className='terms-section'>
            <h1>
              <b>Privacy Policy And Authorization</b>
            </h1>
            <p>
              <b>
                IMPORTANT – THIS IS A LEGAL AGREEMENT BETWEEN YOU (referenced herein with ”you" or with ”your" or
                ”user") AND RIGHTDEVICE, INC. DBA PATIENTPARTNER (referenced herein as ”Rightdevice"). YOU SHOULD READ
                CAREFULLY THE FOLLOWING PRIVACY POLICY AND AUTHORIZATION BECAUSE IT GOVERNS YOUR PARTICIPATION IN THE
                PatientPartner™ PROGRAM OFFERED BY RIGHTDEVICE (referenced herein as the ”PatientPartner™ Program").
                RIGHTDEVICE IS WILLING TO GRANT YOU THE RIGHT TO PARTICIPATE IN THE PatientPartner™ PROGRAM ONLY ON THE
                CONDITION THAT YOU AGREE TO ALL OF THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT. IF YOU DO NOT
                AGREE WITH ALL OF THE TERMS AND CONDITIONS SET FORTH IN THIS AGREEMENT, YOU ARE NOT GRANTED PERMISSION
                TO PARTICIPATE IN THE PatientPartner™ PROGRAM. BY SIGNING AT THE END OF THIS AGREEMENT, YOU ACKNOWLEDGE
                THAT YOU HAVE READ THIS AGREEMENT, THAT YOU UNDERSTAND IT AND ITS TERMS AND CONDITIONS, AND THAT YOU
                AGREE TO BE BOUND LEGALLY BY IT AND ITS TERMS AND CONDITIONS.
              </b>
            </p>
            <p>
              Rightdevice, Inc dba PatientPartner ("us", "we", or "our") operates the{' '}
              <a href='https://www.patientpartner.com'>https://www.patientpartner.com</a> website (hereinafter referred
              to as the "Service"). This page informs you of our policies regarding the collection, use, and disclosure
              of Personal Data when you use our Service and the choices you have associated with that data.
            </p>
            <p>
              We use your Personal Data to provide and improve the Service. By using the Service, you agree to the
              collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy
              Policy, the terms used in this Privacy Policy have the same meanings as in our Terms of Service,
              accessible from <a href='https://patientpartner.com/terms'>https://patientpartner.com/terms</a>
            </p>
            <p>
              Rightdevice uses reasonable administrative, physical, and electronic security measures to protect against
              the loss, misuse, and alteration of your Personal Data. However, the Internet cannot ever be guaranteed to
              be entirely secure, and your transmission of data over the Internet is at your own risk, Neither
              Rightdevice nor our service providers can ensure or warrant the security of the information you send us
              over the Internet. You should protect your data by carefully selecting and protecting your password. Limit
              access to your computer or device and browser.
            </p>
            <p>
              <b>Definitions</b>
            </p>
            <ol>
              <li>
                <b>Service</b>
                <p>
                  Service is the <a href='https://www.patientpartner.com'>https://www.patientpartner.com</a> website
                  operated by Rightdevice, Inc dba PatientPartner and the services provided thereunder. PatientPartner™
                  is a platform to help you through your treatment journey by connecting you with a community of
                  like-minded patients and a network of peer mentors to assist you.
                </p>
              </li>
              <li>
                <b>Personal Data</b>
                <p>
                  Personal Data, otherwise known as individually identifiable information, means data about an
                  individual who can be identified from the supplied data (or from those and other information either in
                  our possession or likely to come into our possession). Rightdevice may de-identify Personal Data. Once
                  identifying information is removed, Rightdevice no longer treats the data as Personal Data.
                  Rightdevice may aggregate or statistically analyze Personal Data from more than one user, in which
                  case such resulting aggregated or statistically analyzed data will not be treated as Personal Data by
                  Rightdevice.
                </p>
              </li>
              <li>
                <b>Usage Data</b>
                <p>
                  Usage Data is data collected automatically either generated by the use of the Service or from the
                  Service infrastructure itself (for example, the duration of a page visit). See later section on for
                  additional relevant provisions concerning Usage Data.
                </p>
              </li>
              <li>
                <b>Cookies</b>
                <p>Cookies are small files stored on your device (computer or mobile device).</p>
              </li>
            </ol>
            <p>
              <b>Information Collection and Use</b>
            </p>
            <p>
              We collect several different types of information for various purposes to provide and improve our Service
              to you as well as to facilitate your use of our Service
            </p>
            <p>
              <b>Types of Data Collected</b>
            </p>
            <ol>
              <li>
                <b>Personal Data</b>
                <p>
                  While using our Service, we may ask you to provide us with certain Personal Data that can be used to
                  contact or identify you. Personal Data may include, but is not limited to:
                </p>
                <ol>
                  <li>userID by the Service</li>
                  <li>First name and last name</li>
                  <li>Email address</li>
                  <li>Phone number</li>
                  <li>Address, State, Province, ZIP/Postal code, City</li>
                  <li>IP addresses</li>
                  <li>Geolocation data</li>
                  <li>Date of birth</li>
                  <li>Current/Historical treatments: procedures, medications, surgeries, etc.</li>
                  <li>Established/Historical Health Care Professional</li>
                  <li>Height and weight</li>
                  <li>Current/Historical health conditions: comorbidities, diseases, conditions, etc.</li>
                  <li>Health insurance information</li>
                  <li>And other relevant healthcare-related information</li>
                  <li>Cookies and Usage Data</li>
                  <li>
                    Private Message content for Private Messages between Users and Mentors (kept and reviewed internally
                    for quality assurance and safety)
                  </li>
                </ol>
              </li>
              <li>
                <b>Non-Identifying Data</b>
                <p>
                  “Non-Identifying Data” is all information, except Personal Data, that Users provide about themselves
                  when using the Service or in other communications with Rightdevice. Examples of Non-Identifying Data
                  that Users may submit include:
                </p>
                <ol>
                  <li>Non-identifying photographs</li>
                  <li>Age (unless over 89)</li>
                  <li>Location (city, state/province, country)</li>
                  <li>Sex</li>
                  <li>Gender identity</li>
                  <li>Condition/disease information (e.g., first symptom, family history)</li>
                  <li>
                    Treatment information (e.g., treatment stop reasons, dosages, side effects, treatment evaluations)
                  </li>
                  <li>Symptom information (e.g., severity, duration)</li>
                  <li>
                    Outcome scores (e.g., ALSFRS-R, MSRS, PDRS, FVC, PFRS, DailyMe, and MonthlyMe measures (not
                    including free-text associated with this tracking))
                  </li>
                  <li>Health measures (e.g., weight, blood pressure, sleep, activity)</li>
                  <li>Laboratory results and biomarkers (e.g., CD4 count, viral load, creatinine, images)</li>
                  <li>Structured survey responses</li>
                  <li>
                    Free text entries, including forum posts, comments, treatment evaluations, surveys, annotations,
                    journals, and feeds (collectively, “Free Text Entries”), that you make anywhere on the Service all
                    of which is by default available to view by the public, including unregistered users (Please Note:
                    If you include information that would otherwise be considered “Personal Data” within any Free Text
                    Entries, for purposes of this Policy, such information will be treated as “Non-Identifying Data”)
                  </li>
                </ol>
                <p>
                  Rightdevice may aggregate or statistically analyze data, including from more than one user. The
                  resulting aggregated or statistically analyzed data shall be treated as Non-Identifying Data by
                  Rightdevice.
                </p>
              </li>
              <li>
                <b>Usage Data</b>
                <p>
                  We may also collect information how the Service is accessed and used ("Usage Data”) We, our Partners,
                  and our Vendors, use (or may use) analytics code and may use web tracking technologies such as cookies
                  , pixel tags, embedded scripts, web-beacons (also known as tracking pixels) and other tracking
                  technologies to understand how Users use our Service and to improve products and services. Such Usage
                  Data can include the URL of the websites you visited before and after you visited our Service, the
                  type of browser you are using, your Internet Service Provider, your computer's Internet Protocol
                  address (e.g. IP address), browser type, browser version, what pages on our platform you visit, what
                  links you click on, date and time of your visit and duration, whether you open email communications we
                  send to you, and whether you interact with advertising or content displayed on the site and
                  third-party sites. The analytics code also collects information about you such as geolocation, age,
                  gender, affinity categories, and interests, which can be used by Rightdevice. You may be able to
                  modify your browser settings to alter which web tracking technologies are permitted when you use the
                  Service, but this may limit your use of the Service. Usage Data is typically only used by Rightdevice,
                  and our Vendors. However, when de-identified it may be shared with our research Partners to help them
                  understand how members use and benefit from the site.
                </p>
                <p>
                  You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                  However, if you do not accept cookies, you may not be able to use some portions of our Service.
                </p>
                <p>Examples of Cookies we use:</p>
                <ol>
                  <li>Session Cookies. We use Session Cookies to operate our Service.</li>
                  <li>
                    Preference Cookies. We use Preference Cookies to remember your preferences and various settings.
                  </li>
                  <li>Security Cookies. We use Security Cookies for security purposes.</li>
                </ol>
              </li>
            </ol>
            <p>
              <b>Use of Data</b>
            </p>
            <p>
              PatientPartner uses the collected data for various internal purposes and only as much of the data as is
              necessary to complete the activities listed below:
            </p>
            <ol>
              <li>To provide and maintain the Service</li>
              <li>For research </li>
              <li>To create better tools and more personalized experiences for you.</li>
              <li>To notify you about changes to our Service</li>
              <li>To allow you to participate in interactive features of our Service when you choose to do so </li>
              <li>To monitor the usage of the Service</li>
              <li>To provide analysis or valuable information so that we can improve the Service </li>
              <li>To detect, prevent and address technical issues.</li>
            </ol>
            <p>
              Rightdevice may report individual adverse event and drug safety information to regulatory Partners like
              the FDA, CDC or other bodies (US and international) as well as directly to Pharmaceutical and other
              Partners. When reporting such information, Rightdevice does not provide Personal Data, although we reserve
              the right to contact Users for follow-up at the request of agencies or Partners.
            </p>
            <b>
              We will never sell your Personal data for non-Rightdevice usage unless you provide specific consent to do
              so.
            </b>
            <p>
              <b>Transfer Of Data</b>
            </p>
            <p>
              Your information, including Personal Data, may be transferred to and maintained on - computers located
              outside of your state, province, country, or other governmental jurisdiction where the data protection
              laws may differ from those from your jurisdiction. If you are located outside the United States and choose
              to provide information to us, please note that we transfer the data, including Personal Data, to the
              United States and process it there. As a result, your submission of Personal Data to us is governed by the
              laws of the United States and the State of California.
            </p>
            <p>
              Your consent to this Privacy Policy followed by your submission of such information represents your
              agreement to that transfer.
            </p>
            <p>
              Rightdevice will take all steps reasonably necessary to ensure that your data is treated securely and in
              accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
              organization or a country unless there are adequate controls and assurances in place including the
              security of your data and other Personal Data.
            </p>
            <p>
              Rightdevice provides California residents with the option to opt-out to sharing of “personal information”
              as defined by California’s “Shine the Light” law with third parties, including our Affiliates, for such
              third parties’ own direct marketing purposes. California residents may exercise that opt-out, and/or
              request information about Rightdevice’s compliance with the Shine the Light law, by contacting Rightdevice
              at <a href='mailto:contact@patientpartner.com'>contact@patientpartner.com</a> or by sending a letter to
              Rightdevice at 1025 Prospect Street, Suite 350, La Jolla, CA 92037. Requests must include “California
              Privacy Rights Request” in the first line of the description and include your name, street address, city,
              state, and ZIP code. Please note that RIGHTDEVICE is only required to respond to one request per customer
              each year.
            </p>
            <p>
              <b>Disclosure Of Data</b>
            </p>
            <p>
              <b>Legal Requirements</b>
            </p>
            <p>
              Rightdevice may disclose your Personal Data in the good faith belief that such action is necessary to:
            </p>
            <ol>
              <li>To comply with a legal obligation.</li>
              <li>To protect and defend the rights or property of Rightdevice.</li>
              <li>To prevent or investigate possible wrongdoing in connection with the Service.</li>
              <li>To protect the personal safety of users of the Service or the public.</li>
              <li>To protect against legal liability.</li>
            </ol>
            <p>
              <b>Other Special Cases</b>
            </p>
            <p>
              There are instances, not covered above, where your Non-Identifying Data, Personal Data, and Usage Data may
              be used and disclosed, including, but not limited to, the following:
            </p>
            <ol>
              <li>
                Rightdevice may use your data in the case of an emergency or other circumstance that we determine
                requires a member of Rightdevice’s team to directly contact the User (for example, a data breach that
                put the User’s data at risk).
              </li>
              <lv>
                Rightdevice may transfer your data to any successor to its business as a result of any merger,
                acquisition, asset sale, bankruptcy proceeding, or similar transaction or event. Users will be made
                aware of any changes in the current Privacy Policy that results from such a transfer.
              </lv>
            </ol>
            <p>
              <b>Your Privacy Rights</b>
            </p>
            <p>
              You have the rights listed below. However, these rights are not absolute, and in certain cases, we may
              decline your request as permitted by law.
            </p>
            <ol>
              <li>Access: You can request a copy of the personal data that we have collected about you.</li>
              <li>
                Correction: You can request that we correct inaccurate personal data that we have collected about you.
              </li>
              <li>
                Deletion: You can request that we delete your personal data. Please note that once we process a deletion
                request, we may be unable to provide access or support to any past products or services you have engaged
                with on PatientPartner.
              </li>
            </ol>
            <p>
              You many submit requests to exercise these rights at:{' '}
              <a href='https://patientpartner.com/contact-us'>https://patientpartner.com/contact-us</a> or by mailing us
              at 1025 Prospect Street #350, La Jolla, CA 92037. We cannot process a request if you do not provide us
              with sufficient information to allow us to understand and respond appropriately.
            </p>
            <p>
              <b>Your State Privacy Rights</b>
            </p>
            <p>
              State consumer privacy laws may provide their residents with additional rights regarding our use of their
              personal information.
            </p>
            <p>
              California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Montana, Oregon, Tennessee, Texas,
              Utah, and Virginia provide (now or in the future) their state residents with rights to:
            </p>
            <ol>
              <li>Confirm whether we process their personal information.</li>
              <li>
                Correct inaccuracies in their personal information, taking into account the information's nature
                processing purpose (excluding Iowa and Utah).
              </li>
              <li>Data portability.</li>
              <li>Opt-out of personal data processing for: </li>
              <li>targeted advertising (excluding Iowa);</li>
              <li>sales; or</li>
              <li>
                profiling in furtherance of decisions that produce legal or similarly significant effects (excluding
                Iowa and Utah).
              </li>
              <li>Either limit (opt-out of) or require consent to process sensitive personal data.</li>
              <li>
                The exact scope of these rights may vary by state. To exercise any of these rights please send us an
                email with your request to <a href='mailto:contact@patientpartner.com'>contact@patientpartner.com</a>.
                To appeal a decision regarding a consumer rights request please send us an email with your notice of
                appeal to <a href='mailto:contact@patientpartner.com'>contact@patientpartner.com</a>.
              </li>
            </ol>
            <p>
              <b>International Transfer</b>
            </p>
            <p>
              Rightdevice is based in the U.S. and the information Rightdevice collects is governed by U.S. law. If you
              access the Service from outside of the U.S., please be aware that information collected through the
              Service may be transferred to, processed, stored and used in the U.S. Data protection laws in the U.S. may
              be different from those of your country of residence. Your use of the Service or provision of any
              information therefore constitutes your consent to and from, processing, usage, sharing and storage of your
              information, including Personal Data, to the U.S. as set forth in this Privacy Policy.
            </p>
            <p>
              <b>GOVERNING LAW AND SERVICE USERS FROM OUTSIDE THE UNITED STATES</b>
            </p>
            <p>
              As previously state, we and our servers are located in the United States and are subject to the applicable
              US local and national laws. These laws may not have equivalent privacy protection as those in your country
              of residence. When we share information about you with our various partners, the data-sharing agreement
              includes data protection clauses. Rightdevice adheres to the EU-U.S. DPF Principles with regard to
              personal data transferred from the European Union and the United Kingdom. Rightdevice does not adhere to
              the Swiss-U.S. DPF Principles with regard to personal data transferred from Switzerland. We also comply
              with the EU-U.S. Data Privacy Framework (EU-U.S. DPF), the UK Extension to the EU-U.S. DPF
            </p>
            <p>
              Those who choose to access the Service do so on their own initiative and understanding that their use of
              the Service and use of the Non-Identifying Data, Personal Data, and Usage Data may be subject to EU, UK,
              and US laws and regulations including the GDPR. If users choose to access or use the Platform, they
              consent to the use and disclosure of information (including GDPR "special category" data such as race,
              ethnicity, and data concerning health) in accordance with this Privacy Policy and subject to such laws.
              Transfer of data from residents of the EU/EEA/UK is done under this consent and also for the purpose of
              providing Service to those users, as allowed by Article 49 of the GDPR.
            </p>
            <p>
              Rightdevice complies with the EU-U.S. Data Privacy Framework (EU-U.S. DPF) and the UK Extension to the
              EU-U.S. DPF. Rightdevice has certified to the U.S. Department of Commerce that it adheres to the EU-U.S.
              Data Privacy Framework Principles (EU-U.S. DPF Principles) with regard to the processing of personal data
              received from the European Union and the United Kingdom in reliance on the EU-U.S. DPF and the UK
              Extension to the EU-U.S. DPF. [IS THIS TRUE?} If there is any conflict between the terms in this privacy
              policy and the EU-U.S. DPF Principles, the Principles shall govern.
            </p>
            <p>
              We acknowledge the right of EU and UK individuals to access their personal data under the EU-U.S. DPF and
              the UK Extension to the EU-U.S. DPF. Individuals wishing to exercise this right may do so by contacting us
              at <a href='mailto:contact@patientpartner.com'>contact@patientpartner.com</a>.
            </p>
            <p>
              We will also provide EU and UK individuals opt-out or opt-in choices before we share their data with third
              parties other than our agents, or before we use it for a purpose other than which it was originally
              collected or subsequently authorized. To request to limit the use and disclosure of your personal
              information, you may do so by contacting us at{' '}
              <a href='mailto:contact@patientpartner.com'>contact@patientpartner.com</a>.
            </p>
            <p>
              <b>General Data Protection Regulation (GDPR)</b>
            </p>
            <p>
              All individuals have rights regarding their data. The European Union’s (EU) General Data Protection
              Regulations (GDPR) describes these rights in law. They include:
            </p>
            <ol>
              <li>
                You have the right to clear and transparent communication about your data. We want to make this policy
                as clear as possible to help you understand it.
              </li>
              <li>
                You have the right to request a copy of your data in a common digital format. To request this
                information, please contact our team.
              </li>
              <li>
                You have the right to edit or correct your data. You can edit most of your information on the Platform.
                If you need help with this, contact our team.
              </li>
              <li>You have the right to request that your data be deleted. To do this, contact our team.</li>
              <li>
                You have the right to be notified of any breach involving your data. We will notify the appropriate data
                protection authority within 72 hours of detecting a breach involving your data. We will notify you as
                soon as possible after that.
              </li>
              <li>
                You have the right to object to the processing of your data. You may decline any consent request to
                share your identifying data with a Partner and this will have no impact on your use of the service. For
                clarity, we may still share with our Partners data regarding you that does not identify you and is not
                connected with you. You may request to close your account at any time (see Closing Your Account above).
              </li>
            </ol>
            <p>
              In some cases, these rights might be restricted. Some examples would include where the information
              requested compromises the privacy of another individual or is the subject of legal proceedings or
              investigation. Additionally, processing that has already occurred cannot be undone. If you have questions
              or complaints about our handling of these rights, see the information at the end of this policy.
            </p>
            <p>
              <b>What are the Legal Bases for Our Collection and Use of Your Data?</b>
            </p>
            <p>GDPR sets out a number of possible bases, three of which apply to Rightdevice and the Service:</p>
            <ol>
              <li>
                We need to use some identifying information just to operate the Service. This includes your email
                address, username, password, and IP address, among other items.
              </li>
              <li>
                We use your data for research with your consent, as described in this Privacy Policy. We will always ask
                for your additional, explicit consent before sharing identifying information with our Partners. This is
                described further, below.
              </li>
              <li>
                In rare cases we may need to share your data to comply with a legal obligation. See "Other Special
                Cases" above.
              </li>
              <li>
                If you are a resident of the European Union or the UK and have a complaint about our use or processing
                of your data, you have a right to lodge a complaint with a national Data Protection Authority. The UK
                and each European Union member nation has established its own Data Protection Authority; you can find
                out about the{' '}
                <a href='https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm'>
                  Data Protection Authority in your country
                </a>
                .
              </li>
              <li>
                Personal Information: Any reference or mention to “personal information” or Personal Data in this
                Privacy Policy are equivalent to “personal data” governed by European data protection legislation.
              </li>
              <li>
                Controller: PatientPartner is the designated controller of your personal information covered by this
                PatientPartner Privacy Policy for purposes of European data protection legislation. We have an appointed
                Data Protection Officer, who can be reached via email at{' '}
                <a href='mailto:contact@patientpartner.com'>contact@patientpartner.com</a>.
              </li>
              <li>
                Other Purposes: We may use your Personal Data for reasons not described in this Privacy Policy where
                permitted by law and the reason is compatible with the purpose for which we collected it. If we need to
                use your Personal Data for an unrelated purpose, we will actively reach out and notify you to explain
                the applicable legal basis and obtain your consent. We may also re-disclose such information for legally
                permissible reasons. Such re-disclosure is in some cases not prohibited by California law and may no
                longer be protected by federal confidentiality law (e.g. HIPAA). However, California law prohibits the
                person receiving your Personal Data from making further disclosures of it unless another authorization
                is obtained from you or unless such disclosure is specifically required or permitted by law. In
                addition, only those individuals who have a reason to review your Personal Data, in conjunction with our
                performance of the Service, will be permitted access to your Personal Data, and only the minimally
                necessary Personal Data required for the performance of such tasks and functions.
                <br />
                <br />
                You may submit requests concerning the handling of your data by contacting us by emailing{' '}
                <a href='mailto:contact@patientpartner.com'>contact@patientpartner.com</a>. We may request specific
                information from you to help us confirm your identity and process your request. Applicable law may
                require or permit us to decline your request. If we decline your request, we will tell you why, subject
                to legal restrictions. You can find your data protection regulator here.
              </li>
              <li>
                Cross-Border Data Transfer
                <br />
                <br />
                If we transfer your personal information from the United Kingdom or European Economic Area to another
                country such that we are required to apply additional safeguards to your personal information under
                European data protection laws, we will do so.
              </li>
            </ol>
            <p>
              <b>Security of Data</b>
            </p>
            <p>
              This website contains forward-looking statements that involve substantial risks and uncertainties. All
              statements, other than statements of historical facts, contained in this website, including statements
              regarding our strategy, future operations, future financial position, future revenues, projected costs,
              prospects, plans and objectives of management, are forward -looking statements. The words “anticipate,”
              “believe,” “estimate,” “expect,” “intend,” “may,” “plan,” “predict,” “project,” “target,” “potential,”
              “will,” “would,” “could,” “should,” “continue,” and similar expressions are intended to identify
              forward-looking statements, although not all forward-looking statements contain these identifying words.
            </p>
            <p>
              We may not actually achieve the plans, intentions or expectations disclosed in our forward -looking
              statements, and you should not place undue reliance on our forward-looking statements. Actual results or
              events could differ materially from the plans, intentions and expectations disclosed in the
              forward-looking statements we make. The forward-looking statements contained in this website reflect our
              current views with respect to future events, and we assume no obligation to update any forward -looking
              statements.
            </p>
            <p>
              The security of your data is important to us but remember that no method of transmission over the Internet
              or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to
              protect your Personal Data, we cannot guarantee its absolute security.
            </p>
            <p>
              <b>Service Providers</b>
            </p>
            <p>
              We may employ third party companies and individuals to facilitate our Service ("Service Providers" or
              “partners”)), to provide the Service on our behalf, to perform Service-related services or to assist us in
              analyzing how our Service is used.
            </p>
            <p>
              These third parties have access to your Personal Data only to perform these tasks on our behalf and are
              obligated not to disclose or use it for any other purpose.
            </p>
            <p>
              <b>Links to Other Sites</b>
            </p>
            <p>
              Our Service may contain links to other sites that are not operated by us. If you click a third party link,
              you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of
              every site you visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the content, privacy policies or practices of any
              third party sites or services.
            </p>
            <p>
              <b>Children's Privacy</b>
            </p>
            <p>Our Service does not address anyone under the age of 18 ("Children").</p>
            <p>
              We do not knowingly collect personally identifiable information from anyone under the age of 18. If you
              are a parent or guardian and you are aware that your Child has provided us with Personal Data, please
              contact us. If we become aware that we have collected Personal Data from children without verification of
              parental consent, we will take steps to remove that information from our servers.
            </p>
            <p>
              <b>Changes to This Privacy Policy</b>
            </p>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
              Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service,
              prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
              Your use of the Service indicates your consent to the privacy policy posted at the time of use. However,
              we will not treat your previously collected Personal Data, to the extent it is not collected under the new
              privacy policy, in a manner materially different than represented at the time it was collected without
              your consent. To the extent any provision of this Privacy Policy is found by a competent tribunal to be
              invalid or unenforceable, such provision shall be severed to the extent necessary for the remainder to be
              valid and enforceable.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
              are effective when they are posted on this page.
            </p>
            <p>
              Execution of this Privacy Policy authorizes the disclosure and use of my Personal Data. Your consent to
              the terms contained in this Privacy Policy shall continue until such time as you revoke it and notify us,
              in writing and submit it to the following mailing address Attn: PatientPartner, 1025 Prospect Street Suite
              350. La Jolla, California, 92037 or via email:{' '}
              <a href='mailto:contact@patientpartner.com'>contact@patientpartner.com</a>
            </p>
            <p>
              My revocation will take effect upon receipt, except to the extent that others have acted in reliance upon
              this authorization.
            </p>
            <p>I have a right to receive a copy of this authorization.</p>
            <p>
              <b>Contact Us</b>
            </p>
            <p>
              If you have any questions about this Privacy Policy, please contact us by email:{' '}
              <a href='mailto:contact@patientpartner.com'>contact@patientpartner.com</a>.
            </p>
          </section>
        </div>
        <div className='col-sm-1'></div>
      </div>
    </div>
  );
}
