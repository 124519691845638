import React, { useState, useContext } from 'react';
import AgreeToTerms from '../ui_kit/utilities/agree_to_terms';
import { createProviderApplicantContacts } from '../../api/provider_applicant_contacts';
import VideoType2 from '../video_sections/video_type_2';
import ScrollTo from '../scroll_to';
import { validateEmail } from '../../helpers';
import ErrorMessage from '../ui_kit/utilities/error_message';
import SuccessPanel from '../success_panel';
import LoadingScreen from '../loading_screen';
import styles from './index.module.css';
import TextLeftImgRightWbg from '../hero_sections/txt_left_img_right_wbg';
import TwoTestimonials from '../two_testimonials';
import FeaturedInCount from '../featured_in_count';
import Context from '../../utils/context';

export default function IndustryPartners({}) {
  const [email, setEmail] = useState('');
  const [acceptedTerms, setTerms] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  var checkedEmail = validateEmail(email);

  const setAcceptedTerms = () => {
    setTerms(!acceptedTerms);
    validate();
  };

  const validate = () => {
    if (checkedEmail === false && email !== '') {
      setEmailError('Please enter a valid email');
    } else {
      setEmailError('');
    }
  };

  const onSubmit = () => {
    setLoading(true);
    createProviderApplicantContacts(null, email, null, null, null, (response) => {
      setLoading(false);
      if (response === 200) {
        setSuccess(true);
      } else {
        setError('There was an error, please try again');
      }
    });
  };

  const downloadClick = () => {
    window.open(
      'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/PatientPartner_ReActiv8_CaseStudy.pdf'
    );
  };

  return (
    <div>
      <TextLeftImgRightWbg
        headline={'Patients sharing how your products changed their lives.'}
        para={
          'Learn how your patients can help grow the awareness of your products with prospective patients. Request a demo today with our team to learn more!'
        }
        bg={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/forprovidershero3.jpg'}
        bgColor={'#F1FEFF'}
        iconContent={''}
        thoseDots={''}
        backgroundSize={'contain'}
        mobileBackgroundSize={'contain'}
        heightOfImage={''}
        shortenHeaderImgCol={true}
        showRightImgOnMobile={true}
        imgOffset={false}
      />
      <div style={{ backgroundColor: '#F1FEFF' }}>
        <div className='container'>
          <div className='row'>
            <div className={`col-lg-6 col-md-5 ${styles.de_pad}`}>
              <div id='sign-up' className={`${styles.move_me_up} last-on-mobile`}>
                <div className={`sign-up-otr ${styles.floatbox}`}>
                  {!success && (
                    <>
                      <div className={`material-input marg-top-0 ${styles.fulwid}`}>
                        <input
                          type='text'
                          value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                          }}
                          onBlur={() => validate()}
                          required
                        />
                        <span class='highlight'></span>
                        <span class='bar'></span>
                        <label>Email</label>
                      </div>
                      {emailError && <ErrorMessage error={emailError} />}
                      <AgreeToTerms acceptedTerms={acceptedTerms} setAcceptedTerms={setAcceptedTerms} />
                      <button
                        disabled={checkedEmail && acceptedTerms === true ? '' : 'disabled'}
                        onClick={() => onSubmit()}
                        className='marg-top-20'
                        style={{ width: '100%' }}
                        data-gtm-click-label='For Providers Submit'
                      >
                        Request a demo
                      </button>
                      {error && <ErrorMessage error={error} />}
                    </>
                  )}
                  {loading && (
                    <>
                      <LoadingScreen />
                    </>
                  )}
                  {success && (
                    <>
                      <SuccessPanel title='Thank you!' content='Our team will contact you in the next 24-48 hours.' />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FeaturedInCount
        colMdClass={`col-md-3`}
        title={`Customer Count`}
        imagesWithLinks={[
          {
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featuredin1-removebg-preview.png',
            link: 'https://patientpartner.com/mentor-match/reactiv8',
          },
          {
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featuredin2-removebg-preview.png',
            link: 'https://www.costplusdrugs.com/',
          },
          {
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featuredin3-removebg-preview.png',
            link: 'https://patientpartner.com/procedures/uterine-fibroids',
          },
        ]}
      />

      <div className='container'>
        <div className='row'>
          <div className={`col-lg-6 col-md-5 ${styles.de_pad}`}>
            <img
              className={styles.dr_pic}
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/forproviderimg.png'
              alt='doctor-with-stethoscope'
            />
          </div>
          <div className='col-lg-1 col-md-1'></div>
          <div className={`col-lg-5 col-md-6 ${styles.grow_smarter} ${styles.de_pad}`}>
            <div className='container-fluid'>
              <div className={`col ${styles.de_pad}`}>
                <div className='row'>
                  <div className={`col ${styles.de_pad}`}>
                    <h2 style={{ marginBottom: '5px' }} className={styles.headingicons}>
                      Grow smarter:
                    </h2>
                  </div>
                </div>
                <div className='row'>
                  <div className={`col ${styles.de_pad}`}>
                    <div className={styles.side_col}>
                      <div className='row h-100 marg-top-30'>
                        <div className='col-md-3 my-auto'>
                          <img
                            className={styles.icon}
                            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/peopleclose.svg'
                          />
                        </div>
                        <div className='col-md-9'>
                          <h6 className={`${styles.heading6} ${styles.iconheads}`}>Measurable impact</h6>
                          <p className='marg-top-5'>
                            Track how mentors can help support prospective patients throughout the healthcare journey
                          </p>
                        </div>
                      </div>
                      <div className='row h-100 marg-top-30'>
                        <div className='col-md-3 my-auto'>
                          <img
                            className={styles.icon}
                            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/broaderreachicon1.svg'
                          />
                        </div>
                        <div className='col-md-9'>
                          <h6 className={`${styles.heading6} ${styles.iconheads}`}>Data driven insights</h6>
                          <p className='marg-top-5'>
                            Obtain a deeper understanding on what patients really care about.
                          </p>
                        </div>
                      </div>
                      <div className='row h-100 marg-top-30'>
                        <div className='col-md-3 my-auto'>
                          <img
                            className={styles.icon}
                            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/framed.svg'
                          />
                        </div>
                        <div className='col-md-9'>
                          <h6 className={`${styles.heading6} ${styles.iconheads}`}>Navigation to your physicians</h6>
                          <p className='marg-top-5'>
                            Carving a simple pathway to help patients find your trusted physicians.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: '70px', backgroundColor: '#F1FEFF' }} className='container-fluid'>
        <div className='container'>
          <div className='row'>
            <TwoTestimonials
              testimonials={[
                {
                  text: 'PatientPartner has be influential in helping patients understand the benefits of our product.',
                  image:
                    'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/Brad+Anderson+Head+Shot.jpeg',
                  name: 'Brad A. (Mainstay Medical)',
                },
                {
                  text: 'It is great to see industry investing in the patient experience and providing new technology to their customers.',
                  image:
                    'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/Dr.%2BJon%2BWilliams%2Bpic.jpeg',
                  name: 'Dr Jon Williams',
                },
              ]}
              bg={''}
              headline={'What our customers are saying'}
            />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#F2F2F2', paddingTop: '0px', paddingBottom: '80px' }} className='container-fluid'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-2'></div>
            <div className='col-lg-8'>
              <h2 className='text-center marg-top-50'>How it works</h2>
              <p className='text-center marg-top-10 marg-bottom-60'>
                We’re accepting applications for new providers across any medical
                <br /> procedure category.
              </p>
            </div>
            <div className='col-lg-2'></div>
          </div>
          <div className='row'>
            <div className={`col-lg-4 ${styles.colpad}`}>
              <div style={{ marginTop: '0px' }} className={styles.numbub}>
                <h2 className={styles.numbubtxt}>1</h2>
              </div>
              <h3 className={`text-center ${styles.heading3}`}>Learn</h3>
              <p className='marg-bottom-20 text-center'>
                Schedule a call with our team, so we can better understand your business and share more about the
                PatientPartner solution.
              </p>
            </div>
            <div className={`col-lg-4 ${styles.colpad}`}>
              <div className={styles.numbub}>
                <h2 className={styles.numbubtxt}>2</h2>
              </div>
              <h3 className={`text-center ${styles.heading3}`}>Launch</h3>
              <p className='marg-bottom-20 text-center'>
                Onboarding takes less than 1 month! The PatientPartner team will work in tandem with your organization
                to insure a fluid launch.
              </p>
            </div>
            <div className={`col-lg-4 ${styles.colpad}`}>
              <div className={styles.numbub}>
                <h2 className={styles.numbubtxt}>3</h2>
              </div>
              <h3 className={`text-center ${styles.heading3}`}>Scale</h3>
              <p className='marg-bottom-20 text-center'>
                Easily scale PatientPartner across your business all around the world.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <button
                data-gtm-click-label='For Providers Start my application'
                className='marg-center marg-top-30'
                onClick={() => ScrollTo('#sign-up', 'initial')}
              >
                Request a demo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: '50px', overflow: 'hidden' }} className='container mentor-video-section'>
        <div className='row'>
          <div className='col'>
            <h2 style={{ color: '#314D69' }} className={styles.vidheadline}>
              See how PatientPartner can help your practice...
            </h2>
          </div>
        </div>
        <div class='row'>
          <div class='col'>
            <button
              data-gtm-click-label='For Providers Start my application'
              onClick={downloadClick}
              className='marg-center marg-top-30'
            >
              Download a case study
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
