import React, { useState, useEffect } from 'react';
import VideoType2 from '../video_type_2';
import styles from './index.module.css';
import { useIntersection } from 'react-use';
import ScrollTrigger from '../../ui_kit/utilities/scroll_trigger';

export default function SingleVideoCenteredWDecor({
  title = 'How PatientPartner can help you',
  videoBg = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/forsurgeons-thunmbnail.jpg',
  videoLink = 'https://www.youtube.com/watch?v=APzRUnur-Sw',
  gtm = 'Home Video Watch',
  bgColor = '#fff',
  para = '',
}) {
  const [animate, setAnimate] = useState(false);

  return (
    <div
      style={bgColor ? { backgroundColor: bgColor, paddingTop: '90px', paddingBottom: '90px', overflow: 'hidden' } : {}}
      className='container-fluid'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-lg-2'></div>
          <div className='col-lg-8'>
            <h4 className={`${animate ? styles.animate : styles.animate_base} ${styles.title}`}>{title}</h4>
            <h3 className={`${animate ? styles.animate : styles.animate_base} ${styles.para}`}>{para}</h3>
            <div style={{ position: 'relative' }}>
              <div
                style={{ position: 'relative', zIndex: '2' }}
                className={`${animate ? styles.animate : styles.animate_base}`}
              >
                <ScrollTrigger delay={400} callback={() => setAnimate(true)} />
                <VideoType2 bg={videoBg} videoLink={videoLink} gtm={gtm} height='390px' />
              </div>
              <svg
                className={styles.svg1}
                width='57'
                height='113'
                viewBox='0 0 57 113'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M56.5 113C41.5153 113 27.1443 107.047 16.5485 96.4515C5.95266 85.8557 1.98376e-06 71.4847 6.73756e-07 56.5C-6.36251e-07 41.5153 5.95266 27.1443 16.5485 16.5485C27.1443 5.95267 41.5153 3.11508e-06 56.5 6.73757e-07L56.5 56.5L56.5 113Z'
                  fill='#314D69'
                />
              </svg>
              <svg
                className={styles.svg2}
                width='259'
                height='208'
                viewBox='0 0 259 208'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M239.812 0.326238C248.879 16.0304 254.764 33.3667 257.131 51.3452C259.498 69.3237 258.3 87.5923 253.607 105.108C248.914 122.624 240.816 139.044 229.777 153.43C218.738 167.816 204.974 179.888 189.269 188.954C173.565 198.021 156.229 203.906 138.251 206.273C120.272 208.64 102.003 207.442 84.4876 202.749C66.9719 198.056 50.5521 189.958 36.1657 178.919C21.7793 167.88 9.70813 154.116 0.641313 138.412L120.227 69.3689L239.812 0.326238Z'
                  fill='#59B6BE'
                />
              </svg>
              <img
                alt='decorative dots'
                className={styles.svg3}
                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/svgdots.svg'
              />
            </div>
          </div>
          <div className='col-lg-2'></div>
        </div>
      </div>
    </div>
  );
}
