import React from 'react';

export default function RealselfHeader() {
  const scrollToQuestionnaire = () => {
    document.querySelector('#questionnaire').scrollIntoView({
      behavior: 'smooth',
    });
    const timer = setTimeout(() => {
      autofocusAll();
    }, 1000);
    return () => clearTimeout(timer);
  };

  return (
    <header className='no-shadow'>
      <div className='container'>
        <div className='header-outr'>
          <div style={{ padding: '15px 0px' }}>
            <img
              alt='logo'
              id='logo'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/logos/jpg/PPrealself-logo.jpg'
              data-gtm-click-label='Nav Logo Embedded/iFrame'
            />
          </div>
          <div className='hdr-btn-innr btn'>
            <button
              onClick={scrollToQuestionnaire}
              data-gtm-click-label='Tummy Tuck Patient Mentor Landing hero section CTA Get Started'
            >
              Get started
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
