import React, { useState, useContext } from 'react';
import styles from './index.module.css';
import Context from '../../../utils/context';
import { Link } from 'react-router-dom';
import ScrollTrigger from '../../ui_kit/utilities/scroll_trigger';

export default function ImgRightTextLeft({
  headline = 'Your partner through surgery',
  para = 'Join a community of similar patients and verified doctors to support you every step of the way.',
  img = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/patient_partner_hero_a.jpg',
  margBottom = '0px',
  bg = '#f1feff',
  headlineColor = '#314D69',
  marginTopPhotoMobile = '0px',
  actionContent = (
    <Link to='/member/join/support'>
      <button style={{ padding: '12px 20px' }} data-gtm-click-label='Home Sign up for free'>
        Sign up for free
      </button>
    </Link>
  ),
  padImg = true,
  padLeft = '0px',
}) {
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile();
  const [animateLeft, setAnimateLeft] = useState(false);
  const [animateRight, setAnimateRight] = useState(false);

  return (
    <div style={{ backgroundColor: bg, padding: '50px 0px' }} className={`container-fluid ${styles.hero_container}`}>
      <div className='container'>
        <div className='row'>
          <div style={{ display: 'flex', padding: padLeft }} className={`col-lg-6`}>
            <ScrollTrigger callback={() => setAnimateRight(true)} />
            <div
              style={{ alignSelf: 'center', height: 'max-content' }}
              className={`${animateLeft ? styles.animate : styles.animate_base}`}
            >
              <h1 style={{ marginBottom: margBottom, color: headlineColor }} className={styles.heading1}>
                {headline}
              </h1>
              <h2 className={`marg-bottom-20 marg-top-20 ${styles.para}`}>{para}</h2>
              {actionContent}
            </div>
          </div>
          <div className={`col-lg-6 ${padImg ? 'de-pad' : ''} align-items-end`}>
            <ScrollTrigger callback={() => setAnimateLeft(true)} />
            <img
              style={{ display: 'block', marginTop: isMobile ? marginTopPhotoMobile : '' }}
              className={`${animateRight ? styles.animate_right : styles.animate_base}`}
              src={img}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
