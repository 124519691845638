import React, { useState, Suspense, useContext } from 'react';
import './weight_loss_landing.css';
import ModalWrapper from '../../modal';
import Oncehub from '../../oncehub';
const PatientPartnerQuestionnaire = React.lazy(() => import('../../questionnaire/patient_partner'));
import PatientPartnerCarousel from '../../patient_partner_carousel';
import Testimonials from '../../testimonials';
import FeaturedInMedia from '../../featured_in_media';
import HowItWorks from '../../how_it_works';
import { contactSync } from '../../../api/contacts';
import { autofocusAll } from '../../../helpers';
import TextLeftImgRightWbg from '../../hero_sections/txt_left_img_right_wbg';
import ImgLeftTextRight from '../../hero_sections/img_left_text_right';
import EmailSignupFooter from '../../email_signup_footer';
import Context from '../../../utils/context';

export default function WeightLoss({
  patientPartners = [
    {
      image:
        'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss/patient-partner-claudia.jpg',
      name: 'Claudia',
      extra: {
        age: '34',
        weight_loss: '80',
      },
      bio: "At age 19, Claudia made the decision to to begin the weight-loss surgical journey. Claudia was ready to take control of her health and researched weight-loss surgery and the requirements needed to qualify for surgery. She took the time to mentally prepare for the recovery process and was grateful to have a friend who also had surgery and could lean on for emotional support. Despite the challenges Claudia experienced throughout recovery, she'd go through the surgery 10x over. Small but invaluable accomplishments such as being able to go up and down the stairs without the fear of falling is worth it.",
    },
    {
      image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss/patient-partner-gina.jpg',
      name: 'Gina',
      extra: {
        age: '64',
        weight_loss: '104',
      },
      bio: "Gina turned to food as a coping mechanism for her depression, which led to weight gain and low self-esteem. She suffered from migraines and high blood pressure and she was afraid of dying at a young age because of her weight. As a wife and mother of two, Gina made the decision to have a sleeve gastrectomy for her family and to regain control and happiness in her life. Since her surgery, Claudia has lost nearly 200 pounds and no longer experiences migraines or high blood pressure. Exercising daily is a large part of Gina's lifestyle now and she is happier than ever. Gina feels that having a respectful, nonjudgemental doctor who takes the time to listen to your concerns is critical to a successful surgical journey.",
    },
    {
      image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss/patient-partner-jenny.jpg',
      name: 'Jenny',
      extra: {
        age: '35',
        weight_loss: '98',
      },
      bio: 'After Jenny\'s brother and only sibling passed away from Cystic Fibrosis, she decided to make a significant change in her life and health. Jenny experienced chronic pain in her feet due to her weight and began developing sleep apnea. The pain would limit her activity for days at a time and she’d be fatigued from lack of sleep. While she always felt confident in her body and had high self-esteem, she knew that she had to make a change to improve her health. Jenny feels the non-scale victories, such as being able to tie her shoes and cross her legs, are the most important to celebrate. While there continue to be good days and "bad days", Jenny has no regrets for her decision and feels her journey has helped shape who she is today.',
    },
  ],
  home,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setContactIdAction,
  setPatientPartners,
}) {
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();
  const [showModal, setShowModal] = useState(false);

  const scrollToQuestionnaire = (status) => {
    if (isNaN(status)) {
      status = 1;
    }
    document.querySelector('#questionnaire').scrollIntoView({
      behavior: 'smooth',
    });
    const timer = setTimeout(() => {
      autofocusAll();
    }, 1000);
    return () => clearTimeout(timer);
  };

  const viewScheduleModal = (patientPartner, gtmEvent) => () => {
    // onScheduled is called
    // super hack... for some reason, weightLoss.answers is frozen to be the default state when
    window.answers = home.globalAnswers;
    window.patient_partner = patientPartner;
    window.scheduleModalGtmEvent = gtmEvent;

    setShowModal(true);
  };

  const onScheduled = (data) => {
    createScheduleContact(data.bookingInfo.CustomerName, data.bookingInfo.CustomerEmail);
    setShowModal(false);
    scrollToQuestionnaire(199);
    fireGtmEvent(window.scheduleModalGtmEvent);
  };

  const createScheduleContact = (name, email) => {
    contactSync(name, email, window.answers, null, true, 'Weight Loss', window.patient_partner.name);
  };

  return (
    <div className='weight-loss'>
      <TextLeftImgRightWbg
        headline={'Learn from someone like you'}
        para={
          'Considering weight-loss surgery? Talk to a patient who’s recovered from surgery and understands what you’re going through for free!'
        }
        bg={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/bariatric-patient-partner.jpg'}
        actionContent={
          <div className='btn-wrap'>
            <button
              onClick={scrollToQuestionnaire}
              className='maincta'
              data-gtm-click-label='Weight loss landing hero section CTA Get Started'
            >
              Get started
            </button>
          </div>
        }
        imgOffset={false}
        imgShadow={true}
        topOfIcon={'75%'}
        heightOfImage={isMobile ? '300px' : '500px'}
        iconContent={
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 132.05 163.45'>
            <g id='Layer_2' data-name='Layer 2'>
              <g id='Layer_1-2' data-name='Layer 1'>
                <path
                  fill='#74ccd3'
                  className='cls-1'
                  d='M131.9,78.63V38.83c0-5.75-.43-11.32-2.66-16.73A36,36,0,0,0,108.2,2C103.11.19,97.89,0,92.57,0H37.07C19.79,0,3.91,11.21.67,28.77-.11,33,0,37.29,0,41.59V61.06C0,72.67,0,84.29,0,95.9c.08,19.16,15.19,35.35,34.54,35.94,2.44.08,4.87,0,7.31,0l.25,0a3,3,0,0,0,2.7-1.5l.12-.13h0L68,99.44c3.92-.87,7.16-4,9.32-7.57C83.79,81.23,89.81,70.31,96,59.54c2.88-5,6.84-12.13,3.58-17.86-2.52-4.47-8.3-4.24-13.27-4a6.26,6.26,0,1,0,.09,6.69h1.1c1.58,0,5.4-.81,6.45.57s-.46,5.17-1.06,6.55a49.19,49.19,0,0,1-2.78,5.15L73.91,84.77c-1.7,2.93-3.61,7.87-7.4,8.37-3.94.52-6.06-4.15-7.69-7l-16.18-28c-1.87-3.24-6.08-8.58-4.58-12.46.87-2.26,4.84-1.28,6.77-1.28h.23a6.25,6.25,0,0,0,11.59-3.26h0a6.25,6.25,0,0,0-11.59-3.26H43.71c-3.09,0-6.29-.29-8.93,1.61-5.19,3.71-3.41,11.19-1.1,16,2.43,5.11,5.63,9.94,8.46,14.83L52.58,88.43c2.15,3.72,4.36,7.81,8.18,9.9l-20.25,27c-8,0-15.43-.65-22.22-5.68A29.34,29.34,0,0,1,6.57,96c-.1-12.3,0-24.6,0-36.9V39.68c0-5.9.25-11.59,3-17A29.4,29.4,0,0,1,35.49,6.54c3.51,0,7,0,10.52,0H89.68c4.63,0,9.34-.27,13.86.89a29.34,29.34,0,0,1,21.14,21.79c1,4.36.68,9,.68,13.44V64c0,11.24.45,22.57,0,33.81a29.25,29.25,0,0,1-17.26,25.11c-7.5,3.34-14.6,1.66-22.39,2.44-2.32.24-3.06,1.54-4.14,3.29l-4.73,7.68L65.62,154.48l-13-16.71c-2.58-3.31-7.16,1.34-4.62,4.62l15.68,20.08c1.35,1.73,4.14.95,5.13-.65L87.22,131.9c5.73.07,11.63.36,17.21-.92a35.81,35.81,0,0,0,23.17-17.71C133.31,102.72,131.9,90.2,131.9,78.63Z'
                />
              </g>
            </g>
          </svg>
        }
      />
      <FeaturedInMedia />
      <ImgLeftTextRight
        img={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/bariactric-surgery-pa.jpg'}
        headline={'Connecting you to real patients'}
        subheadline={'Talk with a PatientPartner™ for free:'}
        list={[
          <span>
            See how you can take <b>control of your health</b> with weight-loss surgery
          </span>,
          <span>
            Get <b>emotional support</b> when friends or family is not an option
          </span>,
          <span>Discover what life is like after surgery</span>,
          <span>
            Understand pain level and recovery time from <b>personal insight</b>
          </span>,
          <span>Receive practical advice, tips, and ask personal questions</span>,
        ]}
        cta={
          <button
            data-gtm-click-label='Weight loss landing Connecting you to real patients Get connected'
            className='gtm-wl-get-connected'
            onClick={scrollToQuestionnaire}
          >
            Get connected
          </button>
        }
      />
      <Testimonials
        testimonials={[
          {
            name: 'Angela',
            bio: 'Talking to a PatientPartner™ before going through surgery was extremely helpful and I’m so glad I found this resource.',
            photo: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/angela.jpg',
          },
          {
            name: 'Mario',
            bio: 'Talking to a PatientPartner™ helped me address uncertainties I was having and helped me find real answers I wasn’t able to get anywhere else.',
            photo: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/mario.jpg',
          },
          {
            name: 'Natasha',
            bio: 'It was so nice to have a PatientPartner™. She was very positive and supportive and made me feel secure in my decisions.',
            photo: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/natasha.jpg',
          },
        ]}
        lilsubtitle={'testimonials'}
        headline={'What others are saying'}
      />
      <HowItWorks
        bublets={[
          {
            icon: (
              <svg width='359' height='344' viewBox='0 0 359 344' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M314.125 22.4375C320.076 22.4375 325.783 24.8014 329.991 29.0093C334.199 33.2171 336.562 38.9242 336.562 44.875V224.375C336.562 230.326 334.199 236.033 329.991 240.241C325.783 244.449 320.076 246.812 314.125 246.812H99.0391C87.1385 246.815 75.7262 251.545 67.3125 259.961L22.4375 304.836V44.875C22.4375 38.9242 24.8014 33.2171 29.0093 29.0093C33.2171 24.8014 38.9242 22.4375 44.875 22.4375H314.125ZM44.875 0C32.9734 0 21.5593 4.72789 13.1436 13.1436C4.72789 21.5593 0 32.9734 0 44.875L0 331.918C0.000471521 334.138 0.659496 336.308 1.89366 338.153C3.12783 339.998 4.88166 341.435 6.93317 342.283C8.98469 343.131 11.2417 343.352 13.4184 342.917C15.5952 342.481 17.5939 341.41 19.1616 339.838L83.1758 275.824C87.3827 271.616 93.0888 269.251 99.0391 269.25H314.125C326.027 269.25 337.441 264.522 345.856 256.106C354.272 247.691 359 236.277 359 224.375V44.875C359 32.9734 354.272 21.5593 345.856 13.1436C337.441 4.72789 326.027 0 314.125 0L44.875 0Z'
                  fill='black'
                />
                <path
                  d='M67.3125 78.5312C67.3125 75.5559 68.4945 72.7023 70.5984 70.5984C72.7023 68.4945 75.5559 67.3125 78.5312 67.3125H280.469C283.444 67.3125 286.298 68.4945 288.402 70.5984C290.506 72.7023 291.688 75.5559 291.688 78.5312C291.688 81.5066 290.506 84.3602 288.402 86.4641C286.298 88.568 283.444 89.75 280.469 89.75H78.5312C75.5559 89.75 72.7023 88.568 70.5984 86.4641C68.4945 84.3602 67.3125 81.5066 67.3125 78.5312ZM67.3125 134.625C67.3125 131.65 68.4945 128.796 70.5984 126.692C72.7023 124.588 75.5559 123.406 78.5312 123.406H280.469C283.444 123.406 286.298 124.588 288.402 126.692C290.506 128.796 291.688 131.65 291.688 134.625C291.688 137.6 290.506 140.454 288.402 142.558C286.298 144.662 283.444 145.844 280.469 145.844H78.5312C75.5559 145.844 72.7023 144.662 70.5984 142.558C68.4945 140.454 67.3125 137.6 67.3125 134.625ZM67.3125 190.719C67.3125 187.743 68.4945 184.89 70.5984 182.786C72.7023 180.682 75.5559 179.5 78.5312 179.5H190.719C193.694 179.5 196.548 180.682 198.652 182.786C200.756 184.89 201.938 187.743 201.938 190.719C201.938 193.694 200.756 196.548 198.652 198.652C196.548 200.756 193.694 201.938 190.719 201.938H78.5312C75.5559 201.938 72.7023 200.756 70.5984 198.652C68.4945 196.548 67.3125 193.694 67.3125 190.719Z'
                  fill='black'
                />
              </svg>
            ),
            head: 'Tell us about yourself',
            para: 'Take a short questionnaire to find the information you are looking for',
          },
          {
            icon: (
              <svg viewBox='0 0 45 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M14.8182 32.8889V32.3889H14.3182H4.09091C2.10054 32.3889 0.5 30.7667 0.5 28.7778V4.11111C0.5 2.10326 2.09892 0.5 4.09091 0.5H40.9091C42.8821 0.5 44.4998 2.10467 44.5 4.1107C44.5 4.11083 44.5 4.11097 44.5 4.11111L44.4795 28.7774V28.7778C44.4795 30.7683 42.8774 32.3889 40.9091 32.3889H30.6818H30.1818V32.8889V36.5H14.8182V32.8889ZM40.9091 29.2778H41.4091V28.7778V4.11111V3.61111H40.9091H4.09091H3.59091V4.11111V28.7778V29.2778H4.09091H40.9091Z'
                  fill='#203856'
                  stroke='white'
                />
                <path
                  d='M30.625 23.725H30.725V23.625V22.25C30.725 22.1119 30.6428 21.9722 30.5238 21.8405C30.402 21.7056 30.2273 21.5634 30.0112 21.4192C29.5784 21.1303 28.9662 20.824 28.2407 20.5442C26.7896 19.9845 24.8711 19.525 23 19.525C21.1289 19.525 19.2104 19.9845 17.7593 20.5442C17.0338 20.824 16.4216 21.1303 15.9888 21.4192C15.7727 21.5634 15.598 21.7056 15.4762 21.8405C15.3572 21.9722 15.275 22.1119 15.275 22.25V23.625V23.725H15.375H30.625ZM25.725 11C25.725 9.49477 24.5052 8.275 23 8.275C21.4948 8.275 20.275 9.49477 20.275 11C20.275 12.5052 21.4948 13.725 23 13.725C24.5052 13.725 25.725 12.5052 25.725 11ZM18.1 11C18.1 8.29273 20.2927 6.1 23 6.1C25.7073 6.1 27.9 8.29273 27.9 11C27.9 13.7073 25.7073 15.9 23 15.9C20.2927 15.9 18.1 13.7073 18.1 11ZM13.1 22.25C13.1 21.4555 13.4972 20.7521 14.161 20.1395C14.8254 19.5262 15.7503 19.0107 16.7856 18.596C18.8564 17.7664 21.3438 17.35 23 17.35C24.6562 17.35 27.1436 17.7664 29.2144 18.596C30.2497 19.0107 31.1746 19.5262 31.839 20.1395C32.5028 20.7521 32.9 21.4555 32.9 22.25V25.9H13.1V22.25Z'
                  fill='#203856'
                  stroke='white'
                  strokeWidth='0.2'
                />
              </svg>
            ),
            head: 'Review your recommendations',
            para: 'Learn more about a surgeon in your area that accepts your insurance or a recovered patient that’s ready to walk you through your surgery.',
          },
          {
            icon: (
              <svg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M11.0473 7.79351L11.0451 7.78656L11.0429 7.77965C10.5827 6.38687 10.3333 4.89273 10.3333 3.33333C10.3333 2.04772 9.28562 1 8 1H3.33333C2.04772 1 1 2.04772 1 3.33333C1 16.4056 11.5944 27 24.6667 27C25.9523 27 27 25.9523 27 24.6667V20.0133C27 18.7277 25.9523 17.68 24.6667 17.68C23.1218 17.68 21.6159 17.431 20.2248 16.9719C19.3973 16.6909 18.4671 16.9053 17.8396 17.5329L15.4313 19.9412C12.3428 18.2093 9.77856 15.6534 8.05753 12.57L10.4671 10.1604C11.1154 9.51214 11.3007 8.59979 11.0473 7.79351Z'
                  stroke='#314D69'
                  strokeWidth='2'
                />
              </svg>
            ),
            head: 'Request a connection',
            para: 'Select your preferred communication method (phone call, text, email) and request to connect with your matched PatientPartner',
          },
        ]}
      />
      <div className='container pad-cont text-center'>
        <div className='container-fluid logo-row'>
          <div className='row free-for-you'>
            <div className='col-sm-12'>
              <h2 className='marg-top-40 marg-bottom-20 headliner'>Why is this free for you?</h2>
              <p className='marg-bottom-50'>
                Our PatientPartner mentors recognize how important it is to have a support network while going through
                surgery. They're passionate about the success of their results and want to help others get the support
                they didn't have while going through surgery.
                <br />
                <br />
                PatientPartner is made possible by hospitals and healthcare companies around the country to ensure
                patients are guaranteed a better patient experience. In turn, we give back to the community by donating
                to the charity of the mentors' choice for each connection. Join us in our circle of giving and support!
              </p>
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                <h5 className='marg-bottom-20'>Foundations we donate to:</h5>
              </div>
            </div>
            <div className='row h-100 pad-bottom-20'>
              <div className='col-md-1'></div>
              <div className='col-6 col-md-2 my-auto'>
                <img
                  alt='forbes-logo'
                  className='fi-image'
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss/Arthritis+Society.jpg'
                />
              </div>
              <div className='col-6 col-md-2 my-auto'>
                <img
                  alt='forbes-logo'
                  className='fi-image'
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss/CF+foundation.jpg'
                />
              </div>
              <div className='col-6 col-md-2 my-auto'>
                <img
                  alt='sd-business-journal-logo'
                  className='fi-image'
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss/MS.jpg'
                />
              </div>
              <div className='col-6 col-md-2 my-auto'>
                <img
                  alt='abc-logo'
                  className='fi-image'
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss/St+jude.jpg'
                />
              </div>
              <div className='col-6 col-md-2 my-auto'>
                <img
                  alt='abc-logo'
                  className='fi-image'
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss/aspca.jpg'
                />
              </div>
              <div className='col-md-1'></div>
            </div>
            <div className='row'>
              <div className='col'>
                <h5 className='marg-top-30 marg-bottom-20'>Bariatric PatientPartner Program in partnership with:</h5>
              </div>
            </div>
            <div className='row h-100 pad-bottom-20'>
              <div className='col-md-4'></div>
              <div className='col-md-4 my-auto'>
                <img
                  style={{ width: '100px' }}
                  alt='Medtronic Logo'
                  className='fi-image'
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss/medtroniclogo-1.jpg'
                />
              </div>
              <div className='col-md-4'></div>
            </div>
          </div>
        </div>
      </div>
      <div className='pp-sec container pad-cont'>
        <div className='row justify-content-center h-100'>
          <div className='col-sm-12 col-md-4 my-auto leftSide'>
            <div className='cont'>
              <h2 className='marg-bottom-30'>Verified PatientPartners&trade; you can trust</h2>
              <h5 className='ul-headline'>Feel confident in knowing:</h5>
              <ol className='marg-bottom-30'>
                <li>
                  <span>Each PatientPartner&trade; is interviewed and screened</span>
                </li>
                <li>
                  <span>PatientPartners&trade; volunteer their time to give you the support you need</span>
                </li>
                <li>
                  <span>The PatientPartner&trade; team is with you every step of the way</span>
                </li>
              </ol>
              <button
                data-gtm-click-label='Weight loss landing match me to a PatientPartner™'
                className='marg-top-20 gtm-wl-match-me-to-patient-partner'
                onClick={scrollToQuestionnaire}
              >
                Match me to a PatientPartner&trade;
              </button>
            </div>
          </div>
          <div className='col-md-8 rightSide'>
            <PatientPartnerCarousel
              showWeightLoss={true}
              setSlidesToShow={2}
              patientPartners={patientPartners}
              actionContentFunc={(partner) => null}
            />
          </div>
        </div>
      </div>
      {showModal && (
        <ModalWrapper
          className='pp-modal'
          content={
            <Oncehub
              divid={gon.patient_partner_divid}
              page={gon.patient_partner_page}
              onScheduled={onScheduled}
              params={{
                name: weightLoss.answers.name,
                email: weightLoss.answers.email,
                'Requested PatientPartner': window.patient_partner.name,
              }}
            />
          }
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
      <div
        id='questionnaire'
        className='container-fluid pad-cont'
        style={{
          backgroundColor: 'rgb(245, 247, 249)',
          alignContent: 'center',
          padding: '0px',
          borderRadius: '8px',
          width: '96%',
        }}
      >
        <div className='row justify-content-center h-100'>
          <div className='col my-auto'>
            <Suspense fallback={<p>loading</p>}>
              <PatientPartnerQuestionnaire
                globalAnswers={home.answers}
                setGlobalAnswers={updateAnswersAction}
                progress={home.patientPartnerQuestionnaireProgress}
                setProgress={setPatientPartnerQuestionnaireProgress}
                contactId={home.contactId}
                setContactIdAction={setContactIdAction}
                patientPartners={home.patientPartners}
                setPatientPartners={setPatientPartners}
                predefinedProcedures={['Weight Loss']}
                predefinedSurgeries={["I'm not sure"]}
                directConnection={true}
                gtm={`Weight Loss`}
                origin={`Weight Loss`}
              />
            </Suspense>
          </div>
        </div>
      </div>
      <EmailSignupFooter
        headline={'Want to learn more about weight loss surgery?'}
        para={'Get our guide to bariatric surgery and learn about your options, the benefits, FAQs, and more!'}
        successMessage={'Check your inbox for information about bariatric surgery!'}
        type={'DownloadGuideContacts::WeightLoss'}
        gtm={'weight loss landing lead magnet send now'}
      />
    </div>
  );
}
