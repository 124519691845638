import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import MentorCard from '../../../../../ui_kit/utilities/mentor_card';
import parent_styles from '../../../../index.module.css';
import Context from '../../../../../../utils/context';

export default function AssociatedMentors({ mentors, slice = false }) {
  const context = React.useContext(Context);
  let { path } = useRouteMatch();
  path = path.replace(`:id`, context.auth.getCurrentUser().slug);

  if (slice) {
    mentors = mentors.slice(0, 2);
  }

  return (
    <div>
      {slice && (
        <div style={{ display: 'flex' }}>
          <p className={parent_styles.profile_section_headline}>
            Associated mentors <span style={{ color: '#828282', fontWeight: 600 }}>{mentors.length}</span>
          </p>
          <Link style={{ marginLeft: 'auto' }} to={`${path}/associated-mentors`}>
            <p style={{ textDecoration: 'none' }} className='text-link no-arrow'>
              See all mentors
            </p>
          </Link>
        </div>
      )}
      {mentors.map((m, i) => (
        <MentorCard key={i} mentor={m} />
      ))}
    </div>
  );
}
