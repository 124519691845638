import React, { useState, useEffect, useContext } from 'react';
import IsScrolling from 'react-is-scrolling';
import { useTransition, useChain, animated, useSpringRef } from '@react-spring/web';
import Skeleton from '../../ui_kit/utilities/skeleton';
import Pagination from '../../ui_kit/utilities/pagination';
import { getSurgeonRoster } from '../../../api/surgeon_administrative_assistants';
import styles from '../patients/mentor_connect/index.module.css';

function PhysicianRoster() {
  const [view, setView] = useState('loading');
  const [surgeons, setSurgeons] = useState();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [sortBy, setSortBy] = useState('');

  useEffect(() => {
    getSurgeonRoster((results) => {
      setSurgeons(results);
      setView('loaded');
    });
  }, []);

  let surgeonsSorted = [];
  if (surgeons !== null) {
    surgeonsSorted = surgeons;
  }

  const tableSkeletonAnimation = useSpringRef();
  const tableSkeleton = useTransition(
    view === 'loading' ? [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}] : surgeons.slice(min, max),
    {
      ref: tableSkeletonAnimation,
      trail: 500 / 15,
      config: { mass: 0.5, tension: 200, friction: 20 },
      from: { opacity: 0, transform: 'translateX(50px)' },
      enter: { opacity: 1, transform: 'translateX(0px)', position: 'relative' },
    }
  );
  useChain([tableSkeletonAnimation]);

  const setSortByWrapper = (type) => {
    if (sortBy === type) {
      setSortBy(`${type}-desc`);
    } else if (sortBy === `${type}-desc`) {
      setSortBy('');
    } else if (sortBy !== type) {
      setSortBy(type);
    }
  };

  const sortByName = (arr) => {
    function compare(a, b) {
      var splitA = a.name.split(' ');
      var splitB = b.name.split(' ');
      var lastA = splitA[splitA.length - 1];
      var lastB = splitB[splitB.length - 1];

      if (lastA < lastB) return -1;
      if (lastA > lastB) return 1;
      return 0;
    }

    return [...arr].sort(compare);
  };

  const sortByAddress = (arr) => {
    function compare(a, b) {
      var x = a.office_locations.map((l) => l.address).join(',');
      var y = b.office_locations.map((l) => l.address).join(',');

      if (x < y) return -1;
      if (x > y) return 1;
      return 0;
    }

    return [...arr].sort(compare);
  };

  const sortByEmail = (arr) => {
    function compare(a, b) {
      var x = a.poc_emails.join(',');
      var y = b.poc_emails.join(',');

      if (x < y) return -1;
      if (x > y) return 1;
      return 0;
    }

    return [...arr].sort(compare);
  };

  const sortByNumReferrals = (arr) => {
    function compare(a, b) {
      var x = a.num_referrals;
      var y = b.num_referrals;

      if (x < y) return -1;
      if (x > y) return 1;
      return 0;
    }

    return [...arr].sort(compare);
  };

  const sortByMethod = () => {
    switch (sortBy) {
      case 'name':
      case 'name-desc':
        return sortByName(surgeonsSorted);
      case 'address':
      case 'address-desc':
        return sortByAddress(surgeonsSorted);
      case 'email':
      case 'email-desc':
        return sortByEmail(surgeonsSorted);
      case 'num_referrals':
      case 'num_referrals-desc':
        return sortByNumReferrals(surgeonsSorted);
      default:
        return surgeonsSorted;
    }
  };
  surgeonsSorted = sortBy.includes('desc') ? sortByMethod().reverse() : sortByMethod();

  const renderColHeader = (sortName, colName, clickable, colSize = 2) => {
    const onClick = () => {
      if (clickable) {
        setSortByWrapper(sortName);
      }
    };

    let sortByClass = styles.col_null;
    if (sortBy === sortName) {
      sortByClass = styles.col_active;
    } else if (sortBy === `${sortName}-desc`) {
      sortByClass = styles.col_active_flipped;
    }

    return (
      <div className={`col-${colSize}`}>
        <p className={`no-select ${styles.hover_col} ${sortByClass}`} onClick={onClick}>
          {colName}
        </p>
        <svg
          className={
            sortBy === sortName
              ? styles.arrow_active
              : sortBy === `${sortName}-desc`
                ? styles.arrow_active_flipped
                : styles.arrow_null
          }
          width='15'
          height='10'
          viewBox='0 0 15 10'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M1 1L7.27586 8L14 1' stroke='#59B6BE' strokeWidth='1.5' strokeLinecap='round' />
        </svg>
      </div>
    );
  };

  const renderAllColHeaders = (clickable) => {
    return (
      <div className={`row ${styles.roster_row} ${styles.roster_row_header}`}>
        {renderColHeader('name', 'Physician name', clickable, 4)}
        {renderColHeader('address', 'Address', clickable, 3)}
        {renderColHeader('email', 'Email', clickable, 3)}
        {renderColHeader('num_referrals', 'Number of referrals', clickable)}
      </div>
    );
  };

  const renderAvatar = (s) => (
    // <div className="avatar-holder">
    //
    //   <div
    //     className="chat-room-pp-avatar"
    //     style={{
    //       backgroundImage: `url(${s.profile_photo.replace('https://rightdevice-images', 'https://patientpartner-images')})`,
    //       backgroundSize: "cover",
    //       backgroundPosition: "center",
    //       backgroundRepeat: "no-repeat",
    //       height: '40px',
    //       width: '40px'
    //     }}
    //   />
    //   <div style={{float: 'left'}}>{s.name}</div>
    // </div>

    <div>
      <img
        className={styles.avatar}
        src={s.profile_photo.replace('https://rightdevice-images', 'https://patientpartner-images')}
        height='40'
        width='40'
      />
      <span>{s.name}</span>
    </div>
  );

  if (view === 'loading') {
    return (
      <div className={`${styles.roster_container_wrapper}`}>
        <Skeleton />
        <div className={`${styles.roster_container} container-fluid`}>
          {
            <>
              <div className='row'></div>
              {renderAllColHeaders(false)}
            </>
          }
          {tableSkeleton((style, item) => (
            <animated.div key={item} style={{ ...style }}>
              {
                <div className={`${styles.roster_row}`}>
                  <div className={`row h-100 ${styles.roster_row_hover}`}>
                    <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                      <svg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M5.56152 10C8.32295 10 10.5615 7.76142 10.5615 5C10.5615 2.23858 8.32295 0 5.56152 0C2.8001 0 0.561523 2.23858 0.561523 5C0.561523 7.76142 2.8001 10 5.56152 10Z'
                          fill='#eee'
                        />
                      </svg>
                    </div>
                    <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                      <Skeleton />
                    </div>
                    <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                      <Skeleton />
                    </div>
                    <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                      <p>
                        <Skeleton />
                      </p>
                    </div>
                    <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                      <Skeleton />
                    </div>
                    <div className={`col my-auto`}>
                      <Skeleton shape='box' />
                    </div>
                  </div>
                </div>
              }
            </animated.div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.roster_container_wrapper}`}>
      <div className={styles.mentor_connect_heading} style={{ marginTop: '20px', marginBottom: '35px' }}>
        <h5 className={`${styles.roster_heading}`}>Physician roster</h5>
      </div>
      <div className={`${styles.roster_container}`}>
        {renderAllColHeaders(true)}
        {surgeonsSorted.slice(min, max).map((r) => {
          return (
            <div className={`row ${styles.roster_row} h-100 ${styles.roster_row_hover}`}>
              <div className={`col-4 cursor-pointer ${styles.roster_col} ${styles.name_col}`}>{renderAvatar(r)}</div>
              <div className={`col-3 cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                <p style={{ width: '100%', display: 'block' }}>{r.office_locations.map((l) => l.address)}</p>
              </div>
              <div className={`col-3 cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                <p style={{ width: '100%', display: 'block' }}>{r.poc_emails.join(',')}</p>
              </div>
              <div className={`col-2 cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                <p style={{ width: '100%', display: 'block' }}>{r.num_referrals}</p>
              </div>
            </div>
          );
        })}
        <div style={{ marginRight: '10px', marginBottom: '10px' }}>
          <Pagination length={surgeons.length} setMax={setMax} setMin={setMin} amountPerPage={10} />
        </div>
      </div>
    </div>
  );
}

export default IsScrolling(PhysicianRoster);
