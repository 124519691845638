export const createProductDiscoveryContacts = (
  email,
  fullName,
  firstName,
  lastName,
  earlyAccessOptions,
  zipCode,
  procedures,
  successFunction
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  fetch('/api/v1/product_discovery_contacts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({
      ...window.utmInfo,
      email: email,
      full_name: fullName,
      first_name: firstName,
      last_name: lastName,
      chosen_early_access: earlyAccessOptions,
      zipcode: zipCode,
      procedures_considering: procedures,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successFunction(data);
    });
};
