import React, { useState } from 'react';
import OptionsAndOther from './../feedback_questions/options_and_other';

export default function MustHaves({ answers, onSubmit, onBack }) {
  return (
    <OptionsAndOther
      title={'What are your "must-haves" when looking for medical treatment? (Select all that apply)'}
      para={'Finding a treatment that works best for you will depend on your preferences and lifestyle.'}
      answers={answers}
      keyForAnswer={'treatment'}
      onSubmit={onSubmit}
      otherTxt={'Enter other preferences here...'}
      onBack={onBack}
      choices={['Minimally invasive', 'FDA approved; non-experimental', 'Short recovery time', 'Covered by insurance']}
    />
  );
}
