export const createProviderApplicantContacts = (name, email, phone, zipcode, procedures, successFunction) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  fetch('/api/v1/provider_applicant_contacts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({
      ...window.utmInfo,
      name: name,
      email: email,
      phone: phone,
      zipcode: zipcode,
      procedures: procedures,
    }),
  }).then((response) => successFunction(response.status));
};
