import React, { useState } from 'react';
import LocationInput from '../../ui_kit/inputs/location';

export default function YesNoLocation({
  onBack,
  onSubmit,
  title,
  para,
  keyForAnswer,
  yesState,
  answers,
  choices = ['Yes', 'No'],
  gtm,
  extraIndex = 0,
  ctaText = 'Next',
  location,
  keyForLocation,
}) {
  const [selected, setSelected] = useState(answers[keyForAnswer] || '');
  const [currentLocation, setCurrentLocation] = useState({});
  const submit = (e) => {
    e.preventDefault();
    let newSelected = { [keyForAnswer]: selected };
    let address = { [keyForLocation]: currentLocation };
    onSubmit(newSelected, address, yesState);
  };

  const onClick = (choice) => {
    if (selected === choice) {
      setSelected('');
    } else {
      setSelected(choice);
    }
  };

  return (
    <form id='plans'>
      <h5 className='question'>{title}</h5>
      <p className='question'>{para}</p>
      {selected !== '' && selected === choices[extraIndex]
        ? [choices[extraIndex]].map((choice) => (
            <>
              <div key={choice} className='tile two-column'>
                <label key={choice} className={`preexisting ${selected.includes(choice) ? 'activeCheckbox' : ''}`}>
                  <input onClick={() => onClick(choice)} type='checkbox' name={choice} value='value' />
                  {choice}
                </label>
              </div>
              {selected === choices[extraIndex] && choice === selected && (
                <div className='marg-top-0 marg-bottom-20 light marg-center w-100'>
                  <LocationInput
                    value={currentLocation}
                    setValue={setCurrentLocation}
                    showSmallLabel={false}
                    style={{ marginTop: '0px' }}
                  />
                  <span className='bar'></span>
                </div>
              )}
            </>
          ))
        : choices.map((choice) => (
            <div key={choice} className='tile two-column'>
              <label key={choice} className={`preexisting ${selected.includes(choice) ? 'activeCheckbox' : ''}`}>
                <input onClick={() => onClick(choice)} type='checkbox' name={choice} value='value' />
                {choice}
              </label>
            </div>
          ))}
      <div className='container-fluid de-pad'>
        <div className='row button-holder de-pad'>
          <button
            className='back col-lg-6 col'
            type='button'
            onClick={() => onBack()}
            data-gtm-click-label={`${gtm} - Back`}
          >
            Back
          </button>
          <button
            disabled={`${selected.length === 0 ? 'disabled' : ''}`}
            className={`gtm-preexisting-conditions submit col-lg-6 col`}
            type='submit'
            onClick={(e) => submit(e)}
            data-gtm-click-label={`${gtm} - Next`}
          >
            {ctaText}
          </button>
        </div>
      </div>
    </form>
  );
}
