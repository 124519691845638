import React, { useState } from 'react';
import OptionsAndOther from '../../../choose_an_option';

export default function DoctorDiagnosis({ answers, onSubmit, onBack, gtm, showBack }) {
  return (
    <div className={`row activity-question`}>
      <div className='col-sm-2'></div>
      <div className='col-sm-8'>
        <OptionsAndOther
          answers={{
            previouslyDiagnosed:
              answers.previouslyDiagnosed === null || answers.previouslyDiagnosed === undefined
                ? null
                : answers.previouslyDiagnosed
                  ? 'Yes'
                  : 'No',
          }}
          onSubmit={(newAnswers) =>
            onSubmit({ ...answers, previouslyDiagnosed: newAnswers.previouslyDiagnosed === 'Yes' ? true : false })
          }
          onBack={onBack}
          title={`Has a doctor ever diagnosed you with uterine fibroids?`}
          para={`70% to 80% of women develop fibroids, but many go undiagnosed.₁`}
          keyForAnswer={`previouslyDiagnosed`}
          choices={['Yes', 'No']}
          yesState={'previouslyDiagnosed'}
          showOther={false}
          selectOne={true}
          showBack={showBack}
          toolTip={true}
        />
      </div>
      <div className='col-sm-2'></div>
    </div>
  );
}
