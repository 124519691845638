import React from 'react';
import styles from './index.module.css';

export default function Rating({
  icons = [
    {
      icon: (
        <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z'
            fill='black'
          />
          <path
            d='M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z'
            fill='black'
          />
          <path
            d='M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM10 12C7.67 12 5.68 13.45 4.88 15.5H6.55C7.24 14.31 8.52 13.5 10 13.5C11.48 13.5 12.75 14.31 13.45 15.5H15.12C14.32 13.45 12.33 12 10 12V12Z'
            fill='black'
          />
        </svg>
      ),
      text: 'Very nervous',
      number: 1,
    },
    {
      icon: (
        <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z'
            fill='black'
          />
          <path
            d='M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z'
            fill='black'
          />
          <path
            d='M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM10 14.5C10.73 14.5 11.39 14.69 11.97 15.03C12.09 14.89 12.83 14.05 12.98 13.89C12.13 13.33 11.11 13 10 13C8.89 13 7.87 13.33 7.01 13.88C7.98 14.97 7.02 13.9 8.02 15.02C8.61 14.69 9.27 14.5 10 14.5Z'
            fill='black'
          />
        </svg>
      ),
      text: 'Nervous',
      number: 2,
    },
    {
      icon: (
        <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z'
            fill='black'
          />
          <path
            d='M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z'
            fill='black'
          />
          <path
            d='M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM10 14C9.27 14 8.62 13.82 8.04 13.48C7.92 13.62 7.18 14.46 7.03 14.63C7.89 15.18 8.9 15.5 10 15.5C11.11 15.5 12.12 15.17 12.98 14.62C12.01 13.53 12.97 14.6 11.97 13.47C11.38 13.82 10.73 14 10 14Z'
            fill='black'
          />
        </svg>
      ),
      text: 'Unsure',
      number: 3,
    },
    {
      icon: (
        <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z'
            fill='black'
          />
          <path
            d='M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z'
            fill='black'
          />
          <path
            d='M10 14C8.52 14 7.25 13.19 6.55 12H4.88C5.68 14.05 7.67 15.5 10 15.5C12.33 15.5 14.32 14.05 15.12 12H13.45C12.76 13.19 11.48 14 10 14ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z'
            fill='black'
          />
        </svg>
      ),
      text: 'Excited',
      number: 4,
    },
    {
      icon: (
        <svg viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M13.5 9C14.3284 9 15 8.32843 15 7.5C15 6.67157 14.3284 6 13.5 6C12.6716 6 12 6.67157 12 7.5C12 8.32843 12.6716 9 13.5 9Z'
            fill='black'
          />
          <path
            d='M6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z'
            fill='black'
          />
          <path
            d='M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM5 12C5.78 14.34 7.72 16 10 16C12.28 16 14.22 14.34 15 12H5Z'
            fill='black'
          />
        </svg>
      ),
      text: 'Very excited',
      number: 5,
    },
  ],
  setSelected = () => {},
  selected = null,
  wrapperStyles = {},
  star = false,
}) {
  if (star) {
    icons.map(
      (i) =>
        (i.icon = (
          <svg width='36' height='33' viewBox='0 0 36 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M18.9234 28.3146C18.352 28.0224 17.6751 28.0221 17.1035 28.3138L9.539 32.1742C8.06831 32.9248 6.37305 31.6707 6.6604 30.0447L8.07607 22.0342C8.19318 21.3716 7.96924 20.6945 7.48014 20.2324L1.52181 14.6027C0.303342 13.4515 0.956774 11.402 2.61675 11.1685L10.942 9.99732C11.5886 9.90635 12.1502 9.50539 12.4461 8.92327L16.2172 1.50638C16.9586 0.0483107 19.0414 0.0483135 19.7828 1.50638L23.5539 8.92327C23.8498 9.50539 24.4114 9.90635 25.058 9.99732L33.3833 11.1685C35.0432 11.402 35.6967 13.4515 34.4782 14.6027L28.5199 20.2324C28.0308 20.6945 27.8068 21.3716 27.9239 22.0342L29.3388 30.0402C29.6263 31.6669 27.9293 32.9211 26.4586 32.1688L18.9234 28.3146Z'
              fill='#314D69'
            />
          </svg>
        ))
    );
  }

  const isSelected = (icon) => {
    return star
      ? selected != null && selected.number >= icon.number
      : selected != null && selected.number === icon.number;
  };

  if (icons.filter((i) => i.number === undefined).length === icons.length) {
    icons.map((i, index) => (i.number = index + 1));
  }

  return (
    <div style={{ width: '100%', ...wrapperStyles }}>
      <div style={{ width: 'max-content', margin: '0px auto', minHeight: '70px' }} className={styles.icon_innr}>
        <div className={styles.icon_holder}>
          {icons.map((icon, index) => (
            <div
              key={index}
              onClick={() => setSelected(icons[index])}
              className={`${styles.icon_wrap} ${isSelected(icon) ? styles.selected : ''}`}
            >
              {icon.icon}
            </div>
          ))}
        </div>
        {selected != null && <p className={styles.txt}>{selected.text}</p>}
      </div>
    </div>
  );
}
