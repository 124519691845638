import { connect } from 'react-redux';
import {
  setContactIdAction,
  updateAnswersAction,
  setSurgeonMatchQuestionnaireProgress,
  setSurgeon,
} from '../../../actions/home';
import CosmeticVirtualConsultation from '../../../components/cosmetic/virtual_consultation';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
  setContactIdAction: (item) => dispatch(setContactIdAction(item)),
  setSurgeonMatchQuestionnaireProgress: (item) => dispatch(setSurgeonMatchQuestionnaireProgress(item)),
  setSurgeon: (item) => dispatch(setSurgeon(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CosmeticVirtualConsultation);
