import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { createBrowserHistory } from 'history';
import rootReducer from './reducers';
import App from './App';
import ReactGA from 'react-ga';
import smoothscroll from 'smoothscroll-polyfill';
import { CookiesProvider } from 'react-cookie';

export const history = createBrowserHistory();

const store = createStore(rootReducer);
if (gon.ga_id) {
  ReactGA.initialize(gon.ga_id);
}
// kick off the polyfill!
smoothscroll.polyfill();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
