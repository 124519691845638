const initialState = {
  answers: gon.global_answers || {},
  patientPartnerQuestionnaireProgress: gon.patient_partner_questionnaire_progress || {
    view: 'contact-info',
    questionNumber: null,
  },
  surgeonMatchQuestionnaireProgress: gon.surgeon_match_questionnaire_progress || {
    view: 'contact-info',
    questionNumber: null,
  },
  patientPartners: [],
  surgeon: null,
  contactId: gon.contact_id,
};

const home = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_ANSWERS':
      return {
        ...state,
        answers: action.item,
      };
    case 'SET_CONTACT_ID':
      return {
        ...state,
        contactId: action.item,
      };
    case 'SET_PATIENT_PARTNER_QUESTIONNAIRE_PROGRESS':
      return {
        ...state,
        patientPartnerQuestionnaireProgress: action.item,
      };
    case 'SET_SURGEON_MATCH_QUESTIONNAIRE_PROGRESS':
      return {
        ...state,
        surgeonMatchQuestionnaireProgress: action.item,
      };
    case 'SET_PATIENT_PARTNERS':
      return {
        ...state,
        patientPartners: action.item,
      };
    case 'SET_SURGEON':
      return {
        ...state,
        surgeon: action.item,
      };
    default:
      return state;
  }
};

export default home;
