import { connect } from 'react-redux';
// import {
//   updateAnswersAction,
//   setQuestionnaireStateAction,
// } from '../../actions/knee_replacement'
import { updateAnswersAction } from '../../actions/home';
import InsuranceCheckForm from '../../components/insurance_check_form';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceCheckForm);
