import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ErrorMessage from '../ui_kit/utilities/error_message';
import AgreeToTerms from '../ui_kit/utilities/agree_to_terms';
import PasswordInput from '../ui_kit/utilities/password_input';
import PhoneNumberInput from '../ui_kit/inputs/phone_number';
import LocationInput from '../ui_kit/inputs/location';
import EmailInput from '../ui_kit/inputs/email';
import Context from '../../utils/context';
import { validateText, isObjEmpty } from '../../helpers';
import RequestButton from '../ui_kit/utilities/request_button';
import parsePhoneNumber from 'libphonenumber-js';
import styles from './index.module.css';
import './index.css';

export default function SignUp({
  defaultName,
  defaultEmail,
  defaultPhone,
  onSuccess,
  gtm = '',
  showAcceptTerms = true,
  params = {},
  headline = 'Join PatientPartner today',
  para = 'Create your account and start communicating with your PatientPartner Mentor in a safe space.',
  embedded = false,
  alignLeft = false,
  light = false,
  cta = 'Create account',
  captureLocation = false,
  capturePhone = true,
  withoutContainer = false,
  defaultFirstName,
  defaultLastName,
  captureFirstLastName = false,
  skipPassword = false,
  showLogin = true,
  australiaPhone = false,
  locationPlaceholder = 'Enter city or zip code',
  countryRestriction = 'us',
  useFacebookConversionApi = true,
}) {
  const [name, setName] = useState(defaultName || '');
  const [firstName, setFirstName] = useState(defaultFirstName || '');
  const [lastName, setLastName] = useState(defaultLastName || '');
  const [email, setEmail] = useState(defaultEmail || '');
  const [phone, setPhone] = useState(defaultPhone || '');
  const [password, setPassword] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(!showAcceptTerms);
  const [validPhone, setValidPhone] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [location, setLocation] = useState({});
  const context = useContext(Context);
  const viewport = context.viewport;
  const [error, setError] = useState('');
  const [userLoggedInError, setUserLoggedInError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const returnTo = window.location.pathname;

  let search = window.location.search;
  if (search) {
    search = '&' + search.substring(1);
  }
  const loginPathName = `/member/login?return_to=${encodeURIComponent(returnTo)}${search}`;

  var checkedEmail = email !== '';
  let checkedName = false;
  if (captureFirstLastName) {
    checkedName = validateText(firstName) && validateText(lastName);
  } else {
    checkedName = validateText(name);
  }

  var checkedLocation = !isObjEmpty(location);
  let validPassword = password !== '' || skipPassword;

  useEffect(() => {
    if (
      checkedName &&
      checkedEmail &&
      (validPhone || !capturePhone) &&
      (checkedLocation || !captureLocation) &&
      acceptedTerms &&
      validPassword
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [checkedName, checkedEmail, validPhone, checkedLocation, acceptedTerms, validPassword]);

  const validatePhone = (phoneNumber) => {
    try {
      const number = parsePhoneNumber(phoneNumber);
      setValidPhone(number.isValid());
      return number.isValid();
    } catch (error) {
      return false;
    }
  };

  const validatePhoneOnBlur = () => {
    if (phone !== '' && phone !== null && !validatePhone(phone)) {
      setPhoneError('Enter a valid phone number');
    } else {
      setPhoneError('');
    }
  };

  const onClick = (afterAction) => {
    let newName = name;
    if (captureFirstLastName) {
      newName = `${firstName} ${lastName}`;
    }
    if (useFacebookConversionApi) {
      context.facebookConversionApi.leadEvent({
        email: email,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phone,
        zipcode: (location || {}).zipcode,
      });
    }
    const user = context.auth.getCurrentUser();

    // If user is already logged in, don't allow them to sign up for a new account
    if (user && Object.keys(user).length > 0) {
      setUserLoggedInError('You are already logged in. Please log out to create a new account.');
      afterAction();
      return;
    }

    context.auth.userSignUp(
      newName,
      email,
      phone,
      password,
      { ...params, ...location, first_name: firstName, last_name: lastName, skipped_password: skipPassword },
      onSuccess,
      (errors) => {
        setError(errors.join(','));
        afterAction();
      }
    );
    setError('');
  };

  // Listens to know if the device is a mobile device START
  const isMobile = viewport.getIsMobile();
  // Listens to know if the device is a mobile device END

  const renderInner = () => {
    return (
      <div
        style={embedded ? { backgroundColor: isMobile ? '' : '#fff', boxShadow: 'none', marginTop: '20px' } : {}}
        className='registrations-container'
      >
        <h5 className={`${alignLeft ? '' : 'text-center'} marg-top-20 marg-bottom-10`}>{headline}</h5>
        <p className={`${alignLeft ? '' : 'text-center'} login-title marg-bottom-20`}>{para}</p>
        {captureFirstLastName ? (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div
              style={embedded ? { maxWidth: '100%' } : {}}
              className={`${light ? 'light' : ''} material-input ${styles.first_name}`}
            >
              <input
                className='autofocus'
                type='text'
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                required
              />
              <span className='highlight'></span>
              <span className='bar'></span>
              <label>First Name</label>
            </div>
            <div
              style={embedded ? { maxWidth: '100%' } : {}}
              className={`${light ? 'light' : ''} material-input ${styles.last_name}`}
            >
              <input type='text' value={lastName} onChange={(event) => setLastName(event.target.value)} required />
              <span className='highlight'></span>
              <span className='bar'></span>
              <label>Last Name</label>
            </div>
          </div>
        ) : (
          <div style={embedded ? { maxWidth: '100%' } : {}} className={`${light ? 'light' : ''} material-input`}>
            <input
              className='autofocus'
              type='text'
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
            <span className='highlight'></span>
            <span className='bar'></span>
            <label>Name</label>
          </div>
        )}
        <EmailInput
          value={email}
          setValue={setEmail}
          style={embedded ? { maxWidth: '100%' } : {}}
          light={light}
          name=''
        />
        <ErrorMessage error={error} />
        {capturePhone && (
          <>
            <PhoneNumberInput
              phone={phone}
              setPhone={setPhone}
              onBlur={validatePhoneOnBlur}
              style={embedded ? { maxWidth: '100%' } : {}}
              light={light}
              required={true}
            />
            <ErrorMessage error={phoneError} />
          </>
        )}
        {captureLocation && (
          <>
            <LocationInput
              value={location}
              setValue={setLocation}
              showModal={false}
              gtm={gtm}
              light={light}
              placeholder={locationPlaceholder}
              style={{ maxWidth: '100%', width: '100%' }}
              showSmallLabel
              countryRestriction={countryRestriction}
            />
          </>
        )}
        {!skipPassword && (
          <PasswordInput
            password={password}
            setPassword={setPassword}
            embedded={embedded}
            light={light}
            name=''
            label='Create password'
            signUp={true}
          />
        )}
        {showAcceptTerms && <AgreeToTerms acceptedTerms={acceptedTerms} setAcceptedTerms={setAcceptedTerms} />}
        <RequestButton
          request={onClick}
          recaptcha={true}
          disabled={isDisabled}
          classnames={'marg-top-10 submit'}
          style={{ width: '100%' }}
          cta={cta}
          gtm={`${gtm} Sign Up`}
        />
        <ErrorMessage error={userLoggedInError} />

        {showLogin && (
          <p className={`marg-top-30 marg-bottom-30 ${alignLeft ? '' : 'text-center'}`} style={{ fontSize: '16px' }}>
            Already have an account?{' '}
            <Link
              style={{ transform: 'translateY(-2px)', fontSize: '16px' }}
              to={loginPathName}
              className='inline-link'
            >
              Log in
            </Link>
          </p>
        )}
      </div>
    );
  };

  if (withoutContainer) {
    return renderInner();
  }

  return (
    <div style={embedded ? { height: 'auto' } : {}} className='container sign-in'>
      <div className='row h-100'>
        <div style={embedded ? { display: 'none' } : {}} className='col-md-3 no-mobile'></div>
        <div className={`${embedded ? 'col' : 'col-md-6 my-auto'}`}>{renderInner()}</div>
      </div>
      <div style={embedded ? { display: 'none' } : {}} className='col-md-3 no-mobile'></div>
    </div>
  );
}
