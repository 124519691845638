export const getArticle = (articleId, successCallback) => {
  fetch(`/api/v1/articles/${articleId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getArticles = (params, successCallback) => {
  fetch(`/api/v1/articles?${objToQueryString(params)}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
