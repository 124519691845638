import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { setConsultationTimes } from '../../api/surgeons';
import LoadingScreen from '../loading_screen';
import SuccessStar from '../ui_kit/utilities/success_star';
import { toSentTime, toSentDay } from '../../helpers';

export default function SurgeonVirtualConsultationConfirm() {
  const [view, setView] = useState('loading');
  const { cid, uid } = useParams();
  const [currentData, setCurrentData] = useState({});
  let history = useHistory();

  useEffect(() => {
    setConsultationTimes({ surgeon_virtual_consultation_time_id: cid, uid: uid, confirmed: true }, (data) => {
      if (data.confirmed) {
        setView('success');
      } else {
        setView('error');
      }
      setCurrentData(data);
    });
  }, [cid, uid]);

  return (
    <div style={{ height: '90vh', maxWidth: '1040px' }} className='container'>
      <div className='row h-100'>
        <div className='col h-100'>
          <div className='h-100' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {view === 'loading' && <LoadingScreen />}
            {view === 'success' && (
              <div>
                <h5
                  style={{
                    marginTop: '100px',
                    textAlign: 'center',
                    marginBottom: '10px',
                    color: '#232323',
                    fontSize: '40px',
                  }}
                >
                  Your free 10-minute virtual consultation with {currentData.surgeon_name} on{' '}
                  {currentData.start_time_readable} has been confirmed!
                </h5>
                <div style={{ margin: '0px auto', width: 'max-content' }}>
                  <SuccessStar />
                </div>
                <p
                  style={{
                    marginBottom: '50px',
                    textAlign: 'center',
                    marginTop: '10px',
                    color: '#232323',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  In the meantime, join the PatientPartner platform and other patients like you to ask questions, get
                  recommendations, see success stories, and get the information you need on the treatment or procedure
                  you're considering. It's free and easy to use!
                </p>
                <p style={{ textAlign: 'center' }}>
                  <button onClick={() => history.push(`/member/home`)}>Join PatientPartner</button>
                </p>
              </div>
            )}
            {view === 'error' && (
              <div>
                <h5 style={{ textAlign: 'center', marginBottom: '10px', color: '#232323', fontSize: '40px' }}>
                  We're sorry, your request for a free virtual consultation has expired. Please click below to request
                  your time.
                </h5>
                <button onClick={() => history.push(`/procedure/spine/virtual-consultation`)}>
                  Schedule My Virtual Consultation
                </button>
                <p
                  style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    color: '#232323',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  Please remember to confirm your appointment via email or text once your request has been made.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
