import React, { Component, useState, useEffect } from 'react';
import './video_type_1.css';
import FsLightbox from 'fslightbox-react';

export default function VideoType1({
  bg = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/videobg.png',
  headline = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.',
  cta = 'Watch',
  gtm,
  videoLink = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/video/R5+-+Patient+Partner+Program+Value+-+D3+(1).mp4',
}) {
  const [toggler, setToggler] = useState(false);

  return (
    <div style={{ backgroundImage: `url('${bg}')` }} className='video-sec container-fluid video-type-1 video-sec-wrap'>
      <div className='video-cover'></div>
      <div className='video-bg'>
        <video autoPlay='autoplay' muted loop playsInline>
          <source src={videoLink} type='video/mp4' />
        </video>
      </div>
      <div className='container content'>
        <div className='row'>
          <div className='col-sm-8'>
            <h1 className='vid-headline'>{headline}</h1>
            <button
              onClick={() => setToggler(!toggler)}
              className='watchbutton'
              data-gtm-click-label={`${gtm} Watch Video`}
            >
              {cta}
              <svg xmlns='http://www.w3.org/2000/svg' width='17' height='21' viewBox='0 0 17 21'>
                <path
                  id='Polygon_4'
                  data-name='Polygon 4'
                  d='M9.649,1.377a1,1,0,0,1,1.7,0l8.707,14.1A1,1,0,0,1,19.207,17H1.793a1,1,0,0,1-.851-1.525Z'
                  transform='translate(17) rotate(90)'
                  fill='#fff'
                />
              </svg>
            </button>
          </div>
          <div className='col-sm-4'></div>
        </div>
      </div>
      <FsLightbox toggler={toggler} sources={[`${videoLink}`]} type='video' />
    </div>
  );
}
