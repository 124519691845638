import React, { useState } from 'react';
import '../input_with_options/index.css';
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';

export default function InputWithRemoteOptions({
  value,
  setValue,
  getOptions,
  name,
  label,
  noMatchTxt,
  light = true,
  wrapperClassnames,
  placeholder = '',
  fullWidth = false,
  style = {},
}) {
  const [currentValue, setCurrentValue] = useState(value);
  const [showOptions, setShowOptions] = useState(false);
  const [hoveringDropdown, setHoveringDropdown] = useState(false);
  const [currentOptions, setCurrentOptions] = useState([]);

  const debouncedInputChange = useDebouncedCallback((value) => {
    getOptions(value, (options) => {
      setCurrentOptions(options);
      setShowOptions(true);
    });
  }, 1000);

  const onInputChange = (value) => {
    setCurrentValue(value);
    if (value && value.length > 3) {
      debouncedInputChange(value);
    } else if (value === '') {
      setValue('');
    }
  };

  const onClickOption = (event) => {
    event.preventDefault();
    setHoveringDropdown(false);
    setValue(event.target.value);
    setCurrentValue(event.target.value);
    setShowOptions(false);
  };

  return (
    <div
      style={{ width: fullWidth ? '100%' : '', maxWidth: fullWidth ? '100%' : '' }}
      className={`input-with-options-container ${wrapperClassnames}`}
    >
      <div
        style={{ width: fullWidth ? '100%' : '', maxWidth: fullWidth ? '100%' : '' }}
        className={`material-input ${light ? 'light' : ''}`}
      >
        <input
          placeholder={placeholder}
          onClick={() => setShowOptions(true)}
          onBlur={() => {
            hoveringDropdown === false ? setShowOptions(false) : null;
          }}
          autoComplete='off'
          className='autofocus'
          name={name}
          value={currentValue}
          onChange={(event) => onInputChange(event.target.value)}
          required
        />
        <span className='highlight'></span>
        <span className='bar'></span>
        <label>{label}</label>
      </div>
      {showOptions && (
        <div
          style={style}
          onMouseOver={() => setHoveringDropdown(true)}
          onMouseOut={() => setHoveringDropdown(false)}
          className='input-with-options-dropdown-container'
        >
          <div className='input-with-options-dropdown'>
            {noMatchTxt && (
              <button
                value={noMatchTxt}
                onClick={(event) => onClickOption(event)}
                className='input-with-options-option'
              >
                {noMatchTxt}
              </button>
            )}
            {currentOptions.map((value, i) => (
              <button
                key={i}
                value={value}
                onClick={(event) => onClickOption(event)}
                className='input-with-options-option'
              >
                {value}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
