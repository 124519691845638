import React, { useState } from 'react';
import './index.css';
import { Link } from 'react-router-dom';
import Divider from '../ui_kit/utilities/divider';

export default function PatientPartnerColumnForGallery({
  partner,
  actionContent,
  index,
  showWeightLoss = false,
  showAge = false,
  showSurgerySpecialty = false,
  showTitle = false,
  showShowMore = true,
  article = false,
}) {
  const [showBio, setShowBio] = useState(false);

  if (index < 10) {
    index = '0' + index;
  }
  const titleCase = (string) => {
    var surgery = string.toLowerCase().split(' ');
    for (var i = 0; i < surgery.length; i++) {
      if (surgery[i][0]) {
        surgery[i] = surgery[i][0].toUpperCase() + surgery[i].slice(1);
      }
    }
    return surgery.join(' ');
  };

  return (
    <div key={index} className='pp-pre-wrapper gallery-pp-column h-100'>
      <div className={`pp-wrapper h-100 ${article ? 'pp-grow' : ''}`}>
        <div className='circle-image' style={{ backgroundImage: `url('${partner.image}')` }}></div>
        <div className='content' style={{ marginBottom: '175px' }}>
          <h5 className='name row justify-content-center marg-top-80'>{partner.name}</h5>
          <div className='pp-details'>
            {partner.surgeries_or_treatments && partner.surgeries_or_treatments.length > 0 && showSurgerySpecialty && (
              <h4 className='light-blue-text'>
                {partner.surgeries_or_treatments
                  .sort()
                  .map((s) => titleCase(s))
                  .join(', ')}
              </h4>
            )}
            <div className='row justify-content-center'>
              {partner.display_location && (
                <p
                  className='location'
                  style={
                    partner.surgeries_or_treatments && partner.surgeries_or_treatments.length > 2
                      ? { marginTop: '0px' }
                      : { marginTop: '5px' }
                  }
                >
                  {partner.display_location}
                </p>
              )}
            </div>
            <Divider color={'#888888'} />
            <div className='bio marg-top-10'>
              <span>{partner.bio}</span>
            </div>
          </div>
        </div>
        <div className='action-content marg-top-30 ' style={{ position: 'absolute', bottom: '0px' }}>
          <Link to={`/member/profile/${partner.slug}/about-me`}>
            <button
              data-pendo-tag={`mentor gallery - read full bio - ${partner.name}`}
              data-gtm-click-label='Patient partner gallery Toggle Show Bio'
              style={{ width: '80%', marginRight: '0px' }}
              className='marg-bottom-10 tertiary_hov'
            >
              See full profile
            </button>
          </Link>
          {actionContent}
        </div>
      </div>
    </div>
  );
}
