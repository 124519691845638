import React, { useContext, useState } from 'react';
import Context from '../../../utils/context';
import GhostButton from '../../ui_kit/utilities/ghost_button';
import MentorTag from './profile_panel_sections/mentor_tag';
import LocationDisplay from './profile_panel_sections/location_display';
import styles from './index.module.css';
import SendMessageButton from '../../ui_kit/utilities/send_message_button';
import AvatarEditor from '../../ui_kit/utilities/avatar_editor';
import RequestButton from '../../ui_kit/utilities/request_button';
import { successToast } from '../../ui_kit/utilities/success_toast';
import ErrorMessage from '../../ui_kit/utilities/error_message';
import LocationInput from '../../ui_kit/inputs/location';
import ReactTooltip from 'react-tooltip';
import { createActivity } from '../../../api/activities';

export default function ProfilePanel({ profile }) {
  const context = useContext(Context);
  const [currentProfile, setCurrentProfile] = useState(profile || {});
  const [editing, setEditing] = useState(false);
  const [displayName, setDisplayName] = useState(profile.display_name);
  const [currentLocation, setCurrentLocation] = useState({ address: profile.address });

  const onClick = (afterAction) => {
    let params = {};
    let fields = [];
    if (displayName !== '' && displayName !== currentProfile.display_name) {
      params.display_name = displayName;
      fields.push('display_name');
    }

    if (currentLocation.address !== profile.address) {
      fields.push('address');
    }

    context.auth.updateProfile({ ...params, ...currentLocation }, (newProfile) => {
      afterAction();
      setCurrentProfile(newProfile);
      createActivity('Activity::EditProfile', { fields: fields.join(',') });
      setEditing(false);
      window.location.reload();
    });
  };

  let disabled = () => {
    let params = {};
    if (displayName !== '' && displayName !== currentProfile.display_name) {
      params.display_name = displayName;
    }
    if (currentLocation.address !== '' && currentLocation.address !== currentProfile.address) {
      params = { ...params, ...currentLocation };
    }

    return Object.keys(params).length === 0;
  };

  const location = profile.city + ', ' + profile.state;

  return (
    <div className={`content_container`}>
      <div className='container de-pad'>
        <div className='row'>
          <div className='col'>
            <div data-active-id={profile.id}>
              {editing ? (
                <>
                  <div className='avatar-holder' style={{ margin: '0px auto' }}>
                    <AvatarEditor
                      size={'90px'}
                      onSuccess={() => window.location.reload()}
                      editPhotoPendoTag='profile page - edit profile photo'
                    />
                  </div>
                  <div className={`material-input marg-top-15`}>
                    <div style={{ display: 'flex' }}>
                      <p style={{ marginRight: '10px' }} className={styles.label}>
                        Display Name
                      </p>
                      <img
                        style={{ marginBottom: '10px', width: '14px', cursor: 'pointer' }}
                        alt='tooltip'
                        data-tip
                        src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/tooltipy.svg'
                      />
                    </div>
                    <input
                      data-pendo-tag='profile sticky - display name'
                      className={`autofocus ${styles.no_trans}`}
                      type='text'
                      value={displayName}
                      required
                      placeholder={displayName}
                      onChange={(e) => {
                        setDisplayName(e.target.value);
                      }}
                    />
                    <ReactTooltip place='top'>
                      <p
                        className={styles.tooltip}
                        style={{ color: '#fff', fontSize: '14px', fontWeight: '600', lineHeight: '16px' }}
                      >
                        This is the name community
                        <br />
                        members call you.
                      </p>
                    </ReactTooltip>
                  </div>
                  <div className={`material-input marg-top-15`}>
                    <p className={styles.label}>Location</p>
                    <LocationInput
                      pendoTag='profile sticky - display name'
                      value={currentLocation}
                      setValue={setCurrentLocation}
                      showSmallLabel={false}
                      style={{ marginTop: '0px' }}
                    />
                  </div>
                  <div className={`${styles.cta_wrap}`}>
                    <button onClick={() => setEditing(false)} className={`${styles.cancel} liner ${styles.create_btn}`}>
                      Cancel
                    </button>
                    <RequestButton
                      disabled={disabled()}
                      cta={'Save'}
                      classnames={styles.create_btn}
                      request={(action) => onClick(action)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className='chat-room-pp-avatar-lg'
                    style={{
                      backgroundImage: `url(${profile.photo && profile.photo.replace('(', '\\(').replace(')', '\\)')})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  ></div>
                  <div style={{ position: 'relative' }}>
                    <div
                      style={{
                        lineHeight: 'normal',
                        fontSize: '18px',
                        fontWeight: '500',
                        textAlign: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '15px auto 8px auto',
                          justifyContent: 'center',
                        }}
                      >
                        <p style={{ textAlign: 'center', fontSize: '18px' }} className={`${styles.name}`}>
                          {currentProfile.display_name}
                        </p>
                        {currentProfile.type && currentProfile.type === 'PatientPartner' && <MentorTag />}
                      </div>
                    </div>
                    {profile.display_location && (
                      <LocationDisplay style={{ justifyContent: 'center' }} location={location} />
                    )}
                    <SendMessageButton style={{ margin: '10px auto', display: 'block' }} otherProfile={profile} />
                    {context.auth.isLoggedIn() && context.auth.getCurrentUser().email === profile.email && !editing && (
                      <GhostButton
                        data-pendo-tag='profile sticky panel - edit button'
                        onClick={() => setEditing(true)}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <img
                          style={{ marginRight: '8px', height: '16px', marginTop: '-4px' }}
                          src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/edit.svg'
                        />
                        <span>Edit</span>
                      </GhostButton>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
