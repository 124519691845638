import React, { useState, useEffect } from 'react';
import ProfileProcedures from './procedures';
import ProfileConditions from './conditions';
import styles from './index.module.css';
import ProfileInsurance from './profile_insurance';
import { Link, useLocation } from 'react-router-dom';

export default function HealthProfile({ user }) {
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery().get('type');
  if (query === null) {
    query = 'current';
  }

  return (
    <>
      <div style={{ display: 'flex', marginTop: '20px', marginBottom: '24px' }}>
        <Link data-pendo-tag='profile page - health profile - current tab' to='?type=current'>
          <p
            style={
              query === 'current' ? { backgroundColor: '#419DA5', color: '#fff', border: '2px solid #419DA5' } : {}
            }
            className={styles.tab}
          >
            Current
          </p>
        </Link>
        <Link data-pendo-tag='profile page - health profile - recovered tab' to='?type=recovered'>
          <p
            style={
              query === 'recovered'
                ? { backgroundColor: '#419DA5', color: '#fff', marginLeft: '10px', border: '2px solid #419DA5' }
                : { marginLeft: '10px' }
            }
            className={styles.tab}
          >
            Recovered
          </p>
        </Link>
      </div>
      <ProfileProcedures user={user} tab={query} />
      <ProfileConditions user={user} tab={query} />
      <ProfileInsurance user={user} />
    </>
  );
}
