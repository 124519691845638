import React, { useState } from 'react';
import styles from './index.module.css';
import ScrollTrigger from '../ui_kit/utilities/scroll_trigger';
import { Link } from 'react-router-dom';
import { validateEmail } from '../../helpers/index';

export default function JoinFooter({
  headline = 'Join the community!',
  para = 'Start learning more about your health condition, surgery, or treatment from peers that have already been through it. It’s easy to sign up and free to use.',
  gtm,
  updateAnswersAction,
}) {
  const [email, setEmail] = useState('');
  const [animate, setAnimate] = useState(false);

  return (
    <div
      style={{ backgroundColor: '#DDF7F9', paddingTop: '70px', paddingBottom: '70px', overflow: 'hidden' }}
      className='container-fluid'
    >
      <div className='container'>
        <div className='row' style={{ position: 'relative', justifyContent: 'center' }}>
          <div
            style={{ position: 'relative' }}
            className={`col-12 col-md-11  ${animate ? styles.animate : styles.animate_base} de-pad`}
          >
            <img
              className={styles.svg}
              alt='decor-dots'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/dotsdots.svg'
            />
            <img
              className={styles.svg1}
              alt='decor-dots'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/dotsdots.svg'
            />
            <ScrollTrigger delay={300} callback={() => setAnimate(true)} />
            <div className={`${styles.newsletter_signup_form} ${styles.get_started_outr}`}>
              <h2 className={`${styles.headline} text-center darkprim marg-bottom-10`}>{headline}</h2>
              <p className={`${styles.para} text-center darkprim marg-bottom-20`}>{para}</p>
              <div className={`row ${styles.newsletter_form}`} style={{ maxWidth: '600px', margin: '0px auto' }}>
                <div className={styles.input_wrap} style={{ display: 'flex', flexGrow: '1' }}>
                  <div style={{ maxWidth: '100000px' }} className={`material-input marg-top-0`}>
                    <input placeholder='Email address' value={email} onChange={(e) => setEmail(e.target.value)} />
                    <span className='bar'></span>
                  </div>
                </div>
                <Link
                  className={styles.btn_wrap}
                  onClick={() => updateAnswersAction({ email })}
                  to={`/member/join/support`}
                >
                  <button
                    disabled={!validateEmail(email)}
                    className={styles.btn}
                    style={{ padding: '9px 40px', marginLeft: '10px' }}
                    data-gtm-click-label={gtm}
                  >
                    Create my account
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
