import React from 'react';
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function MentorConversationTopics({
  answers,
  onSubmit,
  onBack,
  choices,
  showOther = false,
  para = '',
  title = 'How do you think a conversation with a mentor could help you?',
}) {
  return (
    <div className={`row activity-question`}>
      <div className='col-sm-2'></div>
      <div className='col-sm-8'>
        <OptionsAndOther
          answers={{ mentorConversationTopics: answers.MentorConversationTopics }}
          onSubmit={onSubmit}
          ctaTxt={'Submit'}
          onBack={onBack}
          title={title}
          para={para}
          keyForAnswer={`mentorConversationTopics`}
          choices={choices}
          showOther={showOther}
          showBack
        />
      </div>
      <div className='col-sm-2'></div>
    </div>
  );
}
