import React, { useState, useRef, useContext } from 'react';
import styles from '../../posts/createpost.module.css';
import RequestButton from '../../ui_kit/utilities/request_button';
import { failToast } from '../../ui_kit/utilities/fail_toast';
import { createUserDeactivates } from '../../../api/user_deactivates';
import Context from '../../../utils/context';
import { logOut } from '../../../api/users';
import { useClickAway } from 'react-use';

export default function DeactivateModal({ setClickModal }) {
  const ref = useRef(null);
  const ref_1 = useRef(null);
  const context = useContext(Context);
  const currentUser = context.auth.getCurrentUser();
  const [check, setCheck] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [description, setDescription] = useState('');
  const deactivateReasons = [
    { reason: "I didn't find what I was looking for" },
    { reason: 'I am concerned about my privacy' },
    { reason: "I don't feel safe on PatientPartner" },
    { reason: 'Other' },
  ];

  const checkedReasons = (reason) => {
    if (checkedItems.map((i) => i.includes(reason)).includes(true)) {
      setCheckedItems(checkedItems.filter((a) => a != reason));
    } else {
      var arr = [...checkedItems];
      arr.push(reason);
      setCheckedItems(arr);
    }
    setCheck(!check);
  };

  useClickAway(ref_1, () => {
    setClickModal(true);
  });

  useClickAway(ref, () => {
    setClickModal(false);
  });

  const submitUserDeactivate = (afterAction) => {
    let reasons = [...checkedItems];
    createUserDeactivates(reasons, description, currentUser, (data) => {
      if (data['success'] == true) {
        logOut(() => {
          window.location = '/member/login';
          window.localStorage.setItem('showDeactivationToast', 'true');
        });
      } else {
        failToast(
          'Something went wrong, please reach out to support@patientpartner.com for assistance with your request.'
        );
      }

      afterAction();
      setCheckedItems([]);
      setDescription('');
      setClickModal(false);
    });
  };

  return (
    <div className={`${styles.create_post_otr} container-fluid`}>
      <div className={`container ${styles.cp_cont}`}>
        <div className='row justify-content-center'>
          <div ref={ref} className={`col-lg-5 ${styles.cp_col}`}>
            <div className={styles.create_post_wrap} style={{ overflow: 'hidden' }}>
              <div className={`${styles.create_new_banner}`}>
                <p className={`${styles.nb_para}`}>Deactivate account</p>
                <span onClick={() => setClickModal(false)} className={`${styles.close}`}>
                  <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg' />
                </span>
              </div>
              <div className={`${styles.content}`}>
                <div className='container'>
                  <div className='container marg-top-20'>
                    {deactivateReasons.map((r) => (
                      <>
                        <div key={r.reason} className='row'>
                          <button
                            className={styles.report_button}
                            onClick={() => checkedReasons(r.reason)}
                            style={{ background: 'none' }}
                          >
                            <div className='col de-pad' style={{ display: 'flex' }}>
                              {checkedItems.includes(r.reason) ? (
                                <img
                                  className={styles.no_trans}
                                  style={{ marginLeft: '-2px', marginRight: '-2px' }}
                                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+19/checkbox+checked.svg'
                                />
                              ) : (
                                <img
                                  className={styles.no_trans}
                                  style={{ marginLeft: '0px' }}
                                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+18/Rectangle.svg'
                                />
                              )}
                              <p className={styles.report_reason_txt} key={r.reason}>
                                {r.reason}
                              </p>
                            </div>
                          </button>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className='row justify-content-center'>
                    <div className='col'>
                      <p className={`${styles.dropdown_label}`}>Description (optional)</p>
                      <div className='col comment-col de-pad'>
                        <div ref={ref} style={{ position: 'relative' }}>
                          <textarea
                            className={`material-input marg-bottom-10 ${styles.dropdown_label}`}
                            ref={ref}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{
                              overflow: 'hidden',
                              maxWidth: '100%',
                              zIndex: '0',
                              height: '100px',
                              marginTop: '0px',
                            }}
                            placeholder='Please provide further context...'
                            value={description}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`${styles.cta_wrap}`}>
                    <button
                      onClick={() => setClickModal(false)}
                      className={`${styles.cancel} ${styles.cancel_report} liner`}
                    >
                      Cancel
                    </button>
                    <RequestButton
                      disabled={description !== '' || checkedItems.length !== 0 ? '' : 'disabled'}
                      classnames={styles.report_post}
                      request={submitUserDeactivate}
                      cta='Deactivate'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
