import { connect } from 'react-redux';
import { templateAction } from '../../actions/users';
import ConversationRoom from '../../components/users/conversation_room';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  templateAction: (item) => dispatch(templateAction(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationRoom);
