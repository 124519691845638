export const createSurgeonReferredMentorMatch = (params, successCallback) => {
  fetch(`/api/v1/surgeon_referred_mentor_matches`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getSurgeonReferredMentorMatch = (id, successCallback) => {
  fetch(`/api/v1/surgeon_referred_mentor_matches/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getSurgeonReferredMentorMatches = (params, successCallback) => {
  fetch(`/api/v1/surgeon_referred_mentor_matches?${objToQueryString(params)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const updateSurgeonReferredMentorMatch = (id, params, successCallback) => {
  fetch(`/api/v1/surgeon_referred_mentor_matches/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ surgeon_referred_mentor_match: params }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const addMatchedMentor = (id, successCallback) => {
  fetch(`/api/v1/surgeon_referred_mentor_matches/${id}/patient_partner`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => successCallback(data));
};

export const getMatchedSurgeon = (id, successCallback) => {
  fetch(`/api/v1/surgeon_referred_mentor_matches/${id}/matched_surgeon`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => successCallback(data));
};

export const requestAppointment = (id, successCallback) => {
  fetch(`/api/v1/surgeon_referred_mentor_matches/${id}/request_appointment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => successCallback(data));
};
