import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PlatformModal from '../../ui_kit/modals/platform_modal';
import InputWithOptions from '../../ui_kit/utilities/input_with_options';
import DatePickerWrapper from '../../date_picker_wrapper';
import Todpicker from '../../todpicker';
import LoadingScreen from '../../loading_screen';
import SurgeonsColumn from '../../surgeons_column';
import { getSurgeon } from '../../../api/surgeons';
import styles from './index.module.css';
import { createPatientAssistantInfo } from '../../../api/patient_assistant_infos';

export default function RequestAppointmentModal({ surgeonId, surgeonName, setModal, patientName }) {
  let history = useHistory();
  const [view, setView] = useState('loading');
  const [surgeon, setSurgeon] = useState({});
  const [insuranceCarrier, setInsuranceCarrier] = useState('');
  const insurances = [...gon.all_insurances];
  const [apptDate, setApptDate] = useState('');
  const timePlaceholder = 'Preferred appt. time';
  const [apptTimeOfDay, setApptTimeOfDay] = useState(timePlaceholder);
  const options = [timePlaceholder, 'Morning (9:00-12:00pm)', 'Afternoon (1:00-5:00pm)'];
  const [conversationTopics, setConversationTopics] = useState('');

  const getNextWork = (d) => {
    d.setDate(d.getDate() + 1); // tomorrow
    if (d.getDay() == 0) d.setDate(d.getDate() + 1);
    else if (d.getDay() == 6) d.setDate(d.getDate() + 2);
    return d;
  };
  const minDate = getNextWork(getNextWork(new Date()));

  useEffect(() => {
    getSurgeon(surgeonId, (data) => {
      console.log('data', data);
      setSurgeon(data);
      setView('intro');
    });
  }, []);

  if (view === 'loading') {
    return (
      <PlatformModal
        title={`${surgeonName}'s patients are here for you!`}
        setModal={setModal}
        content={<LoadingScreen />}
      />
    );
  }

  if (view === 'intro') {
    return (
      <PlatformModal
        title={`${surgeonName}'s patients are here for you!`}
        setModal={setModal}
        width={8}
        content={
          <div className='container'>
            <div className='row'>
              <div className={`col-12 marg-bottom-20`}>
                <p className={`marg-bottom-10 ${styles.txt}`}>
                  {surgeon.name} has invited past patients like {patientName} to help patients like you throughout your
                  journey.
                </p>
                <p style={{ fontWeight: 700 }} className={`${styles.txt}`}>
                  Schedule an appointment with {surgeon.name} to see how he can help you!
                </p>
              </div>
              <div className='col-12'>
                <SurgeonsColumn
                  surgeon={surgeon}
                  showRequestAnAppointment={false}
                  seeMoreLink={`/surgeon/${surgeon.slug}?back-to-questionnaire=true`}
                  onQuestionnaire
                />
              </div>
            </div>
          </div>
        }
        submitCta={`Request appointment`}
        onSubmit={() => {
          setView('info');
        }}
      />
    );
  }

  if (view === 'alternative-options') {
    return (
      <PlatformModal
        title={`Alternative options for care`}
        setModal={setModal}
        content={
          <>
            <p className={`marg-bottom-15 ${styles.txt}`}>
              As it turns out, {surgeon.name} is not in-network with your insurance carrier. We want to make sure you're
              getting the care you need.
            </p>
            <p className={`marg-bottom-10 ${styles.txt}`}>Here are some options you can explore:</p>
            <ol style={{ marginBottom: '-30px' }}>
              <li className={styles.txt}>Talk to {surgeon.name} about Cash Pay options</li>
              <li className={styles.txt}>Search a gallery of vetted physicians who accept your insurance</li>
            </ol>
          </>
        }
        submitCta={`See other doctors`}
        onSubmit={() => {
          history.push(`/all-surgeons`);
        }}
        cancelCta={`Cash pay`}
        onCancel={() => {
          createPatientAssistantInfo(
            {
              surgeon_administrative_assistant_id: surgeon.surgeon_administrative_assistant_id,
              requested_appt_date: apptDate,
              requested_appt_time_of_date: apptTimeOfDay,
              extra_info: conversationTopics,
              insurance_carrier: '',
              user_id: null,
              create_initial_appointment_request_message: false,
            },
            (patientAssistantInfo) => {
              history.push(`/member/conversations/${patientAssistantInfo.conversation.uuid}`);
            }
          );
        }}
      />
    );
  }

  if (view === 'checking-insurance') {
    return (
      <PlatformModal
        title={``}
        setModal={setModal}
        onCancel={null}
        content={
          <LoadingScreen
            content={
              <h5 className={`marg-top-20`}>
                We're making sure {surgeon.name} is in-network with your insurance provider
              </h5>
            }
          />
        }
      />
    );
  }

  let disabled = 'disabled';
  if (apptDate !== '' && apptDate !== null && apptTimeOfDay !== timePlaceholder && insuranceCarrier !== '') {
    disabled = '';
  }

  return (
    <PlatformModal
      title={`Request an appointment with ${surgeon.name}`}
      setModal={setModal}
      submitDisabled={disabled}
      content={
        <>
          <p>{surgeon.name}'s office will reach out within 24-48 hours to confirm your appointment</p>
          <InputWithOptions
            value={insuranceCarrier}
            setValue={setInsuranceCarrier}
            options={insurances}
            name={'insuranceCarrier'}
            label={'Insurance Provider'}
            noMatchTxt={"I don't see my insurance provider"}
            light={false}
          />
          <DatePickerWrapper
            selected={apptDate}
            onChange={(date) => setApptDate(date)}
            minDate={minDate}
            isWeekday
            wrapperClassName={'react-datepicker-wrapper material-input marg-bottom-30'}
            placeholderText={'Preferred appt. date'}
          />
          <Todpicker
            value={apptTimeOfDay}
            onChange={(event) => {
              setApptTimeOfDay(event.target.value);
            }}
            options={options}
          />
          <div className='text-box-wrap'>
            <p style={{ fontSize: '14px', color: '#333333', marginLeft: '10px', color: '#333333' }}>
              Notes to doctor (optional)
            </p>
            <textarea
              className={`autofocus message-box material-input`}
              placeholder='Tell us here...'
              value={conversationTopics}
              onChange={(e) => setConversationTopics(e.target.value)}
            ></textarea>
          </div>
        </>
      }
      onSubmit={() => {
        setView('checking-insurance');
        setTimeout(() => {
          if (
            surgeon.insurance_carriers.includes(insuranceCarrier) ||
            insuranceCarrier === "I don't see my insurance provider"
          ) {
            createPatientAssistantInfo(
              {
                surgeon_administrative_assistant_id: surgeon.surgeon_administrative_assistant_id,
                requested_appt_date: apptDate,
                requested_appt_time_of_date: apptTimeOfDay,
                extra_info: conversationTopics,
                insurance_carrier: insuranceCarrier,
                user_id: null,
                create_initial_appointment_request_message: false,
              },
              (patientAssistantInfo) => {
                history.push(`/member/conversations/${patientAssistantInfo.conversation.uuid}`);
              }
            );
          } else {
            setView('alternative-options');
          }
        }, 3000);
      }}
    />
  );
}
