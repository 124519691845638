import { compact } from '../helpers';

export const getSurgeonAdministrativeAssistant = (id, successCallback) => {
  fetch(`/api/v1/surgeon_administrative_assistants/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getSurgeonRoster = (successCallback) => {
  fetch(`/api/v1/surgeon_administrative_assistants/surgeon_roster`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getCommunityInsights = (params, successCallback) => {
  fetch(`/api/v1/surgeon_administrative_assistants/community_insights?${objToQueryString(compact(params))}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getPatientDemographics = (params, successCallback) => {
  fetch(`/api/v1/surgeon_administrative_assistants/patient_demographics?${objToQueryString(compact(params))}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getNumReferrals = (params, successCallback) => {
  fetch(`/api/v1/surgeon_administrative_assistants/num_referrals?${objToQueryString(compact(params))}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getConversations = (params, successCallback) => {
  fetch(`/api/v1/surgeon_administrative_assistants/conversations?${objToQueryString(compact(params))}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getConnectedPatients = (params, successCallback) => {
  fetch(`/api/v1/surgeon_administrative_assistants/connected_patients?${objToQueryString(compact(params))}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
