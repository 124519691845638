import React, { useState } from 'react';

export default function ChooseManyOptionsWithNone({
  answers,
  keyForAnswer,
  onSubmit,
  onBack,
  gtm,
  options,
  title,
  para,
}) {
  const defaultValues = answers[keyForAnswer] ? answers[keyForAnswer] : [];
  const [values, setValues] = useState(answers[keyForAnswer] ? answers[keyForAnswer] : []);
  const isNoneValue = (o) => {
    return (o === 'None Of These' || o.startsWith('None of the above')) && options.includes(o);
  };
  const noneChosen = values.some(isNoneValue);

  const onClick = (option) => () => {
    if (isNoneValue(option)) {
      if (noneChosen === true) {
        setValues([]);
      } else {
        setValues([option]);
      }
      return;
    }
    if (values.includes(option)) {
      setValues(values.filter((c) => c !== option));
    } else {
      setValues(values.concat([option]));
    }
  };

  return (
    <div className='row'>
      <div className='col-sm-2'></div>
      <div className='col-sm-8'>
        <form id='plans'>
          <h5 className='question'>{title}</h5>
          <p className='marg-bottom-20'>{para}</p>
          {options.map((option) => (
            <div key={option} className='tile two-column'>
              <label
                key={option}
                className={`preexisting ${values.includes(option) ? 'activeCheckbox' : ''}`}
                disabled={noneChosen && !isNoneValue(option) ? 'disabled' : ''}
              >
                <input
                  disabled={noneChosen && !isNoneValue(option) ? 'disabled' : ''}
                  onClick={onClick(option)}
                  type='checkbox'
                  name={option}
                  value='value'
                />
                {option}
              </label>
            </div>
          ))}
          <div className='row button-holder'>
            {onBack && (
              <button
                data-gtm-click-label={`${gtm} Back`}
                className='back col-6'
                type='button'
                onClick={() => onBack()}
              >
                Back
              </button>
            )}
            <button
              data-gtm-click-label={`${gtm} Submit`}
              disabled={values.length > 0 ? '' : 'disabled'}
              className={` submit col-6`}
              type='submit'
              onClick={(e) => {
                e.preventDefault();
                onSubmit({ ...answers, [keyForAnswer]: [...values] });
              }}
            >
              Next
            </button>
          </div>
        </form>
      </div>
      <div className='col-sm-2'></div>
    </div>
  );
}
