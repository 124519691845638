import React from 'react';
import '../../../questionnaire/questionnaire.css';

export default function GetStartedSurgeonReferred({ surgeon, onSubmit }) {
  let img = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/logos/svg/dark-blue-logo.svg';
  if (surgeon.slug === 'reactiv8') {
    img = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/logos/png/reactiv83.svg';
  }

  return (
    <>
      <div className='row justify-content-center marg-bottom-50'>
        <img alt='PP-logo' src={img} />
      </div>
      <div className='row justify-content-center marg-bottom-20'>
        <div className='col'>
          <p style={{ fontSize: '24px', fontWeight: '700' }}>{surgeon.name}'s past patient mentors are here for you.</p>
        </div>
      </div>
      <div className='row justify-content-center marg-bottom-20'>
        <div className='col'>
          <ol style={{ fontColor: 'black', fontSize: '18px' }}>
            <li>Create your free account.</li>
            <li>Meet your patient mentor.</li>
            {surgeon.name === 'New York Bariatric Group' && (
              <li>Chat and ask questions about the procedure you're considering.</li>
            )}
            {surgeon.name !== 'New York Bariatric Group' && (
              <li>Chat and get support throughout your healthcare journey.</li>
            )}
          </ol>
        </div>
      </div>
      <div className='row justify-content-center marg-bottom-40'>
        <div className='col'>
          <button style={{ width: '100%' }} onClick={onSubmit}>
            Get Started
          </button>
        </div>
      </div>
    </>
  );
}
