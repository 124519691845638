import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './patient_partner_agreement.css';

export default function PatientPartnerAgreement() {
  return (
    <div className='container-fluid nopadding marg-top-for-transparent-header'>
      <div className='row'>
        <div className='col-sm-1'></div>
        <div className='col-sm-10'>
          <section className='terms-section'>
            <h1>
              <b>​PatientPartner&trade; PARTICIPATION AGREEMENT</b>
            </h1>
            <p>
              <b>
                IMPORTANT – THIS IS A LEGAL AGREEMENT BETWEEN YOU (referenced herein with “you” or with “your” or
                “user”) AND RIGHTDEVICE, INC. (referenced herein as “Rightdevice”). YOU SHOULD READ CAREFULLY THE
                FOLLOWING TERMS OF USE (referenced herein as the “Agreement”) BECAUSE THEY GOVERN YOUR PARTICIPATION IN
                THE PatientPartner&trade; PROGRAM OFFERED BY RIGHTDEVICE (referenced herein as the
                “PatientPartner&trade; Program”). RIGHTDEVICE IS WILLING TO GRANT YOU THE RIGHT TO PARTICIPATE IN THE
                PatientPartner&trade; PROGRAM ONLY ON THE CONDITION THAT YOU AGREE TO ALL OF THE TERMS AND CONDITIONS
                SET FORTH IN THIS AGREEMENT. IF YOU DO NOT AGREE WITH ALL OF THE TERMS AND CONDITIONS SET FORTH IN THIS
                AGREEMENT, YOU ARE NOT GRANTED PERMISSION TO PARTICIPATE IN THE PatientPartner&trade; PROGRAM. BY
                CLICKING “I ACCEPT” AT THE END OF THIS AGREEMENT OR BY ACCESSING, PARTICIPATING OR USING ANY PORTION OF
                THE PatientPartner&trade; PROGRAM, YOU ACKNOWLEDGE THAT YOU HAVE READ THIS AGREEMENT, THAT YOU
                UNDERSTAND IT AND ITS TERMS AND CONDITIONS, AND THAT YOU AGREE TO BE BOUND LEGALLY BY IT AND ITS TERMS
                AND CONDITIONS.
              </b>
            </p>
            <p>
              I am electing to participate as a PatientPartner Mentor for the PatientPartner program, a patient support
              and education program developed and funded by PatientPartner. In so doing, I am prepared to fulfill the
              following responsibilities:
            </p>
            <ol>
              <li>Answer patient inquiries by sharing my personal story of recovery with others.</li>
              <li>
                Embody the PatientPartner mission to the best of my ability by providing hope and support to patients
                suffering from various health conditions.
              </li>
            </ol>
            <p>
              As a PatientPartner Mentor, I acknowledge and understand the following guidelines and will communicate
              these key points when corresponding with patients:
            </p>
            <ol>
              <li>I am a volunteer.</li>
              <li>I am not a medical professional and therefore cannot provide medical opinions or recommendations.</li>
              <li>I am here to support and assist incoming patient inquiries.</li>
              <li>I can only speak on behalf of my own experiences.</li>
            </ol>
            <p>
              Not all treatment outcomes and experiences are the same. I cannot provide specific product/treatment
              recommendations, but I can suggest a patient to visit the PatientPartner website at{' '}
              <a style={{ color: 'black', fontSize: '14px' }} href='https://www.PatientPartner.com'>
                https://www.PatientPartner.com
              </a>{' '}
              to learn more about their treatment options or speak to a physician.
            </p>
            <p>
              If necessary, I agree to allow the PatientPartner team to release my personal phone number and email
              address for the purpose of the PatientPartnerTM program in order for patients to contact me. I recognize
              that my personal contact information will not be published online and will not be sold or solicited, but
              will be shared with a patient once the patient has been properly screened.
            </p>
            <p>
              I acknowledge that I’ve been provided the guidelines, and have agreed to the media release program FAQs,
              and the PatientPartner Participation Agreement which can be found on the PatientPartner site:{' '}
              <a
                style={{ color: 'black', fontSize: '14px' }}
                href='https://www.patientpartner.com/patient-partner-agreement'
              >
                https://www.patientpartner.com/patient-partner-agreement
              </a>{' '}
              . I understand that all participating patients must agree to the proper terms and conditions before
              speaking with a Patient Partner.
            </p>
            <p>
              By agreeing to this document, I am agreeing to all of the terms set forth in all documents and links
              provided to me and pledge my commitment to abide by the mission and principles of the PatientPartner, Inc.
              PatientPartner program and spread the message of hope, transparency, and comfort.
            </p>

            <p>
              <b>1. LICENSE GRANT.</b>
            </p>
            <p>
              Subject to your continued compliance with this Agreement, Rightdevice provides to you a personal,
              revocable, limited, non-exclusive, non-transferable, and non-sublicensable license to access and use
              materials for the PatientPartner&trade; Program (as made available by Rightdevice), including the right to
              access the PatientPartner&trade; website (the “Program Website”), and make single copies or prints of the
              content, information and materials provided to you through the PatientPartner&trade; Program (the “Program
              Materials”) for your personal, internal use only. You acknowledge and agree to the following: (i)
              Rightdevice has the right to control and direct the means, manner, and method by which the
              PatientPartner&trade; Program is provided; (ii) Rightdevice may, from time to time engage independent
              contractors, consultants, or subcontractors to aid Rightdevice in providing the PatientPartner&trade;
              Program or use thereof; (iii) Rightdevice has the right to provide the PatientPartner&trade; Program to
              others; and (iv) online access to the Program Website shall be made available from Rightdevice’s
              designated online address(es).
            </p>
            <p>
              <b>2. PARTICIPATION REQUIREMENTS.</b>
            </p>
            <p>
              In order to participate in the PatientPartner&trade; Program, Rightdevice requires that you provide
              certain information and complete the registration and submission procedures fully in order to create a
              PatientPartner&trade; Program account. Upon completing any registration forms provided by Rightdevice,
              Rightdevice will provide a confirmatory e-mail (or other communication) notifying you of whether or not
              your registration has been approved. Rightdevice may deny your participation in the PatientPartner&trade;
              Program in its sole discretion if your registration, expressions of consent, or submitted information do
              not meet Rightdevice’s requirements or otherwise violate the terms and conditions of this Agreement. By
              filling out a registration or submission form and/or participating in the PatientPartner&trade; Program,
              you represent that you have the right, power, and authority (including be of the legal age of majority) to
              create a binding agreement with Rightdevice and fulfill your obligations under this Agreement and that you
              agree to abide by all applicable local, state, national laws and regulations with respect to your
              participation in the PatientPartner&trade; Program. Registration for access to or use of, the
              PatientPartner&trade; Program tools, resources, or materials may also require additional access
              credentials or adherence to other particular access requirements as designated by Rightdevice in its sole
              discretion from time to time. You covenant or represent and warrant that (i) you will at all times provide
              true, accurate, current, and complete information when submitting information to Rightdevice through the
              PatientPartner&trade; Program; and (ii) you will at all times comply with the terms and conditions of this
              Agreement and any other terms and conditions for participation in the PatientPartner&trade; Program or for
              access to or use of its associated tools, resources, or materials. You further represent that you will act
              in a manner that does not cause disruption of any PatientPartner&trade; Program event or in a manner that
              interferes with any other individual’s participation in the PatientPartner&trade; Program. Any breach of
              the foregoing representations and warranties entitles Rightdevice to immediately terminate this Agreement
              and/or seek any and all remedies available at law or equity. You also acknowledge and agree that use of
              the Internet and access to the Program Website is solely at your own risk. While Rightdevice has
              endeavored to create a secure and reliable web site, you should understand that the confidentiality of any
              communication or material transmitted to/from the Program Website over the Internet or other form of
              global communication network cannot be guaranteed. You must make your own determination as to such issues.
            </p>
            <p>
              <b>3. RESTRICTIONS ON USE.</b>
            </p>
            <p>
              All rights not expressly granted under this Agreement are hereby reserved to Rightdevice. Accordingly, you
              are hereby prohibited from participating in the PatientPartner&trade; Program in any manner that is not
              expressly and unambiguously authorized by this Agreement. You therefore may not modify, reproduce,
              duplicate, copy, download, store, further transmit, disseminate, transfer, disassemble, broadcast,
              publish, remove or alter any proprietary notices or labels, license, sublicense, sell, mirror, frame,
              rent, lease, private label, grant a security interest in, create derivative works of, or otherwise exploit
              the Program Website and Program Materials, or any portion of the Program Website and Program Materials
              without Rightdevice’s prior written consent except as expressly and unambiguously authorized herein.
            </p>
            <p>
              <b>4. NO MEDICAL ADVICE.</b>
            </p>
            <p>
              You should always seek the advice of a qualified health care professional regarding the diagnosis and
              treatment of medical conditions or other health care advice or information. The PatientPartner&trade;
              Program is a community which connects participants with information, resources, case managers, health care
              professionals, and other participants. The PatientPartner&trade; Program, the Program Materials, the
              Program Website, and the PatientPartner&trade; Partners are provided for informational purposes only and
              are not intended to be, or be a substitute for, professional medical advice, diagnosis, or treatment.
              PatientPartner&trade; Partners participating in the PatientPartner&trade; Program are non-paid volunteers,
              do not represent Rightdevice, and can only speak about their own experiences. Your experience may differ.
              Nate “Rock” Quarry and Bill Walton are paid spokesmen of Rightdevice.
            </p>
            <p>
              <b>5. LINKS TO OTHER SITES</b>
            </p>
            <p>
              The Program Website may connect to certain third party websites or online networks (collectively, “Third
              Party Sites”). These Third Party Sites have not necessarily been reviewed by Rightdevice and are owned,
              controlled and/or maintained solely by third parties over whom Rightdevice exercises no control. Your
              correspondence or any other dealings with third parties found through any Third Party Site on the Program
              Website is solely between you and such third party. Accordingly, Rightdevice hereby expressly disclaims
              and shall not have any liability or responsibility for any Third Party Sites.
            </p>
            <p>
              <b>6. MOBILE SERVICES</b>
            </p>
            <p>
              The PatientPartner&trade; Program may offer you various tools that are available to you via your mobile
              phone or other mobile computing device (collectively, “Mobile Services”). Please note that your mobile
              carrier’s normal messaging, data, and other rates and fees will apply to your use of the Mobile Services.
              In addition, downloading, installing, or using certain Mobile Services may be prohibited or restricted by
              your mobile carrier, and not all Mobile Services may work with all carriers or devices or in all
              locations. Therefore, you are solely responsible for checking with your mobile carrier to determine if the
              Mobile Services are available for your mobile devices; what restrictions, if any, may be applicable to
              your use of the Mobile Services; and how much such use will cost you. Nevertheless, all use of the
              PatientPartner&trade; Program, the Program Website and its related Mobile Services shall be strictly in
              accordance with this Agreement. Some features of the Mobile Services make use of detailed location and
              route information, for example in the form of GPS signals and other information sent by your mobile device
              on which the Mobile Services are used, installed and activated. These features cannot be provided without
              utilizing this technology.
            </p>
            <p>
              <b>7. FEEDBACK AND SUBMISSIONS.</b>
            </p>
            <p>
              Rightdevice welcomes your feedback and suggestions about Rightdevice’s products and services or with
              respect to how to improve the PatientPartner&trade; Program. By transmitting any suggestions, information,
              material, or other content (collectively, “Feedback”) to Rightdevice, you represent and warrant that such
              feedback does not infringe or violate the intellectual property or proprietary rights of any third party
              (including, without limitation, patents, copyrights, or trademark rights) and that you have all rights
              necessary to convey to Rightdevice and enable Rightdevice to use such Feedback. In addition, any Feedback
              will be deemed to include a royalty-free, perpetual, irrevocable, transferable, non-exclusive right and
              license for Rightdevice to adopt, publish, reproduce, disseminate, transmit, distribute, copy, use, create
              derivative works, and display (in whole or in part) worldwide, or act on such feedback without additional
              approval or consideration, in any form, media, or technology now known or later developed for the full
              term of any rights that may exist in such content, and you hereby waive any claim to the contrary.
            </p>
            <p>
              <b>8. PROPRIETARY RIGHTS.</b>
            </p>
            <p>
              This Agreement provides only a limited right to access and use the Program Materials and the Program
              Website in accordance with the terms of this Agreement. Accordingly, you hereby agree that Rightdevice
              transfers no ownership or intellectual property interest or title in and to the Program Materials, the
              Program Website or any other Rightdevice intellectual property to you or anyone else in connection with
              your participation in the PatientPartner&trade; Program. All text, graphics, user interfaces, visual
              interfaces, photographs, sounds, artwork, computer code (including html code), programs, software,
              products, information, and documentation as well as the design, structure, selection, coordination,
              expression, “look and feel,” and arrangement of any content contained on or available through the Program
              Website are exclusively owned, controlled, and/or licensed by Rightdevice, Inc. or its licensor(s) or
              affiliate(s). RIGHTDEVICE, PatientPartner&trade; and all other marks identifying the products or services
              of Rightdevice are proprietary trademarks of Rightdevice, Inc., and any use of such marks, including,
              without limitation, as domain names, account identifiers, or in connection with any search engine
              optimization practice(s), without the prior express written permission of Rightdevice is hereby strictly
              prohibited.
            </p>
            <p>
              <b>9. PRIVACY POLICY.</b>
            </p>
            <p>
              Participation in the PatientPartner&trade; Program and access through the Program Website may require you
              to submit certain personally identifiable information. As a result, you agree to comply with the Privacy
              Policy contained herein and located{' '}
              <a style={{ color: 'black', fontSize: '14px' }} href='www.patientpartner.com/privacy'>
                HERE
              </a>
            </p>
            <p>
              <b>10. DISCLAIMER.</b>
            </p>
            <p>
              THE PatientPartner&trade; PROGRAM, PROGAM MATERIALS AND PROGRAM WEBSITE ARE PROVIDED ON AN “AS-IS” BASIS
              AND MAY INCLUDE ERRORS, OMISSIONS, OR OTHER INACCURACIES. RIGHTDEVICE HEREBY EXPRESSLY DISCLAIMS ALL
              WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OR ANY OTHER IMPLIED WARRANTY UNDER THE UNIFORM COMPUTER
              INFORMATION TRANSACTIONS ACT AS ENACTED BY ANY STATE. RIGHTDEVICE ALSO MAKES NO REPRESENTATIONS OR
              WARRANTIES THAT THE PatientPartner&trade; PROGRAM, PROGRAM MATERIALS AND PROGRAN WEBSITE WILL OPERATE
              ERROR-FREE, UNINTERRUPTED, OR IN A MANNER THAT WILL MEET YOUR PARTICULAR REQUIREMENTS AND/OR NEEDS.
              THEREFORE, YOU ASSUME THE ENTIRE RISK REGARDING THE QUALITY AND/OR PERFORMANCE OF THE
              PatientPartner&trade; PROGRAM, PROGAM MATERIALS AND PROGRAM WEBSITE.
            </p>
            <p>
              <b>11. LIMITATION OF LIABILITY.</b>
            </p>
            <p>
              YOU AGREE THAT IN NO EVENT SHALL RIGHTDEVICE BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR
              CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH PARTICIPATION IN OR THE USE OF THE
              PatientPartner&trade; PROGRAM BY YOU OR ANYONE ELSE, WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR
              OTHERWISE, EVEN IF RIGHTDEVICE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU ALSO ACKNOWLEDGE
              AND AGREE THAT RIGHTDEVICE SHALL NOT BE RESPONSIBLE FOR ANY FAILURES, INOPERABILITY, DELAYS, OR PROBLEMS
              CAUSED BY YOUR FAILURE TO PROVIDE OR OBTAIN THE NECESSARY RIGHTS CLEARANCE FOR ANY INFORMATION YOU SUBMIT
              TO THE PatientPartner&trade; PROGRAM IN A TIMELY OR ACCURATE MANNER. MOREOVER, RIGHTDEVICE ASSUMES NO
              RESPONSIBILITY FOR THE DELETION OF OR FAILURE TO STORE ANY CONTENT OR INFORMATION. WITHOUT LIMITATION OF
              THE FOREGOING, THE TOTAL LIABILITY OF RIGHTDEVICE FOR ANY REASON WHATSOEVER RELATED TO PARTICIPATION OR
              USE OF THE PatientPartner&trade; PROGRAM OR FOR ANY CLAIMS RELATING TO THIS AGREEMENT SHALL NOT EXCEED
              $500 (USD).
            </p>
            <p>
              <b>12. INJUNCTIVE RELIEF. </b>
            </p>
            <p>
              You acknowledge that any breach, threatened or actual, of this Agreement, including, without limitation,
              violations or infringement of Rightdevice’s intellectual property or proprietary rights, may cause
              irreparable injury to Rightdevice, whereby such injury would not be quantifiable in monetary damages, and
              Rightdevice would not have an adequate remedy at law. In the event of such injury or potential for such
              injury you therefore hereby agree that Rightdevice shall be entitled, in addition to other available
              remedies, to seek and be awarded an injunction or other appropriate equitable relief from a court of
              competent jurisdiction restraining any breach, threatened or actual, of your obligations under any
              provision of this Agreement.
            </p>
            <p>
              <b>13. THIRD-PARTY PRODUCTS/SERVICES.</b>
            </p>
            <p>
              Rightdevice, in its sole discretion, may feature and/or post the advertisements of third parties on the
              Program Materials or Program Website and/or feature on the Program Materials or Program Website,
              materials, programs, products, and services provided by third parties. Rightdevice makes no
              representations with respect to, nor does it guarantee or endorse, the quality, non-infringement,
              accuracy, completeness, timeliness, reliability, or correct sequencing of such third-party materials,
              programs, products, and services or any other materials, programs, products, and services which such
              third-party materials, products, and services may access. Your correspondence or any other dealings with
              third parties found on the Program Materials or Program Website are solely between you and such third
              party. Accordingly, Rightdevice expressly disclaims responsibility and liability for all third-party
              provided materials, programs, products, and services contained on or accessed through the Program
              Materials or Program Website, and you agree that Rightdevice shall not be responsible for any loss or
              damage of any sort incurred as a result of any such dealings or as the result of the presence of such
              third parties on the Program Materials or Program Website.
            </p>
            <p>
              <b>14. SECURITY & ENFORCEMENT.</b>
            </p>
            <p>
              Any actual or attempted use of the Program Website by you in violation of this Agreement may result in
              criminal and/or civil prosecution, including, without limitation, punishment under the Computer Fraud and
              Abuse Act of 1986 under U.S. federal law. Rightdevice reserves the right in its sole discretion to review,
              monitor, and/or record any information relating to your use of the Program Website (“User Information”)
              without any prior notice to or permission from you, including, without limitation, by archiving content
              and/or communications submitted to and/or sent by you through the Program Website. Rightdevice may share
              any User Information we obtain from you with any law enforcement organization in connection with any
              investigation or prosecution of possible criminal or unlawful activity. Rightdevice will also disclose
              User Information as required by any court order and/or subpoena. In addition, Rightdevice hereby reserves
              the right in its sole direction to at any time and without notice modify, suspend, terminate, and/or
              interrupt operation of or access to the Program Website, or any portion thereof, in order to protect the
              Program Website, Rightdevice intellectual property, Rightdevice, or the business interests of Rightdevice
              and/or its members and affiliates.
            </p>
            <p>
              <b>15. MATERIALS YOU POST OR PROVIDE; COMMUNICATION MONITORING</b>
            </p>
            <p>
              You may be able to post or submit reviews, comments, questions, direct messages, photographs, videos
              (including videos created through a third-party tool) and other materials on or through the Site
              (collectively, "Submission" or "Submissions"). Your Submissions, (including to the extent applicable
              personally identifiable information), are made voluntarily and are subject to the Rightdevice Code of
              Conduct and the Rightdevice Privacy Policy. You understand Rightdevice is not required to treat any of
              your Submissions as confidential. In addition, you understand and agree that your Submissions (including
              your username associated with your Submissions) are not confidential and are publicly available for anyone
              to view on the Site.
            </p>
            <p>
              Rightdevice does not claim ownership of the Submission(s) you post or submit on the Site. With the
              exception of any information you directly submit through a health care provider’s profile or inquiry form,
              by posting or otherwise providing your Submission on the Site, you are hereby expressly granting
              Rightdevice a nonexclusive, irrevocable, worldwide, perpetual, royalty free license (including sublicense)
              to use, copy, distribute, publicly display, publicly perform, modify (including create derivative works)
              or transmit (including digital transmission of a sound recording) your Submission, in connection with the
              Site and the Rightdevice business, including without limitation promoting and redistributing part or all
              of the Site or the Rightdevice business, in any media formats and through any media channels now known or
              hereafter developed. You also grant Rightdevice permission to publish and use your name, likeness and
              voice in connection with your Submission. In addition, by uploading a Submission to the Site, you hereby
              waive any rights of privacy or publicity. You also grant Rightdevice the right to grant any of the above
              rights to other persons or entities, including for the purpose of promoting the Site or the Rightdevice
              business, without any compensation or obligation to you.
            </p>
            <p>
              You also grant Rightdevice the right and authority to send takedown notices (on your behalf) related to
              your Submission to any individual or entity under the Digital Millennium Copyright Act. Rightdevice will
              not pay you for your Submission.
            </p>
            <p>
              You also understand and agree that Rightdevice may, in its sole discretion, refuse, or remove part or all
              of your Submission from the Site at any time if Rightdevice considers the Submission to be illegal,
              offensive, harassing, infringing, inappropriate or otherwise violates these Terms or the Rightdevice Code
              of Conduct or other policies. For each Submission, you represent and warrant that you have all rights
              necessary for you to post or submit the Submission and to grant the licenses and permissions in this
              section and that you are solely responsible for the content of your Submission. Further, you represent and
              warrant that your Submission does not violate any law.
            </p>
            <p>
              Rightdevice reserves the right to review and monitor Submissions but is under no obligation to do so. In
              addition, Rightdevice is not responsible for screening, monitoring or editing Submissions. However, we may
              monitor your Submissions and may disclose information about you, including the contents of such
              Submission, if we deem it necessary to
            </p>
            <ol>
              <li>Conform to legal requirements or respond to legal process.</li>
              <li>Ensure your compliance with these Terms or other Rightdevice guidelines or policies.</li>
              <li>
                Protect the rights, property, or interests of Rightdevice, its employees, its users, or the public. You
                may also request information directly from a specific health care provider by submitting an inquiry
                through their profile or inquiry form. You understand these requests, including personally identifiable
                information, are made voluntarily and are subject to the Rightdevice Privacy Policy. There is no
                guarantee that a health care provider will respond to any request submitted through their profile or
                inquiry form. In addition, by submitting a request to a health care provider through the Site, you
                understand that Rightdevice may contact you by phone, email or text message about your request. All
                standard message and data charges will apply to any text messages.
              </li>
            </ol>
            <p>
              <b>16. GOVERNING LAW.</b>
            </p>
            <p>
              This Agreement has been made in and will be construed and enforced in accordance with the laws of the
              State of California as applied to agreements entered into and completely performed in the State of
              California. You agree to the personal jurisdiction by and venue in any federal or state court in the State
              of California, County of San Diego and waive any objection to such jurisdiction or venue and further to a
              jury trial in any action. You also acknowledge and agree that any applicable state law implementation of
              the Uniform Computer Information Transactions Act (including any available remedies or laws) shall not
              apply to this Agreement and is hereby disclaimed. Any claim you might have against Rightdevice must be
              brought within one (1) year after the cause of action arises, or such claim or cause of action is barred.
              In any legal proceeding brought by Rightdevice, Rightdevice shall have the right to seek and be awarded
              all reasonable attorneys’ fees and costs in addition to any other relief, at law or in equity, to which
              Rightdevice may be entitled. A printed version of this Agreement and of any related notice given in
              electronic form shall be admissible in judicial or administrative proceedings based upon or relating to
              this Agreement to the same extent as other documents and records originally generated and maintained in
              printed form. Please print a copy of this Agreement or contact Rightdevice if you wish to receive a
              printed copy of this Agreement.
            </p>
            <p>
              <b>17. TERM AND TERMINATION.</b>
            </p>
            <p>
              This Agreement will take effect at the time you click or check “SUBMIT”, or begin using any part of the
              PatientPartner&trade; Program including the Program Materials and the Program Website, whichever is
              earliest. This Agreement will terminate automatically if (i) you fail to comply with any of its terms and
              conditions; or (ii) cease all use of the PatientPartner&trade; Program. Termination will be effective
              without notice. In addition, Rightdevice may in its sole discretion terminate this Agreement upon notice
              to you for any or no reason. Upon termination of this Agreement, any and all right(s) to participate in
              the PatientPartner&trade; and use the Program Materials and Program Website shall immediately cease and
              you must promptly delete or destroy all copies of the Program Materials and the Program Website in your
              possession or control. Sections 3 and 7-19 will survive the termination of this Agreement.
            </p>
            <p>
              <b>18. WAIVER & SEVERABILITY.</b>
            </p>
            <p>
              Rightdevice’s failure to insist on upon strict performance of any of the terms and conditions of this
              Agreement by you will not operate as a waiver of any subsequent or other default or failure of
              performance. If any part of this Agreement is determined by a court of competent jurisdiction to be
              invalid or unenforceable pursuant to applicable law or court order including, but not limited to, the
              warranty disclaimers and the liability limitations set forth above, then the invalid or unenforceable
              provision will be deemed superseded by a valid, enforceable provision(s) that most clearly matches the
              intent of the original provision and the remainder of this Agreement shall continue in effect.
            </p>
            <p>
              <b>19. OTHER TERMS AND CONDITIONS.</b>
            </p>
            <p>
              Additional notices, terms, and conditions may apply to access to or use of Rightdevice’s products and
              services. If there is a conflict between this Agreement and any other notices, terms, or conditions,
              Rightdevice shall resolve any conflict in good faith in its sole discretion but this Agreement shall
              generally control with respect to accessing and using PatientPartner&trade;.
            </p>
            <p>
              <b>20. MISCELLANEOUS.</b>
            </p>
            <p>
              No joint venture, partnership, employment, or agency relationship exists between you and Rightdevice as a
              result of this Agreement or your utilization of the PatientPartner&trade; Program. Headings are for
              convenience only. You may not assign, delegate, or transfer any rights under this Agreement without the
              prior express written consent of Rightdevice, which may be withheld in Rightdevice’s sole discretion.{' '}
              <b>
                Rightdevice may update these terms and conditions at any time by posting or sending you a revised
                version of the Agreement. Accordingly, please review the terms and conditions found at this location on
                a periodic basis. Each time you access the PatientPartner&trade; Program, you agree to be bound by the
                Agreement in effect at the time you participation in the PatientPartner&trade; Program. If you do not
                agree to the revised terms, do not use the PatientPartner&trade; Program.
              </b>
            </p>
            <p>
              <b>21. MEDIA RELEASE.</b>
            </p>
            <p>
              I hereby grant permission to Rightdevice, Inc. a perpetual, worldwide, and royalty-free license to use,
              display, publish, reproduce, distribute, and/or otherwise disseminate my name, likeness, photographic
              image, radiographic image, video image, and audio recording (collectively, ”Name and Likeness ") and any
              statement or endorsement I make about PatientPartner or my experience with its products or services,
              including, without limitation, the PatientPartner Program (collectively, ”Testimonial "), in
              PatientPartner’s promotional materials and publicity efforts, without further compensation or
              consideration to me. Any content and material using my Name and Likeness will be subjected to written
              authorization by me. I understand that my Name and Likeness and my Testimonial may be used in print and
              online publications and advertisements, product brochures, direct -mail pieces, television, newspapers,
              magazines, social media, and/or other forms of promotional and publicity efforts.
            </p>
            <p>
              I waive all rights of inspection, review, or approval with regard to my Name and Likeness and my
              Testimonial if the content remains unmodified from its original version.
            </p>
            <p>
              I hereby release and discharge PatientPartner from and shall neither sue nor bring any proceeding against
              any PatientPartner for, any claim, demand, or cause of action, whether now known or unknown by me, for
              defamation, invasion of right to privacy, or right of publicity, or due to distortion, illusionary effect,
              or faulty reproduction, or arising out of or related to the use of my Name and Likeness or my Testimonial.
            </p>
            <p>
              Notwithstanding the foregoing, I agree PatientPartner is under no obligation to use my Name and Likeness
              or my Testimonial in any way.
            </p>
            <p>
              <b>22. HIPAA Release (PatientPartner Mentor Specific).</b>
            </p>
            <p>
              Completion of this document authorizes the disclosure and use of health information about you. Failure to
              provide all the information requested may invalidate this authorization.
            </p>
            <p>USE AND DISCLOSURE OF HEALTH INFORMATION</p>
            <p>
              I hereby authorize Rightdevice, Inc dba PatientPartner with an address of 1025 Prospect Street Suite 350
              La Jolla, California, 92037, the following information:
            </p>
            <p>
              Only the following records or types of health information (including any dates): Name, Date of Birth,
              Medical Procedure/Treatments, Established/Historical HCP, Contact Information, Height, Weight, Health
              Conditions, Date of Treatment, Cormorbidities, and Other Relevant Healthcare Related Information.
            </p>
            <p>PURPOSE</p>
            <p>Purpose of requested use or disclosure is for: To participate in a Patient Advocacy program</p>
            <p>MY RIGHTS</p>
            <p>
              I may disagree to this authorization. My refusal will not affect my ability to obtain treatment or payment
              or eligibility for benefits, but may affect your ability to participate as a PatientPartner Mentor in the
              PatientPartner Patient Advocacy program.
            </p>
            <p>
              I may inspect or obtain a copy of the health information that I am being asked to allow the use or
              disclosure of.
            </p>
            <p>
              I may revoke this authorization at any time, but I must do so in writing and submit it to the following
              mailing address Attn: PatientPartner, 1025 Prospect Street Suite 350. La Jolla, California, 92037 or via
              email: george@patientpartner.com.
            </p>
            <p>
              My revocation will take effect upon receipt, except to the extent that others have acted in reliance upon
              this authorization.
            </p>
            <p>I have a right to receive a copy of this authorization.</p>
            <p>
              Information disclosed pursuant to this authorization could be redisclosed by the recipient. Such
              redisclosure is in some cases not prohibited by California law and may no longer be protected by federal
              confidentiality law (HIPAA). However, California law prohibits the person receiving my health information
              from making further disclosures of it unless another authorization is obtained from me or unless such
              disclosure is specifically required or permitted by law.
            </p>
          </section>
        </div>
        <div className='col-sm-1'></div>
      </div>
    </div>
  );
}
