import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch, useParams, useLocation } from 'react-router-dom';
import { getSurgeon } from '../../../api/surgeons';
import LoadingScreen from '../../loading_screen';
import SurgeonProfilePanel from './profile_panel/index';
import SimpleSlidingMenu from '../../ui_kit/utilities/simple_sliding_menu';
import Overview from './sections/overview';
import Practice from './sections/practice';
import styles from '../index.module.css';
import AssociatedMentors from './sections/overview/panels/associated_mentors';
import SurgeonBiography from './sections/biography';

export default function SurgeonProfile() {
  const { id } = useParams();
  const location = useLocation();
  const [surgeon, setSurgeon] = useState(null);

  useEffect(() => {
    getSurgeon(id, (surgeon) => {
      setSurgeon(surgeon);
    });
  }, [id]);

  let { path } = useRouteMatch();
  path = path.replace(`:id`, id);

  let menuItems = [
    {
      linkText: 'Overview',
      idToScrollTo: 'overview',
      href: `${path}/`,
      pendoTag: 'surgeon profile page navigation - Overview',
    },
    {
      linkText: 'Office info',
      idToScrollTo: 'office-info',
      href: `${path}/office-info`,
      pendoTag: 'surgeon profile page navigation - office-info',
    },
    surgeon && surgeon.patient_partners && surgeon.patient_partners !== null && surgeon.patient_partners.length !== 0
      ? {
          linkText: `Associated mentors ${surgeon && surgeon.patient_partners.length > 0 ? surgeon.patient_partners.length : ''}`,
          idToScrollTo: 'associated_mentors',
          href: `${path}/associated-mentors`,
          pendoTag: 'surgeon profile page navigation - associated mentors',
        }
      : {},
    {
      linkText: 'Biography',
      idToScrollTo: 'biography',
      href: `${path}/biography`,
      pendoTag: 'surgeon profile page navigation - biography',
    },
  ];

  useEffect(() => {
    if (
      surgeon &&
      surgeon.patient_partners &&
      surgeon.patient_partners !== null &&
      surgeon.patient_partners.length !== 0 &&
      location.pathname === `${path}/associated-mentors`
    ) {
      setSelectedMenuItem(menuItems[2]);
    }
  }, [surgeon]);

  let defaultSelectedMenuItem = menuItems[0];
  menuItems.forEach((item) => {
    if (item.href === location.pathname) {
      defaultSelectedMenuItem = item;
      return;
    }
  });

  const [selectedMenuItem, setSelectedMenuItem] = useState(defaultSelectedMenuItem);

  if (surgeon === null)
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '94vh', width: '100vw' }}>
        <LoadingScreen />
      </div>
    );

  return (
    <div>
      <div className={`${styles.profile_wrapper} container-fluid`}>
        <div className={`container`}>
          <div className='row'>
            <div className='col-lg-4'>
              <SurgeonProfilePanel surgeon={surgeon} />
            </div>
            <div className='col-lg-8'>
              <SimpleSlidingMenu
                menuItems={menuItems}
                setMarker={selectedMenuItem}
                setMarkerItem={setSelectedMenuItem}
              />
              <Switch>
                <Route path={`${path}/office-info`}>
                  <Practice surgeon={surgeon} />
                </Route>
                <Route path={`${path}/associated-mentors`}>
                  <div style={{ marginTop: '20px' }}>
                    {surgeon.patient_partners &&
                      surgeon.patient_partners !== null &&
                      surgeon.patient_partners.length !== 0 && <AssociatedMentors mentors={surgeon.patient_partners} />}
                  </div>
                </Route>
                <Route path={`${path}/biography`}>
                  <SurgeonBiography surgeon={surgeon} />
                </Route>
                <Route path={`${path}`}>
                  <Overview surgeon={surgeon} />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
