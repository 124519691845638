import { isObjEmpty } from '../helpers';

export const getRecommendedMembers = (shuffle, successCallback) => {
  let query = { shuffle: shuffle };

  fetch(`/api/v1/profiles?${objToQueryString(query)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const updateProfile = (id, params, successCallback) => {
  fetch(`/api/v1/profiles/${id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      profile: params,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getWeightLossSurgeonMatchContactFromProfile = (id, successCallback) => {
  fetch(`/api/v1/profiles/${id}/weight_loss_surgeon_match_contact`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const addNewProcedure = (params, successCallback) => {
  fetch(`/api/v1/profiles/${params.id}/profile_procedures`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      procedure: params.procedure,
      private: params.private,
      recovered: params.recovered,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getProcedures = (id, successCallback) => {
  fetch(`/api/v1/profiles/${id}/profile_procedures`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const deleteProcedure = (profile_id, profile_procedure_id, successCallback) => {
  fetch(`/api/v1/profiles/${profile_id}/profile_procedures/${profile_procedure_id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const updateProcedure = (profile_id, profile_procedure_id, params, successCallback) => {
  fetch(`/api/v1/profiles/${profile_id}/profile_procedures/${profile_procedure_id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      profile: {
        profile_procedure: params,
      },
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getConditions = (id, successCallback) => {
  fetch(`/api/v1/profiles/${id}/profile_conditions`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const addNewCondition = (params, successCallback) => {
  fetch(`/api/v1/profiles/${params.id}/profile_conditions`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      condition: params.condition,
      private: params.private,
      recovered: params.recovered,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const updateCondition = (profile_id, profile_condition_id, params, successCallback) => {
  fetch(`/api/v1/profiles/${profile_id}/profile_conditions/${profile_condition_id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      profile: {
        profile_condition: params,
      },
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const deleteCondition = (profile_id, profile_condition_id, successCallback) => {
  fetch(`/api/v1/profiles/${profile_id}/profile_conditions/${profile_condition_id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getProfileInsuranceCarriers = (id, successCallback) => {
  fetch(`/api/v1/profiles/${id}/profile_insurance_carriers`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const addProfileInsuranceCarrier = (params, successCallback) => {
  fetch(`/api/v1/profiles/${params.id}/profile_insurance_carriers`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      carrier: params.carrier,
      policy: params.policy,
      private: params.private,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const updateProfileInsuranceCarrier = (profile_id, profile_insurance_carrier_id, params, successCallback) => {
  fetch(`/api/v1/profiles/${profile_id}/profile_insurance_carriers/${profile_insurance_carrier_id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      profile: {
        profile_condition: params,
      },
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const deleteProfileInsuranceCarrier = (profile_id, profile_insurance_carrier_id, successCallback) => {
  fetch(`/api/v1/profiles/${profile_id}/profile_insurance_carriers/${profile_insurance_carrier_id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const getProfileMedicalProviders = (id, successCallback) => {
  fetch(`/api/v1/profiles/${id}/profile_medical_providers`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const addProfileMedicalProvider = (params, successCallback) => {
  fetch(`/api/v1/profiles/${params.id}/profile_medical_providers`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      surgeons: params.surgeons,
      private: params.private,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const updateProfileMedicalProvider = (profile_id, profile_medical_provider_id, params, successCallback) => {
  fetch(`/api/v1/profiles/${profile_id}/profile_medical_providers/${profile_medical_provider_id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({
      profile: {
        profile_medical_providers: params,
      },
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const deleteProfileMedicalProvider = (profile_id, profile_medical_provider_id, successCallback) => {
  fetch(`/api/v1/profiles/${profile_id}/profile_medical_providers/${profile_medical_provider_id}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const addMatchedPatientPartner = (successCallback) => {
  fetch(`/api/v1/profiles/matched_patient_partner`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (isObjEmpty(data)) {
        successCallback(null);
      } else {
        successCallback(data);
      }
    });
};

export const getRecommendedSurgeon = (successCallback) => {
  fetch(`/api/v1/profiles/matched_surgeon`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (isObjEmpty(data)) {
        successCallback(null);
      } else {
        successCallback(data);
      }
    });
};

export const getSurgeonReferredMentorMatchFromProfile = (params, successCallback) => {
  fetch(`/api/v1/profiles/surgeon_referred_mentor_match?${objToQueryString(params)}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (isObjEmpty(data)) {
        successCallback(null);
      } else {
        successCallback(data);
      }
    });
};
