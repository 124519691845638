import React from 'react';
import styles from './index.module.css';

export default function VonjoThankYouPage({}) {
  return (
    <div className={styles.thank_you_container}>
      <h3 style={{ fontWeight: '700' }}>Thank you!</h3>
      <h3 style={{ fontWeight: '700' }}>What's next?</h3>
      <div className={styles.thank_you_text}>
        <h5>
          Thank you for requesting a call with your mentor! We will contact you shortly to schedule a time. During the
          call, you can learn how they cope with their disease, hear about their disease experience, and understand
          their treatment journey. If you would like to contact us directly, please email us at{' '}
          <a className={styles.thank_you_link} href='mailto:info@patientpartner.com'>
            info@patientpartner.com
          </a>{' '}
          or call us at <a href='tel:6192681112'>619-268-1112</a>.
        </h5>
      </div>
    </div>
  );
}
