import { connect } from 'react-redux';
import { updateAnswersAction, setQuestionnaireStateAction } from '../../actions/home';
import Home from '../../components/home';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
  setQuestionnaireStateAction: (item) => dispatch(setQuestionnaireStateAction(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
