import React, { useState } from 'react';
import Fuse from 'fuse.js';
import './index.css';

export default function InputWithOptions({
  value,
  setValue,
  options,
  name,
  label,
  noMatchTxt,
  light = true,
  showOptionsFromStart = false,
  wrapperClassnames,
  placeholder = '',
  searchAndPush = false,
  fullWidth = false,
  style = {},
}) {
  const [showOptions, setShowOptions] = useState(showOptionsFromStart);
  const [hoveringDropdown, setHoveringDropdown] = useState(false);
  const [valuePlaceholder, setValuePlaceholder] = useState('');

  let currentOptions = options;
  if (showOptions) {
    if (searchAndPush) {
      if (valuePlaceholder !== '') {
        const fuse = new Fuse(options, {
          includeScore: true,
          threshold: 0.5,
        });

        const result = fuse.search(valuePlaceholder);
        currentOptions = result.map((r) => r.item);
      }

      if (currentOptions.length == 0 && valuePlaceholder != '') {
        currentOptions = [];
      }
    } else {
      if (value !== '') {
        const fuse = new Fuse(options, {
          includeScore: true,
          threshold: 0.5,
        });

        const result = fuse.search(value);
        currentOptions = result.map((r) => r.item);
      }

      if (currentOptions.length == 0 && value != '') {
        currentOptions = [];
      }
    }
  }

  const onInputChange = (value) => {
    setShowOptions(true);
    if (searchAndPush) {
      setValuePlaceholder(value);
    } else {
      setValue(value);
    }
  };

  const onClickOption = (event) => {
    event.preventDefault();
    setHoveringDropdown(false);
    setValue(event.target.value);
    setValuePlaceholder('');
    setShowOptions(false);
  };

  return (
    <div
      style={{ width: fullWidth ? '100%' : '', maxWidth: fullWidth ? '100%' : '' }}
      className={`input-with-options-container ${wrapperClassnames}`}
    >
      <div
        style={{ width: fullWidth ? '100%' : '', maxWidth: fullWidth ? '100%' : '' }}
        className={`material-input ${light ? 'light' : ''}`}
      >
        <input
          placeholder={placeholder}
          onClick={() => setShowOptions(true)}
          onBlur={() => {
            hoveringDropdown === false ? setShowOptions(false) : null;
          }}
          autoComplete='off'
          className='autofocus'
          name={name}
          value={searchAndPush ? valuePlaceholder : value}
          onChange={(event) => onInputChange(event.target.value)}
          required
        />
        <span className='highlight'></span>
        <span className='bar'></span>
        <label>{label}</label>
      </div>
      {showOptions && (
        <div
          style={style}
          onMouseOver={() => setHoveringDropdown(true)}
          onMouseOut={() => setHoveringDropdown(false)}
          className='input-with-options-dropdown-container'
        >
          <div className='input-with-options-dropdown'>
            {noMatchTxt && (
              <button
                value={noMatchTxt}
                onClick={(event) => onClickOption(event)}
                className='input-with-options-option'
              >
                {noMatchTxt}
              </button>
            )}
            {currentOptions.map((value, i) => (
              <button
                key={i}
                value={value}
                onClick={(event) => onClickOption(event)}
                className='input-with-options-option'
              >
                {value}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
