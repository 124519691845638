import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Route, Switch, useRouteMatch, useHistory, useLocation, useParams } from 'react-router-dom';
import LoadingScreen from '../../loading_screen';
import FirstName from '../first_name';
import '../questionnaire.css';
import { updateBetaDoctorConnectContact } from '../../../api/beta_doctor_connect_contacts';
import WhatSpecialist from '../what_specialist';
import WhatConditions from '../what_conditions';
import { autofocusAll } from '../../../helpers';
import ChooseManyOptions from '../choose_many_options';
import ChooseManyOptions1 from '../choose_many_options_1';
import Location from '../location';
import InsuranceCarrier from '../insurance_carrier';
import CommentBox from '../comment_box';
import SubmitRequestEmailPhone from '../submit_request_email_phone';
import ConfirmationPage from '../confirmation_page';
import { contactSync, findPreviousContact } from '../../../api/beta_doctor_connect_contacts';
import Context from '../../../utils/context';
import CheckoutForm from '../../transactions/checkout_form';
import { getProducts } from '../../../api/products';
import styles from '../../beta_doctor_connect/index.module.css';
import StripePaymentSuccessCatch from '../../ui_kit/utilities/stripe_payment_success_catch';

export default function BetaDoctorConnectQuestionnaire({ home, standAlone = false }) {
  let { path } = useRouteMatch();
  const { id } = useParams();
  path = path.replace(`:id`, id);
  let history = useHistory();
  let siteLocation = useLocation();
  const context = useContext(Context);
  const [firstName, setFirstName] = useState(context.auth.getCurrentUser().first_name || '');
  const [specialist, setSpecialist] = useState('');
  const [conditions, setConditions] = useState('');
  const [specialistPriorities, setSpecialistPriorities] = useState([]);
  const [officePriorities, setOfficePriorities] = useState([]);
  const [location, setLocation] = useState({});
  const [insurance, setInsurance] = useState('');
  const [extraInfo, setExtraInfo] = useState({});
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [checkoutItems, setCheckoutItems] = useState([]);

  useEffect(() => {
    getProducts({ type: 'doctor_connect' }, (data) => setCheckoutItems([...data.products]));
  }, []);

  useEffect(() => {
    autofocusAll();
  }, []);

  useEffect(() => {
    if (siteLocation.pathname.includes('loading')) {
      setTimeout(() => {
        history.push(`${path}/submit-request`);
      }, 3000);
    }
  }, [siteLocation.pathname]);

  // redirect out
  useEffect(() => {
    if (
      !id &&
      window.location.href &&
      !window.location.href.endsWith('name') &&
      !window.location.href.endsWith('specialist') &&
      !window.location.href.includes('success')
    ) {
      context.auth.isLoggedIn() ? history.push(`${path}/specialist`) : history.push(`${path}/name`);
    }
  }, []);

  const SPECIALTIES = [
    'Acupuncture',
    'Addiction Medicine',
    'Addiction Psychiatry',
    'Addiction and Substance Abuse Counseling',
    'Adolescent Psychology',
    'Advanced Heart Failure & Transplant Cardiology',
    'Aerospace Medicine',
    'Allergy & Immunology',
    'Alternative Care',
    'Anatomic & Clinical Pathology',
    'Anatomic Pathology',
    'Anesthesiology',
    'Anesthesiology (Anesthesiology Assistant)',
    'Art Therapy',
    'Assisted Living',
    'Assistive Therapy',
    'Athletic Training',
    'Attendant Care',
    'Audiology',
    'Audiology (Audiology Assistant)',
    'Bariatric Medicine',
    'Bariatric Surgery',
    'Behavior Analysis',
    'Blood Banking & Transfusion Medicine',
    'Brain Injury Medicine',
    'Cardiology',
    'Cardiovascular Technology',
    'Chemical Pathology',
    'Child & Adolescent Psychiatry',
    'Child Abuse Pediatrics',
    'Chiropractic',
    'Clinical Cardiac Electrophysiology',
    'Clinical Exercise Physiology',
    'Clinical Informatics',
    'Clinical Lipidology',
    'Clinical Neurophysiology',
    'Clinical Pathology',
    'Clinical Pharmacology',
    'Clinical Psychology',
    'Colorectal Surgery',
    'Community Health Work',
    'Congenital Cardiac Surgery',
    'Cosmetic, Plastic & Reconstructive Surgery',
    'Counseling',
    'Critical Care Medicine',
    'Critical Care Surgery',
    'Cytopathology',
    'Dance Therapy',
    'Darkroom Technology',
    'Dental Hygiene',
    'Dentistry',
    'Dermatology',
    'Dermatopathology',
    'Developmental Therapy',
    'Developmental-Behavioral Pediatrics',
    'Diagnostic Laboratory & Dermatologic Immunology',
    'Diagnostic Radiology',
    'Diagnostic Ultrasound Imaging',
    'Dietetics',
    'Ear, Nose, and Throat',
    'Electrodiagnostic Medicine',
    'Emergency Medical Technology (EMT)',
    'Emergency Medicine',
    'Endocrinology, Diabetes & Metabolism',
    'Endodontics',
    'Family Medicine',
    'Female Pelvic Medicine & Reconstructive Surgery',
    'Forensic Pathology',
    'Forensic Psychiatry',
    'Gastroenterology',
    'General Dentistry (Dental Assistant)',
    'General Surgery',
    'Genetic Counseling',
    'Genetics',
    'Geriatric Medicine',
    'Health Education',
    'Healthcare Coordination',
    'Hearing & Speech Therapy',
    'Hearing Instruments',
    'Hematology',
    'Hematopathology',
    'Hepatology',
    'Holistic Medicine',
    'Home Health (Aide)',
    'Homeopathy',
    'Hospice & Palliative Medicine',
    'Hospital Medicine',
    'Immunopathology',
    'Infectious Disease Medicine',
    'Internal Medicine',
    'Interventional Cardiology',
    'Labor Coaching (Doula)',
    'Laboratory Management Technology',
    'Laboratory Medicine',
    'Lactation Consulting',
    'Legal Medicine',
    'Marriage & Family Therapy',
    'Massage Therapy',
    'Mastectomy Fitting',
    'Maternal-Fetal Medicine',
    'Mechanotherapy',
    'Medical Editing',
    'Medical Ethics',
    'Medical Examiner',
    'Medical Informatics',
    'Medical Microbiology',
    'Medical Physics',
    'Medical Technology',
    'Medical Toxicology',
    'Mental Health ',
    'Midwifery',
    'Molecular Genetic Pathology',
    'Music Therapy',
    'Naprapathy',
    'Naturopathy',
    'Neonatal Medicine',
    'Nephrology',
    'Neurodevelopmental Disabilities',
    'Neurogenetics',
    'Neurology',
    'Neuromusculoskeletal Medicine',
    'Neuropathology',
    'Neuropsychology',
    'Neuroradiology',
    'Neurosurgery',
    'Neurotology',
    'Nuclear Medicine',
    'Nuclear Radiology',
    'Nurse Anesthesiology',
    'Nursing (Clinical Nurse Specialist)',
    'Nursing (Nurse Practitioner)',
    'Nursing (Registered Nurse)',
    'Nursing Services',
    'Obstetrics & Gynecology',
    'Occupational & Physical Therapy',
    'Occupational Medicine',
    'Occupational Therapy',
    'Occupational Therapy (Occupational Therapy Assistant)',
    'Ocularistry',
    'Oncology',
    'Ophthalmic Technology',
    'Ophthalmology',
    'Ophthamology (Ophthalmic Assistant)',
    'Optometry',
    'Optometry (Optometry Assistant)',
    'Optometry Technology',
    'Oral & Maxillofacial Pathology',
    'Oral & Maxillofacial Radiology',
    'Oral & Maxillofacial Surgery',
    'Orthodontics & Dentofacial Orthopedics',
    'Orthopedic Hand Surgery',
    'Orthopedic Spine Surgery',
    'Orthopedic Surgery',
    'Orthoptics',
    'Orthotic Fitting',
    'Other',
    'Pain Medicine',
    'Pathology Technology',
    'Pediatric Allergy & Immunology',
    'Pediatric Anesthesiology',
    'Pediatric Cardiology',
    'Pediatric Cardiothoracic Surgery',
    'Pediatric Chiropractic',
    'Pediatric Critical Care Medicine',
    'Pediatric Dentistry',
    'Pediatric Dermatology',
    'Pediatric Diagnostic Laboratory Immunology',
    'Pediatric Dietetics',
    'Pediatric Emergency Medicine',
    'Pediatric Endocrinology',
    'Pediatric Gastroenterology',
    'Pediatric Hematology & Oncology',
    'Pediatric Hospice & Palliative Medicine',
    'Pediatric Infectious Disease Medicine',
    'Pediatric Medical Genetics',
    'Pediatric Medical Toxicology',
    'Pediatric Medicine',
    'Pediatric Nephrology',
    'Pediatric Neurodevelopmental Disabilities',
    'Pediatric Neurology',
    'Pediatric Neurosurgery',
    'Pediatric Nursing',
    'Pediatric Nursing (Nurse Practitioner)',
    'Pediatric Occupational Therapy',
    'Pediatric Ophthalmology',
    'Pediatric Optometry',
    'Pediatric Orthopedic Surgery',
    'Pediatric Otolaryngology (Ear, Nose & Throat)',
    'Pediatric Pathology',
    'Pediatric Physical Therapy',
    'Pediatric Plastic Surgery',
    'Pediatric Pulmonology',
    'Pediatric Radiology',
    'Pediatric Rheumatology',
    'Pediatric Sleep Medicine',
    'Pediatric Sports Medicine',
    'Pediatric Surgery',
    'Pediatric Transplant Hepatology',
    'Pediatric Urology',
    'Pediatrics',
    'Pediatrics Physical Medicine & Rehabilitation     ',
    'Periodontics',
    'Personal Care Attendance',
    'Personal Emergency Response Attendance',
    'Pharmaceutical Medicine',
    'Pharmacology',
    'Pharmacotherapy',
    'Pharmacy',
    'Pharmacy Clinician Services',
    'Phlebology',
    'Physical Medicine & Rehabilitation',
    'Physical Therapy',
    'Physical Therapy (Physical Therapy Assistant)',
    'Physician Assistant (PA)',
    'Podiatric Surgery',
    'Podiatry',
    'Podiatry (Podiatry Assistant)',
    'Poetry Therapy',
    'Practical Nursing (Licensed Practical Nurse)',
    'Preventive Medicine',
    'Prosthetics',
    'Prosthetics & Orthotics',
    'Psychiatry',
    'Psychosomatic Medicine',
    'Psychotherapy',
    'Public Health & General Preventive Medicine',
    'Pulmonary Disease',
    'Pulmonary Function Technology',
    'Radiation Oncology',
    'Radioisotopic Pathology',
    'Radiologic Technology',
    'Radiology',
    'Radiology (Practitioner Assistant)',
    'Recreation Therapy',
    'Reflexology',
    'Reproductive Endocrinology & Infertility',
    'Research',
    'Respiratory Therapy',
    'Rheumatology',
    'Rural Medicine',
    'Sleep Medicine',
    'Social Work',
    'Spa Medicine',
    'Speech-Language Pathology',
    'Spinal Cord Injury Medicine',
    'Sports Medicine',
    'Surgical (Selective) Pathology',
    'Surgical Assistance',
    'Thoracic Surgery',
    'Transplant Hepatology',
    'Transplant Surgery',
    'Undersea & Hyperbaric Medicine',
    'Urgent Care Medicine',
    'Urology',
    'Vascular & Interventional Radiology',
    'Vascular Medicine',
    'Vascular Neurology',
    'Vascular Surgery',
  ];

  const CONDITIONS = [
    "I don't know",
    'Acid Reflux and GERD',
    'ACL Injury',
    'Acne',
    'Aging Well',
    'Allergies',
    "Alzheimer's Disease",
    'Ankylosing Spondylitis',
    'Anxiety Disorders',
    'Arthritis',
    'Asthma',
    'Atrial Fibrillation',
    'Autism',
    'Back Pain',
    'Bipolar Disorder',
    'Birth Control',
    'Bladder Cancer',
    'Blood Conditions',
    'Bones, Joints and Muscles',
    'Brain and Nerves',
    'Breast Cancer',
    'Cancer',
    'Celiac Disease',
    "Children's Health",
    'Cholesterol',
    'Choosing the Right Health Plan',
    'Chronic Pain',
    'Cold and Flu',
    'Colon Cancer',
    'Coronavirus',
    "Crohn's Disease",
    'Dementia',
    'Depression',
    'Diabetes',
    'Diabetic Neuropathy',
    'Digestive Health',
    'Ear, Nose and Throat',
    'Eating Disorders',
    'Eczema',
    'Endocrinology and Metabolism',
    'Endometriosis',
    'Epilepsy',
    'Erectile Dysfunction',
    'Eye Health',
    'Fertility and Conception',
    'Fibromyalgia',
    'Food, Nutrition and Diet',
    'Foot and Ankle Injury',
    'Gout',
    'Happy and Healthy Holidays',
    'Hearing Loss',
    'Heart Attack',
    'Heart Failure',
    'Heart Health',
    'Hepatitis C',
    'Hidradenitis Suppurativa',
    'High Blood Pressure',
    'Infections and Contagious Diseases',
    'Injuries and Wounds',
    'Irritable Bowel Syndrome',
    'Kidney Cancer',
    'Kidney Disease',
    'Kidneys and the Urinary System',
    'Knee and Leg Injury',
    'Leukemia',
    'Lifestyle and Wellness',
    'Liver Conditions',
    'Low Testosterone',
    'Lung Cancer',
    'Lungs, Breathing and Respiration',
    'Lupus',
    'Lymphoma',
    'Melanoma',
    'Menopause',
    'Mental Health and Behavior',
    'Migraine and Headache',
    'Multiple Myeloma',
    'Multiple Sclerosis',
    'Oral Health',
    'Osteoporosis',
    'Overactive Bladder',
    "Parkinson's Disease",
    'Patient Advocate Center',
    'Pregnancy',
    'Prostate Cancer',
    'Psoriasis',
    'Psoriatic Arthritis',
    'Quitting Smoking',
    'Restless Legs Syndrome',
    'Rheumatoid Arthritis',
    'Rosacea',
    'Schizophrenia',
    'Sexual Health',
    'Shingles',
    'Skin Cancer',
    'Skin, Hair and Nails ',
    'Sleep Disorders',
    'Spine Conditions',
    'Stroke ',
    'Substance Abuse and Addiction',
    'Symptoms and Conditions',
    'Thyroid Disorders',
    'Torn Meniscus',
    'Ulcerative Colitis',
    'Vascular Conditions',
    'Weight Control and Obesity',
    "Women's Health",
    'Working With Lupus',
  ];

  const randomNumber = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  return (
    <div className={styles.questionnaire_ottr} id='questionnaire'>
      <div id={`questionnaire-scroll-to`} className={`patient-partner-questionnaire container text-left h-100 de-pad`}>
        <div
          style={{
            backgroundColor: '#f5f7f9',
            alignContent: 'center',
            minHeight: standAlone ? '100%' : '20vh',
            padding: standAlone ? '0px' : '75px 0px',
            margin: '0px',
            justifyContent: 'center',
          }}
          className='row h-100 '
        >
          <div
            className={`${context.auth.isLoggedIn() && window.location.pathname.includes('submit-request') ? 'col' : 'col-md-8'} fadeOp`}
          >
            <Switch>
              <Route path={`${path}/name`}>
                <FirstName
                  defaultFirstName={firstName}
                  head={"Let's get to know each other. What's your first name?"}
                  onSubmit={(value) => {
                    setFirstName(value);
                    updateBetaDoctorConnectContact(id, { first_name: value });
                    history.push(`${path}/specialist`);
                  }}
                />
              </Route>
              <Route path={`${path}/specialist`}>
                <WhatSpecialist
                  defaultSpecialist={specialist}
                  head={`${firstName}, it's great to meet you. Let's find the right doctor for your needs.`}
                  head1={`What type of specialist are you looking for today?`}
                  onSubmit={(values) => {
                    setSpecialist(values);
                    updateBetaDoctorConnectContact(id, { specialist: values });
                    values === "I don't know"
                      ? history.push(`${path}/unknown-specialist-conditions`)
                      : history.push(`${path}/known-specialist-conditions`);
                  }}
                  choices={SPECIALTIES}
                  onBack={() => {
                    history.push(`${path}/name`);
                  }}
                />
              </Route>
              <Route path={`${path}/unknown-specialist-conditions`}>
                <WhatConditions
                  defaultConditions={conditions}
                  head={"No worries, we're here to help you find the right specialist."}
                  head1={'Is there a specific condition you are seeking treatment for?'}
                  label='Select a condition'
                  onSubmit={(value) => {
                    setConditions(value);
                    updateBetaDoctorConnectContact(id, { conditions: value });
                    history.push(`${path}/priorities-1`);
                  }}
                  choices={CONDITIONS}
                  onBack={() => {
                    history.push(`${path}/specialist`);
                  }}
                />
              </Route>
              <Route path={`${path}/known-specialist-conditions`}>
                <WhatConditions
                  defaultConditions={conditions}
                  head={'What condition are you seeking treatment for?'}
                  label='Select a condition'
                  choices={CONDITIONS}
                  onSubmit={(value) => {
                    setConditions(value);
                    updateBetaDoctorConnectContact(id, { conditions: value });
                    history.push(`${path}/priorities-1`);
                  }}
                  onBack={() => {
                    history.push(`${path}/specialist`);
                  }}
                />
              </Route>
              <Route path={`${path}/priorities-1`}>
                <ChooseManyOptions
                  answers={{ priorities1: specialistPriorities }}
                  keyForAnswer={`priorities1`}
                  title={'Select your top three (3) criteria when choosing a health specialist.'}
                  onSubmit={(answers) => {
                    setSpecialistPriorities(answers.priorities1);
                    updateBetaDoctorConnectContact(id, { specialist_priorities: answers.priorities1 });
                    history.push(`${path}/priorities-2`);
                  }}
                  options={[
                    'Specialist has positive reviews',
                    'Specialist explains conditions ',
                    'Specialist answers my questions',
                    'Experience with my condition',
                    'In-network with my insurance',
                  ]}
                  onBack={() => {
                    specialist === "I don't know"
                      ? history.push(`${path}/unknown-specialist-conditions`)
                      : history.push(`${path}/known-specialist-conditions`);
                  }}
                  submitCta={'Next'}
                  otherInput={false}
                  choseMax={3}
                />
              </Route>
              <Route path={`${path}/priorities-2`}>
                <ChooseManyOptions1
                  answers={{ priorities2: officePriorities }}
                  keyForAnswer={`priorities2`}
                  title={'Select the top three (3) benefits that would keep you coming back to the same specialist.'}
                  onSubmit={(answers) => {
                    setOfficePriorities(answers.priorities2);
                    updateBetaDoctorConnectContact(id, { office_priorities: answers.priorities2 });
                    history.push(`${path}/travel-origin`);
                  }}
                  options={[
                    'Easy appointment scheduling',
                    'Short commute to specialist office',
                    'Modern medical technology',
                    'Staff is friendly and accommodating',
                    'Appointment availability',
                  ]}
                  onBack={() => {
                    history.push(`${path}/priorities-1`);
                  }}
                  submitCta={'Next'}
                  otherInput={false}
                  chooseMax={3}
                />
              </Route>
              <Route path={`${path}/travel-origin`}>
                <Location
                  head={'Where will you be traveling from?'}
                  onSubmit={(value) => {
                    setLocation(value);
                    updateBetaDoctorConnectContact(id, { location: value });
                    history.push(`${path}/insurance-provider`);
                  }}
                  onBack={() => history.push(`${path}/priorities-2`)}
                />
              </Route>
              <Route path={`${path}/insurance-provider`}>
                <InsuranceCarrier
                  answers={{ insuranceCarrier: insurance }}
                  description={''}
                  onSubmit={(answers) => {
                    setInsurance(answers.insuranceCarrier);
                    updateBetaDoctorConnectContact(id, { insurance_carrier: answers.insuranceCarrier });
                    history.push(`${path}/medical-background`);
                  }}
                  head={'What is your insurance provider?'}
                  onBack={() => history.push(`${path}/travel-origin`)}
                />
              </Route>
              <Route path={`${path}/medical-background`}>
                <CommentBox
                  head={`Is there anything else you want us to keep in mind as we search for your perfect doctor?`}
                  placeholder={`Let us know about doctors you've already worked with, treatments you've already tried, and specifics about your condition that you think would be helpful for us to know.`}
                  onBack={() => history.push(`${path}/insurance-provider`)}
                  onSubmit={(value) => {
                    setExtraInfo(value);
                    updateBetaDoctorConnectContact(id, { additional_info: value });
                    history.push(`${path}/loading`);
                  }}
                />
              </Route>
              <Route path={`${path}/loading`}>
                <LoadingScreen
                  title={`Reviewing your request details`}
                  content={`We’re making sure we have everything we need to help you find the best doctor.`}
                />
              </Route>
              <Route path={`${path}/submit-request/success`}>
                <StripePaymentSuccessCatch callback={() => updateBetaDoctorConnectContact(id, { paid: true })} />
                <ConfirmationPage />
              </Route>
              <Route path={`${path}/submit-request`}>
                <div className='container '>
                  <div className='row' style={{ justifyContent: 'center' }}>
                    <div className={`${context.auth.isLoggedIn() ? 'col-12 col-md-6' : 'col-lg-9 col-md-8'}`}>
                      <SubmitRequestEmailPhone
                        head={`We found ${randomNumber(49, 208)} physicians in your area that treat this condition`}
                        subheader={`Now, let's find the best doctor for you`}
                        description={`One of our team members is ready to research your request. They'll use the info you provided to find the specialist that's best for your needs${context.auth.isLoggedIn() ? '' : '- all for free.'}`}
                        answers={{ email: email, phone: phone }}
                        onSubmit={(answers) => {
                          setEmail(answers.email);
                          setPhone(answers.phone);
                          updateBetaDoctorConnectContact(id, { email: answers.email, phone: answers.phone });
                          history.push(`${path}/confirmed`);
                        }}
                        getInfo={!context.auth.isLoggedIn()}
                      />
                    </div>
                    {context.auth.isLoggedIn() && (
                      <div style={{ minHeight: '600px' }} className='col-12 col-md-6'>
                        <CheckoutForm
                          checkoutItems={checkoutItems}
                          ctaText='Pay and submit request'
                          beforeSend={() => context.facebookConversionApi.leadEvent({ email: email })}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Route>
              <Route path={`${path}/confirmed`}>
                <ConfirmationPage />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
