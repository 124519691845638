import React, { useState, useContext } from 'react';
import AvatarEdit from 'react-avatar-edit';
import RequestButton from '../request_button';
import SimpleAvatar from '../simple_avatar';
import { updateProfile } from '../../../../api/profiles';
import { createActivity } from '../../../../api/activities';
import { successToast } from '../success_toast';
import Context from '../../../../utils/context';
import S3 from 'react-aws-s3';
import uuid from 'react-uuid';
import styles from './index.module.css';
const config = {
  bucketName: gon.s3_bucket_name,
  dirName: gon.s3_store_dir /* optional */,
  region: gon.s3_region,
  accessKeyId: gon.s3_key,
  secretAccessKey: gon.s3_secret,
};
const ReactS3Client = new S3(config);

export default function AvatarEditor({ size = '40px', onSuccess = () => {}, editPhotoPendoTag = '' }) {
  const [image, setImage] = useState(null);
  const [editedImage, setEditedImage] = useState(null);
  const context = useContext(Context);
  let user = context.auth.getCurrentUser();

  const s3UploadFile = (file, callback) => {
    ReactS3Client.uploadFile(file, file.name)
      .then((data) => {
        updateProfile(
          user.profile_id,
          {
            photo: data.location,
          },
          () => {
            successToast('Profile photo updated.');
            setImage(null);
            callback();
          }
        );
      })
      .catch((err) => console.error(err));
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  return (
    <div className={`${styles.editor_wrap} container`}>
      <div className={`row`}>
        <div className={`col`}>
          <label className={styles.click_edit}>
            {editedImage === null && image === null && <SimpleAvatar size={size} userSlug={user.slug} editing />}
            {editedImage !== null && (
              <img className={styles.preview} src={editedImage} style={{ height: size, width: size }} />
            )}
            <input
              data-pendo-tag={editPhotoPendoTag}
              onChange={(e) => setImage(URL.createObjectURL(e.target.files[0]))}
              type='file'
              style={{ display: 'none' }}
              name='image'
              multiple
              accept='.jpg,.jpeg,.png'
            />
            <div className={styles.camera_holder}>
              <img
                className={styles.camera}
                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/camera-a.svg'
              />
            </div>
          </label>
          <div className={styles.editor}>
            {image !== null && (
              <AvatarEdit
                height={100}
                imageWidth={300}
                exportAsSquare={true}
                src={image}
                onCrop={setEditedImage}
                onClose={() => {
                  setImage(null);
                  setEditedImage(null);
                }}
              />
            )}
          </div>
          {image !== null && (
            <RequestButton
              cta='Save'
              style={{ marginTop: '10px' }}
              request={(callback) =>
                s3UploadFile(dataURLtoFile(editedImage, uuid()), () => {
                  callback();
                  createActivity('Activity::EditProfile', { fields: 'photo' });
                  onSuccess();
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}
