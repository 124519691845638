import React, { useState } from 'react';

export default function SeenSurgeonBefore({
  answers,
  onSubmit,
  onBack,
  headline = 'Have you seen a surgeon about this procedure before?',
  para = 'This information will help the surgeon understand how they can support you.',
  choices = [{ value: 'Yes' }, { value: 'No' }],
  gtm,
}) {
  const [seenSurgeonBefore, setSeenSurgeonBefore] = useState(answers.seenSurgeonBefore || '');

  const onClick = (value) => () => {
    setSeenSurgeonBefore(value);
  };

  const submit = (e) => {
    e.preventDefault();
    onSubmit({ ...answers, seenSurgeonBefore: seenSurgeonBefore });
  };

  return (
    <div className={`row seenSurgeonBeforeQuestion`}>
      <div className='col-sm-2'></div>
      <div className='col-sm-8'>
        <div>
          <form id='plans'>
            <h5 className='question'>{headline}</h5>
            <p className='question'>{para}</p>
            {choices.map((choice) => (
              <div key={choice.value} className='tile two-column'>
                <label
                  key={choice.value}
                  className={`preexisting ${seenSurgeonBefore === choice.value ? 'activeCheckbox' : ''}`}
                >
                  <input onClick={onClick(choice.value)} type='checkbox' name={choice.value} value='value' />
                  {choice.icon && (
                    <span style={{ width: '20px' }} className='marg-right-20 seenSurgeonBefore-icon-holder'>
                      {choice.icon}
                    </span>
                  )}
                  {choice.value}
                </label>
              </div>
            ))}
            <div className='row button-holder'>
              <button
                data-gtm-click-label={`${gtm} seen surgeon about this procedure before - Back`}
                className='back col-6'
                type='button'
                onClick={onBack}
              >
                Back
              </button>
              <button
                data-gtm-click-label={`${gtm} seen surgeon about this procedure before - Submit`}
                disabled={`${seenSurgeonBefore === '' ? 'disabled' : ''}`}
                className={` submit col-6`}
                type='submit'
                onClick={(e) => submit(e)}
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className='col-sm-2'></div>
    </div>
  );
}
