import { connect } from 'react-redux';
import { updateAnswersAction } from '../../../actions/home';
import September212021 from '../../../components/webinars/september_21_2021';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(September212021);
