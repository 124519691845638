import React, { Component, useState, useEffect, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import './surgeon_match.css';
import FeaturedInMedia from '../../featured_in_media';
import AboutHeadlineThreeIcons from '../../about_headline_three_icons';
import HowItWorks from '../../how_it_works';
import { contactSync } from '../../../api/contacts';
import { autofocusAll } from '../../../helpers';
import TextLeftImgRightWbg from '../../hero_sections/txt_left_img_right_wbg';
import ImgLeftTextRight from '../../hero_sections/img_left_text_right';
import CustomCtaFooter from '../../custom_cta_footer';
import EmailSignupFooter from '../../email_signup_footer';
const SurgeonMatchQuestionnaire = React.lazy(() => import('../../questionnaire/surgeon_match'));

export default function WeightLossSurgeonMatch({
  home,
  updateAnswersAction,
  setContactIdAction,
  setSurgeonMatchQuestionnaireProgress,
  setSurgeon,
}) {
  const [showModal, setShowModal] = useState(false);

  const scrollToQuestionnaire = () => {
    document.querySelector('#questionnaire').scrollIntoView({
      behavior: 'smooth',
    });
    const timer = setTimeout(() => {
      autofocusAll();
    }, 1000);
    return () => clearTimeout(timer);
  };

  return (
    <div className='weight-loss-surgeon-match'>
      <TextLeftImgRightWbg
        headline={'Considering weight-loss surgery?'}
        para={
          'Find the best weight-loss surgeon in your area. Plus check to see if they are covered by your insurance. Get started by taking a short questionnaire below.'
        }
        bg={
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss-surgeon-match/weight_loss_surgeon_match_header.jpg'
        }
        actionContent={
          <div className='btn-wrap'>
            <button onClick={scrollToQuestionnaire} className='maincta' data-gtm-click-label='CTA Get Started'>
              Get started
            </button>
          </div>
        }
        imgOffset={false}
        imgShadow={true}
        topOfIcon={'75%'}
        iconContent={
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 132.05 163.45'>
            <g id='Layer_2' data-name='Layer 2'>
              <g id='Layer_1-2' data-name='Layer 1'>
                <path
                  fill='#74ccd3'
                  className='cls-1'
                  d='M131.9,78.63V38.83c0-5.75-.43-11.32-2.66-16.73A36,36,0,0,0,108.2,2C103.11.19,97.89,0,92.57,0H37.07C19.79,0,3.91,11.21.67,28.77-.11,33,0,37.29,0,41.59V61.06C0,72.67,0,84.29,0,95.9c.08,19.16,15.19,35.35,34.54,35.94,2.44.08,4.87,0,7.31,0l.25,0a3,3,0,0,0,2.7-1.5l.12-.13h0L68,99.44c3.92-.87,7.16-4,9.32-7.57C83.79,81.23,89.81,70.31,96,59.54c2.88-5,6.84-12.13,3.58-17.86-2.52-4.47-8.3-4.24-13.27-4a6.26,6.26,0,1,0,.09,6.69h1.1c1.58,0,5.4-.81,6.45.57s-.46,5.17-1.06,6.55a49.19,49.19,0,0,1-2.78,5.15L73.91,84.77c-1.7,2.93-3.61,7.87-7.4,8.37-3.94.52-6.06-4.15-7.69-7l-16.18-28c-1.87-3.24-6.08-8.58-4.58-12.46.87-2.26,4.84-1.28,6.77-1.28h.23a6.25,6.25,0,0,0,11.59-3.26h0a6.25,6.25,0,0,0-11.59-3.26H43.71c-3.09,0-6.29-.29-8.93,1.61-5.19,3.71-3.41,11.19-1.1,16,2.43,5.11,5.63,9.94,8.46,14.83L52.58,88.43c2.15,3.72,4.36,7.81,8.18,9.9l-20.25,27c-8,0-15.43-.65-22.22-5.68A29.34,29.34,0,0,1,6.57,96c-.1-12.3,0-24.6,0-36.9V39.68c0-5.9.25-11.59,3-17A29.4,29.4,0,0,1,35.49,6.54c3.51,0,7,0,10.52,0H89.68c4.63,0,9.34-.27,13.86.89a29.34,29.34,0,0,1,21.14,21.79c1,4.36.68,9,.68,13.44V64c0,11.24.45,22.57,0,33.81a29.25,29.25,0,0,1-17.26,25.11c-7.5,3.34-14.6,1.66-22.39,2.44-2.32.24-3.06,1.54-4.14,3.29l-4.73,7.68L65.62,154.48l-13-16.71c-2.58-3.31-7.16,1.34-4.62,4.62l15.68,20.08c1.35,1.73,4.14.95,5.13-.65L87.22,131.9c5.73.07,11.63.36,17.21-.92a35.81,35.81,0,0,0,23.17-17.71C133.31,102.72,131.9,90.2,131.9,78.63Z'
                />
              </g>
            </g>
          </svg>
        }
      />
      <FeaturedInMedia
        images={[
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/forbes.jpg',
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/abc.jpg',
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/yahoo_a.jpg',
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/nbc_a.jpg',
        ]}
      />
      <AboutHeadlineThreeIcons
        headline={
          'Weight-loss surgery has given people a chance to reset their health and achieve lifelong goals. The best part - most insurance carriers cover the procedure!'
        }
        smHeadline={'3 facts about surgery:'}
        infoTiles={[
          {
            icon: (
              <svg
                className='beni-icon'
                xmlns='http://www.w3.org/2000/svg'
                width='76.838'
                height='48.897'
                viewBox='0 0 76.838 48.897'
              >
                <path
                  id='Icon_material-people-outline'
                  data-name='Icon material-people-outline'
                  d='M55.636,35.441a41.865,41.865,0,0,0-15.717,3.493A41.2,41.2,0,0,0,24.2,35.441c-7.579,0-22.7,3.772-22.7,11.351v9.6H78.338v-9.6C78.338,39.213,63.215,35.441,55.636,35.441ZM41.665,51.158H6.739V46.792c0-1.886,8.941-6.112,17.463-6.112s17.463,4.226,17.463,6.112Zm31.434,0H46.9V46.792a6.355,6.355,0,0,0-1.816-4.261A33.7,33.7,0,0,1,55.636,40.68c8.522,0,17.463,4.226,17.463,6.112ZM24.2,31.948A12.224,12.224,0,1,0,11.978,19.724,12.238,12.238,0,0,0,24.2,31.948Zm0-19.209a6.985,6.985,0,1,1-6.985,6.985A7.006,7.006,0,0,1,24.2,12.739ZM55.636,31.948A12.224,12.224,0,1,0,43.411,19.724,12.238,12.238,0,0,0,55.636,31.948Zm0-19.209a6.985,6.985,0,1,1-6.985,6.985A7.006,7.006,0,0,1,55.636,12.739Z'
                  transform='translate(-1.5 -7.5)'
                  fill='#59B6BE'
                />
              </svg>
            ),
            title: 'The most effective treatment for obesity. ',
          },
          {
            icon: (
              <svg
                className='beni-icon'
                xmlns='http://www.w3.org/2000/svg'
                width='51.865'
                height='55.936'
                viewBox='0 0 51.865 55.936'
              >
                <path
                  id='Icon_ionic-ios-thumbs-up'
                  data-name='Icon ionic-ios-thumbs-up'
                  d='M50.775,46.316a5.116,5.116,0,0,0,2.1-3.957,5.532,5.532,0,0,0-1.374-3.667c-.076-.151-.139-.29-.2-.428l-.025-.139a1.645,1.645,0,0,1,.252-1.26,5.524,5.524,0,0,0,.731-3.239A3.829,3.829,0,0,0,51.04,30.7a2.4,2.4,0,0,1-.517-2.306,4.86,4.86,0,0,0-1.033-4.763c-1.323-1.487-2.558-1.663-5.847-1.474-2.268.139-6.994.87-10.107,1.361-1.273.2-2.382.378-2.722.4-1.6.164-1.915,0-2.067-.617a4.086,4.086,0,0,1,.617-1.638,27.024,27.024,0,0,0,1.827-4.511c1.273-4.184,1.134-8.72-.34-11.367a4.118,4.118,0,0,0-3.314-2.407c-1.449,0-2.936.3-3.516,1-.428.5-.29,1.462-.781,3.377a36.743,36.743,0,0,1-1.512,5.293c-.945,2.092-5,5.683-7.183,7.611-.517.454-.958.844-1.273,1.147a25.975,25.975,0,0,0-3.213,4.121,21.935,21.935,0,0,1-1.726,2.331,4.552,4.552,0,0,1-2.81,1.374A1.011,1.011,0,0,0,4.514,30.64v20.1A1.043,1.043,0,0,0,5.56,51.785a19.859,19.859,0,0,1,8.368,1.487A21.778,21.778,0,0,0,19.775,54.8a121.045,121.045,0,0,0,14.19,1.021,62.8,62.8,0,0,0,7.133-.491c3.907-.454,8.09-1.361,9.476-4.663a3.559,3.559,0,0,0-.063-2.987.991.991,0,0,1-.1-.328A1.068,1.068,0,0,1,50.775,46.316Z'
                  transform='translate(-2.764 -1.632)'
                  fill='none'
                  stroke='#59B6BE'
                  strokeWidth='3.5'
                />
              </svg>
            ),
            title: 'Only 1-2 weeks of recovery needed.',
          },
          {
            icon: (
              <svg
                className='beni-icon'
                xmlns='http://www.w3.org/2000/svg'
                width='56.436'
                height='56.436'
                viewBox='0 0 56.436 56.436'
              >
                <path
                  id='Icon_map-health'
                  data-name='Icon map-health'
                  d='M53.156,18.2a1.1,1.1,0,0,0-1.092-1.092h-14.2a1.1,1.1,0,0,1-1.092-1.092V1.812A1.1,1.1,0,0,0,35.677.72H18.2a1.1,1.1,0,0,0-1.092,1.092v14.2a1.1,1.1,0,0,1-1.092,1.092H1.812A1.1,1.1,0,0,0,.72,18.2V35.677A1.1,1.1,0,0,0,1.812,36.77h14.2a1.1,1.1,0,0,1,1.092,1.092v14.2A1.1,1.1,0,0,0,18.2,53.156H35.677a1.1,1.1,0,0,0,1.092-1.092v-14.2a1.1,1.1,0,0,1,1.092-1.092h14.2a1.1,1.1,0,0,0,1.092-1.092Z'
                  transform='translate(1.28 1.28)'
                  fill='none'
                  stroke='#59B6BE'
                  strokeWidth='4'
                />
              </svg>
            ),
            title: '95% lost at least 50% of their weight.',
          },
        ]}
      />
      <ImgLeftTextRight
        img={
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/weight-loss-surgeon-match/weight-loss-surgeon-match-patient.jpg'
        }
        headline={'We find the best care for you:'}
        subheadline={'All PatientPartner™ recommended surgeons:'}
        list={[
          <span>Have performed on average 2,000 procedures and have an average of 10+ years of experience</span>,
          <span>Are ASMBS certified, the highest certification in bariatric surgery.</span>,
          <span>Provide an option to speak to their past patients about their surgical experience for free.</span>,
        ]}
        cta={
          <button className='gtm-wl-get-connected' onClick={scrollToQuestionnaire}>
            Find a surgeon
          </button>
        }
      />
      <HowItWorks
        leftHead={
          <h2 className='text-left'>
            Finding the best surgeon is <u>easy</u>
          </h2>
        }
        bublets={[
          {
            icon: (
              <svg width='359' height='344' viewBox='0 0 359 344' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M314.125 22.4375C320.076 22.4375 325.783 24.8014 329.991 29.0093C334.199 33.2171 336.562 38.9242 336.562 44.875V224.375C336.562 230.326 334.199 236.033 329.991 240.241C325.783 244.449 320.076 246.812 314.125 246.812H99.0391C87.1385 246.815 75.7262 251.545 67.3125 259.961L22.4375 304.836V44.875C22.4375 38.9242 24.8014 33.2171 29.0093 29.0093C33.2171 24.8014 38.9242 22.4375 44.875 22.4375H314.125ZM44.875 0C32.9734 0 21.5593 4.72789 13.1436 13.1436C4.72789 21.5593 0 32.9734 0 44.875L0 331.918C0.000471521 334.138 0.659496 336.308 1.89366 338.153C3.12783 339.998 4.88166 341.435 6.93317 342.283C8.98469 343.131 11.2417 343.352 13.4184 342.917C15.5952 342.481 17.5939 341.41 19.1616 339.838L83.1758 275.824C87.3827 271.616 93.0888 269.251 99.0391 269.25H314.125C326.027 269.25 337.441 264.522 345.856 256.106C354.272 247.691 359 236.277 359 224.375V44.875C359 32.9734 354.272 21.5593 345.856 13.1436C337.441 4.72789 326.027 0 314.125 0L44.875 0Z'
                  fill='black'
                />
                <path
                  d='M67.3125 78.5312C67.3125 75.5559 68.4945 72.7023 70.5984 70.5984C72.7023 68.4945 75.5559 67.3125 78.5312 67.3125H280.469C283.444 67.3125 286.298 68.4945 288.402 70.5984C290.506 72.7023 291.688 75.5559 291.688 78.5312C291.688 81.5066 290.506 84.3602 288.402 86.4641C286.298 88.568 283.444 89.75 280.469 89.75H78.5312C75.5559 89.75 72.7023 88.568 70.5984 86.4641C68.4945 84.3602 67.3125 81.5066 67.3125 78.5312ZM67.3125 134.625C67.3125 131.65 68.4945 128.796 70.5984 126.692C72.7023 124.588 75.5559 123.406 78.5312 123.406H280.469C283.444 123.406 286.298 124.588 288.402 126.692C290.506 128.796 291.688 131.65 291.688 134.625C291.688 137.6 290.506 140.454 288.402 142.558C286.298 144.662 283.444 145.844 280.469 145.844H78.5312C75.5559 145.844 72.7023 144.662 70.5984 142.558C68.4945 140.454 67.3125 137.6 67.3125 134.625ZM67.3125 190.719C67.3125 187.743 68.4945 184.89 70.5984 182.786C72.7023 180.682 75.5559 179.5 78.5312 179.5H190.719C193.694 179.5 196.548 180.682 198.652 182.786C200.756 184.89 201.938 187.743 201.938 190.719C201.938 193.694 200.756 196.548 198.652 198.652C196.548 200.756 193.694 201.938 190.719 201.938H78.5312C75.5559 201.938 72.7023 200.756 70.5984 198.652C68.4945 196.548 67.3125 193.694 67.3125 190.719Z'
                  fill='black'
                />
              </svg>
            ),
            head: 'Tell us about yourself',
            para: 'Take a short questionnaire about your current health, location, and insurance plan to find the right surgeon recommendation.',
          },
          {
            icon: (
              <svg viewBox='0 0 45 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M14.8182 32.8889V32.3889H14.3182H4.09091C2.10054 32.3889 0.5 30.7667 0.5 28.7778V4.11111C0.5 2.10326 2.09892 0.5 4.09091 0.5H40.9091C42.8821 0.5 44.4998 2.10467 44.5 4.1107C44.5 4.11083 44.5 4.11097 44.5 4.11111L44.4795 28.7774V28.7778C44.4795 30.7683 42.8774 32.3889 40.9091 32.3889H30.6818H30.1818V32.8889V36.5H14.8182V32.8889ZM40.9091 29.2778H41.4091V28.7778V4.11111V3.61111H40.9091H4.09091H3.59091V4.11111V28.7778V29.2778H4.09091H40.9091Z'
                  fill='#203856'
                  stroke='white'
                />
                <path
                  d='M30.625 23.725H30.725V23.625V22.25C30.725 22.1119 30.6428 21.9722 30.5238 21.8405C30.402 21.7056 30.2273 21.5634 30.0112 21.4192C29.5784 21.1303 28.9662 20.824 28.2407 20.5442C26.7896 19.9845 24.8711 19.525 23 19.525C21.1289 19.525 19.2104 19.9845 17.7593 20.5442C17.0338 20.824 16.4216 21.1303 15.9888 21.4192C15.7727 21.5634 15.598 21.7056 15.4762 21.8405C15.3572 21.9722 15.275 22.1119 15.275 22.25V23.625V23.725H15.375H30.625ZM25.725 11C25.725 9.49477 24.5052 8.275 23 8.275C21.4948 8.275 20.275 9.49477 20.275 11C20.275 12.5052 21.4948 13.725 23 13.725C24.5052 13.725 25.725 12.5052 25.725 11ZM18.1 11C18.1 8.29273 20.2927 6.1 23 6.1C25.7073 6.1 27.9 8.29273 27.9 11C27.9 13.7073 25.7073 15.9 23 15.9C20.2927 15.9 18.1 13.7073 18.1 11ZM13.1 22.25C13.1 21.4555 13.4972 20.7521 14.161 20.1395C14.8254 19.5262 15.7503 19.0107 16.7856 18.596C18.8564 17.7664 21.3438 17.35 23 17.35C24.6562 17.35 27.1436 17.7664 29.2144 18.596C30.2497 19.0107 31.1746 19.5262 31.839 20.1395C32.5028 20.7521 32.9 21.4555 32.9 22.25V25.9H13.1V22.25Z'
                  fill='#203856'
                  stroke='white'
                  strokeWidth='0.2'
                />
              </svg>
            ),
            head: 'Review your surgeon recommendation',
            para: 'Learn more about a surgeon in your area that meets your criteria.',
          },
          {
            icon: (
              <svg viewBox='0 0 28 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M22.1818 2.72727V3.72727H23.1818H24.5455C25.4932 3.72727 26.2727 4.50683 26.2727 5.45455V27.2727C26.2727 28.2204 25.4932 29 24.5455 29H2.72727C1.77956 29 1 28.2204 1 27.2727V5.45455C1 4.50683 1.77956 3.72727 2.72727 3.72727H4.09091H5.09091V2.72727V1H5.81818V2.72727V3.72727H6.81818H20.4545H21.4545V2.72727V1H22.1818V2.72727ZM24.5455 28.2727H25.5455V27.2727V9.54545V8.54545H24.5455H2.72727H1.72727V9.54545V27.2727V28.2727H2.72727H24.5455Z'
                  stroke='#314D69'
                  strokeWidth='2'
                />
              </svg>
            ),
            head: 'Request an appointment',
            para: 'Request a time that works best for you to have a consultation with a surgeon and get started on your journey to a healthier, happier you.',
          },
        ]}
      />
      <div
        id='questionnaire'
        className='container-fluid pad-cont'
        style={{
          backgroundColor: 'rgb(245, 247, 249)',
          alignContent: 'center',
          padding: '0px',
          borderRadius: '8px',
          width: '96%',
        }}
      >
        <div className='row justify-content-center h-100'>
          <div className='col my-auto'>
            <Suspense fallback={<p>loading</p>}>
              <SurgeonMatchQuestionnaire
                globalAnswers={home.answers}
                setGlobalAnswers={updateAnswersAction}
                progress={home.surgeonMatchQuestionnaireProgress}
                setProgress={setSurgeonMatchQuestionnaireProgress}
                contactId={home.contactId}
                surgeon={home.surgeon}
                setSurgeon={setSurgeon}
                setContactIdAction={setContactIdAction}
                predefinedProcedures={['Weight Loss']}
                predefinedSurgeries={["I'm not sure"]}
                gtm={'Weight Loss'}
              />
            </Suspense>
          </div>
        </div>
      </div>
      <EmailSignupFooter
        headline={'Receive a free insurance guide today!'}
        para={
          "Receive a free guide to learn more about weight-loss surgery options, determine if your insurance plan will cover your procedure, what do to if it doesn't, and what steps to take if it does!"
        }
        successMessage={'Check your email inbox for information about insurance coverage!'}
        type={'DownloadGuideContacts::WeightLossSurgeonMatch'}
        gtm={'wl-surgeon-match-lead-magnet'}
      />
    </div>
  );
}
