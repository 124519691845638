import React, { useState, useEffect, useContext } from 'react';
import NewPatientMenu from '../new_patient_menu';
import { useTransition, useChain, animated, useSpringRef } from '@react-spring/web';
import styles from './index.module.css';
import { Link, useHistory } from 'react-router-dom';
import Context from '../../../../utils/context';
import Skeleton from '../../../ui_kit/utilities/skeleton';
import Pagination from '../../../ui_kit/utilities/pagination';
import { getSurgeonPatientLeads } from '../../../../api/surgeon_patient_lead';
import StatusMessage from '../../../ui_kit/utilities/status_message';
import {
  getSurgeonReferredMentorMatches,
  updateSurgeonReferredMentorMatch,
} from '../../../../api/surgeon_referred_mentor_matches';
import { createPatientAssistantInfo } from '../../../../api/patient_assistant_infos';

export default function MentorConnect() {
  const [view, setView] = useState('loading');
  const [surgeonPatientLeads, setSurgeonPatientLeads] = useState(null);
  const [surgeonReferredMentorMatches, setSurgeonReferredMentorMatches] = useState(null);
  const [sortBy, setSortBy] = useState('');
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile();
  const user = context.auth.getCurrentUser();
  let history = useHistory();
  const [searchTerm, setSearchTerm] = useState();

  const onFilterClick = () => {
    setView('loading');
    getSurgeonPatientLeads({ search_term: searchTerm }, (surgeonPatientLeadsArr) => {
      setSurgeonPatientLeads(surgeonPatientLeadsArr);
      getSurgeonReferredMentorMatches({ search_term: searchTerm }, (surgeonReferredMentorMatchesArr) => {
        setSurgeonReferredMentorMatches(surgeonReferredMentorMatchesArr);
        setView('loaded');
      });
    });
  };

  useEffect(() => {
    getSurgeonPatientLeads({}, (surgeonPatientLeadsArr) => {
      setSurgeonPatientLeads(surgeonPatientLeadsArr);
      getSurgeonReferredMentorMatches({}, (surgeonReferredMentorMatchesArr) => {
        setSurgeonReferredMentorMatches(surgeonReferredMentorMatchesArr);
        setView('loaded');
      });
    });
  }, []);

  let providerPatientsSorted = [];
  if (surgeonPatientLeads !== null && surgeonReferredMentorMatches !== null) {
    providerPatientsSorted = [
      ...surgeonReferredMentorMatches.map((srmm) => ({ ...srmm, isSurgeonPatientLead: false })),
      ...surgeonPatientLeads.map((spl) => ({ ...spl, isSurgeonPatientLead: true })),
    ];
  }

  const dateReceived = (date) => {
    if (date === null) return null;
    var a = new Date(date);

    var year = a.getFullYear();

    var month = (1 + a.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = a.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    if (month && day && year) {
      return month + '/' + day + '/' + year;
    }
    return '';
  };

  const timeReceived = (date) => {
    if (date === null) return null;
    return new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  };

  const sortByName = (patientsArr) => {
    function compare(a, b) {
      var lastA = a.name.toLowerCase();
      var lastB = b.name.toLowerCase();

      if (lastA < lastB) return -1;
      if (lastA > lastB) return 1;
      return 0;
    }

    return [...patientsArr].sort(compare);
  };

  const sortByReadyForNextStep = (patientsArr) => {
    function compare(a, b) {
      var x = a.ready_for_next_step_sortable;
      var y = b.ready_for_next_step_sortable;

      if (x < y) return -1;
      if (x > y) return 1;
      return 0;
    }

    return [...patientsArr].sort(compare);
  };

  const sortByEmail = (patientsArr) => {
    function compare(a, b) {
      var lastA = a.email;
      var lastB = b.email;

      if (lastA < lastB) return -1;
      if (lastA > lastB) return 1;
      return 0;
    }

    return [...patientsArr].sort(compare);
  };

  const sortByMentor = (patientsArr) => {
    function compare(a, b) {
      if (a.patient_partner === null && b.patient_partner === null) return 0;
      if (a.patient_partner === null) return -1;
      if (b.patient_partner === null) return 1;
      var lastA = a.patient_partner.name;
      var lastB = b.patient_partner.name;

      if (lastA < lastB) return -1;
      if (lastA > lastB) return 1;
      return 0;
    }

    return [...patientsArr].sort(compare);
  };

  const sortBySurgeon = (patientsArr) => {
    function compare(a, b) {
      var lastA = a.surgeon.name;
      var lastB = b.surgeon.name;

      if (lastA < lastB) return -1;
      if (lastA > lastB) return 1;
      return 0;
    }

    return [...patientsArr].sort(compare);
  };

  const sortByZipcode = (patientsArr) => {
    function compare(a, b) {
      var lastA = a.zipcode;
      var lastB = b.zipcode;

      if (lastA < lastB) return -1;
      if (lastA > lastB) return 1;
      return 0;
    }

    return [...patientsArr].sort(compare);
  };

  const statuses = [
    { status: 'error', message: 'No Connection' },
    { status: 'infoBlue', message: 'In Progress' },
    { status: 'success', message: 'Connection Complete' },
  ];

  const sortByStatus = (patientsArr) => {
    const statuses = {
      'No Connection': 1,
      'In Progress': 2,
      'Connection Complete': 3,
    };

    return [...patientsArr].sort((a, b) => {
      return statuses[a.status] - statuses[b.status];
    });
  };

  const sortByTime = (patientsArr) => {
    return [...patientsArr].sort((a, b) => {
      if (a.connection_date === null && b.connection_date === null) return 0;
      if (a.connection_date === null) return 1;
      if (b.connection_date === null) return -1;
      return new Date(b.connection_date).getTime() - new Date(a.connection_date).getTime();
    });
  };

  const isXcopriPatient = (r) => {
    if (r.surgeon.slug === 'xcopri') {
      return true;
    }
    return false;
  };

  const goToPatientConvo = (r) => {
    if (isXcopriPatient(r)) {
      return;
    }
    if (r.patient_assistant_info_uuid !== null)
      return history.push(`/provider/patients/${r.patient_assistant_info_uuid}?from=mentor-connect`);
    createPatientAssistantInfo(
      {
        surgeon_administrative_assistant_id: r.surgeon.surgeon_administrative_assistant_id,
        patient_profile_id: r.profile_id,
        surgeon_referred_mentor_match_uuid: r.uuid,
      },
      (patient_assistant_info) => history.push(`/provider/patients/${patient_assistant_info.uuid}?from=mentor-connect`)
    );
  };

  const setSortByWrapper = (type) => {
    if (sortBy === type) {
      setSortBy(`${type}-desc`);
    } else if (sortBy === `${type}-desc`) {
      setSortBy('');
    } else if (sortBy !== type) {
      setSortBy(type);
    }
  };
  const sortByMethod = () => {
    console.log('sortBy', sortBy);
    switch (sortBy) {
      case 'name':
      case 'name-desc':
        return sortByName(providerPatientsSorted);
      case 'email':
      case 'email-desc':
        return sortByEmail(providerPatientsSorted);
      case 'surgeon':
      case 'surgeon-desc':
        return sortBySurgeon(providerPatientsSorted);
      case 'connection':
      case 'connection-desc':
        return sortByTime(providerPatientsSorted);
      case 'mentor':
      case 'mentor-desc':
        return sortByMentor(providerPatientsSorted);
      case 'status':
      case 'status-desc':
        return sortByStatus(providerPatientsSorted);
      case 'readyForNextStep':
      case 'readyForNextStep-desc':
        return sortByReadyForNextStep(providerPatientsSorted);
      case 'zipcode':
      case 'zipcode-desc':
        return sortByZipcode(providerPatientsSorted);
      default:
        return providerPatientsSorted;
    }
  };
  providerPatientsSorted = sortBy.includes('desc') ? sortByMethod().reverse() : sortByMethod();

  const tableSkeletonAnimation = useSpringRef();
  const tableSkeleton = useTransition(
    view === 'loading'
      ? [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
      : providerPatientsSorted.slice(min, max),
    {
      ref: tableSkeletonAnimation,
      trail: 500 / 15,
      config: { mass: 0.5, tension: 200, friction: 20 },
      from: { opacity: 0, transform: 'translateX(50px)' },
      enter: { opacity: 1, transform: 'translateX(0px)', position: 'relative' },
    }
  );
  useChain([tableSkeletonAnimation]);

  const statusColors = {
    'No Connection': '#EC4E3E',
    'In Progress': '#419DA5',
    'Connection Complete': '#24A845',
  };

  const renderAllColHeaders = (clickable) => {
    return (
      <div className={`row ${styles.roster_row} ${styles.roster_row_header}`}>
        {renderColHeader('name', 'Patient name', clickable)}
        {renderColHeader('signup', 'Sign up date', clickable, 2)}
        {user.is_reactiv8 || (user.is_ossio && renderColHeader('zipcode', 'Zipcode', clickable, 1))}
        <div className='col-1'></div>
        {!user.is_nybg &&
          !user.is_reactiv8 &&
          !user.is_morehouse &&
          !user.is_ossio &&
          renderColHeader('surgeon', 'Surgeon name', clickable)}
        {renderColHeader('messaged', 'Matched mentor', clickable)}
        {renderColHeader('status', 'Connection status', clickable)}
        {renderColHeader('readyForNextStep', 'Ready for next step', clickable)}
      </div>
    );
  };

  const renderColHeader = (sortName, colName, clickable, colSize = 2) => {
    const onClick = () => {
      if (clickable) {
        setSortByWrapper(sortName);
      }
    };

    let sortByClass = styles.col_null;
    if (sortBy === sortName) {
      sortByClass = styles.col_active;
    } else if (sortBy === `${sortName}-desc`) {
      sortByClass = styles.col_active_flipped;
    }

    return (
      <div className={`col-${colSize}`}>
        <p className={`no-select ${styles.hover_col} ${sortByClass}`} onClick={onClick}>
          {colName}
        </p>
        <svg
          className={
            sortBy === sortName
              ? styles.arrow_active
              : sortBy === `${sortName}-desc`
                ? styles.arrow_active_flipped
                : styles.arrow_null
          }
          width='15'
          height='10'
          viewBox='0 0 15 10'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M1 1L7.27586 8L14 1' stroke='#59B6BE' strokeWidth='1.5' strokeLinecap='round' />
        </svg>
      </div>
    );
  };

  return (
    <div>
      {view === 'loading' && (
        <div className={`${styles.roster_container_wrapper}`}>
          <Skeleton />
          <div className={`${styles.roster_container} container-fluid`}>
            {!isMobile && (
              <>
                <div className='row'></div>
                {renderAllColHeaders(false)}
              </>
            )}
            {tableSkeleton((style, item) => (
              <animated.div key={item} style={{ ...style }}>
                {!isMobile && (
                  <div className={`${styles.roster_row}`}>
                    <div className={`row h-100 ${styles.roster_row_hover}`}>
                      <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                        <svg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            d='M5.56152 10C8.32295 10 10.5615 7.76142 10.5615 5C10.5615 2.23858 8.32295 0 5.56152 0C2.8001 0 0.561523 2.23858 0.561523 5C0.561523 7.76142 2.8001 10 5.56152 10Z'
                            fill='#eee'
                          />
                        </svg>
                      </div>
                      <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                        <Skeleton />
                      </div>
                      <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                        <Skeleton />
                      </div>
                      <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                        <p>
                          <Skeleton />
                        </p>
                      </div>
                      <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                        <Skeleton />
                      </div>
                      <div className={`col my-auto`}>
                        <Skeleton shape='box' />
                      </div>
                    </div>
                  </div>
                )}
                {isMobile && (
                  <div className={`${styles.roster_row_mobile}`}>
                    <div className={`row h-100 ${styles.roster_row_hover}`}>
                      <div style={{ backgroundColor: '#eee' }} className={`${styles.row_status_marker}`}></div>
                      <div className={`col-1`}>
                        <p className={`${styles.roster_col}`}>
                          <Skeleton />
                        </p>
                      </div>
                      <div className={`col`}>
                        <p className={`${styles.roster_mobile_name}`}>
                          <Skeleton />
                        </p>
                        <p>
                          <Skeleton />
                        </p>
                        <p style={{ backgroundColor: '#eee', width: '80px' }} className={`${styles.col_status_marker}`}>
                          <Skeleton />
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </animated.div>
            ))}
          </div>
        </div>
      )}
      {view === 'loaded' && (
        <div className={`${styles.roster_container_wrapper}`}>
          <div className={styles.mentor_connect_heading} style={{ marginTop: '20px', marginBottom: '35px' }}>
            <h5 className={`${styles.roster_heading}`}>MentorConnect</h5>
            <NewPatientMenu />
          </div>
          <div className={`material-input ${styles.filter_input_container}`}>
            <input type='text' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            <button className={`submit ${styles.filter_input_submit}`} onClick={onFilterClick}>
              Search
            </button>
          </div>
          <div className={`${styles.roster_container}`}>
            {!isMobile && renderAllColHeaders(true)}
            {providerPatientsSorted.length === 0 ? (
              <div className={styles.no_patients_notif_wrap}>
                <div className={styles.no_patients_notif}>
                  <img
                    src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/icons/crying-emoji.jpg'
                    alt='sad face'
                  />
                  <h5>No patients invited!</h5>
                  <p>
                    It doesn't look like any patients have been invited to speak with a mentor. Click
                    <span>+ Invite new patient</span> to invite a new patient.
                  </p>
                </div>
              </div>
            ) : (
              providerPatientsSorted.slice(min, max).map((r) => {
                if (!isMobile) {
                  return (
                    <div className={`row ${styles.roster_row} h-100 ${styles.roster_row_hover}`}>
                      <div className={`col-2 cursor-pointer ${styles.roster_col} ${styles.name_col}`}>
                        <p>{r.name}</p>
                      </div>
                      <div className={`col-2 cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                        <p style={{ width: '100%', display: 'block' }}>{dateReceived(r.created_at)}</p>
                      </div>
                      {user.is_reactiv8 ||
                        (user.is_ossio && (
                          <div className={`col-1 cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                            <p style={{ width: '100%', display: 'block' }}>{r.zipcode}</p>
                          </div>
                        ))}

                      <div className={`col-1 cursor-pointer ${styles.roster_col}`}>
                        <div className={styles.contact_icons}>
                          <div onClick={() => goToPatientConvo(r)} className={styles.message_bubble}>
                            {!r.isSurgeonPatientLead && (
                              <>
                                <svg
                                  style={styles.message_icon}
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M18 11L21 14V4.99999C21 3.89542 20.1046 2.99999 19 2.99999L12 3C10.8954 3 10 3.89543 10 5L9.99999 8.99999C9.99999 10.1046 10.8954 11 12 11H18Z'
                                    stroke='#419DA5'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <path
                                    d='M14 15V16C14 17.1046 13.1046 18 12 18H6L3 21V12C3 10.8954 3.89543 10 5 10H6'
                                    stroke='#419DA5'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      {!user.is_nybg && !user.is_reactiv8 && !user.is_morehouse && !user.is_ossio && (
                        <div className={`col-2 cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                          <p>{r.surgeon.name}</p>
                        </div>
                      )}
                      {/*
                        <div className={`col cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                          <p style={{ width: '100%', display: 'block' }}>{dateReceived(r.connection_date)}
                            <span style={{ opacity: '60%', display: 'block' }}>{timeReceived(r.connection_date)}</span>
                          </p>
                        </div>
                        */}
                      <div className={`col-2 cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                        <p>{r.patient_partner_name}</p>
                      </div>

                      <div className={`col-2 my-auto`}>
                        <StatusMessage
                          message={r.status}
                          status={statuses.filter((s) => s.message === r.status)[0].status}
                        />
                      </div>

                      <div className={`col-2 my-auto`}>
                        {!r.isSurgeonPatientLead && r.ready_for_next_step === 'Yes' && (
                          <StatusMessage message={'Yes'} status={'success'} />
                        )}
                        {!r.isSurgeonPatientLead &&
                          r.ready_for_next_step !== null &&
                          r.ready_for_next_step !== 'Yes' && <StatusMessage message={'No'} status={'error'} />}
                        {!r.isSurgeonPatientLead && !r.ready_for_next_step && !r.post_survey_complete && (
                          <StatusMessage message={'Did not take survey'} status={'infoBlue'} />
                        )}
                      </div>
                    </div>
                  );
                }

                return (
                  <Link>
                    <div className={`${styles.roster_row_mobile}`}>
                      <div className={`row h-100 ${styles.roster_row_hover}`}>
                        <div
                          style={{ backgroundColor: statusColors[r.status] }}
                          className={`${styles.row_status_marker}`}
                        ></div>
                        <div className={`col-1`}>
                          <p className={`${styles.roster_col}`}>
                            {r.conversation && r.conversation.unread_messages > 0 && (
                              <svg
                                width='11'
                                height='10'
                                viewBox='0 0 11 10'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M5.56152 10C8.32295 10 10.5615 7.76142 10.5615 5C10.5615 2.23858 8.32295 0 5.56152 0C2.8001 0 0.561523 2.23858 0.561523 5C0.561523 7.76142 2.8001 10 5.56152 10Z'
                                  fill='#59B6BE'
                                />
                              </svg>
                            )}
                          </p>
                        </div>
                        <div className={`col`}>
                          <p className={`${styles.roster_mobile_name}`}>{r.name}</p>
                          <p className={`${styles.roster_mobile_name}`}>{r.email}</p>
                          <p>{r.patient_partner && r.patient_partner.name ? r.patient_partner.name : ''}</p>
                          <p
                            style={{ backgroundColor: statusColors[r.status] }}
                            className={`${styles.col_status_marker}`}
                          >
                            {r.status}
                          </p>
                        </div>
                        <div className={`${styles.roster_col_mobile_date}`}>
                          {r.connection_date && <p>{dateReceived(r.connection_date)}</p>}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })
            )}
            <Pagination length={providerPatientsSorted.length} setMax={setMax} setMin={setMin} amountPerPage={10} />
          </div>
        </div>
      )}
    </div>
  );
}
