export const getCareCosts = (params, successCallback) => {
  fetch(`/api/v1/care_cost_requests?${objToQueryString(params)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const createCareCost = (params, successCallback) => {
  fetch(`/api/v1/care_cost_requests`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const runCareCost = (params, successCallback) => {
  fetch(`/api/v1/care_cost_requests/${params.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
