export default class Viewport {
  constructor() {
    this.update();
  }

  getIsMobile = () => {
    this.update();
    return this.isMobile;
  };

  getPlatformHeight = () => {
    this.update();
    return this.platformHeight;
  };

  getIsSmallMobile = () => {
    this.update();
    return this.isSmallMobile;
  };

  update = () => {
    this.isMobile = window.innerWidth < 768;
    this.isSmallMobile = window.innerWidth < 534;
    this.platformHeight = null;
    if (document.querySelector('.platform-header')) {
      this.platformHeight = window.innerHeight - document.querySelector('.platform-header').offsetHeight;
    }
  };
}
