import React, { useState, useEffect } from 'react';
import ErrorMessage from '../ui_kit/utilities/error_message';

export default function HeightAndWeight({
  answers,
  onSubmit,
  onBack,
  gtmSubmitLabel = 'wl',
  gtm,
  finalCTA,
  para = 'We’ll match you with somone who had a similar BMI range prior to weight-loss surgery.',
}) {
  const [feet, setFeet] = useState(answers.feet || '');
  const [inches, setInches] = useState(answers.inches || '');
  const [currentWeight, setCurrentWeight] = useState(answers.currentWeight || '');
  const [heightError, setHeightError] = useState('');
  const [weightError, setWeightError] = useState('');
  const [heightDisabled, setHeightDisabled] = useState('disabled');
  const [weightDisabled, setWeightDisabled] = useState('disabled');
  const [disabled, setDisabled] = useState('disabled');

  useEffect(() => {
    validate();
  }, [feet, inches, currentWeight]);

  useEffect(() => {
    if (heightDisabled === '' && weightDisabled === '') {
      setDisabled('');
    }
  }, [heightDisabled, weightDisabled]);

  const validate = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (isNaN(feet) && feet !== '') {
      setHeightError('Please enter only numbers for feet');
      setHeightDisabled('disabled');
    } else if (isNaN(inches) && inches !== '') {
      setHeightError('Please enter only numbers for inches');
      setHeightDisabled('disabled');
    } else {
      setHeightError('');
      setHeightDisabled('');
    }

    if (feet === '' || inches === '') {
      setHeightDisabled('disabled');
    }

    if (isNaN(currentWeight) && currentWeight !== '') {
      setWeightError('Please enter only numbers for weight');
      setWeightDisabled('disabled');
    } else {
      setWeightError('');
      setWeightDisabled('');
    }

    if (currentWeight === '') {
      setWeightDisabled('disabled');
    }

    if (heightDisabled === 'disabled' || weightDisabled === 'disabled') {
      setDisabled('disabled');
    }
  };

  return (
    <div className='row height'>
      <div className='col-sm-2'></div>
      <div className='col-sm-8'>
        <form>
          <div>
            <div>
              <h5 className='question'>What is your current height and weight?</h5>
              <p>{para}</p>
              <h5 className='question'>Height</h5>
              <div className='row marg-top-20'>
                <div className='col'>
                  <div className='material-input light'>
                    <input
                      className='autofocus'
                      type='tel'
                      name='feet'
                      value={feet}
                      onChange={(e) => setFeet(e.target.value)}
                      onBlur={validate}
                      required
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label>Feet</label>
                  </div>
                </div>
              </div>
              <div className='row marg-top-20'>
                <div className='col'>
                  <div className='material-input light'>
                    <input
                      type='tel'
                      name='inches'
                      value={inches}
                      onChange={(e) => setInches(e.target.value)}
                      onBlur={validate}
                      required
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label>Inches</label>
                  </div>
                </div>
              </div>
            </div>
            {heightError && <ErrorMessage error={heightError} />}
          </div>
          <div className='marg-top-30'>
            <h5 className='question'>Weight</h5>
            <div className='material-input light'>
              <input
                type='tel'
                name='currentWeight'
                value={currentWeight}
                onChange={(e) => setCurrentWeight(e.target.value)}
                onBlur={validate}
                required
              />
              <span className='highlight'></span>
              <span className='bar'></span>
              <label>Lbs</label>
            </div>
          </div>
          {weightError && <ErrorMessage error={weightError} />}
          <div className='row button-holder'>
            <button
              data-gtm-click-label={`${gtm} - Height Back`}
              className='back col-6'
              type='button'
              onClick={() => onBack()}
            >
              Back
            </button>
            <button
              data-gtm-click-label={`${gtm} - Height Submit`}
              disabled={disabled}
              className={`submit col-6`}
              type='submit'
              onClick={(e) => {
                e.preventDefault();
                onSubmit({ ...answers, feet, inches, currentWeight });
              }}
            >
              {finalCTA ? finalCTA : 'Next'}
            </button>
          </div>
        </form>
      </div>
      <div className='col-sm-2'></div>
    </div>
  );
}
