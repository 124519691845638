import React from 'react';
import ContentLoader from 'react-content-loader';

export default function Skeleton({ shape = 'bar' }) {
  return (
    <>
      {shape === 'circle' && (
        <ContentLoader
          speed={2}
          width={44}
          height={44}
          viewBox='0 0 44 44'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <circle cx='20' cy='20' r='20' />
        </ContentLoader>
      )}
      {shape === 'pieChart' && (
        <ContentLoader
          speed={2}
          width={712}
          height={330}
          viewBox='0 0 712 330'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <rect x='42' y='26' rx='0' ry='0' width='263' height='22' />
          <rect x='49' y='86' rx='0' ry='0' width='16' height='16' />
          <rect x='77' y='87' rx='0' ry='0' width='208' height='15' />
          <rect x='79' y='141' rx='0' ry='0' width='208' height='14' />
          <rect x='83' y='242' rx='0' ry='0' width='205' height='14' />
          <circle cx='445' cy='155' r='115' />
          <rect x='80' y='193' rx='0' ry='0' width='208' height='15' />
          <rect x='50' y='140' rx='0' ry='0' width='16' height='16' />
          <rect x='51' y='191' rx='0' ry='0' width='16' height='16' />
          <rect x='54' y='242' rx='0' ry='0' width='16' height='16' />
        </ContentLoader>
      )}

      {shape === 'avatar-w-info' && (
        <ContentLoader
          speed={2}
          width={400}
          height={44}
          viewBox='0 0 400 44'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <rect x='48' y='8' rx='3' ry='3' width='88' height='6' />
          <rect x='48' y='26' rx='3' ry='3' width='52' height='6' />
          <circle cx='20' cy='20' r='20' />
        </ContentLoader>
      )}
      {shape === 'bar' && (
        <div>
          <svg role='img' aria-labelledby='loading-aria' viewBox='0 0 340 11' preserveAspectRatio='none'>
            <rect
              x='0'
              y='0'
              width='100%'
              height='11px'
              clip-path='url(#clip-path)'
              style={{ fill: 'url("#fill")' }}
            ></rect>
            <defs>
              <clipPath id='clip-path'>
                <rect x='0' y='0' rx='6' ry='6' width='70%' height='11px' />
              </clipPath>
              <linearGradient id='fill'>
                <stop offset='0.599964' stop-color='#f3f3f3' stop-opacity='1'>
                  <animate
                    attributeName='offset'
                    values='-2; -2; 1'
                    keyTimes='0; 0.25; 1'
                    dur='2s'
                    repeatCount='indefinite'
                  ></animate>
                </stop>
                <stop offset='1.59996' stop-color='#ecebeb' stop-opacity='1'>
                  <animate
                    attributeName='offset'
                    values='-1; -1; 2'
                    keyTimes='0; 0.25; 1'
                    dur='2s'
                    repeatCount='indefinite'
                  ></animate>
                </stop>
                <stop offset='2.59996' stop-color='#f3f3f3' stop-opacity='1'>
                  <animate
                    attributeName='offset'
                    values='0; 0; 3'
                    keyTimes='0; 0.25; 1'
                    dur='2s'
                    repeatCount='indefinite'
                  ></animate>
                </stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      )}
      {shape === 'box' && (
        <div>
          <svg role='img' aria-labelledby='loading-aria' viewBox='0 0 340 60' preserveAspectRatio='none'>
            <rect
              x='0'
              y='0'
              width='100%'
              height='60'
              clip-path='url(#clip-path-box)'
              style={{ fill: 'url("#fill")' }}
            ></rect>
            <defs>
              <clipPath id='clip-path-box'>
                <rect x='0' y='0' rx='6' ry='6' width='300' height='60' />
              </clipPath>
              <linearGradient id='fill'>
                <stop offset='0.599964' stop-color='#f3f3f3' stop-opacity='1'>
                  <animate
                    attributeName='offset'
                    values='-2; -2; 1'
                    keyTimes='0; 0.25; 1'
                    dur='2s'
                    repeatCount='indefinite'
                  ></animate>
                </stop>
                <stop offset='1.59996' stop-color='#ecebeb' stop-opacity='1'>
                  <animate
                    attributeName='offset'
                    values='-1; -1; 2'
                    keyTimes='0; 0.25; 1'
                    dur='2s'
                    repeatCount='indefinite'
                  ></animate>
                </stop>
                <stop offset='2.59996' stop-color='#f3f3f3' stop-opacity='1'>
                  <animate
                    attributeName='offset'
                    values='0; 0; 3'
                    keyTimes='0; 0.25; 1'
                    dur='2s'
                    repeatCount='indefinite'
                  ></animate>
                </stop>
              </linearGradient>
            </defs>
          </svg>
        </div>
      )}
    </>
  );
}
