import React, { useState, useContext, useRef } from 'react';
import styles from '../../index.module.css';
import GhostButton from '../../../ui_kit/utilities/ghost_button';
import RequestButton from '../../../ui_kit/utilities/request_button';
import Context from '../../../../utils/context';
import { successToast } from '../../../ui_kit/utilities/success_toast';
import Divider from '../../../ui_kit/utilities/divider';
import DatePicker from 'react-datepicker';
import DropdownInput from '../../../ui_kit/utilities/dropdown_input';
import { createActivity } from '../../../../api/activities';

export default function OtherDetails({ user }) {
  const context = useContext(Context);
  const currentUser = context.auth.getCurrentUser();
  const [editing, setEditing] = useState(false);
  const [profile, setProfile] = useState(user.current_profile);

  const [income, setIncome] = useState([profile.household_income]);
  const [adults, setAdults] = useState(profile.household_size.adults);
  const [minors, setMinors] = useState(profile.household_size.minors);
  const [disabled, setDisabled] = useState(false);

  const params = useRef({});

  if (!user.current_profile) {
    return <></>;
  }

  const validateIncome = (incomeInput) => {
    if (incomeInput[0] !== profile.household_income) {
      setDisabled(false);
      setIncome([incomeInput]);
      params.current = { ...params.current, household_income: incomeInput };
      if (
        incomeInput === 'Household income' ||
        incomeInput === 'household income' ||
        incomeInput === 'Household Income'
      ) {
        params.current = { ...params.current, household_income: null };
      }
    }
  };

  const validateAdults = (adultValue) => {
    if (adultValue !== profile.household_size.adults) {
      setDisabled(false);
      setAdults(adultValue);
      params.current = { ...params.current, household_adults: adultValue };
    }
  };

  const validateMinors = (minorValue) => {
    if (minorValue !== profile.household_size.minors) {
      setDisabled(false);
      setMinors(minorValue);
      params.current = { ...params.current, household_minors: minorValue };
    }
  };

  const updateInfo = (afterAction) => {
    let fields = [];
    if (((income && income[0]) || null) !== profile.household_income) {
      fields.push('household_income');
    }
    if (
      params.current.household_adults &&
      params.current.household_adults !== (profile.household_size.adults || -1).toString()
    ) {
      fields.push('household_adults');
    }
    if (
      params.current.household_minors &&
      params.current.household_minors !== (profile.household_size.minors || -1).toString()
    ) {
      fields.push('household_minors');
    }
    context.auth.updateProfile(params.current, (data) => {
      successToast('Your changes have been saved.');
      setProfile(data);
      setEditing(false);
      createActivity('Activity::EditProfile', { fields: fields.join(',') });
      afterAction();
    });
  };

  let profileIsCurrentUser = false;
  if (
    context.auth.isLoggedIn() &&
    currentUser.current_profile &&
    profile &&
    currentUser.current_profile.id === profile.id
  ) {
    profileIsCurrentUser = true;
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        {currentUser && currentUser.current_profile && profile && (
          <>
            {profileIsCurrentUser && !editing && (
              <GhostButton onClick={() => setEditing(true)} style={{ marginLeft: 'auto', marginBottom: '10px' }}>
                <img
                  style={{ marginRight: '8px', height: '16px', marginTop: '-4px' }}
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/edit.svg'
                />
                <span>Edit</span>
              </GhostButton>
            )}
            {profileIsCurrentUser && editing && (
              <>
                <GhostButton
                  style={{ marginLeft: 'auto', padding: '5px 23px', marginBottom: '10px' }}
                  onClick={() => setEditing(false)}
                >
                  <p>Cancel</p>
                </GhostButton>
                <RequestButton
                  style={{ padding: '5px 23px', marginBottom: '10px' }}
                  cta={'Save'}
                  request={(afterAction) => updateInfo(afterAction)}
                  disabled={disabled}
                />
              </>
            )}
          </>
        )}
      </div>
      <div className={`content_container marg-top-0`}>
        <div id='household_income'>
          {editing ? (
            <>
              <p className={styles.label}>household income</p>
              <DropdownInput
                type='single'
                options={[
                  {
                    value: 'Household income',
                  },
                  {
                    value: 'Less than $20,000',
                  },
                  {
                    value: '$20,000 to $34,999',
                  },
                  {
                    value: '$35,000 to $49,999',
                  },
                  {
                    value: '$50,000 to $74,999',
                  },
                  {
                    value: '$75,000 to $99,999',
                  },
                  {
                    value: 'Over $100,000',
                  },
                ]}
                values={[income]}
                setValues={validateIncome}
                light={false}
                wrapperClassname={styles.input_txt}
              />
            </>
          ) : (
            <>
              <div className={styles.bio}>
                {income[0] !== null && income[0] !== 'Household income' ? (
                  profileIsCurrentUser ? (
                    <>
                      <p className={`${styles.label} marg-top-0`}>household income</p>
                      <p className={styles.bio}>{income}</p>
                    </>
                  ) : (
                    <p className={styles.bio}>No income information provided yet</p>
                  )
                ) : profileIsCurrentUser ? (
                  <p onClick={() => setEditing(true)} className={styles.add_bio}>
                    + Add your income
                  </p>
                ) : (
                  <p className={styles.bio}>No income information provided yet</p>
                )}
              </div>
            </>
          )}
        </div>
        <Divider />
        <div id='household_size'>
          {editing ? (
            <>
              <div className='row'>
                <div className={`material-input marg-top-0 col-6 ${styles.inputs}`}>
                  <p className={`${styles.label}`}>Adults</p>
                  <input
                    className={styles.input_txt}
                    type='zipcode'
                    value={adults}
                    placeholder={adults}
                    onChange={(e) => {
                      validateAdults(e.target.value);
                    }}
                  />
                  <span className='highlight'></span>
                </div>
                <div className={`material-input marg-top-0 col-6 ${styles.inputs}`}>
                  <p className={`${styles.label}`}>minors</p>
                  <input
                    className={styles.input_txt}
                    type='text'
                    value={minors}
                    placeholder={minors}
                    onChange={(e) => {
                      validateMinors(e.target.value);
                    }}
                  />
                  <span className='highlight'></span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.bio}>
                {adults !== null ? (
                  profileIsCurrentUser ? (
                    <>
                      <p className={`${styles.label} marg-top-0`}>Household size</p>
                      <p
                        className={styles.bio}
                      >{`${adults || 0} adult${adults > 1 || adults === 0 ? 's' : ''}, ${minors || 0} minor${minors > 1 || minors === 0 ? 's' : ''}`}</p>
                    </>
                  ) : (
                    <p className={`${styles.bio} marg-top-15`}>No household size provided yet</p>
                  )
                ) : profileIsCurrentUser ? (
                  <p style={{ marginTop: '15px' }} onClick={() => setEditing(true)} className={styles.add_bio}>
                    + Add your household size
                  </p>
                ) : (
                  <p className={`${styles.bio} marg-top-15`}>No household size provided yet</p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
