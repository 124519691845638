import React, { useState, useEffect } from 'react';
import './index.css';

export default function ScrollToTopButton({ gtmClickLabelPage = 'Patient Partner Carousel' }) {
  // Listens to know if the user has scrolled from top START
  const [scrolledFromTop, setScrolledFromTop] = useState(window.pageYOffset);

  const updateScroll = () => {
    setScrolledFromTop(window.pageYOffset > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScroll);
    return () => window.removeEventListener('scroll', updateScroll);
  }, []);
  // Listens to know if the user has scrolled from top END

  const scrollToTop = () => {
    document.querySelector('#topOfPage').scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div
      data-gtm-click-label={`${gtmClickLabelPage} Scroll To Top`}
      onClick={scrollToTop}
      className={`back-to-top ${scrolledFromTop ? 'scrolledFromTop' : ''}`}
    >
      <svg xmlns='http://www.w3.org/2000/svg' width='11.294' height='19.754' viewBox='0 0 11.294 19.754'>
        <path
          id='Icon_ionic-ios-arrow-back'
          data-name='Icon ionic-ios-arrow-back'
          d='M14.655,16.068,22.13,8.6a1.412,1.412,0,0,0-2-1.994l-8.468,8.462a1.409,1.409,0,0,0-.041,1.947l8.5,8.521a1.412,1.412,0,0,0,2-1.994Z'
          transform='translate(-11.251 -6.194)'
          fill='#74CCD3'
          data-gtm-click-label={`${gtmClickLabelPage} Scroll To Top`}
        ></path>
      </svg>
    </div>
  );
}
