export const createSurgeonMatchContact = (
  name,
  email,
  phone,
  zipcode,
  surgery_type,
  insuranceCarrier,
  fromQuestionnaire,
  successFunction
) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  fetch('/surgeon_match_contacts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({
      ...window.utmInfo,
      name: name,
      email: email,
      phone: phone,
      zipcode: zipcode,
      surgery_type: surgery_type,
      insurance_carrier: insuranceCarrier,
      fromQuestionnaire: fromQuestionnaire,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successFunction(data);
    })
    .catch(function (error) {
      console.log(error);
    });
};
