import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { getConversations } from '../../../api/surgeon_administrative_assistants';
import styles from './index.module.css';
import Context from '../../../utils/context';
import Skeleton from '../../ui_kit/utilities/skeleton';
import { useTransition, useChain, animated, useSpringRef } from '@react-spring/web';

export default function AdminConversations() {
  const [view, setView] = useState('loading');
  const [conversations, setConversations] = useState([]);
  const [sortBy, setSortBy] = useState('');
  let history = useHistory();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [openStatus, setOpenStatus] = useState(null);
  const isMobile = useContext(Context).viewport.getIsMobile();

  useEffect(() => {
    getConversations({}, (data) => {
      setConversations(data);
      setView('loaded');
    });
  }, []);

  const dateReceived = (date) => {
    if (!date) {
      return '';
    }
    var a = new Date(date).toString().slice(4, 15);
    return a.substring(0, 6) + ',' + a.substring(6, 11);
  };

  const sortByStatus = (patientsArr) => {
    const status = {
      'Not Contacted': 1,
      Contacted: 2,
      'Consult scheduled': 3,
      'Surgery scheduled': 4,
      'Cancelled appt': 5,
      'No longer interested': 6,
    };
    return [...patientsArr].sort((a, b) => {
      return status[a.status] - status[b.status];
    });
  };

  const setSortByWrapper = (type) => {
    if (sortBy === type) {
      setSortBy(`${type}-desc`);
    } else if (sortBy === `${type}-desc`) {
      setSortBy('');
    } else if (sortBy !== type) {
      setSortBy(type);
    }
  };

  const tableSkeletonAnimation = useSpringRef();
  const tableSkeleton = useTransition(
    view === 'loading' ? [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}] : conversations.slice(min, max),
    {
      ref: tableSkeletonAnimation,
      trail: 500 / 15,
      config: { mass: 0.5, tension: 200, friction: 20 },
      from: { opacity: 0, transform: 'translateX(50px)' },
      enter: { opacity: 1, transform: 'translateX(0px)', position: 'relative' },
    }
  );
  useChain([tableSkeletonAnimation]);

  const renderColumnHeader = (colName) => {
    return (
      <div className='col'>
        <p className={`no-select ${styles.hover_col} ${styles.col_null}`}>{colName}</p>
        <svg
          className={styles.arrow_null}
          width='15'
          height='10'
          viewBox='0 0 15 10'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M1 1L7.27586 8L14 1' stroke='#59B6BE' strokeWidth='1.5' strokeLinecap='round' />
        </svg>
      </div>
    );
  };

  return (
    <>
      {view === 'loading' && (
        <div className={`${styles.roster_container_wrapper}`}>
          <Skeleton />
          <div className={`${styles.roster_container} container-fluid`}>
            <div className='row'></div>
            <div className={`${styles.roster_row} ${styles.roster_row_header}`}>
              {renderColumnHeader('Patient Name')}
              {renderColumnHeader('Mentor Name')}
              {renderColumnHeader('Date of First Message')}
              {renderColumnHeader('Date of Last Message')}
              {renderColumnHeader('Mentor Unresponsive for 24 hours')}
              {renderColumnHeader('Flagged Conversation')}
              {renderColumnHeader('View Conversation')}
            </div>
            {conversations.length === 0 && (
              <div className={styles.no_patients_notif}>
                <h4>New conversations will appear here!</h4>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 132.05 163.45'>
                  <g id='Layer_2' data-name='Layer 2'>
                    <g id='Layer_1-2' data-name='Layer 1'>
                      <path
                        fill='#74ccd3'
                        className='cls-1'
                        d='M131.9,78.63V38.83c0-5.75-.43-11.32-2.66-16.73A36,36,0,0,0,108.2,2C103.11.19,97.89,0,92.57,0H37.07C19.79,0,3.91,11.21.67,28.77-.11,33,0,37.29,0,41.59V61.06C0,72.67,0,84.29,0,95.9c.08,19.16,15.19,35.35,34.54,35.94,2.44.08,4.87,0,7.31,0l.25,0a3,3,0,0,0,2.7-1.5l.12-.13h0L68,99.44c3.92-.87,7.16-4,9.32-7.57C83.79,81.23,89.81,70.31,96,59.54c2.88-5,6.84-12.13,3.58-17.86-2.52-4.47-8.3-4.24-13.27-4a6.26,6.26,0,1,0,.09,6.69h1.1c1.58,0,5.4-.81,6.45.57s-.46,5.17-1.06,6.55a49.19,49.19,0,0,1-2.78,5.15L73.91,84.77c-1.7,2.93-3.61,7.87-7.4,8.37-3.94.52-6.06-4.15-7.69-7l-16.18-28c-1.87-3.24-6.08-8.58-4.58-12.46.87-2.26,4.84-1.28,6.77-1.28h.23a6.25,6.25,0,0,0,11.59-3.26h0a6.25,6.25,0,0,0-11.59-3.26H43.71c-3.09,0-6.29-.29-8.93,1.61-5.19,3.71-3.41,11.19-1.1,16,2.43,5.11,5.63,9.94,8.46,14.83L52.58,88.43c2.15,3.72,4.36,7.81,8.18,9.9l-20.25,27c-8,0-15.43-.65-22.22-5.68A29.34,29.34,0,0,1,6.57,96c-.1-12.3,0-24.6,0-36.9V39.68c0-5.9.25-11.59,3-17A29.4,29.4,0,0,1,35.49,6.54c3.51,0,7,0,10.52,0H89.68c4.63,0,9.34-.27,13.86.89a29.34,29.34,0,0,1,21.14,21.79c1,4.36.68,9,.68,13.44V64c0,11.24.45,22.57,0,33.81a29.25,29.25,0,0,1-17.26,25.11c-7.5,3.34-14.6,1.66-22.39,2.44-2.32.24-3.06,1.54-4.14,3.29l-4.73,7.68L65.62,154.48l-13-16.71c-2.58-3.31-7.16,1.34-4.62,4.62l15.68,20.08c1.35,1.73,4.14.95,5.13-.65L87.22,131.9c5.73.07,11.63.36,17.21-.92a35.81,35.81,0,0,0,23.17-17.71C133.31,102.72,131.9,90.2,131.9,78.63Z'
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
            )}
            {tableSkeleton((style, item) => (
              <animated.div key={item} style={{ ...style }}>
                <div className={`${styles.roster_row}`}>
                  <div className={`row h-100 ${styles.roster_row_hover}`}>
                    <div className={`col-1 cursor-pointer ${styles.roster_col_skeleton}`}>
                      <svg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M5.56152 10C8.32295 10 10.5615 7.76142 10.5615 5C10.5615 2.23858 8.32295 0 5.56152 0C2.8001 0 0.561523 2.23858 0.561523 5C0.561523 7.76142 2.8001 10 5.56152 10Z'
                          fill='#eee'
                        />
                      </svg>
                    </div>
                    <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                      <Skeleton />
                    </div>
                    <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                      <Skeleton />
                    </div>
                    <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                      <p>
                        <Skeleton />
                      </p>
                    </div>
                    <div className={`col cursor-pointer ${styles.roster_col_skeleton}`}>
                      <Skeleton />
                    </div>
                    <div className={`col my-auto`}>
                      <Skeleton shape='box' />
                    </div>
                  </div>
                </div>
              </animated.div>
            ))}
          </div>
        </div>
      )}
      {view === 'loaded' && (
        <>
          <div className={`${styles.roster_container_wrapper}`}>
            <h5 className={`${styles.roster_heading_innr} ${styles.roster_heading}`}>Conversations</h5>
            <div className={`${styles.roster_container}`}>
              <>
                <div className='row'></div>
                <div className={`${styles.roster_row} ${styles.roster_row_header}`}>
                  {renderColumnHeader('Patient Name')}
                  {renderColumnHeader('Mentor Name')}
                  {renderColumnHeader('Date of First Message')}
                  {renderColumnHeader('Date of Last Message')}
                  {renderColumnHeader('Mentor Unresponsive for 24 hours')}
                  {renderColumnHeader('Flagged Conversation')}
                  {renderColumnHeader('View Conversation')}
                </div>
              </>
              {conversations.length === 0 && (
                <div className={styles.no_patients_notif}>
                  <h4>New conversations will appear here!</h4>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 132.05 163.45'>
                    <g id='Layer_2' data-name='Layer 2'>
                      <g id='Layer_1-2' data-name='Layer 1'>
                        <path
                          fill='#74ccd3'
                          className='cls-1'
                          d='M131.9,78.63V38.83c0-5.75-.43-11.32-2.66-16.73A36,36,0,0,0,108.2,2C103.11.19,97.89,0,92.57,0H37.07C19.79,0,3.91,11.21.67,28.77-.11,33,0,37.29,0,41.59V61.06C0,72.67,0,84.29,0,95.9c.08,19.16,15.19,35.35,34.54,35.94,2.44.08,4.87,0,7.31,0l.25,0a3,3,0,0,0,2.7-1.5l.12-.13h0L68,99.44c3.92-.87,7.16-4,9.32-7.57C83.79,81.23,89.81,70.31,96,59.54c2.88-5,6.84-12.13,3.58-17.86-2.52-4.47-8.3-4.24-13.27-4a6.26,6.26,0,1,0,.09,6.69h1.1c1.58,0,5.4-.81,6.45.57s-.46,5.17-1.06,6.55a49.19,49.19,0,0,1-2.78,5.15L73.91,84.77c-1.7,2.93-3.61,7.87-7.4,8.37-3.94.52-6.06-4.15-7.69-7l-16.18-28c-1.87-3.24-6.08-8.58-4.58-12.46.87-2.26,4.84-1.28,6.77-1.28h.23a6.25,6.25,0,0,0,11.59-3.26h0a6.25,6.25,0,0,0-11.59-3.26H43.71c-3.09,0-6.29-.29-8.93,1.61-5.19,3.71-3.41,11.19-1.1,16,2.43,5.11,5.63,9.94,8.46,14.83L52.58,88.43c2.15,3.72,4.36,7.81,8.18,9.9l-20.25,27c-8,0-15.43-.65-22.22-5.68A29.34,29.34,0,0,1,6.57,96c-.1-12.3,0-24.6,0-36.9V39.68c0-5.9.25-11.59,3-17A29.4,29.4,0,0,1,35.49,6.54c3.51,0,7,0,10.52,0H89.68c4.63,0,9.34-.27,13.86.89a29.34,29.34,0,0,1,21.14,21.79c1,4.36.68,9,.68,13.44V64c0,11.24.45,22.57,0,33.81a29.25,29.25,0,0,1-17.26,25.11c-7.5,3.34-14.6,1.66-22.39,2.44-2.32.24-3.06,1.54-4.14,3.29l-4.73,7.68L65.62,154.48l-13-16.71c-2.58-3.31-7.16,1.34-4.62,4.62l15.68,20.08c1.35,1.73,4.14.95,5.13-.65L87.22,131.9c5.73.07,11.63.36,17.21-.92a35.81,35.81,0,0,0,23.17-17.71C133.31,102.72,131.9,90.2,131.9,78.63Z'
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
              )}
              {conversations.length !== 0 &&
                conversations.map((r) => {
                  return (
                    <div className={`${styles.roster_row}`}>
                      <div
                        onClick={() => history.push(`/admin/conversations/${r.uuid}`)}
                        className={`h-100 ${styles.roster_row_hover}`}
                      >
                        <div className={`col cursor-pointer ${styles.roster_col} ${styles.name_col}`}>
                          <p>{r.patient_name}</p>
                        </div>
                        <div className={`col cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                          <p>{r.mentor_name}</p>
                        </div>
                        <div className={`col cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                          <p style={{ display: 'block' }}>{dateReceived(r.first_message_date)}</p>
                        </div>
                        <div className={`col cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                          <p style={{ display: 'block' }}>{dateReceived(r.last_message_date)}</p>
                        </div>
                        <div className={`col cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                          <p style={{ display: 'block' }}>{r.mentor_responded ? 'Yes' : 'No'}</p>
                        </div>
                        <div className={`col cursor-pointer ${styles.roster_col} ${styles.bordered}`}>
                          <p style={{ display: 'block' }}>{r.flagged_conversation ? 'Yes' : 'No'}</p>
                        </div>
                        <div className={`col cursor-pointer ${styles.roster_col}`}>
                          <div className={styles.contact_icons}>
                            <div className={styles.message_bubble}>
                              <svg
                                style={styles.message_icon}
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M18 11L21 14V4.99999C21 3.89542 20.1046 2.99999 19 2.99999L12 3C10.8954 3 10 3.89543 10 5L9.99999 8.99999C9.99999 10.1046 10.8954 11 12 11H18Z'
                                  stroke='#419DA5'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <path
                                  d='M14 15V16C14 17.1046 13.1046 18 12 18H6L3 21V12C3 10.8954 3.89543 10 5 10H6'
                                  stroke='#419DA5'
                                  strokeWidth='2'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                              <div className={styles.bubble}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
