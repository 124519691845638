import { sha256 } from 'js-sha256';

export default class FacebookConversionApi {
  leadEvent = (lead) => {
    return;

    if (!gon.facebook_pixel_id || !gon.facebook_access_token) {
      return;
    }

    const form = new FormData();
    const user_data = {
      em: [sha256(lead.email)],
    };
    if (lead.firstName) {
      user_data.fn = [sha256(lead.firstName)];
    }
    if (lead.lastName) {
      user_data.ln = [sha256(lead.lastName)];
    }
    if (lead.phoneNumber) {
      user_data.ph = [sha256(lead.phoneNumber)];
    }
    if (lead.zipcode) {
      user_data.zp = [sha256(lead.zipcode)];
    }

    form.append(
      'data',
      JSON.stringify([
        {
          event_name: 'Lead',
          event_time: Math.floor(Date.now() / 1000),
          action_source: 'website',
          user_data: user_data,
        },
      ])
    );
    if (gon.facebook_test_event_code) {
      form.append('test_event_code', gon.facebook_test_event_code);
    }

    fetch(
      `https://graph.facebook.com/v12.0/${gon.facebook_pixel_id}/events?access_token=${gon.facebook_access_token}`,
      {
        method: 'POST',
        body: form,
      }
    );
  };

  pageViewEvent = () => {
    if (!gon.facebook_pixel_id || !gon.facebook_access_token) {
      return;
    }

    const form = new FormData();
    form.append(
      'data',
      JSON.stringify([
        {
          event_name: 'PageView',
          event_time: Math.floor(Date.now() / 1000),
          action_source: 'website',
          user_data: {
            em: [null],
          },
        },
      ])
    );
    if (gon.facebook_test_event_code) {
      form.append('test_event_code', gon.facebook_test_event_code);
    }

    fetch(
      `https://graph.facebook.com/v12.0/${gon.facebook_pixel_id}/events?access_token=${gon.facebook_access_token}`,
      {
        method: 'POST',
        body: form,
      }
    );
  };
}
