import React from 'react';

export default function Divider({ color = '#F2F2F2' }) {
  return (
    <div
      style={{
        background: color,
        height: '1px',
        width: '100%',
        display: 'block',
        marginTop: '16px',
        marginBottom: '6px',
      }}
    ></div>
  );
}
