import React, { useRef, useState, useContext } from 'react';
import styles from '../../posts/createpost.module.css';
import PasswordInput from '../../ui_kit/utilities/password_input';
import { useClickAway } from 'react-use';
import RequestButton from '../../ui_kit/utilities/request_button';
import Context from '../../../utils/context';
import { successToast } from '../../ui_kit/utilities/success_toast';
import { failToast } from '../../ui_kit/utilities/fail_toast';

export default function ChangePassword({ setClickModal }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const ref = useRef(null);
  const context = useContext(Context);
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const isDisabled = currentPasswordError || newPasswordError || !currentPassword || !newPassword;

  useClickAway(ref, () => {
    setClickModal(false);
  });

  const handleSubmit = () => {
    let params = {};
    if (newPassword !== '' && currentPassword !== '') {
      params.current_password = currentPassword;
      params.new_password = newPassword;
    }

    context.auth.userUpdate(
      params,
      () => {
        successToast('Your password has been changed.');
      },
      (errors) => {
        failToast(errors.join(','));
      }
    );

    setClickModal(false);
  };

  return (
    <div className={`${styles.create_post_otr} container-fluid`}>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className={styles.change_password_container} ref={ref}>
            <div className={`${styles.change_password_wrap}`}>
              <div className={`${styles.create_new_banner}`}>
                <p className={`${styles.nb_para}`}>Change password</p>
                <span onClick={() => setClickModal(false)} className={`${styles.close}`}>
                  <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg' />
                </span>
              </div>
              <div className={`${styles.content}`}>
                <form>
                  <PasswordInput
                    password={currentPassword}
                    setPassword={setCurrentPassword}
                    label='Current password'
                    medium={true}
                    signUp={false}
                    onError={setCurrentPasswordError}
                  />
                  <PasswordInput
                    password={newPassword}
                    setPassword={setNewPassword}
                    label='New password'
                    medium={true}
                    requireConfirmation
                    signUp={true}
                    onError={setNewPasswordError}
                  />
                  <div className={`row ${styles.cta_wrap}`} style={{ display: 'flex' }}>
                    <div className='col-md-12'>
                      <button
                        onClick={() => setClickModal(false)}
                        className={`${styles.create_btn} liner`}
                        style={{ color: 'black', border: 'none' }}
                      >
                        Cancel
                      </button>
                      <RequestButton
                        classnames={`${styles.create_btn}`}
                        cta='Change password'
                        style={{ float: 'right' }}
                        request={handleSubmit}
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12">
                      <p className={`${styles.error}`}>{error}</p>
                    </div>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
