import React from 'react';
import { useHistory } from 'react-router-dom';
import SurgeonMatchQuestionnaire from '../../../questionnaire/surgeon_match';
import styles from '../index.module.css';

export default function GetStartedSurgeonMatchWomensHealth({
  home,
  updateAnswersAction,
  setContactIdAction,
  setSurgeonMatchQuestionnaireProgress,
  setSurgeon,
}) {
  let history = useHistory();

  const onBackClick = () => {
    history.goBack();
  };

  return (
    <>
      <div className={styles.questionnaire_ottr} style={{ backgroundColor: '#f5f7f9' }}>
        <div id='questionnaire'>
          <div className='row justify-content-center h-100'>
            <div className='col h-100'>
              <SurgeonMatchQuestionnaire
                globalAnswers={{ ...home.answers, selectedProcedures: ["Women's health"] }}
                setGlobalAnswers={updateAnswersAction}
                progress={home.surgeonMatchQuestionnaireProgress}
                setProgress={setSurgeonMatchQuestionnaireProgress}
                contactId={home.contactId}
                setContactIdAction={setContactIdAction}
                surgeon={home.surgeon}
                setSurgeon={setSurgeon}
                includeBackButton
                gtm={`Get Started Surgeon Match Uterine Fibroids`}
                predefinedProcedures={["Women's health"]}
                predefinedSurgeries={['Radiofrequency Ablation (Fibroids Treatment)']}
                newFlow
                standAlone
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
