import React, { Component, useState, useEffect } from 'react';
import './todpicker.css';

export default function Todpicker({
  options = ['Preferred appt. time', 'Morning (9:00-12:00pm)', 'Afternoon (1:00-5:00pm)'],
  value,
  onChange,
  onBlur,
  light,
  todpickerClass = true,
  disabled = false,
}) {
  return (
    <div
      className={`material-input ${todpickerClass ? 'tod-picker' : ''} ${value !== options[0] ? 'remove-arrow' : ''} ${light ? 'light' : ''}`}
    >
      {todpickerClass && <label className={`${value !== options[0] ? 'in-final-position' : ''}`}>{options[0]}</label>}
      <select
        disabled={disabled}
        onBlur={onBlur}
        className={`${value === options[0] ? 'greyed-out' : ''}`}
        value={value}
        onChange={onChange}
        style={todpickerClass ? {} : { background: 'url()', marginBottom: '20px' }}
      >
        {options.map((option, index) => (
          <option
            selected={option === options[0] ? 'selected' : ''}
            disabled={index === 0 ? 'disabled' : ''}
            key={index}
          >
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
