export const createPaymentIntent = (params, successCallback, errorCallback) => {
  fetch('/api/v1/payments', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response.json());
    })
    .then((data) => successCallback(data))
    .catch((data) => data.then((result) => errorCallback(result)));
};

export const createSubscription = (params, successCallback, errorCallback) => {
  fetch('/api/v1/payments', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response.json());
    })
    .then((data) => successCallback(data))
    .catch((data) => data.then((result) => errorCallback(result)));
};

export const completePaymentIntent = (paymentIntentId, successCallback = () => {}, errorCallback = () => {}) => {
  fetch(`/api/v1/payments/${paymentIntentId}/complete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.ok) return response.json();
      return Promise.reject(response.json());
    })
    .then((data) => successCallback(data))
    .catch((data) => data.then((result) => errorCallback(result)));
};
