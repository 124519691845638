import React from 'react';
import OptionsAndOther from '../choose_an_option';

export default function PrimaryCaregiver({ answers, onSubmit, onBack, gtm }) {
  const answer = answers.primaryCaregiver != null ? (answers.primaryCaregiver ? 'Yes' : 'No') : '';

  return (
    <div className={`row activity-question`}>
      <div className='col-sm-2'></div>
      <div className='col-sm-8'>
        <OptionsAndOther
          answers={{ primaryCaregiver: answer }}
          onSubmit={(answer) =>
            onSubmit({ ...answers, primaryCaregiver: answer.primaryCaregiver === 'Yes' ? true : false })
          }
          onBack={onBack}
          title={`Are you a primary caregiver at home?`}
          para={`Caring for others is a big responsibility. We'll match you with someone who can relate to your situation.`}
          keyForAnswer={`primaryCaregiver`}
          choices={['Yes', 'No']}
          yesState={'how_are_you_feeling'}
          gtm={`${gtm} Primary Caregiver`}
          showOther={false}
          selectOne={true}
        />
      </div>
      <div className='col-sm-2'></div>
    </div>
  );
}
