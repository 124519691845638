export const createCsvUpload = (params, successCallback) => {
  fetch(`/api/v1/surgeon_csv_uploads`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};

export const createSurgeonPatientLead = (params, successCallback) => {
  fetch('/api/v1/surgeon_patient_leads', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
