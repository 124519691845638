export const getSurgeonPatientLeads = (params, successCallback) => {
  fetch(`/api/v1/surgeon_patient_leads?${objToQueryString(params)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
