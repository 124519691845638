import React, { useState, useEffect, useContext } from 'react';
import { Switch, useParams, useRouteMatch, Route, useHistory, Redirect } from 'react-router-dom';
import Context from '../../../../utils/context';
import LoadingScreen from '../../../loading_screen';
import DoctorDiagnosis from './doctor_diagnosis';
import { getUser } from '../../../../api/users';
import { updateProfile } from '../../../../api/profiles';
import UterineFibroidsSymptoms from '../symptoms';
import { createSurgeonReferredMentorMatch } from '../../../../api/surgeon_referred_mentor_matches';
import styles from './index.module.css';
import OptionsAndOther from '../../choose_an_option';
import ChooseManyOptionsWithNone from '../../choose_many_options_with_none';

export default function WomensHealthPreConversationQuestionnaire({}) {
  const context = useContext(Context);
  const [user, setUser] = useState(context.auth.getCurrentUser());
  const [currentProfile, setCurrentProfile] = useState(user.current_profile);
  const [previouslyDiagnosed, setPreviouslyDiagnosed] = useState(
    user.current_profile ? user.current_profile.previously_diagnosed : null
  );
  const [treatmentPreferences, setTreatmentPreferences] = useState(
    user.current_profile ? user.current_profile.treatment_preferences : []
  );
  const [symptoms, setSymptoms] = useState(user.current_profile ? user.current_profile.symptoms.split(',') : []);
  const [symptoms1, setSymptoms1] = useState(user.current_profile ? user.current_profile.symptoms.split(',') : []);
  let { path } = useRouteMatch();
  const history = useHistory();
  const [secondSymptomsPage, setSecondSymptomsPage] = useState();

  const update = (params) => {
    updateProfile(user.profile_id, params, (data) => {
      setCurrentProfile(data);
    });
  };

  const createAndRedirectToPre = () => {
    createSurgeonReferredMentorMatch(
      { surgeon_id: null, surgery: 'Radiofrequency Ablation (Fibroids Treatment)' },
      (match) => {
        history.push(`/member/surgeon-mentor-matches/${match.uuid}/pre-survey/uterine-fibroids`);
      }
    );
  };

  return (
    <div className={styles.questionnaire_ottr} style={{ backgroundColor: '#f5f7f9', maxWidth: '100%' }}>
      <div id='questionnaire' className='container h-100'>
        <div className='row justify-content-center h-100'>
          <div className='col h-100'>
            <div className={`row ${styles.introQuestion} h-100`}>
              <div className='col-sm-2'></div>
              <div className='col-sm-8'>
                <Switch>
                  <Route exact path={path}>
                    <Redirect to={`${path}/diagnosis`} />
                  </Route>
                  <Route exact path={`${path}/diagnosis`}>
                    <DoctorDiagnosis
                      answers={{ previouslyDiagnosed: previouslyDiagnosed }}
                      onSubmit={(answers) => {
                        setPreviouslyDiagnosed(answers.previouslyDiagnosed);
                        update({ previously_diagnosed: answers.previouslyDiagnosed });
                        answers.previouslyDiagnosed === true
                          ? history.push(`${path}/treatment`) && setSymptoms([]) && setSymptoms1([])
                          : history.push(`${path}/symptoms`);
                      }}
                      title={`Has a doctor ever diagnosed you with uterine fibroids?`}
                      para={`70% to 80% of women develop fibroids, but many go undiagnosed.₁`}
                      keyForAnswer={`previouslyDiagnosed`}
                      choices={['Yes', 'No']}
                      yesState={'previouslyDiagnosed'}
                      showOther={false}
                      selectOne={true}
                      showBack={false}
                      gtm={`Uterine-Fibroids`}
                    />
                  </Route>
                  <Route exact path={`${path}/symptoms`}>
                    {!secondSymptomsPage && (
                      <ChooseManyOptionsWithNone
                        answers={{ symptoms: symptoms }}
                        keyForAnswer={`symptoms`}
                        onSubmit={(answers) => {
                          setSymptoms(answers.symptoms);
                          update({ profile_symptoms: answers.symptoms.concat(symptoms1) });
                          setSecondSymptomsPage(true);
                        }}
                        onBack={() => history.push(`${path}/diagnosis`)}
                        options={[
                          'My periods are very painful and sometimes feel never-ending.',
                          'I feel like I look pregnant even though I am not.',
                          'I often feel bloated around my lower stomach and pelvis.',
                          'None of the above',
                        ]}
                        title={'Do you relate to any of these statements? (Select all that apply)'}
                        para={
                          'Fibroid symptoms can take many  forms, and are often overlooked or confused with other issues. '
                        }
                        gtm={`Uterine-Fibroids`}
                      />
                    )}
                    {secondSymptomsPage && (
                      <ChooseManyOptionsWithNone
                        answers={{ symptoms1: symptoms1 }}
                        keyForAnswer={`symptoms1`}
                        onSubmit={(answers) => {
                          setSymptoms1(answers.symptoms1);
                          update({ profile_symptoms: answers.symptoms1.concat(symptoms) });
                          if (
                            answers.symptoms1.includes('None of the above') ||
                            answers.symptoms1.includes('None of the above ')
                          ) {
                            history.push(`${path}/treatment-options`);
                          } else {
                            history.push(`${path}/treatment`);
                          }
                        }}
                        onBack={() => setSecondSymptomsPage(false)}
                        options={[
                          'I can’t control my bowel movements.',
                          'Sex has become physically painful for me.',
                          'Sometimes I miss work or social events because of my period.',
                          'Sometimes I experience back and leg pain.',
                          'None of the above ',
                        ]}
                        title={'Do you relate to any of these statements? (Select all that apply)'}
                        para={''}
                        gtm={`Uterine-Fibroids`}
                      />
                    )}
                  </Route>
                  <Route exact path={`${path}/treatment`}>
                    <div className={`row`}>
                      <div className='col-sm-2'></div>
                      <div className='col-sm-8'>
                        <OptionsAndOther
                          title={
                            'What are your "must-haves" when looking for medical treatment? (Select all that apply)'
                          }
                          para={
                            'Finding a treatment that works best for you will depend on your preferences and lifestyle.'
                          }
                          answers={{ treatment: treatmentPreferences }}
                          keyForAnswer={'treatment'}
                          onSubmit={(answers) => {
                            update({ treatment_preferences: answers.treatment });
                            setTreatmentPreferences(answers.treatment);
                            if (symptoms.includes('None of the above') && symptoms1.includes('None of the above ')) {
                              history.push(`${path}/treatment-options`);
                            } else {
                              setTimeout(createAndRedirectToPre, 5000);
                              history.push(`${path}/treatment-options`);
                            }
                          }}
                          otherTxt={'Enter other preferences here...'}
                          onBack={() => {
                            if (previouslyDiagnosed) {
                              history.push(`${path}/diagnosis`);
                            } else {
                              setSecondSymptomsPage(true);
                              history.push(`${path}/symptoms`);
                            }
                          }}
                          choices={[
                            'Minimally invasive',
                            'FDA approved; non-experimental',
                            'Short recovery time',
                            'Covered by insurance',
                          ]}
                        />
                      </div>
                      <div className='col-sm-2'></div>
                    </div>
                  </Route>
                  {symptoms &&
                    symptoms1 &&
                    symptoms.includes('None of the above') &&
                    symptoms1.includes('None of the above ') && (
                      <Route exact path={`${path}/treatment-options`}>
                        <div className={styles.whats_next}>
                          <h5>We recommend talking to a trusted OBGYN about your health concerns.</h5>
                          <h6>Whats next?</h6>
                          <p>Answer a few questions to find a doctor based on your health insurance and location.</p>
                          <button
                            className='marg-top-40'
                            onClick={() => history.push(`/member/join/surgeon-match/uterine-fibroids`)}
                          >
                            Find a doctor for me
                          </button>
                        </div>
                      </Route>
                    )}
                  {previouslyDiagnosed && (
                    <Route exact path={`${path}/treatment-options`}>
                      <div className={styles.whats_next}>
                        <LoadingScreen
                          title='The Acessa® procedure is a safe, uterine-sparing treatment option for fibroids.'
                          content={
                            <>
                              <p className={styles.sm}>
                                *Only a doctor can diagnose fibroids. This quiz is not a substitute for a physician's
                                evaluation or diagnosis.
                              </p>
                              <h6>Whats next?</h6>
                              <p>
                                Answer a few more questions to talk to someone who's received treatment for their
                                fibroids and can help.
                              </p>
                            </>
                          }
                        />
                      </div>
                    </Route>
                  )}
                  {!previouslyDiagnosed && (
                    <Route exact path={`${path}/treatment-options`}>
                      <div className={styles.whats_next}>
                        <LoadingScreen
                          title='Based on your answers, you may have uterine fibroids.'
                          content={
                            <>
                              <p className={styles.sm}>
                                *Only a doctor can diagnose fibroids. This quiz is not a substitute for a physician's
                                evaluation or diagnosis.
                              </p>
                              <h6>Whats next?</h6>
                              <p>
                                Answer a few more questions to talk to someone who's received treatment for their
                                fibroids and can help.
                              </p>
                            </>
                          }
                        />
                      </div>
                    </Route>
                  )}
                </Switch>
              </div>
              <div className='col-sm-2'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
