export default function ScrollTo(elem, offset) {
  if (!offset) {
    offset = 0;
  } else {
    offset = -offset;
  }
  var top = document.querySelector(elem).getBoundingClientRect().top + window.pageYOffset;
  window.scrollTo({
    top: top + offset,
    left: 0,
    behavior: 'smooth',
  });
}
