import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DatePickerWrapper from '../../date_picker_wrapper';
import Todpicker from '../../todpicker';
import ErrorMessage from '../../ui_kit/utilities/error_message';
import { onlyUnique, toSentTime } from '../../../helpers';
import { getConsultationTimes, setConsultationTimes } from '../../../api/surgeons';
import LoadingScreen from '../../loading_screen';
import Context from '../../../utils/context';

export default function VirtualConsultationDateTime({
  onSubmit,
  onBack,
  answers,
  title,
  subtitle = 'Select from available times and dates for your consultation.',
  datePlaceholder = 'Preferred appt. date',
  timePlaceholder = 'Preferred appt. time',
  gtm,
  cta = 'Next',
}) {
  const [apptDate, setApptDate] = useState(answers.surgeonVirtualConsultationApptDate || '');
  const [apptTimeOfDay, setApptTimeOfDay] = useState(
    answers.surgeonVirtualConsultationApptTimeOfDay || timePlaceholder
  );
  const [availableTimes, setAvailableTimes] = useState([]);
  const [error, setError] = useState('');
  const [checkedForConsultations, setCheckedForConsultations] = useState(false);
  const [showRequestMoreTime, setShowRequestMoreTime] = useState(false);
  const context = useContext(Context);
  let history = useHistory();

  useEffect(() => {
    getConsultationTimes(answers, (data) => {
      setAvailableTimes(data);
      setCheckedForConsultations(true);
    });
  }, []);

  let selectableConsultations = [
    ...availableTimes
      .filter((t) => new Date(t.start_time).getTime() > new Date().setDate(new Date().getDate() + 2))
      .map((t) => (t = { ...t, time_slot: `${toSentTime(t.start_time)} - ${toSentTime(t.end_time)}` })),
  ]; // <- filter only shows days greater than 2 days from now and the map adds a new field called time_slot so we can easily associate the selected time with objects in the array

  // \/ only show available days in DatePickerWrapper
  let months = selectableConsultations.map((t) => new Date(t.start_time).getMonth());
  let days = selectableConsultations.map((t) => new Date(t.start_time).getDate());
  const isAvailableDate = (date) => {
    return days.indexOf(date.getDate()) !== -1 && months.indexOf(date.getMonth()) !== -1;
  };
  // /\ only show available days in DatePickerWrapper

  // \/ show the times associated with the day selected in Todpicker
  let filteredTimes = selectableConsultations.filter(
    (t) => new Date(t.start_time).getDate() === new Date(apptDate).getDate()
  );
  let options = [
    'Preferred appt. time',
    ...filteredTimes
      .map((t) => t.time_slot)
      .filter(onlyUnique)
      .sort(),
  ];
  // /\ show the times associated with the day selected in Todpicker

  const onClick = () => {
    let selectedConsult = selectableConsultations.filter(
      (c) => c.time_slot === apptTimeOfDay && new Date(c.start_time).getDate() === new Date(apptDate).getDate()
    )[0];
    let params = { ...answers, surgeon_virtual_consultation_time_id: selectedConsult.id };
    setConsultationTimes(params, (data) =>
      onSubmit({
        ...params,
        surgeonVirtualConsultationTimeId: data.uuid,
        surgeonVirtualConsultationApptDate: apptDate,
        surgeonVirtualConsultationApptTimeOfDay: apptTimeOfDay,
      })
    );
  };

  if (!checkedForConsultations) return <LoadingScreen />;

  if (selectableConsultations.length === 0 && checkedForConsultations === true)
    return (
      <div className='row'>
        <div className='col'>
          <h2 className='marg-bottom-20'>No Virtual Consultations Available</h2>
          <p className='bold marg-bottom-20'>
            We could not locate a doctor in our network based on your location. We'll send you an email when a doctor in
            your area joins the program.
          </p>
          <h6 style={{ color: '#59B6BE' }} className='marg-bottom-10 bold'>
            What's next?
          </h6>
          <p className='marg-bottom-20'>
            In the meantime, check out our platform and join hundreds of other patients to learn about the treatments or
            procedures you're considering from your peers.
          </p>
          <button onClick={() => history.push(`/member/home`)}>Join PatientPartner</button>
        </div>
      </div>
    );

  const requestMoreTimes = () => {
    if (context.auth.isLoggedIn()) {
      context.auth.userUpdate(
        { requested_more_surgeon_virtual_consultation_times: true },
        setShowRequestMoreTime(true),
        setShowRequestMoreTime(true)
      );
    }
  };

  if (showRequestMoreTime) {
    return (
      <div className='row'>
        <div className='col'>
          <h2 className='marg-bottom-20'>We have received your request!</h2>
          <h6 style={{ color: '#59B6BE' }} className='marg-bottom-10 bold'>
            What's next?
          </h6>
          <p className='marg-bottom-20'>
            We're sorry we do not currently have the availability that works for you. We will reach out to you shortly
            to coordinate your free virtual consultation.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='date-time-smallform'>
        <h5 className='text-left'>{title}</h5>
        <hr />
        <p className='marg-top-5 text-left'>{subtitle}</p>
        <DatePickerWrapper
          selected={apptDate}
          onChange={(date) => setApptDate(date)}
          minDate={new Date()}
          filterDate={isAvailableDate}
          wrapperClassName={'react-datepicker-wrapper text-left material-input marg-bottom-30'}
          placeholderText={datePlaceholder}
        />
        <Todpicker
          value={apptTimeOfDay}
          onChange={(event) => setApptTimeOfDay(event.target.value)}
          options={options}
          todpickerClass={false}
          disabled={apptDate === '' ? 'disabled' : ''}
          removeArrow
        />
        <ErrorMessage error={error} />
        <div className=''>
          <button
            style={{ width: '48%', marginRight: '2px' }}
            className=''
            data-gtm-click-label={`${gtm} Date Time Submit`}
            disabled={apptDate !== '' && apptTimeOfDay !== '' ? '' : 'disabled'}
            onClick={onClick}
          >
            {cta}
          </button>
          <button
            style={{ width: '48%', marginLeft: '2px' }}
            className='back'
            type='button'
            onClick={requestMoreTimes}
            data-gtm-click-label={`${gtm} Request More Times`}
          >
            Request more times
          </button>
        </div>
      </div>
    </div>
  );
}
