import React, { useState, useEffect } from 'react';
import PatientPartnerCard from '../patient_partner_card';
import Slider from 'react-slick';
import uuid from 'react-uuid';
import './doc-patient-partners.css';

export default function DocPatientPartners({
  patientPartners,
  headline,
  subheadline,
  para,
  actionContentFunc = (partner) => <></>,
  sliderClassName = 'pp-reviews-carousel',
  startOnSecondSlide = false,
  showSendMessage = true,
}) {
  const [showRestOfPartners, setShowRestOfPartners] = useState(false);

  // Listens to know if the device is a mobile device START
  const [isMobile, setDesktop] = useState(window.innerWidth < 768);

  const updateMedia = () => {
    setDesktop(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });
  // Listens to know if the device is a mobile device END

  const prevArrowId = `prevArrowId_${uuid()}`;
  const nextArrowId = `nextArrowId_${uuid()}`;

  const [nextFadeOut, setNextFadeOut] = useState(false);
  const [prevFadeOut, setPrevFadeOut] = useState(false);

  const prevClick = () => {
    if (patientPartners.length > 1) {
      currentSlider.slickPrev();
    }
  };

  const nextClick = () => {
    if (patientPartners.length > 1) {
      currentSlider.slickNext();
    }
  };

  useEffect(() => {
    if (!isMobile) {
      if (patientPartners.length > 1 && startOnSecondSlide === true) {
        currentSlider.slickNext();
      }
      if (
        document.querySelector('.' + sliderClassName + ' .slick-track > .slick-slide:first-child') &&
        document.querySelector('.' + sliderClassName + ' .slick-track > .slick-slide:first-child').classList[1] ==
          'slick-active'
      ) {
        setPrevFadeOut(true);
      }
    }
  }, []);
  const settings = {
    className: 'pp-reviews-carousel',
    dots: true,
    centerMode: true,
    arrows: false,
    slidesToShow: 1,
    variableWidth: true,
    infinite: false,
    beforeChange: (oldIndex, newIndex) => {
      if (newIndex === patientPartners.length - 1) {
        setNextFadeOut(true);
      } else {
        setNextFadeOut(false);
      }
      if (newIndex === 0) {
        setPrevFadeOut(true);
      } else {
        setPrevFadeOut(false);
      }
    },
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  let currentSlider;

  return (
    <div>
      {(headline || subheadline) && (
        <div className='container-fluid patient-partners marg-top-30'>
          <div className='container'>
            <div className='row'>
              <div className='col'>
                {headline && <h3 className='marg-bottom-5'>{headline}</h3>}
                {subheadline && <p className='marg-bottom-20 bold'>{subheadline}</p>}
                {para && <p>{para}</p>}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='patient-partners-reviews'>
        {!isMobile && patientPartners.length == 1 && (
          <div className='row only-one'>
            <div className='col otr-pp-review-slider'>
              <PatientPartnerCard
                partner={patientPartners[0]}
                actionContent={actionContentFunc(patientPartners[0])}
                showSendMessage={showSendMessage}
              />
            </div>
          </div>
        )}
        {!isMobile && patientPartners.length > 1 && (
          <div className='row'>
            <div className='col otr-pp-review-slider'>
              <Slider ref={(slider) => (currentSlider = slider)} {...settings}>
                {patientPartners.map((partner) => (
                  <div key={partner.name}>
                    <PatientPartnerCard
                      partner={partner}
                      actionContent={actionContentFunc(partner)}
                      showSendMessage={showSendMessage}
                    />
                  </div>
                ))}
              </Slider>
              <div
                data-gtm-click-label='Questionnaire Left Arrow'
                id={prevArrowId}
                onClick={prevClick}
                className={`prev-arrow ${prevFadeOut ? 'no-opacity' : ''}`}
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='11.253' height='19.682' viewBox='0 0 11.253 19.682'>
                  <path
                    id='Icon_ionic-ios-arrow-back'
                    data-name='Icon ionic-ios-arrow-back'
                    d='M14.643,16.032,22.09,8.591A1.407,1.407,0,1,0,20.1,6.6L11.66,15.036a1.4,1.4,0,0,0-.041,1.939l8.473,8.49a1.407,1.407,0,0,0,1.992-1.986Z'
                    transform='translate(-11.251 -6.194)'
                    fill='#74CCD3'
                    data-gtm-click-label='Patient Partner Carousel Left Arrow'
                  />
                </svg>
              </div>
              <div
                data-gtm-click-label='Questionnaire Right Arrow'
                id={nextArrowId}
                onClick={nextClick}
                className={`next-arrow ${nextFadeOut ? 'no-opacity' : ''}`}
              >
                <svg xmlns='http://www.w3.org/2000/svg' width='11.294' height='19.754' viewBox='0 0 11.294 19.754'>
                  <path
                    id='Icon_ionic-ios-arrow-back'
                    data-name='Icon ionic-ios-arrow-back'
                    d='M14.655,16.068,22.13,8.6a1.412,1.412,0,0,0-2-1.994l-8.468,8.462a1.409,1.409,0,0,0-.041,1.947l8.5,8.521a1.412,1.412,0,0,0,2-1.994Z'
                    transform='translate(-11.251 -6.194)'
                    fill='#74CCD3'
                    data-gtm-click-label='Patient Partner Carousel Right Arrow'
                  />
                </svg>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className='row'>
            {patientPartners.reduce((patientPartners, partner, index) => {
              var upperLimit, lowerLimit;
              if (!showRestOfPartners) {
                /* shows 3 partners */
                upperLimit = 2;
                lowerLimit = -1;
              } else if (showRestOfPartners) {
                /* shows all partners */
                upperLimit = 300000;
                lowerLimit = -1;
              }
              if (index <= upperLimit && index > lowerLimit) {
                patientPartners.push(
                  <div className='col-12'>
                    <PatientPartnerCard
                      partner={partner}
                      actionContent={actionContentFunc(partner)}
                      showSendMessage={showSendMessage}
                    />
                  </div>
                );
              }
              return patientPartners;
            }, [])}
            {patientPartners.length > 3 && (
              <>
                {!showRestOfPartners && (
                  <button className='gallery-cta' onClick={() => setShowRestOfPartners(true)}>
                    View all
                  </button>
                )}
                {showRestOfPartners && (
                  <button className='gallery-cta' onClick={() => setShowRestOfPartners(false)}>
                    Show less
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
