var saveAs = require('file-saver');

export const getExport = (successCallback) => {
  fetch(`/api/v1/mentor_connect_exports`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/csv',
    },
  })
    .then((response) => response.blob())
    .then((blob) => saveAs(blob, 'export.csv'));
};
