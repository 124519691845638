import { connect } from 'react-redux';
import { updateAnswersAction } from '../../actions/home';
import ContactUs from '../../components/contact_us';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
