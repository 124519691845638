import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.css';
import CheckboxWLabel from '../../../../ui_kit/utilities/checkbox_w_label';
import RequestButton from '../../../../ui_kit/utilities/request_button';
import SelectSearch from '../../../../ui_kit/inputs/select_search';
import Context from '../../../../../utils/context';
import { successToast } from '../../../../ui_kit/utilities/success_toast';
import ErrorMessage from '../../../../ui_kit/utilities/error_message';
import { addNewCondition } from '../../../../../api/profiles';
import { createActivity } from '../../../../../api/activities';

export default function AddCondition({ recovered = false, setConditions, setAddCondition, conditions }) {
  const [isPrivate, setIsPrivate] = useState(false);
  const [chosenCondition, setChosenCondition] = useState('');
  const [show, setShow] = useState(false);
  const context = useContext(Context);
  let isMobile = context.viewport.getIsMobile();

  let selectableConditions = [
    'Acid Reflux',
    'Anemia',
    'Arthritis',
    'COPD',
    'Diabetes',
    'Heart Disease',
    'High Blood Pressure',
    'High Cholesterol ',
    'PCOS',
    'Pre-diabetic',
    'Sleep Apnea',
  ];

  useEffect(() => {
    setShow(true);
  }, []);

  const addCondition = (afterAction) => {
    addNewCondition(
      {
        id: context.auth.getCurrentUser().profile_id,
        condition: chosenCondition,
        private: isPrivate,
        recovered: recovered,
      },
      (data) => {
        setConditions(data);
        setAddCondition(false);
        successToast('New condition added.');
        createActivity('Activity::EditProfile', { fields: 'add_condition' });
        afterAction();
      }
    );
  };

  return (
    <div className='container'>
      <div style={{ padding: '20px' }} className={`${styles.add_procedure} ${show ? styles.show : null} row`}>
        <div className={`${styles.input} col-12 col-md-6 de-pad`}>
          <p className={styles.label}>condition type</p>
          <SelectSearch
            label='Condition type'
            choices={selectableConditions}
            selected={chosenCondition}
            setSelected={setChosenCondition}
            light={false}
          />
        </div>
        <div className={`col-md-3 col-5 de-pad`} style={isMobile ? { marginTop: '20px' } : { marginLeft: '10px' }}>
          <p className={styles.label}>Visibility</p>
          <CheckboxWLabel
            setCheck={setIsPrivate}
            check={isPrivate}
            label='Private'
            wrapperStyles={{ padding: '10.5px 20px' }}
          />
        </div>
        <RequestButton
          classnames={styles.add_button}
          cta='Add'
          request={(afterAction) => addCondition(afterAction)}
          disabled={chosenCondition === '' || conditions.some((c) => c.condition === chosenCondition)}
        />
        <ErrorMessage
          error={
            conditions.some((c) => c.condition === chosenCondition) ? 'Condition already added to your profile' : ''
          }
        />
      </div>
    </div>
  );
}
