import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './custom_cta_footer.css';

export default function CustomCtaFooter({
  headline = 'pass me in as headline',
  para = 'pass me in as para',
  buttontxt = 'pass me in as buttontxt',
  link = '/',
  mergeWithFooter = 'true',
  targetBlank = false,
  gtm,
  actionContent,
}) {
  return (
    <div className={`custom-cta-footer ${mergeWithFooter == false ? '' : 'marg-bottom-50'}`}>
      <section className='get-started-sec'>
        <div className='container'>
          <div className='get-started-outr wow fadeInDown'>
            <div className='cmn-hdr wh text-center'>
              <div className='container-fluid newsletter-signup-form'>
                <div className='row'>
                  <div className='col-sm-1'></div>
                  <div className='col-sm-10'>
                    <h2 className='text-center darkprim marg-bottom-20 marg-top-20'>{headline}</h2>
                    <p className='text-center darkprim marg-bottom-30'>{para}</p>
                    {!actionContent && (
                      <div>
                        {!targetBlank && (
                          <a href={link}>
                            <button
                              className='text-center marg-bottom-10'
                              data-gtm-click-label={`${gtm} Custom Footer CTA`}
                            >
                              {buttontxt}
                            </button>
                          </a>
                        )}
                        {targetBlank && (
                          <a target='_blank' href={link}>
                            <button
                              className='text-center marg-bottom-10'
                              data-gtm-click-label={`${gtm} Custom Footer CTA`}
                            >
                              {buttontxt}
                            </button>
                          </a>
                        )}
                      </div>
                    )}
                    {actionContent && <div>{actionContent}</div>}
                  </div>
                  <div className='col-sm-1'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {mergeWithFooter == true && <div className='half-bg'></div>}
    </div>
  );
}
