import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTransition, useChain, animated, useSpringRef } from '@react-spring/web';
import Context from '../../utils/context';
import IsScrolling from 'react-is-scrolling';
import { useClickAway } from 'react-use';
import styles from './portal_header.module.css';

function PortalNav({
  navigationItems,
  profileCircleElement = (
    <img
      alt='profile-icon'
      src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/profile_icon_default_s.svg'
    />
  ),
  isScrolling,
}) {
  const context = useContext(Context);
  let user = context.auth.getCurrentUser();
  const isMobile = context.viewport.getIsMobile();
  let history = useHistory();
  let navArr =
    navigationItems ||
    (context.auth.isLoggedIn()
      ? [
          context.auth.getCurrentUser().current_profile_type === 'SurgeonAdministrativeAssistant'
            ? {
                title: 'Patients',
                props: {
                  to: '/provider/patients',
                },
              }
            : {
                link: '/member/conversations',
                title: 'Conversations',
                props: {
                  to: '/member/conversations',
                },
              },
          {
            onclick: 'logout',
            title: 'Log out',
          },
        ]
      : [
          {
            link: '/member/join',
            title: 'Join for free',
            props: {
              to: '/member/join',
            },
          },
          {
            link: '/member/login',
            title: 'Login',
            props: {
              to: '/member/login',
            },
          },
          {
            title: 'Become a mentor',
            smallTitle: 'Help patients throughout their journey.',
            props: {
              to: '/for-mentors',
            },
          },
        ]);

  const [open, setOpen] = useState(false);

  const dropDownAnimation = useSpringRef();
  const dropdown = useTransition(open ? 1 : [], {
    ref: dropDownAnimation,
    config: { mass: 1, tension: 500, friction: 30 },
    from: { opacity: 0, transform: 'translateY(-10px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
  });

  const navItemsAnimation = useSpringRef();
  const navItems = useTransition(open ? navArr : [], {
    ref: navItemsAnimation,
    trail: open ? 100 / navArr.length : 0,
    config: { mass: 1, tension: 1000, friction: 100 },
    from: { opacity: 0, transform: 'translateX(30px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
  });

  useChain(open ? [dropDownAnimation, navItemsAnimation] : [navItemsAnimation, dropDownAnimation], [
    0,
    open ? 0.1 : 0.05,
  ]);

  const dropdownItemOnClick = (dropdownItem) => () => {
    if (dropdownItem.onclick === 'logout') {
      context.auth.userLogOut();
    } else if (dropdownItem.onclick === 'stopImpersonating') {
      context.auth.stopImpersonating(
        () => {
          history.push('/member/home');
        },
        () => {}
      );
    }
  };

  const ref = React.useRef(null);
  useClickAway(ref, () => {
    setOpen(false);
  });

  if (open && isScrolling) {
    setOpen(false);
  }

  const renderDropdownItem = (dropdownItem, style) => {
    const renderDropdownItemInner = () => (
      <animated.div
        style={{ ...style, display: 'flex' }}
        data-gtm-click-label={`Nav ${dropdownItem.title}`}
        className='dd-item'
      >
        <img className={styles.menu_icon} src={dropdownItem.icon} />
        <div className={`dd-item-text ${styles.menu_item}`} data-gtm-click-label={`Nav ${dropdownItem.title}`}>
          <p className={styles.title} style={{ marginBottom: '-3px', color: dropdownItem.color }}>
            {dropdownItem.title}
          </p>
          {dropdownItem.smallTitle && <p className={styles.smtitle}>{dropdownItem.smallTitle}</p>}
        </div>
      </animated.div>
    );

    if (dropdownItem.props && dropdownItem.props.to) {
      return (
        <Link
          onClick={dropdownItemOnClick(dropdownItem)}
          data-gtm-click-label={`Nav ${dropdownItem.title}`}
          className='dropdown-parent-link'
          {...dropdownItem.props}
          data-pendo-tag={dropdownItem.pendoTag ? dropdownItem.pendoTag : ''}
        >
          {renderDropdownItemInner()}
        </Link>
      );
    }

    return (
      <a
        onClick={dropdownItemOnClick(dropdownItem)}
        data-gtm-click-label={`Nav ${dropdownItem.title}`}
        className='dropdown-parent-link'
      >
        {renderDropdownItemInner()}
      </a>
    );
  };

  return (
    <div
      ref={ref}
      className={`${styles.portal_menu} portal-menu`}
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div className={`${styles.menu_tab}`}>
        {context.auth.isLoggedIn() ? (
          <>
            <div className={styles.icon_element}>{profileCircleElement}</div>
            {!isMobile && (
              <>
                <p className={styles.menu_tab_name}>
                  {user && user.current_profile && user.current_profile.display_name}
                </p>
                <img
                  className={styles.menu_down_arrow}
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/down_arrow_drop.svg'
                />
              </>
            )}
          </>
        ) : (
          <div style={{ marginLeft: '10px' }}>{profileCircleElement}</div>
        )}
      </div>
      <div className={styles.portal_menu_wrapper}>
        {dropdown((style) => (
          <animated.div style={{ ...style }} className='dropdown'>
            {navItems((style, dropdownItem) => (
              <>
                {(dropdownItem.title === 'Become a mentor') === true || (dropdownItem.hr_top && <hr />)}
                {dropdownItem.link && dropdownItem.link.includes('mailto') ? (
                  <a
                    href={dropdownItem.link}
                    data-gtm-click-label={`Nav ${dropdownItem.title}`}
                    className='dropdown-parent-link'
                  >
                    <animated.div
                      style={{ ...style }}
                      data-gtm-click-label={`Nav ${dropdownItem.title}`}
                      className='dd-item'
                    >
                      <div className='dd-item-text'>
                        <p className='bold'>{dropdownItem.title}</p>
                      </div>
                    </animated.div>
                  </a>
                ) : (
                  renderDropdownItem(dropdownItem, style)
                )}
              </>
            ))}
          </animated.div>
        ))}
      </div>
    </div>
  );
}

export default IsScrolling(PortalNav);
// wrapper for passing in scroll watcher variable
