export const createReportAnEvent = (params, successCallback) => {
  fetch(`/api/v1/report_an_events`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
