export const createDoctorConnectPatient = (params, successCallback) => {
  fetch(`/api/v1/doctor_connect`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...window.utmInfo,
      ...params,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      successCallback(data);
    });
};
