import React from 'react';
import styles from './index.module.css';

export default function QuestionnaireStopper({
  header = 'Thank you for your interest!',
  para = (
    <p>
      We're sorry, but this program is specifically for patients and caregivers of Myelofibrosis (MF). Please reach out
      to your healthcare provider for further assistance. Or contact us if you feel like you got this in error at{' '}
      <a style={{ fontFamily: 'HK Grotesk', fontSize: '18px', color: '#232323' }} href='mailto:info@patientpartner.com'>
        info@patientpartner.com
      </a>
      .
    </p>
  ),
}) {
  return (
    <div className={styles.thank_you_container}>
      <h3 className={styles.header_three} style={{ fontWeight: '700' }}>
        {header}
      </h3>
      <div className={styles.thank_you_text}>{para}</div>
    </div>
  );
}
