import React from 'react';
import PlatformModal from '../../../ui_kit/modals/platform_modal';

export default function PostConversationModal({ postConversationUrl, setModal }) {
  return (
    <PlatformModal
      title={`How's the conversation going?`}
      setModal={setModal}
      width={8}
      content={
        <div className='container'>
          <div className='row'>
            Your mentor volunteers their time to help others, and your experience with them matters! Please take our
            short, anonymous survey to share your thoughts on your conversation.
          </div>
        </div>
      }
      submitCta={'Start survey'}
      submitGtmClickLabel={`Post conversation survey modal`}
      onSubmit={() => {
        window.open(postConversationUrl);
        setModal(false);
      }}
      onCancel={null}
    />
  );
}
