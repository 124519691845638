import React from 'react';
import styles from './index.module.css';
import VideoType2 from '../../video_sections/video_type_2';

export default function UrlPreview({ metadata, onDelete }) {
  const link = () => {
    let l = metadata.url;
    if (l.includes('youtube.com')) {
      return false;
    }
    return l;
  };

  const renderDelete = () => {
    if (onDelete) {
      return (
        <img
          alt='delete preview'
          onClick={(e) => {
            onDelete();
            e.stopPropagation();
          }}
          className={`${styles.remove_img}`}
          src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg'
        />
      );
    }

    return <></>;
  };

  const goToLink = () => {
    window.open(metadata.url, '_blank');
  };

  const renderInner = () => (
    <>
      <div className={`${styles.row} row h-100`}>
        <div className={`${styles.col} ${styles.left_col} col de-pad`}>
          {metadata.host === 'www.youtube.com' && (
            <div className={`${styles.video_wrap}`}>
              <VideoType2
                margButton={false}
                bg={metadata.image}
                videoLink={metadata.url}
                height='200px'
                borderRadius='0px'
              />
              {renderDelete()}
            </div>
          )}
          {metadata.host !== 'www.youtube.com' && (
            <div className={`${styles.img_wrap}`}>
              <img alt='preview' src={metadata.image} className={`${styles.img}`} />
              {renderDelete()}
            </div>
          )}
        </div>
      </div>
      <div className={`${styles.row} row h-100`}>
        <div className={`${styles.col} col my-auto`}>
          <p className={`${styles.host}`}>{metadata.host}</p>
          <p className={`${styles.desc}`}>
            {metadata.title && `${metadata.title} - `}
            {metadata.description}
          </p>
        </div>
      </div>
    </>
  );

  return (
    <div className={`${styles.meta_wrap} container`}>
      {metadata.host === 'www.youtube.com' ? (
        renderInner()
      ) : (
        <a rel='noreferrer' href={metadata.url} target='_blank'>
          {renderInner()}
        </a>
      )}
    </div>
  );
}
