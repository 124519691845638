import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import NameEmailPhoneZip from './../name_email_phone_zip';
import HeightAndWeight from './../height_and_weight';
import PreexistingConditions from './../preexisting_conditions';
import ConfirmationForm from '../confirmation_form';
import AppointmentConfirmationForm from '../appointment_confirmation_form';
import ModalWrapper from '../../modal';
import ErrorMessage from '../../ui_kit/utilities/error_message/index';
import { contactSyncSurgeonMatch, findPreviousWeightLossSurgeonMatchContact } from '../../../api/contacts';
import { getSurgeonMatches } from './../../../api/surgeons';
import { createActivity } from './../../../api/activities';
import { getWeightLossSurgeonMatchContactFromProfile } from './../../../api/profiles';
import { autofocusAll } from '../../../helpers';
import '../questionnaire.css';
import PaymentMethod from './../payment_method';
import ConversationTopics from './../conversation_topics';
import ThinkingAboutSurgery from './../thinking_about_surgery';
import SuccessPanel from '../../success_panel';
import LoadingScreen from '../../loading_screen';
import DateTime from '../weight_loss_surgeon_match/date_time';
import VirtualConsultationDateTime from '../virtual_consultation/virtual_consultation_date_time';
import SurgeonsColumn from '../../surgeons_column';
import SurgeonOutOfStateModal from '../../surgeon_out_of_state_modal';
import InsuranceCarrier from './../insurance_carrier';
import DateOfBirth from './../date_of_birth';
import WhatProcedure from '../patient_partner/what_procedure';
import WhatSurgery from '../patient_partner/what_surgery';
import WhereBackPain from '../spine_surgery/where_back_pain';
import ExperiencingBackPain from '../spine_surgery/experiencing_back_pain';
import PreviousTreatments from '../spine_surgery/previous_treatments';
import UterineFibroidsSymptoms from '../womens_health/symptoms';
import MustHaves from '../womens_health/must_haves';
import DoctorDiagnosis from '../womens_health/pre_conversation/doctor_diagnosis';
import BedsideManner from '../patient_partner/bedside_manner';
import SeenSurgeonBefore from '../patient_partner/seen_surgeon_before';
import SignUp from '../../sign_up';
import Context from '../../../utils/context';

// const PATIENT_PARTNER_VIEW = {
//   contactInfo: 'contact-info',
//   selectSurgeries: 'select-surgeries',
//   answerQuestions: 'answer-questions',
//   loadingPatientPartners: 'loading-patient-partners',
//   patientPartners: 'patient-partners',
// };
//
// const PATIENT_PARTNER_QUESTIONNAIRE_SLIDE_STATE = {
//   none: 'none',
//   left: 'left',
//   right: 'right',
// };

export default function SurgeonMatchQuestionnaire({
  globalAnswers = {},
  setGlobalAnswers,
  progress,
  setProgress,
  contactId = null,
  setContactIdAction,
  surgeon,
  setSurgeon,
  predefinedSurgeries,
  predefinedProcedures,
  gtm = '',
  newFlow = false,
  standAlone = false,
  questionnaireHead = 'Find the best surgeon',
  signupHead = null,
  signupPara = null,
  removeUnnecessaryQuestions = false,
  skipAppointmentRequest = false,
  dateBirthBeforeInsurance = false,
  skipPassword = false,
  virtualConsultation = false,
  origin = 'Surgeon Match Questionnaire',
  includeBackButton = false,
  capturePhone = false,
}) {
  const currentGtm = `${gtm} Surgeon Match Questionnaire`;
  const [previousContact, setPreviousContact] = useState(null);
  const [showRestartModal, setShowRestartModal] = useState(false);
  const [bariatricQualified, setBariatricQualified] = useState(false);
  const [surgeonOutOfState, setSurgeonOutOfState] = useState(false);
  const [showSurgeonOutOfStateModal, setShowSurgeonOutOfStateModal] = useState(false);
  let history = useHistory();
  const context = useContext(Context);
  const viewport = context.viewport;
  let { path } = useRouteMatch();

  // START figure out what questions to show the user
  let applicableQuestions = [];
  if (globalAnswers.selectedProcedures && globalAnswers.selectedProcedures.includes('Weight Loss')) {
    applicableQuestions.push({
      component: HeightAndWeight,
      para: 'This information will help the surgeon understand your current health conditions.',
    });
    applicableQuestions.push({
      component: PreexistingConditions,
      para: 'This information will help the surgeon understand your current health conditions.',
    });
    applicableQuestions.push({
      component: ThinkingAboutSurgery,
      para: 'This information will help the surgeon understand how they can support you.',
    });
    applicableQuestions.push({ component: BedsideManner });
    applicableQuestions.push({ component: SeenSurgeonBefore });
    applicableQuestions.push({
      component: ConversationTopics,
      headline: 'What else would you like your surgeon to know?',
      para: 'This information will help the surgeon understand how they can support you.',
    });
  } else if (
    globalAnswers.selectedProcedures &&
    (globalAnswers.selectedProcedures.includes('Spine Surgery') ||
      globalAnswers.selectedProcedures.includes('Back/neck treatment'))
  ) {
    if (!removeUnnecessaryQuestions) {
      applicableQuestions.push({ component: WhereBackPain });
      applicableQuestions.push({ component: ExperiencingBackPain });
      applicableQuestions.push({ component: PreviousTreatments });
      applicableQuestions.push({
        component: BedsideManner,
        headline: 'Do you prefer your specialist to have a warm and friendly bedside manner or to the point?',
        para: 'This information will help the specialist understand how they can support you.',
      });
      applicableQuestions.push({
        component: SeenSurgeonBefore,
        headline: 'Have you seen a doctor about this procedure before?',
        para: 'This information will help the specialist understand how they can support you.',
      });
    }
    if (virtualConsultation) {
      applicableQuestions.push({
        component: VirtualConsultationDateTime,
        props: { title: 'Select a time and date', cta: 'Find a consultation' },
      });
      applicableQuestions.push({
        component: ConversationTopics,
        headline: 'What would you like to discuss with the doctor?',
        para: 'Please specify in detail what you would like to discuss during your free virtual consultation with the spine specialist. Feel free to mention your symptoms, previous treatments, and concerns.',
        cta: 'Send Message',
        placeholder: 'Example: I am experiencing a lot of pain and numbness in my back. What are my treatment options?',
      });
    } else {
      applicableQuestions.push({
        component: ConversationTopics,
        headline: 'Share your symptoms, conditions, or questions.',
        para: 'Send a message to a pain specialist so they can help you.',
        cta: 'Send Message',
      });
    }
  } else if (globalAnswers.selectedProcedures && globalAnswers.selectedProcedures.includes("Women's health")) {
    applicableQuestions.push({ component: MustHaves });
    applicableQuestions.push({ component: DoctorDiagnosis });
    applicableQuestions.push({
      component: ConversationTopics,
      headline: 'What else would you like the specialist to know about your condition or symptoms?',
      para: 'This information will help the specialist understand how they can support you.',
    });
  } else {
    if (!removeUnnecessaryQuestions) {
      applicableQuestions.push({ component: BedsideManner });
      applicableQuestions.push({ component: SeenSurgeonBefore });
    }
    if (virtualConsultation) {
      applicableQuestions.push({
        component: VirtualConsultationDateTime,
        props: { title: 'Select a time and date', cta: 'Book my consultation' },
      });
      applicableQuestions.push({
        component: ConversationTopics,
        headline: 'What would you like to discuss with the doctor?',
        para: 'Please specify in detail what procedure you would like to discuss during your free virtual consultation with the plastic surgeon.',
        placeholder: 'Example: I am thinking about liposuction after my second baby. What are my options?',
      });
    } else {
      applicableQuestions.push({
        component: ConversationTopics,
        headline: 'What else would you like your surgeon to know?',
        para: 'This information will help the surgeon understand how they can support you.',
      });
    }
  }

  let CurrentQuestion = null;
  let currentPara = null;
  let currentHeadline = null;
  let currentCta = null;
  let currentPlaceholder = null;
  if (progress.view === 'answer-questions') {
    CurrentQuestion = applicableQuestions[progress.questionNumber].component;
    currentPara = applicableQuestions[progress.questionNumber].para;
    currentHeadline = applicableQuestions[progress.questionNumber].headline;
    currentCta = applicableQuestions[progress.questionNumber].cta;
    currentPlaceholder = applicableQuestions[progress.questionNumber].placeholder;
  }

  let procedures = [];
  for (const [key, value] of Object.entries(gon.procedure_surgery_mappings)) {
    procedures.push({
      procedure: key,
      surgeries: value,
    });
  }

  let availableProcedures = Object.keys(gon.procedure_surgery_mappings);

  var availableSurgeries = [];
  if (globalAnswers.selectedProcedures) {
    procedures.filter((procedureCategory) => {
      globalAnswers.selectedProcedures.filter((procedure) => {
        if (procedure.toLowerCase() === procedureCategory.procedure.toLowerCase()) {
          availableSurgeries = procedureCategory.surgeries;
        }
      });
    });
  }

  const isSpine =
    globalAnswers.selectedProcedures &&
    (globalAnswers.selectedProcedures.includes('Spine Surgery') ||
      globalAnswers.selectedProcedures.includes('Back/neck treatment'));

  const showProcedures = predefinedProcedures === undefined;
  const showSurgeries = predefinedSurgeries === undefined;

  const insuranceOnlyProcedures = ['Spine Surgery', 'Back/neck treatment', "Women's health"];
  const cashOnlyProcedures = ['Cosmetic', 'Plastic surgery'];
  const doNotShowPaymentMethod = (selectedProcedures) => {
    let ret = false;
    if (virtualConsultation) {
      return true;
    }

    insuranceOnlyProcedures.forEach((doNotShowPaymentMethodProcedure) => {
      if (selectedProcedures && selectedProcedures.includes(doNotShowPaymentMethodProcedure)) {
        ret = true;
      }
    });
    cashOnlyProcedures.forEach((doNotShowPaymentMethodProcedure) => {
      if (selectedProcedures && selectedProcedures.includes(doNotShowPaymentMethodProcedure)) {
        ret = true;
      }
    });

    return ret;
  };
  const presetPaymentMethod = (selectedProcedures) => {
    if (virtualConsultation) {
      return 'Cash Pay';
    }

    let ret = '';
    insuranceOnlyProcedures.forEach((doNotShowPaymentMethodProcedure) => {
      if (selectedProcedures && selectedProcedures.includes(doNotShowPaymentMethodProcedure)) {
        ret = 'Health Insurance';
      }
    });
    cashOnlyProcedures.forEach((doNotShowPaymentMethodProcedure) => {
      if (selectedProcedures && selectedProcedures.includes(doNotShowPaymentMethodProcedure)) {
        ret = 'Cash Pay';
      }
    });

    return ret;
  };
  // END figure out what questions to show the user

  const goToNextSlide = (nextProgress, reloadPage) => {
    setProgress(nextProgress);
    autofocusAll();
    scrollToQuestionnaire();
    if (reloadPage) {
      history.push(`${path}`);
    }
  };

  const setGlobalAnswersWrapper = (answers) => {
    let finalAnswers = answers;
    if (predefinedSurgeries && predefinedProcedures) {
      answers.selectedSurgeries = predefinedSurgeries;
      answers.selectedProcedures = predefinedProcedures;
      finalAnswers = {
        ...finalAnswers,
        selectedSurgeries: predefinedSurgeries,
        selectedProcedures: predefinedProcedures,
      };
    }

    if (presetPaymentMethod(finalAnswers.selectedProcedures) !== '') {
      finalAnswers = {
        ...finalAnswers,
        paymentMethod: presetPaymentMethod(finalAnswers.selectedProcedures),
      };
    }

    setGlobalAnswers(finalAnswers);
  };

  const contactSyncWrapper = (answer, finishedQuestionnaire, requestedAppointment, questionnaireProgress, callback) => {
    let finalAnswers = answer;
    if (predefinedSurgeries && predefinedProcedures) {
      finalAnswers = {
        ...finalAnswers,
        selectedSurgeries: predefinedSurgeries,
        selectedProcedures: predefinedProcedures,
      };
    }

    contactSyncSurgeonMatch(
      finalAnswers.name,
      finalAnswers.email,
      finalAnswers.phone,
      finalAnswers.zipcode,
      finalAnswers,
      finishedQuestionnaire,
      requestedAppointment,
      origin,
      surgeon,
      finalAnswers.selectedProcedures && finalAnswers.selectedProcedures[0],
      contactId,
      questionnaireProgress,
      virtualConsultation,
      (data) => {
        setContactIdAction(data.contact.uuid);
        if (callback) {
          callback(data);
        }
      }
    );
  };

  const navigateAfterNameAndEmail = (reloadPage, newAnswer) => {
    let nextView = null;
    if (showProcedures) {
      nextView = {
        view: 'select-procedures',
        questionNumber: null,
      };
    } else if (showSurgeries) {
      nextView = {
        view: 'select-surgeries',
        questionNumber: null,
      };
    } else {
      nextView = {
        view: 'payment-method',
        questionNumber: null,
      };
      if (doNotShowPaymentMethod(newAnswer.selectedProcedures)) {
        newAnswer.paymentMethod = presetPaymentMethod(newAnswer.selectedProcedures);
        if (newAnswer.paymentMethod === 'Health Insurance') {
          nextView = {
            view: dateBirthBeforeInsurance ? 'date-of-birth' : 'insurance-provider',
            questionNumber: null,
          };
        } else {
          nextView = {
            view: 'answer-questions',
            questionNumber: 0,
          };
        }
      }
    }
    goToNextSlide(nextView, reloadPage);
    return nextView;
  };

  const nameAndEmailOnSubmit = (newAnswer, reloadPage) => {
    setGlobalAnswersWrapper(newAnswer);
    findPreviousWeightLossSurgeonMatchContact(newAnswer.email, (previousContact) => {
      if (previousContact && previousContact.surgeon_match_questionnaire_progress) {
        setPreviousContact(previousContact);
        setShowRestartModal(true);
      } else {
        const nextView = navigateAfterNameAndEmail(reloadPage, newAnswer);
        contactSyncWrapper(newAnswer, false, false, nextView, null);
      }
    });
  };

  const loadingSurgeon = (currentContactId) => {
    getSurgeonMatches(currentContactId || contactId, (data) => {
      setBariatricQualified(data.bariatric_qualified);
      setSurgeonOutOfState(data.surgeon_out_of_state);
      if (data.surgeon) {
        setSurgeon(data.surgeon);
        goToNextSlide({
          ...progress,
          view: 'surgeon',
        });
      } else {
        goToNextSlide({
          ...progress,
          view: 'no-surgeon-results',
        });
      }
    });
  };

  const onAnswerQuestionsSubmit = (newAnswer) => {
    // if it is the last step before loading patient partners
    const finishedLastQuestion = progress.questionNumber === applicableQuestions.length - 1;
    const newProgress = {
      view: finishedLastQuestion ? 'loading-surgeon' : 'answer-questions',
      questionNumber: progress.questionNumber + (finishedLastQuestion ? 0 : 1),
    };

    setGlobalAnswersWrapper(newAnswer);
    let requestedAppointment = skipAppointmentRequest && finishedLastQuestion;
    contactSyncWrapper(newAnswer, finishedLastQuestion, requestedAppointment, newProgress, (data) => {
      if (finishedLastQuestion) {
        if (skipAppointmentRequest && data.conversation_id) {
          history.push(`/member/conversations/${data.conversation_id}?view=conversation`);
        } else {
          setTimeout(loadingSurgeon, 1000);
        }
      }
    });
    goToNextSlide(newProgress);
  };

  const onAnswerQuestionsBack = () => {
    setTimeout(() => {
      if (progress.questionNumber === 0) {
        if (doNotShowPaymentMethod(globalAnswers.selectedProcedures)) {
          if (presetPaymentMethod(globalAnswers.selectedProcedures) === 'Health Insurance') {
            setProgress({
              view: dateBirthBeforeInsurance ? 'insurance-provider' : 'date-of-birth',
              questionNumber: null,
            });
          } else {
            if (showSurgeries) {
              setProgress({
                view: 'select-surgeries',
                questionNumber: null,
              });
            } else if (showProcedures) {
              setProgress({
                view: 'select-procedures',
                questionNumber: null,
              });
            } else {
              setProgress({
                view: 'contact-info',
                questionNumber: null,
              });
            }
          }
        } else {
          if (globalAnswers.paymentMethod === 'Health Insurance') {
            setProgress({
              view: dateBirthBeforeInsurance ? 'insurance-provider' : 'date-of-birth',
              questionNumber: null,
            });
          } else {
            setProgress({
              view: 'payment-method',
              questionNumber: null,
            });
          }
        }
      } else {
        setProgress({
          ...progress,
          questionNumber: progress.questionNumber - 1,
        });
      }
    }, 200);
  };

  const scrollToQuestionnaire = (useDelay) => {
    const scrollFunc = () => {
      const element = document.getElementById('questionnaire-scroll-to');
      const y = element.getBoundingClientRect().top + (window.pageYOffset || 0) - 50;
      window.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    };

    if (useDelay) {
      setTimeout(scrollFunc, 1000);
    } else {
      scrollFunc();
    }
  };

  const onContinue = () => {
    setGlobalAnswersWrapper(previousContact.global_answers);
    setContactIdAction(previousContact.uuid);
    goToNextSlide(previousContact.surgeon_match_questionnaire_progress);
    if (previousContact.surgeon_match_questionnaire_progress.view === 'loading-surgeon') {
      scrollToQuestionnaire(true);
      loadingSurgeon(previousContact.uuid);
    }
    setShowRestartModal(false);
  };

  const restart = () => {
    setShowRestartModal(false);
    if (showProcedures) {
      goToNextSlide({
        view: 'select-procedures',
        questionNumber: null,
      });
    } else if (showSurgeries) {
      goToNextSlide({
        view: 'select-surgeries',
        questionNumber: null,
      });
    } else {
      goToNextSlide({
        view: 'answer-questions',
        questionNumber: 0,
      });
    }
  };

  const renderPreConditions = (preConditions) => {
    if (preConditions.length === 1) {
      return (
        <span>
          {' '}
          with <b>{preConditions[0]}</b>
        </span>
      );
    }
    if (preConditions.length > 1) {
      return (
        <span>
          {' '}
          with{' '}
          <b>
            {preConditions.splice(0, preConditions.length - 1)} and {preConditions[preConditions.length - 1]}
          </b>
        </span>
      );
    }

    return <></>;
  };

  const seeAllPatientPartners = () => {
    history.push('/partners');
  };

  useEffect(() => {
    if (progress.view === 'loading-surgeon') {
      loadingSurgeon();
    }
  }, []);

  const backButtonFromResults = () => {
    setTimeout(() => {
      goToNextSlide({
        view: 'answer-questions',
        questionNumber: applicableQuestions.length - 1,
      });
    });
  };

  const searchParams = new URLSearchParams(location.search);
  let returnTo = '';
  if (searchParams.get('return_to')) {
    returnTo = searchParams.get('return_to');
  }
  // Listens to know if the device is a mobile device START
  const isMobile = viewport.getIsMobile();

  useEffect(() => {
    if (context.auth.isLoggedIn()) {
      getWeightLossSurgeonMatchContactFromProfile(context.auth.getCurrentUser().profile_id, (res) => {
        if (!res.error) {
          setGlobalAnswersWrapper(res.contact.global_answers);
          setContactIdAction(res.contact.uuid);
          if (res.contact.surgeon_match_questionnaire_progress.view === 'loading-surgeon') {
            scrollToQuestionnaire(true);
            loadingSurgeon(res.contact.uuid);
            goToNextSlide(res.contact.surgeon_match_questionnaire_progress);
          } else if (['select-procedures'].includes(res.contact.surgeon_match_questionnaire_progress.view)) {
            const nextView = navigateAfterNameAndEmail(false, res.contact.global_answers);
            contactSyncWrapper(res.contact.global_answers, false, false, nextView, null);
          } else if (
            ['confirmation', 'appointment-confirmation'].includes(res.contact.surgeon_match_questionnaire_progress.view)
          ) {
            goToNextSlide({
              view: 'loading-surgeon',
              questionNumber: null,
            });
            scrollToQuestionnaire(true);
            loadingSurgeon(res.contact.uuid);
          } else if (['success'].includes(res.contact.surgeon_match_questionnaire_progress.view)) {
            goToNextSlide({
              view: 'select-procedures',
              questionNumber: null,
            });
            scrollToQuestionnaire(true);
          } else {
            goToNextSlide(res.contact.surgeon_match_questionnaire_progress);
          }
        }
      });
    }
  }, []);

  let defaultBackFunc = null;
  if (includeBackButton) {
    defaultBackFunc = () => {
      history.goBack();
    };
  }

  const insuranceCarrierBackFunc = () => {
    let nextView = {
      ...progress,
      view: 'payment-method',
    };
    if (doNotShowPaymentMethod(globalAnswers.selectedProcedures)) {
      if (dateBirthBeforeInsurance) {
        nextView = {
          ...progress,
          view: 'date-of-birth',
        };
      } else if (showSurgeries) {
        nextView = {
          ...progress,
          view: 'select-surgeries',
        };
      } else if (showProcedures) {
        nextView = {
          ...progress,
          view: 'select-procedures',
        };
      } else {
        if (context.auth.isLoggedIn()) {
          return defaultBackFunc;
        }
        nextView = {
          ...progress,
          view: 'contact-info',
        };
      }
    }
    return () => goToNextSlide(nextView);
  };

  const dateOfBirthBackFunc = () => {
    let nextView = {
      ...progress,
      view: 'insurance-provider',
    };
    if (dateBirthBeforeInsurance) {
      if (doNotShowPaymentMethod(globalAnswers.selectedProcedures)) {
        if (showSurgeries) {
          nextView = {
            ...progress,
            view: 'select-surgeries',
          };
        } else if (showProcedures) {
          nextView = {
            ...progress,
            view: 'select-procedures',
          };
        } else {
          if (context.auth.isLoggedIn()) {
            return defaultBackFunc;
          }
          nextView = {
            ...progress,
            view: 'contact-info',
          };
        }
      }
    }

    return () => goToNextSlide(nextView);
  };

  const paymentMethodBackFunc = () => {
    if (showSurgeries) {
      return () => {
        goToNextSlide({
          ...progress,
          view: 'select-surgeries',
        });
      };
    } else if (showProcedures) {
      return () => {
        goToNextSlide({
          ...progress,
          view: 'select-procedures',
        });
      };
    } else if (context.auth.isLoggedIn()) {
      return defaultBackFunc;
    } else {
      return () => {
        goToNextSlide({
          ...progress,
          view: 'contact-info',
        });
      };
    }
  };

  return (
    <div
      id={`questionnaire-scroll-to`}
      className={`patient-partner-questionnaire container text-left ${progress.view === 'loading-surgeon' ? 'fixedHeight' : ''} h-100 de-pad`}
    >
      <div
        style={{
          alignContent: 'center',
          minHeight: standAlone ? '87vh' : '20vh',
          padding: standAlone ? '0px' : '75px 0px',
        }}
        className='row h-100 questionnaire-content h-100'
      >
        {progress.view !== 'surgeon' && (
          <>
            <div className='col-md-2'></div>
            <div className={`col-md-8 fadeOp`}>
              {progress.view === 'contact-info' &&
                (!newFlow ? (
                  <NameEmailPhoneZip
                    answers={globalAnswers}
                    onSubmit={nameAndEmailOnSubmit}
                    includeBackButton={includeBackButton}
                    gtm={currentGtm}
                    head={questionnaireHead}
                    para={
                      isSpine
                        ? 'Take a short questionnaire and we will help you find the best specialist in your area based on your location, insurance, and health goals.'
                        : 'Take a short questionnaire and we will help you find the best surgeon in your area based on your location, insurance, and health goals.'
                    }
                  />
                ) : (
                  <SignUp
                    headline={
                      signupHead === null
                        ? isSpine
                          ? 'Connect to the best specialist'
                          : 'Join PatientPartner today'
                        : signupHead
                    }
                    para={
                      signupPara === null
                        ? isSpine
                          ? 'Chat directly with a specialist in your area and start learning about your pain management options'
                          : 'Create your account and get matched with a surgeon in your area who accepts your insurance provider.'
                        : signupPara
                    }
                    cta={isSpine ? 'Start' : 'Create account'}
                    defaultEmail={globalAnswers.email}
                    onSuccess={(user) => {
                      nameAndEmailOnSubmit(
                        {
                          name: user.name,
                          email: user.email,
                          phone: user.phone,
                          zipcode: user.current_profile.zipcode,
                          latitude: user.current_profile.latitude,
                          longitude: user.current_profile.longitude,
                        },
                        !skipAppointmentRequest
                      );
                      if (returnTo !== '') {
                        history.push(returnTo);
                      }
                    }}
                    captureLocation
                    capturePhone={skipAppointmentRequest || virtualConsultation || capturePhone}
                    gtm={currentGtm}
                    withoutContainer
                    captureFirstLastName
                    params={{ keap_force_sync: true, origin: origin, show_surgeon_modal: skipAppointmentRequest }}
                    embedded={true}
                    light={false}
                    alignLeft
                    skipPassword={skipPassword}
                  />
                ))}
              {progress.view === 'select-procedures' && (
                <WhatProcedure
                  head={'What kind of procedure are you considering?'}
                  para={
                    isSpine
                      ? 'We will recommend a doctor that specializes in the specific treatment you are considering.'
                      : 'We will recommend a surgeon that specializes in the specific procedure you are considering.'
                  }
                  selected={globalAnswers.selectedProcedures}
                  choices={[...availableProcedures]}
                  label={'Type procedure here...'}
                  gtm={currentGtm}
                  onSubmit={(values) => {
                    const newAnswers = {
                      ...globalAnswers,
                      selectedProcedures: values,
                    };
                    // remove surgery if the procedure has changed
                    if (
                      values[0] !==
                      (globalAnswers && globalAnswers.selectedProcedures && globalAnswers.selectedProcedures[0])
                    ) {
                      newAnswers.selectedSurgeries = [];
                    }
                    setGlobalAnswersWrapper(newAnswers);
                    if (
                      arrayIntersect(
                        values.map((v) => v.toLowerCase()),
                        availableProcedures.map((v) => v.toLowerCase())
                      ).length > 0
                    ) {
                      contactSyncWrapper(
                        newAnswers,
                        false,
                        false,
                        { view: 'select-surgeries', questionNumber: null },
                        null
                      );
                      goToNextSlide({
                        view: 'select-surgeries',
                        questionNumber: null,
                      });
                    } else {
                      contactSyncWrapper(
                        newAnswers,
                        false,
                        false,
                        { view: 'no-procedure-results', questionNumber: null },
                        null
                      );
                      goToNextSlide({
                        view: 'no-procedure-results',
                        questionNumber: null,
                      });
                    }
                  }}
                  onBack={
                    context.auth.isLoggedIn()
                      ? null
                      : () => {
                          goToNextSlide({
                            ...progress,
                            view: 'contact-info',
                          });
                        }
                  }
                />
              )}
              {progress.view === 'select-surgeries' && (
                <WhatSurgery
                  head={isSpine ? 'What treatment are you considering?' : 'What surgery are you considering'}
                  selected={globalAnswers.selectedSurgeries}
                  choices={[...availableSurgeries]}
                  noMatchTxt={"I'm not sure"}
                  label={
                    globalAnswers.selectedProcedures &&
                    (globalAnswers.selectedProcedures.includes('Spine Surgery') ||
                      globalAnswers.selectedProcedures.includes('Back/neck treatment'))
                      ? 'Type treatment here...'
                      : 'Type surgery here...'
                  }
                  gtm={currentGtm}
                  para={
                    globalAnswers.selectedProcedures &&
                    (globalAnswers.selectedProcedures.includes('Spine Surgery') ||
                      globalAnswers.selectedProcedures.includes('Back/neck treatment'))
                      ? 'We will recommend a doctor that specializes in the specific treatment you are considering.'
                      : 'We will recommend a surgeon that specializes in the specific surgery you are considering.'
                  }
                  onSubmit={(values) => {
                    const newAnswers = {
                      ...globalAnswers,
                      selectedSurgeries: values,
                    };
                    setGlobalAnswersWrapper(newAnswers);
                    if (
                      arrayIntersect(
                        values.map((v) => v.toLowerCase()),
                        availableSurgeries.map((v) => v.toLowerCase())
                      ).length > 0 ||
                      values[0] === "I'm not sure"
                    ) {
                      let nextView = {
                        ...progress,
                        view: 'payment-method',
                      };
                      if (doNotShowPaymentMethod(newAnswers.selectedProcedures)) {
                        newAnswers.paymentMethod = presetPaymentMethod(newAnswers.selectedProcedures);
                        if (newAnswers.paymentMethod === 'Health Insurance') {
                          nextView = {
                            view: dateBirthBeforeInsurance ? 'date-of-birth' : 'insurance-provider',
                            questionNumber: null,
                          };
                        } else {
                          nextView = {
                            view: 'answer-questions',
                            questionNumber: 0,
                          };
                        }
                      }

                      contactSyncWrapper(newAnswers, false, false, nextView, null);
                      goToNextSlide(nextView);
                    } else {
                      contactSyncWrapper(
                        newAnswers,
                        false,
                        false,
                        { view: 'no-surgery-results', questionNumber: null },
                        null
                      );
                      goToNextSlide({
                        view: 'no-surgery-results',
                        questionNumber: null,
                      });
                    }
                  }}
                  onBack={() => {
                    if (showProcedures) {
                      goToNextSlide({
                        ...progress,
                        view: 'select-procedures',
                      });
                    } else {
                      goToNextSlide({
                        ...progress,
                        view: 'contact-info',
                      });
                    }
                  }}
                />
              )}
              {progress.view === 'payment-method' && (
                <PaymentMethod
                  gtm={currentGtm}
                  answers={globalAnswers}
                  onSubmit={(newAnswers) => {
                    setGlobalAnswersWrapper({ ...globalAnswers, paymentMethod: newAnswers });
                    if (newAnswers === 'Health Insurance') {
                      contactSyncWrapper(
                        { ...globalAnswers, paymentMethod: newAnswers },
                        false,
                        false,
                        { view: 'insurance-provider', questionNumber: null },
                        null
                      );
                      goToNextSlide({
                        view: dateBirthBeforeInsurance ? 'date-of-birth' : 'insurance-provider',
                        questionNumber: null,
                      });
                    } else {
                      contactSyncWrapper(
                        { ...globalAnswers, paymentMethod: newAnswers },
                        false,
                        false,
                        { view: 'answer-questions', questionNumber: 0 },
                        null
                      );
                      goToNextSlide({
                        view: 'answer-questions',
                        questionNumber: 0,
                      });
                    }
                  }}
                  onBack={paymentMethodBackFunc()}
                />
              )}
              {progress.view === 'insurance-provider' && (
                <InsuranceCarrier
                  description={
                    isSpine
                      ? 'We will recommend a doctor that accepts your insurance provider.'
                      : 'We will recommend a surgeon that accepts your insurance provider.'
                  }
                  gtm={currentGtm}
                  answers={globalAnswers}
                  onSubmit={(newAnswers) => {
                    setGlobalAnswersWrapper(newAnswers);
                    const newProgress = {
                      view: dateBirthBeforeInsurance ? 'answer-questions' : 'date-of-birth',
                      questionNumber: 0,
                    };
                    contactSyncWrapper(newAnswers, false, false, newProgress, null);
                    goToNextSlide(newProgress);
                  }}
                  onBack={insuranceCarrierBackFunc()}
                />
              )}
              {progress.view === 'date-of-birth' && (
                <DateOfBirth
                  gtm={currentGtm}
                  answers={globalAnswers}
                  onSubmit={(newAnswers) => {
                    setGlobalAnswersWrapper(newAnswers);
                    const newProgress = {
                      view: dateBirthBeforeInsurance ? 'insurance-provider' : 'answer-questions',
                      questionNumber: 0,
                    };
                    contactSyncWrapper(newAnswers, false, false, newProgress, null);
                    goToNextSlide(newProgress);
                  }}
                  onBack={dateOfBirthBackFunc()}
                  description={
                    isSpine
                      ? 'This information will help the specialist understand how they can support you.'
                      : 'This information will help the surgeon understand how they can support you.'
                  }
                />
              )}
              {progress.view === 'answer-questions' && (
                <CurrentQuestion
                  answers={globalAnswers}
                  onSubmit={onAnswerQuestionsSubmit}
                  onBack={onAnswerQuestionsBack}
                  headline={currentHeadline}
                  para={currentPara}
                  gtm={currentGtm}
                  cta={currentCta}
                  placeholder={currentPlaceholder}
                  {...applicableQuestions[progress.questionNumber].props}
                />
              )}
              {progress.view === 'loading-surgeon' && (
                <LoadingScreen
                  title='Locating the best doctor in your area'
                  content={
                    globalAnswers.paymentMethod !== 'Cash Pay' && isSpine
                      ? 'Finding the best specialist in your area that accepts your insurance coverage.'
                      : globalAnswers.paymentMethod !== 'Cash Pay' && !isSpine
                        ? 'Finding the best surgeon in your area that accepts your insurance coverage.'
                        : ''
                  }
                />
              )}
              {progress.view === 'confirmation' && (
                <ConfirmationForm
                  surgeons={surgeon}
                  answers={globalAnswers}
                  onSubmit={(newAnswers) => {
                    if (surgeonOutOfState) {
                      setShowSurgeonOutOfStateModal(true);
                    } else {
                      contactSyncWrapper(newAnswers, true, true, { ...progress, view: 'success' }, null);
                      goToNextSlide({
                        ...progress,
                        view: 'success',
                      });
                    }
                  }}
                  onBack={() => {
                    goToNextSlide({
                      ...progress,
                      view: 'surgeon',
                    });
                  }}
                  datePlaceholder={'Preferred apt. date'}
                  timePlaceholder={'Preferred apt. time'}
                  gtm={currentGtm}
                />
              )}
              {progress.view === 'signup' && (
                <div className='embedded-signup-wrapper'>
                  <SignUp
                    defaultName={globalAnswers.name}
                    defaultEmail={globalAnswers.email}
                    defaultPhone={globalAnswers.phone}
                    onSuccess={(user) => {
                      goToNextSlide({
                        ...progress,
                        view: 'appointment-confirmation',
                      });
                    }}
                    gtm={currentGtm}
                    showAcceptTerms={false}
                    params={{ origin: 'Surgeon Match Questionnaire', weight_loss_surgeon_match_contact_id: contactId }}
                    headline={'Create your account'}
                    para='Create your account and get matched with a surgeon in your area who accepts your insurance provider.'
                    alignLeft={true}
                    cta='Review and book'
                    embedded={true}
                    light={false}
                  />
                </div>
              )}
              {progress.view === 'appointment-confirmation' && (
                <AppointmentConfirmationForm
                  surgeons={surgeon}
                  answers={globalAnswers}
                  onSubmit={(newAnswers) => {
                    createActivity('Activity::ReferralRequest', { origin: origin, surgeon_id: surgeon.id });
                    contactSyncWrapper(newAnswers, true, true, { ...progress, view: 'success' }, (data) => {
                      history.push(`/member/conversations/${data.conversation_id}?view=conversation`);
                    });
                  }}
                  datePlaceholder={'Preferred apt. date'}
                  timePlaceholder={'Preferred apt. time'}
                  gtm={currentGtm}
                  light={false}
                  wrapperClassName={'surgeon-request-appt-sa pad-30 text-left'}
                  head={`Review and book with ${surgeon.name}`}
                  para={`Review your appointment request information and submit!`}
                  cta={'Submit booking request'}
                  capturePhone={newFlow}
                />
              )}
              {progress.view === 'success' && (
                <div className='container'>
                  <div className='row'>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-8'>
                      <div className='container '>
                        <div className='row'>
                          <div className='col'>
                            <SuccessPanel
                              title='Thank you!'
                              content={`${surgeon.name}'s office will call you in 24-48 hours to confirm your insurance plan coverage and appointment. In the meantime, you can learn more about ${surgeon.name} and about speaking to one of their past patients by follow the link below.`}
                              cta={
                                context.auth.isLoggedIn() ? (
                                  <Link to={`/member/home`}>
                                    <button
                                      data-gtm-click-label={`${currentGtm} Explore the community`}
                                      style={{ width: '100%' }}
                                    >
                                      Explore the community
                                    </button>
                                  </Link>
                                ) : (
                                  <Link to={`/surgeon/${surgeon.slug}`}>
                                    <button
                                      data-gtm-click-label={`${currentGtm} See more about Surgeon`}
                                      style={{ width: '100%' }}
                                    >
                                      See more about {surgeon.name}
                                    </button>
                                  </Link>
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {progress.view === 'no-procedure-results' && (
                <div className='container text-left'>
                  <div className='row'>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-8'>
                      <div className='container '>
                        <div className='row'>
                          <div className='col'>
                            <p
                              className='text-link all-surgeons-button text-left'
                              onClick={() => setProgress({ ...progress, view: 'select-procedures' })}
                            >
                              <a data-gtm-click-label={`${currentGtm} No Procedure Results Back`}>
                                Back to questionnaire
                              </a>
                            </p>
                            <h2 className='marg-bottom-20'>We're adding doctors to our program every day!</h2>
                            <p className='bold marg-bottom-20'>
                              {globalAnswers.name}, we were unable to locate a doctor without our network based on your
                              procedure, insurance provider, and location.
                            </p>
                            <h6 style={{ color: '#59B6BE' }} className='marg-bottom-10 bold'>
                              What's next?
                            </h6>
                            <p className='marg-bottom-20'>
                              We will send you an email when a doctor for {globalAnswers.selectedProcedures[0]} joins
                              the program.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {progress.view === 'no-surgery-results' && (
                <div className='container text-left'>
                  <div className='row'>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-8'>
                      <div className='container '>
                        <div className='row'>
                          <div className='col'>
                            <p
                              className='text-link all-surgeons-button text-left'
                              onClick={() => setProgress({ ...progress, view: 'select-surgeries' })}
                            >
                              <a data-gtm-click-label={`${currentGtm} No Surgery Results Back`}>
                                Back to questionnaire
                              </a>
                            </p>
                            <h2 className='marg-bottom-20'>We're adding doctors to our program every day!</h2>
                            <p className='bold marg-bottom-20'>
                              {globalAnswers.name}, we were unable to locate a doctor without our network based on your
                              procedure, insurance provider, and location.
                            </p>
                            <h6 style={{ color: '#59B6BE' }} className='marg-bottom-10 bold'>
                              What's next?
                            </h6>
                            <p className='marg-bottom-20'>
                              We will send you an email when a doctor for{' '}
                              {globalAnswers.selectedSurgeries[0].toLowerCase() === "i'm not sure"
                                ? globalAnswers.selectedProcedures[0]
                                : globalAnswers.selectedSurgeries[0]}{' '}
                              joins the program.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {progress.view === 'no-surgeon-results' && (
                <div className='container text-left'>
                  <div className='row'>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-8'>
                      <div className='container '>
                        <div className='row'>
                          <div className='col'>
                            <p
                              className='text-link all-surgeons-button text-left'
                              onClick={() =>
                                setProgress({
                                  view: 'answer-questions',
                                  questionNumber: applicableQuestions.length - 1,
                                })
                              }
                            >
                              <a data-gtm-click-label={`${currentGtm} No Surgeon Results Back`}>
                                Back to questionnaire
                              </a>
                            </p>
                            <h2 className='marg-bottom-20'>We're adding doctors to our program every day!</h2>
                            <p className='bold marg-bottom-20'>
                              {globalAnswers.name}, we were unable to locate a doctor without our network based on your
                              procedure, insurance provider, and location.
                            </p>
                            <h6 style={{ color: '#59B6BE' }} className='marg-bottom-10 bold'>
                              What's next?
                            </h6>
                            <p className='marg-bottom-20'>
                              We will send you an email when a doctor for{' '}
                              {globalAnswers.selectedSurgeries[0].toLowerCase() === "i'm not sure"
                                ? globalAnswers.selectedProcedures[0]
                                : globalAnswers.selectedSurgeries[0]}{' '}
                              joins the program.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-2'></div>
          </>
        )}
        {progress.view === 'surgeon' && (
          <div className='container text-left'>
            {!virtualConsultation && (
              <p className='text-link all-surgeons-button text-left' onClick={() => backButtonFromResults()}>
                <a data-gtm-click-label={`${currentGtm} Results Back to Questionnaire`}>Back to questionnaire</a>
              </p>
            )}
            <div className='row'>
              <div className='col'>
                {virtualConsultation && (
                  <h3 className='text-left'>
                    Congrats - your request for a free virtual consultation has been successful!
                  </h3>
                )}
                {!virtualConsultation && (
                  <h3 className='text-left'>
                    You have been matched to
                    <br />
                    {surgeon.name}
                  </h3>
                )}
                <hr />
                <>
                  {!virtualConsultation && (
                    <p className='marg-bottom-20 text-left'>
                      {globalAnswers.name}, based on your location, health conditions, and payment preference,{' '}
                      {surgeon.name} is your recommended surgeon.
                    </p>
                  )}

                  <h6 className='bold prim-blue marg-bottom-5 text-left'>What's next?</h6>
                  {virtualConsultation && (
                    <p className='marg-bottom-30 text-left'>
                      <b>
                        Please check your email or text messages to confirm your virtual consultation. You must confirm
                        within an hour, or your link will expire.
                      </b>
                      <br />
                      You will be meeting with {surgeon.name} during your appointment.
                    </p>
                  )}
                  {!virtualConsultation && (
                    <p className='marg-bottom-30 text-left'>
                      Request an appointment through your free PatientPartner account.
                    </p>
                  )}
                </>
                {isMobile && !virtualConsultation && (
                  <div className='marg-bottom-20'>
                    <DateTime
                      onSubmit={(newAnswers) => {
                        const nextView = surgeon.surgeon_administrative_assistant_id
                          ? context.auth.isLoggedIn()
                            ? 'appointment-confirmation'
                            : 'signup'
                          : 'confirmation';
                        setGlobalAnswersWrapper(newAnswers);
                        contactSyncWrapper(newAnswers, true, false, { ...progress, view: nextView }, null);
                        goToNextSlide({
                          ...progress,
                          view: nextView,
                        });
                      }}
                      answers={globalAnswers}
                      onBackClick={backButtonFromResults}
                      title={`Request an appointment with ${surgeon.name}`}
                      gtm={currentGtm}
                      subtitle='Select a day and time, then create your account.'
                      capturePhone={newFlow}
                    />
                  </div>
                )}
                {!isMobile && (
                  <SurgeonsColumn
                    surgeon={surgeon}
                    showRequestAnAppointment={false}
                    seeMoreLink={`/surgeon/${surgeon.slug}?back-to-questionnaire=true`}
                    onQuestionnaire={true}
                    gtm={currentGtm}
                  />
                )}
              </div>
              {!virtualConsultation && (
                <div className='col-lg-5'>
                  <div className='surgeon-results-right-side'>
                    {!isMobile && (
                      <DateTime
                        onSubmit={(newAnswers) => {
                          const nextView = surgeon.surgeon_administrative_assistant_id
                            ? context.auth.isLoggedIn()
                              ? 'appointment-confirmation'
                              : 'signup'
                            : 'confirmation';
                          setGlobalAnswersWrapper(newAnswers);
                          contactSyncWrapper(newAnswers, true, false, { ...progress, view: nextView }, null);
                          goToNextSlide({
                            ...progress,
                            view: nextView,
                          });
                        }}
                        answers={globalAnswers}
                        onBackClick={backButtonFromResults}
                        title={`Request an appointment with ${surgeon.name}`}
                        gtm={currentGtm}
                        subtitle='Select a day and time, then create your account.'
                        capturePhone={newFlow}
                      />
                    )}
                    {isMobile && (
                      <SurgeonsColumn
                        surgeon={surgeon}
                        showRequestAnAppointment={false}
                        seeMoreLink={`/surgeon/${surgeon.slug}?back-to-questionnaire=true`}
                        onQuestionnaire={true}
                        gtm={currentGtm}
                      />
                    )}
                    {surgeon.patient_partners.length > 0 && (
                      <>
                        <h5 className='text-left marg-top-20 marg-bottom-15'>
                          Talk to {surgeon.name}'s PatientPartners™
                        </h5>
                        <p className='text-left marg-bottom-20'>
                          Learn about former patient experiences. They want to answer any questions you might have for
                          free!
                        </p>
                        <Link
                          style={{ float: 'left' }}
                          to={`/surgeon/${surgeon.slug}?back-to-questionnaire=true&to-patientpartners=true`}
                        >
                          <button
                            data-gtm-click-label={`${currentGtm} surgeon results see patient partners`}
                            className='liner'
                          >
                            See past patients
                          </button>
                        </Link>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {showRestartModal && (
        <ModalWrapper
          className='patient-partner-match-modal already-took-this'
          content={
            <div>
              <h3 className='marg-bottom-20'>It looks like you've already taken this questionnaire!</h3>
              <ErrorMessage error={'Restarting this questionnaire might change your recommended patient partners'} />
              <div className='marg-top-20 row max-wid'>
                <div className='col-sm-6'>
                  <button
                    data-gtm-click-label={`${currentGtm} Prefill Questionnaire`}
                    onClick={onContinue}
                    className={`submit`}
                    type='submit'
                  >
                    Continue where I left off
                  </button>
                </div>
                <div className='col-sm-6'>
                  <button
                    data-gtm-click-label={`${currentGtm} Retake Questionnaire`}
                    onClick={restart}
                    className={`submit destructive`}
                    type='submit'
                  >
                    Retake questionnaire
                  </button>
                </div>
              </div>
            </div>
          }
          onClose={() => {
            setShowRestartModal(false);
          }}
        />
      )}
      {showSurgeonOutOfStateModal && (
        <SurgeonOutOfStateModal
          gtmLocationLabel={`${gtm} Surgeon Match Questionnaire`}
          onSubmit={() => {
            setShowSurgeonOutOfStateModal(false);
            contactSyncWrapper(globalAnswers, true, true, { ...progress, view: 'success' }, () => {
              goToNextSlide({
                ...progress,
                view: 'success',
              });
            });
          }}
          onClose={() => {
            setShowSurgeonOutOfStateModal(false);
          }}
        />
      )}
    </div>
  );
}
