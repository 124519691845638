import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ScrollTo from '../../../scroll_to';
import BedsideManner from '../../../bedside_manner';
import IconButtonSVG from '../../../ui_kit/utilities/icon_button_svg';
import VideoType2 from '../../../video_sections/video_type_2';
import SendMessageButton from '../../../ui_kit/utilities/send_message_button';
import styles from './index.module.css';

export default function ProfilePanel({ surgeon }) {
  let history = useHistory();

  const scrollToBio = () => {
    history.push(`/surgeon/${surgeon.slug}/biography`);
    const timer = setTimeout(() => {
      ScrollTo('#surgeon-individual-bio-sec', 100);
    }, 0);
    return () => clearTimeout(timer);
  };

  return (
    <>
      <div className={`${styles.prof_container} content_container`}>
        <div className='container de-pad'>
          <div className='row'>
            <div className='col'>
              <div
                className='chat-room-pp-avatar-lg'
                style={{
                  backgroundImage: `url(${surgeon.profile_photo})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
              <p className={styles.name}>
                {surgeon.name}
                <span className={styles.tag}>Doctor</span>
              </p>
              <div style={{ width: 'max-content', margin: '0px auto', marginBottom: '10px' }}>
                {surgeon.specialties &&
                  surgeon.specialties !== null &&
                  surgeon.specialties !== undefined &&
                  surgeon.specialties.map((s) => (
                    <p style={{ fontSize: '16px', lineHeight: '24px', color: '#828282' }}>{s}</p>
                  ))}
              </div>
              <div style={{ display: 'flex', width: 'max-content', margin: '0px auto', maxWidth: '100%' }}>
                <img
                  style={{ marginRight: '10px', maxWidth: '10%' }}
                  alt='location'
                  src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/waypoint.svg'
                />
                <p style={{ fontSize: '16px', lineHeight: '24px', color: '#828282', maxWidth: '90%' }}>
                  {surgeon.location}
                </p>
              </div>
              <BedsideManner bedsideManner={surgeon.bedside_manner} slide={false} wrapperStyles={{ padding: '0px' }} />
              <div style={{ display: 'flex', marginTop: '15px' }}>
                {surgeon.patient_partners && surgeon.patient_partners.length > 0 && (
                  <div className={styles.info_col}>
                    <span className={styles.num_span}>{surgeon.patient_partners.length}</span>
                    <p className={styles.info_p}>mentor{surgeon.patient_partners.length > 1 && 's'}</p>
                  </div>
                )}
                {surgeon.extra && surgeon.extra.years_of_experience && (
                  <div className={styles.info_col}>
                    <span className={styles.num_span}>{surgeon.extra.years_of_experience} years</span>
                    <p className={styles.info_p}>of experience</p>
                  </div>
                )}
              </div>
              <IconButtonSVG
                icon={
                  <svg
                    className={styles.btn_icon}
                    width='19'
                    height='21'
                    viewBox='0 0 19 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M17.1 1.90909H16.15V0H14.25V1.90909H4.75V0H2.85V1.90909H1.9C0.855 1.90909 0 2.76818 0 3.81818V19.0909C0 20.1409 0.855 21 1.9 21H17.1C18.145 21 19 20.1409 19 19.0909V3.81818C19 2.76818 18.145 1.90909 17.1 1.90909ZM17.1 19.0909H1.9V6.68182H17.1V19.0909Z'
                      fill='white'
                    />
                  </svg>
                }
                text='Request appointment'
                onClick={() => history.push(`/surgeon/${surgeon.slug}/request-appointment`)}
                styles={{ width: '100%', padding: '16px 10px' }}
                textStyles={{ fontSize: '16px' }}
                classNames={styles.icon_btn_request}
              />
              {surgeon && surgeon.surgeon_administrative_assistant_id != null && (
                <SendMessageButton
                  otherProfile={{
                    type: 'SurgeonAdministrativeAssistant',
                    id: surgeon.surgeon_administrative_assistant_id,
                  }}
                  style={{ width: '100%', marginTop: '10px', padding: '16px 10px' }}
                  pendoTag={`surgeon profile - ${surgeon.name}`}
                  tertiary
                />
              )}
              {/* TODO: add this functionality */}
              {/* <button style={{ marginTop: '10px', width: '100%' }} className='secondary' >Send message</button> */}
            </div>
          </div>
        </div>
      </div>
      <div className={`content_container`}>
        <div className='container de-pad'>
          <div className='row'>
            <div className='col'>
              <p className={styles.about_me}>About me</p>
              <div style={{ marginTop: '10px' }}>
                {surgeon.video_url && <VideoType2 iconSize='50px' videoLink={surgeon.video_url} autoplay />}
              </div>
              <p className={`${styles.about_bio} ${styles.short}`}>{surgeon.bio}</p>
              <a
                onClick={() => scrollToBio()}
                style={{ width: '100%', marginTop: '10px', padding: '6px 20px', display: 'block' }}
                className='tertiary'
              >
                See full bio
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
