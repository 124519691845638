import React from 'react';
import { Link } from 'react-router-dom';
import ModalWrapper from '../modal';
import './index.css';

export default function AgreeToCallModal({ onSubmit, onClose, gtm }) {
  return (
    <ModalWrapper
      className='agree-to-call already-took-this'
      content={
        <div>
          <h3 className='marg-bottom-20'>Before you request a call</h3>
          <p>
            PatientPartners are volunteers.Please commit to respecting our volunteers time and schedule. They can only
            answer questions about their personal experience. Any questions{' '}
            <Link style={{ top: '-3px', position: 'relative' }} to='/contact-us'>
              <p className='text-link no-arrow'>regarding finances or insurance can be asked here.</p>
            </Link>
          </p>
          <p className='marg-top-20'>
            <b>I agree to answer my PatientPartner’s phone call.</b> I promise to treat my PatientPartner with respect
            and without judgement or bias.
          </p>
          <div className='marg-top-20 row max-wid'>
            <div className='col-sm-6'>
              <button
                data-gtm-click-label={`${gtm} Agree to Call I Agree`}
                onClick={onSubmit}
                className={`submit`}
                type='submit'
              >
                I agree
              </button>
            </div>
            <div className='col-sm-6'>
              <button
                data-gtm-click-label={`${gtm} Agree to Call I Decline`}
                onClick={onClose}
                className={`submit destructive`}
                type='submit'
              >
                I decline
              </button>
            </div>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
}
