import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import SuccessPanel from '../success_panel';
import './index.css';
import ReCAPTCHA from 'react-google-recaptcha';
import ErrorMessage from '../ui_kit/utilities/error_message';
import AgreeToTerms from '../ui_kit/utilities/agree_to_terms';
import { createInsuranceCheckContact } from '../../api/insurance_check_contacts';
import { validatePhone, validateEmail, validateText } from '../../helpers';

export default function InsuranceCheckForm({ home, updateAnswersAction, weightLoss }) {
  const [view, setView] = useState('form');
  const [name, setName] = useState(home.answers.name || '');
  const [email, setEmail] = useState(home.answers.email || '');
  const [phone, setPhone] = useState(home.answers.phone || '');
  const [insuranceCarrier, setInsuranceCarrier] = useState('');
  const [error, setError] = useState('');
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  let history = useHistory();
  const [showSuccess, setShowSuccess] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(weightLoss.answers.acceptedTerms || false);

  const recaptchaRef = React.createRef();

  const runRecaptcha = async (event) => {
    event.preventDefault();
    recaptchaRef.current.execute();
  };

  let disabled = 'disabled';
  var checkedEmail = validateEmail(email);

  let checkedPhone = false;
  if (phone !== '') {
    checkedPhone = validatePhone(phone);
  }
  var checkedName = validateText(name);
  var checkedInsuranceCarrier = validateText(insuranceCarrier);

  if (
    checkedName === true &&
    checkedEmail === true &&
    checkedPhone === true &&
    checkedInsuranceCarrier === true &&
    acceptedTerms
  ) {
    disabled = '';
  } else {
    disabled = 'disabled';
  }

  let fromQuestionnaire = new URLSearchParams(window.location.search).get('questionnaire');
  if (fromQuestionnaire == null) {
    fromQuestionnaire = false;
  }

  const validate = (event) => {
    event.preventDefault();
    if (checkedEmail === false && email !== '') {
      if (email.indexOf('@') == '-1') {
        setEmail('');
      }
      setError('Please enter a valid email');
    } else if (checkedPhone == false && phone !== '') {
      setError('Please enter a valid phone number');
    } else if (checkedName == false && name !== '') {
      setError('Please enter a name without special characters');
    } else if (checkedInsuranceCarrier == false && insuranceCarrier !== '') {
      setError('Please enter an insurance carrier without special characters');
    } else {
      setError('');
    }
  };

  const onClick = (event) => {
    if (hasBeenSubmitted == false) {
      setHasBeenSubmitted(true);
      if (checkedEmail === false) {
        setError('Please enter a valid email');
        setHasBeenSubmitted(false);
      } else if (checkedPhone == false) {
        setError('Please enter a valid phone number');
        setHasBeenSubmitted(false);
      } else if (checkedName == false) {
        setError('Please enter a name without special characters');
        setHasBeenSubmitted(false);
      } else if (checkedInsuranceCarrier == false) {
        setError('Please enter an insurance carrier without special characters');
        setHasBeenSubmitted(false);
      } else {
        createInsuranceCheckContact(name, email, phone, insuranceCarrier, fromQuestionnaire, () =>
          setShowSuccess(true)
        );
        setError('');
        updateAnswersAction({
          ...home.answers,
          name,
          email,
          phone,
        });
      }
    }
  };

  const bg = 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/insurance+check+banner.png';

  const backToQuestionnaire = () => {
    history.push('/get-started/patient-partner-match');
  };

  return (
    <div className='insurance-check'>
      <div className='container-fluid insurance-hero'>
        <div className='img-cover'></div>
        <div className='img-bg' style={{ backgroundImage: `url('${bg}')` }}></div>
        <div className='container'>
          <div className='row h-100'>
            <div className='col-sm-6'>
              <p className='little-strong marg-bottom-20'>Free Insurance Check</p>
              <h1 className='main marg-bottom-20'>Bariatric Surgery Insurance Coverage Check</h1>
              <h2 className='submain'></h2>
            </div>
            <div className='col-sm-6 my-auto'></div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div id='sign-up' className='col-md-5 move-me-up first-on-mobile'>
            <div className='sign-up-otr'>
              {!showSuccess && (
                <form id='infoCap'>
                  <h5 className='bold'>We'll let you know if your insurance carrier covers bariatric surgery:</h5>
                  <div className='material-input'>
                    <input
                      className='autofocus'
                      type='text'
                      name='name'
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      onBlur={validate}
                      required
                    />
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label>Name</label>
                  </div>
                  <div className='material-input'>
                    <input
                      type='email'
                      name='email'
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      onBlur={validate}
                      required
                    />
                    <span className='bar'></span>
                    <label>Email</label>
                  </div>
                  <div className='material-input'>
                    <input
                      type='tel'
                      required
                      name='phone'
                      value={phone}
                      onChange={(event) => setPhone(event.target.value)}
                      onBlur={validate}
                    ></input>
                    <span className='highlight'></span>
                    <span className='bar'></span>
                    <label>Phone Number</label>
                  </div>
                  <div className='material-input'>
                    <input
                      type='text'
                      name='insuranceCarrier'
                      value={insuranceCarrier}
                      onChange={(event) => setInsuranceCarrier(event.target.value)}
                      onBlur={validate}
                      required
                    />
                    <span className='bar'></span>
                    <label>Insurance Carrier</label>
                  </div>
                  <AgreeToTerms acceptedTerms={acceptedTerms} setAcceptedTerms={setAcceptedTerms} />
                  {error && <ErrorMessage error={error} />}
                  <ReCAPTCHA ref={recaptchaRef} size='invisible' sitekey={gon.recaptcha_sitekey} onChange={onClick} />
                  <button
                    disabled={disabled}
                    onClick={runRecaptcha}
                    data-gtm-click-label='Insurance Carrier Form Submit'
                    className={`marg-top-10 submit`}
                    type='submit'
                  >
                    Submit
                  </button>
                </form>
              )}
              {showSuccess && (
                <div className='success-panel-wrapper marg-top-30'>
                  <SuccessPanel
                    title='Thank you!'
                    content='Our team will check to see if your insurance carrier covers Bariatric Surgery and get back to you via phone call in the next 24-48 hours.'
                  />
                  {fromQuestionnaire && (
                    <button
                      onClick={backToQuestionnaire}
                      data-gtm-click-label='Insurance Check Form Back to Questionnaire'
                      className={`marg-top-10 maincta`}
                    >
                      Back to your PatientPartners
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='col-md-7 last-on-mobile'>
            <div className='content-body'>
              <h3 className='marg-bottom-20 marg-top-30'>
                Did you know that many insurance plans will cover bariatric surgery?
              </h3>
              <p>
                Figuring out if your insurance plan will cover your specific surgery is an important first step in your
                weight-loss journey and we're here to help.
              </p>
              <p className='bold marg-top-20 marg-bottom-10'>Here's how it works:</p>
              <ol className='marg-bottom-50'>
                <li>Fill out the insurance check form.</li>
                <li>In 24-48 hours, our team will call and let you know if your insurance covers the surgery.</li>
                <li>
                  Let us know if you need help finding a surgeon that accepts your insurance or{' '}
                  <Link to='/all-surgeons'>
                    <p className='text-link'>search our surgeon gallery!</p>
                  </Link>
                </li>
                <li>
                  If your insurance doesn't cover the surgery, talk to our team and we'll help guide you through
                  solutions.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
