import React, { Component } from 'react';
import OptionsAndOther from '../../../../questionnaire/choose_an_option';

export default function InsuranceCarrier({ value, onSubmit, onBack }) {
  return (
    <OptionsAndOther
      answers={{ insuranceCarrier: value }}
      keyForAnswer={'insuranceCarrier'}
      onSubmit={onSubmit}
      onBack={onBack}
      choices={['UnitedHealthcare', 'Anthem BCBS', 'Aetna', 'Cigna', 'Humana', 'BCBS of State']}
      title={`What commercial insurance do you have?`}
      para={``}
      showOther
      ctaTxt='Next'
      selectOne
    />
  );
}
