import React, { useState, useContext } from 'react';
import styles from './img_r_txt_l_simple.module.css';
import Context from '../../../utils/context';
import PhotoWBlueBg from '../photo_w_blue_bg';

export default function ImgRTxtLSimple({
  smallheadline,
  headline,
  mediumheadline,
  img,
  margBottom,
  bg = '#f1feff',
  headlineColor = '#314D69',
  marginTopPhotoMobile = '0px',
  list = [],
  cta = <></>,
  blueBg = true,
}) {
  const [imgLoaded, setImgLoaded] = useState(false);
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile();

  return (
    <div style={{ backgroundColor: bg }} className={`container-fluid ${styles.hero_container}`}>
      <div className='container'>
        <div className='row'>
          <div className={`col-lg-6 col-md-7 ${styles.minheightcol}`}>
            <h6 className={`marg-bottom-20 marg-top-20 ${styles.heading6}`}>{smallheadline}</h6>
            <h2 className='marg-bottom-30'>{mediumheadline}</h2>
            <h1 style={{ marginBottom: margBottom, color: headlineColor }} className={styles.heading1}>
              {headline}
            </h1>
            <ol className='marg-bottom-40'>
              {list.map((l, index) => (
                <li key={`img-left-text-right-${index}`}>{l}</li>
              ))}
            </ol>
            {cta}
          </div>
          <div className={`col-lg-6 col-md-5 de-pad align-items-end ${styles.minheightcol}${styles.flexcol}`}>
            <PhotoWBlueBg
              img={img}
              blueBg={blueBg}
              style={
                imgLoaded ? { display: 'block', marginTop: isMobile ? marginTopPhotoMobile : '' } : { display: 'none' }
              }
              onLoad={() => setImgLoaded(true)}
              className={styles.photo}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
