import React from 'react';
import WeightLossWebinarParent from '../index';
export default function January182022({ home, updateAnswersAction }) {
  return (
    <div>
      <WeightLossWebinarParent
        home={home}
        updateAnswersAction={updateAnswersAction}
        title={'Nutrition Throughout Weight Loss Surgery'}
        photo={
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/webinars/january-18-2022/Jan182022Webinar.jpg'
        }
        webinarDate={'January 18, 2022 12:00:00'}
        webinarDateText={['Tuesday, January 18th, 2022', '12:00 PM PT', '45 Minutes']}
        webinarTitleForDb={'January182022'}
        evergreenPage={'/webinar/january-18-2022/view'}
        googleCalendarLink={
          'https://calendar.google.com/event?action=TEMPLATE&tmeid=XzhncTM0YzIzNzBwNDRiOWk2cDFqYWI5azZwMGs0YmExNm9xMzRiOW42MTJqOGRoaDhwMms0ZGkzNjQgdGltb25leUBwYXRpZW50cGFydG5lci5jb20&tmsrc=timoney%40patientpartner.com'
        }
        otherCalendarLink={
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/webinars/january-18-2022/%E2%9C%A8Nutrition+Throughout+Weight+Loss+Surgery+Webinar%E2%9C%A8.ics'
        }
        wrapperClass={'center-bg-photo'}
        content={
          <>
            <p className='marg-top-30'>
              Weight loss surgery can seem intimidating because of the dietary requirements needed to qualify for and
              follow after the procedure.
              <br />
              <br />
              Our upcoming webinar <b>Nutrition Throughout Weight Loss Surgery</b> will help clarify the dietary
              recommendations you can expect before, during, and after weight loss surgery to ease your hesitation.
              Register now to be a part of the conversation on <b>Tuesday, January 18th at 12 pm PST.</b>
            </p>
            <p className='marg-top-30'>
              We will discuss the following topics:
              <ul className='marg-top-10'>
                <li>Fad diets and why they don't work</li>
                <li>4 stages of pre & post-op diet</li>
                <li>Common myths of what you can and can’t eat after surgery</li>
                <li>Helpful tools and resources for post-op</li>
                <li>Perspective from a recovered patient on what dieting was really like</li>
              </ul>
            </p>
            <p className='marg-top-20'>
              Joining us is Rachel Macam, a Registered Dietician with{' '}
              <a style={{ color: '#173959' }} href='https://oldedelmarsurgical.com/'>
                Olde Del Mar Surgical Clinic
              </a>
              , where they pride themselves on their award-winning team in San Diego, CA. Rachel is involved in the
              patient’s nutritional aspect of weight loss surgery from start to finish, helping to educate and support
              patients from 6 months before surgery through 1-year post-op.
              <br /> <br />
              We will also be joined by one of her recovered patients, Judy, who will also share helpful insights into
              what day-to-day life looks like through the surgery journey from a patient’s perspective.
            </p>
            <h2 className='body-subheadline marg-top-20'>
              Nutrition is an essential element in your weight loss surgery journey. Our upcoming webinar will help
              educate you on how to prepare your diet before, during, and after surgery.
            </h2>
            <p className='marg-top-20 marg-bottom-30'>
              Register for our webinar today to find out if weight loss surgery could be a life-changing option for you.
            </p>
            <button className='marg-top-20 send-it-final no-mobile'>Register now</button>
          </>
        }
      />
    </div>
  );
}
