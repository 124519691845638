import { connect } from 'react-redux';
import { updateAnswersAction, setQuestionnaireStateAction } from '../../actions/about';
import about from '../../components/about';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
  setQuestionnaireStateAction: (item) => dispatch(setQuestionnaireStateAction(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(about);
