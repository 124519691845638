import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Context from '../../../utils/context';
import LoginEdit from '../../users/login_edit';
import ProfileEdit from '../../users/profile_edit';
import styles from './acc_details.module.css';
import styles1 from '../../posts/createpost.module.css';
import RequestButton from '../../ui_kit/utilities/request_button';
import { successToast } from '../../ui_kit/utilities/success_toast';

export default function ProviderAccountDetails({}) {
  const context = useContext(Context);
  const user = context.auth.getCurrentUser();
  let history = useHistory();
  const [allSurgeons, setAllSurgeons] = useState(
    user.surgeon_administrative_assistants.sort((a, b) => (a.id > b.id ? 1 : -1))
  );
  const [canSaveSurgeons, setCanSaveSurgeons] = useState(false);
  const isMobile = context.viewport.getIsMobile();
  const checkedOffices = (s) => {
    const newAllSurgeons = [...allSurgeons];
    let newSurgeon = newAllSurgeons.find((newS) => s.id === newS.id);
    newSurgeon.send_patient_welcome_sequence = !newSurgeon.send_patient_welcome_sequence;
    setAllSurgeons(newAllSurgeons);
    setCanSaveSurgeons(true);
  };

  const onSubmit = (afterAction) => {
    context.auth.userUpdate({ surgeon_administrative_assistants: allSurgeons }, (result) => {
      successToast('Your changes have been saved.');
      setCanSaveSurgeons(false);
      afterAction();
    });
  };

  return (
    <div className={`container-fluid ${styles.roster_container_wrapper}`}>
      <div className={styles.roster_container}>
        <div className='container'>
          <p className='text-link all-surgeons-button text-left marg-bottom-0'>
            <a onClick={() => history.goBack()} data-gtm-click-label={`No Procedure Results Back`}>
              Back
            </a>
          </p>
          <div className='row'>
            <div className='col'>
              <div className={`${styles.profile_photo_name_wrap} h-100 marg-bottom-50`}>
                <div className={`${styles.profile_photo} my-auto`} style={{ backgroundImage: `url(${user.photo})` }} />
                <p className={`my-auto`}>{user.name}</p>
              </div>
            </div>
          </div>
          <div className={`row ${styles.roster_row}`}>
            <div className={`col-md-6 ${isMobile ? 'marg-bottom-50' : ''}`}>
              <div className={styles.roster_container}>
                <ProfileEdit />
              </div>
            </div>
            <div className={`col-md-6 ${isMobile ? 'marg-bottom-50' : ''}`}>
              <div className={styles.roster_container}>
                <LoginEdit />
              </div>
            </div>
          </div>
          <div className={styles.roster_container}>
            {!(user.is_xcopri || user.is_reactiv8 || user.is_reactiv8_aus || user.is_hologic || user.is_ossio) && (
              <div className='col-md-12 marg-top-10 marg-bottom-10'>
                <h5 style={{ fontSize: '24px', fontWeight: 'bold' }}>Welcome experience</h5>
                <p className='marg-top-10' style={{ display: 'flex', color: '#000000', fontSize: '18px' }}>
                  Enrolled patients will receive an automated message encouraging them to share more details about their
                  health background and insurance information.
                </p>
                <p style={{ display: 'flex', color: '#000000', fontSize: '16px' }} className='marg-top-20'>
                  Enroll new patients for:
                </p>

                {allSurgeons.map((s, index) => (
                  <button key={index} style={{ background: 'none' }} onClick={() => checkedOffices(s)} className='row'>
                    <div className='col-12 marg-top-10' style={{ display: 'flex' }}>
                      {s.send_patient_welcome_sequence ? (
                        <img
                          alt='checked box'
                          className={styles.no_trans}
                          style={{
                            marginLeft: '-2px',
                            marginRight: '-2px',
                          }}
                          src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+19/checkbox+checked.svg'
                        />
                      ) : (
                        <img
                          alt='unchecked box'
                          className={styles.no_trans}
                          style={{ marginLeft: '0px' }}
                          src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/newsfeed/icon+18/Rectangle.svg'
                        />
                      )}
                      <p style={{ marginLeft: '25px', color: '#000000', fontSize: '16px' }}>{s.display_name}</p>
                      <p
                        style={{
                          marginLeft: '10px',
                          fontStyle: 'italic',
                          color: '#828282',
                          fontSize: '14px',
                          marginTop: '3px',
                        }}
                      >
                        Recommended
                      </p>
                    </div>
                  </button>
                ))}
                <RequestButton
                  request={onSubmit}
                  disabled={canSaveSurgeons ? '' : 'disabled'}
                  cta='Save'
                  classnames={`marg-top-10 submit ${styles1.create_btn}`}
                >
                  Save
                </RequestButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
