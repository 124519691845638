import { connect } from 'react-redux';
import {
  setContactIdAction,
  updateAnswersAction,
  setPatientPartnerQuestionnaireProgress,
  setPatientPartners,
} from '../../actions/home';
import RealselfLiposuction from '../../components/realself/liposuction';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
  setContactIdAction: (item) => dispatch(setContactIdAction(item)),
  setPatientPartnerQuestionnaireProgress: (item) => dispatch(setPatientPartnerQuestionnaireProgress(item)),
  setPatientPartners: (item) => dispatch(setPatientPartners(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RealselfLiposuction);
