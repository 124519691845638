import React, { useState, useRef, useMemo, useEffect, useContext } from 'react';
import styles from './createpost.module.css';
import { createPost, updatePost } from './../../api/posts';
import { createActivity } from './../../api/activities';
import RequestButton from '../ui_kit/utilities/request_button';
import S3UploadFile from '../ui_kit/utilities/s3_upload_file';
import CheckboxWLabel from '../ui_kit/utilities/checkbox_w_label';
import ImageGridDisplay from '../ui_kit/utilities/image_grid_display';
import { useClickAway } from 'react-use';
import DropdownInput from '../ui_kit/utilities/dropdown_input';
import { pageInspect } from './../../api/metainspector';
import UrlPreview from './url_preview';
import LoadingScreen from '../loading_screen';
import { successToast } from '../ui_kit/utilities/success_toast';
import { isObjEmpty } from '../../helpers';
import Context from '../../utils/context';
var linkify = require('linkifyjs');
import useDebouncedCallback from 'use-debounce/lib/useDebouncedCallback';

export default function CreatePost({ onClose, modalType, postToEdit = {} }) {
  const context = useContext(Context);
  const isTestUser = context.auth.isLoggedIn() && context.auth.getCurrentUser().test_user;
  const isEdit = Object.keys(postToEdit).length !== 0;
  const [body, setBody] = useState(postToEdit.body || '');
  let defaultResponders = [{ value: 'Mentors' }, { value: 'Medical professionals' }, { value: 'General users' }];
  if (postToEdit.responders) {
    defaultResponders = postToEdit.responders.split(',').map((r) => {
      return { value: r };
    });
  }
  const [responders, setResponders] = useState(defaultResponders);
  let defaultFiles = [];
  if (postToEdit.post_images) {
    defaultFiles = postToEdit.post_images.map((i) => i.image);
  }
  const [files, setFiles] = useState(defaultFiles);
  const [type, setType] = useState(postToEdit.category || modalType || '');
  const [showDiscard, setShowDiscard] = useState(false);
  const ref = useRef(null);
  const ref_1 = useRef(null);
  const [urlPreview, setUrlPreview] = useState();
  const [isUrlPreviewLoading, setIsUrlPreviewLoading] = useState(false);
  const [urlMetadata, setUrlMetadata] = useState(postToEdit.url_preview_metadata);
  const [test, setTest] = useState(postToEdit.test || false);
  const [future, setFuture] = useState(postToEdit.future || false);

  const submitCreatePost = (afterAction) => {
    if (isEdit) {
      updatePost(
        postToEdit.uuid,
        {
          body: body,
          responders: responders.map((r) => r.value),
          files: files,
          url_preview_metadata: urlMetadata,
          category: type,
          test: test,
          future: future,
        },
        (data) => {
          onClose();
          successToast('Post updated.');
        }
      );
    } else {
      createActivity('Activity::DiscussionsPost', {});
      createPost(
        {
          body: body,
          responders: responders.map((r) => r.value),
          files: files,
          url_preview_metadata: urlMetadata,
          category: type,
          test: test,
          future: future,
        },
        (data) => {
          if (future) {
            window.location = data.share_url;
          } else {
            onClose();
          }
        }
      );
    }
  };

  const canShowDiscard = () => {
    if (body !== '' || files.length > 0) {
      return true;
    }
    return false;
  };

  useClickAway(ref_1, () => {
    if (canShowDiscard() === true) {
      setShowDiscard(true);
    } else {
      onClose(false);
    }
  });

  useClickAway(ref, () => {
    if (canShowDiscard() === true) {
      setShowDiscard(true);
    } else {
      onClose(false);
    }
  });

  const debouncedShowUrlPreview = useDebouncedCallback((value) => {
    showUrlPreview(value);
  }, 1000);

  const showUrlPreview = (newBody) => {
    const link = linkify.find(newBody).find((l) => l.type === 'url');
    if (link) {
      if (link.href !== urlPreview) {
        setUrlPreview(link.href);
        pageInspect(link.href, (data) => {
          if (!isObjEmpty(data) && data.title && data.description && data.image) {
            setUrlMetadata(data);
          }
          setIsUrlPreviewLoading(false);
        });
        setIsUrlPreviewLoading(true);
      }
    }
  };

  const onBodyChange = (e) => {
    setBody(e.target.value);
    debouncedShowUrlPreview(e.target.value);
  };

  const removeUrlPreview = () => {
    setUrlMetadata(null);
    setUrlPreview(null);
  };

  const [previousResponders, setPreviousResponders] = useState(responders);
  useEffect(() => {
    let arr = responders.filter(
      (r) => r.value === 'Mentors' || r.value === 'Medical professionals' || r.value === 'General users'
    );
    if (previousResponders.length === 4 && responders.length === 3 && arr.length === 3) {
      setResponders([]);
      setPreviousResponders([]);
    } else if (
      (arr.length === 3 && responders.length !== 4) ||
      (responders.length === 1 && responders.filter((r) => r.value === 'All users').length === 1)
    ) {
      setResponders([
        { value: 'All users' },
        { value: 'Mentors' },
        { value: 'Medical professionals' },
        { value: 'General users' },
      ]);
      setPreviousResponders([
        { value: 'All users' },
        { value: 'Mentors' },
        { value: 'Medical professionals' },
        { value: 'General users' },
      ]);
    } else if (responders.length === 3 && responders.filter((r) => r.value === 'All users').length === 1) {
      setResponders(responders.filter((r) => r.value !== 'All users'));
      setPreviousResponders(responders.filter((r) => r.value !== 'All users'));
    }
  }, [responders]);

  return (
    <div className={`${styles.create_post_otr} container-fluid create-post`}>
      <div className={`${styles.cp_cont} container`}>
        <div className='row justify-content-center'>
          <div ref={ref} className={`${styles.cp_col} container col-lg-6`}>
            <div
              className={styles.create_post_wrap}
              style={{ paddingBottom: '0px', overflow: 'hidden', height: '100%' }}
            >
              <div className={`${styles.create_new_banner}`}>
                {isEdit && <p className={`${styles.nb_para}`}>Edit post</p>}
                {!isEdit && <p className={`${styles.nb_para}`}>Create new post</p>}
                <span
                  onClick={() => (body !== '' || files.length > 0 ? setShowDiscard(true) : onClose(false))}
                  className={`${styles.close}`}
                >
                  <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg' />
                </span>
              </div>
              <div className={`${styles.content}`}>
                {/* <div style={{ maxWidth: '100%' }} className="material-input">
                                    <input style={{ maxWidth: '100%' }} className="autofocus" type="text" value={title} onChange={(event) => setTitle(event.target.value)} required />
                                    <span className="highlight"></span>
                                    <span className="bar"></span>
                                    <label>Question Title...</label>
                                </div> */}
                <div className={`${styles.dropdowns}`}>
                  {/* <div className={`${styles.dropdown_wrap} ${styles.dropdown_wrap_1}`}>
                                        <p className={`${styles.dropdown_label}`}>Post type</p>
                                        <DropdownInput
                                            marginTop={'8px'}
                                            type={'single'}
                                            placeholder={'Select One'}
                                            options={[
                                                {
                                                    value: 'Question'
                                                },
                                                {
                                                    value: 'Personal update'
                                                },
                                                {
                                                    value: 'Helpful info'
                                                }
                                            ]}
                                            values={type}
                                            setValues={setType}
                                            defaultOpen={type === '' ? true : false}
                                            focus={type === '' ? true : false}
                                        />
                                    </div> */}
                  <div className={`${styles.dropdown_wrap} ${styles.dropdown_wrap_2}`}>
                    <p className={`${styles.dropdown_label}`}>Receive comments from</p>
                    <DropdownInput
                      marginTop={'8px'}
                      type={'multi'}
                      options={[
                        {
                          value: 'All users',
                        },
                        {
                          value: 'Mentors',
                        },
                        {
                          value: 'Medical professionals',
                        },
                        {
                          value: 'General users',
                        },
                        {
                          value: 'Turn off commenting',
                          single: true,
                        },
                      ]}
                      values={responders}
                      setValues={setResponders}
                      valueOverride={responders.length === 4 ? 'All users' : false}
                    />
                  </div>
                </div>
                <div className={`${styles.content_body}`}>
                  <textarea
                    value={body}
                    onChange={onBodyChange}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && setBody(body.push('\n'));
                    }}
                    style={{ maxWidth: '100%', height: '150px', marginTop: '0px' }}
                    className='material-input'
                    placeholder='Write your post here...'
                  />
                  {/* START website preview */}
                  <div style={{ marginTop: '10px' }}>
                    {isUrlPreviewLoading && <LoadingScreen size={'20'} />}
                    {!isUrlPreviewLoading && urlMetadata && (
                      <UrlPreview metadata={urlMetadata} onDelete={removeUrlPreview} />
                    )}
                  </div>
                  {/* END website preview */}
                  <div className='marg-top-10'>
                    <S3UploadFile
                      onChange={(newFiles) => {
                        setFiles([...files, ...newFiles]);
                      }}
                      type='button'
                      name='filename'
                      multiple={true}
                    />
                  </div>
                  <div className='marg-top-10'>
                    {useMemo(
                      () => (
                        <ImageGridDisplay photos={files} setPhotos={setFiles} type={'inline'} allowRemove />
                      ),
                      [files]
                    )}
                  </div>
                  {isTestUser && (
                    <>
                      <div>
                        <CheckboxWLabel setCheck={setTest} check={test} label='Test post' />
                      </div>
                      <div>
                        <CheckboxWLabel setCheck={setFuture} check={future} label='Future post' />
                      </div>
                    </>
                  )}
                </div>
                <div className={`${styles.cta_wrap}`}>
                  <button
                    onClick={() => (body !== '' || files.length > 0 ? setShowDiscard(true) : onClose(false))}
                    className={`${styles.cancel} liner ${styles.create_btn}`}
                  >
                    Cancel
                  </button>
                  <RequestButton
                    disabled={body === '' || isUrlPreviewLoading ? 'disabled' : ''}
                    cta={isEdit ? 'Update' : 'Post'}
                    classnames={styles.create_btn}
                    request={submitCreatePost}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDiscard && (
        <div className={`${styles.create_post_otr} container-fluid`}>
          <div className='container'>
            <div className='row justify-content-center'>
              <div ref={ref_1} className='col-lg-6'>
                <div
                  className={`${styles.create_post_wrap} ${styles.discard} marg-top-80`}
                  style={{ paddingBottom: '0px', overflow: 'hidden' }}
                >
                  <div className={`${styles.create_new_banner}`}>
                    <p className={`${styles.nb_para}`}>Discard changes</p>
                    <span onClick={() => setShowDiscard(false)} className={`${styles.close}`}>
                      <img src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/cross.svg' />
                    </span>
                  </div>
                  <div className={`${styles.content}`}>
                    <p className='marg-top-20' style={{ fontSize: '16px', color: '#828282', fontWeight: '500' }}>
                      You have not finished your publication yet. Leave this page and discard changes?
                    </p>
                    <div className={`${styles.cta_wrap}`}>
                      <button
                        onClick={() => setShowDiscard(false)}
                        className={`${styles.create_btn} ${styles.cancel} liner`}
                      >
                        Cancel
                      </button>
                      <button className={`${styles.create_btn}`} onClick={() => onClose(false)}>
                        Discard
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
