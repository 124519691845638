export const createUserDeactivates = (reasons, description, currentUser, successFunction) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  fetch(`/api/v1/users/${currentUser.id}/user_deactivates`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({
      reason: reasons,
      description: description,
      user_id: currentUser.id,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (successFunction) {
        successFunction(data);
      }
    });
};
