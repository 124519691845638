import React, { useState, useEffect, useContext } from 'react';
import SimpleAvatar from '../../ui_kit/utilities/simple_avatar';
import CreatePost from '../create_post';
import styles from './index.module.css';
import Context from '../../../utils/context';

export default function CreateModule({
  refresh = () => {},
  openCreate = false,
  setOpenCreate = () => console.log(),
  pendoTag = '',
}) {
  const [create, setCreate] = useState(openCreate);
  const [typeOfPost, setTypeOfPost] = useState('');
  const context = useContext(Context);
  const isMobile = context.viewport.getIsMobile();
  const user = context.auth.getCurrentUser();

  const closeModal = (bool) => {
    setCreate(bool);
    setTypeOfPost('');
    refresh();
    setOpenCreate(false);
  };

  useEffect(() => {
    if (create) {
      document.querySelector('body').style.overflow = 'hidden';
      document.querySelector('body').style.height = '100%';
      document.querySelector('html').style.overflow = 'hidden';
      document.querySelector('html').style.height = '100%';
    } else {
      document.querySelector('body').style.overflowY = 'scroll';
    }
  }, [create]);

  useEffect(() => {
    setCreate(openCreate);
  }, [openCreate]);

  return (
    <>
      <div onClick={() => setCreate(true)} className={`container content_container ${styles.create_otr}`}>
        <div className={`${styles.row_wrap} row`}>
          <div className={`${styles.ava_wrap} col`}>
            <div>
              <SimpleAvatar userSlug={user.slug} />
            </div>
            <div className='container'>
              <div className='row'>
                <div className='col'>
                  <p data-pendo-tag={pendoTag} className={`${styles.input}`}>
                    Start a post
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.row_wrap} row`}>
          <div className={`${styles.ava_wrap} ${styles.left_prompt} col`}></div>
          <div className={`${styles.ava_wrap} col`}>
            <p className={styles.prompt}>
              Ask a question, celebrate milestones, or share some helpful tips with the community!
            </p>
          </div>
        </div>

        {/* <div className={`${styles.row_wrap} row`}>
              <div className={`${styles.center_col} col`}>
                  <div onClick={() => setTypeOfPost('Question')} className={`${styles.btn_wrap}`}>
                      <img className={`${styles.icon}`} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/question.svg" />
                      <p className={`${styles.para}`}>Question</p>
                  </div>
                  <div onClick={() => setTypeOfPost('Personal update')} className={`${styles.btn_wrap}`}>
                      <img className={`${styles.icon}`} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/write.svg" />
                      <p className={`${styles.para}`}>Update</p>
                  </div>
                  <div onClick={() => setTypeOfPost('Helpful info')} className={`${styles.btn_wrap}`}>
                      <img className={`${styles.icon}`} src="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/platform/Icon.svg" />
                      <p className={`${styles.para}`}>Helpful info</p>
                  </div>
              </div>
            </div> */}
      </div>
      <div className={`row marg-top-15 ${styles.cta_row}`}></div>
      {create && (
        <>
          <div className={`${styles.fade} ${create ? styles.fade_in : ''}`}>
            <CreatePost onClose={closeModal} modalType={typeOfPost} />
          </div>
        </>
      )}
    </>
  );
}
