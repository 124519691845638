import React, { Suspense, useState, useEffect, useContext, useRef } from 'react';
import { Route, Switch, Redirect, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import SignUp from '../../sign_up';
import LoadingScreen from '../../loading_screen';
import Context from '../../../utils/context';
import WhatProcedure from '../patient_partner/what_procedure';
import WhatSurgery from '../patient_partner/what_surgery';
import PatientGender from '../patient_partner/patient_gender';
import Age from '../age';
import '../questionnaire.css';
import { createConversation } from '../../../api/conversations';
import { createSurgeonReferredMentorMatch } from '../../../api/surgeon_referred_mentor_matches';
import { getSurgeon } from '../../../api/surgeons';
const XcopriQuestionnaire = React.lazy(() => import('../xcopri/index.jsx'));
const OssioQuestionnaire = React.lazy(() => import('../ossio/index.jsx'));
const VonjoQuestionnaire = React.lazy(() => import('../vonjo/index.jsx'));

export default function DiscussionsQuestionnaire({ home, standAlone = false }) {
  const context = useContext(Context);
  let currentGtm = 'Discussions Questionnaire';

  let { path } = useRouteMatch();
  const patientPartnerId = useRef(new URLSearchParams(window.location.search).get('patient_partner_id'));
  const profileId = useRef(new URLSearchParams(window.location.search).get('profile_id'));
  const profileType = useRef(new URLSearchParams(window.location.search).get('profile_type'));
  let history = useHistory();
  let location = useLocation();
  let availableProcedures = Object.keys(gon.showable_procedure_surgery_mappings);
  const [currentProcedures, setCurrentProcedures] = useState(
    context.auth.isLoggedIn() ? context.auth.getCurrentUser().current_profile.procedures : []
  );
  const [currentSurgeries, setCurrentSurgeries] = useState(
    context.auth.isLoggedIn() ? context.auth.getCurrentUser().current_profile.surgeries_or_treatments : []
  );
  const [currentGender, setCurrentGender] = useState(
    context.auth.isLoggedIn() ? context.auth.getCurrentUser().gender : null
  );
  const [currentBirthday, setCurrentBirthday] = useState(
    context.auth.isLoggedIn() ? context.auth.getCurrentUser().date_of_birth : null
  );

  useEffect(() => {
    if (location.pathname.includes('loading')) {
      setTimeout(() => {
        history.push(`/member/home`);
      }, 3000);
    }
  }, [location.pathname, history]);

  let procedures = [];
  for (const [key, value] of Object.entries(gon.procedure_surgery_mappings)) {
    procedures.push({
      procedure: key,
      surgeries: value,
    });
  }

  let availableSurgeries = [];
  if (context.auth.isLoggedIn()) {
    procedures.forEach((procedureCategory) => {
      currentProcedures.forEach((procedure) => {
        if (procedure.toLowerCase() === procedureCategory.procedure.toLowerCase()) {
          availableSurgeries = procedureCategory.surgeries;
        }
      });
    });
  }

  const searchParams = new URLSearchParams(location.search);
  let returnTo = '';
  if (searchParams.get('return_to')) {
    returnTo = searchParams.get('return_to');
  }

  const isSpine =
    currentProcedures &&
    (currentProcedures.includes('Spine Surgery') || currentProcedures.includes('Back/neck treatment'));

  let capturePhone = true;

  const redirectAfterSurgery = (procedure, surgery) => {
    if (
      procedure === 'Weight Loss' ||
      procedure === 'ReActiv8' ||
      procedure === 'Epilepsy' ||
      procedure === 'Bunions'
      // TODO: uncomment this when Vonjo is live
      // || procedure === 'Myelofibrosis'
    ) {
      let surgeonSlug;
      switch (procedure) {
        case 'Epilepsy':
          surgeonSlug = 'xcopri';
          break;
        case 'Weight Loss':
          surgeonSlug = 'dr-shaneeta-johnson';
          break;
        case 'Bunions':
          surgeonSlug = 'ossio';
          break;
        // TODO: uncomment this when Vonjo is live
        // case 'Myelofibrosis':
        //   surgeonSlug = 'vonjo';
        //   break;
        default:
          surgeonSlug = 'reactiv8';
          break;
      }

      if (procedure === 'Epilepsy') {
        history.push(`${path}/epilepsy`);
        return;
      }
      // TODO: uncomment this when Vonjo is live
      // if (procedure === 'Myelofibrosis') {
      //   history.push(`${path}/myelofibrosis`);
      //   return;
      // }

      if (procedure === 'Bunions') {
        history.push(`${path}/bunions`);
        return;
      }

      getSurgeon(surgeonSlug, (data) => {
        let surgeon = data;

        createSurgeonReferredMentorMatch(
          {
            surgeon_id: surgeon.id,
            surgery: surgery,
          },
          (match) => {
            history.push(`/member/surgeon-mentor-matches/${match.uuid}/pre-survey`);
          }
        );
      });
    } else {
      history.push(`${path}/gender`);
    }
  };

  return (
    <div id={`questionnaire-scroll-to`} className={`patient-partner-questionnaire container text-left h-100 de-pad`}>
      <div
        style={{
          backgroundColor: '#f5f7f9',
          alignContent: 'center',
          minHeight: standAlone ? '100%' : '20vh',
          padding: standAlone ? '0px' : '75px 0px',
          margin: '0px',
        }}
        className='row h-100 '
      >
        <div className='col-md-2'></div>
        <div className={`col-md-8 fadeOp`}>
          {context.auth.isLoggedIn() ? (
            <Switch>
              <Route path={`${path}/procedures`}>
                <WhatProcedure
                  head='What do you want to connect with your mentor about?'
                  para='This will help pair you with a mentor who has similar experiences.'
                  selected={currentProcedures}
                  choices={[...availableProcedures]}
                  label={'Select one'}
                  gtm={currentGtm}
                  onSubmit={(values) => {
                    setCurrentProcedures(values);
                    history.push(`${path}/surgeries`);
                  }}
                />
              </Route>
              <Route path={`${path}/surgeries`}>
                <WhatSurgery
                  head={
                    isSpine ? 'What treatment are you considering?' : 'What surgery or treatment are you considering?'
                  }
                  para='This will help pair you with a mentor who has similar experiences.'
                  selected={currentSurgeries}
                  choices={[...availableSurgeries]}
                  noMatchTxt={"I'm not sure"}
                  label={isSpine ? 'Select treatment (e.g. Spinal Fusion)' : 'Select one'}
                  gtm={currentGtm}
                  onBack={() => {
                    history.push(`${path}/procedures`);
                  }}
                  onSubmit={(values) => {
                    setCurrentSurgeries(values);
                    context.auth.userUpdate({ procedure: currentProcedures[0], surgery: values[0] }, () => {
                      redirectAfterSurgery(currentProcedures[0], values[0]);
                    });
                  }}
                />
              </Route>
              <Route path={`${path}/gender`}>
                <PatientGender
                  para='Receive recommendations and resources relatable to your gender.'
                  answers={{ gender: currentGender }}
                  onSubmit={(answers) => {
                    setCurrentGender(answers.gender);
                    context.auth.userUpdate({ gender: answers.gender }, () => history.push(`${path}/age`));
                  }}
                  onBack={() => {
                    history.push(`${path}/surgeries`);
                  }}
                  gtm={currentGtm}
                />
              </Route>
              <Route path={`${path}/age`}>
                <Age
                  title='What is your birthday?'
                  para='Receive recommendations and resources relatable to your age group.'
                  answers={{ date_of_birth: currentBirthday }}
                  onSubmit={(answers) => {
                    setCurrentBirthday(answers.date_of_birth);
                    context.auth.userUpdate({ date_of_birth: answers.date_of_birth }, () =>
                      history.push(`${path}/loading`)
                    );
                  }}
                  onBack={() => {
                    history.push(`${path}/gender`);
                  }}
                  gtm={currentGtm}
                  cta='Submit'
                />
              </Route>
              <Route path={`${path}/epilepsy`}>
                <Suspense fallback={<p>loading</p>}>
                  <XcopriQuestionnaire />
                </Suspense>
              </Route>
              <Route path={`${path}/bunions`}>
                <Suspense fallback={<p>loading</p>}>
                  <OssioQuestionnaire />
                </Suspense>
              </Route>
              {/* TODO: uncomment this when Vonjo is live */}
              {/* <Route path={`${path}/myelofibrosis`}>
                <Suspense fallback={<p>loading</p>}>
                  <VonjoQuestionnaire />
                </Suspense>
              </Route> */}
              <Route path={`${path}/loading`}>
                <LoadingScreen
                  title={`Creating your personalized profile!`}
                  content={`We are on it! We’re curating your personalized PatientPartner experience based on your answers.`}
                />
              </Route>
              <Route path={`${path}`}>
                <Redirect to={`/member/home`} />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route exact path={`${path}`}>
                <SignUp
                  para='Create your account and explore your personalized support community.'
                  defaultEmail={home.answers.email || ''}
                  onSuccess={(user) => {
                    if (patientPartnerId.current && patientPartnerId.current != null) {
                      createConversation(
                        { patient_partner_id: patientPartnerId.current, add_procedure: true },
                        (conversation) => {
                          history.push(`/member/conversations/${conversation.uuid}`);
                        }
                      );
                    } else if (returnTo !== '') {
                      history.push((returnTo.includes('?') ? returnTo + '&' : returnTo + '?') + 'signed_up=true');
                    } else if (
                      profileId.current &&
                      profileId.current != null &&
                      profileType.current &&
                      profileType.current != null
                    ) {
                      createConversation(
                        { profile_id: profileId.current, profile_type: profileType.current, add_procedure: true },
                        (conversation) => {
                          history.push(`/member/conversations/${conversation.uuid}`);
                        }
                      );
                    } else {
                      history.push(`${path}/procedures`);
                    }
                  }}
                  captureLocation
                  capturePhone={capturePhone}
                  gtm={currentGtm}
                  withoutContainer
                  captureFirstLastName
                  params={{ origin: 'Support Questionnaire' }}
                  embedded
                  light={false}
                  alignLeft
                />
              </Route>
            </Switch>
          )}
        </div>
        <div className='col-md-2'></div>
      </div>
    </div>
  );
}
