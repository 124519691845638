import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SurgeonDetailDisplay from '../../../surgeon/detail_display';
import { getSurgeonAdministrativeAssistant } from '../../../../api/surgeon_administrative_assistants';

export default function PatientSurgeonAdminPanel({ isMobile, otherUser }) {
  const [loading, setLoading] = useState(true);
  const [surgeon, setSurgeon] = useState();

  useEffect(() => {
    getSurgeonAdministrativeAssistant(otherUser.profile.id, (data) => {
      setSurgeon(data.surgeon);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <>Loading</>;
  }

  const renderAge = (otherUser) => {
    if (otherUser.user && otherUser.user.age) {
      return `${otherUser.user.age} yrs. old`;
    }

    return '';
  };

  return (
    <>
      <div
        className={`${otherUser.profile.type === 'SuperUser' ? 'chat-room-super-user-avatar-lg' : 'chat-room-pp-avatar-lg'} marg-bottom-20 ${isMobile ? 'marg-top-10' : 'marg-top-40'}`}
        style={
          otherUser.profile.type === 'SurgeonAdministrativeAssistant'
            ? {
                backgroundImage: `url(${otherUser.profile.photo})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }
            : { backgroundImage: `url(${otherUser.profile.photo})` }
        }
      />
      <p className='bold text-center ou-name'>{otherUser.profile.full_name}</p>
      <p className='text-center ou-short-info marg-bottom-20'>
        {renderAge(otherUser)}
        {otherUser.user && otherUser.user.age && otherUser.profile.display_location !== null ? ', ' : ''}
        {otherUser.profile.display_location !== null ? otherUser.profile.display_location : ''}
      </p>
      <SurgeonDetailDisplay surgeon={surgeon} />
    </>
  );
}
