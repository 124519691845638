import React, { useState, useContext } from 'react';
import AgreeToTerms from '../ui_kit/utilities/agree_to_terms';
import { createProviderApplicantContacts } from '../../api/provider_applicant_contacts';
import VideoType2 from '../video_sections/video_type_2';
import ScrollTo from '../scroll_to';
import { validateEmail } from '../../helpers';
import ErrorMessage from '../ui_kit/utilities/error_message';
import SuccessPanel from '../success_panel';
import LoadingScreen from '../loading_screen';
import styles from './for_providers.module.css';
import TextLeftImgRightWbg from '../hero_sections/txt_left_img_right_wbg';
import TwoTestimonials from '../two_testimonials';
import Context from '../../utils/context';

export default function ForProviders({}) {
  const [email, setEmail] = useState('');
  const [acceptedTerms, setTerms] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const context = useContext(Context);
  const viewport = context.viewport;
  const isMobile = viewport.getIsMobile();

  var checkedEmail = validateEmail(email);

  const setAcceptedTerms = () => {
    setTerms(!acceptedTerms);
    validate();
  };

  const validate = () => {
    if (checkedEmail === false && email !== '') {
      setEmailError('Please enter a valid email');
    } else {
      setEmailError('');
    }
  };

  const onSubmit = () => {
    setLoading(true);
    createProviderApplicantContacts(null, email, null, null, null, (response) => {
      setLoading(false);
      if (response === 200) {
        setSuccess(true);
      } else {
        setError('There was an error, please try again');
      }
    });
  };

  return (
    <div>
      <TextLeftImgRightWbg
        headline={'Optimize your patient experience'}
        para={
          'Learn how you can leverage your past patients to grow your practice. Request a demo today to learn more!'
        }
        bg={'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/landing-pages/for-providers-hero.jpg'}
        bgColor={'#F1FEFF'}
        iconContent={''}
        thoseDots={''}
        backgroundSize={'contain'}
        mobileBackgroundSize={'contain'}
        heightOfImage={''}
        shortenHeaderImgCol={true}
        showRightImgOnMobile={true}
      />
      <div className='container'>
        <div className='row'>
          <div className={`col-lg-6 col-md-5 ${styles.de_pad}`}>
            <div id='sign-up' className={`${styles.move_me_up} last-on-mobile`}>
              <div className={`sign-up-otr ${styles.floatbox}`}>
                {!success && (
                  <>
                    <div className={`material-input marg-top-0 ${styles.fulwid}`}>
                      <input
                        type='text'
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                        onBlur={() => validate()}
                        required
                      />
                      <span class='highlight'></span>
                      <span class='bar'></span>
                      <label>Email</label>
                    </div>
                    {emailError && <ErrorMessage error={emailError} />}
                    <AgreeToTerms acceptedTerms={acceptedTerms} setAcceptedTerms={setAcceptedTerms} />
                    <button
                      disabled={checkedEmail && acceptedTerms === true ? '' : 'disabled'}
                      onClick={() => onSubmit()}
                      className='marg-top-20'
                      style={{ width: '100%' }}
                      data-gtm-click-label='For Providers Submit'
                    >
                      Request a demo
                    </button>
                    {error && <ErrorMessage error={error} />}
                  </>
                )}
                {loading && (
                  <>
                    <LoadingScreen />
                  </>
                )}
                {success && (
                  <>
                    <SuccessPanel title='Thank you!' content='Our team will contact you in the next 24-48 hours.' />
                  </>
                )}
              </div>
              <img
                className={styles.dr_pic}
                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/doctor-hero.jpg'
                alt='doctor-with-stethoscope'
              />
            </div>
          </div>
          <div className='col-lg-1 col-md-1'></div>
          <div className={`col-lg-5 col-md-6 ${styles.grow_smarter} ${styles.de_pad}`}>
            <div className='container-fluid'>
              <div className={`col ${styles.de_pad}`}>
                <div className='row'>
                  <div className={`col ${styles.de_pad}`}>
                    <h2 style={{ marginBottom: '5px' }} className={styles.headingicons}>
                      Grow smarter:
                    </h2>
                  </div>
                </div>
                <div className='row'>
                  <div className={`col ${styles.de_pad}`}>
                    <div className={styles.side_col}>
                      <div className='row h-100 marg-top-30'>
                        <div className='col-md-3 my-auto'>
                          <img
                            className={styles.icon}
                            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/peopleclose.svg'
                          />
                        </div>
                        <div className='col-md-9'>
                          <h6 className={`${styles.heading6} ${styles.iconheads}`}>Differentiate your practice</h6>
                          <p className='marg-top-5'>
                            Boost patient retention and engagement with an unparalleled support system.
                          </p>
                        </div>
                      </div>
                      <div className='row h-100 marg-top-30'>
                        <div className='col-md-3 my-auto'>
                          <img
                            className={styles.icon}
                            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/broaderreachicon1.svg'
                          />
                        </div>
                        <div className='col-md-9'>
                          <h6 className={`${styles.heading6} ${styles.iconheads}`}>Data-driven insight</h6>
                          <p className='marg-top-5'>
                            Gain information found nowhere else on how to better serve your patients.
                          </p>
                        </div>
                      </div>
                      <div className='row h-100 marg-top-30'>
                        <div className='col-md-3 my-auto'>
                          <img
                            className={styles.icon}
                            src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/framed.svg'
                          />
                        </div>
                        <div className='col-md-9'>
                          <h6 className={`${styles.heading6} ${styles.iconheads}`}>Empower your patients</h6>
                          <p className='marg-top-5'>
                            Allow your best patients to vouch for your practice with interactive reviews.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: '70px', backgroundColor: '#F1FEFF' }} className='container-fluid'>
        <div className='container'>
          <div className='row'>
            <TwoTestimonials
              testimonials={[
                {
                  text: 'You can’t be the best surgeon out there without something like this in place.',
                  image:
                    'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/landing-pages/dr-daniel-rosen.jpg',
                  name: 'Dr. Daniel J. Rosen',
                  city: 'New York City, NY',
                },
                {
                  text: 'This program is so effective, it can change an entire practice. I would not have seen some of the patients I have without this program.',
                  image:
                    'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/landing-pages/dr-ben-leong.jpg',
                  name: 'Dr. Ben Leong',
                  city: 'Corona, CA',
                },
              ]}
              bg={''}
              headline={'What physicians are saying'}
            />
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: '#F2F2F2', paddingTop: '0px', paddingBottom: '80px' }} className='container-fluid'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-2'></div>
            <div className='col-lg-8'>
              <h2 className='text-center marg-top-50'>How it works</h2>
              <p className='text-center marg-top-10 marg-bottom-60'>
                We’re accepting applications for new providers across any medical
                <br /> procedure category.
              </p>
            </div>
            <div className='col-lg-2'></div>
          </div>
          <div className='row'>
            <div className={`col-lg-4 ${styles.colpad}`}>
              <div style={{ marginTop: '0px' }} className={styles.numbub}>
                <h2 className={styles.numbubtxt}>1</h2>
              </div>
              <h3 className={`text-center ${styles.heading3}`}>Learn</h3>
              <p className='marg-bottom-20 text-center'>
                Request a 20-minute demo to see where PatientPartner can fit within your practice.
              </p>
            </div>
            <div className={`col-lg-4 ${styles.colpad}`}>
              <div className={styles.numbub}>
                <h2 className={styles.numbubtxt}>2</h2>
              </div>
              <h3 className={`text-center ${styles.heading3}`}>Launch</h3>
              <p className='marg-bottom-20 text-center'>
                Implement your strategy with your new PatientPartner customer success and engineering team.
              </p>
            </div>
            <div className={`col-lg-4 ${styles.colpad}`}>
              <div className={styles.numbub}>
                <h2 className={styles.numbubtxt}>3</h2>
              </div>
              <h3 className={`text-center ${styles.heading3}`}>Scale</h3>
              <p className='marg-bottom-20 text-center'>
                Continuously grow your practice by utilizing key data insights to drive more revenue and provide the
                ultimate patient experience.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <button
                data-gtm-click-label='For Providers Start my application'
                className='marg-center marg-top-30'
                onClick={() => ScrollTo('#sign-up', 'initial')}
              >
                Request a demo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: '50px', overflow: 'hidden' }} className='container mentor-video-section'>
        <div className='row'>
          <div className='col'>
            <h2 style={{ color: '#314D69' }} className={styles.vidheadline}>
              See how PatientPartner can help your practice...
            </h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-2'></div>
          <div className='col-lg-8'>
            <div style={{ position: 'relative' }}>
              <svg
                className={styles.svg1}
                width='57'
                height='113'
                viewBox='0 0 57 113'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M56.5 113C41.5153 113 27.1443 107.047 16.5485 96.4515C5.95266 85.8557 1.98376e-06 71.4847 6.73756e-07 56.5C-6.36251e-07 41.5153 5.95266 27.1443 16.5485 16.5485C27.1443 5.95267 41.5153 3.11508e-06 56.5 6.73757e-07L56.5 56.5L56.5 113Z'
                  fill='#314D69'
                />
              </svg>
              <VideoType2
                bg='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/landing-pages/dr-bhoyrul-video.jpg'
                videoLink='https://www.youtube.com/watch?v=48D3DzxJ-Go'
                gtm='For Providers Video Watch'
                height='390px'
              />
              <svg
                className={styles.svg2}
                width='259'
                height='208'
                viewBox='0 0 259 208'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M239.812 0.326238C248.879 16.0304 254.764 33.3667 257.131 51.3452C259.498 69.3237 258.3 87.5923 253.607 105.108C248.914 122.624 240.816 139.044 229.777 153.43C218.738 167.816 204.974 179.888 189.269 188.954C173.565 198.021 156.229 203.906 138.251 206.273C120.272 208.64 102.003 207.442 84.4876 202.749C66.9719 198.056 50.5521 189.958 36.1657 178.919C21.7793 167.88 9.70813 154.116 0.641313 138.412L120.227 69.3689L239.812 0.326238Z'
                  fill='#59B6BE'
                />
              </svg>
              <img
                className={styles.svg3}
                src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/svgdots.svg'
              />
            </div>
          </div>
          <div className='col-lg-2'></div>
        </div>
      </div>
    </div>
  );
}
