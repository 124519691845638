import React, { useState, useContext } from 'react';
import './email_signup_footer.css';
import { validateEmail } from '../../helpers';
import ErrorMessage from '../ui_kit/utilities/error_message';
import SuccessMessage from '../ui_kit/utilities/success_message';
import { createDownloadGuideContact } from '../../api/download_guide_contacts';
import Context from '../../utils/context';

export default function EmailSignupFooter({
  headline = 'Receive a free insurance guide today!',
  para = "Receive a free guide to learn more about weight-loss surgery options, determine if your insurance plan will cover your procedure, what do to if it doesn't, and what steps to take if it does!",
  successMessage,
  type,
  gtm,
}) {
  const context = useContext(Context);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const onSubmit = () => {
    var checkedEmail = validateEmail(email);
    if (checkedEmail == true) {
      setError('');
      createDownloadGuideContact({ email, type }, () => {
        setEmail('');
        setSuccess(successMessage);
      });
    } else {
      setSuccess('');
      setError('Please enter a valid email address.');
    }
  };

  return (
    <div className='newsletter-footer'>
      <section className='get-started-sec'>
        <div className='container'>
          <div className='get-started-outr wow fadeInDown'>
            <div className='cmn-hdr wh text-center'>
              <div className='container-fluid newsletter-signup-form'>
                <div className='row'>
                  <div className='col'>
                    <h2 className='text-center darkprim marg-bottom-10'>{headline}</h2>
                    <p className='text-center darkprim marg-bottom-20'>{para}</p>
                    <div className='row newsletter-form'>
                      <div className='col-sm-8'>
                        <div className='material-input light'>
                          <input
                            aria-label='name'
                            value={email}
                            onChange={handleChange}
                            id='name'
                            name='name'
                            required
                          />
                          <span className='bar'></span>
                          <label>Email</label>
                        </div>
                        {error && <ErrorMessage error={error} />}
                        {success && <SuccessMessage success={success} />}
                      </div>
                      <div className='col-sm-4 submit-button'>
                        <button className='gtm-wl-lead-magnet' onClick={onSubmit} data-gtm-click-label={gtm}>
                          Send now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='half-bg'></div>
    </div>
  );
}
