import React from 'react';
import WebinarView from '.';

export default function ({}) {
  return (
    <div className='webinar-view'>
      <WebinarView video={'https://www.youtube.com/embed/zb__Wh5VZ40'} />
    </div>
  );
}
