export const sendMessage = (conversation_id, body, url_preview_metadata, files, successFunction) => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  fetch(`/api/v1/messages/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify({
      conversation_id: conversation_id,
      body: body,
      url_preview_metadata: url_preview_metadata,
      files: files,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (successFunction) {
        successFunction(data);
      }
    });
};
