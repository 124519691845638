import { connect } from 'react-redux';
import { updateAnswersAction } from '../../../actions/home';
import April282021 from '../../../components/webinars/april_28_2021';

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updateAnswersAction: (item) => dispatch(updateAnswersAction(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(April282021);
