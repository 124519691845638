import React from 'react';
import './about.css';
import CustomCtaFooter from '../custom_cta_footer';
import Ihh_type_1 from '../image_headline_heros/ihh_type_1';
import JoinFooter from '../join_footer';
import WaveSeparator from '../ui_kit/utilities/wave_separator';

export default function About({}) {
  return (
    <div className='about'>
      <Ihh_type_1 />
      <div className='container marg-top-100'>
        <div className='row marg-top-100'>
          <div className='col-md-6'>
            <h2 className='marg-bottom-30'>Bringing more empathy to healthcare.</h2>
            <p>
              PatientPartner co-founder and CEO, George Kramb, spent years in the operating room alongside doctors
              ensuring medical devices were utilized properly. He supported hundreds of operations; but it wasn’t until
              he spoke to a few patients before their procedure that he realized how anxious and fearful they were going
              into it.
              <br />
              <br />
              George saw a need for greater support, education and compassion for patients who were going through a
              stressful medical experience. He saw an opportunity to connect patients with one another through a
              community of empathy and support. Thus, PatientPartner was born. <br />
              <br />
              Today, our community includes over 100 PatientPartner Mentors who have gone through a medical procedure
              and are ready to share their experience to help others. We’ve also built a network of over 50 doctors who
              see the value in connecting relatable patients and are committed to improving their patients’ experience.
            </p>
          </div>
          <div className='col-md-6'>
            <img
              alt='founders'
              className='about-pic'
              src='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/georgeandpatrick.png'
            />
            <p className='photo-sub'>George Kramb (left) and Patrick Frank (right)</p>
          </div>
        </div>
      </div>
      <div className='marg-top-100'>
        <WaveSeparator />
        <JoinFooter />
      </div>
    </div>
  );
}
