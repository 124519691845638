import React, { Component, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import ModalWrapper from '../modal';
import ErrorMessage from '../ui_kit/utilities/error_message';
import './index.css';

export default function SurgeonOutOfStateModal({ onSubmit, onClose, gtmLocationLabel }) {
  let history = useHistory();

  const findADifferentDoctorClick = () => {
    history.push('/surgeon-referral');
  };

  return (
    <ModalWrapper
      className='surgeon-out-of-state already-took-this'
      content={
        <div>
          <h3 className='marg-bottom-20'>Are you open to traveling?</h3>
          <ErrorMessage
            error={
              "It looks like you're requesting an appointment with a doctor located in a different state. Are you planning to travel to the doctor's location for the procedure?"
            }
          />
          <div className='marg-top-20 row max-wid'>
            <div className='col-sm-6'>
              <button
                data-gtm-click-label={`${gtmLocationLabel} Yes I Plan to Travel`}
                onClick={onSubmit}
                className={`submit`}
                type='submit'
              >
                Yes, I plan to travel
              </button>
            </div>
            <div className='col-sm-6'>
              <button
                data-gtm-click-label={`${gtmLocationLabel} Find a Different Doctor`}
                onClick={findADifferentDoctorClick}
                className={`submit destructive`}
                type='submit'
              >
                No, find a different doctor
              </button>
            </div>
          </div>
        </div>
      }
      onClose={onClose}
    />
  );
}
