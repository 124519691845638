import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import UrlPreview from '../../../posts/url_preview';
import ImageGridDisplay from '../../../ui_kit/utilities/image_grid_display';
import DOMPurify from '../../../../utils/purify.min.js';
import PatientOtherUserPanel from '../../../users/conversation_room/profile_panel/patient_other_user_panel';
import { findConversation, updateConversation } from '../../../../api/conversations';
import '../../../users/conversation_room/conversation_room.css';
import styles from './index.module.css';

export default function AdminConversationsShow({ match }) {
  let conversation_id = match.params.conversation_id;
  const linkifyHtml = require('linkifyjs/html');
  const [conversation, setConversation] = useState();
  useEffect(() => {
    findConversation(
      conversation_id,
      (result) => {
        setConversation(result);
      },
      () => {},
      true
    );
  }, []);

  const dismissMentorUnresponsive = () => {
    updateConversation(
      conversation_id,
      {
        mentor_unresponsive: false,
      },
      () => {
        window.location.reload();
      }
    );
  };

  const dismissConversationFlag = () => {
    updateConversation(
      conversation_id,
      {
        flagged: false,
      },
      () => {
        window.location.reload();
      }
    );
  };

  if (!conversation) {
    return <></>;
  }

  return (
    <div className={`container-fluid ${styles.conversation_room_bg}`}>
      <p className={`text-link ${styles.back}`}>
        <Link to={`/admin/conversations`}>Back to all conversations</Link>
      </p>
      {conversation.mentor_unresponsive && (
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <p>
                Mentor is unresponsive.
                <button className='btn-primary' onClick={dismissMentorUnresponsive}>
                  Dismiss
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
      {conversation.flagged && (
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <p>
                Conversation is flagged based on words.
                <button className='btn-primary' onClick={dismissConversationFlag}>
                  Dismiss
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
      <div className='container conversation-room-innr'>
        <div className='row'>
          <div className='col-md-3'>
            <PatientOtherUserPanel
              isMobile={false}
              otherUser={conversation.other_user_conversations[0]}
              setShowSurgeonModal={() => {}}
            />
          </div>
          <div className='col-md-6 chat-room'>
            <div className='message-container-otr' style={{ height: '62vh' }}>
              <div className='message-container'>
                <div className='container'>
                  <div className='row'>
                    <div className='col'>
                      {conversation &&
                        conversation.messages.map((message, index) => {
                          const currentMessageCurrentUser = message.current_user_sent;
                          const previousMessageCurrentUser =
                            index - 1 !== -1 && conversation.messages[index - 1].current_user_sent;
                          return (
                            <>
                              {((index - 1 === -1) === true ||
                                (new Date(message.created_at).getDate() !==
                                  new Date(conversation.messages[index - 1].created_at).getDate() &&
                                  index - 1 !== -1)) && (
                                <p className='text-center message-date'>{`${new Date(message.created_at).toLocaleString('default', { month: 'short' })} ${new Date(message.created_at).getDate()}`}</p>
                              )}
                              {index - 1 === -1 && (
                                // show timestamp for the very first message
                                <p
                                  style={{ textAlign: message.current_user_sent ? 'right' : 'left' }}
                                  className='message-date marg-top-10'
                                >{`${new Date(message.created_at).toLocaleTimeString()}`}</p>
                              )}
                              {index - 1 !== -1 &&
                                (message.current_user_sent !== conversation.messages[index - 1].current_user_sent) ===
                                  true &&
                                new Date(message.created_at).getMinutes() ===
                                  new Date(conversation.messages[index - 1].created_at).getMinutes() && (
                                  // show timestamp if the message is from a different user than the one above
                                  <p
                                    style={{ textAlign: message.current_user_sent ? 'right' : 'left' }}
                                    className='message-date marg-top-10'
                                  >{`${new Date(message.created_at).toLocaleTimeString()}`}</p>
                                )}
                              {index - 1 !== -1 &&
                                new Date(message.created_at).getMinutes() !==
                                  new Date(conversation.messages[index - 1].created_at).getMinutes() && (
                                  // show timestamp if the message above is a different minute
                                  <p
                                    style={{ textAlign: message.current_user_sent ? 'right' : 'left' }}
                                    className='message-date marg-top-10'
                                  >{`${new Date(message.created_at).toLocaleTimeString()}`}</p>
                                )}
                              {currentMessageCurrentUser && (
                                <div
                                  style={
                                    message.message_images.length > 0 ? { width: 'auto' } : { width: ' max-content' }
                                  }
                                  className={`message-bubble current-user ${index - 1 !== -1 ? (!previousMessageCurrentUser ? 'marg-top-20' : '') : ''} ${message.flagged ? styles.flagged_message : ''}`}
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        linkifyHtml(message.body.replaceAll('\n', '<br/>'), { target: 'blank' })
                                      ),
                                    }}
                                    className='no-select'
                                  ></p>
                                  {message.url_preview_metadata && (
                                    <UrlPreview metadata={message.url_preview_metadata} />
                                  )}
                                  {message.message_images.length > 0 && (
                                    <div className='marg-top-10 marg-bottom-10'>
                                      <ImageGridDisplay photos={message.message_images.map((i) => i.image)} />
                                    </div>
                                  )}
                                </div>
                              )}
                              {!currentMessageCurrentUser && (
                                <div
                                  style={
                                    message.message_images.length > 0 ? { width: 'auto' } : { width: ' max-content' }
                                  }
                                  className={`message-bubble other-user ${index - 1 !== -1 ? (previousMessageCurrentUser ? 'marg-top-20' : '') : ''}`}
                                >
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        linkifyHtml(message.body.replaceAll('\n', '<br/>'), { target: 'blank' })
                                      ),
                                    }}
                                    className='no-select'
                                  ></p>
                                  {message.url_preview_metadata && (
                                    <UrlPreview metadata={message.url_preview_metadata} />
                                  )}
                                  {message.message_images.length > 0 && (
                                    <div className='marg-top-10 marg-bottom-10'>
                                      <ImageGridDisplay photos={message.message_images.map((i) => i.image)} />
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <PatientOtherUserPanel
              isMobile={false}
              otherUser={conversation.current_user_conversation}
              setShowSurgeonModal={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
